import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import List from 'devextreme-react/list';
import ArrayStore from 'devextreme/data/array_store';
import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import viewIcon from "../../../images/request/detail/eye.svg";
import GeneralModal from "../../../theme/Modal/GeneralModal";
import ContentPreviewModal2 from "../../../theme/Modal/ContentPreviewModal2";
const styles = (theme) => ({
    tableFile: {
        '& tr': {
            '& th': {
                padding: 5,
                backgroundColor: 'transparent',
            },
            '& td': {
                padding: 5,
                backgroundColor: '#fff',
                borderBottom: '5px solid #e6edfc'
            }
        },
        '& .actionView': {
            width: 24,
            cursor: 'pointer'
        }
    }
})
const useStyles = makeStyles(styles);

const dataSource = new ArrayStore({
    key: 'id',
    data: [{id: -1, fileName: 'Unknow'}],
});
class FileList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedItemKeys: [...props.selectedItemKeys],
            openModal: false,
            valueModal: null
        }

        dataSource.clear()
        props.files.forEach(file => dataSource.push([{ type: "insert", data: file }]))

        console.log("new FileList",dataSource)

        this.onSelectedItemKeysChange = this.onSelectedItemKeysChange.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        // Ça va écraser toute mise à jour de l'état local !
        // Ne faites pas ça.
        // state.selectedItemKeys = []

        dataSource.clear()
        props.files.forEach(file => dataSource.push([{ type: "insert", data: file }]))

        console.log("getDerivedStateFromProps FileList",dataSource)

        return state
    }

    onSelectedItemKeysChange(newSelectedItemKeys) {
        this.setState({
            selectedItemKeys: newSelectedItemKeys,
        }, () => {
            this.props.onSelectionChange(this.state.selectedItemKeys)
        });
    }

    handleCloseModal = () => {
        this.setState({
            openModal: false,
            valueModal: null
        })
    }

    render() {
        const {
            classes,
        } = this.props;
        const {
            openModal,
            valueModal,
            selectedItemKeys
        } = this.state;
        // console.log("FileList render()",dataSource, this.props, this.state)
        // const dataSource = new ArrayStore({
        //     key: 'id',
        //     data: this.props.files,
        //   });
        console.log(selectedItemKeys)
        let checkAll = true;
        this.props.files.forEach((itemFile, indexFile) => {
            if (!selectedItemKeys.includes(itemFile.id)) {
                checkAll = false;
            }
        })
        return (
            <>
                <Table className={classes.tableFile}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Checkbox
                                    checked={checkAll}
                                    onChange={(event) => {
                                        if (event.target.checked) {
                                            this.onSelectedItemKeysChange(this.props.files.map(item => item.id))
                                        } else {
                                            this.onSelectedItemKeysChange([])
                                        }
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <div style={{
                                    color: '#3939b7',
                                    fontSize: 16,
                                    fontWeight: 600,
                                    textTransform: 'uppercase'
                                }}>
                                    {this.props.t('requestDocument.select_all')}
                                </div>
                            </TableCell>
                            <TableCell>

                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.props.files.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Checkbox
                                                checked={selectedItemKeys.includes(item.id)}
                                                onChange={(event) => {
                                                    let newSelected = [...selectedItemKeys]
                                                    if (event.target.checked) {
                                                        newSelected = [
                                                            ...selectedItemKeys,
                                                            item.id
                                                        ]
                                                    } else {
                                                        newSelected = newSelected.filter(itemS => itemS !== item.id)
                                                    }
                                                    this.onSelectedItemKeysChange(newSelected)
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <div
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    let newSelected = [...selectedItemKeys]
                                                    if (!selectedItemKeys.includes(item.id)) {
                                                        newSelected = [
                                                            ...selectedItemKeys,
                                                            item.id
                                                        ]
                                                    } else {
                                                        newSelected = newSelected.filter(itemS => itemS !== item.id)
                                                    }
                                                    this.onSelectedItemKeysChange(newSelected)
                                                }}
                                            >
                                                {item.docName}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <img className="actionView" src={viewIcon}  onClick={() => {
                                                this.setState({
                                                    openModal: true,
                                                    valueModal: {
                                                        key: index
                                                    }
                                                })
                                            }}/>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                {openModal && valueModal && <GeneralModal
                    className={"previewModal"}
                    open={openModal}
                    handleClose={this.handleCloseModal}
                    content={<ContentPreviewModal2 files={this.props.files} keyFile={valueModal.key}/>}
                    saveButton={false}
                    cancelButton={false}
                />}
            </>
        )
        return (
            <React.Fragment>
                <List
                    dataSource={dataSource}
                    height={"auto"}
                    showSelectionControls={true}
                    keyExpr={'id'}
                    displayExpr={'docName'}
                    selectionMode={'all'}
                    selectAllMode={'allPages'}
                    selectedItemKeys={this.state.selectedItemKeys}
                    onOptionChanged={this.onSelectedItemKeysChange}
                    // menuItems={[
                    //     {
                    //         action: 'action',
                    //         text:'text'
                    //     }
                    // ]}
                >
                </List>
            </React.Fragment>
        )
    }
}

export default compose(
    // connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter
)(FileList);
