import React from "react";
import UserForm from "./UserForm";
import {FORM_EDIT} from "../../../../constants/constants";
class UserEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div>
                <UserForm formType={FORM_EDIT}/>
            </div>
        )
    }
}

UserEdit.propTypes = {
};

export default UserEdit;
