import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {API_DELETE_PARAMETER, API_GET_PARAMETER} from "../../../../constants/apiSuffix";
import qs from "qs";
import {
    APP_ADVERTISE, arrAppAdvertisec,
    arrBlickoContact,
    arrBreakTimeForm,
    arrCallTimeForm,
    arrCurrentForm,
    arrDayOfWeekForm,
    arrEmailAddressBcc,
    arrHolidayForm,
    arrPricePayDefault, arrShareDocumentExpireNumberDay,
    arrWordTimeForm,
    BLICKO_CONTACT,
    BREAK_TIME,
    CALL_TIME,
    CURRENCY,
    DAY_OF_WEEK,
    EMAIL_ADDRESS_BCC,
    FORM_CREATE,
    FORM_EDIT,
    FORM_LIST,
    HOLIDAY,
    PRICE_PAY_DEFAULT, SHARE_DOCUMENT_EXPIRE_NUMBER_DAY,
    WORK_TIME
} from "../../../../constants/constants";
import FormParameter from "./FormParameter";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {NavLink, withRouter} from "react-router-dom";
import moment from "moment";
import List from "../../../../theme/List";
import {withStyles} from "@material-ui/core/styles";
import {convertNumberIntMinuteToHour} from "../../../../functions/functions";
import DialogForm from "../../../../theme/CustomElements/DialogForm";
import AlertCustom from "../../../../theme/form/Alert/AlertCustom";
const styles = {
    header:{
        display: 'flex',
        alignItems: "center",
        justifyContent: "flex-end",
        padding: '1rem',
        '& a':{
            '&:hover': {
                background: "#0000c7",
            },
            textTransform: "uppercase",
            fontSize: '0.75rem',
            color: "white",
            background: "#0000FF",
            padding: '0.5rem 1rem',
            borderRadius: 5,
            fontWeight: 700,
            textDecoration: 'none'
        }
    },
    btnEdit:{
        padding: '0.5rem 1rem',
        fontWeight: 600,
        '&.btnDelete':{
            background: "#ff0000",
            marginLeft: 10,
            cursor: 'pointer',
            '&:hover':{
                background: "#b80c0c",
            },
        },
        '&:hover':{
            background: "#0000c7",
        },
        textTransform: "uppercase",
        textDecoration: 'none',
        color: "white",
        background: "#0000FF",
    },
    btnActionWrapper:{
        display: 'flex'
    },
    paramaterPage: {
        '& td': {
            padding: '0.5rem 1rem!important'
        }
    }
};
export const formDataValue = {
    id: null,
    code: "",
    value:"",
    description: "",
    callTime: "",
    startTime:null,
    endTime:null,
    startDate:null,
    endDate: null
};

export const PARAMETER_IS_NOT_VIEW_LIST = [CURRENCY, WORK_TIME, CALL_TIME, BLICKO_CONTACT, PRICE_PAY_DEFAULT, SHARE_DOCUMENT_EXPIRE_NUMBER_DAY];
class ParameterPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formType: FORM_EDIT,
            formValue: {
                id: null,
                code: "",
                value:"",
                description: "",
                callTime: "",
                startTime:null,
                endTime:null,
                startDate:null,
                endDate: null,
                dialogOpen: null,
                successOpen: false,
                errorOpen: false
            },
            forceUpdate: false
        };
    }

    setForceUpdate = (value) => {
        this.setState({
            forceUpdate: value
        })
    }
   convertMinuteToTime = (minute)=>{
       const hour = parseInt(minute/60)
       const minutesByHour = minute%60
       return moment(moment().format("YYYY-MM-DD")+ " "+hour+":"+(minutesByHour<10 ?"0"+minutesByHour:minutesByHour)+":00");
    }
    getData = () => {
        const {location} = this.props;

        const code = location.pathname.replace("/", "");
        if (PARAMETER_IS_NOT_VIEW_LIST.includes(code)) {
            let query = {code: code}

            const url = API_GET_PARAMETER + `?${qs.stringify(query)}`;
            axios.get(url)
                .then(response => {
                    if(response.status===200) {
                        let formValueNew = {...this.state.formValue};
                        formValueNew.code= code;
                        if(response.data.items.length >0 ) {
                            const dataValue = response.data.items[0];
                            formValueNew = {...formValueNew,
                                code:dataValue.code??"",
                                value:dataValue.value??"",
                                description:dataValue.description?? "",
                                callTime: dataValue.callTime??"",
                                startTime:dataValue.startTime?(this.convertMinuteToTime(dataValue.startTime)):null,
                                endTime:dataValue.endTime?(this.convertMinuteToTime(dataValue.endTime)):null,
                                startDate:dataValue.startDate??null,
                                endDate: dataValue.endDate??null
                            }
                        }
                        this.setState({
                            formType: FORM_CREATE,
                            formValue: formValueNew,
                            forceUpdate: false
                        })
                    }else{
                        throw new Error();
                    }
                })
                .catch(error => {
                    this.setState({
                        formType: FORM_CREATE,
                        formValue: {
                            ...this.state.formValue,
                            code: code
                        },
                        forceUpdate: false
                    })
                });
        } else {
            this.setState({
                formType: FORM_LIST,
                formValue: {
                    ...this.state.formValue,
                    code: code
                },
                forceUpdate: false
            })
        }

    }

    componentDidMount() {
     this.getData()
    }

    componentDidUpdate() {
        if(this.state.forceUpdate){
            this.getData()
        }
    }

    deleteItem = (id)=>{
        this.setState({
            dialogOpen: null,
            isLoading: true
        })
        axios.post(API_DELETE_PARAMETER.replace("{id}", id))
            .then(res=>{
                if(res.status === 200) {
                    this.setState({
                        forceUpdate: true,
                        isLoading: false,
                        successOpen: true
                    })
                }else{
                    throw new Error();
                }
            })
            .catch(error=>{
                this.setState({
                    isLoading: false,
                    errorOpen: false
                })
            })
    }
    render() {
        const {formType, formValue}= this.state;
        const {location, t} = this.props;
        const { classes, ...other } = this.props;
        const  arrayColumnItem = formValue.code ===CURRENCY ? arrCurrentForm :
           formValue.code ===BREAK_TIME ? arrBreakTimeForm :
                formValue.code ===WORK_TIME ? arrWordTimeForm :
                    formValue.code ===DAY_OF_WEEK? arrDayOfWeekForm:
                        formValue.code === HOLIDAY ?arrHolidayForm :
                            formValue.code === CALL_TIME ? arrCallTimeForm :
                                formValue.code === BLICKO_CONTACT ? arrBlickoContact :
                                    formValue.code === PRICE_PAY_DEFAULT ? arrPricePayDefault:
                                        formValue.code === EMAIL_ADDRESS_BCC ?arrEmailAddressBcc:
                                            formValue.code === APP_ADVERTISE ?arrAppAdvertisec:
                                                formValue.code === SHARE_DOCUMENT_EXPIRE_NUMBER_DAY ? arrShareDocumentExpireNumberDay :
                                                [];
        let arrayColumn = [];
        let filterColumnsEnabled = [{columnName: "action", filteringEnabled: false}];
        let sortingStateColumnExtensions = [{columnName: 'action', sortingEnabled: false}];
        arrayColumnItem.map(item => {
            let nameTitle = item;
            if (formValue.code === DAY_OF_WEEK) {
                nameTitle = item === "startTime" ? "startTimeWork" : (item === "endTime" ? "endTimeWork" : item)
            }else if(formValue.code === EMAIL_ADDRESS_BCC && item === "value"){
                nameTitle = "email"
            }
            let itemColumn = {
                source: item,
                label: this.props.t("reactAdmin.parameter.list." + nameTitle),
                name: item,
                title: this.props.t("reactAdmin.parameter.list." + nameTitle),
                getCellValue: row =>
                {
                    if(row[item]){
                        return <div >
                            { item === "startDate" || item === "endDate" ? moment(row[item]).format("DD-MM-YYYY HH:mm:ss") :
                                (item === "startTime" || item === "endTime")?convertNumberIntMinuteToHour(row[item]).format("HH:mm"):
                                    (item === "value" && formValue.code !== EMAIL_ADDRESS_BCC)? t(`reactAdmin.parameter.form.dayOfWeek.${row[item]}`) :row[item] }
                        </div>
                    } else {
                        return ""
                    }
                }

            };
                arrayColumn.push( itemColumn);
                filterColumnsEnabled.push({columnName: item, filteringEnabled: false});
                sortingStateColumnExtensions.push({columnName: item, sortingEnabled: false})
            }
        );
        if(formType === FORM_LIST){
            arrayColumn.push({
                name: "action",
                title: " ",
                getCellValue: row =>{
                    return <div className={classes.btnActionWrapper}>
                        <NavLink to={location.pathname+"/"+row.id} className={classes.btnEdit}>
                            {t("reactAdmin.categories.btnEdit")}
                        </NavLink>
                        {formValue.code === APP_ADVERTISE && !row.itemUsed && <div
                            onClick={()=>this.setState({
                                dialogOpen: row
                            })}
                            className={classes.btnEdit+ " btnDelete"}>
                            {t("reactAdmin.parameter.list.btnDelete")}
                        </div>}
                    </div>
                }
            })
        }

        let apiParams = {
            code: formValue.code
        };
        let componentState = Object.assign({
            iconList: '',
            iconNew: '',
            classList: 'productList',
            columns: arrayColumn,
            // apiParams:apiParams,
            filterColumnsEnabled:filterColumnsEnabled,
            sortingStateColumnExtensions:sortingStateColumnExtensions,
            defaultColumnWidths:[
                // {columnName: 'action', width: 40}
            ],
            titleList: '',
            /*api link*/
            apiNameList: API_GET_PARAMETER,
            // hiddenColumns: hiddenColumns
            apiParams: apiParams ,
            /*route link*/
        },this.props.componentState?this.props.componentState:{});
        return (
            <div>
                {formType === FORM_EDIT || formType === FORM_CREATE ?
                    formValue.code ? <FormParameter arrayColumnItem={arrayColumnItem} formValue={formValue} setForceUpdate={this.setForceUpdate} formType={formType} />:""
                    :
                    <div className={classes.paramaterPage}>
                        <div className={classes.header}><NavLink to={location.pathname + "/create"}
                                      className={"btnCreate"}>{this.props.t("reactAdmin.label.create")}</NavLink></div>
                        <List
                            {...other}
                            componentState={componentState}
                            forceUpdate={this.state.forceUpdate}
                            // className={classes.requestListWrapper}
                            callbackWidth={this.callbackWidth}
                        />
                    </div>
                }
                {!!this.state.dialogOpen && <DialogForm
                    styleNumber={2}
                    dialogProps={{
                        open: !!this.state.dialogOpen
                    }}
                    disagreeButtonProps={{
                        handleDisagree: () => {
                            this.setState({
                                dialogOpen: null
                            })
                        }
                    }}
                    agreeButtonProps={{
                        handleAgree: () => {
                            this.deleteItem(this.state.dialogOpen.id);
                        }
                    }}
                />}
                <AlertCustom
                    successOpen={this.state.successOpen}
                    handleCloseNotice={()=>this.setState({
                        successOpen: false,
                        errorOpen: false
                    })}
                    errorOpen={this.state.errorOpen}
                />
            </div>
        )
    }
}

ParameterPage.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

export default  compose(
    // connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter
) (ParameterPage);
