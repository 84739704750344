import * as types from "./../_constants/auth";
// import {API_COUNT_REQUEST_BY_STATUS} from "../constants/apiSuffix";
// import axios from "axios";

export const logout = () => {
    return {
        type: "LOGOUT"
    };
    // return (dispatch) => {
    //     let apiUrlCount = "/api/logout";
    //     axios.get( apiUrlCount)
    //         .then((response) => {
    //             if (response.status ===  200) {
    //                 dispatch({
    //                     type: "LOGOUT"
    //                 });
    //             }
    //         }).catch((err) => {
    //         dispatch({
    //             type: "LOGOUT"
    //         });
    //     })
    // }
};

export const accessDeny = () => {
    return {
        type: "ACCESS_DENY"
    };
};

export const badRequest = () => {
    return {
        type: "BAD_REQUEST"
    };
};

export const serverError = () => {
    return {
        type: "SERVER_ERROR"
    };
};

export const changeUrl = () => {
    return {
        type: "CHANGE_URL"
    };
};
export const setDataUser = (dataUser)=>{
    return {
        type: types.AUTH_DATA_USER,
        dataUser: dataUser,
    };
};

export const setDataLogin = (dataLogin) => {
    return {
        type: types.AUTH_DATA_LOGIN,
        dataLogin: dataLogin
    };
}

export const setRequestResetPassword = (successRequest) => {
    return {
        type: types.REQUEST_RESET_PASSWORD,
        successRequest: successRequest
    }
}

export const changePassword = (changePasswordSuccess) => {
    return {
        type: types.CHECK_CHANGE_PASSWORD,
        changePasswordSuccess: changePasswordSuccess
    }
}

export const checkCreateNewClientSuccess = (checkCreateNewClient) => {
    return {
        type: types.CHECK_CREATE_NEW_CLIENT,
        checkCreateNewClient: checkCreateNewClient
    }
}

export const saveTypeFormAuth = (typeFormAuth) => {
    return {
        type: types.SAVE_TYPE_FORM,
        typeFormAuth: typeFormAuth
    }
}

export const checkSwitchAuthForm = (checkSwitch) => {
    return {
        type: types.CHECK_SWITCH_AUTH_FORM,
        checkSwitch: checkSwitch
    }
}
