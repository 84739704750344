import {Button, Grid as MuiGrid, TextField} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {translate,} from 'react-admin';

import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core";
import AppFreeSoloAutocomplete from "../../theme/form/Autocomplete/AppFreeSoloAutocomplete";
import {compose} from "redux";
import ListEmpty from "./resources/ListEmpty";
// import SaveIcon from '@material-ui/icons/Save';

const styles = {
    tablePaginationBlock:{
        padding: "20px",
        alignItems: "center",
        "& button":{
            //minWidth: "2.5rem"
        },
        "& .pagingInfos" :{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }
    },
    pageSizeButton:{
        "&.active":{
            border: "1px solid #0000ff",
        }
    },
    paginationPageNum :{
        padding:"8px 0",
        "&.active":{
            background: "#0000ff",
            color: "white!important",
            '& span': {
                color: "white!important"
            }
        },
        "&:hover:not(.active)":{
            background: "rgba(70,67,90,0.81)",
            color:"white"
        },
        "&.prevBtn,&.nextBtn":{
            fontSize: "1.2rem"
        }
    },
    paginationResultInfos:{
        padding: "0 5px",
        marginRight: "2rem"
    },
};
const pageSizes = [10, 30, 50];

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state={

        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.location.pathname && props.location.pathname !== state.pathNameLocation && state.changePathNameLocation){
            return {
                pathNameLocation: props.location.pathname,
                changePathNameLocation: false
            }
        }
        return null;
    }

    static propTypes = {
        setPage: PropTypes.func,
        setPerPage: PropTypes.func,
        // logout: PropTypes.object,
    };


    render() {
        const {page, perPage, total, setPage, setPerPage, classes, t} = this.props;
        // console.log(this.props)
        const nbPages = Math.ceil(total / perPage) || 1;
        let pageSizeOptions = pageSizes;
        if (!pageSizeOptions.includes(perPage)){
            pageSizeOptions.push(perPage);
        }
        pageSizeOptions.sort(function (a, b) {
            return a - b;
        });
        let infoPaginate = t("text.table.paging.info")
            .replace( /{from}/g, (page >1 ? (page-1) * perPage +1: page))
            .replace(/{to}/g, (page < nbPages)?(perPage * page) : total)
            .replace(/{count}/g, total)

        return ((total < 1 && this.props.arrayColumn) ? <ListEmpty arrayColumn={this.props.arrayColumn} hiddenCreatedButton={true}/> :
            <MuiGrid container direction={"row"} className={classes.tablePaginationBlock}>
                {
                    total >0 && pageSizes && pageSizes.length &&
                    <MuiGrid  item xs={3} className="selectPageSize">
                        <AppFreeSoloAutocomplete
                            fullWidth={false}
                            options={pageSizeOptions?pageSizeOptions.map((item)=>item.toString()):[]}
                            disableClearable
                            value={perPage}
                            style={{ height:"auto",borderRadius:10 }}
                            onChange={(e,value)=>{
                                setPerPage(parseInt(value));
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password',
                                        value:perPage
                                    }}
                                    variant={"outlined"}
                                    onChange={(e)=>setPerPage(e.target.value)}
                                />

                            )}
                        />
                    </MuiGrid>
                }
                {nbPages >0 && total > 0 && <MuiGrid  item container xs={6} className="pagingInfos" spacing={1} >
                    <MuiGrid item className={classes.paginationResultInfos}>{infoPaginate}</MuiGrid>
                    {nbPages>1 &&
                    <React.Fragment>
                        {page>1 && <MuiGrid item><Button size="small" className={classes.paginationPageNum+" prevBtn"} onClick={()=>setPage(page-1)}>{"<"}</Button></MuiGrid>}
                        {page>1 &&<MuiGrid item><Button className={classes.paginationPageNum} onClick={()=>setPage(page-1)}>{page-1}</Button></MuiGrid>}
                        <MuiGrid item> <Button className={classes.paginationPageNum+" active"}>{page}</Button></MuiGrid>
                        {page < nbPages && <MuiGrid item><Button className={classes.paginationPageNum} onClick={() => setPage(page + 1)}>{page+1}</Button></MuiGrid>}
                        {page<nbPages && <MuiGrid item><Button className={classes.paginationPageNum+" nextBtn"} onClick={() => setPage(page + 1)}>{">"}</Button></MuiGrid>}
                    </React.Fragment>
                    }
                </MuiGrid>}
            </MuiGrid>
        );
    }
}

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    theme: state.theme,
    // locale: state.i18n.locale,
});

Pagination.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

const enhance = compose(
    withStyles(styles),
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
    withTranslation(),
    translate
);

export default enhance(Pagination);
