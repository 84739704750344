import React, {Component} from 'react';
import _ from "lodash";
import {compose} from "redux";
import {IconButton, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';

const styles = theme => ({
    showFileModal:{
        width: '100%',
        height: '500px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    imageContent:{
        width: '80%',
        height: '100%',
        objectFit: 'contain'
    },
    videoContent:{
        width: '80%',
        height: 'auto',
        maxHeight: '100%',
        objectFit: 'contain'
    },
    buttonShow:{
        '&:hover':{
            background: '#b4cae6'
        }
    },
})

const showElement = (props) =>{
    let {itemShow, files, classes} = props
    let showedFile = files[itemShow]
    if (showedFile && showedFile.meta && showedFile.meta.type ) {
        if (showedFile.meta.type.includes("image")) {
            let urlImage = URL.createObjectURL(showedFile.file)
            return <img alt="" className={classes.imageContent} src={urlImage}/>
        }
        else if (showedFile.meta.type.includes("video")) {
            let url = URL.createObjectURL(showedFile.file)
            return  <video controls className={classes.videoContent} autoPlay> <source src={url}/> </video>
        } else return <div>Hello</div>
    }
    else return <div>Hello</div>
}
export const ShowElementComponent = compose(withStyles(styles), withTranslation())(showElement)

class ContentPreviewModal extends Component {
    constructor(props) {
        super(props);
        let {files, meta} = props
        // console.log(files)
        if(files.length>0) files = files.filter(item => item.meta && item.meta.status === "done")
        let itemShow = files.findIndex(item =>item.meta && _.isEqual(item.meta, meta))
        this.state={
            itemShow : itemShow,
            files: this.props.files,
            meta: this.props.meta
        }
    }
    buttonAction = (field) => {
        let {itemShow, files} = this.state
        if (field === 'leftButton'){
            if(itemShow === 0) itemShow = files.length - 1
            else itemShow -= 1
        }
        if (field === 'rightButton'){
            if(itemShow === files.length - 1) itemShow = 0
            else itemShow += 1
        }
        this.setState({
            itemShow: itemShow
        })


    }
    render() {
        let { files } = this.state
        let { classes } = this.props
        return (
            <div className={classes.showFileModal}>
                {files.length > 1 && <IconButton onClick={()=>this.buttonAction('leftButton')} className={`${classes.buttonShow}`}>
                    <ChevronLeftOutlinedIcon/>
                </IconButton>}
                <ShowElementComponent {...this.state}/>
                {files.length > 1 && <IconButton onClick={()=>this.buttonAction('rightButton')} className={`${classes.buttonShow}`}>
                    <ChevronRightOutlinedIcon/>
                </IconButton>}
            </div>
        );
    }
}

ContentPreviewModal.defaultProps = {
    files : [],
    meta: {},
}

export default withStyles(styles)(ContentPreviewModal);