import React from 'react';
import {compose} from "redux";
import PopoverCustom from "../../../../theme/CustomElements/PopoverCustom";
import {Grid, withStyles} from "@material-ui/core";
import CustomButton from "../../../../theme/CustomElements/CustomButton";
import CustomInput from "../../../../theme/CustomElements/CustomInput";
import returnIcon from "../../../../images/request/return.svg";
import {withTranslation} from "react-i18next";
import {MONTSERRAT} from "../../../../constants/fontCss";
import ErrorInput from "../../../../theme/form/Alert/ErrorInput";
import {XS} from "../../../../constants/constants";

const styles = (theme) => ({
    popoverEditStatus: {
        background: '#fff',
        boxShadow: '0px 0px 20px rgba(0, 0, 254, 0.05)',
        borderRadius: 10,
        width: 400,
        [theme.breakpoints.down(XS)]:{
            maxWidth: 'calc(100vw - 20px)'
        }
    },
    headerEditStatus: {
        background: '#001529',
        boxShadow: '0px 0px 20px rgba(0, 0, 254, 0.05)',
        borderRadius: '10px 10px 0px 0px',
        color: '#fff',
        fontWeight:600,
        padding: '0.5rem',
    },
    contentEditStatusWrapper:{
        padding: '0.5rem',
        '& .statusType':{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0.25rem'
        },
        '& .inputWrapper':{
            padding: '0.25rem'
        },
        '& .btnAction':{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '0.25rem'
        }
    },
    btnResponse:{
        background: '#0000FF !important',
        borderRadius: 10,
        '& .MuiButton-label':{
            fontFamily: MONTSERRAT,
            fontWeight: 700,
            fontSize: '0.875rem'
        }
    }
});

class PopoverAddNote extends React.Component{
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const { anchorEl,popoverAriaOwns ,onClose, classes,formValuePopover, noteForRequest, t, errors} = this.props;
        return <PopoverCustom
            anchorEl={anchorEl}
            popoverAriaOwns={popoverAriaOwns}
            onClose={onClose}
            content={
                <div className={classes.popoverEditStatus}>
                    <div className={`${classes.headerEditStatus} headerEditStatus`}>
                        {t("request.list.id")}: {formValuePopover.id}
                    </div>
                    <div className={`${classes.contentEditStatusWrapper} contentEditStatusWrapper`}>
                        <Grid container>
                            <Grid item xs={12} lg={12} className={"inputWrapper"}>
                                <CustomInput multiline
                                             rows={3}
                                             rowsMax={3}
                                             value={noteForRequest}
                                             onChange={(e)=>this.props.handleChangeField(e.target.value, "noteForRequest")}
                                             placeholder={t("request.popoverAddNote.note")}
                                             className={"comment"}
                                             validate={errors.noteForRequest}
                                />
                                <ErrorInput textValidate={errors.noteForRequest}/>
                            </Grid>
                            <Grid item xs={12}  lg={12} className={"btnAction"}>
                                <CustomButton
                                    className={classes.btnResponse}
                                    title={this.props.t("request.popoverAddNote.btnAddNote")}
                                    onClick={this.props.handleSave}
                                    buttonProps={{
                                        startIcon: <React.Fragment><img
                                            src={returnIcon} className={"iconAdd"} alt=""/></React.Fragment>
                                    }}

                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            }
        />
    }
}

export default compose(withStyles(styles), withTranslation())(PopoverAddNote);
