import React, { lazy } from "react";
import * as links from "../constants/links";
import RequestPage from "../components/client/request/RequestPage";
import ReactAdmin from "../components/ReactAdmin/ReactAdmin";
import {
  USER_TYPE_ADMIN,
  USER_TYPE_CLIENT,
  USER_TYPE_REPAIRER,
  USER_TYPE_SUPER_ADMIN,
} from "./constants";
import Profile from "../components/client/Profile";
import ManagerCalenderPage from "../components/admin/calendar/ManagerCalenderPage";
import StaffList from "../components/admin/staff/StaffList";
import StaffDetail from "../components/admin/staff/StaffDetail";
import RequestDetailPage from "../components/client/requestDetail/RequestDetailPage";
// import {DIAGNOSTIC, REPORT, REPORT_REQUEST} from "../constants/links";
import ReportPage from "../components/client/report/ReportPage";
import AddQuotation from "../components/client/report/AddQuotation";
import RequestRepairPage from "../components/admin/repair/RequestRepairPage";
const RestdayList = lazy(() =>
  import("../components/admin/calendar/RestdayList")
);
const CategoryPage = lazy(() =>
  import("../components/admin/category/CategoryPage")
);
const InformationAdditional = lazy(() =>
  import("../components/client/information/InformationAdditional")
);
const HomePage = lazy(() => import("../components/HomePage"));
const Diagnostic = lazy(() => import("../components/admin/report/Diagnostic"));
const Report = lazy(() => import("../components/admin/report/Report"));

const arrayRoutesPrivate = [
  {
    path: links.HOME,
    component: () => <HomePage />,
    exact: true,
    permission: [
      USER_TYPE_CLIENT,
      USER_TYPE_REPAIRER,
      USER_TYPE_ADMIN,
      USER_TYPE_SUPER_ADMIN,
    ],
  },
  {
    path: links.REQUEST,
    component: () => <RequestPage />,
    exact: true,
    permission: [USER_TYPE_CLIENT],
  },
  {
    path: links.REPORT,
    component: () => <ReportPage />,
    exact: true,
    permission: [USER_TYPE_CLIENT],
  },
  {
    path: links.ADMIN_REQUEST,
    component: () => <RequestPage />,
    exact: true,
    permission: [USER_TYPE_REPAIRER, USER_TYPE_ADMIN, USER_TYPE_REPAIRER],
  },
  {
    path: links.INFORMATION_PAGE,
    component: () => <InformationAdditional />,
    exact: true,
    permission: [USER_TYPE_CLIENT],
  },
  {
    path: links.BACKOFFICE_PAGE,
    component: () => <ReactAdmin />,
    exact: false,
    permission: [USER_TYPE_SUPER_ADMIN],
  },
  // {
  //     path: links.REQUEST_INFORMATION_DETAIL,
  //     component: (match) => <InformationAdditional match={match}/>,
  //     exact: true,
  //     permission: [USER_TYPE_CLIENT, USER_TYPE_REPAIRER, USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN]
  // },
  {
    path: links.PROFILE,
    component: () => <Profile />,
    exact: false,
    permission: [
      USER_TYPE_CLIENT,
      USER_TYPE_REPAIRER,
      USER_TYPE_ADMIN,
      USER_TYPE_SUPER_ADMIN,
    ],
  },
  {
    path: links.CALENDAR,
    component: () => <ManagerCalenderPage />,
    exact: false,
    permission: [USER_TYPE_REPAIRER, USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN],
  },
  {
    path: links.RESTDAY,
    component: () => <RestdayList />,
    exact: false,
    permission: [USER_TYPE_REPAIRER, USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN],
  },

  {
    path: links.CATEGORY,
    component: () => <CategoryPage />,
    exact: false,
    permission: [USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN],
  },
  {
    path: links.MANAGEMENT_STAFF,
    component: () => <StaffList />,
    exact: false,
    permission: [USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN],
  },
  {
    path: links.DETAIL_STAFF,
    component: (match) => <StaffDetail match={match} />,
    exact: false,
    permission: [USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN],
  },
  {
    path: links.EDIT_STAFF,
    component: (match) => <Profile match={match} />,
    exact: false,
    permission: [USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN],
  },
  {
    path: links.CREATE_STAFF,
    component: () => <Profile />,
    exact: false,
    permission: [USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN],
  },
  {
    path: links.REQUEST_INFORMATION_DETAIL,
    component: (match) => <RequestDetailPage match={match} />,
    exact: false,
    permission: [USER_TYPE_CLIENT],
  },
  {
    path: links.DIAGNOSTIC,
    component: (match) => <Diagnostic match={match} />,
    exact: true,
    permission: [USER_TYPE_ADMIN, USER_TYPE_REPAIRER, USER_TYPE_SUPER_ADMIN],
  },
  {
    path: links.REPORT_REQUEST,
    component: (match) => <Report match={match} />,
    exact: true,
    permission: [USER_TYPE_ADMIN, USER_TYPE_REPAIRER, USER_TYPE_SUPER_ADMIN],
  },
  {
    path: links.ADD_REPORT_TO_REQUEST,
    component: (match) => <AddQuotation match={match} />,
    exact: true,
    permission: [USER_TYPE_CLIENT],
  },
  {
    path: links.REQUEST_REPAIR,
    component: (match) => <RequestRepairPage match={match} />,
    exact: true,
    permission: [USER_TYPE_REPAIRER],
  },
];

export default arrayRoutesPrivate;
