import {DatagridBody, Create, Datagrid, Edit, EditButton, List, SimpleForm, TextField,
    // useNotify, useRedirect,
    TextInput, required} from "react-admin";
import React from "react";
import Pagination from "../Pagination";

import { withStyles } from '@material-ui/core/styles';
import {styleCreate, styleEdit, styleList} from "../style";
import {MyDatagridRow} from "../MyDatagridRow";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import ListEmpty from "./ListEmpty";
import {ListActions} from "../custom/customActionsList";
// import {CustomFilters} from "../customFiltersList";
// import {AddTitleForm} from "../customTitleForm";

const MyDatagridBody = props => <DatagridBody {...props} row={<MyDatagridRow />} />;
const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody />} />;
const arrayColumn = [
    {
        source: 'name',
        label: 'reactAdmin.categories.name'
    }
];
export const CategoryList = compose(withStyles(styleList), withTranslation())(({ classes, t, ...props }) => {
        const {tReady, ...newProps} = props

        return (
            <List {...newProps}
                  empty={<ListEmpty arrayColumn={arrayColumn} titleList={``}/>}
                  actions={<ListActions t={t} classes={classes} props={newProps}/>}
                // filters={<CustomFilters classes={classes} t={t} titleList={LABEL_CATEGORY}/>}
                  pagination={<Pagination {...newProps}/>} className={classes.listWrapper}
            >
                <Datagrid className={classes.tableWrapper}>
                    {arrayColumn.map((item, key) =>
                        <TextField key={key} source={item.source} label={t(item.label)} sortable={false}/>
                    )}
                    {/*<TextField source="code" label={t('admin.label.code')}/>*/}
                    <EditButton className={classes.editButton}/>
                </Datagrid>
            </List>
        )
    }
);

export const CategoryCreate = compose(withStyles(styleCreate), withTranslation())(({ classes, t, ...props }) => {
    // const notify = useNotify();
    // const redirect = useRedirect();
    // const onSuccess = () => {
    //     notify(t("form.success.save_ok"))
    //     redirect('list', props.basePath);
    // }
    // const onFailure = (error) => {
    //     notify(t("form.error.occurred"), 'error')
    // }
    const {tReady, ...newProps} = props

    return <Create {...newProps} className={classes.createWrapper}>
        <SimpleForm className={classes.createSimpleForm} >
            <Form t={t} classes={classes} {...props}/>
        </SimpleForm>
    </Create>
});

export const CategoryEdit = compose(withStyles(styleEdit), withTranslation())(({ classes, t, ...props }) => {
    // const notify = useNotify();
    // const redirect = useRedirect();
    // const onSuccess = () => {
    //     notify(t("form.success.save_ok"))
    //     redirect('list', props.basePath);
    // }
    // const onFailure = (error) => {
    //     notify(t("form.error.occurred"), 'error')
    // }
    const {tReady, ...newProps} = props

    return <Edit {...newProps} undoable={false} className={classes.editWrapper}>
        <SimpleForm className={classes.editSimpleForm}>
            <Form t={t} classes={classes} {...props} isFormEdit={true} />
        </SimpleForm>
    </Edit>
});

const Form = ({t, ...props}) => {
    return (
        <React.Fragment>
            {/*<AddTitleForm {...props} t={t} titleForm={LABEL_CATEGORY}/>*/}
            <TextInput source="name" fullWidth label={t('reactAdmin.categories.name')} validate={[required()]}/>
            {/*<TextInput source="code" fullWidth label={t('admin.label.code')}/>*/}
        </React.Fragment>
    )
}
