import { Notification } from 'react-admin';

const CustomNotification = props => {
    // console.log(props)
    return <Notification {...props} autoHideDuration={5000}
                         classesOverride={"notificationBackOfficeWrapper"}
                         className={"notificationBackoffice"}
                         // message={"dfdjfh"}
    />
};

export default CustomNotification;