import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {Grid, Switch, Tooltip, withWidth} from "@material-ui/core";
import userIcon from "../../../images/infomation/user.svg";
import mailIcon from "../../../images/infomation/mail.svg";
import phoneIcon from "../../../images/infomation/phone.svg";
import homeIcon from "../../../images/infomation/home.svg";
import addressIcon from "../../../images/infomation/address.svg";
import corporationIcon from "../../../images/infomation/corporation.svg";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import CustomInput from "../../../theme/CustomElements/CustomInput";
import RowFormColumn from "../../../theme/form/RowFormColumn";
import {MD, LG, XS, SM, TRUE_NUMBER, FALSE_NUMBER, GO_DOC, OBJECT_COMPANY} from "../../../constants/constants";
import {MONTSERRAT, NUNITO} from "../../../constants/fontCss";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CustomButton from "../../../theme/CustomElements/CustomButton";
import ErrorInput from "../../../theme/form/Alert/ErrorInput";
import {withRouter} from "react-router-dom";
import * as links from "../../../constants/links";
import AppCountryAutocomplete from "../../../theme/form/Autocomplete/AppCountryAutocomplete";
import countries from "i18n-iso-countries";
import AppAsyncPaginate from "../../../theme/form/Select/AppAsyncPaginate";
import { GET_LEASE_TYPE_LIST} from "../../../constants/apiSuffix";
import qs from "qs";
import axios from "axios";
import {getParamInUrl} from "../../../functions/functions";
import AppKeyboardDatePicker from "../../../theme/form/DatePicker/AppKeyboardDatePicker";
import CGV_FILE from "../../../docs/CGV.pdf";
import BusinessIcon from '@material-ui/icons/Business';
import {TAB_DATE, TAB_INFORMATION} from "./InformationPage";
import CustomSvg from "../../../theme/CustomElements/CustomSvg";
import PersonalIcon from "../../../images/request/personal.svg";
import CompanyIcon from "../../../images/request/company.svg";
import VatIcon from "../../../images/request/vat.svg";
import checkOwnerIcon from "../../../images/request/checkOwner.svg";
import builtForOver2YearsIcon from "../../../images/request/builtForOver2Years.svg";
import startingDateOfStayIcon from "../../../images/request/startingDateOfStay.svg";
import sentByAgencyIcon from "../../../images/request/sentByAgency.svg";
import additionalInfoIcon from "../../../images/request/additionalInfo.svg";

const styles = theme => ({
    information: {
        '& .rowFormColumn':{
            alignItems: 'flex-start'
        },
        '& .headerInfo': {
            fontFamily: MONTSERRAT,
            fontSize: '1.0625rem',
            fontWeight: 700,
            // lineHeight: 1.4,
            // height: '1.125rem',
            display: 'flex',
            flexDirection: 'row',
            color: '#001529',
            // padding: '1rem',
            marginBottom: '1rem',
            // '&:before': {
            //     borderBottom: '1px solid #E0E0E0',
            //     content: '""',
            //     flex: '1',
            // },
            // '&:after': {
            //     borderBottom: '1px solid #E0E0E0',
            //     content: '""',
            //     flex: '4',
            // },
            '& .titleHeaderContent': {
                padding: '0 0.3em',
                flexGrow: 1
            },
            '& .leftHeader': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                fontWeight: 500,
                fontSize: '0.85rem'
            }
        },
        '& .column': {
            paddingRight: '0.25rem',
        },
        '& .column1': {
            paddingRight: '0.5rem',
            [theme.breakpoints.between(XS, SM)]: {
                paddingRight: '0',
            },
            [theme.breakpoints.between(SM, MD)]: {
                paddingRight: '0.5rem',
            },

            [theme.breakpoints.between(MD, LG)]: {
                paddingRight: '0.5rem',
            },
        },
        '& .column2': {
            paddingLeft: '0.5rem',
            [theme.breakpoints.between(XS, SM)]: {
                paddingLeft: '0',
            },
            [theme.breakpoints.between(SM, MD)]: {
                paddingLeft: '0.5rem',
            },

            [theme.breakpoints.between(MD, LG)]: {
                paddingLeft: '0.5rem',
            },
        },
        '& .imgSrcLabelRowFormColumn': {
            // height: 25
        },
        '& .labelRowFormColumn': {
            fontFamily: MONTSERRAT,
            fontSize: '0.8125rem',
            // fontWeight: 700,
            color: '#3a3a3a',
        },
        '& .inputRowFormColumn': {
            fontFamily: MONTSERRAT,
            fontSize: '0.875rem',
            fontWeight: 400,
            '& span':{
                color: '#3a3a3a'
            }
        }
    },
    leftContent: {
        paddingRight: '1rem',
        '& .leftContentWrapper': {
            background: '#F5F5F5',
            borderRadius: 15,
            // padding: '1rem 0',
            width: '100%',
            '& .leftContent': {
                // padding: '1rem',
            }
        },
        [theme.breakpoints.between(SM, MD)]: {
            paddingRight: '0.5rem',
        },
        [theme.breakpoints.between(XS, SM)]: {
            paddingRight: 0
        },
        '& input':{
            background: '#FFFFFF'
        }
    },
    blockInfo: {
        background: '#F5F5F5',
        borderRadius: 15,
        padding: '2rem',
        [theme.breakpoints.between(XS, MD)]:{
            padding: '1rem',
        }
    },
    rightContent: {
        paddingLeft: '1rem',
        '& .rightContentWrapper': {
            background: '#F5F5F5',
            borderRadius: 15,
            padding: '1rem 0',
            width: '100%',
            '& .rightContent': {
                padding: '1rem',
            }

        },
        [theme.breakpoints.between(SM, MD)]: {
            paddingLeft: '0.5rem',
        },
        [theme.breakpoints.between(XS, SM)]: {
            paddingLeft: 0,
            marginTop: '1rem'
        },
        '& input':{
            background: '#FFFFFF'
        },
        '& .additionalInfo':{
            background: '#FFFFFF',
            border: '1px solid #E0E0E0',
            borderRadius: 6,
            // padding: '6px 10px 7px',
            fontSize: '0.875rem',
        }
    },
    appelVisio: {
        display: 'flex',
        color: '#001529',
        fontFamily: NUNITO,
        fontSize: '1rem',
        fontWeight: 700,
        padding: '0.3rem 0',
        '& img': {
            paddingRight: '0.5rem'
        }
    },
    question: {
        // '& .questionSentByAgency':{
        //     '& svg':{
        //         marginBottom: -3
        //     }
        // },
        '& .MuiFormLabel-root': {
            fontWeight: 700,
            fontFamily: NUNITO,
            fontSize: '1rem',
            color: '#001529',
            '& img': {
                paddingRight: '0.5rem'
            }
        },
        '& .MuiFormGroup-root': {
            paddingLeft: '1rem',
            '& span': {
                fontFamily: NUNITO,
                fontSize: '1rem',
                color: '#001034',
            }
        },
    },
    radioOwnerWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        paddingTop: 25,
        paddingBottom: '0.5rem',
        '& .radioOwner': {
            minHeight: 48,
            '& .MuiFormGroup-root': {
                // paddingLeft: '1rem',
                '& span': {
                    fontFamily: NUNITO,
                    fontSize: '1rem',
                    color: '#001034',
                }
            }
        }
    },
    customRadio:{
        marginLeft: '0 !important',
        '& > span':{
            padding: '0 5px !important'
        }
    },
    footerContentInformation: {
        padding: '1.5rem 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.between(XS, SM)]: {
            '& .btnSaveWrapper':{
                width: '100%',
            }
        },
        [theme.breakpoints.down(XS)]:{
            '& .btnSaveWrapper':{
                margin: '0 auto',
                justifyContent: 'center!important',
                padding: '1rem'
            }
        },
        '& .checkboxAccept': {
            display: 'flex',
            alignItems: 'flex-start',
            '& .labelCheckBox': {
                paddingTop: 12,
                fontFamily: NUNITO,
                fontSize: '0.875rem',
                color: '#001034',
            },
            '& .MuiCheckbox-root path':{
                color: '#0000ff !important',
            },
        },
        '& .certificate': {
            color: '#101dff',
            display: "block"
        },
        '& .btnSaveWrapper':{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginLeft: 'auto'
        }
    },
    btnAddInformation: {
        background: '#0000FF !important',
        borderRadius: 15,
        height: 50,
        width: 145,
    },
    leaseType:{
        marginTop: 5,
        '&.errorInput':{
            '& .selectPaginate__control': {
                border: '1px solid #d01631'
            }
        },
        '& .selectPaginate__control':{
            borderRadius: 6
        },
        "& .selectPaginate__placeholder":{
            color: "hsl(0,0%,50%)"
        }
    },
    startDateOfStay: {
        '& .inputBlockDatePicker':{
            borderRadius: 6
        }
    },
    startDateOfStayDetail:{
        '& .Mui-disabled':{
            color: 'inherit'
        }
    },
    switchRole: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        padding: '1rem 0',
        flexWrap: "wrap",
        '& .optionSwitch': {
            padding: '0 1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
                '& path': {
                    fill: '#bababa'
                }
            },
            '& .label': {
                paddingLeft: '0.5rem',
                fontWeight: 700,
                color: '#bababa',
            
            },

        }
    },
    lineObjectCompany: {
        borderBottom: '1px dashed #707070',
        paddingBottom: '1rem',
        marginBottom: '1rem',
        width: "100%",
        height: "1rem"
    },
    isThereAnElevator: {
        '& span': {
            fontSize: '0.8rem',
            padding: '0 0 0 5px'
        }
    },
    itemRowWrapper:{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%',
        flexWrap: "wrap",
        padding: '0.5rem 0',
    },

    lodgingItemLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingRight: '1rem',
        padding: '0.25rem 0',
        '& .icon': {
            width: 30,
            marginRight: 8
        },
        '& .title': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: 'calc(100% - 36px)',
            fontFamily: MONTSERRAT,
            fontSize: '0.8125rem',
            color: '#3a3a3a',
            fontWeight: 500,
            padding: '0.25rem 0',
        }
    },
    lodgingItemInput: {
        padding: '0.5rem 0',
        '& div, span':{
            fontFamily: MONTSERRAT,
            fontSize: '0.8125rem',
            color: '#3a3a3a',
        }
    },
    partnerCode:{
        marginTop: "0.5rem",
    },
    optionalField:{
        color: "#6b9bbf"
    }
});

export const OWNER = "owner"
export const TENANT = "tenant"

const BUILT_FOR_OVER_2_YEARS_YES = 1;
const BUILT_FOR_OVER_2_YEARS_NO = 2;
const BUILT_FOR_OVER_2_YEARS_DONT_KNOW = 3;


const AntSwitch = withStyles((theme) => ({
    root: {
        width: 36,
        height: 16,
        padding: 0,
        display: 'flex',
        transform: "rotate(180deg)"
    },
    switchBase: {
        padding: 2,
        color: '#fff',
        '&$checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + $track': {
                opacity: 1,
                backgroundColor: '#3a3a3a',
                borderColor: '#3a3a3a',
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: '1px solid #3a3a3a',
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: '#3a3a3a',
    },
    checked: {}
}))(Switch);

class Information extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    async loadLeaseTypeOptions(search, loadedOptions, {page}) {
        let params = {filters: {name: search}, page: page};
        const url = GET_LEASE_TYPE_LIST + "?" + qs.stringify(params)
        const response = await axios.get(url);
        const data = response.data;
        let dataItems = [];
        let hasMore = false;
        if (data.hasOwnProperty('items') && data.items) {
            dataItems = data.items;
            hasMore = data.currentPage < data.nbPages;
        } else if (data.hasOwnProperty('hydra:member')) {
            dataItems = data["hydra:member"];
            const hydraView = data["hydra:view"];
            const paramsCurrent = getParamInUrl(hydraView["@id"]);
            const paramsPageLast = getParamInUrl(hydraView["hydra:last"]);
            const currentPageList = paramsCurrent.hasOwnProperty("page") ? paramsCurrent.page : 1;
            const nbPagesList = paramsPageLast.hasOwnProperty("page") ? paramsPageLast.page : 1;
            hasMore = currentPageList < nbPagesList;

        }
        const options = dataItems.map((item) => {
            return {label: item.name, value: item.id, ...item};
        });

        return {
            options: options,
            hasMore: hasMore,
            additional: {
                page: page + 1,
            },
        };
    }

    onChangeRegisteredObject = (event) => {
        this.props.handleChange(event ,"registeredObject")
    }

    renderLodgingItem = (props) => {
        const {
            classes,
            t
        } = this.props;
        const {
            icon,
            title,
            input,
            className,
            error,
            optional
        } = props;

        return (
            <div className={classes.itemRowWrapper}>
                <div className={classes.lodgingItemLabel}>
                    <div className="icon">
                        <CustomSvg
                            svgProps={{
                                src: icon,
                            }}
                        />
                    </div>
                    <div className="title">{title}{!!optional && <span className={classes.optionalField}> ({t("label.optional_field")})</span>}</div>
                </div>
                <div className={classes.lodgingItemInput + " " + (className ? className : "")}>
                    {input}
                    {error &&<ErrorInput textValidate={error}/>}
                </div>
            </div>
        )
    }

    render() {
        const {classes, t, valueForm,errors, pageEditNotLogin, paymentRequest, isModalShowClientDetail, location, isLoading, initialRequest} = this.props;
        const certificate = process.env.REACT_APP_URL_CERTIFICATE_FORM_INFORMATION;
        const pathPage = location && location.pathname ? location.pathname : "";
        const isPageDetail = pathPage.replace(/[0-9]/g, '') === links.REQUEST_INFORMATION_DETAIL.replace(":id", '') || isModalShowClientDetail
        let errorsField =errors? {...errors}:{};
        const statusCode = initialRequest && initialRequest.status && initialRequest.status.statusType ? initialRequest.status.statusType.code : null;
        return (
            <div className={classes.information}>
                <Grid container item lg={12}>
                    <Grid container item sm={12} md={4} lg={4} className={classes.leftContent}>
                        <div className={classes.blockInfo + " leftContentWrapper"}>
                            <div className="headerInfo">
                                <div className={"titleHeaderContent"}>{t("information.formTab1.userAccount")}</div>
                            </div>
                            <div className={"leftContent"}>
                                <Grid container item lg={12}>
                                    <Grid container item lg={12}>
                                        <div className={classes.switchRole}>
                                            <div className={"optionSwitch" + (valueForm.registeredObject !== OBJECT_COMPANY ? " active": "")}>
                                                <CustomSvg
                                                    svgProps={{
                                                        src: PersonalIcon,
                                                    }}
                                                    // scale="0.3"
                                                />
                                                <span className="label">
                                                    {t("information.formTab1.personal")}
                                                </span>
                                            </div>
                                            <AntSwitch checked={valueForm.registeredObject !== OBJECT_COMPANY} onChange={this.onChangeRegisteredObject}/>
                                            <div className={"optionSwitch" + (valueForm.registeredObject === OBJECT_COMPANY ? " active": "")}>
                                                <CustomSvg
                                                    svgProps={{
                                                        src: CompanyIcon,
                                                    }}
                                                    // scale="0.3"
                                                />
                                                <span className="label">
                                                    {t("information.formTab1.company")}
                                                </span>
                                            </div>
                                        </div>
                                    </Grid>
                                    {valueForm.registeredObject === OBJECT_COMPANY && <>
                                        <Grid item lg={12}>
                                            <RowFormColumn
                                                label={t("information.formTab1.socialReason")}
                                                imgSrcLabel={CompanyIcon}
                                                input={
                                                    <CustomInput
                                                        name="companyName"
                                                        value={valueForm.companyName}
                                                        onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"companyName")}}}
                                                        validate={errorsField.companyName}
                                                        disabled={isPageDetail && !valueForm.companyName}
                                                        className={isPageDetail ? 'inputDetail' : ""}
                                                    />
                                                }
                                                textValidate={errorsField.companyName}
                                            />
                                        </Grid>
                                        <Grid item lg={12}>
                                            <RowFormColumn
                                                label={t("information.formTab1.vat")}
                                                imgSrcLabel={VatIcon}
                                                input={
                                                    <CustomInput
                                                        name="companyTaxCode"
                                                        value={valueForm.companyTaxCode}
                                                        onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"companyTaxCode")}}}
                                                        validate={errorsField.companyTaxCode}
                                                        disabled={isPageDetail && !valueForm.companyTaxCode}
                                                        className={isPageDetail ? 'inputDetail' : ""}
                                                    />
                                                }
                                                textValidate={errorsField.companyTaxCode}
                                            />
                                        </Grid>
                                        <div className={classes.lineObjectCompany}>

                                        </div>
                                    </>}
                                    <Grid container item xs={12} lg={12}>
                                    <Grid container item xs={12} sm={6} md={6} lg={6} className={"column1"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.firstName")}
                                            imgSrcLabel={userIcon}
                                            input={
                                                <CustomInput
                                                    name="firstName"
                                                    value={valueForm.firstName}
                                                    onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"firstName")}}}
                                                    validate={errorsField.firstName}
                                                    disabled={isPageDetail && !valueForm.firstName}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.firstName}
                                        />
                                    </Grid>
                                    <Grid container item xs={12} sm={6} md={6} lg={6} className={"column2"}>
                                        <RowFormColumn
                                            imgSrcLabel={userIcon}
                                            label={t("information.formTab1.lastName")}
                                            input={
                                                <CustomInput
                                                    name="lastName"
                                                    value={valueForm.lastName}
                                                    onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"lastName")}}}
                                                    validate={errorsField.lastName}
                                                    disabled={isPageDetail && !valueForm.lastName}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.lastName}
                                        />
                                    </Grid>
                                </Grid>
                                    <RowFormColumn
                                        label={t("information.formTab1.email")}
                                        imgSrcLabel={mailIcon}
                                        input={
                                            <CustomInput
                                                name="email"
                                                value={valueForm.email}
                                                disabled={true}
                                                onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"email")}}}
                                                validate={errorsField.email}
                                            />
                                        }
                                        textValidate={errorsField.email}
                                    />
                                    <RowFormColumn
                                        label={t("information.formTab1.phoneNumber")}
                                        imgSrcLabel={phoneIcon}
                                        input={
                                            <CustomInput
                                                name="phoneNumber"
                                                value={valueForm.phoneNumber}
                                                onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"phoneNumber")}}}
                                                validate={errorsField.phoneNumber}
                                                disabled={isPageDetail && !valueForm.phoneNumber}
                                                className={isPageDetail ? 'inputDetail' : ""}
                                            />
                                        }
                                        textValidate={errorsField.phoneNumber}
                                    />
                                    {pageEditNotLogin && !valueForm.hiddenPassword && !valueForm.userInfoId &&
                                    <RowFormColumn
                                        label={t("information.formTab1.password")}
                                        imgSrcLabel={mailIcon}
                                        input={
                                            <CustomInput
                                                type={"password"}
                                                name="password"
                                                value={valueForm.password}
                                                onChange={(e)=>this.props.handleChange(e ,"password")}
                                                validate={errorsField.password}
                                            />
                                        }
                                        textValidate={errorsField.password}
                                    />}
                                    {pageEditNotLogin && !valueForm.hiddenPassword && !valueForm.userInfoId &&
                                    <RowFormColumn
                                        label={t("information.formTab1.confirmPassword")}
                                        imgSrcLabel={phoneIcon}
                                        input={
                                            <CustomInput
                                                type={"password"}
                                                name="confirmPassword"
                                                value={valueForm.confirmPassword}
                                                onChange={(e) => this.props.handleChange(e, "confirmPassword")}
                                                validate={errorsField.confirmPassword}
                                            />
                                        }
                                        textValidate={errorsField.confirmPassword}
                                    />
                                    }
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                    <Grid container item sm={12} md={8} lg={8} className={classes.rightContent}>
                        <Grid xs={12} className={classes.blockInfo} style={{
                            marginBottom: '1rem'
                        }}>
                            <div className="headerInfo">
                                <div className={"titleHeaderContent"}>{t("information.formTab1.labelAddressRepair")}</div>
                            </div>
                            <div className={"rightContent"}>
                                <Grid container lg={12}>
                                    <Grid container item sm={6} md={6} lg={6} xl={6} className={"column"}>
                                        <RowFormColumn
                                            imgSrcLabel={addressIcon}
                                            label={t("information.formTab1.addressDescription")}
                                            input={
                                                <CustomInput
                                                    name="addressDescription"
                                                    value={valueForm.addressDescription}
                                                    onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"addressDescription")}}}
                                                    validate={errorsField.addressDescription}
                                                    disabled={isPageDetail && !valueForm.addressDescription}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.addressDescription}
                                        />
                                    </Grid>
                                    <Grid container item sm={6} md={6} lg={2} xl={2} className={"column"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.addressCity")}
                                            imgSrcLabel={corporationIcon}
                                            input={
                                                <CustomInput
                                                    name="addressCity"
                                                    value={valueForm.addressCity}
                                                    onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"addressCity")}}}
                                                    validate={errorsField.addressCity}
                                                    disabled={isPageDetail && !valueForm.addressCity}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.addressCity}
                                        />
                                    </Grid>
                                    <Grid container item sm={6} md={6} lg={4} xl={4} className={"column"}>
                                        <RowFormColumn
                                            imgSrcLabel={homeIcon}
                                            label={t("information.formTab1.addressPostalCode")}
                                            input={
                                                <CustomInput
                                                    name="addressPostalCode"
                                                    value={valueForm.addressPostalCode}
                                                    onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"addressPostalCode")}}}
                                                    validate={errorsField.addressPostalCode}
                                                    disabled={isPageDetail &&! valueForm.addressPostalCode}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.addressPostalCode}
                                        />
                                    </Grid>
                                    <Grid container item sm={6} md={6} lg={2} xl={2} className={"column"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.floorNumber")}
                                            input={
                                                <CustomInput
                                                    name="floorNumber"
                                                    value={valueForm.floorNumber}
                                                    onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"floorNumber")}}}
                                                    validate={errorsField.floorNumber}
                                                    disabled={isPageDetail &&! valueForm.floorNumber}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.floorNumber}
                                        />
                                    </Grid>
                                    <Grid container item sm={6} md={6} lg={2} xl={2} className={"column"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.isThereAnElevator")}
                                            input={
                                                <RadioGroup
                                                    className={classes.isThereAnElevator}
                                                    column aria-label="isThereAnElevator" name="isThereAnElevator"
                                                            value={valueForm.isThereAnElevator===null || valueForm.isThereAnElevator===undefined?undefined:(valueForm.isThereAnElevator==TRUE_NUMBER || valueForm.isThereAnElevator===true?TRUE_NUMBER:FALSE_NUMBER)}
                                                            onChange={(e) => {if(!isPageDetail){this.props.handleChange(e, "isThereAnElevator")}}}>
                                                    <FormControlLabel value={TRUE_NUMBER}  className={classes.customRadio} control={<Radio color="#000000"/>}
                                                                      label={t("information.formTab1.yes")}/>
                                                    <FormControlLabel value={FALSE_NUMBER}   className={classes.customRadio} control={<Radio color="#000000"/>}
                                                                      label={t("information.formTab1.no")}/>
                                                </RadioGroup>
                                            }
                                            textValidate={errorsField.isThereAnElevator}
                                        />
                                    </Grid>
                                    <Grid container item sm={6} md={6} lg={2} xl={2} className={"column"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.doorPassword")}
                                            input={
                                                <CustomInput
                                                    name="doorPassword"
                                                    value={valueForm.doorPassword}
                                                    onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"doorPassword")}}}
                                                    validate={errorsField.doorPassword}
                                                    disabled={isPageDetail &&! valueForm.doorPassword}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.doorPassword}
                                        />
                                    </Grid>
                                    <Grid container item sm={6} md={6} lg={6} xl={6} className={"column"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.addressCountry")}
                                            imgSrcLabel={addressIcon}
                                            input={
                                                isPageDetail ?
                                                    <CustomInput
                                                        name="addressCountry"
                                                        value={countries.getName(valueForm.addressCountry, this.props.t('translations.lang'))}
                                                    /> :
                                                    isLoading ? <div></div> :<AppCountryAutocomplete
                                                        defaultValue={valueForm.addressCountry ? {
                                                            code: valueForm.addressCountry,
                                                            label: countries.getName(valueForm.addressCountry, this.props.t('translations.lang'))
                                                        } : undefined}
                                                        onChange={(event, value) => {if(!isPageDetail){this.props.handleChange(value, "addressCountry")}}}
                                                        // className={classes.countryField}
                                                        inputPlaceHolder={""}
                                                    />
                                            }
                                            textValidate={errorsField.addressCountry}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid xs={12} className={classes.blockInfo} style={{
                            marginBottom: '1rem'
                        }}>
                            <div className="headerInfo">
                                <div className={"titleHeaderContent"}>{t("information.formTab1.labelAddressBilling")}</div>
                                <div className="leftHeader">
                                    <div>
                                        {t("information.formTab1.billingAddressSameAsRepairAddress")}
                                    </div>
                                    <Checkbox
                                        checked={!valueForm.otherBillingAddress}
                                        onChange={(event) => {if(!isPageDetail){this.props.handleChange(event, 'otherBillingAddress')}}}
                                        color="#000000"
                                    />
                                </div>
                            </div>
                            {valueForm.otherBillingAddress ? <div className={"rightContent"}>
                                <Grid container lg={12}>
                                    <Grid container item sm={6} md={6} lg={6} className={"column1"}>
                                        <RowFormColumn
                                            imgSrcLabel={addressIcon}
                                            label={t("information.formTab1.addressDescription")}
                                            input={
                                                <CustomInput
                                                    name="billingAddressDescription"
                                                    value={valueForm.billingAddressDescription}
                                                    onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"billingAddressDescription")}}}
                                                    validate={errorsField.billingAddressDescription}
                                                    disabled={isPageDetail && !valueForm.billingAddressDescription}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.billingAddressDescription}
                                        />
                                    </Grid>
                                    <Grid container item sm={6} md={6} lg={6} className={"column2"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.addressCity")}
                                            imgSrcLabel={corporationIcon}
                                            input={
                                                <CustomInput
                                                    name="billingAddressCity"
                                                    value={valueForm.billingAddressCity}
                                                    onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"billingAddressCity")}}}
                                                    validate={errorsField.billingAddressCity}
                                                    disabled={isPageDetail && !valueForm.billingAddressCity}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.billingAddressCity}
                                        />
                                    </Grid>
                                    <Grid container item sm={6} md={6} lg={6} className={"column1"}>
                                        <RowFormColumn
                                            imgSrcLabel={homeIcon}
                                            label={t("information.formTab1.addressPostalCode")}
                                            input={
                                                <CustomInput
                                                    name="billingAddressPostalCode"
                                                    value={valueForm.billingAddressPostalCode}
                                                    onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"billingAddressPostalCode")}}}
                                                    validate={errorsField.billingAddressPostalCode}
                                                    disabled={isPageDetail &&! valueForm.billingAddressPostalCode}
                                                    className={isPageDetail ? 'inputDetail' : ""}
                                                />
                                            }
                                            textValidate={errorsField.billingAddressPostalCode}
                                        />
                                    </Grid>
                                    <Grid container item sm={6} md={6} lg={6} className={"column2"}>
                                        <RowFormColumn
                                            label={t("information.formTab1.addressCountry")}
                                            imgSrcLabel={addressIcon}
                                            input={
                                                isPageDetail ?
                                                    <CustomInput
                                                        name="billingAddressCountry"
                                                        value={countries.getName(valueForm.billingAddressCountry, this.props.t('translations.lang'))}
                                                    /> :
                                                    isLoading ? <div></div> :<AppCountryAutocomplete
                                                        defaultValue={valueForm.billingAddressCountry ? {
                                                            code: valueForm.billingAddressCountry,
                                                            label: countries.getName(valueForm.billingAddressCountry, this.props.t('translations.lang'))
                                                        } : undefined}
                                                        onChange={(event, value) => {if(!isPageDetail){this.props.handleChange(value, "billingAddressCountry")}}}
                                                        // className={classes.countryField}
                                                        inputPlaceHolder={""}
                                                    />
                                            }
                                            textValidate={errorsField.billingAddressCountry}
                                        />
                                    </Grid>
                                </Grid>
                            </div> : ''}
                        </Grid>
                        <Grid xs={12} className={classes.blockInfo}>
                            <div className="headerInfo">
                                <div className={"titleHeaderContent"}>{t("information.formTab1.logement")}</div>
                            </div>
                            <div className={"rightContent"}>
                                    {/*<Grid container item sm={6} md={6} lg={6} className={"column1"}>*/}
                                    {/*    <RowFormColumn*/}
                                    {/*        label={t("information.formTab1.constructionYear")}*/}
                                    {/*        imgSrcLabel={timeBlueBlackIcon}*/}
                                    {/*        input={*/}
                                    {/*            isPageDetail ?*/}
                                    {/*                <CustomInput*/}
                                    {/*                    name="constructionYear"*/}
                                    {/*                    value={valueForm.constructionYear}*/}
                                    {/*                    disabled*/}
                                    {/*                /> :*/}
                                    {/*            <CustomInput*/}
                                    {/*                name="constructionYear"*/}
                                    {/*                value={valueForm.constructionYear}*/}
                                    {/*                onChange={(e)=>this.props.handleChange(e ,"constructionYear")}*/}
                                    {/*                type={'number'}*/}
                                    {/*                validate={errorsField.constructionYear}*/}
                                    {/*                placeholder={this.props.t("formatDate.yyyy")}*/}
                                    {/*            />*/}
                                    {/*        }*/}
                                    {/*        textValidate={errorsField.constructionYear}*/}
                                    {/*    />*/}
                                    {/*</Grid>*/}
                                    {
                                        this.renderLodgingItem({
                                            icon: checkOwnerIcon,
                                            title: t("information.formTab1.situation"),
                                            error: errorsField.checkOwner,
                                            // className: "ownerWrapper",
                                            input: <div><FormControl component="fieldset" className={"radioOwner"}>
                                                <RadioGroup row aria-label="position" name="position"
                                                            value={valueForm.checkOwner}
                                                            onChange={(e,value) => {
                                                                if (!isPageDetail) {
                                                                    this.props.handleChange(e, "checkOwner")
                                                                }
                                                            }}>
                                                    <FormControlLabel value={OWNER}
                                                                      className={classes.customRadio}
                                                                      control={<Radio color="#000000"/>}
                                                                      label={t("information.formTab1.owner")}/>
                                                    <FormControlLabel value={TENANT}
                                                                      className={classes.customRadio}
                                                                      control={<Radio color="#000000"/>}
                                                                      label={t("information.formTab1.tenant")}/>
                                                </RadioGroup>
                                                {/*<ErrorInput textValidate={errorsField.checkOwner}/>*/}
                                            </FormControl>
                                                {valueForm.checkOwner === TENANT &&
                                                <div className={`${classes.leaseType} ${errorsField.leaseType ? "errorInput" : ''}`}>
                                                    {isPageDetail ?
                                                        <CustomInput
                                                            name="leaseType"
                                                            value={valueForm.leaseType && valueForm.leaseType.label ? valueForm.leaseType.label : ""}
                                                            disabled
                                                            placeholder={t("information.formTab1.leaseType")}
                                                        /> :
                                                        <AppAsyncPaginate
                                                            classNamePrefix={"selectPaginate"}
                                                            value={valueForm.leaseType}
                                                            loadOptions={(search, loadedOptions, {page}) => this.loadLeaseTypeOptions(search, loadedOptions, {page})}
                                                            onChange={(value) => {if(!isPageDetail){this.props.handleChange(value, "leaseType")}}}
                                                            debounceTimeout={500}
                                                            additional={{
                                                                page: 1
                                                            }}
                                                            placeholder={t("information.formTab1.leaseType")}
                                                            // closeMenuOnSelect={false}
                                                        />
                                                    }
                                                    <ErrorInput textValidate={errorsField.leaseType}/>
                                                </div>
                                                }
                                            </div>
                                        })
                                    }
                                    {/*{valueForm.checkOwner === TENANT &&*/}
                                    {/*<>*/}
                                    {/*    {this.renderLodgingItem({*/}
                                    {/*        icon: checkOwnerIcon,*/}
                                    {/*        title: t("information.formTab1.leaseType"),*/}
                                    {/*        input: <RowFormColumn*/}
                                    {/*            className={`${classes.leaseType} ${errorsField.leaseType? "errorInput":''}`}*/}
                                    {/*            input={*/}
                                    {/*                isPageDetail ?*/}
                                    {/*                    <CustomInput*/}
                                    {/*                        name="leaseType"*/}
                                    {/*                        value={valueForm.leaseType && valueForm.leaseType.label ? valueForm.leaseType.label : ""}*/}
                                    {/*                        disabled*/}
                                    {/*                    /> :*/}
                                    {/*                    <AppAsyncPaginate*/}
                                    {/*                        classNamePrefix={"selectPaginate"}*/}
                                    {/*                        value={valueForm.leaseType}*/}
                                    {/*                        loadOptions={(search, loadedOptions, {page}) => this.loadLeaseTypeOptions(search, loadedOptions, {page})}*/}
                                    {/*                        onChange={(value) => this.props.handleChange(value, "leaseType")}*/}
                                    {/*                        debounceTimeout={500}*/}
                                    {/*                        additional={{*/}
                                    {/*                            page: 1*/}
                                    {/*                        }}*/}
                                    {/*                        // closeMenuOnSelect={false}*/}
                                    {/*                    />*/}
                                    {/*            }*/}
                                    {/*            textValidate={errorsField.leaseType}*/}
                                    {/*        />,*/}
                                    {/*        className: `${classes.leaseType} ${errorsField.leaseType? "errorInput":''}`*/}
                                    {/*    })}*/}
                                    {/*</>*/}
                                    {/*}*/}
                                    {
                                        this.renderLodgingItem({
                                            icon: builtForOver2YearsIcon,
                                            title: t("information.formTab1.buildingHasBeenBuiltForOver2Years"),
                                            error: errorsField.builtForOver2Years,
                                            input: <React.Fragment>
                                                <RadioGroup row value={valueForm.builtForOver2Years}
                                                            aria-label="builtForOver2Years" name="builtForOver2Years"
                                                            onChange={(e) => {
                                                                if (!isPageDetail) {
                                                                    this.props.handleChange(e, "builtForOver2Years")
                                                                }
                                                            }}>
                                                    <FormControlLabel value={BUILT_FOR_OVER_2_YEARS_YES}
                                                                      className={classes.customRadio}
                                                                      control={<Radio
                                                                          checked={valueForm.builtForOver2Years == BUILT_FOR_OVER_2_YEARS_YES} color="#000000"/>}
                                                                      label={t("information.formTab1.olderThan2Years")}/>
                                                    <FormControlLabel value={BUILT_FOR_OVER_2_YEARS_NO}
                                                                      className={classes.customRadio}
                                                                      control={<Radio
                                                                          checked={valueForm.builtForOver2Years == BUILT_FOR_OVER_2_YEARS_NO} color="#000000"/>}
                                                                      label={t("information.formTab1.lessThan2Years")}/>
                                                    <FormControlLabel value={BUILT_FOR_OVER_2_YEARS_DONT_KNOW}
                                                                      className={classes.customRadio}
                                                                      control={<Radio
                                                                          checked={valueForm.builtForOver2Years == BUILT_FOR_OVER_2_YEARS_DONT_KNOW} color="#000000"/>}
                                                                      label={t("information.formTab1.dontKnow")}/>
                                                </RadioGroup>
                                                {/*<ErrorInput textValidate={errorsField.builtForOver2Years}/>*/}
                                            </React.Fragment>
                                        })
                                    }

                                    {/*<Grid container item sm={6} md={6} lg={6}*/}
                                    {/*      className={`column1 ${classes.radioOwnerWrapper}`}>*/}
                                    {/*    */}
                                    {/*</Grid>*/}
                                    {/*{valueForm.checkOwner === TENANT &&*/}
                                    {/*<Grid container item sm={6} md={6} lg={6} className={"column2"}>*/}
                                    {/*    <RowFormColumn*/}
                                    {/*        label={t("information.formTab1.leaseType")}*/}
                                    {/*        // imgSrcLabel={timeBlueBlackIcon}*/}
                                    {/*        className={`${classes.leaseType} ${errorsField.leaseType? "errorInput":''}`}*/}
                                    {/*        input={*/}
                                    {/*            isPageDetail ?*/}
                                    {/*                <CustomInput*/}
                                    {/*                    name="leaseType"*/}
                                    {/*                    value={valueForm.leaseType && valueForm.leaseType.label ? valueForm.leaseType.label : ""}*/}
                                    {/*                    disabled*/}
                                    {/*                /> :*/}
                                    {/*                <AppAsyncPaginate*/}
                                    {/*                    classNamePrefix={"selectPaginate"}*/}
                                    {/*                    value={valueForm.leaseType}*/}
                                    {/*                    loadOptions={(search, loadedOptions, {page}) => this.loadLeaseTypeOptions(search, loadedOptions, {page})}*/}
                                    {/*                    onChange={(value) => this.props.handleChange(value, "leaseType")}*/}
                                    {/*                    debounceTimeout={500}*/}
                                    {/*                    additional={{*/}
                                    {/*                        page: 1*/}
                                    {/*                    }}*/}
                                    {/*                    // closeMenuOnSelect={false}*/}
                                    {/*                />*/}
                                    {/*        }*/}
                                    {/*        textValidate={errorsField.leaseType}*/}
                                    {/*    />*/}
                                    {/*</Grid>}*/}
                                    {/*<Grid container item sm={12}>*/}
                                    {/*    <FormControl component="fieldset" className={classes.question}>*/}
                                    {/*        <FormLabel component="legend"> <img src={timeBlueBlackIcon}*/}
                                    {/*                                            alt={""}/> {t("information.formTab1.buildingHasBeenBuiltForOver2Years")}*/}
                                    {/*        </FormLabel>*/}
                                    {/*        <RadioGroup row value={valueForm.builtForOver2Years}*/}
                                    {/*                    aria-label="builtForOver2Years" name="builtForOver2Years"*/}
                                    {/*                    onChange={(e) => {*/}
                                    {/*                        console.log(e.target.value)*/}
                                    {/*                        if (!isPageDetail) {*/}
                                    {/*                            this.props.handleChange(e, "builtForOver2Years")*/}
                                    {/*                        }*/}
                                    {/*                    }}>*/}
                                    {/*            <FormControlLabel value={BUILT_FOR_OVER_2_YEARS_YES}*/}
                                    {/*                              className={valueForm.builtForOver2Years == BUILT_FOR_OVER_2_YEARS_YES ? "" : "customRadio"}*/}
                                    {/*                              control={<Radio*/}
                                    {/*                                  checked={valueForm.builtForOver2Years == BUILT_FOR_OVER_2_YEARS_YES}/>}*/}
                                    {/*                              label={t("information.formTab1.yes")}/>*/}
                                    {/*            <FormControlLabel value={BUILT_FOR_OVER_2_YEARS_NO}*/}
                                    {/*                              className={valueForm.builtForOver2Years == BUILT_FOR_OVER_2_YEARS_NO ? "" : "customRadio"}*/}
                                    {/*                              control={<Radio*/}
                                    {/*                                  checked={valueForm.builtForOver2Years == BUILT_FOR_OVER_2_YEARS_NO}/>}*/}
                                    {/*                              label={t("information.formTab1.no")}/>*/}
                                    {/*            <FormControlLabel value={BUILT_FOR_OVER_2_YEARS_DONT_KNOW}*/}
                                    {/*                              className={valueForm.builtForOver2Years == BUILT_FOR_OVER_2_YEARS_DONT_KNOW ? "" : "customRadio"}*/}
                                    {/*                              control={<Radio*/}
                                    {/*                                  checked={valueForm.builtForOver2Years == BUILT_FOR_OVER_2_YEARS_DONT_KNOW}/>}*/}
                                    {/*                              label={t("information.formTab1.dontKnow")}/>*/}
                                    {/*        </RadioGroup>*/}
                                    {/*        <ErrorInput textValidate={errorsField.builtForOver2Years}/>*/}
                                    {/*    </FormControl>*/}
                                    {/*</Grid>*/}
                                    {
                                        this.renderLodgingItem({
                                            icon: startingDateOfStayIcon,
                                            title: t("information.formTab1.startingDateOfStay"),
                                            // className:{`${classes.startDateOfStay} ${isPageDetail? classes.startDateOfStayDetail : ''}`}
                                            error: errorsField.startingDateOfStay,
                                            input:  <AppKeyboardDatePicker
                                                value={valueForm.startingDateOfStay}
                                                onChange={(value) =>  {if(!isPageDetail){this.props.handleChange(value, "startingDateOfStay")}}}
                                                emptyLabel={''}
                                                // invalidDateMessage={""}
                                                disabled={!!isPageDetail}
                                            />
                                        })
                                    }
                                    {/*<Grid container item sm={12} md={6} lg={6}>*/}
                                    {/*    <RowFormColumn*/}
                                    {/*        label={t("information.formTab1.startingDateOfStay")}*/}
                                    {/*        imgSrcLabel={startingDateOfStayIcon}*/}
                                    {/*        className={`${classes.startDateOfStay} ${isPageDetail? classes.startDateOfStayDetail : ''}`}*/}
                                    {/*        input={*/}
                                    {/*            <AppKeyboardDatePicker*/}
                                    {/*                value={valueForm.startingDateOfStay}*/}
                                    {/*                onChange={(value) =>  this.props.handleChange(value, "startingDateOfStay")}*/}
                                    {/*                emptyLabel={''}*/}
                                    {/*                // invalidDateMessage={""}*/}
                                    {/*                disabled={!!isPageDetail}*/}
                                    {/*            />*/}
                                    {/*        }*/}
                                    {/*        textValidate={errorsField.startingDateOfStay}*/}
                                    {/*    />*/}
                                    {/*</Grid>*/}
                                    {/*{!pageEditNotLogin &&*/}
                                    {
                                        this.renderLodgingItem({
                                            icon: sentByAgencyIcon,
                                            title: t("information.formTab1.sentByAgency"),
                                            // error: errorsField.sentByAgency,
                                            input: <React.Fragment>
                                                <div>
                                                    <RadioGroup row aria-label="position" name="position" value={valueForm.sentByAgency}
                                                                onChange={(e) => {
                                                                    if (!isPageDetail) {
                                                                        this.props.handleChange(e, "sentByAgency")
                                                                    }
                                                                }}>
                                                        <FormControlLabel value={TRUE_NUMBER}
                                                                          className={classes.customRadio}
                                                                          control={<Radio color="#000000"/>}
                                                                          label={t("information.formTab1.yes")}/>
                                                        <FormControlLabel value={FALSE_NUMBER}
                                                                          className={classes.customRadio}
                                                                          control={<Radio color="#000000"/>}
                                                                          label={t("information.formTab1.no")}/>
                                                    </RadioGroup>
                                                    <ErrorInput textValidate={errorsField.sentByAgency}/>
                                                </div>
                                                {
                                                    valueForm.sentByAgency === TRUE_NUMBER &&
                                                    <div className={classes.partnerCode}>
                                                        <Tooltip title={t("information.formTab1.questionRealEstateCenterCode")}>
                                                            <CustomInput
                                                                onChange={(event) => {
                                                                    if (!isPageDetail) {
                                                                        this.props.handleChange(event, "realEstateCenterCode")
                                                                    }
                                                                }}
                                                                name="questionRealEstateCenterCode"
                                                                value={valueForm.realEstateCenterCode}
                                                                disabled={!!isPageDetail}
                                                                placeholder={t("information.formTab1.questionRealEstateCenterCode")}
                                                                validate={errorsField.realEstateCenterCode}

                                                            />
                                                        </Tooltip>
                                                        <ErrorInput textValidate={errorsField.realEstateCenterCode}/>
                                                    </div>
                                                }
                                            </React.Fragment>
                                        })
                                    }
                                    {/*<FormControl component="fieldset" className={classes.question}>*/}
                                    {/*    <FormLabel component="legend"> <span className={"questionSentByAgency"}><BusinessIcon/> </span> {t("information.formTab1.sentByAgency")}*/}
                                    {/*        /!*<img src={cardUserIcon}*!/*/}
                                    {/*        /!*                                    alt={""}/> {t("information.formTab1.sentByAgency")}*!/*/}
                                    {/*    </FormLabel>*/}
                                    {/*    <RadioGroup row aria-label="position" name="position"*/}
                                    {/*                value={valueForm.sentByAgency}*/}
                                    {/*                onChange={(e) => {if(!isPageDetail){this.props.handleChange(e, "sentByAgency")}}}>*/}
                                    {/*        <FormControlLabel value={TRUE_NUMBER}  className={valueForm.sentByAgency === TRUE_NUMBER ? "" :"customRadio"} control={<Radio color="primary"/>}*/}
                                    {/*                          label={t("information.formTab1.yes")}/>*/}
                                    {/*        <FormControlLabel value={FALSE_NUMBER}   className={valueForm.sentByAgency === FALSE_NUMBER ? "" :"customRadio"} control={<Radio color="primary"/>}*/}
                                    {/*                          label={t("information.formTab1.no")}/>*/}
                                    {/*    </RadioGroup>*/}
                                    {/*    <ErrorInput textValidate={errorsField.sentByAgency}/>*/}
                                    {/*</FormControl>*/}
                                    {/*}*/}
                                    {/*{*/}
                                    {/*    valueForm.sentByAgency === TRUE_NUMBER && <>*/}
                                    {/*        {this.renderLodgingItem({*/}
                                    {/*            icon: sentByAgencyIcon,*/}
                                    {/*            title: t("information.formTab1.questionRealEstateCenterCode"),*/}
                                    {/*            error: errorsField.questionRealEstateCenterCode,*/}
                                    {/*            input:*/}
                                    {/*                <CustomInput*/}
                                    {/*                    onChange={(event) => {*/}
                                    {/*                        if (!isPageDetail) {*/}
                                    {/*                            this.props.handleChange(event, "realEstateCenterCode")*/}
                                    {/*                        }*/}
                                    {/*                    }}*/}
                                    {/*                    name="questionRealEstateCenterCode"*/}
                                    {/*                    value={valueForm.realEstateCenterCode}*/}
                                    {/*                    disabled={!!isPageDetail}*/}
                                    {/*                    validate={errorsField.realEstateCenterCode}*/}
                                    {/*                />*/}
                                    {/*        })}*/}
                                    {/*    </>*/}
                                    {/*}*/}

                                    {/*{valueForm.sentByAgency === TRUE_NUMBER &&*/}
                                    {/*<Grid container item sm={12}/>*/}
                                    {/*    <RowFormColumn*/}
                                    {/*        label={t("information.formTab1.questionRealEstateCenterCode")}*/}
                                    {/*        imgSrcLabel={<BusinessIcon/>}*/}
                                    {/*        input={*/}
                                    {/*            <Grid sm={12} md={6}>*/}
                                    {/*                <CustomInput*/}
                                    {/*                    onChange={(event) => {if(!isPageDetail){ this.props.handleChange(event, "realEstateCenterCode")}}}*/}
                                    {/*                    name="questionRealEstateCenterCode"*/}
                                    {/*                    value={valueForm.realEstateCenterCode}*/}
                                    {/*                    disabled={!!isPageDetail}*/}
                                    {/*                />*/}
                                    {/*            </Grid>*/}
                                    {/*        }*/}
                                    {/*        textValidate={errorsField.realEstateCenterCode}*/}
                                    {/*    />*/}
                                    {/*</Grid>*/}
                                    {/*}*/}
                                    {/*<Grid item lg={12} className={classes.appelVisio}>*/}
                                    {/*    <img src={cardUserIcon} alt=""/>*/}
                                    {/*    <div>{t("information.formTab1.appelVisio")}</div>*/}
                                    {/*</Grid>*/}
                                    {
                                        this.renderLodgingItem({
                                            icon: additionalInfoIcon,
                                            title: t("information.formTab1.otherInfo"),
                                            optional: true,
                                            input:   <RowFormColumn
                                                input={
                                                    isPageDetail ?
                                                        <CustomInput
                                                            multiline
                                                            className={"additionalInfo"}
                                                            rows={3}
                                                            rowsMax={3}
                                                            name="additionalInfo"
                                                            value={valueForm.additionalInfo}
                                                            disabled
                                                        /> :
                                                        <CustomInput
                                                            multiline
                                                            className={"additionalInfo"}
                                                            rows={3}
                                                            rowsMax={3}
                                                            name="additionalInfo"
                                                            value={valueForm.additionalInfo}
                                                            onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"additionalInfo")}}}
                                                        />
                                                }
                                            />
                                        })
                                    }
                                    {/*<RowFormColumn*/}
                                    {/*    label={t("information.formTab1.otherInfo")}*/}
                                    {/*    imgSrcLabel={docBluBlackIcon}*/}
                                    {/*    input={*/}
                                    {/*        isPageDetail ?*/}
                                    {/*            <CustomInput*/}
                                    {/*                multiline*/}
                                    {/*                className={"additionalInfo"}*/}
                                    {/*                rows={3}*/}
                                    {/*                rowsMax={3}*/}
                                    {/*                name="additionalInfo"*/}
                                    {/*                value={valueForm.additionalInfo}*/}
                                    {/*                disabled*/}
                                    {/*            /> :*/}
                                    {/*            <CustomInput*/}
                                    {/*                multiline*/}
                                    {/*                className={"additionalInfo"}*/}
                                    {/*                rows={3}*/}
                                    {/*                rowsMax={3}*/}
                                    {/*                name="additionalInfo"*/}
                                    {/*                value={valueForm.additionalInfo}*/}
                                    {/*                onChange={(e)=>{if(!isPageDetail){this.props.handleChange(e ,"additionalInfo")}}}*/}
                                    {/*            />*/}
                                    {/*    }*/}
                                    {/*/>*/}
                            </div>
                        </Grid>
                    </Grid>
                    {!paymentRequest && !isPageDetail && <Grid container item md={12} lg={12} className={classes.footerContentInformation}>
                        <div>
                            <div className={"checkboxAccept"}>
                                <Checkbox
                                    checked={valueForm.acceptCertificate}
                                    onChange={(e) => this.props.handleChange(e, "acceptCertificate")}
                                    name="checkedB"
                                    color="#000000"
                                />
                                <div className={"labelCheckBox"}>{t("information.formTab1.acceptCertificate")}
                                    <a className={"certificate"} target={"_blank"} rel="noreferrer"
                                       href={CGV_FILE}> {t("information.formTab1.certificate")}</a>
                                </div>
                            </div>
                            <ErrorInput textValidate={errorsField.acceptCertificate}/>
                        </div>
                        <div className={'btnSaveWrapper'}>
                        <CustomButton
                            className={classes.btnAddInformation}
                            titleComponent={<div>{t(statusCode === GO_DOC?"information.formTab1.valid":"information.formTab1.btnSave")}</div>}
                            onClick={()=> this.props.handleSubmitInformation(statusCode === GO_DOC ? TAB_INFORMATION : TAB_DATE)}
                        />
                        </div>
`
                    </Grid> }
                </Grid>
            </div>
        )
    }
}

Information.defaultProps = {
    isModalShowClientDetail: false
}

Information.propTypes = {
    classes: PropTypes.object.isRequired,
    isModalShowClientDetail: PropTypes.bool
}

export default compose(
    withStyles(styles),
    withTranslation(),
    withWidth(),
    withRouter
)(Information);
