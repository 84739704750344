import React from 'react';
import {
    Select,
    TextField,
    withStyles,
    FormControl,
    MenuItem,
    InputLabel,
    InputAdornment,
    Tooltip
} from "@material-ui/core";
import {TableFilterRow} from "@devexpress/dx-react-grid-material-ui";
import {withTranslation} from "react-i18next";
import AppKeyboardDatePicker from "../form/DatePicker/AppKeyboardDatePicker";
import axios from 'axios';
import SearchIcon from "@material-ui/icons/Search";
import AppAsyncPaginate from "../form/Select/AppAsyncPaginate";
import {withRouter} from "react-router";
import {compose} from "redux";
import qs from "qs";
import _ from 'lodash';
import moment from "moment";
import {getParamInUrl} from "../../functions/functions";
const styles={
    filterCell: {
        "&:first-child":{
            paddingLeft: "10px !important",
        },
        padding: "10px 5px"
    },
    inputText:{
        "& input,&.MuiSelect-select":{
            padding: "10px 10px 10px 0",
            borderBottom: "1px dotted rgba(0, 0, 0, 0.42)",
            "& $selectIcon":{
                height: "1.3em",
                "&>*":{
                    height: "100%"
                }
            }
        }
    },
    inputDate:{
        "& input":{
            padding: "10px 0",
        },
        '& .MuiFilledInput-underline::before':{
            // borderBottom:'none'
            borderBottom: "1px dotted rgba(0, 0, 0, 0.42)",
        },
    },
    formControl:{
        width: "100%",
        "& .MuiInput-underline:before":{
            borderBottom: "1px dotted rgba(0, 0, 0, 0.42)",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before":{
            borderBottom: "2px solid rgba(0, 0, 0, 0.42)"
        },
        "& .MuiSvgIcon-root":{
            fill: "rgba(224, 224, 224, 1)"
        }
    },
    selectComponent:{
        "& .filterSelect__control":{
            border: 0,
            borderRadius: 0,
            borderBottom: "1px dotted rgba(0, 0, 0, 0.42)",
            "&:hover:not(.filterSelect__control--menu-is-open)":{
                border: "none",
                borderBottom: "1px dotted rgba(0, 0, 0, 0.42)",
                outline: "none",
                cursor: "pointer",
                boxShadow: "none"
            },
            "&.filterSelect__control--menu-is-open":{
                borderColor: "rgba(0, 0, 0, 0.42)",
                boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.42)"
            }
        }
    },
    placeholderLabel:{
        top: "-0.75rem",
        color: "rgba(0, 0, 0, 0.4)",
        "&.Mui-focused":{
            display: "none"
        },
        "&+.MuiInput-formControl":{
            marginTop: 0
        }
    },
    selectIcon:{
        marginRight: "0.2rem",
    },
    selectLabel:{

    }
};

class FilterCell extends React.Component{
    constructor(props) {
        super(props);
        // const search = props.location?props.location.search:"";
        // const urlParams = qs.parse(search, { ignoreQueryPrefix: true });
        this.state = {
            // urlParams: urlParams,
            value: null,
            updateValue: false,
            loading: props.column.filterOptions && props.column.filterOptions.api,
            callback: props.column.filterOptions && typeof props.column.filterOptions.callback === "function"?props.column.filterOptions.callback:null,
            choices: props.column.filterOptions && props.column.filterOptions.choices?props.column.filterOptions.choices:[],
            withNoneOption: !(props.column.filterOptions && props.column.filterOptions.withNoneOption===false),
        };
        const {filterOptions} = props.column;
        if (filterOptions){
            let {api,apiParams,choiceLabel,choiceValue} = filterOptions;
            if (!apiParams){
                apiParams = {};
            }
            if (!apiParams.hasOwnProperty("group")){
                apiParams.group = "showSelect";
            }
            if (api){
                axios.get(api,{params:apiParams})
                    .then(response => {
                        const items = response.data.items;
                        this.setState({
                            loading: false,
                            choices: items.map((item)=>{
                                let label;
                                let value;
                                if (typeof choiceLabel === "function"){
                                    label = choiceLabel(item);
                                }
                                else{
                                    label = item[choiceLabel];
                                }
                                if (typeof choiceValue === "function"){
                                    value = choiceValue(item);
                                }
                                else{
                                    value = item[choiceValue];
                                }
                                return {"label":label,"value":value};
                            })
                        });
                    })
                    .catch(error => {
                        this.setState({
                            loading: false,
                            choices: []
                        });
                    });
            }
        }
        this.handleChange = this.handleChange.bind(this);
        this.loadPaginateSelectOptions = this.loadPaginateSelectOptions.bind(this);
    }

    async loadPaginateSelectOptions(search, loadedOptions, { page }){
        const {api,apiParams,choiceValue,choiceLabel} = this.props.column.filterOptions;
        let params = {
            search: search,
            page: page,
            group: 'showSelect',
        };
        if (typeof apiParams === "object"){
            params = Object.assign({},params,apiParams);
        }
        const response = await axios.get(api, {params: params});
        const data = response.data;
        let dataItems = [];
        let hasMore = false;
        if (data.hasOwnProperty('items') && data.items) {
            dataItems = data.items;
            hasMore = data.currentPage < data.nbPages;
        } else if (data.hasOwnProperty('hydra:member')) {
            dataItems = data["hydra:member"];
            const hydraView = data["hydra:view"];
            const paramsCurrent = getParamInUrl(hydraView["@id"]);
            const paramsPageLast = getParamInUrl(hydraView["hydra:last"]);
            const currentPageList = paramsCurrent.hasOwnProperty("page") ? paramsCurrent.page : 1;
            const nbPagesList = paramsPageLast.hasOwnProperty("page") ? paramsPageLast.page : 1;
            hasMore = currentPageList < nbPagesList;

        }
        const options = dataItems.map((item)=>{
            return {label: typeof choiceLabel==="string"?item[choiceLabel]:(typeof choiceLabel==="function"?choiceLabel(item):choiceLabel.label), value: typeof choiceValue==="string"?item[choiceValue]:(typeof choiceValue==="function"?choiceValue(item):choiceLabel.id)};
        });
        return {
            options: options,
            hasMore: hasMore,
            additional: {
                page: page + 1,
            },
        };
    }

    handleChange(value,type){
        let filterValue = value;
        if (typeof type !== "undefined"){
            switch (type) {
                case "date":
                    if (value){
                        filterValue = value.format("DD/MM/YYYY");
                    }
                    else{
                        filterValue = "";
                    }
                    break;
                case "paginate_select":
                    if (value){
                        filterValue = value.value;
                    }
                    else{
                        filterValue = "";
                    }
                    break;
                default:
                    break;
            }
        }
        const self = this;
        // let {urlParams} = this.state;
        // let filters = this.state.urlParams.filters ? this.state.urlParams.filters : {};
        // filters = {
        //     ...filters,
        //     [this.props.column.name]: value
        // }
        // urlParams = {
        // ...this.state.urlParams,
        //     filters
        // }

        this.setState({
            value: value,
            updateValue: true,
            // urlParams: urlParams
        },function () {
            self.props.onFilter({value:filterValue});
            if (typeof self.state.callback==="function"){
                self.state.callback(filterValue);
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const newState = {};

        const {updateValue} = this.state;
        const search = this.props.location?this.props.location.search:"";
        const currentUrlParams = qs.parse(search, { ignoreQueryPrefix: true });
        const columnName = this.props.column.name;
        const filterType = this.props.column.filterOptions && this.props.column.filterOptions.type?this.props.column.filterOptions.type:null;

        const prevSearch = prevProps.location?prevProps.location.search:"";
        const prevUrlParams = qs.parse(prevSearch, { ignoreQueryPrefix: true });

        if (!_.isEqual(currentUrlParams, prevUrlParams)  && !updateValue) {
            if (currentUrlParams.filters) {
                if (this.state.value !== currentUrlParams.filters[columnName]) {
                    if (!filterType) {
                        newState.value = currentUrlParams.filters[columnName] ? currentUrlParams.filters[columnName] : ''
                    }
                    newState.value = currentUrlParams.filters[columnName] ? currentUrlParams.filters[columnName] : null
                    newState.urlParams = currentUrlParams
                }
            } else if (this.state.value) {
                if (!filterType) {
                    newState.value = ''
                } else {
                    newState.value = null
                }

                newState.urlParams = currentUrlParams
            }
        }

        if (prevState.updateValue) {
            newState.updateValue = false
        }

        if (Object.keys(newState).length > 0){
            this.setState(newState);
        }
    }

    render() {
        const {classes,i18n,t,tReady,filter,staticContext, ...props} = this.props;
        let {value,choices,withNoneOption} = this.state;
        const { column} = props;
        let FilterComponent = <div/>;
        if (props.filteringEnabled){
            const filterType = column.filterOptions && column.filterOptions.type?column.filterOptions.type:null;
            switch (filterType) {
                case "date":
                    FilterComponent = <AppKeyboardDatePicker
                        className={classes.inputDate}
                        value={value!==null?moment(value,"DD/MM/YYYY"):null}
                        onChange={(e)=>this.handleChange(e,"date")}
                        emptyLabel={""}
                        InputProps={{
                            disableUnderline: false
                        }}
                    />;
                    break;
                case "paginate_select":
                    let options = [
                        {value:"",label:t("label.all")}
                    ];
                    if (withNoneOption){
                        options.push({value:"__null__",label:t("label.none")});
                    }
                    if (column.filterOptions.options && column.filterOptions.options.length){
                        options = options.concat(...column.filterOptions.options);
                    }
                    FilterComponent = <React.Fragment>
                        {!value && <InputLabel id={"filter-select-"+column.name} className={classes.placeholderLabel} />}
                        {(column.filterOptions.loadOptions || column.filterOptions.api) && <AppAsyncPaginate
                            // defaultValue={filter?{value:1, label:123}:null}
                            options={options}
                            loadOptions={column.filterOptions.loadOptions?column.filterOptions.loadOptions:this.loadPaginateSelectOptions}
                            debounceTimeout={500}
                            className={classes.selectComponent}
                            classNamePrefix={"filterSelect"}
                            onChange={(e)=>this.handleChange(e,"paginate_select")}
                            additional={{
                                page: 1,
                            }}
                            isClearable={true}
                            placeholder={""}
                            inputProps={{
                                className: classes.inputText,
                            }}
                        />}
                    </React.Fragment>;
                    break;
                case "select":
                    value = value!==null?value:filter?filter.value:undefined;

                    FilterComponent = <React.Fragment>
                        {!value && <InputLabel id={"filter-select-"+column.name} className={classes.placeholderLabel} />}
                        <Select
                            labelId={"filter-select-"+column.name}
                            fullWidth={true}
                            value={(value !== '' && value !== '_null_' && typeof value !== 'string') ? (isNaN(parseInt(value)) ? value : parseInt(value)) : value}
                            className={`${classes.selectComponent} selectFilerInHeaderList`}
                            onChange={(e)=>this.handleChange(e.target.value)}
                            inputProps={{
                                className: classes.inputText,
                            }}

                        >
                            <MenuItem value={""}>{t("label.all")}</MenuItem>
                            {withNoneOption && <MenuItem value={"_null_"}>{t("label.none")}</MenuItem>}
                            {choices.map((item, key)=>{
                                const contentItem = <div>{item.icon?<div className={classes.selectIcon}>{item.icon}</div>:""}{item.label?<div className={classes.selectLabel}>{item.label}</div>:""}</div>;
                                return <MenuItem key={key} value={item.value}>{item.tooltip?<Tooltip arrow title={item.tooltip}>{contentItem}</Tooltip>:contentItem}</MenuItem>
                            })}
                        </Select>
                    </React.Fragment>;
                    break;
                default:
                    FilterComponent = <TextField
                        fullWidth={true}
                        className={classes.inputText}
                        value={value!==null?value:filter?filter.value:undefined}
                        onChange={(e)=>this.handleChange(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />;
            }
        }
        return <TableFilterRow.Cell {...props} className={classes.filterCell} children={
            <FormControl className={classes.formControl} children={FilterComponent}/>
        }
        />;
    }
}

export default compose(withStyles(styles),withRouter,withTranslation())(FilterCell);
