import { fetchUtils } from 'react-admin';
import {BASE_URL, DATA_USER, REQ_DOC_IMAGE, REQ_DOC_VIDEO} from "../../constants/constants";
import qs from "qs";
import {
    API_SUB_DOCUMENT_TYPE_RESOURCE,
    GET_SUB_DOCUMENT_TYPE_CREATE,
    GET_SUB_DOCUMENT_TYPE_LIST, REQUEST_LIST
} from "../../constants/apiSuffix";
import moment from "moment";
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json'});
    }
    const dataUser = localStorage.getItem(DATA_USER);
    if(dataUser && typeof dataUser === "string" && JSON.parse(dataUser).token) {
        options.headers.set('Authorization', `Bearer ${JSON.parse(dataUser).token}`);
    }
    return fetchUtils.fetchJson(url, options);
}


// const httpClient = fetchUtils.fetchJson;
const apiUrl = BASE_URL+"/api"

const dataProvider = {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const code = params.filter ? params.filter.code : '';
        const sorts = {
                [field]: order
            };
        let query = {
            // range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            // filter: JSON.stringify(params.filter),
            page: page,
            maxPerPage: perPage,
            code: code,
            sorts: sorts,
            ...params.filter,
        };
        switch (resource){
            case "mail_types":
                resource = "mail_type";
                break;
            case "request_mail_types":
                query.mailType = "requestMailType";
                resource = "mail_type";
                break;
            case "users":
                resource="list/user"
                break;
            case "categories":
                resource="category/list";
                break;
            case "lease_types":
                resource="lease/type/list"
                break;
            case "request_document_types":
                resource="request_document_type/list"
                break;
            case "import_intervention":
                resource="intervention-excel-update/list";
                break;
            case "mails":
                resource="mail/list"
                break;
            case "video_types":
            case "image_types":
                query = {...query, documentTypeCode: (resource === "video_types" ? REQ_DOC_VIDEO : REQ_DOC_IMAGE)}
                resource = GET_SUB_DOCUMENT_TYPE_LIST.replace("/api/", "");
                break;
            // case "sub_document_types":
            //     resource = GET_SUB_DOCUMENT_TYPE_LIST.replace("/api/", "");
            //     break;
            case "request":
                resource = REQUEST_LIST.replace("/api/", "")
                break;
            default:
                break;
        }
        const url =  `${apiUrl}/${resource}?${qs.stringify(query)}`;
        return httpClient(url).then(({ status,headers, json }) => {
            return ({
                data: json.items ? json.items :json,
                total: json.nbResults ? json.nbResults : (json.items ? json.items.length : json.length),
            })
        })
    },

    getOne: (resource, params) => {
        switch (resource){
            case "request_mail_types":
                resource = "mail_types";
                break;
            case "request_document_types":
                resource="document_types"
                break;
            case "video_types":
            case "image_types":
                resource = "sub_document_types";
                break;
            default:
                break;
        }
        return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({json}) => ({
            data: json.item ? json.item : json,
        }))
    },


    getMany: (resource, params) => {
        const query = {
            filter: { id: params.ids }
        };
        switch (resource){
            case "mail_types":
                resource = "mail_type";
                break;
            case "request_mail_types":
                query.mailType = "requestMailType";
                resource = "mail_type";
                break;
            default:
                break;
        }
        const url = `${apiUrl}/${resource}?${qs.stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json.items }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const query = {
            sort: [field, order],
            range: [(page - 1) * perPage, page * perPage - 1],
            filter:{
                ...params.filter,
                [params.target]: params.id,
            },
        };
        switch (resource){
            case "mail_types":
                resource = "mail_type";
                break;
            case "request_mail_types":
                query.mailType = "requestMailType";
                resource = "mail_type";
                break;
            default:
                break;
        }
        const url = `${apiUrl}/${resource}?${qs.stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }))
    },

    update: (resource, params) => {
        let body = params.data;
        console.log(resource)
        delete body.id;
        delete body.createdAt;
        delete body.deletedAt;
        delete body.updatedAt;
        delete body.fieldsTranslations;
        let methodUpdate = 'POST';
        switch (resource) {
            case "video_types":
            case "image_types":
                resource=API_SUB_DOCUMENT_TYPE_RESOURCE+"/edit"
                body =formChangeFieldName(body)
                break;
            case "categories":
                methodUpdate = 'PUT';
                body={name: body.name}
                body = JSON.stringify(body);
                break;
            case "request_mail_types":
                resource = "mail_type";
                body = formMailType(body, true)
                break;
            case "mail_types":
                resource = "mail_type";
                body = formMailType(body)
                break;
            case "lease_types":
                methodUpdate = 'PUT';
                body={name: body.name}
                body = JSON.stringify(body);
                break;
            case "request_document_types":
                resource="request_document_type/edit"
                body = formChangeFieldName(body)
                break;
            case "voice_types":
                // const formData = new FormData();
                // formData.append("name", body.name);
                // formData.append("tokenAudio", body.tokenAudio);
                // body = formData;
                methodUpdate = 'PUT';
                body={name: body.name, tokenAudio: body.tokenAudio}
                body = JSON.stringify(body);
                break;
            default:
                body = JSON.stringify(body);
                break;
        }
        let url = `${apiUrl}/${resource}/${params.id}`;

        return httpClient(url, {
            method: methodUpdate,
            // body: JSON.stringify(params.data),
            body: body
        }).then(({ json }) => ({ data: json.item ? json.item : json }));
    },


    updateMany: (resource, params) => {
        const query = {
            filter: { id: params.ids},
        };
        switch (resource){
            case "request_mail_types":
                resource = "mail_type";
                break;
            default:
                break;
        }
        return httpClient(`${apiUrl}/${resource}?${qs.stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
        let body = params.data;
        switch (resource) {
            case "request_mail_types":
                resource = "mail_types";
                break;
            case "lease_types":
                resource = "lease/type/new";
                body =formChangeFieldName(body)
                break;
            case "request_document_types":
                resource="request_document_type/create"
                body =formChangeFieldName(body)
                break;
            case "video_types":
            case "image_types":
                resource=GET_SUB_DOCUMENT_TYPE_CREATE.replace("/api/","")
                body =formChangeFieldName(body)
                body.append("documentTypeCode", resource === "video_types" ? REQ_DOC_VIDEO : REQ_DOC_IMAGE);
                break;
            default:
                body = JSON.stringify(body);
                break;
        }
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: body,
        }).then(({ json }) => ({
            data: { ...body, id: json.item ? json.item.id : json ? json.id : null},
        }));
    },


    delete: (resource, params) => {
        switch (resource) {
            case "request_document_types":
                resource = "request_document_type";
                break;
            case "video_types":
            case "image_types":
                resource = API_SUB_DOCUMENT_TYPE_RESOURCE;
                break;
            default:
                break;
        }
        return httpClient(`${apiUrl}/${resource}/${params.id}/delete`, {
            method: 'POST',
        }).then(({json}) => ({data: json}))
    },

    deleteMany: (resource, params) => {
        const query = {
            filter: { id: params.ids},
        };
        return httpClient(`${apiUrl}/${resource}?${qs.stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

};

const formChangeFieldName = (data)=>{
    const formData = new FormData();
    formData.append("name", data.name);
    return formData;
}
const formMailType  =  (data, isRequest) => {
    const formData = new FormData();

    Object.keys(data).forEach((key, index) => {
            switch (key) {
                case 'name':
                case 'subject':
                case 'body':
                    formData.append(key, data[key]);
                    break;
                case 'statusType':
                    if(isRequest) {
                        formData.append(key, data[key].id);
                    }
                    break;
                default:
                    // formData.append(key, Object.values(data)[index]);
                    break;
            }
        }
    );
    return formData;
}

export default dataProvider;
