import React, {useState} from 'react';
import {compose} from "redux";
import PopoverCustom from "../../../../theme/CustomElements/PopoverCustom";
import {Grid, withStyles} from "@material-ui/core";
import CustomButton from "../../../../theme/CustomElements/CustomButton";
import CustomInput from "../../../../theme/CustomElements/CustomInput";
import returnIcon from "../../../../images/request/return.svg";
import {withTranslation} from "react-i18next";
import {MONTSERRAT} from "../../../../constants/fontCss";
import ErrorInput from "../../../../theme/form/Alert/ErrorInput";
import {ARCHIVED, REPEAT, UNARCHIVED_AND_NOT_REPEAT, XS} from "../../../../constants/constants";
import {customRenderShowBtnAction} from "../constantRequestPage";
import BookmarkIcon from "../../../../images/request/archive.svg";
import {useSelector} from "react-redux";
import SaveIcon from "@material-ui/icons/Save";
import goFilterIcon from "../../../../images/request/Groupe 9099.svg";
import archiveIcon from "../../../../images/request/Groupe 9100.svg";
import allIcon from "../../../../images/request/Groupe 9130.svg";

const styles = (theme) => ({
    popoverEditStatus: {
        background: '#fff',
        boxShadow: '0px 0px 20px rgba(0, 0, 254, 0.05)',
        borderRadius: 10,
        minWidth: 200,
        maxWidth: 400,
        [theme.breakpoints.down(XS)]: {
            maxWidth: 'calc(100vw - 20px)'
        }
    },
    headerEditStatus: {
        background: '#001529',
        boxShadow: '0px 0px 20px rgba(0, 0, 254, 0.05)',
        borderRadius: '10px 10px 0px 0px',
        color: '#fff',
        fontWeight: 600,
        padding: '0.5rem',
    },
    contentEditStatusWrapper: {
        padding: '0.5rem',
        width: '100%',
        '& .btnActionEdit': {
            margin: '5px 10px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            width: 'fit-content',
            minWidth: 170,
            maxWidth: '100%',
            padding: '4px 16px',
            borderRadius: 8,
            background: 'transparent',
            border: '1px solid transparent',
            '&:hover':{
                background: '#ebf2f8',
            },
            '&.activeChooseBtn': {
                background: '#DDDDFF',
                '& div':{
                    fontWeight: 600
                }
            },
            '& .iconWrapper':{
                display: 'flex',
                alignItems: 'center',
            },
            '& svg': {
                width: 20,
                '& path': {
                    fill: '##0000fe !important'
                }
            },
            '& .titleIcon': {
                paddingLeft: 8,
                color: '#0000fe',
                textTransform: 'uppercase',
                lineHeight: 1.1,
                fontWeight: 500,
            }
        },
        '& .btnAction':{
            padding: 10,
            display: 'flex',
            justifyContent: 'center'
        }
    },
    btnResponse: {
        background: '#0000FF !important',
        borderRadius: 10,
        '& .MuiButton-label': {
            fontFamily: MONTSERRAT,
            fontWeight: 700,
            fontSize: '0.875rem'
        }
    }
});

const PopoverEditActionStatusArchiveRepeat = (props) => {
    const {
        anchorEl, popoverAriaOwns, onClose, classes, formValuePopover, t
    } = props;
    const dataUser = useSelector((state) => state.authReducer.dataUser);
    const [value, setValue] = useState("");
    const [loading, setLoading] = useState(false);
    return (<PopoverCustom
        anchorEl={anchorEl}
        popoverAriaOwns={popoverAriaOwns}
        onClose={onClose}
        content={
            <div className={classes.popoverEditStatus}>
                <div className={`${classes.headerEditStatus} headerEditStatus`}>
                    {t("request.list.id")}: {formValuePopover.id}
                </div>
                <div className={`${classes.contentEditStatusWrapper} contentEditStatusWrapper`}>
                    {(formValuePopover.archivedByAdmin || formValuePopover.repeatAt) && customRenderShowBtnAction({
                        className: `btnActionEdit ${value === UNARCHIVED_AND_NOT_REPEAT ? "activeChooseBtn" : ""}`,
                        onClick: () => setValue(UNARCHIVED_AND_NOT_REPEAT),
                        icon: goFilterIcon,
                        title: t("request.status.unarchivedAndNotRepeat")
                    })}
                    {!formValuePopover.repeatAt && customRenderShowBtnAction({
                        className: `btnActionEdit ${value === REPEAT ? "activeChooseBtn" : ""}`,
                        onClick: () => setValue(REPEAT),
                        icon: allIcon,
                        title: t("request.status.repeat")
                    })}
                    {!formValuePopover.archivedByAdmin && customRenderShowBtnAction({
                        className: `btnActionEdit ${value === ARCHIVED ? "activeChooseBtn" : ""}`,
                        onClick: () => setValue(ARCHIVED),
                        icon: archiveIcon,
                        title: t("request.status.archived")
                    })}
                    <div className={"btnAction"}>
                        <CustomButton
                            className={classes.btnResponse}
                            title={t("button.save")}
                            onClick={() => {
                                if (typeof props.handleSave === "function") {
                                    props.handleSave(value)
                                }
                            }}
                            buttonProps={{
                                startIcon: <SaveIcon className={"iconAdd"}/>
                            }}

                        />
                    </div>
                </div>
            </div>
        }
    />)
}

export default compose(withStyles(styles), withTranslation())(PopoverEditActionStatusArchiveRepeat);
