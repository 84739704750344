import React from 'react';
import {
    List,
    DateField,
    TextField,
    Datagrid,
    ExportButton,
    TopToolbar, Filter, DateInput, FilterButton
    // Filter
} from 'react-admin';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {withStyles} from "@material-ui/core/styles";
import ListEmpty from "./ListEmpty";
import Pagination from "../Pagination";
import {styleList} from "../style";
import {REQUEST_INFORMATION_DETAIL} from "../../../constants/links";
import EditRoundedIcon from "@material-ui/icons/Edit";
import moment from "moment";
import {convertTimezoneHour} from "../../../functions/functions";

const headTranslate = 'request.list.';

const CreatedDateColumn = ({record}) => {
    return <span>{record.createdDate ? moment(record.createdDate).format("DD/MM/YYYY HH:mm") : ""}</span>
}

const RegistrationStartColumn = ({record}) => {
    const row = record;
    if (row.registrationStart && row.registrationTimeStart && row.registrationTimeEnd) {
        let startTime = convertTimezoneHour(row.registrationTimeStart, row.registrationStart)
        let time = moment().hour(startTime / 60).minute(startTime % 60).second(0).format('HH:mm')
        return <span>
            {row.registrationStart ? moment(row.registrationStart).format("DD/MM/YYYY") : ""} {time}
        </span>
    } else return ""

}

const ProblemColumn = ({record}) => {
    return record.subject ? <span>{record.subject}</span> : (record.problem ?
        <span dangerouslySetInnerHTML={{__html: record.problem}}/> : "")
}

const properties = [

    {
        source: "id",
        label: "request.list.id",

    },
    {
        source: "problem",
        label: "request.list.subject",
        component: (props) => <ProblemColumn {...props}/>,

    },
    {
        source: "fullName",
        label: 'request.list.client',
    },
    {
        source: "createdDate",
        label: "request.list.date",
        component: (props) => <CreatedDateColumn {...props}/>,
    },
    {
        source: 'registrationStart',
        label: 'request.list.startTime',
        component: (props) => <RegistrationStartColumn {...props} />,
    },
    {
        source: 'manager.person.fullName',
        label: 'request.list.staff',
    },
    // {
    //     source: "note",
    //     title: "request.list.note",
    //     component: (props)=> <NoteColumn {...props} label={"request.list.date"}/>,
    // },
];


const EditIcon = ({record, classes}) => {
    return <a className={`${classes.editButton} requestEditButton`}
              href={(window.location.origin) + REQUEST_INFORMATION_DETAIL.replace(":id", record.id)}>
        <EditRoundedIcon/>
    </a>
}


const ListActions = (props) => {
    console.log(props)
  return  <TopToolbar className={"toolbarList"}>
      {props.total > 0 && <ExportButton />}
    </TopToolbar>
};

export const CustomFilters = (props) => {
    const {t, ...newProps} = props

    return (
        <Filter {...newProps} className={"filtersOnList"}>
            <DateInput label={props.t("request.list.startDate")} source="startDate"
                       alwaysOn/>
            <DateInput label={props.t("request.list.endDate")} source="endDate"
                       alwaysOn/>
        </Filter>
    )
};
export const RequestList = compose(withStyles(styleList), withTranslation())(({classes, t, ...props}) => {
    const {tReady, ...newProps} = props
    let endDate = moment();
    let startDate = moment(moment().subtract(1, "month")).format("YYYY-MM-01")
    return (<List  empty={false} {...newProps}
                  actions={<ListActions/>}
                  filters={<CustomFilters t={t}/>}
                  filterDefaultValues={{startDate: startDate, endDate: endDate.format("YYYY-MM-DD")}}
                  sort={{field: 'createdDate', order: 'DESC'}}
                  pagination={<Pagination {...newProps} arrayColumn={properties}/>} className={classes.listWrapper}>
        <Datagrid className={classes.tableWrapper}>
            {properties.map((item, key) => {
                    return item.component ? item.component({
                            basePath: props.basePath,
                            label: item.label,
                            resource: props.resource,
                            key: key,
                            source: item.source
                        }) :
                        (item.type === "date" ?
                            <DateField key={key} source={item.source} label={t(item.label)} sortable={true}/> :
                            <TextField key={key} source={item.source} label={t(item.label)} sortable={false}/>)
                }
            )}
            <EditIcon classes={classes}/>

        </Datagrid>

    </List>)
})
