import React, {Component} from 'react';
import {Grid, IconButton} from "@material-ui/core";
import {MAYBE, NOT_BE_REPAIRED, REPAIRED, STOP, WAITING_FOR_REPLY} from "../../../../constants/constants";
import waitingIcon from "../../../../images/request/status/waiting.svg";
import goIcon from "../../../../images/request/status/go.svg";
import notgoIcon from "../../../../images/request/requestAdmin/noGoActive.svg";
import maybeIcon from "../../../../images/request/status/maybe.svg";
import moneyIcon from "../../../../images/request/requestAdmin/moneyIcon.svg"
import commentIcon from "../../../../images/request/requestAdmin/comment.svg"
import stopIcon from "../../../../images/request/requestAdmin/stopActive.svg"
import noteIcon from "../../../../images/request/requestAdmin/Note.svg"
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import CustomInlineSvg from "../../../../theme/CustomElements/CustomInlineSvg";
import AppPersonAvatar from "../../../../theme/Avatar/AppPersonAvatar";
import {connect} from "react-redux";
import moment from "moment";
import AddIcon from '@material-ui/icons/Add';
import CustomInput from "../../../../theme/CustomElements/CustomInput";
import ErrorInput from "../../../../theme/form/Alert/ErrorInput";
import {logout} from "../../../../_actions/auth";
import CustomButton from "../../../../theme/CustomElements/CustomButton";
import returnIcon from "../../../../images/request/return.svg";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";
import axios from "axios";
import {API_ADD_NOTE_REQUEST} from "../../../../constants/apiSuffix";

const styles = theme => ({
    left: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            width: 50,
            height: 50
        }
    },
    right: {
        background: '#F4F7FF',
        borderRadius: 15,
        padding: '0.625rem'
    },
    status: {
        fontWeight: 700,
        fontSize: '1.5rem',
        marginLeft: 10
    },
    titleRight: {
        display: 'flex',
        alignItems: 'center',
        color: '#001529',
        marginBottom: 10,
        '&:nth-last-child(2n)': {
            marginBottom: 5
        },
        '& .title': {
            margin: '0 10px 0 5px',
            fontWeight: 700,
            fontSize: '1rem'
        }
    },
    comment: {
        maxHeight: 75,
        overflow: 'auto',
        overflowWrap: 'break-word',
        '&::-webkit-scrollbar': {
            width: '5px'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor:'#C4C4C4',
            borderRadius: 3
        },
    },
    noteTitle: {
        display: 'flex',
        alignItems: 'center',
        color: '#001529',
        marginTop: 10,
        '& svg': {
            width: 25,
            height: 25,
            '& path': {
                fill: '#001529'
            }
        },
        '& .note': {
            fontWeight: 700,
            fontSize: '1.125rem',
            marginLeft: 5
        },
        '& button':{
            width: 30,
            height: 30,
            marginLeft: 5
        }
    },
    showNoteWrapper:{
        maxHeight: 300,
        marginTop: 15,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '5px'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor:'#C4C4C4',
            borderRadius: 3
        },
        '& .inputWrapper':{
            width: '80%',
            margin: 'auto',
            border: '1px solid #e0e0e0',
            borderRadius: 8,
            '& .comment':{
                border: 'none'
            },
            '& .validateCustom':{
                paddingLeft: 10
            }
        }
    },
    showNote: {
        display: 'flex',
        marginTop: 10,
        padding: '0 10px',
        color: '#001529',
        '& .avatar': {
            width: '50px!important',
            height: '50px!important',
            objectFit: 'cover!important',
            marginRight: 10,
            cursor:'default'
        }
    },
    noteContent: {
        minHeight: 60,
        width: '80%',
        background: '#d0daf3',
        position: 'relative',
        borderRadius: 5,
        padding: 10,
        '&:after': {
            content: '""',
            position: 'absolute',
            borderTop: '10px solid transparent',
            borderBottom: '10px solid transparent',
            borderRight: '10px solid #d0daf3',
            left: -10,
            top: 10
        },
        '& .userName':{
            display: 'block',
            fontWeight: 700
        },
        '& .createdAt':{
            display: 'block',
            fontSize: '0.625rem',
            color: '#36456b',
            paddingTop: 5
        }
    },
    showNoteUser:{
        flexDirection: 'row-reverse',
        '& .avatar':{
            marginRight: 0,
            marginLeft: 5
        },
        '& $noteContent:after':{
            borderRight: 'none',
            borderLeft: '10px solid #d0daf3',
            right: -10,
            left: 'initial'
        }
    },
    // btnResponse:{
    //     marginBottom: 5,
    //     marginLeft: 5,
    //     padding: 3,
    // }
    groupIconEdit:{
        textAlign: 'end',
        marginBottom: 5,
        '& .cancelBtn':{
            padding: 5,
            marginRight: 5,
            background: '#f4f7fe!important',
            color: '#021529',
            border: '1px solid #021529'
        },
        '& .saveBtn':{
            padding: 5,
            marginRight: 5
        },
        // '& button':{
        //     width: 20,
        //     height: 20,
        //     marginLeft: 5,
        //     '&:hover':{
        //         background: 'none'
        //     }
        // },
        // '& svg:hover':{
        //     '& path':{
        //         fill: 'black'
        //     }
        // }
    }
})
class NoteContentModal extends Component {
    constructor(props) {
        super(props);
        this.state= {
            addNote: false,
            noteContent: null,
            errors: null,
            dataNote: (this.props.data && this.props.data.notes) ?? []
        }
    }
    showIcon = (status) => {
        const {classes, t} = this.props
        switch (status){
            case WAITING_FOR_REPLY:
                return <React.Fragment>
                    <img alt={'waiting'} src={waitingIcon}/>
                    <span className={classes.status} style={{color:'#FF9518'}}>{t('request.status.WAITING_FOR_REPLY')}</span>
                </React.Fragment>
            case REPAIRED:
                return <React.Fragment>
                    <img alt={'repaired'} src={goIcon}/>
                    <span className={classes.status} style={{color:'#0DBE2A'}}>{t('request.status.REPAIRED')}</span>
                </React.Fragment>

            case NOT_BE_REPAIRED:
                return <React.Fragment>
                    <img className={classes.nogoIcon} alt={'not_be_repaired'} src={notgoIcon}/>
                    <span className={classes.status} style={{color:'#E33838'}}>{t('request.status.NOT_BE_REPAIRED')}</span>
                </React.Fragment>
            case MAYBE:
                return <React.Fragment>
                    <img alt={'not_be_repaired'} src={maybeIcon}/>
                    <span className={classes.status} style={{color:'#F2DC12'}}>{t('request.status.MAYBE')}</span>
                </React.Fragment>
            case STOP:
                return <React.Fragment>
                    <img alt={'stop'} src={stopIcon}/>
                    <span className={classes.status} style={{color:'#E33838'}}>{t('request.status.STOP')}</span>
                </React.Fragment>
            default:
                return <div></div>
        }
    }
    addNote = (data) => {
        let {dataNote} = this.state
        if (data.noteContent) {
            // this.setState({isLoading: true})
            const formData = new FormData();
            formData.append("content", data.noteContent);
            axios.post(API_ADD_NOTE_REQUEST.replace("{requestId}", data.id), formData)
                .then(response => {
                    if (response.status === 200) {
                        let data = response.data.item
                        if(data) dataNote.unshift(data)
                        this.props.callBackAddNote(true)
                        this.setState({
                            addNote: false,
                            noteContent: null
                        })
                    } else {
                        throw new Error();
                    }
                })
                .catch(error => {
                    console.log(error)
                    // this.setState({isLoading: false})
                })
        } else {
            let errors = {...this.state.errors};
            errors.noteForRequest = this.props.t("request.popoverAddNote.errors.noteEmpty")
            this.setState({
                errors: errors,
                errorOpen: true
            })
        }
    }
    render() {
        const {data, classes, t, dataUser} = this.props
        const {status, payment} = data
        const {addNote, noteContent, errors, dataNote} = this.state
        return (
            <React.Fragment>
                <Grid container xs={12}>
                    <Grid item xs={6} className={classes.left}>
                        {status && status.hasOwnProperty('statusType') && status.statusType.code &&
                        this.showIcon(status.statusType.code)
                        }
                    </Grid>
                    <Grid item xs={6} className={classes.right}>
                        <Grid xs={12} className={classes.titleRight}>
                            <img src={moneyIcon} alt={"moneyIcon"}/>
                            <span className={'title'}>{t('request.list.amount')}</span>
                            {payment && <span>{new Intl.NumberFormat(payment.currency, {
                                style: 'currency',
                                currency: payment.currency
                            }).format(payment.price)}</span>
                            }
                        </Grid>
                        <Grid xs={12} className={classes.titleRight}>
                            <img src={commentIcon} alt={"commentIcon"}/>
                            <span className={'title'}>{t('request.list.comment')}</span>
                        </Grid>
                        {status.comment && <Grid xs={12} className={classes.comment}>
                            <span>{status.comment}</span>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid xs={12} container>
                    <Grid item xs={12} className={classes.noteTitle}>
                        <CustomInlineSvg
                            svgProps={{
                                src: noteIcon
                            }}
                        />
                        <span className={"note"}>{(t('request.list.note'))}</span>
                        <IconButton onClick={()=>this.setState({addNote: true})}>
                            <AddIcon/>
                        </IconButton>
                    </Grid>
                    <Grid xs={12} className={classes.showNoteWrapper}>
                        {addNote &&
                        <Grid item className={"inputWrapper"}>
                            <CustomInput multiline
                                         rows={3}
                                         rowsMax={3}
                                         value={noteContent}
                                         onChange={(e)=>this.setState({noteContent: e.target.value, errors: null})}
                                         placeholder={t("request.popoverAddNote.note")}
                                         className={"comment"}
                                         validate={errors && errors.noteForRequest}
                            />
                            <ErrorInput textValidate={errors && errors.noteForRequest}/>
                            <Grid item xs={12} className={classes.groupIconEdit}>
                                <CustomButton
                                    onClick={()=>this.setState({addNote: false, noteContent: null, errors: null})}
                                    title={t("modal.cancel")}
                                    className={"cancelBtn"}
                                />
                                <CustomButton
                                    onClick={()=>this.addNote({noteContent: noteContent, id: data.id})}
                                    title={t("modal.save")}
                                    className={"saveBtn"}
                                />
                                {/*<IconButton onClick={()=>this.setState({addNote: false, noteContent: null})}>*/}
                                {/*    <ClearIcon/>*/}
                                {/*</IconButton>*/}
                                {/*<IconButton onClick={()=>this.addNote({noteContent: noteContent, id: data.id})}>*/}
                                {/*    <SaveIcon/>*/}
                                {/*</IconButton>*/}
                            </Grid>
                        </Grid>
                        }
                        {dataNote && dataNote.map(item=>{
                            // if(dataUser.data && dataUser.data.id && item.user && item.user.id && item.user.id === dataUser.data.id)
                            //     return <div>Hello</div>
                            // else
                                return <Grid className={`${classes.showNote} ${dataUser.data && dataUser.data.id && item.user && item.user.id && item.user.id === dataUser.data.id ? classes.showNoteUser : ""}`} xs={12}>
                                <AppPersonAvatar
                                    name={
                                        (item.user && item.user.person && item.user.person.lastName) ?? ""
                                    }
                                    title={" "}
                                    alt={item.user.email}
                                    variant="rounded"
                                    src={(item.user && item.user.person && item.user.person.avatar && item.user.person.avatar.pathUrl) ?? ""}
                                    avatarImgClassName={"avatar"}
                                />
                                <div className={classes.noteContent}>
                                    <span className={"userName"}>{item.user && item.user.person && item.user.person.fullName} ({item.user && item.user.email})</span>
                                    {item.content}
                                    <span className={"createdAt"}>{item.createdAt && moment(item.createdAt).format('hh:mm DD/MM/YYYY')}</span>
                                </div>

                            </Grid>
                        })}
                    </Grid>
                </Grid>
            </React.Fragment>

        );
    }
}

const mapStateToProps = state => {
    return {
        dataUser: state.authReducer.dataUser,
    };
};
export default compose(connect(mapStateToProps, null),withStyles(styles), withTranslation()) (NoteContentModal);