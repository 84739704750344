import { withStyles, Button } from "@material-ui/core";
import { Height } from "@material-ui/icons";
import { useEffect, useRef } from "react";
import {useTranslation, withTranslation} from "react-i18next";
import { compose } from "redux";
import SignaturePad from "signature_pad";
import { NUNITO } from "../../../constants/fontCss";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    "& canvas": {
      border: "1px solid #afdbfd",
    },
  },
  action: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    "& button": {
      margin: "0 10px",
    },
    "& .btnClear": {
      border: "1px solid #ccc",
      boxShadow: "0px 4px 20px rgba(15, 15, 137, 0.1) !important",
      borderRadius: 30,
      backgroundSize: "200%",
      transition: "all .4s ease-in-out",
      textTransform: "none",
      padding: "10px 20px",
      "&:before": {
        background: "none!important",
      },
      "& .MuiButton-label": {
        fontFamily: NUNITO,
        lineHeight: "1.3rem",
        fontWeight: "700",
        color: "#0070c7",
        fontSize: "1rem",
      },
      "&:hover": {
        backgroundPosition: "100% 0",
        transition: "all .4s ease-in-out",
      },
    },
    "& .btnSave": {
      backgroundImage:
        "linear-gradient(95deg,#00c1d1, #0070c7, #00c1d1) !important",
      boxShadow: "0px 4px 20px rgba(15, 15, 137, 0.1) !important",
      borderRadius: 30,
      backgroundSize: "200%",
      transition: "all .4s ease-in-out",
      textTransform: "none",
      padding: "10px 20px",
      "&:before": {
        background: "none!important",
      },
      "& .MuiButton-label": {
        fontFamily: NUNITO,
        lineHeight: "1.3rem",
        fontWeight: "700",
        color: "#fff",
        fontSize: "1rem",
      },
      "&:hover": {
        backgroundPosition: "100% 0",
        transition: "all .4s ease-in-out",
      },
    },
  },
};
const SignaturePagApp = ({ classes, t, onSavePng }) => {
  const canvasRef = useRef(null);
  const sigPad = useRef(null);

  useEffect(() => {
    sigPad.current = new SignaturePad(canvasRef.current);
  }, []);

  const onClear = () => sigPad.current.clear();

  function base64ToFile(base64, filename) {
    // Decode the base64 string
    const byteString = atob(base64.split(",")[1]);

    // Create an array of 8-bit unsigned integers
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // Create a Blob from the array buffer
    const blob = new Blob([ab], { type: "application/octet-stream" });

    // Create a File object from the Blob
    const file = new File([blob], filename, {
      type: "application/octet-stream",
    });

    return file;
  }
  const onSave = () => {
    const dataUrl = sigPad.current.toDataURL("image/png");
    // console.log(base64ToFile(dataUrl, "signature.png"));
    onSavePng(base64ToFile(dataUrl, "signature.png"));
  };
  return (
    <div className={classes.container}>
      <canvas ref={canvasRef} />
      <div className={classes.action}>
        <Button className="btnClear" onClick={() => onClear()}>
          {t('button.clear')}
        </Button>
        <Button className="btnSave" onClick={() => onSave()}>
          {t('button.save')}
        </Button>
      </div>
    </div>
  );
};

export default compose(withStyles(styles), withTranslation())(SignaturePagApp);
