import React from 'react';
import countries from "i18n-iso-countries";
import {TextField, withStyles} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import {SUPPORTED_LOCALES} from "../../../constants/constants";
SUPPORTED_LOCALES.map(lang => countries.registerLocale(require("i18n-iso-countries/langs/"+lang+".json")));

const styles = {
    appCountryAutocomplete:{
        width: "100%",
        lineHeight: "1.5rem",
        border: '1px solid #E0E0E0',
        borderRadius: 6,
        background: '#fff',
        overflow: 'hidden',
        padding: '0.2rem 0.7rem',
        "& input":{
            padding: "6px 10px",
            fontSize: "1rem",
            fontFamily: `"Nunito", sans-serif`,
            "&::placeholder":{
                opacity:0.8,
                fontWeight: 100,
            }
        },
        '& .MuiInput-underline:before': {
            content: 'none!important'
        },
        '& .MuiInput-underline:after': {
            content: 'none!important'
        },
        '& .MuiInputBase-root': {
            paddingRight: 0
        },
        '& .MuiTypography-body1': {
            lineHeight: 1
        }
    },
    option: {
        width: "100%"
    }
};

class AppCountryAutocomplete extends React.Component{
    // constructor(props) {
    //     super(props);
    // }

    loadCountryByLanguage = (lang) => {
        const objectCountry = countries.getNames(lang);
        const arrCountry = [];
        for (let [key, value] of Object.entries(objectCountry)) {
            let itemCountry = {};
            itemCountry.code = key;
            itemCountry.label = value;
            arrCountry.push(itemCountry);
        }
        return arrCountry;
    }

    countryToFlag = (isoCode) => {
        return typeof String.fromCodePoint !== 'undefined'
            ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
            : isoCode;
    }

    render() {
        const {classes,i18n,tReady,defaultValue,...otherProps} = this.props;
        let self = this;
        let options = this.loadCountryByLanguage(this.props.t('translations.lang'));
        let stateSelect = {...otherProps}
        delete (stateSelect["t"])
        delete (stateSelect["inputPlaceHolder"])

        return (
            <Autocomplete
                id={"country-select-demo"}
                className={classes.appCountryAutocomplete}
                options={options}
                classes={{
                    option: classes.option
                }}
                defaultValue={defaultValue||options.find((item)=>item.code ==="FR")}
                // clearText={this.props.t("label.empty")}
                // closeText={this.props.t("label.close")}
                autoHighlight
                getOptionLabel={option => `${option.label}`}
                renderOption={option => (
                    <React.Fragment>
                        <span style={{marginRight: '5px'}}>{this.countryToFlag(option.code)} </span>
                        {option.label} ({option.code})
                    </React.Fragment>
                )}
                renderInput={params => {
                    return <TextField
                        {...params}
                        fullWidth
                        className={classes.inputText}
                        placeholder={self.props.hasOwnProperty("inputPlaceHolder")?self.props.inputPlaceHolder:self.props.t('user_page.fields.addressCountry')}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password'
                        }}
                    />;
                }}
                {...stateSelect}
            />);
    }
}

AppCountryAutocomplete.propsType = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withTranslation()(AppCountryAutocomplete));
