import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import * as application from "../../../_actions/application";
import {Button, Grid, Tooltip} from "@material-ui/core";
import videoIcon from "../../../images/request/detail/online-video.svg"
import documentIcon from "../../../images/request/detail/Groupe 200.svg"
import {MONTSERRAT, NUNITO} from "../../../constants/fontCss";
import calenderIcon from "../../../images/request/detail/calendar (2).svg"
import seaIcon from "../../../images/request/detail/sea.svg"
import TabRequestDocument from "./TabRequestDocument";
import qs from "qs";
import {API_GET_SHOW_REQUEST, API_GET_SHOW_REQUEST_UPLOAD_FILE_TOKEN} from "../../../constants/apiSuffix";
import axios from "axios";
import LoadingAction from "../../../theme/LoadingAction";
// import downloadIcon from "../../../images/request/detail/arrow5.svg"
// import showIcon from "../../../images/request/detail/Groupe 276.svg"
// import viewIcon from "../../../images/request/detail/eye.svg"
import TabRequestDate from "./TabRequestDate";
import {
    GO_DOC,
    MAYBE, MD, REACT_APP_MECURE_URL,
    REQ_DOC_VIDEO,
    REQUEST_DOC_TYPE_VIDEO,
    SM,
    STRING, USER_TYPE_ADMIN, USER_TYPE_CLIENT,
    WAITING_FOR_REPLY,
    XS
} from "../../../constants/constants";
import NavBar2 from "../../NavBar2";
import banner from "../../../images/banner/banner.png";
import PopoverAddChat from "../request/popover/PopoverAddChat";
import ChatIcon from "@material-ui/icons/Chat";
import {POPOVER_ADD_CHAT} from "../request/RequestPage";
// import CustomInlineSvg from "../../../theme/CustomElements/CustomInlineSvg";
import VideoCallBlock from "../../videocall/VideoCallBlock";
import {END_VIDEO_CALL, REQUEST_UPLOAD_FILE_TOKEN} from "../../../constants/links";
// import {convertDatetimeToDDMMYYhhmm} from "../../../functions/functions";
import * as links from "../../../constants/links";


const styles = theme => ({
    headerRequestDetailPage: {
        background: `url(${banner})`,
        backgroundSize: 'cover',
        height: 154,
        position: 'relative',
        '&::before':{
            content:'""',
            width: '55%',
            height: '100%',
            position: 'absolute',
            background: 'linear-gradient(270deg, rgba(14, 56, 96, 0) 8.24%, #0E2F60 34.94%, #0A0730 97.58%)',
        },
        [theme.breakpoints.down(MD)]:{
            height: '150px!important'
        },
        '&.isPageUploadFile':{
            height: 50,
            [theme.breakpoints.down(MD)]:{
                height: '50px!important'
            },
        }
        // [theme.breakpoints.between(XS, SM)]: {
        //     height: 'auto',
        //     marginBottom: "20px",
        // },
    },
    requestDetailPage:{
        padding: '3rem',
        [theme.breakpoints.between(XS, SM)]: {
            padding: '0.5rem',
        },

        '& .headercontent':{

            [theme.breakpoints.between(XS, SM)]: {
                display:'flex',
                flexWrap: 'nowrap',
            },
            [theme.breakpoints.down(XS)]: {
                display:'block',
            },

        }
    },
    headerLeftWrapper:{
        paddingRight: '0.5rem',
        '& .headerLeftWrapper':{
            padding: '1rem 1.5rem',
            borderRadius: 12,
            boxShadow: '0 3px 6px 0 rgba(13, 66, 159, 0.16)',
            background: '#fff',
            display: 'flex',
            [theme.breakpoints.between(XS, SM)]: {
                display: 'block',
            },

            '& .headerLeftContentLeftWrapper':{
                display: 'flex',
                flexDirection: 'column',
                fontFamily: NUNITO,
                fontSize: '1rem',
                fontWeight: 500,
                color: '#605e72',
                '& .labelProblem':{
                },
                '& .contentProblem':{
                    // fontSize: '1.625rem',
                    fontWeight: 600,
                    maxHeight: 100,
                    overflowY: 'auto',
                    [theme.breakpoints.between(XS, SM)]: {
                        fontSize: '1.125rem',
                    },
                    '&::-webkit-scrollbar': {
                        width: 5,
                        height: 5
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor:'#C4C4C4',
                        borderRadius: 3
                    },
                    '& p':{
                        margin: 0,
                        padding: 0,
                        lineHeight: 1.1
                    }

                }
            },
            '& .headerLeftContentRightWrapper':{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                [theme.breakpoints.between(XS, SM)]: {
                    justifyContent: 'space-between',
                    paddingTop: '8px'
                },
                // '& .headerLeftContentRightBlock':{
                //     display: 'flex',
                //     alignItems: 'center',
                //     justifyContent: 'space-evenly',
                //     width: 'calc(100vw - 30px)',
                //     '& div': {
                //         fontFamily: NUNITO,
                //         fontSize: 16,
                //         fontWeight: 500,
                //         // lineHeight: 2,
                //         color: '#605e72'
                //     },
                //     '& .valueShow':{
                //         fontSize: '1.2rem',
                //         fontWeight:  600,
                //         [theme.breakpoints.down(XS)]:{
                //             fontSize: '1rem',
                //
                //         }
                //     },
                //     [theme.breakpoints.between(XS, SM)]:{
                //         justifyContent: 'flex-start',
                //
                //         '& .headerDate':{
                //             paddingRight:'15px',
                //         },
                //
                //     },
                //
                //
                //
                // },
            }
        },

        [theme.breakpoints.between(XS, SM)]: {
            width:'62%',
            margin: '0px 0px 0px 1%',
            paddingRight:0,
 
        },
        [theme.breakpoints.down(XS)]: {
            width:'98%',
            margin: '0px 1% 15px 1%',
 
        },
    },
    headerRightWrapper:{
        paddingLeft: '0.5rem',
        '& .headerRightWrapper': {
            height: '100%',
            padding: '1rem 1.5rem',
            borderRadius: 12,
            boxShadow: '0 3px 6px 0 rgba(13, 66, 159, 0.16)',
            background: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            [theme.breakpoints.between(XS, SM)]: {
                margin: '0.5rem 0',
            },
            '& .buttonVideoMp4':{
                borderRadius: 12,
                display: 'flex',
                padding: '0.5rem',
                boxShadow: '0 3px 6px 0 rgba(13, 66, 159, 0.16)',
                backgroundImage: 'linear-gradient(180deg, #fff, #e4edf8)',
                textTransform: 'uppercase',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                lineHeight: '15px',
                [theme.breakpoints.between(XS, SM)]: {
                   fontSize:'0.875rem',
                   lineHeight: '0.975rem',
                },
                '& img':{
                    paddingRight: '0.5rem',
                },
                '&:hover':{
                    background: '#e4edf8',
                    transition: 'all .4s ease-in-out',
                }
            },
            '& .documentIconWrapper':{
                cursor: 'pointer',
                '& .circleBlue':{
                    background: '#2d7ec8',
                    borderRadius: '50%',
                    height: 25,
                    width: 25,
                    color: '#fff',
                    fontSize: '1rem',
                    position: 'relative',
                    marginLeft: 30,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 700,
                },
                '& img':{
                    marginTop: -25,
                    marginLeft: '0.6rem',
                    width: '36px',
                }
            },

            [theme.breakpoints.between(XS, SM)]: {
                margin: 'initial',
            },
         
        },

        [theme.breakpoints.between(XS, SM)]: {
            width:'34%',
            margin: '0px 1%',

            
            '& .documentIconWrapper img':{
                width: '34px !important',
            }
          
        },
        [theme.breakpoints.down(XS)]: {
            width:'98%',
            margin: '0px 0px 15px 1%',
            paddingLeft: '0px',
            display: 'none' //not necessary to display on small device
        },

        
    },
    tabsWrapper:{
        padding: '2rem 0 1rem 0',
        '& .tabWrapper':{
            border: '1px solid #0071c7',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0.5rem',
            cursor: 'pointer',
            lineHeight: '16px',
            '&:not(:last-child):hover':{
                background: '#dbf1fe',
                transition: 'all .4s ease-in-out',
            },
            '& img':{
                height: 30
            },
            '&:first-child':{
                borderRadius: '24px 0 0 24px'
            },
            '&:last-child':{
                borderRadius: '0 24px 24px 0',
                backgroundImage: `url(${seaIcon} )`,
                backgroundPositionX: 'left',
                backgroundPositionY: 'center',
                cursor: 'revert',
            },
            [theme.breakpoints.between(XS, SM)]: {
                '&:nth-last-child(2)':{
                    borderRadius: '0 24px 24px 0',
                },
                '&:last-child':{
                    display: 'none'
                },

                '& img':{
                    height: 23,
                },
            },
            '&.active':{
                background: '#afdbfd',
                '& .labelTab': {
                    fontWeight: 700
                }
            },
            '& .labelTab':{
                paddingLeft: '0.5rem',
                fontFamily: NUNITO,
                fontSize: 15,
                fontWeight: 500,
                fontHeight: 0.76,
                color: '#0071c7',
                textTransform: 'uppercase',
                [theme.breakpoints.between(XS, SM)]: {
                    fontSize:'0.775rem'
                },
            }
        },

        [theme.breakpoints.between(XS, SM)]: {
            margin: '0px 1%',
            padding: '1rem 0 1rem 0',
        },
    },
    contentBlock:{
        padding: '1rem',
        [theme.breakpoints.between(XS, SM)]: {
            padding: '0 1rem',
        },
    },
    btnChat: {
        padding: 5,
        minWidth: 'unset',
        '& svg path': {
            fill: '#007ec8'
        },
        '& .MuiButton-label': {
            position: 'relative',
        },
        '& .hasNewChat': {
            width: 12,
            height: 12,
            backgroundColor: '#f02849',
            borderRadius: '50%',
            position: 'absolute',
            top: -4,
            right: -4,
        }
    },
    videoCallBlock:{
        paddingLeft: 10
    }
});

const TAB_REPORT = "TAB_REPORT";
export const TAB_DOCUMENT = "TAB_DOCUMENT";
export const TAB_CALENDER = "TAB_CALENDER";

class RequestDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            tabShowRequest: TAB_DOCUMENT,
            requestItem: null,
            isLoading: false,
            changeTabDocumentLeft: "",
            countNbResultDocumentRequest: 0,
            anchorEl: null,
            loadingRequest: false
        };
    }

    handleChangeFieldReqDeaPage =(value, field)=>{
        let stateFieldNew = {
            [field]: value
        }
        if (field === "changeTabDocumentLeft") {
            stateFieldNew = {...stateFieldNew, tabShowRequest: TAB_DOCUMENT}
        }
        this.setState(stateFieldNew)
    }

    componentDidMount() {
        const {match, location, dataUser}= this.props;
        let searchParam = location.search ??"";
        if(searchParam !== "" && searchParam !== "?" && typeof searchParam === STRING){
            searchParam = searchParam.substr(1);
            let paramUrl =  qs.parse(searchParam);
            if(paramUrl.hasOwnProperty("tabShowRequest") && [TAB_REPORT,TAB_DOCUMENT,TAB_CALENDER].includes(paramUrl.tabShowRequest)){
                this.setState({
                    tabShowRequest: paramUrl.tabShowRequest
                })
            }
        }

        const requestIdPage = (match?.params?.id) ? match.params.id : null
        const requestTokenPage = (match?.params?.token) ? match.params.token : null

        const isUploadFileTokenPage =  (match?.path) === REQUEST_UPLOAD_FILE_TOKEN

        if (requestIdPage || requestTokenPage) {
            let urlGetShow = !isUploadFileTokenPage ? API_GET_SHOW_REQUEST.replace(":id", requestIdPage) :
                `${API_GET_SHOW_REQUEST_UPLOAD_FILE_TOKEN}?token=${requestTokenPage}`
            this.setState({isLoading: true})
            axios.get(urlGetShow)
                .then(response => {
                    if (response.status === 200) {
                        this.setState({
                            requestItem: response.data.item,
                            isLoading: false
                        })
                    } else {
                        throw new Error();
                    }
                })
                .catch(error => {
                    if(isUploadFileTokenPage && (error?.response?.status) === 404){
                        window.location.href = links.PAGE404;
                    }
                    this.setState({
                        isLoading: false
                    })
                })
        }
        if(isUploadFileTokenPage) {
            try {
                const eventSource = new EventSource(REACT_APP_MECURE_URL + "?topic=chat", {
                    withCredentials: false
                });
                eventSource.onmessage = event => {
                    const data = JSON.parse(event.data);
                    const chat = data["chat"];
                    const groupUser = dataUser.data?.group;
                    const currentUserId = dataUser.data?.id;
                    const requestId = chat?.request?.id;
                    const userId = chat?.request?.userId;
                    const instanceofSender = chat?.sender.instanceof;
                    if (
                        requestId && parseInt(requestId) === parseInt(requestIdPage) &&
                        USER_TYPE_CLIENT === groupUser && userId === currentUserId && instanceofSender !== USER_TYPE_CLIENT
                    ) {
                        this.changeFieldClientSawManagerLastChatInRequestItem()
                    }
                }
                eventSource.onerror = () => {
                    eventSource.close()
                }
            } catch (err) {

            }

            try {
                const eventSource = new EventSource(REACT_APP_MECURE_URL + "?topic=videoCall", {
                    withCredentials: false
                });
                eventSource.onmessage = event => {
                    const dataVideoCall = JSON.parse(event.data);
                    const videoCall = dataVideoCall["videoCall"];
                    if (parseInt(videoCall.request) === parseInt(requestIdPage)) {
                        this.changeVideoCallingFieldInRequestItem({
                            meetingId: videoCall.meetingId,
                            status: videoCall.status,
                        })

                    }
                }
                eventSource.onerror = () => {
                    eventSource.close()
                }
            } catch (e) {
                // console.log(e);
            }
        }
    }

    changeVideoCallingFieldInRequestItem = (videoCalling)=>{
        this.setState({
            requestItem: {...this.state.requestItem, videoCalling: (videoCalling.status === END_VIDEO_CALL ? null : videoCalling)},
        })
    }
    changeFieldClientSawManagerLastChatInRequestItem = ()=>{
        if(this.state.popoverType !== POPOVER_ADD_CHAT) {
            this.setState({
                requestItem: {...this.state.requestItem, clientSawManagerLastChat: false},
            })
        }
    }

    handleOpenPopOver = (event, type) => {
        const {requestItem} = this.state
        let newStatePopover = {
            anchorEl: event.currentTarget,
            popoverType: type,
        }
        if (type === POPOVER_ADD_CHAT && !requestItem.clientSawManagerLastChat) {
            newStatePopover = {...newStatePopover, requestItem: {...requestItem, clientSawManagerLastChat: true}}
        }
        this.setState(newStatePopover);
    }

    handleClosePopOver = () => {
        this.setState({
            anchorEl: null,
            popoverType: "",
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.loadingRequest) {
            this.setState({
                loadingRequest: false
            })
        }
    }

    render() {
        const {classes,t, dataUser, location, match} = this.props;
        const {tabShowRequest, isLoading,requestItem, changeTabDocumentLeft, countNbResultDocumentRequest, anchorEl, popoverType} = this.state;
        const status = requestItem && requestItem.status && requestItem.status.statusType ? requestItem.status.statusType.code : null;
        const groupUser = dataUser && dataUser.data ? dataUser.data.group : "";

        const isNotUploadFileTokenPage = (match?.path) !== REQUEST_UPLOAD_FILE_TOKEN
        return (
            <div>
                {isLoading && <LoadingAction />}
                <div className={`${classes.headerRequestDetailPage} ${isNotUploadFileTokenPage? "": "isPageUploadFile"}`}>
                    <NavBar2 noReturn={!isNotUploadFileTokenPage}/>
                </div>
                <Grid container className={classes.requestDetailPage}>
                    {requestItem &&<Grid className={"headercontent"} container item xs={12}>
                        <Grid container item sx={12} sm={12} md={9} className={classes.headerLeftWrapper}>
                            <Grid container item sx={12} sm={12} xs={12} className={"headerLeftWrapper"}>
                                <Grid item sx={12} sm={12} md={6} lg={7} className={"headerLeftContentLeftWrapper"}>
                                    <div
                                        className={"labelProblem"}>{t("request.requestDetail.encounteredProblem")}</div>
                                    {requestItem && requestItem.subject ?
                                        <Tooltip title={requestItem.subject} arrow>
                                            <div className="problemRequest contentProblem">{requestItem.subject}</div>
                                        </Tooltip>
                                        :
                                        requestItem?.problem ? <Tooltip
                                            title={<div dangerouslySetInnerHTML={{__html: requestItem.problem}}/>}
                                            arrow>
                                            <div className="problemRequest contentProblem"
                                                 dangerouslySetInnerHTML={{__html: requestItem.problem}}/>
                                        </Tooltip> : ""}
                                </Grid>
                                {isNotUploadFileTokenPage && <Grid item sx={12} sm={12} md={6} lg={5} className={"headerLeftContentRightWrapper"}>
                                    {/*<div className={"headerLeftContentRightBlock"}>*/}
                                    {/*    <div>*/}
                                    {/*        <div className={"headerDate"}>{t("request.requestDetail.dateVisioDiagnostic")}</div>*/}
                                    {/*        <div*/}
                                    {/*            className={"valueShow"}>{requestItem && requestItem.registrationStart ? ConvertToDateD_M_Y(requestItem.registrationStart) : ""}</div>*/}
                                    {/*    </div>*/}
                                    {/*    <div  className={"headerHour"}>*/}
                                    {/*        <div>{t("request.requestDetail.hour")}</div>*/}
                                    {/*        <div*/}
                                    {/*            className={"valueShow"}>{requestItem && requestItem.registrationTimeStart ? moment().hour(requestItem.registrationTimeStart / 60).minute(requestItem.registrationTimeStart % 60).second(0).format('HH:mm') : ""}</div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <Button
                                        onClick={(e) => this.handleOpenPopOver(e, POPOVER_ADD_CHAT)}
                                        className={classes.btnChat}
                                    >
                                        <ChatIcon/>
                                        {requestItem && ((groupUser === USER_TYPE_CLIENT && !requestItem.clientSawManagerLastChat) || (groupUser !== USER_TYPE_CLIENT && !requestItem.managerSawClientLastChat)) &&
                                        <div className="hasNewChat"></div>}
                                    </Button>

                                    {!!(requestItem?.videoCalling) ?
                                        <div className={classes.videoCallBlock}><VideoCallBlock
                                            request={requestItem}
                                            centerButton={true}
                                            changeDataRequestItem={(requestNew) => {
                                                this.setState({
                                                    requestItem: {...requestNew},
                                                })
                                            }}
                                        /></div> : ""}
                                </Grid>}
                            </Grid>
                        </Grid>
                        {isNotUploadFileTokenPage && <Grid item sx={12} sm={12} md={3} className={classes.headerRightWrapper}>
                            <div className={"headerRightWrapper"}>
                                <div className={"buttonVideoMp4"}
                                     onClick={() => this.handleChangeFieldReqDeaPage(REQUEST_DOC_TYPE_VIDEO, "changeTabDocumentLeft")}>
                                    <img alt={""} src={videoIcon}/>
                                    <div>{t("request.requestDetail.videoMp4")}</div>
                                </div>
                                <div className={"documentIconWrapper"}
                                     onClick={() => this.handleChangeFieldReqDeaPage(TAB_DOCUMENT, "tabShowRequest")}>
                                    <div className={"circleBlue"}>{countNbResultDocumentRequest}</div>
                                    <img alt={""} src={documentIcon}/>
                                </div>
                            </div>
                        </Grid>}
                    </Grid>}
                    {isNotUploadFileTokenPage && <Grid container className={classes.tabsWrapper}>
                        {/*<Grid item xs={3} className={`tabWrapper ${tabShowRequest === TAB_REPORT? "active":""}`}*/}
                        {/*      onClick={() => this.handleChangeFieldReqDeaPage(TAB_REPORT, "tabShowRequest")}>*/}
                        {/*    <img alt={""} src={reportIcon}/>*/}
                        {/*    <div className={"labelTab"}>{t("request.requestDetail.tabs.tabReport")}</div>*/}
                        {/*</Grid>*/}
                        <Grid item xs={6} sm={6} md={4}
                              className={`tabWrapper ${tabShowRequest === TAB_DOCUMENT ? "active" : ""}`}
                              onClick={() => this.handleChangeFieldReqDeaPage(TAB_DOCUMENT, "tabShowRequest")}>
                            <img alt={""} src={documentIcon}/>
                            <div className={"labelTab"}>{t("request.requestDetail.tabs.tabDocument")}</div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}
                              className={`tabWrapper ${tabShowRequest === TAB_CALENDER ? "active" : ""}`}
                              onClick={() => this.handleChangeFieldReqDeaPage(TAB_CALENDER, "tabShowRequest")}>
                            <img alt={""} src={calenderIcon}/>
                            <div className={"labelTab"}>{t("request.requestDetail.tabs.tabCalender")}</div>
                        </Grid>
                        <Grid item xs={4} className={"tabWrapper"}>
                        </Grid>
                    </Grid>}
                    {requestItem && <Grid container className={classes.contentBlock}>
                        {(tabShowRequest === TAB_DOCUMENT && this.state.loadingRequest === false) ?
                            <TabRequestDocument
                                chatDocuments={Array.isArray(requestItem?.chatDocuments)? requestItem.chatDocuments : []}
                            requestItem={requestItem}
                            // showActionShowAndDownload={this.showActionShowAndDownload}
                            loadingRequest={() => {
                            this.setState({
                                loadingRequest: true
                            })
                        }} changeTabDocumentLeft={changeTabDocumentLeft} handleChangeFieldReqDeaPage={this.handleChangeFieldReqDeaPage}/>:
                            tabShowRequest === TAB_CALENDER && ![WAITING_FOR_REPLY, MAYBE, null, GO_DOC].includes(status) ? <TabRequestDate/>:""}
                    </Grid>}
                </Grid>
                {anchorEl && requestItem && popoverType === POPOVER_ADD_CHAT &&
                <PopoverAddChat
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClosePopOver}
                    formValuePopover={requestItem}
                />
                }
            </div>
        )
    }
}

RequestDetailPage.defaultProps = {}

RequestDetailPage.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        dataUser: state.authReducer.dataUser,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOpenModalAddRequestInList: (openModalAddRequestInList) => dispatch(application.setOpenModalAddRequestInList(openModalAddRequestInList) )
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter
)(RequestDetailPage);
