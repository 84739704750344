import React from 'react';
import {compose} from "redux";
import {Checkbox, Grid, withStyles} from "@material-ui/core";
import CustomButton from "../../../../theme/CustomElements/CustomButton";
import CustomInput from "../../../../theme/CustomElements/CustomInput";
import returnIcon from "../../../../images/request/return.svg";
import {withTranslation} from "react-i18next";
import {MONTSERRAT, NUNITO} from "../../../../constants/fontCss";
import GoActionIcon from "../../../../images/request/go_action.svg";
import GoDocActionIcon from "../../../../images/request/go_doc_action.svg";
import SendToIconIcon from "../../../../images/request/paper-plane.svg";
import xIcon from "../../../../images/request/x.svg";
import maybeIcon from "../../../../images/request/maybe.svg";
import CustomInlineSvg from "../../../../theme/CustomElements/CustomInlineSvg";
import {GO_DOC, MAYBE, NOT_BE_REPAIRED, REPAIRED, WAITING_FOR_REPLY} from "../../../../constants/constants";
import ErrorInput from "../../../../theme/form/Alert/ErrorInput";
import InputAdornment from '@material-ui/core/InputAdornment';
import GeneralModal from "../../../../theme/Modal/GeneralModal";
import closeModal from "../../../../images/theme/close.svg";
import {connect} from "react-redux";

const styles ={
    editStatusModal: {
        borderRadius: 10,
        border: 'none',
        maxHeight: 400,
        '& .headerModalWrapper': {
            display: 'none'
        },
        '& .bodyModalWrapper': {
            padding: 0,
            height: '100%',
            maxHeight: 'unset'
        }
    },
    popoverEditStatus: {
        background: '#fff',
        boxShadow: '0px 0px 20px rgba(0, 0, 254, 0.05)',
        borderRadius: 10,
        height: '100%',
    },
    headerEditStatus: {
        background: '#555555',
        boxShadow: '0px 0px 20px rgba(0, 0, 254, 0.05)',
        borderRadius: '10px 10px 0px 0px',
        color: '#fff',
        fontWeight:600,
        padding: '10px 20px',
        height: 70,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontFamily: NUNITO,
        '& .toRespondTo': {
            opacity: 0.58,
            fontSize: '0.9'
        },
        '& .fullName': {
            fontSize: '1.25rem',
        },
        '& .headerLeft': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        '& .closeButtonModal': {
            cursor: 'pointer',
            marginLeft: 20,
            '& img': {
                width: 12,
                height: 12
            }
        }
    },
    contentEditStatusWrapper:{
        padding: '15px 0px 15px 20px',
        height: 'calc(100% - 40px)',
        '& .statusType':{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0.25rem',
            '& .tabStatusType': {
                padding: '0.5rem 1rem',
                textTransform: 'uppercase',
                background: '#fff !important',
                width: '100%',
                height: '100%',
                fontSize: '0.875rem',
                borderRadius: 10,
                '&:before':{
                    background: 'none !important'
                },
                '&.tabActive':{
                    color: '#fff !important',
                    fontWeight: 600,
                    '&.notBeRepaired': {
                        background: '#E33838 !important',
                    },
                    '&.repaired': {
                        background: '#0DBE2A  !important',
                    },
                    '&.maybe': {
                        background: '#707070  !important',
                    },
                    '&.goAction': {
                        background: '#0000fe  !important',
                    },
                    '& svg':{
                        '& path':{
                            fill: '#fff'
                        }
                    }
                },
            },
            '& .notBeRepaired': {
                border: '1px solid #d49f9f',
                color: '#d24545',
                '&:hover':{
                    background: '#ffe3e3 !important'
                },
                '& svg':{
                    '& path':{
                        fill: '#d24545'
                    }
                }
            },
            '& .repaired': {
                border: '1px solid #0DBE2A',
                color: '#0DBE2A',
                '&:hover':{
                    background: '#deffe0 !important'
                },
                '& svg':{
                    '& path':{
                        fill: '#0DBE2A'
                    }
                }
            },
            '& .maybe': {
                border: '1px solid #b4b4b4',
                color: '#707070',
                '&:hover':{
                    background: '#d8d5d5 !important'
                },
                '& svg':{
                    '& path':{
                        fill: '#707070'
                    }
                }
            },
            '& .goAction': {
                border: '1px solid #8f8fdc',
                color: '#0000fe',
                '&:hover':{
                    background: '#dbdbf9 !important'
                },
                '& svg':{
                    '& path':{
                        fill: '#0000fe'
                    }
                }
            }
        },
        '& .inputWrapper':{
            padding: '0.25rem',
            '&.goFreeWrapper':{
                display: 'flex',
                alignItems: 'center',
                '& .goFreeLabel': {
                    color: '#001529',
                    fontWeight: 700,
                    fontSize: '0.875rem',
                    lineHeight: 1.2
                }
            }
        },
        '& .btnAction':{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '0.25rem',
            minHeight: 51
        },
        '& .comment': {
            backgroundColor: '#ebf2f8',
            borderRadius: 8,
            border: 'none',
            height: '100%'
        },
        '& .btnActionStatus': {
            padding: '10px 15px',
            '& .priceBox': {
                height: 160,
            }
        }
    },
    btnResponse:{
        background: '#0000FF !important',
        borderRadius: 10,
        width: '100%',
        '& .MuiButton-label':{
            fontFamily: MONTSERRAT,
            fontWeight: 700,
            fontSize: '0.875rem'
        }
    }
};

class PopoverEditStatus extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            price: "",
            comment: "",
            isGoFree: false
        }
    }

    handleChange = (value, field) =>{
        let valueNew = value;
        let stateChangeNew = {};
        if (field === "price" && valueNew) {
            valueNew= valueNew.replace(",", ".")
            let regexPrice = /^[0-9.]+$/g;
            if (valueNew.match(regexPrice)) {
                let regexPriceDecimal = /^[0-9]+[.]+([0-9]{3,})$/g
                if (valueNew.indexOf(".") !== -1 && (valueNew.split(".").length > 2 || valueNew.match(regexPriceDecimal))) {
                    valueNew = this.state.price;
                }
            } else {
                valueNew = this.state.price;
            }
        }
        if(field === "isGoFree" && value){
            this.props.handleChangeField("", "price");
            stateChangeNew = {...stateChangeNew , price: "" }
        }
        this.props.handleChangeField(value, field);
        stateChangeNew = {...stateChangeNew,[field]: valueNew }
        this.setState(stateChangeNew)
    }
    render() {
        const { anchorEl,popoverAriaOwns ,onClose, currency, classes,formValuePopover, t, changeStatus, errors} = this.props;
        const statusBegin = formValuePopover && formValuePopover.status && formValuePopover.status.statusType ? formValuePopover.status.statusType.code : "";
        const {price, comment, isGoFree} = this.state;

        return <GeneralModal
            className={classes.editStatusModal}
            open={anchorEl}
            handleClose={onClose}
            showCloseButton={false}
            content={<div className={classes.popoverEditStatus}>
                <div className={`${classes.headerEditStatus} headerEditStatus`}>
                    <div>
                        <div className="toRespondTo">
                            {t('request.popoverChangeStatus.toRespondTo')}
                        </div>
                        <div className="fullName">
                            {formValuePopover.fullName ?? ""}
                        </div>
                    </div>
                    <div className="headerLeft">
                        <div>{t("request.list.id")}: {formValuePopover.id}</div>
                        <div
                            className={`${classes.closeButton} closeButtonModal`}
                            onClick={onClose}
                        >
                            <img alt={'close button'} src={closeModal}/>
                        </div>
                    </div>
                </div>
                <Grid className={`${classes.contentEditStatusWrapper} contentEditStatusWrapper`}>
                    <Grid container>
                        <Grid item xs={8}>
                            <CustomInput multiline
                                         rowsMax={5}
                                         rows={5}
                                         placeholder={t("request.popoverChangeStatus.comment")}
                                         className={"comment"}
                                         onChange={(e)=>this.handleChange(e.target.value, "comment")}
                                         value={comment}
                                // validate={errors.comment}
                            />
                        </Grid>
                        <Grid item xs={4} className="btnActionStatus">
                            <Grid item className={"statusType"}>
                                <CustomButton
                                    title={this.props.t('request.status.GO_DOC')}
                                    className={`tabStatusType repaired ${changeStatus === GO_DOC ? "tabActive" : ""}`}
                                    buttonProps={{
                                        startIcon: <React.Fragment>
                                            <CustomInlineSvg
                                                svgProps={{
                                                    src: GoDocActionIcon
                                                }}
                                            />
                                        </React.Fragment>
                                    }}
                                    onClick={(e)=>this.props.handleChangeField(GO_DOC, "changeStatus")}
                                />
                            </Grid>
                            <Grid item className={"statusType"}>
                                <CustomButton
                                    title={this.props.t('request.status.REPAIRED')}
                                    className={`tabStatusType goAction ${changeStatus === REPAIRED ? "tabActive" : ""}`}
                                    buttonProps={{
                                        startIcon: <React.Fragment>
                                            <CustomInlineSvg
                                                svgProps={{
                                                    src: GoActionIcon
                                                }}
                                            />
                                        </React.Fragment>
                                    }}
                                    onClick={(e)=>this.props.handleChangeField(REPAIRED, "changeStatus")}
                                />
                            </Grid>
                            <Grid item className={"statusType"}>
                                <CustomButton
                                    title={this.props.t('request.status.NOT_BE_REPAIRED')}
                                    className={`tabStatusType notBeRepaired ${changeStatus === NOT_BE_REPAIRED ? "tabActive" : ""}`}
                                    buttonProps={{
                                        startIcon: <React.Fragment><CustomInlineSvg
                                            svgProps={{
                                                src: xIcon
                                            }}
                                            scale="0.8"
                                        /></React.Fragment>
                                    }}
                                    onClick={(e)=>this.props.handleChangeField(NOT_BE_REPAIRED, "changeStatus")}
                                />
                            </Grid>
                            <Grid item className="priceBox">
                                {/*{![REPAIRED, NOT_BE_REPAIRED].includes(statusBegin) &&*/}
                                <Grid item className={"statusType"}>
                                    <CustomButton
                                        title={this.props.t('request.status.MAYBE')}
                                        className={`tabStatusType maybe ${changeStatus === MAYBE ? "tabActive" : ""}`}
                                        buttonProps={{
                                            startIcon: <React.Fragment><CustomInlineSvg
                                                svgProps={{
                                                    src: maybeIcon
                                                }}
                                                scale="0.8"
                                            /></React.Fragment>
                                        }}
                                        onClick={(e)=>this.props.handleChangeField(MAYBE, "changeStatus")}
                                    />
                                </Grid>
                                <ErrorInput textValidate={errors.changeStatus} />
                                {/*{changeStatus === REPAIRED && <Grid item className={"inputWrapper goFreeWrapper"}>*/}
                                {/*    <Checkbox*/}
                                {/*        checked={isGoFree}*/}
                                {/*        onChange={(e)=>this.handleChange(e.target.checked, "isGoFree")}*/}
                                {/*    />*/}
                                {/*    <div className={"goFreeLabel"}>{t("request.popoverChangeStatus.goFree")}</div>*/}
                                {/*</Grid>}*/}
                                {/*{changeStatus === REPAIRED && <Grid item className={"inputWrapper"}>*/}
                                {/*    <CustomInput className={"price"}*/}
                                {/*                 type="text"*/}
                                {/*                 placeholder={t("request.popoverChangeStatus.price")}*/}
                                {/*                 onChange={(e)=>this.handleChange(e.target.value, "price")}*/}
                                {/*                 value={price}*/}
                                {/*                 InputProps={{*/}
                                {/*                     startAdornment: (*/}
                                {/*                         <InputAdornment position="start">*/}
                                {/*                             {currency && currency.unit ? currency.unit :""}*/}
                                {/*                         </InputAdornment>*/}
                                {/*                     ),*/}
                                {/*                 }}*/}
                                {/*                 validate={errors.price}*/}
                                {/*                 disabled={isGoFree}*/}
                                {/*    />*/}
                                {/*    <ErrorInput textValidate={errors.price} />*/}
                                {/*</Grid>}*/}
                            </Grid>
                           <Grid item className={"btnAction"}>
                               {changeStatus &&
                                   // statusBegin !== changeStatus &&
                                   <CustomButton
                                    className={classes.btnResponse}
                                    title={this.props.t("request.list.btnResponse")}
                                    buttonProps={{
                                        startIcon: <React.Fragment><CustomInlineSvg
                                            svgProps={{
                                                src: SendToIconIcon
                                            }}
                                            scale="0.8"
                                        /></React.Fragment>
                                    }}
                                    onClick={this.props.handleSave}

                                />}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>}
            saveButton={false}
            cancelButton={false}
            hideFooter={true}
        />;
        // return <PopoverCustom
        //     anchorEl={anchorEl}
        //     popoverAriaOwns={popoverAriaOwns}
        //     onClose={onClose}
        //     content={
        //
        //     }
        //     />
    }
}

const mapStateToProps = (state) => {
    return {
        currency: state.applicationReducer.currency,
    };
};

const mapDispatchToProps = (dispatch)=>{
    return {
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),withStyles(styles), withTranslation())(PopoverEditStatus);
