import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {MONTSERRAT, NUNITO} from "../constants/fontCss";
import {withRouter} from "react-router";
import * as links from "../constants/links";
import {NavLink} from "react-router-dom";
import AddRequestModal from "./client/request/modal/AddRequestModal";
import {MD, SM, XS} from "../constants/constants";
import returnIcon from "../images/navbar/Groupe 8620.svg";

const styles = theme => ({
    navbar2Wrapper: {
        // padding: '1rem 5rem',
        position: 'relative',
        height: '100%',
        '& .navbar2': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            height: '100%',
            [theme.breakpoints.down(SM)]:{
                flexDirection: 'column'
            }
        },
        '& .titlePage': {
            fontFamily: MONTSERRAT,
            fontSize: '1.5rem',
            display: 'flex',
            alignItems: 'center',
            paddingRight: '1rem',
            fontWeight: 700,
            color: '#fff'
        },
        '& .headerInformation': {
            width: '100%',
            height: '100%',
            display: 'flex',
            '& .returnRequest':{
                display: 'flex',
                height: '100%'
            },
            '& .returnHistory': {
                borderRadius: 24,
                border: 'solid 1px #ffffff',
                color:'#fff',
                textDecoration: 'none',
                padding: '0.5rem 1rem',
                margin: 'auto 0',
                marginLeft: '3rem',
                display: 'flex',
                alignItems: 'center',
                zIndex: 20,
                '&:hover':{
                    background:'linear-gradient(270deg, rgba(14, 56, 96, 0) 8.24%, #0E2F60 34.94%, #0A0730 97.58%)',
                    backgroundPosition: '100% 0',
                    transition: 'all .4s ease-in-out',
                },
                '& > span':{
                    fontSize: '1.1125rem',
                    paddingLeft: '0.5rem',
                    lineHeight: 0.9,
                    fontFamily: NUNITO
                }
            },
            '& .titleInformation': {
                color: '#fff',
                fontFamily: MONTSERRAT,
                fontWeight: 700,
                fontSize: '2rem',
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                width: '100%',
                zIndex: 1,
                top: '10%',
                // marginBottom: '4rem',
                [theme.breakpoints.down(MD)]:{
                    top: '50%',
                    transform: 'translateY(-50%)'
                },
                [theme.breakpoints.down(XS)]:{
                    // paddingRight: '3rem',
                    // justifyContent: 'flex-end',
                    fontSize: '1.25rem',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center'
                }
            }
        },
        '& a':{
            cursor: 'point',
            '&.addRequest':{
                textDecoration: 'none'
            }
        },
        '& .addRequestBlock:first-child': {
            [theme.breakpoints.between(XS, SM)]: {
                alignItems: 'flex-start'
            },
        },
        '& .addRequestBlock': {
            marginRight: '6rem',
            padding: '35px',
            display: 'flex',
            alignItems: 'center',
            '& .reportIconWrapper':{
                padding: '0.6rem 0.6rem 0.3rem 0.6rem',
                margin: '16px 50px 0px 50px',
                position: 'relative',
                fontFamily: NUNITO,
                minWidth: '100px',
                transition: '0.2s ease',
                top:'0px',
                // '&:hover': {
                //     background: 'rgba(233, 232,232, 1)'
                // },
                '& a':{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textDecoration: 'none',
                    '& svg':{
                        '& path':{
                            fill: '#fff'
                        }
                    }
                },
                '& .circleNumber':{
                    background: '#fff',
                    color: '#18409a',
                    fontSize: '1rem',
                    borderRadius:'50%',
                    height: 30, width: 30,
                    position: 'absolute',
                    right: 3,
                    top: -8,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                },
                '& .labelReportIcon':{
                    fontSize: '0.975rem',
                    fontWeight: 600,
                    color: '#fff'
                },
                [theme.breakpoints.down(MD)]:{
                    marginLeft: '0rem',
                },
                '&:hover':{
                    top: '-2px',
                    transition: '0.2s ease',
                    background: 'rgba(255,255,255,0.1)',
                    borderRadius: '8px',
                }


            },
            [theme.breakpoints.between(XS, SM)]: {
               
            },
            [theme.breakpoints.down(XS)]:{
                flexDirection: 'row',
                paddingRight: '1.3rem !important',
                paddingLeft: '1.3rem !important',
            },
            [theme.breakpoints.down(MD)]:{
                width: '100%',
                textAlign: 'center',
                margin: '0.5rem 0',
                justifyContent: 'space-between',
                paddingTop: '26px',
                //paddingBottom: '0',
                paddingRight: '24px',
                paddingBottom: '20px',

             
            },
        },
        '& .notificationUser': {
            display: 'flex',
            flexDirection: 'column',
            color: '#fff',
            marginLeft: '4rem',
            wordBreak: 'break-word',

            '& .clientName':{
                fontWeight: 200,
                fontSize: '1.6rem',
                marginBottom: '0.5rem',
                lineHeight: '1.9rem',
                marginTop: '0.4rem',
                marginRight: '2rem',

                [theme.breakpoints.down(MD)]:{
                    fontSize: '1.45rem',
                    lineHeight: '1.6rem',
                    marginRight: '4rem',
                },
            },
            '& .alertElement':{
                display: 'flex',
                alignItems: 'center',
                fontSize: '0.875rem',
                cursor: 'pointer',
                '&:hover':{
                    background: '#0c204e',
                    borderRadius: '10px 10px 0 0'
                },
                '&.activeFilter':{
                    background: '#0e2d5e',
                    borderRadius: '10px 10px 0 0'
                },
                '& .alarmDetail':{
                    marginRight: '1rem',
                    position: 'relative',
                    '& svg':{
                        '& path':{
                            fill: '#00d5ff'
                        }
                    },
                    '& .circleAlarm':{
                        display: 'inline-block',
                        width: 19,
                        height: 19,
                        fontWeight: 800,
                        fontSize: '0.875rem',
                        lineHeight: '19px',
                        textAlign: 'center',
                        borderRadius: '50%',
                        background: '#00d5ff',
                        color: '#13387b',
                        position: 'absolute',
                        top: -7,
                        right: -10
                    }
                },
                '& .underline':{
                    marginLeft: 5,
                    position: 'relative',
                    '&:before':{
                        content: '""',
                        width: '100%',
                        height: 1,
                        background: '#fff',
                        position: 'absolute',
                        bottom: 2
                    }
                }
            },
            [theme.breakpoints.down(MD)]:{
                width: '100%',
                marginLeft: '0 !important',
                paddingLeft: '2.8rem',
                marginTop: '0.6rem',
            },
            [theme.breakpoints.down(XS)]:{

                '& .alertElement':{
                    justifyContent: 'center'
                }
            },
        },
        '& .btnAdd': {
            backgroundImage: 'linear-gradient(95deg,#00c1d1, #0070c7, #00c1d1) !important',
            boxShadow: '0px 4px 20px rgba(15, 15, 137, 0.1) !important',
            borderRadius: 55,
            width: 438,
            maxWidth: '365px',
            height: 75,
            backgroundSize: '200%',
            transition: 'all .4s ease-in-out',
            textTransform: 'none',
            paddingRight: '72px',
            '&:before':{
                background: 'none!important'
            },
            '& .MuiButton-startIcon':{
                marginRight: '35px',
                position: 'relative',
                top: '-2px',
                left: '-7px',
            },
            '& svg':{
                width: 50,
                height: 50
            },
            '& .MuiButton-label':{
                fontFamily: NUNITO,
                fontSize: '1.3rem',
                lineHeight: '1.5rem',
                fontWeight: '600',
            },
            '&:hover':{
                backgroundPosition: '100% 0',
                transition: 'all .4s ease-in-out',
            },
            [theme.breakpoints.down(XS)]:{
                width: 'auto!important',
                height: 'auto!important',
                padding: '10px 20px',
                borderRadius: 40,
                fontSize: '0.8rem',
                '& .MuiButton-label':{
                    fontSize: '1rem',
                    lineHeight: '1rem',
                },
                '& .MuiButton-startIcon':{
                    marginRight: '5px',
                    left: '-6px',
                },
                '& svg':{
                    width: 33,
                    height: 32,
                }
            }
        },
        '& .returnRequestList': {
            borderRadius: 24,
            border: 'solid 1px #ffffff',
            color:'#fff',
            textDecoration: 'none',
            padding: '0.5rem 1rem',
            marginLeft: '3rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            maxWidth: 150,
            [theme.breakpoints.between(XS, SM)]: {
                marginLeft: '1rem',
            },
            '&:hover':{
                //background:'linear-gradient(270deg, rgba(14, 56, 96, 0) 8.24%, #0E2F60 34.94%, #0A0730 97.58%)',
                background: '#ffffff70',
                //color: '#0c1c49',
                color: '#fff',
                backgroundPosition: '100% 0',
                transition: 'all .4s ease-in-out',
            },
            '& > span':{
                fontSize: '1.1125rem',
                paddingLeft: '0.5rem',
                lineHeight: 0.9,
                fontFamily: NUNITO
            }
        }
    },
    btnAdd: {
        background: '#00D5FF !important',
        boxShadow: '0px 4px 20px rgba(15, 15, 137, 0.1) !important',
        borderRadius: 10,
        '& .MuiButton-label':{
            fontFamily: NUNITO,
            fontSize: '1rem',
            fontWeight: 400
        },
    },

});

class NavBar2 extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.state = {
            noReturn: props.noReturn || false
        }
    }

    render() {
        const {
            classes, t, location, history,countRequestNotPaid,apiParams, match
        } = this.props;
        const { noReturn } = this.state;
        const pathPage = location && location.pathname ? location.pathname : "";
        const returnInfo = history && history.location && history.location.pathname && history.location.pathname !== pathPage ? history.location.pathname : pathPage===links.INFORMATION_PAGE? links.REQUEST: links.HOME;

        return (
                <div className={classes.navbar2Wrapper}>
                    {[links.REQUEST,links.REPORT, links.REQUEST_INFORMATION_DETAIL.replace(":id", '')].includes(pathPage.replace(/[0-9]/g, '')) ? <div className={"navbar2"}>
                            {/*<div className={"titlePage"}>{t("request.titlePage")}</div>*/}
                            <AddRequestModal countRequestNotPaid={countRequestNotPaid} apiParams={apiParams} handleChangeApiParams={this.props.handleChangeApiParams} getNewItem={this.props.getNewItem}/>
                           {/*<NavLink to={links.INFORMATION_PAGE} className={"addRequest"}><CustomButton*/}
                           {/*     className={classes.btnAdd}*/}
                           {/*     title={t("request.btnAddRequest")}*/}
                           {/*     // onClick={()=> props.handleOpenModal(OPEN_MODAL_ADD_PRODUCT)}*/}
                           {/*     buttonProps={{*/}
                           {/*         startIcon: <React.Fragment><img*/}
                           {/*             src={addIcon} className={"iconAdd"} alt=""/></React.Fragment>*/}
                           {/*     }}*/}
                           {/*/></NavLink>*/}
                        </div>
                        :
                        // [links.INFORMATION_PAGE, links.REGISTRATION_INFORMATION ].includes(pathPage)?
                        pathPage !== links.ADMIN_REQUEST?
                        <div className={"headerInformation"}>
                            {pathPage.replace(/[0-9]/g, '') !== links.INFORMATION_PAGE.replace(":id","") && (!match || match.path !== links.EDIT_REQUEST_NOT_LOGIN) &&
                            <div className={"returnRequest"}>
                                {!noReturn ? <NavLink to={pathPage === links.CREATE_STAFF||  pathPage.replace(/[0-9]/g, '') === links.EDIT_STAFF.replace(":id", '') ?
                                    links.MANAGEMENT_STAFF: returnInfo} className={"returnHistory"}>
                                    <img alt={t("information.header.returnHistory")} src={returnIcon}/>
                                    <span>{t("information.header.returnHistory")}</span>
                                </NavLink> : ""}
                            </div>}
                                <div className={"titleInformation"}>
                                    {pathPage === links.PROFILE ? t("profile.titleHeader") :
                                        pathPage === links.CREATE_STAFF ? t("profile.titleAddStaff") :
                                            pathPage.replace(/[0-9]/g, '') === links.EDIT_STAFF.replace(":id", '') ? t("profile.titleEditStaff") :
                                                ""}
                                    {/*t("information.header.appelVisio")}*/}
                                </div>
                            </div>
                            : ""
                    }
                </div>
        )
    }
}

NavBar2.defaultProps = {

}

NavBar2.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(
    withStyles(styles),
    withTranslation(),
    withRouter
)(NavBar2);
