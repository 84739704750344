import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import {withTranslation} from "react-i18next";
import {compose} from "redux";

class Dashboard extends React.Component{
    render() {
        return (
            <Card>
                <CardHeader title={this.props.t('admin.welcome')} />
                <CardContent>{this.props.t('admin.title')}</CardContent>
            </Card>
        );
    }
}
export default compose(
    withTranslation()
) (Dashboard);
