import {NUNITO} from "../../../../constants/fontCss";
import {SM, XS} from "../../../../constants/constants";

export const stylesManagerCalenderPageLeft = theme => ({
    managerCalendarPage: {
        width: '100%',
        padding: '1rem',
        [theme.breakpoints.between(XS, SM)]: {
            padding: 0,
        },
    },
    calendarSmallWrapper: {
        width: '100%',
        // padding: ' 0 30px'
        '& tr': {
            height: '30px',
            '& td': {
                padding: 0,
                border: 'none'
            }
        },
        '& .dx-calendar-other-view': {
            color: '#BDBDBD!important',
            visibility: 'hidden'
        },
        '& .dx-calendar': {
            minWidth: 'unset',
            width: '100%',
            boxShadow: '0px 4px 20px rgba(0, 0, 254, 0.05)',
            borderRadius: 15,
            border: '1px solid #E0E0E0',
            padding: '1rem'
        },
        '& .dx-calendar-caption-button': {
            left: 0,
            right: 'unset',
            // background: 'none!important',
            '& .dx-button-content':{
                padding: '0 !important'
            },
        },
        '& .dx-button': {
            border: 'none!important',
            background: 'transparent'
        },
        '& .dx-calendar-cell': {
            fontFamily: NUNITO,
            fontSize: '0.875rem',
            fontWeight: 700,
            color: '#1D243E'
        },
        '& .dx-calendar-empty-cell': {
            background: 'none!important',
            color: '#BDBDBD!important'
        },
        '& .dx-button-text': {
            color: '#1D243E',
            fontWeight: "700",
            fontSize: '0.875rem',
            fontFamily: NUNITO,
            textTransform: 'capitalize'
        },
        '& .dx-calendar-navigator-previous-view': {
            left: 'unset',
            right: '25px',
            width: '25px',
            height: '25px',
            borderRadius: '50%'
        },
        '& .dx-calendar-navigator-next-view': {
            width: '25px',
            height: '25px',
            borderRadius: '50%'
        },
        '& .dx-icon::before': {
            color: '#1D243E'
        },
        '& .dx-calendar-selected-date': {
            boxShadow: 'none!important',
            background: 'none!important',
            color: '#fff',
            '& span': {
                background: '#0000FF',
                borderRadius: '50%',
                // padding: 4,
                display: 'inline-block',
                width: 30,
                height: 30,
                lineHeight: '30px'
            }
        },
        [theme.breakpoints.down(XS)]: {
            borderRadius: '0 0 15px 15px',
            border: '1px solid #E0E0E0',
            borderTop: 'none',
        }
    },
    detailByCalendarSmallWrapper:{
        marginTop: '1rem',
        background: '#5A8EA4',
        boxShadow: '0px 4px 20px rgba(0, 0, 254, 0.05)',
        borderRadius: 15,
        padding: '0 1rem',
        display: 'flex',
        justifyContent: 'space-between',
        '& .titleBlock':{
            color:' #FFFFFF',
            fontWeight: 800,
            fontSize: '1rem'
        },
        '& .contentBlockLeft':{
            padding: '1.5rem 0',
            '& .contentBlock': {
                '& .content': {
                    padding: '0.5rem 0',
                    display: 'flex',
                    color: ' #FFFFFF',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-start',
                    fontSize: '1rem',
                    '& .number': {
                        fontWeight: 800,
                        padding: '0 0.3rem'
                    },
                    '& .nameStatus': {},
                    '& .imgStatus': {
                        height: 20, width: 20
                    }
                }
            }
        },
        '& .contentBlockRight':{
            display: 'flex',
            flexDirection: 'column',
            '& .pointIcon':{
                padding: '0.5rem 0'
            }
        }
    }
});
