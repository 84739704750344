import React from "react";
import { Admin, AppBar, Resource, Layout} from "react-admin";
import {connect, Provider} from "react-redux";
import { createBrowserHistory } from 'history';
import createAdminStore from './createAdminStore';
import dataProvider from "./dataProvider";
import Dashboard from "./Dashboard";
import {withTranslation} from "react-i18next";
import authProvider from "./authProvider";
import * as links from "../../constants/links";
import {withRouter} from 'react-router-dom';
import {compose} from "redux";
import MenuLayout from  "./MenuLayout";
import LogoutButton from "./LogoutButton";
import "./reactAdminStyle.css"
import {withStyles} from "@material-ui/core";
import {arrResources} from "./resourceReactAdmin";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import frenchMessages from 'ra-language-french';
import englishMessages from 'ra-language-english';
import localEnglishMessages from '../../translations/en/translates.json';
import localFranceMessages from '../../translations/fr/translates.json';
import CustomNotification from "./CustomNotification";
export const SET_TIME_OUT_ALERT_SUCCESS = 1000;
const messages = {
    fr: {
        ...localFranceMessages,
        ...frenchMessages
    },
    en: {
        ...localEnglishMessages,
        ...englishMessages
    },
};
const i18nProvider = polyglotI18nProvider(
    locale => messages[locale] ? messages[locale] : messages.fr,
    localStorage.getItem('i18nextLng')
);
const MyLayout = props => {
   return <Layout {...props} menu={MenuLayout} appBar={MyAppBar} notification={CustomNotification}
            className={"layoutReactAdmin"}/>
};
const history = createBrowserHistory({basename: links.BACKOFFICE_PAGE});

const MyAppBar =({ classes, ...props }) => (
    <AppBar {...props} className={"barReactAdmin"}>
        <div></div>
    </AppBar>
);

const styles = {
    appBar:{
        display: 'none'
    }
}

class ReactAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payrollSyntaxExist: true,
            source: props.location.pathname
        }
    }

    componentDidMount() {
        if (this.props.history.location.pathname === links.BACKOFFICE_PAGE  && history.location.pathname !== "/"){
            history.replace("/");
        }
    }

    render () {
        return (
            <Provider  store={createAdminStore({
                // applicationReducer:{
                //     corporation: this.props.corporation,
                //     corporationCurrency: this.props.corporationCurrency,
                //     setParameterCurrency: this.props.setParameterCurrency,
                //     setCorporationCurrency: this.props.setCorporationCurrency,
                //     parameterCurrency: this.props.parameterCurrency
                // },
                authProvider,
                dataProvider,
                history,
            })}
            >
                <Admin
                    locale={localStorage.getItem('i18nextLng')}
                    dashboard={Dashboard}
                    authProvider={authProvider}
                    dataProvider={dataProvider}
                    i18nProvider={i18nProvider}
                    loginPage={false}
                    history={history}
                    layout={MyLayout}
                    logoutButton={LogoutButton}
                >
                    {arrResources.map((item, index)=>{
                       return <Resource key={`resource-${index}`} name={item.name} list={item.list?? null} create={item.create ?? null} edit={item.edit ??null}/>;
                    })}
                    {/*<Resource name="mail_types" list={MailTypeList} create={MailTypeCreate} edit={MailTypeEdit} />*/}
                    {/*<Resource name="request_mail_types" list={RequestMailTypeList} create={RequestMailTypeCreate} edit={RequestMailTypeEdit} />*/}
                    {/*<Resource name="users" list={UserList} create={UserNew} edit={UserEdit} />*/}
                </Admin>
            </Provider>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = (dispatch)=> {
    return {
    }
}
export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withTranslation(),
    withStyles(styles),
    withRouter
) (ReactAdmin);
