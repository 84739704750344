import React, {Component} from 'react';
import goIcon from "../../../../images/request/status/go.svg";
import notgoIcon from "../../../../images/request/requestAdmin/noGoActive.svg";
import unassignedActive from "../../../../images/request/requestAdmin/unassignedActive.svg"
import assignedActive from "../../../../images/request/requestAdmin/assignedActive.svg"
import maybeIcon from "../../../../images/request/status/maybe.svg";
import waitingIcon from "../../../../images/request/status/waiting.svg";
import {NOT_BE_REPAIRED, REPAIRED, WAITING_FOR_REPLY, MAYBE, STOP} from "../../../../constants/constants";
import {withStyles} from "@material-ui/core";
import {compose} from "redux";
import {API_GET_CATEGORY} from "../../../../constants/apiSuffix";
import qs from "qs";
import axios from "axios";
import stopIcon from "../../../../images/request/requestAdmin/stopActive.svg";
import {convertToDateH_m_D_M_Y} from "../../../../functions/datetime";
import {withTranslation} from "react-i18next";
import {returnStatusInColumnToggle} from "../RequestPage";
import {listStatusClient} from "../constantRequestPage";

const styles = theme => ({
    nogoIcon:{
        width: 30,
        height: 30
    },
    wrapper:{
        display: 'flex',
        alignItems: 'center',
        margin: '5px 0',
        justifyContent: 'space-between',
        height: 48,
        '&:hover':{
            '& $editBtn':{
                visibility: 'visible',
                opacity: 1
            }
        }
    },
    infoWrapper:{
        display: 'flex',
        alignItems: 'center',
        width: '85%',
        '& .selectCategories':{
            width: '225px',
            marginRight: 10,
            '& .selectPaginate__single-value':{
                color:'#1D243E',
                fontWeight: 700
            },
            '& .selectPaginate__indicator-separator':{
                visibility: 'hidden'
            }
        },
        '& .staff':{
            width: 30,
            height: 30,
            marginLeft: 5
        }
    },
    title:{
        color: '#001529',
        fontSize: '1rem',
        fontWeight: 700,
        marginRight: 5
    },
    titleGrid:{
        maxWidth: '225px',
        whiteSpace: 'noWrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
        // WebkitLineClamp: 2,
        // WebkitBoxOrient: 'vertical',
        // display: '-webkit-box',
    },
    notPaid:{
        background: '#e33838',
        padding: '0 0.5rem',
        borderRadius: 15,
        marginLeft: 5,
        height: 30,
        lineHeight: '30px',
        fontWeight: 700,
        color: "#fff"

    },
    paid:{
        background: '#13be2a'
    },
    gridList:{
        '& img':{
            width: 20,
            height: 20
        },
        '& .staff':{
            width: 20,
            height: 20
        },
        '& $notPaid':{
            height: 20,
            lineHeight: '20px'
        }
    },
    groupIconEdit:{
        display: 'flex',
        '& .saveBtn':{
            '& svg>path':{
                fill: '#0000FF'
            },
            '&:hover':{
                '& svg>path':{
                    fill: 'black'
                }
            }
        },
        '& button':{
            width: 20,
            height: 20,
            marginLeft: 5,
            '&:hover':{
                background: 'none'
            }
        },
        '& svg:hover':{
            '& path':{
                fill: 'black'
            }
        }
    },
    editBtn:{
        visibility: 'hidden',
        opacity: 0,
        '& svg>path':{
            fill: '#0000ff'
        }
    },
    notAdmin:{
        width: '65%!important'
    },
    registrationStart:{
        marginRight: 10,
        '& span':{
            fontWeight: 700,
            marginRight: 5
        }
    }
})

class ProblemTitleModal extends Component {
    constructor(props) {
        super(props);
        this.state={
            categories: this.props.categories ?? null
        }
    }
    async loadCategoryOption(search, loadedOptions, { page }) {
        let params = {search: search, page: page, group: 'showSelect', employee: 1};
        const urlCategory = API_GET_CATEGORY + "?" + qs.stringify(params)
        const response = await axios.get(urlCategory)
        let data = response.data
        let dataItems = [];
        if(data.hasOwnProperty('items') && data.items){
            dataItems = data.items;
        }
        const options = dataItems.map((item) => {
            return {label: item.name, value: item.id, id: item.id};
        });

        return {
            options: options,
            hasMore: data.currentPage < data.nbPages,
            additional: {
                page: page + 1,
            },
        };
    }
    changeCategory = (e)=>{
        this.setState({
            categories: e
        })
        let categoryModal = {...e, rowId: this.props.id}
        this.props.handleChangeField( categoryModal, "categoryModal")
    }
    cancelChange = () => {
        this.setState({
            categories: this.props.categories ?? null
        })
        this.props.editRow(null)
        this.props.handleChangeField( null, "categoryModal")
    }
    showIcon = (status) => {
        const {classes} = this.props
        switch (status){
            case WAITING_FOR_REPLY:
                return <img alt={'waiting'} src={waitingIcon}/>
            case REPAIRED:
                return <img alt={'repaired'} src={goIcon}/>
            case NOT_BE_REPAIRED:
                return <img className={classes.nogoIcon} alt={'not_be_repaired'} src={notgoIcon}/>
            case MAYBE:
                return <img alt={'not_be_repaired'} src={maybeIcon}/>
            case STOP:
                return  <img alt={'stop'} src={stopIcon}/>
            default:
                return <div></div>
        }
    }
    render() {
        const { t, status, category, title,requestItem, payment, classes, assignStaff, gridList, hasChangeCategory, saveButton,registrationStart } = this.props
        const { categories } = this.state
        const statusKeyInStatusList = returnStatusInColumnToggle(requestItem);
        return (
            <div className={classes.wrapper}>
                <div  className={`${classes.infoWrapper} ${gridList ? classes.gridList : ""} ${!category ? classes.notAdmin : ""}`}>
                    {/*{category ?*/}
                    {/*    !gridList || (gridList && hasChangeCategory) ?*/}
                    {/*        <AppAsyncPaginate*/}
                    {/*            classNamePrefix={"selectPaginate"}*/}
                    {/*            className={"selectCategories"}*/}
                    {/*            value={categories}*/}
                    {/*            loadOptions={(search, loadedOptions, {page}) => this.loadCategoryOption(search, loadedOptions, {page})}*/}
                    {/*            onChange={(e)=>this.changeCategory(e)}*/}
                    {/*            additional={{*/}
                    {/*                page: 1,*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*        :*/}
                    {/*        categories && categories.label ? <Tooltip arrow title={categories.label}>*/}
                    {/*            <span className={`${classes.title} ${classes.titleGrid}`}>{categories.label}</span>*/}
                    {/*        </Tooltip> : null*/}
                    {/*    :*/}
                    {/*    <span className={classes.title}>{title}</span>*/}
                    {/*}*/}
                    {listStatusClient.hasOwnProperty(statusKeyInStatusList)?<div className={classes.statusWrapperGrid} ><img src={listStatusClient[statusKeyInStatusList].icon} className="" alt={t(listStatusClient[statusKeyInStatusList].label)}/></div>:""}
                    {/*{status && status.hasOwnProperty('statusType') && status.statusType.code &&*/}
                    {/*this.showIcon(status.statusType.code)*/}
                    {/*}*/}
                    {payment && <React.Fragment>
                        {payment.paid && category ? assignStaff ? <img className={'staff'} src={assignedActive}/> : <img className={'staff'} src={unassignedActive}/> : null}
                        <span className={`${classes.notPaid} ${payment.paid ? classes.paid : ""}`}>{new Intl.NumberFormat(payment.currency, {
                            style: 'currency',
                            currency: payment.currency
                        }).format(payment.price)}
                </span>
                    </React.Fragment>
                    }
                </div>
                {registrationStart && !category &&
                    <span className={classes.registrationStart}>
                        <span>{t("request.list.startTime")}:</span>
                        {convertToDateH_m_D_M_Y(registrationStart)}
                    </span>
                }
                {/*{gridList &&*/}
                {/*    (!hasChangeCategory ?*/}
                {/*            <IconButton className={classes.editBtn} onClick={()=>this.props.editRow(this.props.id)}>*/}
                {/*                <EditIcon/>*/}
                {/*             </IconButton> :*/}
                {/*            <div className={classes.groupIconEdit}>*/}
                {/*                {saveButton && <IconButton onClick={()=>this.props.handleSave()} className={"saveBtn"}>*/}
                {/*                    <SaveIcon/>*/}
                {/*                </IconButton>}*/}
                {/*                <IconButton onClick={()=>this.cancelChange()}>*/}
                {/*                    <ClearIcon/>*/}
                {/*                </IconButton>*/}
                {/*            </div>*/}
                {/*    )*/}
                {/*}*/}
            </div>
        );
    }
}

export default compose(withTranslation(), withStyles(styles)) (ProblemTitleModal);
