import React from "react";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import i18n from "../../../i18n";
import moment from "moment";
import MomentUtils from "@date-io/moment";

class AppMuiPickersUtilsProvider extends React.Component{
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return <MuiPickersUtilsProvider {...this.props} libInstance={moment} utils={MomentUtils} locale={i18n.language}>
            {this.props.children}
        </MuiPickersUtilsProvider>;
    }
}

export default AppMuiPickersUtilsProvider;