import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withStyles} from "@material-ui/core";
import {NavLink, withRouter} from "react-router-dom";
import Button from "@material-ui/core/Button";
// import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import {
    Grid,
    Table,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import Paper from '@material-ui/core/Paper';

const styles = {
    listDocumentWrapper: {
        paddingTop:16,
        '& :first-of-type':{
            boxShadow:'none'
        },
        '& .tableHeader': {
        },
        '& .NotificationListAdmin': {
            padding:'0px 30px 20px 35px',
            boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
            '& tr':{
                '& th':{
                    fontWeight:400,
                    color:'#0000ff',
                    fontSize:'0.875rem',
                    lineHeight:'1.5rem',
                    border:'none',
                    padding:0
                },
            },
            '& tbody':{
                '& tr:hover':{
                    backgroundColor:'#ccd0d8'
                },
                '& tr>td':{
                    color: '#0000ff',
                    borderTop: '4px solid #fff',
                    borderBottom: '4px solid #fff',
                    backgroundColor: 'rgba(204, 208, 216, 0.27)',
                    fontWeight:500
                }
            },
            '& td':{
                padding:0
            },
        },
    },

    iconRow: {
        color:'#0000ff',
        width:20,
        height:20,
    },
    notificationCreateButton: {
        padding: '0px 10px',
        borderRadius: 5,
        backgroundColor:'#0000ff',
        // fontSize: '10px',
        fontWeight:500,
        '&:hover':{
            backgroundColor: '#0404d0'
        }
    },
    addButton:{
        width: 20,
        height:20,
    },
    text:{
        // paddingLeft:'0.8em',
        lineHeight: '30px',
        letterSpacing:0.5,
        color: '#fff',
        fontSize: 10,
        fontWeight: 700
    },
    rightHeader:{
        display:'flex',
        '& a':{
            textDecoration: 'none'
        }
    },
    saveButton:{
        paddingLeft:5
    },
    buttonEdit:{
        margin:5,
        width:65,
        // height:28,
        borderRadius: 3
    },
    headerLeft: {
        // position: "absolute",
        '&.titleList': {
            color: '#54516a',
            fontFamily: "'Open Sans', sans-serif",
            fontSize: '1rem',
            // opacity:0.8,
            // fontWeight: 100,
            fontWeight: 700,
            textTransform: 'uppercase',
            marginLeft:7
        },
    },
    tableHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 5px 20px 5px',
        backgroundColor:'#fff',
        justifyContent: 'space-between',
        '& h6': {
            color: '#0000ff',
            textTransform: 'uppercase',
            fontSize: 23,
            fontWeight: 'bold',
            marginLeft: 15,
            lineHeight: "25px",
            paddingRight: "26px",
            flexGrow: 1,
        },
        '&.customHeaderColumn': {
            justifyContent: 'space-between'
        }
    },
}

const ListHeader = (props) => {
    const classes = props.classes;
    return <div className= {`${classes.tableHeader} tableHeader `}>
        {typeof props.leftHeaderComponent !== "undefined" && props.leftHeaderComponent!==null && props.leftHeaderComponent}
        {typeof props.rightHeaderComponent !== "undefined" && props.rightHeaderComponent!==null && props.rightHeaderComponent}
    </div>
};



const RightHeaderListContent = (props) => {
    const {t, classes} = props;
    return (
        <div className={classes.rightHeader}>
            {/*{props.location.pathname === links.TRANSLATION_MANAGER &&*/}
            {/*<NavLink to={links.TRANSLATION_MANAGER_IMPORT_JSON}>*/}
            {/*    <Button className={`${classes.notificationCreateButton}`}>*/}
            {/*        <AddOutlinedIcon className={classes.addButton}/>*/}
            {/*        <span className={classes.text}>{t("admin.translationManager.importJsonFile")}</span>*/}
            {/*    </Button>*/}
            {/*</NavLink>*/}
            {/*}*/}
            <NavLink to={props.hasOwnProperty("pathCreate") && props.pathCreate ?props.pathCreate: props.location.pathname+"/create"}>
                {!(props.hiddenCreatedButton) && <Button  className={`${classes.notificationCreateButton}`}>
                    {/*<AddOutlinedIcon className={classes.addButton}/>*/}
                    <div className={classes.text}>{t("reactAdmin.label.create")}</div>
                </Button>}
            </NavLink>
        </div>
    )
};


const LeftHeaderListContent = (props) => {
    const {classes} = props;
    return (
        <div className={`${classes.headerLeft} titleList`} >
            {props.titleList?props.titleList:""}
        </div>
    )
}

class ListEmpty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            forceUpdate: false,
            isLoading: false,
            tableMessages: {
                noData: "text.noResult"
            },
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const newState = {};
        if (this.state.forceUpdate){
            newState.forceUpdate = false;
        }
        if (Object.keys(newState).length){
            this.setState(newState);
        }
    }

    render() {
        const {classes, arrayColumn, t, hiddenCreatedButton} = this.props;
        const LeftHeaderList = <LeftHeaderListContent
            {...this.props}
        />;
        const RightHeaderList = <RightHeaderListContent
            {...this.props}
        />;

        let columns = [];
        let tableMessages = {};
        Object.keys(this.state.tableMessages).map((item)=>{
            tableMessages[item] = this.props.t(this.state.tableMessages[item]);
            return item
        });
        arrayColumn.forEach(item => {
            columns.push(
                {
                    name: item.source,
                    title: t(item.label),
                    getCellValue: rowData => rowData.hasOwnProperty(item.source) ? rowData[item.source] : ""
                });

        });

        return (
            <Paper>
                <ListHeader
                    titleList={""}
                    t={this.props.t}
                    leftHeaderComponent={LeftHeaderList}
                    rightHeaderComponent={RightHeaderList}
                    classes={classes}
                />
                <div className={"NotificationListAdmin"}>
                    <Grid
                        rows={[]}
                        columns={columns}
                    >
                        <Table messages={tableMessages}/>
                        <TableHeaderRow/>
                    </Grid>
                </div>

            </Paper>
        );
    }
}
ListEmpty.defaultProps = {
    hiddenCreatedButton: false
}

ListEmpty.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    hiddenCreatedButton: PropTypes.bool
};

export default compose(
    // connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withRouter,
    withTranslation()
)(ListEmpty);
