import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import backgroundLogin from "../../images/login/background.png"
import traceIcon from "../../images/login/Trace.svg"
import backgroundNew from "../../images/login/backgroundNew.png"
import logo from "../../images/login/logo.svg"
import AuthForm from "./AuthForm";
import corner from "../../images/login/corner.svg"
import {
    BASE_URL,
    CHANGE_PASSWORD,
    DATA_USER, FACEBOOK, FACEBOOK_ACCOUNT_NOT_EMAIL, GOOGLE,
    LOGIN_PAGE,
    MD,
    PASSWORD_COMPONENT, REDIRECT,
    RESET_PASSWORD,
    SIGN_UP, SM, TWITTER, XS
} from "../../constants/constants";
import {Button, Typography} from "@material-ui/core";
import facebook from "../../images/login/facebook.svg";
import google from "../../images/login/google.svg"
import * as auth from "../../_actions/auth";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import AlertCustom from "../../theme/form/Alert/AlertCustom";
import * as application from "../../_actions/application";
import axios from "axios";
import {API_CHECK_TOKEN, API_LOGIN_SOCIAL} from "../../constants/apiSuffix";
import * as links from "../../constants/links";
import {EMAIL_OR_USER_ID_NOT_EXIST} from "../../constants/responseCode";
import LoadingAction from "../../theme/LoadingAction";
import stopBigIcon from "../../images/request/requestAdmin/stopActive.svg";
import CustomInlineSvg from "../../theme/CustomElements/CustomInlineSvg";
import CloseIcon from '@material-ui/icons/Close';
import {SEGOEUI} from "../../constants/fontCss";
import {paramsToObject} from "../../functions/functions";
import notify from "devextreme/ui/notify";
import SuccessAlert from "../../theme/form/Alert/SuccessAlert";
import AlertRegistrationSuccess from "./AlertRegistrationSuccess";
const styles = theme => ({
    login: {
        background: `url(${backgroundNew}) no-repeat`,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        backgroundSize: 'cover',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        // height: 'auto',
        overflowY: 'auto',
        [theme.breakpoints.down(SM)]:{
            height: 'auto',
            // overflowY: 'auto'
        },

    },
    logo: {
        '& img': {
            maxWidth: '100%',
            // maxHeight: 88
            marginTop: 20
        }
    },
    info: {
        color: '#a2b1d7',
        fontSize: '1rem',
        maxWidth: '95%',
        marginLeft: '50px',
        marginRight: '70px',
        marginBottom: '30px',
        marginTop: '7px',
        textAlign: 'center',
    },
    form: {
        width: 990,
        borderRadius: 21,
        margin: '20px 0',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        height: 740,
        maxHeight: '90vh',
        // background: '#072248',
        zIndex: 9,
        boxShadow: ' 0 8px 6px 0 rgba(0, 0, 0, 0.37)',
        animation: '$fadeIn 700ms ease-in-out',
        overflow: 'hidden',
        '&::before': {
            content: `''`,
            background: `url(${corner}) no-repeat right bottom`,
            position: 'absolute',
            width: '100%',
            height: '100%',
            opacity: 0.1,
            zIndex: -1
        },
        [theme.breakpoints.down(MD)]: {
            width: '70%',

        },
        [theme.breakpoints.down(SM)]: {
            // height: '100%',
            // margin: 'auto',
            display: 'flex',
            flexDirection: 'column-reverse',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'auto',
            borderRadius: 0,
            boxShadow: 'none',
            width: '100%',
            height: 'auto',
            maxHeight: 'none'
        },
        // [theme.breakpoints.down(XS)]:{
        //     width: '100%',
        //     height: 'auto',
        //     maxHeight: 'none'
        // }
    },
    bonusInfo: {
        '& h3': {
            fontSize: '1.5rem',
            color: '#fff'
        },
        [theme.breakpoints.down(MD)]: {
            minWidth: 'unset!important',
            margin: '0!important',
            textAlign: 'center!important'
        }
    },
    authForm: {
        width: '55%',
        // position: 'absolute',
        // left: 50,
        // borderRadius: '0 21px 21px 0',
        backgroundColor: '#fff',
        // animation: '$fadeIn 700ms ease-in-out',
        '&$loginChoice': {
            // left: 'auto',
            // right: 50,
            height: '100%'
        },
        [theme.breakpoints.down(MD)]: {
            width: '70%'
            // position: 'relative',
            // marginBottom: 20,
            // '&$loginChoice': {
            //     left: 'unset',
            //     right: 'unset'
            // }
        },
        [theme.breakpoints.down(SM)]:{
            width: '95%',
            borderRadius: 21,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            marginBottom: 0,
        },
        // [theme.breakpoints.down(XS)]:{
        //     marginBottom: 0,
        // }
    },
    registrationBlock:{
        width: '45%',
        height: '100%',
        backgroundColor: '#2d2dd2',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        transitionDelay: '0.2s',
        paddingLeft: '25px',
        paddingRight: '25px',
        '& svg':{
            marginBottom: 40
        },
        [theme.breakpoints.down(MD)]: {

        },
        [theme.breakpoints.down(SM)]:{
            width: '95%',
            marginTop: -23,
            borderRadius: '0 0 21px 21px',
            paddingTop: '110px',
            paddingBottom: '120px',
        },
        // [theme.breakpoints.down(XS)]:{
        //     marginTop: -23,
        //     paddingTop: 30,
        //     paddingBottom: '0.5rem',
        //     borderRadius: '0 0 21px 21px'
        // }
    },
    registrationBlockSU:{
        opacity: 0,
        visibility: 'hidden',
        transitionDelay: '1s',
        [theme.breakpoints.down(XS)]:{
            height: 0
        }
    },
    registrationBlockSULogin:{
        [theme.breakpoints.down(XS)]:{
            opacity: 0,
            visibility: 'hidden',
            transitionDelay: '0.4s',
            position: 'absolute',
            height: 0
        }
    },
    loginChoice: {

    },

    signUpPage: {
        justifyContent: 'flex-end',
        '& $bonusInfo': {
            marginRight: '12rem'
        }
    },
    loginPage: {
        '& $bonusInfo': {
            textAlign: 'right',
            marginLeft: 50,
            minWidth: 330,
        },
        [theme.breakpoints.down(MD)]: {
            width: '85%',
        },

        [theme.breakpoints.down(SM)]:{
            padding: '20px 30px 20px 15px',
            width: '95%'
        },
    },
    switchButton: {
        margin: '15px 0',
        border: '1px solid #FFFFFF',
        borderRadius: 6,
        color: '#fff',
        fontSize: '1rem',
        minWidth: '10rem',
        '&:hover': {
            background: '#fff',
            color: '#072248'
        }
    },
    smallText: {
        fontSize: '1.25rem',
        color: '#fff',
        marginBottom: 15
    },

    creatAccount:{
        color: '#E8E8F8',
        fontSize: '1.5625rem',
        fontWeight: 800,
        letterSpacing: -0.25,
        marginBottom: 40,
        textTransform: 'uppercase',
        textAlign: 'center'
    },
    registrationButton:{
        borderRadius: 33,
        display: 'flex',
        height: 60,
        width: 289,
        justifyContent: 'center',
        alignItems: 'center',
        border: 'solid 1px #fff',
        textTransform: 'uppercase',
        fontSize: '1.125rem',
        letterSpacing: 3.65,
        fontFamily: SEGOEUI,
        fontWeight: 600,
        color: '#fff',
        cursor: 'pointer',
        maxWidth: '90%',
        transition: '0.2s ease',

        '&:hover':{
            background: '#4040f2',
            border:0,
            letterSpacing: '4.65px',
            transition: '0.2s ease',
        }
    },
    signUpComponent: {
        opacity: 0,
        visibility: 'hidden',
        position: 'absolute',
        background: 'rgba(45, 45, 210, 0.98)',
        boxShadow: '13px 10px 11px 0 rgba(7, 7, 7, 0.31)',
        borderRadius: 21,
        width: '94%',
        height: '100%',
        // transition: '1s linear',
        animation: '$rightToLeft 0.8s ease-in-out',
        '& .formWrapperLogin':{
            overflow: 'hidden'
        },
        '& .closeIcon':{
            textAlign: 'end',
            marginRight: 20,
            marginTop: 20,
            cursor: 'pointer',
            '& svg':{
                color: '#fff',
                fontSize: 30
            }
        },
        '& .backBtm':{
            color: '#fff'
        }
    },
    signUpComponentActive:{
        zIndex: 1000,
        opacity: 1,
        visibility: 'visible',
        animation: '$leftToRight 0.8s ease-out',
        '& .formWrapperLogin':{
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: '5px'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor:'#C4C4C4',
                borderRadius: 3
            },
        },
        [theme.breakpoints.down(MD)]:{
            '& .formWrapperLogin':{
                padding: '3rem',
                paddingBottom: '0',
                paddingTop: '0.2rem',
            }
        },
        [theme.breakpoints.down(SM)]:{
            width: '95%',
            '& .formWrapperLogin':{
                padding: '1rem',

                '& h2':{
                    marginBottom: '60px',
                    marginTop: '20px',

                }

            }
        },
        [theme.breakpoints.down(XS)]:{
            height: 'auto',
            maxHeight: 'unset',
            position: 'initial',
            '& .formWrapperLogin':{
                maxHeight: 'unset'
            },

        }
    },
    signUpWrapper:{
        height: '100%'
    },
    '@keyframes fadeIn': {
        '0%': {
            opacity: 0,
            visibility: 'hidden',
            transform: 'scale(0)'
        },
        '100%': {
            opacity: 1,
            visibility: 'visible',
            transform: 'scale(1)'
        }
    },
    '@keyframes leftToRight': {
        '0%': {
            opacity: 0,
            visibility: 'hidden',
            // left: -1000
            width: '0',
        },
        '50%': {
            opacity: 0,
            visibility: 'hidden',
        },
        '75%': {
            opacity: 1,
            visibility: 'visible',
        },
        '100%': {
            opacity: 1,
            visibility: 'visible',
            // left: 0,
            width: '94%'
        }
    },
    '@keyframes rightToLeft': {
        '0%': {
            opacity: 1,
            visibility: 'visible',
            // left: 0
            width: '94%'
        },
        '80%': {
            opacity: 0,
            visibility: 'hidden',
            // left: 0
            width: '20%'
        },
        '100%': {
            opacity: 0,
            visibility: 'hidden',
            // left: -1000
            width: 0
        }
    },
});

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            typePage: this.props.match.path === CHANGE_PASSWORD ? PASSWORD_COMPONENT : LOGIN_PAGE,
            loadingPage: this.props.match.path === CHANGE_PASSWORD ? true : false,
            preType: null,
            showMustLogin: false,
            registrationSuccessPage: false,
            dataInitial: null,
            responseMessage: null,
        }
    }


    switchForm = (event, value) => {
        const self = this;
        let {typePage} = this.state

        this.setState({
            preType: typePage,
            typePage: value,
            registrationSuccessPage: ""
        }, () => {
            self.props.setRequestResetPassword(false);
            self.props.checkCreateNewClientSuccess(false);
            self.props.checkSwitchAuthForm(value);
            self.props.changePassword(false)
        })
        if (value === RESET_PASSWORD) {
            this.props.saveTypeFormAuth(LOGIN_PAGE)
        }
    }

    signup(res, type) {
        let postData;
        if (type === FACEBOOK && res.email) {
            postData = {
                name: res.name,
                socialType: type,
                email: res.email,
                userId: res.id,
                accessToken: res.accessToken,
            };
        }
        if (type === GOOGLE && res && res.profileObj && res.profileObj.email && res.googleId) {
            postData = {
                socialType: type,
                email: res.profileObj.email,
                lastName: res.profileObj.familyName??"",
                firstName: res.profileObj.givenName??"",
                userId: res.googleId,
                accessToken: res.accessToken,
            };
        }

        if(type !== TWITTER) {
            if (postData) {
                this.setState({isLoading: true})
                const formData = new FormData();
                Object.keys(postData).forEach((key, index) => {
                    formData.append(key, postData[key]);
                })

                axios.post(API_LOGIN_SOCIAL, formData)
                    .then(response => {
                        if (response.status === 200) {
                            localStorage.setItem(DATA_USER, JSON.stringify(response.data.data));
                            window.location.href = links.HOME;
                        } else {
                            throw new Error();
                        }
                    })
                    .catch(error => {
                        let errorMess = ""
                        if (error.response && error.response.status === 400 && error.response.data && error.response.data.response_code === EMAIL_OR_USER_ID_NOT_EXIST) {
                            errorMess = this.props.t("login.errors.EMAIL_OR_USER_ID_NOT_EXIST")
                        }
                        this.setState({
                            isLoading: false,
                        }, function () {
                            this.props.setErrorAlert(true, errorMess)
                        })
                    })
            } else {
                this.props.setErrorAlert(true)
            }
        }
    }
    componentDidMount() {
        let {typePage, loadingPage} = this.state
        let token = this.props.match.params && this.props.match.params.token ? this.props.match.params.token : ''
        try {
            let params = (new URL(document.location)).searchParams;
            const typePageParams = params.get('typePage');
            const firstNameParams = params.get('firstName');
            const lastNameParams = params.get('lastName');
            const facebookIdParams = params.get('facebookId');
            if (typePageParams === SIGN_UP) {
                this.setState({
                    dataInitial: {
                        firstName: firstNameParams,
                        lastName: lastNameParams,
                        facebookId: facebookIdParams ?? null
                    },
                    typePage: SIGN_UP
                })
            }
        } catch (e) {

        }



        if(typePage === PASSWORD_COMPONENT && token.length > 0) {
            axios.get(BASE_URL + API_CHECK_TOKEN.replace("{token}",token))
                .then(response=>{
                    // console.log(response)
                })
                .catch(error=>{
                    if (error.response.data && error.response.data.status === 400) {
                        this.setState({
                            typePage: LOGIN_PAGE
                        },()=>{
                            this.props.setErrorAlert(true, this.props.t("login.errors.tokenNotExist"))
                            this.props.history.push(links.LOGIN)
                            }
                        )
                    }
                })
                .finally(()=>{
                    // console.log(11111)
                    loadingPage = false
                    this.setState({
                        loadingPage: loadingPage
                    })
                })
        }
        if(loadingPage && typePage!==PASSWORD_COMPONENT) {
            this.setState({
                loadingPage: false
            })
        }
        const {
            location,
            t
        } = this.props;
        try {
            const urlParams = new URLSearchParams(location.search.substr(1));
            const entries = urlParams.entries();
            const params = paramsToObject(entries);
            if (params?.response === FACEBOOK_ACCOUNT_NOT_EMAIL) {
                this.setState({
                    responseMessage: t('errors.facebook_account_not_email')
                })
                this.props.history.replace({
                    search: "",
                })
            }
            if (params?.redirect) {
                this.setState({
                    showMustLogin: true
                })
                localStorage.setItem(REDIRECT, params.redirect)
            } else {
                localStorage.removeItem(REDIRECT)
            }
        } catch (e) {
            localStorage.removeItem(REDIRECT)
        }

    }

    loginSocial = (typeSocial) =>{
        const {location}  =this.props;
        const urlParams = new URLSearchParams(location.search.substr(1));
        const entries = urlParams.entries();
        const params = paramsToObject(entries);
        window.location.href = BASE_URL+"/connect/"+typeSocial+`?state=${JSON.stringify(params)}`;
    }

    render() {
        const {
            classes,
            typeFormAuth,
            messageErrorAlert,
            t,
            // history,
            // match,

        } = this.props;
        let {
            typePage, isLoading, loadingPage, preType, registrationSuccessPage, dataInitial, responseMessage
        } = this.state;
        // console.log(responseMessage)
        return (
            <div className={classes.login}>
                {(isLoading || loadingPage) && <LoadingAction />}
                <AlertCustom
                    handleCloseNotice={()=>this.props.setErrorAlert(false, "")}
                    errorOpen={this.props.errorOpenAlert}
                    messageError={messageErrorAlert}
                />
                <div className={classes.logo}>
                    <img src={logo} alt=""/>
                </div>
                <div className={`${classes.form} ${classes.loginPage}`}>
                    {/*<div className={classes.bonusInfo}>*/}
                    {/*    <Typography variant={"h3"}>*/}
                    {/*        {(typePage === LOGIN_PAGE || typePage === RESET_PASSWORD || typePage === PASSWORD_COMPONENT) ? this.props.t('login.text.dontHaveAnAccount') : this.props.t('login.text.haveAnAccount')}*/}
                    {/*    </Typography>*/}
                    {/*    <Button*/}
                    {/*        variant={"outlined"}*/}
                    {/*        className={classes.switchButton}*/}
                    {/*        onClick={(event) => this.switchForm(event, typePage === LOGIN_PAGE ? SIGN_UP : LOGIN_PAGE)}*/}
                    {/*    >*/}
                    {/*        {(typePage === LOGIN_PAGE || typePage === RESET_PASSWORD || typePage === PASSWORD_COMPONENT) ? this.props.t('button.signup') : this.props.t('button.login')}*/}
                    {/*    </Button>*/}
                    {/*    <Typography variant={"body1"} className={classes.smallText}>*/}
                    {/*        {this.props.t('login.text.orLogin')}*/}
                    {/*    </Typography>*/}
                    {/*    <div className={classes.socialLogin}>*/}
                    {/*        <div className={"socialLogin facebook"} onClick={()=> this.loginSocial(FACEBOOK)}>*/}
                    {/*           <div className={"iconButton"}> <CustomInlineSvg*/}
                    {/*                svgProps={{src:facebook}}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*            <div>{t("login.text.loginWithFacebook")}</div>*/}
                    {/*        </div>*/}
                    {/*        <div  className={"socialLogin"} onClick={()=> this.loginSocial(GOOGLE)}>*/}
                    {/*            <div className={"iconButton"}>  <CustomInlineSvg*/}
                    {/*                svgProps={{src:google}}*/}
                    {/*            /></div>*/}
                    {/*            <div>{t("login.text.loginWithGoogle")}</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={`${classes.registrationBlock} ${typePage === SIGN_UP ? classes.registrationBlockSU : ""}`}>
                        <span className={classes.creatAccount}>{t('login.text.creatAccount')}</span>
                        <CustomInlineSvg svgProps={{src: traceIcon}}/>
                        <span className={classes.registrationButton}
                              onClick={(event) => this.switchForm(event, typePage === SIGN_UP ? preType : SIGN_UP)}
                        >
                            {t('login.text.registration')}
                        </span>
                    </div>
                    {!(loadingPage) && <div className={`${classes.authForm} ${classes.loginChoice} ${typePage === SIGN_UP ? classes.registrationBlockSULogin : ""}`}>
                        <AuthForm
                            title={typePage === LOGIN_PAGE ? this.props.t('button.login') :  (typePage === PASSWORD_COMPONENT ? this.props.t('login.text.createNewPassword') : this.props.t('login.text.forgot_title'))}
                            typePage={typePage === SIGN_UP ? preType : typePage}
                            typePageNow={typePage}
                            forgotPasswordFunc={(event) => this.switchForm(event, RESET_PASSWORD)}
                            backFunction={(event) => this.switchForm(event, typeFormAuth)}
                            loginSocial={(type)=>this.loginSocial(type)}
                            dataInitial={dataInitial}
                        />
                    </div>}
                    {!(loadingPage)  &&
                    <div className={`${classes.signUpComponent} ${typePage === SIGN_UP ? classes.signUpComponentActive : ""}`}>
                        {typePage === SIGN_UP &&
                            <div className={classes.signUpWrapper}>
                                <div className={'closeIcon'}>
                                    <CloseIcon onClick={(event) => this.switchForm(event, typePage === SIGN_UP ? preType : SIGN_UP)}/>
                                </div>
                                {registrationSuccessPage? <AlertRegistrationSuccess email={registrationSuccessPage}  backLogin={(event, typeFormAuth) => this.switchForm(event, typeFormAuth)}/>:
                                <AuthForm
                                    title={this.props.t('login.text.creatAccount')}
                                    typePage={SIGN_UP}
                                    forgotPasswordFunc={(event) => this.switchForm(event, RESET_PASSWORD)}
                                    backFunction={(event) => this.switchForm(event, typeFormAuth)}
                                    backLogin={this.switchForm}
                                    loginSocial={(type)=>this.loginSocial(type)}
                                    registrationUserSuccessFunc={(email)=>this.setState({registrationSuccessPage: email})}
                                    dataInitial={dataInitial}
                                />}
                        </div>
                        }
                    </div>
                    }
                </div>
                <div className={classes.info}>
                    {t("text.footer.info")}
                </div>
                <SuccessAlert
                    snackbarProps={{
                        open: responseMessage != null || this.state.showMustLogin,
                        onClose: () => {
                            this.setState({
                                showMustLogin: false,
                                responseMessage: null
                            })
                        }
                    }}
                    alertProps={{
                        severity: "info"
                    }}
                    message={responseMessage || t('errors.mustLogin')}
                />
            </div>
        )
    }
}

Login.defaultProps = {

}

Login.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        successRequest: state.authReducer.successRequest,
        typeFormAuth: state.authReducer.typeFormAuth,
        messageErrorAlert: state.applicationReducer.messageErrorAlert,
        errorOpenAlert: state.applicationReducer.errorOpenAlert
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setErrorAlert: (successOpenAlert, message) => dispatch(application.setErrorAlert(successOpenAlert, message)),
        setRequestResetPassword: (successRequest) => dispatch(auth.setRequestResetPassword(successRequest)),
        checkCreateNewClientSuccess: (checkCreateNewClient) => dispatch(auth.checkCreateNewClientSuccess(checkCreateNewClient)),
        checkSwitchAuthForm: (checkSwitch) => dispatch(auth.checkSwitchAuthForm(checkSwitch)),
        saveTypeFormAuth: (typeFormAuth) => dispatch(auth.saveTypeFormAuth(typeFormAuth)),
        changePassword: (changePasswordSuccess) => dispatch(auth.changePassword(changePasswordSuccess))
    }
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withRouter)(withTranslation()(Login));
