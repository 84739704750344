import React, {Component} from 'react';
import {IconButton, withStyles} from "@material-ui/core";
import parse from 'html-react-parser';
import GeneralModal from "../../../../theme/Modal/GeneralModal";
import zoomInIcon from "../../../../images/request/zoomIn.svg";
import playIcon from "../../../../images/theme/playButton.svg";
import CustomInlineSvg from "../../../../theme/CustomElements/CustomInlineSvg";
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import {ShowElementComponent} from "../../../../theme/Modal/ContentPreviewModal";

const styles = theme => ({
    problemFileWrapper:{
        display: 'flex',
        alignItems: 'center',
        marginBottom: 5
    },
    problemFilesZoomIn:{
        flexWrap: 'nowrap!important',
    },
    problemFiles:{
        display: 'flex',
        maxHeight: '120px',
        overflow: 'hidden',
        width: '85%'
    },
    problemContentZoomIn:{
        overflow: 'auto !important',
        height: '180px',
        maxHeight: '180px!important',
    },
    problemContent:{
        overflow: 'hidden',
        marginBottom: '10px',
        maxHeight: '110px',
    },
    videoFile:{
        width: 120,
        height: '100%',
        borderRadius: 6,
        objectFit: 'cover',
    },
    imageFile:{
        height: '100%',
        borderRadius: 6,
        objectFit: 'contain',
        width: 120
    },
    contentWrapper:{
        position: 'relative',
        padding: '0 10px',
        textAlign: 'center',
        height: 120
        // '&:last-child':{
        //     paddingRight: '0px',
        //     '& $zoomIn':{
        //         '& svg':{
        //             right: '5px'
        //         }
        //     }
        // }
    },
    zoomIn:{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg':{
            // position: 'absolute',
            // right: '15px',
            // top: '5px',
            background: '#929AAE',
            borderRadius: '5px',
            cursor: 'pointer',
            '&:hover':{
                background: '#7b8498'
            }
        }
    },
    zoomInModal:{
        marginTop: '-10px',
        width: '100%',
        // height: '265px',
        objectFit: 'contain',
        maxHeight: '60vh',
        '&:focus':{
            outline: 'none'
        }
    },
    buttonShow:{
        height: '50px',
        margin: '0 3px'
    },
    showEndFile:{
        justifyContent: 'flex-end',
    },
    playVideoButton:{
        position: 'absolute',
        width: '20px',
        top: '50%',
        left: '50%',
        transform: "translate(-50%, -50%)",
        '& svg':{
            fill: 'white'
        },
    }
})
const ZoomIn = (props) => {
    let {item, classes} = props
    if(item.hasOwnProperty('mimeType') && item.hasOwnProperty('pathUrl')){
        if(item.mimeType.includes('image')) return <img className={classes.zoomInModal} src={item.pathUrl}/>
        else if(item.mimeType.includes('video')) return <video className={classes.zoomInModal} autoPlay controls><source src={item.pathUrl}/></video>
    }
    else return <div>Hello</div>
}

class ProblemContentModal extends Component {
    constructor(props) {
        super(props);
        this.state={
            open: false,
            itemZoom : {},
            indexShow: 0,
            increment: 0,
            fileNumber: this.props.dataDetailProblem && this.props.dataDetailProblem.documents ? this.props.dataDetailProblem.documents.length : 1 ,
            fileShow: this.props.dataDetailProblem && this.props.dataDetailProblem.documents ? this.props.dataDetailProblem.documents : {},
            arrowHidden: false
        }
        this.fileWrapper = React.createRef()
    }
    handleCloseModalPreview = () => {
        this.setState({
            open : false,
        })
    }
    showFileModal = (item) => {
        this.setState({
            open : true,
            itemZoom: item
        })
    }

    componentDidMount() {
        if(this.fileWrapper){
            let fileWrapper = this.fileWrapper.current && this.fileWrapper.current.offsetWidth
            let widthFiles = 0
            let a = this.fileWrapper.current.children
            for (let i = 0; i < a.length; i++) {
                widthFiles += a[i].offsetWidth
            }
            if(widthFiles < fileWrapper) this.setState({arrowHidden: true})
        }
        if( this.props.dataDetailProblem &&  this.props.dataDetailProblem.documents && this.state.fileNumber !== this.props.dataDetailProblem.documents.length){
            this.setState({
                fileNumber: this.props.dataDetailProblem.documents.length
            })
        }
    }

    buttonAction = (field) => {
        let {indexShow, increment, fileShow, fileNumber} = this.state
        let {dataDetailProblem} = this.props
        let files = dataDetailProblem.documents
        if (field === 'leftButton'){
            increment = 0
            if(indexShow===0) {
                indexShow = 0
            }
            else indexShow -= 1
        }
        if (field === 'rightButton'){
            if(this.fileWrapper && this.fileWrapper.current) {
                let widthFiles = 0
                let a = this.fileWrapper.current.children
                for (let i = 0; i < a.length; i++) {
                    widthFiles += a[i].offsetWidth
                }
                if (widthFiles > this.fileWrapper.current.offsetWidth) indexShow += 1
            }
        }
        fileShow = files.slice(indexShow, indexShow+fileNumber)
        this.setState({
            indexShow: indexShow,
            increment: increment,
            fileShow: fileShow
        })
    }
    showFiles = (files) => {
        let {zoomIn, classes} = this.props
        return files.map((item, index) =>{
            if(item.mimeType && item.mimeType.includes('video') && item.pathUrl){
                return <div className={classes.contentWrapper} key={index}>
                    <video className={classes.videoFile} controls={false} key={item.pathUrl}>
                        <source src={item.pathUrl}/>
                    </video>
                    <div className={classes.playVideoButton}>
                        <CustomInlineSvg
                            svgProps={{
                                src: playIcon
                            }}
                        />
                    </div>
                    {zoomIn && <div className={classes.zoomIn}>
                        <div  onClick={()=>this.showFileModal(item)}>
                            <CustomInlineSvg
                                svgProps={{
                                    src: zoomInIcon
                                }}
                            />
                        </div>
                    </div>}
                </div>
            } else if(item.mimeType && item.mimeType.includes('image') && item.pathUrl){
                return <div className={classes.contentWrapper} key={index}>
                    <img className={classes.imageFile} alt={'file problem'} src={item.pathUrl}/>
                    {zoomIn && <div className={classes.zoomIn}>
                        <div  onClick={()=>this.showFileModal(item)}>
                            <CustomInlineSvg
                                svgProps={{
                                    src: zoomInIcon
                                }}
                            />
                        </div>

                    </div>}
                </div>
            } else return <div key={index} />
        })
    }

    render() {
        const { classes, dataDetailProblem, zoomIn} = this.props
        const { open, itemZoom,fileNumber, fileShow, arrowHidden} = this.state
        return (
            <React.Fragment>
                {zoomIn && JSON.stringify(fileShow)!=="{}" && !arrowHidden ?
                    <div className={classes.problemFileWrapper}>
                        <IconButton onClick={()=>this.buttonAction('leftButton')} className={`${classes.buttonShow}`}>
                            <ChevronLeftOutlinedIcon/>
                        </IconButton>
                        <div ref={this.fileWrapper} className={`${classes.problemFiles} ${zoomIn ? classes.problemFilesZoomIn : ''}  `}>
                            {dataDetailProblem.documents && this.showFiles(fileShow)}
                        </div>
                        <IconButton onClick={()=>this.buttonAction('rightButton')} className={`${classes.buttonShow}`}>
                            <ChevronRightOutlinedIcon/>
                        </IconButton>
                    </div>
                    :
                    <div className={classes.problemFileWrapper}>
                        <div ref={this.fileWrapper} className={`${classes.problemFiles} ${zoomIn ? classes.problemFilesZoomIn : ''}`}>
                            {dataDetailProblem.documents && this.showFiles(dataDetailProblem.documents)}
                        </div>
                    </div>
                }
                <div className={`problemContentNormal ${classes.problemContent} ${zoomIn ? classes.problemContentZoomIn : ''}`}>
                    {dataDetailProblem && dataDetailProblem.problem && parse(dataDetailProblem.problem)}
                </div>
                { open && <GeneralModal
                    open = {open}
                    handleClose = {this.handleCloseModalPreview}
                    content={<ZoomIn item={itemZoom} classes={classes}/>}
                    className={'previewRequestModal'}
                    saveButton={false}
                    cancelButton={false}
                /> }
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(ProblemContentModal);