import React from 'react';
import List from "../../../theme/List";

import { withStyles } from '@material-ui/core/styles';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import { STATUS_DONE, STATUS_FAIL,
    STATUS_WAITING,
} from "../../../constants/constants";
import {Button, makeStyles, Popover} from "@material-ui/core";
import PropTypes from "prop-types";
import { withRouter} from "react-router-dom";
import {API_MAIL_LIST, API_SEND_MAIL} from "../../../constants/apiSuffix";
import {convertToDateD_M_Y_H_m} from "../../../functions/datetime";
import DialogForm from "../../../theme/CustomElements/DialogForm";
import axios from "axios";
import {SET_TIME_OUT_ALERT_SUCCESS} from "../ReactAdmin";
import LoadingAction from "../../../theme/LoadingAction";
import {dateCompare} from "../../../functions/functions";
import {TableHeaderRow} from "@devexpress/dx-react-grid-material-ui";
const TooltipHeaderSort = ({getMessage, column, ...restProps}) => {
    return (
        <TableHeaderRow.SortLabel
            column={column}
            getMessage={() => {
                return `${column.title}`;
            }}
            {...restProps}
        />
    );
};
const styles = {
    mailList:{
        '& .selectFilerInHeaderList':{
            '& > div':{
                padding: '10px 0 10px 10px'
            }
        }
    },
    body:{
        maxHeight: "5rem",
        overflow: "hidden",
        display: "block"
    },
    mailCell: {
        maxWidth: 120,
        margin: '0.5rem 1rem',
        '&.popoverActive':{
            border: '1px dotted #128cca'
        },
        '&.mailBody': {
            width: 360,
            maxWidth: 360,
            overflow: 'hidden',
            cursor: 'pointer',
            '&:hover':{
                border: '1px dotted #128cca'
            }
        },
        '&.mailBody1': {
            // width: 360,
            maxWidth: 500,
            with: '100%',
            minWidth: 360,
            overflow: 'auto',
            maxHeight: 600
        },
        '&.date': {
            maxWidth: 180,
        },
        '&.status': {
            backgroundColor: '#605e72',
            padding: '0.5rem 1rem',
            borderRadius: '0.5rem',
            fontWeight: 600,
            color: '#fff',
            textAlign: 'center',
            '&.waiting': {
                backgroundColor: '#f99b2d',
            },
            '&.done': {
                backgroundColor: '#01ca90'
            },
            '&.fail': {
                backgroundColor: '#e33838',
            },
        },
        '&.recipients': {
            '& .itemRecipient': {
                '& .mail': {
                    fontStyle: 'italic'
                },
                '& .fullName': {
                    fontWeight: 600
                }
            }
        }
    },
    btnResendMail: {
        fontWeight: 600,
        textTransform: 'unset',
        backgroundColor: '#008cca',
        color: '#fff',
        '&:hover': {
            backgroundColor: 'rgba(0, 140, 202, 0.6)'
        }
    },
    popoverBody:{
        '& div':{
            // background:'#fafafa'
        }
    },
    popoverBodyContentBlock: {
        padding: '0.5rem',
        '& .closePopover': {
            textAlign: 'right',
            fontSize: 20,
            lineHeight: 1,
            '& span': {
                cursor: 'pointer',
                fontWeight: 700,
                '&:hover': {
                    color: 'red'
                }
            }

        }
    }
};

const properties = [
    "createdAt",
    "recipients",
    "subject",
    "body",
    "status",
    "sentAt",
    "action"
];

class MailList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            forceUpdate: false,
            idMail: null,
            dialogSendMail: false,
            isLoading: false,
            anchorEl: null,
            valuePopover: null
        }
    }
    openPopover = (event, data) =>{
        this.setState({
            anchorEl: event.currentTarget,
            valuePopover: data
        })
    }
    closePopover = () =>{
        this.setState({
            anchorEl: null,
            valuePopover: null
        })
    }
    componentDidUpdate() {
        if(this.state.forceUpdate){
            this.setState({
                forceUpdate: false
            })
        }
    }
    resendMail = () => {
        this.setState({
            isLoading: true,
            dialogSendMail: false
        })
        axios.post(API_SEND_MAIL.replace('{id}', this.state.idMail))
            .then(res => {
                if (res.status === 200) {
                    this.setState({
                        idMail: null,
                    })
                    setTimeout(function() {
                        this.setState({
                            isLoading: false,
                            forceUpdate: true
                        })
                    }.bind(this), SET_TIME_OUT_ALERT_SUCCESS);
                } else {
                    throw new Error();
                }
            })
            .catch(err => {
                this.setState({
                    idMail: null,
                    dialogSendMail: false,
                    isLoading: false
                })
            })

    }

    render() {
        const { classes, ...other } = this.props;
        const {
            anchorEl,
            valuePopover,
            isLoading
        } = this.state;
        let arrayColumn = [];
        let filterColumnsEnabled = [
            {columnName: 'recipients', sortingEnabled: true},
            {columnName: 'subject', sortingEnabled: true},
            {columnName: 'body', sortingEnabled: true},
            {columnName: 'status', sortingEnabled: true},
            // {columnName: 'action', sortingEnabled: false},
            // {columnName: 'createdAt', sortingEnabled: false},
            // {columnName: 'sentAt', sortingEnabled: false}
        ];
        let sortingStateColumnExtensions = [
            {columnName: 'recipients', sortingEnabled: true},
            {columnName: 'subject', sortingEnabled: true},
            {columnName: 'body', sortingEnabled: true},
            {columnName: 'status', sortingEnabled: true},
            {columnName: 'action', sortingEnabled: false},
            {columnName: 'createdAt', sortingEnabled: true},
            {columnName: 'sentAt', sortingEnabled: true}
        ];
        properties.forEach((item, index) => {
            let nameTitle = item;

            let itemColumn = {
                source: item,
                label: this.props.t("reactAdmin.mail." + nameTitle),
                name: item,
                title: this.props.t("reactAdmin.mail." + nameTitle),
                getCellValue: row =>
                {
                    if (item === "createdAt" || item === "sentAt") {
                        return (
                            <div className={classes.mailCell + " date"}>
                                {row[item] ? convertToDateD_M_Y_H_m(row[item]) : ""}
                            </div>
                        )
                    } else if (item === "status") {
                        switch (row[item]) {
                            case STATUS_WAITING:
                                return <div className={classes.mailCell + " status waiting"}>
                                    {this.props.t('reactAdmin.mail.status_waiting')}
                                </div>
                            case STATUS_DONE:
                                return <div className={classes.mailCell + " status done"}>
                                    {this.props.t('reactAdmin.mail.status_done')}
                                </div>
                            case STATUS_FAIL:
                                return <div className={classes.mailCell + " status fail"}>
                                    {this.props.t('reactAdmin.mail.status_fail')}
                                </div>
                        }
                    } else if (item === "body") {
                        return (
                            <div className={`${classes.mailCell} ${anchorEl && valuePopover?.id && valuePopover.id === row.id ?" popoverActive":""} mailBody`}
                                 aria-owns={"problem-popover-mail-" + row.id}
                                 aria-haspopup="true"
                                 dangerouslySetInnerHTML={{
                                     __html: row[item]
                                 }} onClick={(e)=>this.openPopover(e, row)}>

                            </div>
                        )
                    } else if (item === "recipients") {
                        return (
                            <div className={classes.mailCell + " recipients"}>
                                {
                                    row[item].map((itemRecipient, keyRecip) => {
                                        return (
                                            <div className="itemRecipient" key={keyRecip}>
                                                {itemRecipient.length > 1 ? <>
                                                    <div className="mail">{itemRecipient[0]}</div>
                                                    <div className="fullName">{itemRecipient[1]}</div>
                                                </>: ''}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    } else if (item === "action") {
                       return (
                           <Button className={classes.btnResendMail} onClick={() => {
                               this.setState({
                                   idMail: row.id,
                                   dialogSendMail: true
                               })
                           }}>
                               {this.props.t('reactAdmin.mail.resendMail')}
                           </Button>
                       )
                    }

                    return (
                        <div className={classes.mailCell}>
                            {row[item] ? row[item] : ''}
                        </div>
                    )
                },
                filterOptions:item === "status" ? {
                    type: "select",
                    withNoneOption:false,
                    choices: [
                        {"tooltip": this.props.t('reactAdmin.mail.status_waiting'),"value":STATUS_WAITING, "label": this.props.t('reactAdmin.mail.status_waiting')},
                        {"tooltip": this.props.t('reactAdmin.mail.status_done'),"value":STATUS_DONE, "label": this.props.t('reactAdmin.mail.status_done')},
                        {"tooltip": this.props.t('reactAdmin.mail.status_fail'),"value":STATUS_FAIL, "label": this.props.t('reactAdmin.mail.status_fail')},
                    ]
                }: undefined

            };
            arrayColumn.push(itemColumn);
            filterColumnsEnabled.push({columnName: item, filteringEnabled: false});
            sortingStateColumnExtensions.push({columnName: item, sortingEnabled: false});

        })
        let componentState = Object.assign({
            iconList: '',
            iconNew: '',
            classList: classes.mailList,
            columns: arrayColumn,
            sortingColumnStates: [
                {columnName: "createdAt", compare: dateCompare},
                {columnName: "sentAt", compare: dateCompare},
            ],
            filterColumnsEnabled:filterColumnsEnabled,
            sortingStateColumnExtensions:sortingStateColumnExtensions,
            defaultColumnWidths:[

            ],
            CellHeaderProps: {
                sortLabelComponent: TooltipHeaderSort,
            },
            titleList: '',
            apiNameList: API_MAIL_LIST,
            apiParams: {

            } ,
            /*route link*/
        },this.props.componentState?this.props.componentState:{});
        return (
            <div>
                {isLoading && <LoadingAction/>}
                <List
                    {...other}
                    componentState={componentState}
                    forceUpdate={this.state.forceUpdate}
                    defaultSorting={{createdAt:"desc"}}
                />
                {this.state.dialogSendMail && <DialogForm
                    styleNumber={2}
                    dialogProps={{
                        open: this.state.dialogSendMail
                    }}
                    disagreeButtonProps={{
                        handleDisagree: () => {
                            this.setState({
                                idMail: null,
                                dialogSendMail: false
                            })
                        }
                    }}
                    agreeButtonProps={{
                        handleAgree: () => {
                            this.resendMail();
                        }
                    }}
                    messageProps={{
                        content: <div className={classes.messageDialog}>
                            {this.props.t('reactAdmin.mail.messageSendMail')}
                        </div>
                    }}
                />}
                {anchorEl && valuePopover && <Popover id={"problem-popover-mail-" + valuePopover.id}
                                                      open={true}
                                                      anchorEl={anchorEl}
                                                      anchorOrigin={{
                                                          vertical: 'top',
                                                          horizontal: 'left',
                                                      }}
                                                      transformOrigin={{
                                                          vertical: 'bottom',
                                                          horizontal: 'left',
                                                      }}
                                                      onClose={() => this.closePopover()}
                                                      disableRestoreFocus={true}
                                                      className={classes.popoverBody}
                >
                    <div className={classes.popoverBodyContentBlock}>
                        <div className={"closePopover"}> <span onClick={() => this.closePopover()}>X</span></div>
                        <div className={classes.mailCell + " mailBody1"}
                        dangerouslySetInnerHTML={{
                        __html: valuePopover.body
                    }} />
                    </div>
                </Popover>}
            </div>
        )
    }
}

MailList.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

export default  compose(
    // connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter
) (MailList);
