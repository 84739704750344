import React, {useCallback, useEffect, useState} from 'react';
import 'react-dropzone-uploader/dist/styles.css'
import LoadingAction from "../../theme/LoadingAction";
import {compose} from "redux";
import {Button, Grid, Table, TableCell, TableHead, TableRow, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {NavLink, withRouter} from "react-router-dom";
import axios from "axios";
import * as links from "../../constants/links";
import notify from "devextreme/ui/notify";
import {
    CURRENCY_DEFAULT,
    IS_SENT_TO_CLIENT,
    LG,
    MD,
    PAYMENT_CARD_MASTERCARD,
    PAYMENT_CARD_VISA,
    PAYMENT_REPORT,
    SM,
    XS
} from "../../constants/constants";
import logoClient from "../../images/navbar/logo2.png";
import returnIcon from "../../images/navbar/Groupe 8620.svg";
import {MONTSERRAT, NUNITO} from "../../constants/fontCss";
import banner from "../../images/banner/banner.png";
import {API_END_VIDEO_CALL} from "../../constants/apiSuffix";

const styles = theme => ({
    paymentReportWrapper: {

    },
    navbar: {
        height: 80,
        display: 'flex',
        alignItems: 'center',
        // padding:'0 80px',
        padding: '0 4rem',
        background: '#fff',
        justifyContent: 'space-between',
        [theme.breakpoints.down(XS)]: {
            height: 60
        },
        [theme.breakpoints.between(MD, LG)]: {
            padding: '0 4rem'
        },
        [theme.breakpoints.between(XS, MD)]: {
            padding: '0 2rem'
        },
        '& a': {
            '& img': {
                [theme.breakpoints.down(XS)]: {
                    width: 80
                }
            }
        }
    },
    header: {
        background: `url(${banner})`,
        backgroundSize: 'cover',
        height: 154,
        position: 'relative',
        '&::before':{
            content:'""',
            height: '100%',
            position: 'absolute',
            background: '#2020ac',
            opacity:'0.5',
            boxShadow: 'inset 5px -38px 44px -18px rgba(0,0,0,0.54)',
            width: '100%',
        },
        '& .headerWrapper': {
            position: 'relative',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        [theme.breakpoints.down(SM)]:{
            height: 174
        }
    },
    returnRequestList: {
        borderRadius: 24,
        border: 'solid 1px #ffffff',
        color: '#fff',
        textDecoration: 'none',
        padding: '0.5rem 1rem',
        marginLeft: '3rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        maxWidth: 150,
        [theme.breakpoints.between(XS, SM)]: {
            marginLeft: '1rem',
        },
        '&:hover': {
            //background:'linear-gradient(270deg, rgba(14, 56, 96, 0) 8.24%, #0E2F60 34.94%, #0A0730 97.58%)',
            background: '#ffffff70',
            //color: '#0c1c49',
            color: '#fff',
            backgroundPosition: '100% 0',
            transition: 'all .4s ease-in-out',
        },
        '& > span': {
            fontSize: '1.1125rem',
            paddingLeft: '0.5rem',
            lineHeight: 0.9,
            fontFamily: NUNITO
        }
    },
    bodyEndCallPage: {
        padding: '2rem',
        [theme.breakpoints.between(XS, SM)]: {
            padding: '1rem',
        },
        '& .content': {
            background: '#fff',
            borderRadius: 22,
            boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
            width: '100%',
            padding: '30px 20px',
        },
        '& .successText': {
            textAlign: 'center',
            fontWeight: '600',
            color: '#3fac00',
            fontSize: 18,
        },
        '& .errorText': {
            textAlign: 'center',
            fontWeight: '600',
            color: '#d24545',
            fontSize: 18,
        },
    },
})

const EndCallPage = (props) => {
    const {
        classes,
        match,
        t
    } = props;
    const [endCallStatus, setEndCallStatus] = useState(null);
    useEffect(() => {
        axios.post(API_END_VIDEO_CALL.replace('{meetId}', match.params.meetingId))
            .then(res => {
                if (res.status === 200) {
                    setEndCallStatus(true);
                }
            })
            .catch(err => {
                setEndCallStatus(false);
            })
    }, [])
    return (
        <div className={classes.paymentReportWrapper}>
            {endCallStatus === null && <LoadingAction/>}
            <div className={classes.navbar}>
                <NavLink to={links.HOME}><img src={logoClient} alt={'logo'}/></NavLink>
            </div>
            <div className={classes.header}>
                <div className="headerWrapper">
                    <NavLink to={links.HOME} className={`${classes.returnRequestList} returnRequestList`}>
                        <img alt={t("information.header.returnHistory")} src={returnIcon} />
                        <span>{t("information.header.returnHistory")}</span>
                    </NavLink>
                </div>
            </div>
            <div className={classes.bodyEndCallPage}>
                {
                    endCallStatus === true ?
                        <div className="content">
                            <div className="successText">
                                {t('video_call.label.ended_call')}
                            </div>
                        </div>
                        :
                        endCallStatus === false
                    ?
                            <div className="content">
                                <div className="errorText">
                                    {t('video_call.label.end_wrong')}
                                </div>
                            </div>
                            :
                            <></>
                }
            </div>
        </div>
    );
}

export default compose( withStyles(styles),withTranslation(),withRouter)(EndCallPage);
