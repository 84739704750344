import React from "react";
import {withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import SuccessAlert from "./SuccessAlert";
import ErrorAlert from "./ErrorAlert";

const styles = {
    alertCustom:{
        position: 'absolute',
        zIndex: 10000
    }
};

class AlertCustom extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return(
            <div className={this.props.classes.alertCustom}>
                <SuccessAlert
                    snackbarProps={{
                        open: this.props.successOpen,
                        onClose: this.props.handleCloseNotice,
                    }}
                    message={this.props.messageSuccess ?this.props.messageSuccess :""}
                />
                <ErrorAlert
                    snackbarProps={{
                        open: this.props.errorOpen,
                        onClose: this.props.handleCloseNotice,
                    }}
                    message={this.props.messageError ?this.props.messageError :""}
                />
            </div>
        )
    }
}

export default withStyles(styles)(withTranslation()(AlertCustom));
