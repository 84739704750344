import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";

const styles = {
    mainBackground: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 99999,
        backgroundColor: 'rgba(255,255,255,0.95)',
        '&.mainBackgroundLoadingWrapper':{
            position: 'relative',
            width: '100%',
            minHeight: 50,
            zIndex: 1,
            textAlign: 'center',
            background: 'transparent',
            '& $backgroundContent':{
                position: 'relative',
                transform: 'none',
                left: 0,
                top: 0
            }
        }
    },
    backgroundContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    }
};

class LoadingAction extends React.Component {
    render() {
        const {classes, isLoadingBlock} = this.props;
        return (
            <div className={`${classes.mainBackground} ${isLoadingBlock? "mainBackgroundLoadingWrapper":""}`}>
                <div className={classes.backgroundContent}>
                    <CircularProgress />
                </div>
            </div>
        );
    }

}

LoadingAction.defaultProps={
    isLoadingBlock: false
}
LoadingAction.propTypes = {
    classes: PropTypes.object.isRequired,
    isLoadingBlock: PropTypes.bool
};
export default withStyles(styles)(LoadingAction);