// import lydia from '../images/payment/lydia.svg'
import masterCard from '../images/payment/masterCard.svg'
import visa from '../images/payment/visa.svg'
import paypal from '../images/payment/paypal.svg'
export const PAYMENT_TAB_LYDIA = 'lydia';
export const PAYMENT_TAB_MASTER_CARD = 'masterCard';
export const PAYMENT_TAB_VISA = 'visa';
export const PAYMENT_TAB_PAYPAL = 'payPal';
export const PAYMENT_CARD = 'card';
export const paymentArray = [
    {
        name: PAYMENT_TAB_MASTER_CARD,
        logo: masterCard
    },
    {
        name: PAYMENT_TAB_VISA,
        logo: visa
    },
    {
        name: PAYMENT_TAB_PAYPAL,
        logo: paypal
    },
    // {
    //     name: PAYMENT_TAB_LYDIA,
    //     logo: lydia
    // },
];
