import React from 'react';
import {compose} from "redux";
import {Button, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import CustomSvg from "../../../../theme/CustomElements/CustomSvg";
import AddCameraIcon from "./../../../../images/request/add_camera.svg"
import {
    FILE_2_MB_SIZE_BYTES,
    STRING,
    USER_TYPE_ADMIN,
    USER_TYPE_CLIENT,
    USER_TYPE_SUPER_ADMIN
} from "../../../../constants/constants";
import Dropzone from 'react-dropzone'
import axios from "axios";
import {API_UPDATE_LAST_VIEW_DOCUMENT_BY_TYPE, UPLOAD_REQUEST_DOCUMENT} from "../../../../constants/apiSuffix";
import LoadingAction from "../../../../theme/LoadingAction";
import UploadingAction from "../../../../theme/UploadingAction";
import SuccessAlert from "../../../../theme/form/Alert/SuccessAlert";
import * as applicationActions from "../../../../_actions/application";
import * as applicationAction from "../../../../_actions/application";
import {connect} from "react-redux";
import {REQUEST_UPLOAD_FILE_TOKEN} from "../../../../constants/links";
import {withRouter} from "react-router-dom";

const styles = (theme) => ({
    addDocWrapper: {
        borderLeft: '1px solid rgba(113, 109, 135, 0.25)',
        borderRight: '1px solid rgba(113, 109, 135, 0.25)',
        marginRight: 10,

    },
    btnAddCamera: {
        padding: 5,
        minWidth: 'unset',
        textTransform: 'initial',
        color: '#716d87',
        fontSize: '0.7rem',
        margin: '0 10px',
        width: 120,

        '& .addCamera':{
            textTransform: 'uppercase',
        },
        '& .iconBtnAddCameraWrapper':{
            paddingBottom: 8
        },
        '& svg path': {
            fill: '#4040ff'
        },
        // '& .MuiButton-label': {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            '& div': {
                lineHeight: '16px',
                // paddingTop: '8px',
            },

        // },
        '&:hover':{
            background:'#dbedff7d',
            borderRadius: 10,
            '& div':{
                color:'#1c1cff',

            },
        },
    },
    btnSeenRequestAlertWrapper:{
        cursor: 'pointer',
        backgroundColor: '#0000f9',
        color: '#fff',
        padding: '4px 10px',
        borderRadius: 15,
        textTransform: 'uppercase',
        fontWeight: 600,
        width: 'fit-content',
        opacity: 0.85,
        '&:hover':{
            opacity: 1
        }
    },
    btnSeenRequestAlertBlock:{
        display:'flex',
        justifyContent: 'center'
    }
});

class ButtonAddDocument extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            successOpen: false,
            countFiles: 0,
            progress: 0,
        }

        this.onUploadProgress = this.onUploadProgress.bind(this)
    }

    onUploadProgress(event) {
        let progress = Math.round((100 * event.loaded) / event.total)
        this.setState({
            progress
        });
    }

    handleChange(allFiles) {
        const {
            requestId,
            match,
        } = this.props;

        if (allFiles.length > 0) {
            this.setState({
                isLoading: true
            })
            const formData = new FormData();
            allFiles.map((file, index) => {
                formData.append('documents[' + index + '][documentFile]', file);
            });
            const token = match?.params?.token
            const isUploadFileTokenPage = (match?.path) === REQUEST_UPLOAD_FILE_TOKEN
            const apiUploadFileByToken = "/api/upload_request_document_not_auth/" + token
            axios.post(isUploadFileTokenPage ? apiUploadFileByToken : UPLOAD_REQUEST_DOCUMENT.replace("{id}", requestId), formData, {
                onUploadProgress: this.onUploadProgress
            })
                .then(res => {
                    if (res.status === 200) {
                        if (typeof this.props.loadingRequest === "function") {
                            this.props.loadingRequest();
                        }
                        this.setState({
                            isLoading: false,
                            countFiles: allFiles.length,
                            successOpen: true,
                            progress: 0
                        })
                    } else {
                        throw new Error();
                    }
                })
                .catch(err => {
                    this.setState({
                        isLoading: false,
                        progress: 0
                    })
                })
        }
    }
    actionSeenDocumentAlert = (requestId) =>{
        axios.post(API_UPDATE_LAST_VIEW_DOCUMENT_BY_TYPE.replace("{id}", requestId))
            .then(response => {
                if (response.status === 200) {
                    this.props.removeValueToRequestDocumentManagerNotSeenRedux(requestId)
                } else {
                    throw new Error();
                }
            })
            .catch(err => {
            })
    }
    render() {
        const { classes, t, requestId,groupBaseUser, requestDocumentManagerNotSeen} = this.props;
        const {
            isLoading,
            successOpen,
            countFiles,
        } = this.state;
        return (
            <div className={`${classes.addDocWrapper} addDocWrapper1`}>
                {isLoading && <UploadingAction progress={this.state.progress}/>}
                <Dropzone
                    ref={(fileUpload) => {
                        this.fileUpload = fileUpload;
                    }}
                    // accept={"image/*, video/*"}
                    minSize={0}
                    // maxSize={FILE_2_MB_SIZE_BYTES}
                    maxFiles={1}
                    multiple={true}
                    onDrop={acceptedFiles => this.handleChange(acceptedFiles)}>
                    {({getRootProps, getInputProps}) => {
                        return (<section>
                            <div {...getRootProps()} className={"dropAvatar"}>
                                <input {...getInputProps()} />
                                <div
                                    className={classes.btnAddCamera}
                                    // onClick={(e) => this.fileUpload.open()}
                                >
                                    <div className={"iconBtnAddCameraWrapper"}>
                                        <CustomSvg
                                            svgProps={{
                                                src: AddCameraIcon
                                            }}
                                        />
                                    </div>
                                    <div className="addCamera labelButtonWrapper">{t("request.list.add_camera")}</div>
                                </div>
                            </div>
                        </section>)
                    }}
                </Dropzone>
                {[USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN].includes(groupBaseUser) && Array.isArray(requestDocumentManagerNotSeen) && requestDocumentManagerNotSeen.includes(requestId)  ?
                    <div className={classes.btnSeenRequestAlertBlock}><div className={classes.btnSeenRequestAlertWrapper} onClick={() => this.actionSeenDocumentAlert(requestId)}>{t("request.list.seenDocumentManagerUpdated")}</div>
                    </div> : ""}
                <SuccessAlert
                    snackbarProps={{
                        open: successOpen,
                        onClose: () => {
                            this.setState({
                                successOpen: false
                            })
                        }
                    }}
                    message={t('request.list.messageUploadDocument', {
                        "count": countFiles,
                        "id": requestId
                    })}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dataUser: state.authReducer.dataUser,
        groupBaseUser: state.authReducer.groupBaseUser,
        requestDocumentManagerNotSeen: state.applicationReducer.requestDocumentManagerNotSeen,
        countPaymentRequestReport:state.applicationReducer.countPaymentRequestReport,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        removeValueToRequestDocumentManagerNotSeenRedux: (value) => dispatch(applicationAction.removeValueToRequestDocumentManagerNotSeenRedux(value)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),withStyles(styles), withTranslation(), withRouter)(ButtonAddDocument);
