import {
    BASE_URL,
    BYTE,
    GIGABYTE,
    KILOBYTE,
    BYTE_TO_KILOBYTE,
    STRING,
    MEGABYTE,
    DATA_USER, TIMEZONE
} from "../constants/constants";
import qs from 'qs';
import {ConvertToDateY_M_D} from "./datetime";
import moment from "moment-timezone";


export const checkRequestIsGoFree = (valueRequest) => {
    //when no payment is needed (go free, paidAt = NULL) and not registration date
    return !!(valueRequest && valueRequest.payment && valueRequest.payment.paid && valueRequest.payment.price === 0);
}


export const checkRequestIsGoFreeAndRegistrationStartNull = (valueRequest) => {
    // no payment is needed and not registration date = null
    return !!(checkRequestIsGoFree (valueRequest) && !valueRequest.registrationStart) ;
}
export const getParamInUrl = (url) =>{
    const urlLink =  new URL(url, BASE_URL);
    let searchParam = urlLink.search;
    if(searchParam !== "" && searchParam !== "?" && typeof searchParam === STRING){
        searchParam = searchParam.substr(1);
       return qs.parse(searchParam);
    }
    return false;
}

export const upperCaseStr = (string)=>{
    return string.toUpperCase();
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const createKeyIsDateY_M_D = (datetime) => {
    return "date_"+ConvertToDateY_M_D(datetime)
}

export const convertByteToNumberUnit = (byteNumber) => {
    const numberConvert = byteNumber/BYTE_TO_KILOBYTE;
    let unit = BYTE;
    let resultSizeFile = byteNumber
    if(numberConvert >0.9){
        unit = KILOBYTE;
        const  numberConvert1 = numberConvert / BYTE_TO_KILOBYTE;
        resultSizeFile = numberConvert;
        if(numberConvert1 >0.9) {
            unit = MEGABYTE;
            resultSizeFile = numberConvert1;
            const numberConvert2 = numberConvert1 / BYTE_TO_KILOBYTE;
            if (numberConvert2 > 0.9) {
                resultSizeFile = numberConvert2;
                unit = GIGABYTE;
            }
        }
    }
    return resultSizeFile +""+ unit;
}


export const convertNumberIntMinuteToHour = (numBerMinute)=>{
    if(numBerMinute) {
        let getHours = numBerMinute / 60;
        let getMinutes = numBerMinute % 60;
        getHours = getHours > 1 ? (getHours < 10 ? "0" + parseInt(getHours) : parseInt(getHours)) : "00"
        getMinutes = getMinutes > 1 ? (getMinutes < 10 ? "0" + getMinutes : getMinutes) : "00";
        return moment(moment().format(`YYYY-MM-DD ${getHours}:${getMinutes}:00`));
    }else {
        return "";
    }
}


export const convertTimezoneHour = (hour, date) => {
    let user = localStorage.getItem(DATA_USER);
    let backTimeZone = TIMEZONE;
    if (user !== null) {
        user = JSON.parse(user).data
        if(user && user.timezoneDefault) backTimeZone = user.timezoneDefault
    }
    backTimeZone = moment(date).tz(backTimeZone).utcOffset()
    // console.log('backTimeZone ',backTimeZone)
    let userTimezone = moment().utcOffset()
    let timezone = - backTimeZone + userTimezone
    // console.log('timezone ',timezone)
    hour = hour + timezone
    if(hour >= 1440) hour = hour - 1440
    return hour
}

export const compareDate = (a, b, field) => {
    let checkDate = moment(a).diff(b, field)
    if(checkDate === 0){
        checkDate = moment(a).get('date')*1 - moment(b).get("date")*1
    }
    if(checkDate<=0) return true
    else if(checkDate > 0) return false
}

export const paramsToObject = (entries) => {
    let result = {}
    for(let entry of entries) { // each 'entry' is a [key, value] tupple
        const [key, value] = entry;
        result[key] = value;
    }
    return result;
}

export const dateCompare = (a, b) => {
    let momentA = moment(a, "DD/MM/YYYY HH:mm");
    let momentB = moment(b, "DD/MM/YYYY HH:mm");
    if (!momentB.isValid()) {
        return 1;
    }
    if (!momentA.isValid()) {
        return -1;
    }
    return momentA.format("x") < momentB.format("x") ? -1 : (momentA.format("x") === momentB.format("x") ? 0 : 1);
};

export const convertDatetimeToDDMMYYhhmm= (datetime = "")=>{
    return datetime && moment(datetime).isValid() ? moment(datetime).format("DD/MM/YYYY HH:mm"):""

}
