import React, {useEffect, useState} from "react";
import * as application from "../../../_actions/application";
import {compose} from "redux";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {XS} from "../../../constants/constants";
import {convertTimezoneHour} from "../../../functions/functions";
import CallIcon from "../../../images/request/call.svg";
import moment from "moment";
import {withTranslation} from "react-i18next";
import {getCountUpcomingAppointment} from "../../../_actions/application";

const useStyles = makeStyles((theme)=>({
    callColumn: {
        display: 'flex',
        alignItems: 'center',
        color: '#0d00c1',
        '& .dateWrapper': {
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: 5,
        },
        '& .dateCreate': {
            fontSize: '1rem',
            fontWeight: 700
        },
        '& .hourCreate': {
            fontSize: '0.95rem',
            fontWeight: 300
        }
    },
    dateColumn: {
        display: 'flex',
        color: '#605e72',
        flexDirection: 'column',
        '& .dateCreate': {
            fontSize: '1rem',
            fontWeight: 500
        },
        '& .hourCreate': {
            fontSize: '1rem',
            fontWeight: 300
        }

    },
}))

const UpcomingColumn = (props) => {
    const classes = useStyles();
    const {
        registrationStart,
        handleOpenSetDateModal,
        time,
        row,
        t,
        requestUpcomingAppointment,
        setCountUpcomingAppointment
    } = props;
    let intervalColumn = null;
    const [removeUpcomming, setRemoveUpcoming] = useState(false);
    useEffect(() => {
        intervalColumn = setInterval(() => {
            getData();
        }, 60000)
    }, [])

    const getData = () => {
        console.log( moment())
        if (moment(row.registrationStart) < moment()) {
            setRemoveUpcoming(true);
            clearInterval(intervalColumn);
            setCountUpcomingAppointment(-1)
        }
    }
    // useEffect(() => {
    //     if (removeUpcomming) {
    //         setRemoveUpcoming(false);
    //     }
    // }, [removeUpcomming])
    // console.log(row.registrationStart)
    // console.log(row.registrationTimeStart)
    if (removeUpcomming) {
        return (
            <div className={`${classes.dateColumn} `} onClick={()=>handleOpenSetDateModal({id:row.id, dateSelected: row.registrationStart, startTime: convertTimezoneHour(row.registrationTimeStart, row.registrationStart), endTime: convertTimezoneHour(row.registrationTimeEnd, row.registrationStart)})}>
                        <span
                            className={'dateCreate'}>{t('request.list.the')} {row.registrationStart ? moment(row.registrationStart).format("DD/MM/YYYY") : ""}</span>
                <span
                    className={'hourCreate'}>{time}</span>
            </div>
        )
    }
    return (
        <div className={`${classes.callColumn} `} onClick={()=>handleOpenSetDateModal({id:row.id, dateSelected: row.registrationStart, startTime: convertTimezoneHour(row.registrationTimeStart, row.registrationStart), endTime: convertTimezoneHour(row.registrationTimeEnd, row.registrationStart)})}>
            <img src={CallIcon}/>
            <div className="dateWrapper">
                                        <span
                                            className={'dateCreate'}>{row.registrationStart ? moment(row.registrationStart).format("DD/MM/YYYY") : ""}</span>
                <span
                    className={'hourCreate'}>{time}</span>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        requestUpcomingAppointment:state.applicationReducer.requestUpcomingAppointment,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setCountUpcomingAppointment: (value) => dispatch(application.setCountUpcomingAppointment(value) )
    }
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation()) (UpcomingColumn);

