import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withStyles} from "@material-ui/core";
import { withRouter} from "react-router-dom";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Paper from '@material-ui/core/Paper';
import axios from "axios";
import LoadingAction from "../../../../theme/LoadingAction";
import RowFormColumn from "../../../../theme/form/RowFormColumn";
import CustomInput from "../../../../theme/CustomElements/CustomInput";
import CustomButton from "../../../../theme/CustomElements/CustomButton";
import SaveIcon from "@material-ui/icons/Save";
import AddBoxIcon from '@material-ui/icons/AddBox';
import {ACTION_ADD, ACTION_DELETE} from "../../../../constants/constants";
import {Redirect} from "react-router";
import {DOCUMENT_REQUEST_VALID_URL} from "../../linkRectAdmin";
const styles = {
    btnResponse:{
        background: '#0000ff !important'
    },
    formEdit:{
        padding: '2rem',
        '& .inputRow':{
            display:'flex',
            alignItems: 'center',
            '&.minMaxSize':{
                '&>div':{
                    maxWidth: 300
                }
            },
            '& .unit':{
                paddingLeft: '0.5rem',
                fontWeight: 700,
            }
        }
    },
    fileFormatWrapper:{
        padding: '0.5rem 0',
        display: 'flex',
        flexFlow: 'row wrap',
        '& svg':{
            cursor: 'pointer'
        },
        '& .fileFormatItem':{
            display: 'flex',
            paddingRight: '0.5rem',
            paddingBottom: '0.5rem',
            '& svg':{
                color: '#ff0022'
            }
        },
        '& .btnAdd':{
            '& svg':{
                color: '#0000FF'
            }
        }
    }
}

class DocumentRequestValidForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            forceUpdate: false,
            isLoading: false,
            dataEdit: {},
            errors: {},
            redirectList: false
        };
    }

    componentDidMount() {
        this.setState({isLoading: true})
        axios.get("/api/document_request_valids/"+this.props.match.params.id)
            .then(response=>{
                if(response.status === 200){
                    let documentRequestType = response.data;
                    this.setState({
                        dataEdit: documentRequestType,
                        isLoading: false
                    })
                } else {
                    throw new Error();
                }
            }).catch((err) => {

        })
    }

    handleSave = ()=>{
        let {dataEdit, errors} = this.state;
        // const formData = new FormData();
        this.setState({
            isLoading: true
        })
        let formData = {};
        if(dataEdit.validType === "size"){
            formData= {
                maxSize: dataEdit.maxSize ?parseFloat(dataEdit.maxSize): null,
                minSize:  dataEdit.minSize ?parseFloat(dataEdit.minSize): 0,
            }
        }else{
            let fileFormatNew = Array.isArray(dataEdit.fileFormat) ? dataEdit.fileFormat.filter(item=> item):[];
            formData= {
                fileType: dataEdit.fileType ?? "",
                fileFormat:  fileFormatNew ?? [],
            }
        }
        axios.put("/api/document_request_valids/"+this.props.match.params.id, formData)
            .then(response=>{
                if(response.status === 200){
                    this.setState({
                        isLoading: false,
                        redirectList : true
                    })
                } else {
                    throw new Error();
                }
            }).catch((err) => {

        })
    }

    handleChangeFileFormat = (value, key, action) => {
        let {dataEdit, errors} = this.state;
        if (action === ACTION_DELETE) {
            dataEdit["fileFormat"] = dataEdit.fileFormat.filter((itemChild, keyChild) => key !== keyChild);
        } else if (action === ACTION_ADD) {
            dataEdit.fileFormat.push("")
        } else {
            dataEdit["fileFormat"][key] = value;
        }
        this.setState({dataEdit: dataEdit, errors:errors})
    }

    handleChange = (value, field) => {
        let {dataEdit, errors} = this.state;
        if(errors.hasOwnProperty(field)){
            delete (errors[field]);
        }
        let valueNew = value
        if(["maxFile","minFile"].includes(field)){
            valueNew= valueNew.replace(",", ".")
            let regexPrice = /^[0-9.]+$/g;
            if (valueNew.match(regexPrice)) {
                let regexPriceDecimal = /^[0-9]+[.]+([0-9]{3,})$/g
                if (valueNew.indexOf(".") !== -1 && (valueNew.split(".").length > 2 || valueNew.match(regexPriceDecimal))) {
                    valueNew = dataEdit[field];
                }
            } else {
                valueNew = dataEdit[field];
            }
        }
            dataEdit[field] = valueNew;
        this.setState({dataEdit: dataEdit, errors:errors})
    }


    render() {
        const {classes, t} = this.props;
        const {errors, dataEdit, isLoading, redirectList} = this.state;
        if(redirectList){
            return <Redirect to={DOCUMENT_REQUEST_VALID_URL}/>
        }
        const arrayColumn = [
            {
                source: 'minSize',
                label: 'reactAdmin.document_request_valid.minSize'
            },
            {
                source: 'maxSize',
                label: 'reactAdmin.document_request_valid.maxSize'
            },
            {
                source: 'fileFormat',
                label: 'reactAdmin.document_request_valid.fileFormat'
            },
            {
                source: 'fileType',
                label: 'reactAdmin.document_request_valid.fileType'
            }
        ];
        const inputEdit = dataEdit && dataEdit.validType === "size" ? arrayColumn.filter(item=> ["minSize","maxSize"].includes(item.source)): arrayColumn.filter(item=>  !(["minSize","maxSize"].includes(item.source)))
        return (
            <Paper>
                {isLoading && <LoadingAction/>}
                <div className={classes.formEdit}>
                {inputEdit.map((item, key)=>{
                    if(item.source === "fileFormat"){
                       if(Array.isArray(dataEdit.fileFormat )) {
                          return <RowFormColumn
                              key={key}
                               label={t(item.label)}
                               input={ <div className={classes.fileFormatWrapper}>
                                   {dataEdit.fileFormat.map((format, keyFormat) => {
                                       return <div className={"fileFormatItem"} key={keyFormat}><CustomInput
                                           name={item.source}
                                           value={format}
                                           onChange={(e) => this.handleChangeFileFormat(e.target.value, keyFormat)}
                                       /> <RemoveCircleIcon onClick={() => this.handleChangeFileFormat("", keyFormat, ACTION_DELETE)}/></div>
                                   })}
                                   <div className={"btnAdd"} onClick={() => this.handleChangeFileFormat("", "", ACTION_ADD)}>
                                       <AddBoxIcon />
                                   </div>
                               </div>}
                               textValidate={errors[item.source]}
                           />

                       }
                    }else {
                        return <RowFormColumn
                            key={key}
                            label={t(item.label)}
                            input={<div className={`inputRow ${dataEdit && dataEdit.validType === "size" ? "minMaxSize":""}`}><CustomInput
                                name={item.source}
                                type={dataEdit && dataEdit.validType === "size" ? "number" : "text"}
                                value={dataEdit[item.source]}
                                onChange={(e) => this.handleChange(e.target.value, item.source)}
                                // validate={dataEdit[item.source]}
                            />{dataEdit && dataEdit.validType === "size" ? <div
                                className={"unit"}>{t("reactAdmin.document_request_valid.sizeFileUnit")}</div> : ""}
                            </div>}
                            textValidate={errors[item.source]}
                        />
                    }
                })}
                    <CustomButton
                        className={classes.btnResponse}
                        title={this.props.t("button.save")}
                        buttonProps={{
                            startIcon: <React.Fragment><SaveIcon className={"iconAdd"} /></React.Fragment>
                        }}
                        onClick={this.handleSave}
                    />
                </div>

            </Paper>
        );
    }
}
DocumentRequestValidForm.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

export default compose(
    // connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withRouter,
    withTranslation()
)(DocumentRequestValidForm);
