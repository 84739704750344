import React from "react";
import InlineSVG from "react-inlinesvg";
import PropTypes from "prop-types"

class CustomSvg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialState: {
                cacheRequests: true,
                uniqueHash: 'r8e1a9c5',
                uniquifyIDs: true
            }
        }
    }

    render() {
        const {svgProps, scale} = this.props;
        const {initialState} = this.state;
        let props = Object.assign(initialState, svgProps ? svgProps : {})
        return (
            <InlineSVG
                {...props}
                style={{transform: scale ? `scale(${scale})` : 'scale(1)'}}
            />
        )
    }
}

CustomSvg.propTypes = {
    scale: PropTypes.string,
    svgProps: PropTypes.object
}

export default CustomSvg;