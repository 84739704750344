import React from 'react';
import * as currency from 'country-currency-map';
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    InputBase, 
    // TextField, 
    withStyles
} from "@material-ui/core";
import {withTranslation} from "react-i18next";

const styles = {
    appCurrencyAutocomplete:{
        minWidth: "4.5rem",
        fontFamily: `"Nunito", sans-serif`,
        "& $inputText":{
            width: "100%"
        }
    },
    inputBase: {

    },
    currencyLabel: {

    }
};

class AppCurrencyAutocomplete extends React.Component{
    // constructor(props) {
    //     super(props);
    // }

    loadCurrencyList(){
        return currency.getCurrencyList();
    }

    render() {
        const {classes,i18n,tReady,classBonusName,inputPlaceHolder,t,...otherProps} = this.props;
        const self = this;
        let options = this.loadCurrencyList();
        let currencyValue = this.props.value;
        if (currencyValue && currencyValue.abbr){
            const regex = new RegExp(currencyValue.abbr,"gi");
            options = options.filter((item)=>regex.test((item.abbr+" "+item.name+" "+item.symbolFormat.replace(/\s*\{#\}\s*/gi,""))));
            const valueIndex = options.findIndex((item)=>item.abbr===currencyValue.abbr);
            if (valueIndex>-1){
                currencyValue = options[valueIndex];
            }
        }
        return <Autocomplete
            freeSolo
            className={classes.appCurrencyAutocomplete}
            options={options}
            autoHighlight
            getOptionLabel={option => {
                if (option.abbr){
                    return `${option.abbr}`;
                }
                return "";
            }}
            clearText={this.props.t("label.empty")}
            closeText={this.props.t("label.close")}
            loadingText={this.props.t("label.loading")}
            renderOption={option => (
                <React.Fragment>
                    <span className={classes.currencyLabel}>{option.abbr}</span>&nbsp;(<span dangerouslySetInnerHTML={{__html: option.symbolFormat?option.symbolFormat.replace(/\s*\{#\}\s*/gi,""):"" }} />)
                </React.Fragment>
            )}
            renderInput={params => {
                const {InputProps, InputLabelProps, ...newParams} = params
                return <InputBase
                    {...newParams}
                    ref={params.InputProps.ref}
                    placeholder={inputPlaceHolder ?? self.props.t('label.currency')}
                    className={`${classes.inputBase} ${classBonusName ? classBonusName : ''}`}
                />;
            }}
            {...otherProps}
            value={currencyValue}
        />;
    }
}

export default withStyles(styles)(withTranslation()(AppCurrencyAutocomplete));