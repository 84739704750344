import {CategoryCreate, CategoryEdit, CategoryList} from "./resources/category";
import {MailTypeList} from "./resources/mailType";
import {RequestMailTypeList} from "./resources/requestMailType";
import {UserList} from "./resources/user/user";
import UserNew from "./resources/user/UserNew";
import UserEdit from "./resources/user/UserEdit";
import ParameterPage from "./resources/parameters/ParameterPage";
import {
    APP_ADVERTISE,
    BLICKO_CONTACT,
    BREAK_TIME,
    CALL_TIME,
    CURRENCY,
    DAY_OF_WEEK, EMAIL_ADDRESS_BCC, GENERAL_DOCUMENT,
    HOLIDAY, IMPORT_INTERVENTION, PRICE_PAY_DEFAULT, SHARE_DOCUMENT_EXPIRE_NUMBER_DAY,
    WORK_TIME
} from "../../constants/constants";
import FormParameter from "./resources/parameters/FormParameter";
import MailTypeAndSmsTypeForm from "./resources/MailTypeAndSmsTypeForm";
import {LeaseTypeCreate, LeaseTypeEdit, LeaseTypeList} from "./resources/leaseType";
import DocumentRequestValidList from "./resources/documentRequestValid/DocumentRequestValidList";
import DocumentRequestValidForm from "./resources/documentRequestValid/DocumentRequestValidForm";
import ImportIntervention from "./resources/ImportIntervention";
import {SubDocumentTypeCreate, SubDocumentTypeEdit, SubDocumentTypeList} from "./resources/subDocumentType";
import MaiList from "./resources/mail";
import GeneralDocumentPage from "./resources/generalDocument/GeneralDocumentPage";
import GeneralDocumentForm from "./resources/generalDocument/GeneralDocumentForm";
import {RequestList} from "./resources/request";
import {SmsTypeList} from "./resources/smsType";
import {VoiceTypeEdit, VoiceTypeList} from "./resources/voiceType";
import SmsAndVoiceToken from "./resources/SmsAndVoiceToken";

const categories = {
    name: "categories",
    list: CategoryList,
    create: CategoryCreate,
    edit: CategoryEdit
}

const videoType = {
    name: "video_types",
    list: CategoryList,
    create: CategoryCreate,
    edit: CategoryEdit
}
const imageType = {
    name: "image_types",
    list: CategoryList,
    create: CategoryCreate,
    edit: CategoryEdit
}

const sub_document_type = {
    name: "sub_document_types",
    list: SubDocumentTypeList,
    create: SubDocumentTypeCreate,
    edit: SubDocumentTypeEdit
}

const requestDocumentType = {
    name: "request_document_types",
    list: CategoryList,
    create: CategoryCreate,
    edit: CategoryEdit
}

const document_request_valid = {
    name: "document_request_valids",
    list: DocumentRequestValidList,
    create: null,
    edit: DocumentRequestValidForm
}

const lease_type = {
    name: "lease_types",
    list: LeaseTypeList,
    create: LeaseTypeCreate,
    edit: LeaseTypeEdit
}

const mail_types = {
    name: "mail_types",
    list: MailTypeList,
    create: null,
    edit: MailTypeAndSmsTypeForm
}
const request_mail_types = {
    name: "request_mail_types",
    list: RequestMailTypeList,
    create: null,
    edit: MailTypeAndSmsTypeForm
}
const users = {
    name: "users",
    list: UserList,
    create: UserNew,
    edit: UserEdit
}
const parameter_current = {
    name: CURRENCY,
    list: ParameterPage,
    create: FormParameter,
    edit: FormParameter
}

const mails = {
    name: "mails",
    list: MaiList,
}

const parameter_break_time = { ...parameter_current,
    name: BREAK_TIME,
}
const parameter_work_time = { ...parameter_current,
    name: WORK_TIME,
}
const parameter_day_of_week = { ...parameter_current,
    name: DAY_OF_WEEK,
}

const parameter_holiday = { ...parameter_current,
    name: HOLIDAY,
}
const parameter_call_time = { ...parameter_current,
    name: CALL_TIME,
}
const parameter_blicko_contact = { ...parameter_current,
    name: BLICKO_CONTACT,
}

const parameter_price_default = { ...parameter_current,
    name: PRICE_PAY_DEFAULT,
}


const parameter_email_address_bcc_default = { ...parameter_current,
    name: EMAIL_ADDRESS_BCC,
}

const parameter_app_advertise_default = { ...parameter_current,
    name: APP_ADVERTISE,
}

const parameter_share_document_expire_number_day_default = { ...parameter_current,
    name: SHARE_DOCUMENT_EXPIRE_NUMBER_DAY,
}

const parameter_general_document = { ...parameter_current,
    name: GENERAL_DOCUMENT,
    list: GeneralDocumentPage,
    create: GeneralDocumentForm,
    edit: GeneralDocumentForm
}

const import_intervention = { ...parameter_current,
    name: IMPORT_INTERVENTION,
    list: ImportIntervention
}

const request = { ...parameter_current,
    name: "request",
    list: RequestList
}


const sms_types = {
    name: "sms_types",
    list: SmsTypeList,
    create: null,
    edit: MailTypeAndSmsTypeForm
}

const voice_type = {
    name: "voice_types",
    list: VoiceTypeList,
    create: null,
    edit: VoiceTypeEdit
}

const param_token = {
    name: "param_token",
    list: SmsAndVoiceToken,
    create: null,
    edit: null
}
export const arrResources = [videoType,requestDocumentType,document_request_valid,imageType,
    lease_type, mail_types, request_mail_types, users, parameter_current, parameter_break_time, parameter_work_time, parameter_day_of_week,
    parameter_holiday, parameter_call_time,parameter_blicko_contact,parameter_price_default,parameter_email_address_bcc_default,import_intervention, mails,
    parameter_app_advertise_default, parameter_general_document, parameter_share_document_expire_number_day_default, request, sms_types, voice_type,
    param_token
];
