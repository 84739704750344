export const HOME = "/";

export const PAGE403 = "/403";
export const PAGE400 = "/400";
export const PAGE404 = "/404";
export const PAGE500 = "/500";

export const REQUEST = "/request";
export const REPORT = "/report";
export const ADMIN_REQUEST = "/request/list";
export const ENABLE_USER = "/enable/user/:tokenEnabled";

export const INFORMATION_PAGE = "/request/:id/edit";
export const EDIT_REQUEST_NOT_LOGIN = "/edit/request/:tokenEnabled";
// export const REGISTRATION_INFORMATION = '/new-request';
export const REQUEST_INFORMATION_DETAIL = "/request/:id/detail";

export const LOGIN = "/login";
export const CHANGE_PASSWORD = "/change-password/:token";

export const BACKOFFICE_PAGE = "/backoffice";
export const PROFILE = "/profile";
// export const TEST_MODAL = '/test_modal' //cai nay de test
export const PAYMENT_FORM =
  "/payment-form/:requestId/:paymentType/:paymentCard";
export const CALENDAR = "/calendar";
export const RESTDAY = "/restday";

export const LOGIN_SOCIAL = "/login/social/:dataUser";
export const CATEGORY = "/category";
export const MANAGEMENT_STAFF = "/management/staff";
export const DETAIL_STAFF = "/staff/:id";
export const EDIT_STAFF = "/edit/:id/staff";
export const CREATE_STAFF = "/add/staff/";
// export const DETAIL_REQUEST = '/request/:id/detail';

export const DIAGNOSTIC = "/diagnostic/:id";
export const REPORT_REQUEST = "/report/:id";

export const SHOW_SHARED_DOCUMENTS_NOT_LOGIN = "/share/:token";
export const ADD_REPORT_TO_REQUEST = "/add-quotation/:token";
export const PAYMENT_REPORT_REQUEST = "/pay-report/:token";

export const END_VIDEO_CALL = "/end-call/:meetingId";
export const REQUEST_UPLOAD_FILE_TOKEN = "/request-upload/:token";

export const REQUEST_REPAIR = "/request/:token/repair";
