import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import LoadingAction from "../../theme/LoadingAction";
import axios from "axios";
import {API_ENABLE_USER} from "../../constants/apiSuffix";
import {DATA_USER, LOGIN_PAGE} from "../../constants/constants";
import * as auth from "../../_actions/auth";
import * as application from "../../_actions/application";
import AlertCustom from "../../theme/form/Alert/AlertCustom";
import * as links from "../../constants/links"
import {Button} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import {Redirect} from "react-router";
const styles = theme => ({

});

class EnableUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            successOpen: false,
            errorOpen: false,
            messageError:"",
            redirectUrl : ""
        }
    }

    handleCloseNotice = () => {
        this.setState({
            successOpen: false,
            errorOpen: false
        })
    }

    componentDidMount() {
        const {match}= this.props;
        const token = match &&  match.match.params && match.match.params.tokenEnabled ? match.match.params.tokenEnabled : ""
        if(token) {
            const url =API_ENABLE_USER.replace(':tokenEnabled', token);
            axios.get(url)
                .then(response => {
                    if (response.status === 200) {
                        localStorage.setItem(DATA_USER, JSON.stringify(response.data.data));
                        window.location.href = links.HOME;
                    } else {
                        throw new Error();
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 400 && error.response.data && error.response.data.response_code === "INVALID_TOKEN") {
                        this.setState({
                            isLoading: false,
                            redirectUrl: links.LOGIN
                        }, function () {
                            this.props.setErrorAlert(true, this.props.t("enableUser.errors.notRequestEnableUser"))
                        })
                    }
                })
        }else{
            this.setState({
                isLoading: false,
                redirectUrl: links.LOGIN
            }, function (){
                this.props.setErrorAlert(true, this.props.t("enableUser.errors.notRequestEnableUser"))
            })
        }
    }

    render() {
        const {
            isLoading,messageError, redirectUrl
        } = this.state;
        if(redirectUrl){
            return <Redirect to={links.LOGIN}/>
        }
        return (
            <div>
                {isLoading && <LoadingAction />}
                <AlertCustom
                    successOpen={this.state.successOpen}
                    errorOpen={this.state.errorOpen}
                    handleCloseNotice={()=>this.props.setErrorAlert(false, "")}
                    messageError={messageError}
                />
            </div>
        )
    }
}

EnableUser.defaultProps = {

}

EnableUser.propTypes = {
    classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
    return {
        dataUser : state.authReducer.dataUser
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setErrorAlert: (successOpenAlert, message) => dispatch(application.setErrorAlert(successOpenAlert, message)),
        saveTypeFormAuth: (typeFormAuth) => dispatch(auth.saveTypeFormAuth(typeFormAuth)),
        setDataUser: (dataUser) => dispatch(auth.setDataUser(dataUser)),
        checkSwitchAuthForm: (checkSwitch) => dispatch(auth.checkSwitchAuthForm(checkSwitch)),
        setDataLogin: (dataLogin) => dispatch(auth.setDataLogin(dataLogin)),
    }
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation()
)(EnableUser);