import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import Calendar from "devextreme-react/calendar";
import tickCircleIcon from "../../../images/managerCalendar/tickCircle.svg";
import sunIcon from "../../../images/managerCalendar/type/sun.svg";
import personBigIcon from "../../../images/managerCalendar/personBigIcon.svg";
import pointMultilIcon from "../../../images/managerCalendar/pointIcon.svg";
import moment from "moment";
import {ConvertToDateY_M_D} from "../../../functions/datetime";
import {stylesManagerCalenderPageLeft} from "./scheduleFuntion/styleLeft";

class ManagerCalendarPageLeft extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleChange = (e) => {
        this.props.setDateShowInCalender(moment(e.value))
    }
    render() {
        const {
            classes, t, countValidateRestDay, dateShowInCalendar, countRequestTask, countDataHolidayByMonthYear
        } = this.props;
        const dateSelectY_M_D = ConvertToDateY_M_D(dateShowInCalendar);
        return (
            <div className={classes.managerCalendarPage}>
                <div className={classes.calendarSmallWrapper}>
                        <Calendar
                            value={dateShowInCalendar}
                            firstDayOfWeek={1}
                            focusStateEnabled={false}
                            onValueChanged={this.handleChange}
                            // disabled={paymentRequest ? paymentRequest : loadingTime}
                            // onOptionChanged={(e)=>this.handleChangeMonth(e)}
                            // disabledDates={(date)=>this.checkDisableDate(date)}
                        />
                </div>
                <div className={classes.detailByCalendarSmallWrapper}>
                    <div className={"contentBlockLeft"}>
                        <div
                            className={"titleBlock"}>{t("managerCalendarPage.managerCalendarLeft.titleDetailByCalendar")}</div>
                        <div className={"contentBlock"}>
                            {
                                countRequestTask && countRequestTask.hasOwnProperty(dateSelectY_M_D) &&
                                <div className={"content"}>
                                    <img src={tickCircleIcon} className={"imgStatus"} alt={""}/>
                                    <div className={"number"}>{countRequestTask[dateSelectY_M_D].length}</div>
                                    <div className={"nameStatus"}>{t("managerCalendarPage.type.task")}</div>
                                </div>
                            }
                            {/*{*/}
                            {/*    countValidateRestDay && countValidateRestDay.hasOwnProperty(dateSelectY_M_D) &&*/}
                            {/*    <div className={"content"}>*/}
                            {/*        <img src={taskIcon} className={"imgStatus"} alt={""}/>*/}
                            {/*        <div className={"number"}>{countValidateRestDay[dateSelectY_M_D].length}</div>*/}
                            {/*        <div className={"nameStatus"}>{t("managerCalendarPage.type.holiday")}</div>*/}
                            {/*    </div>*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    countDataHolidayByMonthYear && countDataHolidayByMonthYear.hasOwnProperty(dateSelectY_M_D) &&*/}
                            {/*    <div className={"content"}>*/}
                            {/*        <img src={sunIcon} className={"imgStatus"} alt={""}/>*/}
                            {/*        <div className={"number"}>{countDataHolidayByMonthYear[dateSelectY_M_D].length}</div>*/}
                            {/*        <div className={"nameStatus"}>{t("managerCalendarPage.type.holiday")}</div>*/}
                            {/*    </div>*/}
                            {/*}*/}

                        </div>
                    </div>
                    <div className={"contentBlockRight"}>
                        <img src={pointMultilIcon} alt="" className={"pointIcon"}/>
                        <img src={personBigIcon} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}

ManagerCalendarPageLeft.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(
    withStyles(stylesManagerCalenderPageLeft),
    withTranslation(),
    withRouter
)(ManagerCalendarPageLeft);