import React, {Component} from 'react';
import {withStyles, Grid, CircularProgress} from "@material-ui/core";
import dateBackground from "../../../images/dateClient/dateBackground.svg"
import timeIcon from "../../../images/dateClient/time.svg"
import closeIcon from "../../../images/dateClient/close.svg"
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import Calendar from 'devextreme-react/calendar';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import {NUNITO} from "../../../constants/fontCss";
import moment from "moment";
import {BASE_URL, MD, SM, USER_TYPE_CLIENT, XS} from "../../../constants/constants";
import CustomSvg from "../../../theme/CustomElements/CustomSvg";
import {
    SHOW_TIME_REGISTRATION,
    API_REGISTRATION_TIME,
    SHOW_TIME_REGISTRATION_NOT_LOGIN, API_REGISTRATION_TIME_NOT_LOGIN
} from "../../../constants/apiSuffix"
import axios from "axios";
import CustomButton from "../../../theme/CustomElements/CustomButton";
import {connect} from "react-redux";
import * as application from "../../../_actions/application";
import {withRouter} from "react-router";
import {logout} from "../../../_actions/auth";
import * as links from "../../../constants/links";
import qs from "qs";
import {compareDate, convertTimezoneHour} from "../../../functions/functions";
import AlertCustom from "../../../theme/form/Alert/AlertCustom";
import {TAB_PAID} from "./InformationPage";
import {checkRequestIsGoFree} from "../../../functions/functions";




const styles = theme =>({
    dateClient:{
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        minHeight: '450px',
        [theme.breakpoints.down(MD)]:{
            width: '95%'
        },
        [theme.breakpoints.down(SM)]:{
            width: '100%'
        },
    },
    dateClientWrapper:{
        justifyContent: 'center',
        // position: 'relative'
    },
    dataClient_leftContent:{
        minHeight: '375px',
        backgroundImage: `url(${dateBackground})`,
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        backgroundSize: 'cover',
        zIndex: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        boxShadow: '0px 4px 20px rgba(0, 0, 116, 0.1)',
        borderRadius: '15px 0px 0px 15px',
        '&::before':{
            content:'""',
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: 'linear-gradient(rgba(14, 56, 96, 0) 21.5%, #0E2F60 78.76%, #0A0730 131.14%)',
            zIndex: 1,
            borderRadius: '16px 0 0 16px',
        },
        '& .date_left':{
            zIndex: 2,
            color: '#fff',
            marginLeft: '30px',
            marginRight: '10px'
        },
        '& .diagnosis':{
            fontWeight: 700,
            fontSize: '1.5rem',
            margin: '8px 10px 5px 30px'
        },
        '& .chooseDate':{
            fontWeight: 400,
            fontSize: '1rem'
        },
        [theme.breakpoints.down(XS)]:{
            minHeight: '145px',
            borderRadius: '15px 15px 0 0',
        }
    },
    dataClient_rightContent:{
        padding: ' 0 30px',
        boxShadow: '0px 4px 20px rgba(0, 0, 116, 0.1)',
        borderRadius: '0px 15px 15px 0px',
        border: '1px solid #E0E0E0',
        borderLeft: 'none',
        '& tr':{
            height: '30px',
            '& td':{
                padding: 0,
                border: 'none'
            },
            '& th':{
                color: '#1D243E!important',
                fontWeight: '400!important',
                fontSize: '0.875rem!important',
                fontFamily: `${NUNITO}!important`,
                textTransform: 'capitalize!important'
            }
        },
        '& .dx-calendar-other-view':{
            color: '#BDBDBD!important',
            visibility: 'hidden'
        },
        '& .dx-calendar':{
            minWidth: 'unset',
            width: '100%'
        },
        '& .dx-calendar-caption-button':{
            left: 0,
            right: 'unset',
            background: 'none!important',
            '& .dx-button-content':{
                cursor: 'default!important'
            },
        },
        '& .dx-button':{
            border: 'none!important'
        },
        '& .dx-calendar-cell':{
            fontFamily: NUNITO,
            fontSize: '0.875rem',
            fontWeight: 700,
            color:'#1D243E'
        },
        '& .dx-calendar-empty-cell':{
            background: 'none!important',
            color:'#BDBDBD!important'
        },
        '& .dx-button-text':{
            color:'#1D243E',
            fontWeight: "700",
            fontSize: '0.875rem',
            fontFamily: NUNITO,
            textTransform: 'capitalize'
        },
        '& .dx-calendar-navigator-previous-view':{
            left: 'unset',
            right: '25px',
            width: '25px',
            height: '25px',
            borderRadius: '50%'
        },
        '& .dx-calendar-navigator-next-view':{
            width: '25px',
            height: '25px',
            borderRadius: '50%'
        },
        '& .dx-icon::before':{
            color: '#1D243E'
        },
        '& .dx-calendar-selected-date':{
            boxShadow: 'none!important',
            background: 'none!important',
            color: '#fff',
            '& span':{
                background: '#0000FF',
                borderRadius: '50%',
                // padding: 4,
                display: 'inline-block',
                width: 30,
                height: 30,
                lineHeight: '30px'
            }
        },
        [theme.breakpoints.down(XS)]:{
            borderRadius: '0 0 15px 15px',
            border: '1px solid #E0E0E0',
            borderTop: 'none',
        }
    },
    dataClient_rightContent_selectDate:{
        fontWeight: 700,
        fontSize: '1rem',
        paddingTop: '10px',
        'color':'#1D243E',
        paddingLeft: '15px'
    },
    dataClient_rightContent_hasTime:{
        padding: '0 10px 0px 30px',
        '& .dx-calendar':{
            width: '70%'
        },
        [theme.breakpoints.down(SM)]:{
            paddingLeft: 15
        },
        [theme.breakpoints.down(XS)]:{
            padding: 0,
            '& .dx-calendar':{
                width: '100%'
            },
        }
    },
    dataClient_rightContent_calendarWrapper:{
        display: 'flex',
        position: 'relative',
        [theme.breakpoints.down(XS)]:{
            flexDirection: 'column'
        }
    },
    dataClient_rightContent_selectTimeWrapper:{
        paddingLeft: '20px',
        width: '30%',
        position: 'relative',
        [theme.breakpoints.down(XS)]:{
            paddingLeft: 0,
            width: '65%',
            margin: '0.5rem auto',
            textAlign: 'center',
            minHeight: 200
        }
    },
    dataClient_rightContent_selectTime:{
        color: '#1D243E',
        fontSize: '0.875rem',
        fontWeight: '700'
    },
    listTime: {
        height: '220px',
        overflow: 'auto',
        margin: '20px 0',
        '&::-webkit-scrollbar': {
            width: '5px'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor:'#C4C4C4',
            borderRadius: 3
        },
    },
    timeElement: {
        border: '1px solid #E0E0E0',
        borderRadius: '15px',
        height: '40px',
        lineHeight: '40px',
        textAlign: 'center',
        marginRight: 10,
        cursor: 'pointer',
        color: '#1D243E',
        '&:not(:last-child)':{
            marginBottom: '10px'
        }
    },
    timeSelected:{
        border: '2px solid #0000FF'
    },
    dateTimeSelected:{
        fontWeight: 700,
        fontSize: '0.875rem',
        color: '#1D243E',
        maxWidth: 270,
        height: 50,
        borderRadius: 15,
        border: '1px solid #E0E0E0',
        boxShadow: '0px 4px 20px rgba(0, 0, 116, 0.1)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
        '& span':{
            paddingRight: 5
        },
        '& svg':{
            cursor: 'pointer',
            '&:hover > path':{
                fill: '#3f57a7',
            }

        },
        [theme.breakpoints.down(XS)]:{
            maxWidth: 'unset'
        }
    },
    loadingTime:{
        position: 'absolute',
        width: '100%',
        height: '85%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
        left: 0,
        padding: 15,
        fontWeight: 700,
        color: 'red',
        [theme.breakpoints.down(XS)]:{
            height: '50%'
        }
    },
    buttonSubmitWrapper:{
        textAlign: 'end',
        [theme.breakpoints.down(XS)]:{
            textAlign: 'center',
            padding: '1rem'
        }
    },
    buttonSubmit:{
        background: '#0000FF!important',
        height: 50,
        width: 145,
        '&:before': {
            background: 'none'
        },
        '&:hover':{
            background: '#0000c7!important'
        }
    },
    errorNoDate:{
        color: 'red',
        [theme.breakpoints.down(XS)]:{
            padding: '0 1rem'
        }
    },
    disableTime:{
        cursor: 'default',
        opacity: '0.5'
    },
    disabledButton:{
        background: '#ddd !important',
        color: '#fff!important'
    },
    loadingTimeCalendar:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    }
})

class DateClient extends Component {
    constructor(props) {
        super(props);
        this.state={
            dateSelected: this.props.dataTab2.dateSelected ?? null,
            timeSelected: this.props.dataTab2.timeSelected ?? null,
            loadingTime: false,
            callTime: this.props.dataTab2.callTime ?? null,
            timeRange: this.props.dataTab2.timeRange ?? null,
            callTimeFree:this.props.dataTab2.callTimeFree ?? [],
            errors: {},
            dateRestDay: [],
            dateCheckDisable: this.props.dataTab2.dateSelected ?? new Date(),
            callTimeMonth: {},
            monthSelect:this.props.dataTab2.dateSelected ? moment(this.props.dataTab2.dateSelected).format("MM") : moment().format("MM"),
            errorOpen: false,
            successOpen: false
        }
    }
    handleChange = (e, event) => {
        const {monthSelect, callTimeMonth, errors} = this.state
        const {match, location, id}= this.props;
        let url = SHOW_TIME_REGISTRATION
        const pathPage = location && location.pathname ? location.pathname : "";
        let date = moment(e.value).format('YYYY-MM-DD')
        let month = moment(e.value).format('MM')
        this.setState({
            dateSelected: e.value,
            timeSelected: null,
            loadingTime: true,
            errors: {}
        },()=> {
            if(this.props.callbackDate) this.props.callbackDate(null, null, true)
        })
        if (month !== monthSelect) {
            if(pathPage === links.EDIT_REQUEST_NOT_LOGIN.replace(":tokenEnabled", match && match.params ? match.params.tokenEnabled:"")){
                url = SHOW_TIME_REGISTRATION_NOT_LOGIN
            }
            axios.get(url + "?" + `date=${date}` + '&' + `requestId=${id}`)
                .then(({data}) => {
                    let callTimeFree = []
                    if(data.dataCallTime) callTimeFree= data.dataCallTime[moment(e.value).format('D')].callTimeFree
                    this.setState({
                        callTime: data.callTime,
                        callTimeFree: callTimeFree ? callTimeFree : [],
                        callTimeMonth: data.dataCallTime ?? {},
                    }, () => {
                        this.props.setDataFormTab2({
                            ...this.props.dataTab2,
                            callTime: this.state.callTime,
                            callTimeFree: this.state.callTimeFree,
                            dateSelected: e.value,
                            timeSelected: null
                        })
                    })
                })
                .catch(err => {
                    // console.log(err)
                    if(err.response && err.response.data && err.response.data.message){
                        errors.backServer = err.response.data.message
                        this.setState({
                            errors: errors
                        })
                    }
                })
                .finally(() => this.setState({
                    loadingTime: false
                }, ()=>{
                    if(this.props.callbackDate) this.props.callbackDate(null, null, false)
                }))
        } else {
            let {callTimeFree} = callTimeMonth[`day_${moment(e.value).format('D')}`]
            this.setState({
                callTimeFree: callTimeFree,
            },()=>this.props.setDataFormTab2({... this.props.dataTab2,callTimeFree: callTimeFree, dateSelected: e.value, timeSelected: null}))
            setTimeout(()=>this.setState({loadingTime: false}, ()=>{if(this.props.callbackDate) this.props.callbackDate(null, null, false)}),2000)
        }

    }
    handleSetTime = (time, timeRange) => {
        this.setState({
            timeSelected: time,
            timeRange: timeRange,
            errors: {}
        },()=>{
            this.props.setDataFormTab2(this.state.timeSelected,'timeSelected')
            this.props.setDataFormTab2(this.state.timeRange,'timeRange')
        })
    }
    deleteDate = () => {
        this.setState({
            dateSelected: null,
            timeSelected: null
        },()=>{
            this.props.setDataFormTab2({})
        })
    }
    showTime = () => {
        const { classes, paymentRequest,  dataUser} = this.props
        let { timeSelected, callTimeFree, callTime } = this.state
        let timeCheck = moment().format('HH')*60
        let groupUser = dataUser && dataUser.data ? dataUser.data.group : "";
        if(groupUser === USER_TYPE_CLIENT && moment(timeSelected).format('DD MM YYYY') === moment().format('DD MM YYYY')){
            callTimeFree = callTimeFree.filter(item => parseInt(item) > timeCheck)
        }
        return  callTimeFree.map(item=>{
            let starTime = moment().hour(item/60).minute(item%60).second(0)
            let endTime = moment(starTime).add(`${callTime}`,'minute').format('HH:mm')
            let timeRange = moment(starTime).format('HH:mm') + ' - ' + endTime
            return <div  className={`${classes.timeElement} ${timeSelected===item ? classes.timeSelected : ""} ${paymentRequest? classes.disableTime : ""}`} onClick={()=>!paymentRequest?this.handleSetTime(item, timeRange):()=>{}}>{timeRange}</div>
        })
    }
    saveDateSelected = (tabNext) => {
        const { t, paymentRequest, id,token, match, location } = this.props
        const pathPage = location && location.pathname ? location.pathname : "";
        const { dateSelected, timeSelected, errors } = this.state
        const formData = new FormData();
        formData.append("token",token);
        if(!paymentRequest){
            if(!dateSelected || (!timeSelected && timeSelected!==0)){
                errors.notDateTime = t('information.formTab2.errorNoDate')
                this.setState({
                    errors: errors,
                    errorOpen: true
                })
            }
            if(this.checkDisableCalendar()){
                errors.notDateTime = t('information.formTab2.timeExpired')
                this.setState({
                    errors: errors,
                    errorOpen: true
                })
            }
            if(dateSelected && (timeSelected || timeSelected === 0) && this.checkDisableCalendar() === false){
                let dateFormat = moment(dateSelected).format('YYYY-MM-DD')
                formData.append('date',dateFormat)
                formData.append('callTimeRequest',timeSelected)
                let url = API_REGISTRATION_TIME.replace('{id}',id)
                this.props.callbackDate(true)
                if(pathPage === links.EDIT_REQUEST_NOT_LOGIN.replace(":tokenEnabled", match && match.params ? match.params.tokenEnabled:"")){
                    url = API_REGISTRATION_TIME_NOT_LOGIN.replace('{id}',id)
                }
                axios.post(BASE_URL + url, formData)
                    .then(response=>{
                        let userInfo = response.data.item;
                        this.props.updateDataRequest(userInfo);
                        let dateRegis= {
                            dateSelected: userInfo.registrationStart ? new Date(userInfo.registrationStart) : null,
                            timeSelected: userInfo.registrationTimeStart ? convertTimezoneHour(userInfo.registrationTimeStart, userInfo.registrationStart) : null,
                            callTimeFree: userInfo.registrationTimeStart ? [userInfo.registrationTimeStart] : null,
                            callTime: userInfo.registrationTimeEnd && userInfo.registrationTimeStart ? userInfo.registrationTimeEnd - userInfo.registrationTimeStart + 1: null,
                            timeRange: userInfo.registrationTimeEnd && userInfo.registrationTimeStart ? moment().hour(convertTimezoneHour(userInfo.registrationTimeStart, userInfo.registrationStart)/60).minute(convertTimezoneHour(userInfo.registrationTimeStart, userInfo.registrationStart)%60).second(0).format('HH:mm') + "-" + moment().hour(convertTimezoneHour(userInfo.registrationTimeEnd + 1, userInfo.registrationStart)/60).minute(convertTimezoneHour(userInfo.registrationTimeEnd + 1, userInfo.registrationStart)%60).second(0).format('HH:mm') : null
                        }
                        if(!checkRequestIsGoFree(userInfo)) {
                            this.props.callbackDate(false, tabNext ? tabNext : this.props.tabNext);
                        }else{
                            this.setState({
                                successOpen:true
                            })
                        }
                        this.props.setDataFormTab2(dateRegis)
                    })
                    .catch(err=>{
                        if(err.response && err.response.data && err.response.data.message){
                            errors.backServer = err.response.data.message
                            this.setState({
                                errors: errors,
                                errorOpen: true
                            })
                        }
                        this.props.callbackDate(false)
                    })
            }
        }
        else this.props.callbackDate(false, this.props.tabNext)

    }
    handleChangeMonth (e) {
        let {monthSelect, callTime, errors, dateRestDay} = this.state
        const {match, location, id}= this.props;
        const pathPage = location && location.pathname ? location.pathname : "";
        let url = SHOW_TIME_REGISTRATION
        errors = {}
        if(e.name === 'currentDate'){
            let date = e.value
            let dateUrl = moment(date).format('YYYY-MM-DD')
            if(monthSelect!==moment(date).format('MM')){
                this.setState({
                    loadingTime: true,
                    errors: errors
                }, ()=>{if(this.props.callbackDate) this.props.callbackDate(null, null, true)})
                if(pathPage === links.EDIT_REQUEST_NOT_LOGIN.replace(":tokenEnabled", match && match.params ? match.params.tokenEnabled:"")){
                    url = SHOW_TIME_REGISTRATION_NOT_LOGIN
                }
                axios.get(url+"?"+`date=${dateUrl}`+'&'+`requestId=${id}`)
                    .then(response=>{
                        let {data} = response
                        const checkMonth = dateRestDay.find(item => item.month === data.month);
                        if (checkMonth) {
                            dateRestDay = dateRestDay.filter(item => item.month !== data.month);
                        }
                        dateRestDay.push({month: data.month, dataRestDay: data.dateRestday})
                        this.setState({
                            dateRestDay: dateRestDay,
                            callTimeMonth: data.dataCallTime ?? {},
                            monthSelect: data.month ?? null,
                        })
                        if(callTime!==data.callTime){
                            this.setState({callTime: data.callTime})
                            this.props.setDataFormTab2(data.callTime, 'callTime')
                        }
                    })
                    .catch(err=>{
                        // console.log(err)
                        if(err.response && err.response.data && err.response.data.message){
                            errors.backServer = err.response.data.message
                            this.setState({
                                errors: errors
                            })
                        }
                    })
                    .finally(()=> this.setState({loadingTime: false},()=>{if(this.props.callbackDate) this.props.callbackDate(null, null, false)}))
            }

        }

    }
    checkDisableDate = (date) => {
        let {dateRestDay, callTimeMonth} = this.state
        if(dateRestDay.length && date.view === "month") for(let i in dateRestDay) {
            if(moment(date.date).format("MM") === dateRestDay[i].month){
                if(dateRestDay[i].dataRestDay && dateRestDay[i].dataRestDay.includes(moment(date.date).format("D")*1)) return true
            }
        }
        if(date && date.date && moment(date.date).format('DD MM YYYY') === moment().format('DD MM YYYY')){
            let timeCheck = moment().format('HH')*60
            let callTimeFree = callTimeMonth[moment(date.date).format('D')]
            if(callTimeFree) {
                callTimeFree = callTimeFree.callTimeFree
                callTimeFree = callTimeFree.filter(item => parseInt(item) > timeCheck)
                if(callTimeFree && callTimeFree.length === 0) return true
            }
        }
        return undefined
    }
    checkDisableCalendar = () => {
        let {dateSelected, timeSelected} = this.state
        let dateNow = moment();
        let timeNow = moment(dateNow).get('hour')*60 + moment(dateNow).get('minute')*1
        let checkDate = moment(dateSelected).diff(dateNow, 'days')
        if(checkDate == 0){
            checkDate = moment(dateSelected).get('date')*1 - moment(dateNow).get("date")*1
        }
        let checkMinute = timeSelected - timeNow
        if(checkDate<0) return true
        else if(checkDate > 0) return false
        else return checkMinute <= 0
    }
    componentDidMount() {
        const  {paymentRequest, dataTab2, t} = this.props
        let {errors} = this.state;
        const {match, location, id}= this.props;
        const pathPage = location && location.pathname ? location.pathname : "";
        let url = SHOW_TIME_REGISTRATION
        if(!paymentRequest){
            let date = dataTab2.dateSelected ? moment(dataTab2.dateSelected).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
            let checkDate = moment(dataTab2.dateSelected).diff(moment(), 'days')
            if(checkDate === 0){
                checkDate = moment(dataTab2.dateSelected).get('date')*1 - moment().get('date')*1
            }
            let {dateSelected, timeRange, timeSelected, callTime} = dataTab2
            let dataChoose = moment(dateSelected).format("ddd")+', '+moment(dateSelected).format("DD MMM YYYY")+" - "+timeRange
            this.setState({
                loadingTime: true
            }, ()=>{if(this.props.callbackDate) this.props.callbackDate(null, null, true)})
            if(pathPage === links.EDIT_REQUEST_NOT_LOGIN.replace(":tokenEnabled", match && match.params ? match.params.tokenEnabled:"")){
                url = SHOW_TIME_REGISTRATION_NOT_LOGIN
            }
            axios.get(url+"?"+`date=${date}`+'&'+`requestId=${id}`)
                .then(response=>{
                    let {data} = response
                    if(data && data.dataCallTime){
                        let dateShow = data.dataCallTime[`day_${moment(date).format('D')*1}`]
                        if(dateShow) {
                            if((dataTab2.dateSelected && dataTab2.timeSelected && dateShow.callTimeFree.indexOf(timeSelected)===-1) || (callTime && data.callTime!==callTime) || checkDate < 0){
                                errors.timeExpired = t('information.formTab2.timeExpired',{date:`(${dataChoose})`})
                                this.setState({
                                    errors: errors,
                                    // dateSelected: null,
                                    timeSelected: null,
                                    timeRange: null,
                                })
                            }
                        }
                        this.setState({
                            dateRestDay: [{month: data.month, dataRestDay: data.dateRestday}],
                            callTimeMonth: data.dataCallTime,
                            monthSelect: data.month,
                            callTime: data.callTime,
                            callTimeFree: dateShow.callTimeFree
                        })
                        this.props.setDataFormTab2(data.callTime, 'callTime')
                    }
                })
                .catch(err=>{
                    // console.log(err)
                })
                .finally(()=>this.setState({
                    loadingTime: false,
                }, ()=>{if(this.props.callbackDate) this.props.callbackDate(null, null, false)}))

        }
    }
    handleCloseNotice = () => {
        this.setState({
            successOpen: false,
            errorOpen: false,
        })
    }

    render() {
        const { classes, t, paymentRequest, callSaveDate } = this.props
        const { dateSelected, timeSelected, loadingTime, timeRange, callTimeFree, errors  } = this.state
        let minDate =  moment()
        if(dateSelected && compareDate(moment(dateSelected), minDate, 'days')) minDate = moment(dateSelected)
        if(callSaveDate) this.saveDateSelected()

        return (
            <React.Fragment>
                <AlertCustom
                    successOpen={this.state.successOpen}
                    errorOpen={this.state.errorOpen}
                    handleCloseNotice={this.handleCloseNotice}
                />
                <div className={classes.dateClient}>
                    <Grid container xs={12} className={classes.dateClientWrapper}>
                        <Grid item xs={12} sm={3} md={4} className={classes.dataClient_leftContent}>
                            <img src={timeIcon} className={"date_left"}/>
                            <span className={"date_left diagnosis"}>{t('information.formTab2.diagnosis')}</span>
                            <span className={"date_left chooseDate"}>{t('information.formTab2.chooseDate')}</span>
                        </Grid>
                        <Grid item xs={12} sm={dateSelected?9:8} md={8} className={`${classes.dataClient_rightContent} ${dateSelected ? classes.dataClient_rightContent_hasTime : ""}`}>
                            <p className={classes.dataClient_rightContent_selectDate}>{t('information.formTab2.selectDate')}</p>
                            <div className={classes.dataClient_rightContent_calendarWrapper}>
                                <Calendar
                                    value={dateSelected}
                                    firstDayOfWeek={1}
                                    min={minDate}
                                    focusStateEnabled={false}
                                    onValueChanged={this.handleChange}
                                    disabled={paymentRequest ? paymentRequest : loadingTime}
                                    onOptionChanged={(e)=>this.handleChangeMonth(e)}
                                    disabledDates={(date)=>this.checkDisableDate(date)}
                                    minZoomLevel={"month"}
                                    maxZoomLevel={"month"}
                                    activeStateEnabled={false}
                                />
                                {!dateSelected && loadingTime &&
                                <div className={classes.loadingTimeCalendar}><CircularProgress/></div>
                                }
                                {dateSelected && <div className={classes.dataClient_rightContent_selectTimeWrapper}>
                                    <span className={classes.dataClient_rightContent_selectTime}>
                                        {moment(dateSelected).format("MMM DD, YYYY")}
                                    </span>
                                    {!loadingTime ?
                                        callTimeFree.length || (dateSelected && callTimeFree.length) ?
                                            <div className={classes.listTime}>
                                                {this.showTime()}
                                            </div>
                                            :
                                            // <div className={classes.loadingTime}>{t('information.formTab2.offTime')}</div>
                                            null
                                        :
                                        <div className={classes.loadingTime}><CircularProgress/></div>
                                    }
                                </div>}
                            </div>
                            {errors && errors.notDateTime && <Grid xs={12} className={classes.errorNoDate}>{errors.notDateTime}</Grid>}
                            {errors && errors.backServer && <Grid xs={12} className={classes.errorNoDate}>{errors.backServer}</Grid>}
                            {errors && errors.timeExpired && <Grid xs={12} className={classes.errorNoDate}>{errors.timeExpired}</Grid>}
                        </Grid>
                        {dateSelected && (timeSelected || timeSelected === 0) && timeRange && !loadingTime && <Grid xs={12}>
                            <div className={classes.dateTimeSelected}>
                                <span>{moment(dateSelected).format("ddd")+', '+moment(dateSelected).format("DD MMM YYYY")+" - "+timeRange}</span>
                                {!paymentRequest && <span onClick={()=>this.deleteDate()}>
                                    <CustomSvg
                                        svgProps={{
                                            src: closeIcon,
                                        }}
                                    />
                                </span>}
                            </div>
                        </Grid>}
                    </Grid>
                </div>
                {!paymentRequest && <Grid xs={12} className={classes.buttonSubmitWrapper}>
                    <CustomButton
                        className={`${classes.buttonSubmit} ${loadingTime ? classes.disabledButton : ""}`}
                        title={t('information.formTab2.next')+' >'}
                        onClick={()=>this.saveDateSelected(TAB_PAID)}
                        disabled={loadingTime}
                    />
                </Grid>}
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => (
    {
        dataTab2: state.applicationReducer.dataTab2,
        dataUser: state.authReducer.dataUser,
    })
const mapDispatchToProps = dispatch => (
    {
        setDataFormTab2: (dataTab2, field) => dispatch(application.setDataFormTab2(dataTab2, field))
    })
export default compose(connect(mapStateToProps,mapDispatchToProps),withTranslation(),withStyles(styles), withRouter)(DateClient);
