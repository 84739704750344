import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import {withRouter} from "react-router";
import {
    API_GET_USER_LIST,
} from "../../../constants/apiSuffix";
import axios from "axios";
import qs from "qs";
import AppPersonAvatar from "../../../theme/Avatar/AppPersonAvatar";
import SearchIcon from '@material-ui/icons/Search';
import {USER_TYPE_ADMIN, USER_TYPE_REPAIRER} from "../../../constants/constants";
import CustomPagination from "../../../theme/list/CustomPagination";
import CustomInput from "../../../theme/CustomElements/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import LoadingAction from "../../../theme/LoadingAction";
import RequestHeaderAdmin from "../../client/request/RequestHeaderAdmin";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import banner from "../../../images/banner/banner.png";
import CustomButton from "../../../theme/CustomElements/CustomButton";
import {NavLink} from "react-router-dom";
import {CREATE_STAFF, DETAIL_STAFF} from "../../../constants/links";
import userIcon from "../../../images/infomation/user.svg"
import addressIcon from "../../../images/infomation/address.svg";
import mailIcon from "../../../images/infomation/mail.svg"
import phoneIcon from "../../../images/infomation/phone.svg"
import CustomInlineSvg from "../../../theme/CustomElements/CustomInlineSvg";
import * as links from "../../../constants/links";
const styles = theme => ({
    staffPage: {
        background: '#f8f8ff',
        // padding: '2rem',
        '& .headerStaffPage': {
            background: `url(${banner})`,
            backgroundSize: 'cover',
            height: 100,
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: '3rem',
            '&::before': {
                content:'""',
                width: '55%',
                height: '100%',
                position: 'absolute',
                background: 'linear-gradient(270deg, rgba(14, 56, 96, 0) 8.24%, #0E2F60 34.94%, #0A0730 97.58%)',
            },
            '& a':{
                textDecoration:'none'
            },
            '& .headerListWrapper': {
                top: 0,
                position: 'relative',
                '& .statusWrapper': {
                    textTransform: 'uppercase',
                }
            }
        },
    },
    bodyWrapper:{
        padding: '2rem',
        '& .emptyData':{
            fontSize: '1.1rem',
            display: 'flex',
            justifyContent: 'center',
            color: '#918b8b'
        }
    },
    blockItemWrapper:{
        padding: '4rem 1rem'
    },
    itemWrapper: {
        background: ' #FFFFFF',
        boxShadow: '0px 4px 20px rgba(0, 0, 254, 0.05)',
        borderRadius: 15,
        width: '100%',
        color: '#001529',
        padding: '1rem 0 4rem 0',
        position: 'relative',
        '& .headerItem':{
            marginTop: '-4rem',
            // display: 'flex',
            // justifyContent: 'space-between',
            '& .avatarWrapper':{
                display: 'flex',
                justifyContent: 'center',
                '& img':{
                    objectFit: 'fill!important'
                }
            },
            '& .headerRight':{
                padding: '0.5rem 0 0.5rem 0.5rem',
                textTransform: 'uppercase',
                '& .statusItem': {
                    '&.on':{
                        background: '#e1f2ea',
                        color: '#0DBE2A',
                    },
                    '&.off':{
                        background: '#f6e5eb',
                        color: '#DF373C',
                    },
                    textAlign: 'center',
                    borderRadius: 15,
                    padding: '0.25rem 0.5rem',
                }
            }
        },
        '& .fullName':{
            textAlign: 'center',
            fontSize: '20px',
            fontWeight: 700
        },
        '& .itemBody':{
            padding: '0.3rem 0'
        },
        '& .label':{
            fontWeight: 700,
            fontSize: '1rem',
            display: 'flex',
            alignItems: 'flex-start',
            padding: ' 0 0.5rem',
            lineHeight: '1.25rem',
            '& svg':{
                marginRight: '0.625rem',
                fontSize:'0.9375rem',
                width: 20,
                height: 20
            }
        },
        '& .content':{
            fontSize: '1rem',
            display: 'flex',
            alignItems: 'flex-start'
        },
        '& .viewDetail':{
            // paddingTop: '1rem',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            height: '3rem',
            '& a':{
                textDecoration: 'none'
            }
        }
    },
    headerWrapper: {
        padding: '2rem',
        '& .headerBlock': {
            padding: '1rem',
            display: 'flex',
            justifyContent: 'center',
            '& .searchInput':{
                background: '#F8F8FF',
                border: '1px solid #D8DDE6',
                borderRadius: 20

            }
        },
    },
    customTooltip:{
        fontSize: '0.8rem'
    },
    viewDetail:{
        '&:hover':{
            color: '#0000c7!important',
            background: '#d9d9d9'
        },
        borderRadius: '0 0 15px 15px',
        borderTop: '1px solid #DDE4F8',
        fontSize: '0.875rem',
        color: '#00d5ff!important',
        fontWeight: 700,
        textAlign: 'center',
        padding: '1rem',
        cursor: 'pointer',
    },
    btnAdd:{
        background: '#00D5FF !important',
        borderRadius: 15,
        fontWeight: 500
    }
});

class StaffList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            dataList: [],
            currentPage: 1,
            pageSize:  10,
            totalPages:  1,
            totalCount: 1,
            apiParams: {},
            userEnabledTrue: 0,
            userEnabledFalse: 0,
            apiNameList: API_GET_USER_LIST
        };
        this.paramsName = "page_"+this.state.apiNameList+"_"+window.location.pathname.replace("/","_")+"_params";
        let pageInfos = localStorage.getItem(this.paramsName);
        if (!pageInfos){
            pageInfos = {};
        }
        else{
            pageInfos = JSON.parse(pageInfos)
        }
        if (pageInfos.hasOwnProperty("pageSize")){
            this.state.pageSize = pageInfos.pageSize;
        }
        if (pageInfos.hasOwnProperty("currentPage")){
            this.state.currentPage = pageInfos.currentPage;
        }
        this.getData = this.getData.bind(this);
    }

    onCurrentPageChange = (value) => {
        localStorage.setItem(this.paramsName,JSON.stringify({currentPage: value}));
        this.setState({
            currentPage: value
        }, function (){
            this.getData();
        })
    }
    onPageSizeChange = (value) => {
        localStorage.setItem(this.paramsName,JSON.stringify({pageSize: value}));
        this.setState({
            pageSize: value
        }, function (){
            this.getData();
        })
    }

    countByEnable = () =>{
        let apiParamsNew = {
            userType: [USER_TYPE_ADMIN, USER_TYPE_REPAIRER]};
        const urlUser1 = API_GET_USER_LIST + "?" + qs.stringify({
            ...apiParamsNew,
            manager: 1,
            filters: {
                enabled: true
            }
        })
        const urlUser0 = API_GET_USER_LIST + "?" + qs.stringify({
            ...apiParamsNew,
            manager: 1,
            filters: {
                enabled: false
            }
        })

        axios.all([
            axios.get(urlUser0),
            axios.get(urlUser1),
        ])

            .then(res => {
                let userEnabledTrue = 0;
                let userEnabledFalse = 0
                if (res[0].status === 200) {
                    userEnabledFalse = res[0].data.nbResults;
                }
                if (res[1].status === 200) {
                    userEnabledTrue = res[1].data.nbResults;
                }
                this.setState({
                    userEnabledTrue: userEnabledTrue,
                    userEnabledFalse: userEnabledFalse
                })
            })
            .catch(err=>{
            })
    }
    getData(notLoading) {
        let {apiParams, pageSize, apiNameList} = this.state;
        if(!notLoading) {
            this.setState({
                isLoading: true
            })
        }
        let apiParamsNew = {...apiParams,
            maxPerPage: pageSize,
            page:this.state.currentPage,
            manager: 1,
            userType: [USER_TYPE_ADMIN, USER_TYPE_REPAIRER]};
        const urlUser = apiNameList + "?" + qs.stringify(apiParamsNew)

        axios.get(urlUser)
            .then(res => {
                if(res.status === 200){
                    this.setState({
                        dataList: res.data.items,
                        currentPage: res.data.currentPage,
                        pageSize:  res.data.maxPerPage,
                        totalPages:  res.data.nbPages,
                        totalCount: res.data.nbResults,
                        isLoading: false,

                    })
                } else {
                    throw new Error();
                }
            })
            .catch(err=>{
                if( err.response && err.response.status) {
                    window.location.href = links.PAGE500
                }
            })
    }

    componentDidMount() {
        this.countByEnable()
        this.getData()
    }

    componentRender = (props, state, item) => {
        const {classes, t} = props;
        const itemPerson = item.person ?? {};
        const userFullNameOrEmail= item && item.person && item.person.fullName ? item.person.fullName : item.email;
        return (
            <div className={classes.itemWrapper}>
                <Grid container>
                    <Grid container item xs={12} className={"headerItem"}>
                        <Grid item xs={4} className={"headerLeft"}></Grid>
                        <Grid item xs={4} className={"avatarWrapper"}>
                            <AppPersonAvatar
                                name={
                                    <React.Fragment>
                                        <div>{userFullNameOrEmail}</div>
                                    </React.Fragment>
                                }
                                alt={userFullNameOrEmail}
                                variant="rounded"
                                src={item && item.person && item.person.avatar && item.person.avatar.pathUrl ? item.person.avatar.pathUrl : ""}
                                avatarImgClassName={"avatarRepairer"}/>
                        </Grid>
                        <Grid item xs={4} className={"headerRight"}>
                            {
                                item.enabled ?
                                    <div className={"statusItem on"}>{t("reactAdmin.user.list.enabledStatus.on")}</div>
                                    : <div className={"statusItem off"}>{t("reactAdmin.user.list.enabledStatus.off")}</div>

                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={"itemBody"}>
                        <div className={"fullName"}>{userFullNameOrEmail}</div>
                    </Grid>
                    <Grid container item xs={12} className={"itemBody"}>
                        <Grid item xs={5} className={"label"}>
                         {/*<PersonIcon/>*/}
                         <CustomInlineSvg svgProps={{src:userIcon}}/>
                         <div>{t("reactAdmin.user.list.roles")}</div>
                        </Grid>
                        <Grid item xs={7} className={"content"}>
                            {t("reactAdmin.user.groups." + item.instanceof)}
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} className={"itemBody"}>
                        <Grid item xs={5} className={"label"}>
                            {/*<LocationOnIcon/>*/}
                            <CustomInlineSvg svgProps={{src:addressIcon}}/>
                            <div>{t("reactAdmin.user.list.address")}</div>
                        </Grid>
                        <Grid item xs={7} className={"content"}>
                            {itemPerson.addressPostalCode ? itemPerson.addressPostalCode + ", " : ""}
                            {itemPerson.addressDescription ? itemPerson.addressDescription + ", " : ""}
                            {itemPerson.addressCity ? itemPerson.addressCity + ", " : ""}
                            {itemPerson.addressCountry ? itemPerson.addressCountry : ""}
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={"itemBody"}>
                        <Grid item xs={5} className={"label"}>
                            <CustomInlineSvg svgProps={{src:phoneIcon}}/>
                            <div>{t("reactAdmin.user.list.phoneNumber")}</div>
                        </Grid>
                        <Grid item xs={7} className={"content"}>
                            {item.person ? item.person.phoneNumber : ""}
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} className={"itemBody"}>
                        <Grid item xs={5} className={"label"}>
                            <CustomInlineSvg svgProps={{src:mailIcon}}/>
                            <div>{t("reactAdmin.user.list.email")}</div>
                        </Grid>
                        <Grid item xs={7} className={"content"}>
                            {item.person ? item.email : ""}
                        </Grid>
                    </Grid>
                </Grid>
                <div className={"viewDetail"}>
                    <NavLink to={DETAIL_STAFF.replace(":id", item.id)}><div className={classes.viewDetail}>{t("reactAdmin.user.list.viewDetail")}</div></NavLink>
                </div>
            </div>
        )
    }

    handleChangeApiParam(event,value, field){
        let {apiParams} = this.state;
        let apiParamsNew = {...apiParams}
        if(!value){
            delete (apiParamsNew[field])
        }else {
            apiParamsNew = {...apiParams, [field]: value}
        }
        this.setState({
            apiParams: apiParamsNew
        }, function () {
            if (event.key === 'Enter' || event.charCode ===13) {
                // Do code here
                event.preventDefault();
                this.getData(true)
            }
        })
    }

    changeApiParamEnableFilter = (value) => {
        let {apiParams} = this.state;
        let apiParamsNew = {...apiParams}
        if(apiParamsNew.hasOwnProperty("filters") && apiParamsNew.filters.hasOwnProperty("enabled") && apiParams.filters.enabled === value){
            delete (apiParamsNew["filters"])
        }else {
            apiParamsNew = {
                ...apiParams, filters: {
                    enabled: value
                }
            }
        }
        this.setState({
            apiParams: apiParamsNew
        }, function () {
            this.getData(true)
        })
    }
    dataEnableFilter = () =>{
        const {t} = this.props;
        return [
            {
                name: t('reactAdmin.user.list.enabledStatus.on'),
                icon: <CheckCircleIcon />,
                // iconActive: CheckCircleIcon,
                number: this.state.userEnabledTrue,
                status: true,
            },
            {
                name: t('reactAdmin.user.list.enabledStatus.off'),
                icon: <CancelIcon />,
                // iconActive: CancelIcon,
                number: this.state.userEnabledFalse,
                status: false,
            },
        ]
    }
    render() {
        const {
            classes, t
        } = this.props;
        const {
            dataList,apiParams,
            totalCount,
            totalPages,
            currentPage,
            pageSize,
            isLoading
        } = this.state;
        return (
            <div className={classes.staffPage}>
                {isLoading && <LoadingAction /> }
                <div className={"headerStaffPage"}>
                    <RequestHeaderAdmin statusListFilter={this.dataEnableFilter()} titleHeader={t("reactAdmin.user.list.titleList")} statusCode={apiParams.filters && apiParams.filters.hasOwnProperty("enabled")? [apiParams.filters.enabled] : null } changeStatusClick={this.changeApiParamEnableFilter}/>
                    <NavLink to={CREATE_STAFF}><CustomButton
                        className={classes.btnAdd}
                        title={t("reactAdmin.user.list.btnAddStaff")}
                    /></NavLink>
                </div>

                <Grid container className={"contentInformationPage"}>
                    <Grid container item xs={12}  className={classes.headerWrapper}>
                        <Grid container item xs={12} md={4} className={"headerBlock"}>
                            <CustomInput className={"searchInput"}
                                         placeholder={t("reactAdmin.user.list.search")}
                                         onChange={(e)=> this.handleChangeApiParam(e, e.target.value, "search")}
                                         onKeyPress={(e)=>this.handleChangeApiParam(e, e.target.value, "search")}
                                         value={apiParams.search}
                                         InputProps={{
                                             endAdornment: (
                                                 <InputAdornment position="end">
                                                    <SearchIcon onClick={()=>this.getData(true)}/>
                                                 </InputAdornment>
                                             ),
                                         }}
                                         // validate={errors.price}
                            />

                        </Grid>
                    </Grid>
                    <Grid container item xs={12}  className={classes.bodyWrapper}>
                    { dataList && dataList.length ?
                        dataList.map((item, key)=>{
                            return <Grid container item xs={12} md={4} lg={3} className={classes.blockItemWrapper} key={key}>
                                {this.componentRender(this.props, this.state, item)}
                            </Grid>
                        }) :
                        <Grid item xs={12} className={classes.blockItemWrapper}>
                            <div className={"emptyData"}>
                                {t("text.noResult")}
                            </div>
                        </Grid>
                    }
                    </Grid>
                    <Grid container  item xs={12}>
                        <CustomPagination
                            onCurrentPageChange={this.onCurrentPageChange}
                        onPageSizeChange={this.onPageSizeChange}
                        // setPageSize={this.setPageSize}
                        totalCount={totalCount}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        pageSize={pageSize}
                            />
                    </Grid>


                </Grid>
            </div>
        )
    }

}

StaffList.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(
    withStyles(styles),
    withTranslation(),
    withRouter
)(StaffList);