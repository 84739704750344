import React from 'react';
import Popover from '@material-ui/core/Popover';
import {
    // makeStyles, 
    withStyles
} from '@material-ui/core/styles';
import PropTypes from "prop-types";
import {compose} from "redux";

const styles = (theme) => ({
    popover: {
        // pointerEvents: 'none',
    },
    // paper: {
    //     padding: theme.spacing(1),
    // },
});


class PopoverCustom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const {classes, anchorEl, onClose, content, popoverAriaOwns, className} = this.props;
        return (
                <Popover
                    style={{
                        width: 460,
                        // height: 300,
                        borderRadius: 10,
                        overflow: "visible"
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        className: `${classes.popover} ${className??""}`
                    }}
                    id={popoverAriaOwns}
                    open={true}
                    anchorEl={anchorEl}
                    onClose={()=>onClose()}
                >
                    <div>
                        {content}
                    </div>

                </Popover>
        );
    }
}

PopoverCustom.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(
    withStyles(styles),
)(PopoverCustom);
