import {
    CURRENCY,
    BREAK_TIME,
    CALL_TIME,
    HOLIDAY,
    DAY_OF_WEEK,
    WORK_TIME,
    BLICKO_CONTACT,
    PRICE_PAY_DEFAULT,
    EMAIL_ADDRESS_BCC, APP_ADVERTISE, GENERAL_DOCUMENT, SHARE_DOCUMENT_EXPIRE_NUMBER_DAY
} from "../../constants/constants";
export const  CATEGORY = "/categories";
export const  MAIL_TYPE = "/mail_types"
export const  REQUEST_MAIL_TYPE = "/request_mail_types";
export const  USER = "/users";
export const  LEASE_TYPE_URL =`/lease_types`;
export const  VIDEO_TYPE_URL =`/video_types`;
export const  REQUEST_DOCUMENT_TYPE_URL =`/request_document_types`;
export const  DOCUMENT_REQUEST_VALID_URL =`/document_request_valids`;
export const  IMPORT_INTERVENTION = '/import_intervention';
export const  CURREN =`/${CURRENCY}`;
export const  BREAK_TIME_URL =`/${BREAK_TIME}`;
export const  GENERAL_DOCUMENT_URL =`/${GENERAL_DOCUMENT}`;
export const  WORK_TIME_URL =`/${WORK_TIME}`;
export const  DAY_OF_WEEK_URL =`/${DAY_OF_WEEK}`;
export const  HOLIDAY_URL =`/${HOLIDAY}`;
export const  CALL_TIME_URL =`/${CALL_TIME}`;
export const  SHARE_DOCUMENT_EXPIRE_NUMBER_DAY_URL =`/${SHARE_DOCUMENT_EXPIRE_NUMBER_DAY}`;
export const  BLICKO_CONTACT_URL =`/${BLICKO_CONTACT}`;
export const  PRICE_PAY_DEFAULT_URL =`/${PRICE_PAY_DEFAULT}`;
export const  EMAIL_ADDRESS_BCC_URL =`/${EMAIL_ADDRESS_BCC}`;
export const  SUB_DOCUMENT_TYPE_URL =`/sub_document_types`;
export const  APP_ADVERTISE_URL =`/${APP_ADVERTISE}`;
export const  IMAGE_TYPE_URL =`/image_types`;
export const  MAIL_URL = 'mails';
export const  REQUEST_URL = 'request';
export const  SMS_TYPE_URL = "/sms_types"
export const  VOICE_TYPE_URL = "/voice_types"
export const  PARAM_TOKEN_URL = "/param_token"





