import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withStyles} from "@material-ui/core";
import {NavLink, withRouter} from "react-router-dom";
import Button from "@material-ui/core/Button";
import EditIcon from '@material-ui/icons/Edit';
import {
    Grid,
    Table,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import Paper from '@material-ui/core/Paper';
import axios from "axios";
import {API_DOCUMENT_REQUEST_VALID} from "../../../../constants/apiSuffix";
import LoadingAction from "../../../../theme/LoadingAction";
import {DOCUMENT_REQUEST_VALID_URL} from "../../linkRectAdmin";

const styles = {
    listDocumentWrapper: {
        paddingTop:16,
        '& :first-of-type':{
            boxShadow:'none'
        },
        '& .tableHeader': {
        },
        '& .NotificationListAdmin': {
            padding:'0px 30px 20px 35px',
            boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
            '& tr':{
                '& th':{
                    fontWeight:400,
                    color:'#0000ff',
                    fontSize:'0.875rem',
                    lineHeight:'1.5rem',
                    border:'none',
                    padding:0
                },
            },
            '& tbody':{
                '& tr:hover':{
                    backgroundColor:'#ccd0d8'
                },
                '& tr>td':{
                    color: '#0000ff',
                    borderTop: '4px solid #fff',
                    borderBottom: '4px solid #fff',
                    backgroundColor: 'rgba(204, 208, 216, 0.27)',
                    fontWeight:500
                }
            },
            '& td':{
                padding:0
            },
        },
    },

    iconRow: {
        color:'#0000ff',
        width:20,
        height:20,
    },
    notificationCreateButton: {
        padding: '0px 10px',
        borderRadius: 5,
        backgroundColor:'#0000ff',
        // fontSize: '10px',
        fontWeight:500,
        '&:hover':{
            backgroundColor: '#0404d0'
        }
    },
    addButton:{
        width: 20,
        height:20,
    },
    text:{
        // paddingLeft:'0.8em',
        lineHeight: '30px',
        letterSpacing:0.5,
        color: '#fff',
        fontSize: 10,
        fontWeight: 700
    },
    rightHeader:{
        display:'flex',
        '& a':{
            textDecoration: 'none'
        }
    },
    saveButton:{
        paddingLeft:5
    },
    buttonEdit:{
        padding: 5,
        width: 65,
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover':{
            backgroundColor: 'rgba(63, 81, 181, 0.04)'
        }
    },
    headerLeft: {
        // position: "absolute",
        '&.titleList': {
            color: '#54516a',
            fontFamily: "'Open Sans', sans-serif",
            fontSize: '1rem',
            // opacity:0.8,
            // fontWeight: 100,
            fontWeight: 700,
            textTransform: 'uppercase',
            marginLeft:7
        },
    },
    tableHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 5px 20px 5px',
        backgroundColor:'#fff',
        justifyContent: 'space-between',
        '& h6': {
            color: '#0000ff',
            textTransform: 'uppercase',
            fontSize: 23,
            fontWeight: 'bold',
            marginLeft: 15,
            lineHeight: "25px",
            paddingRight: "26px",
            flexGrow: 1,
        },
        '&.customHeaderColumn': {
            justifyContent: 'space-between'
        }
    },
    listBlock:{

        '& thead':{
            '& th':{
                color: '#0000ff',
                textTransform: 'uppercase',
                fontWeight: 400,
                borderBottom: 'none',
                fontFamily:"'Open Sans', sans-serif",
                "& span":{
                    color: '#000000',
                    fontWeight: 600
                }
            }
        },
        '& tbody': {
            '& td': {
                color: '#0000ff',
                borderTop: '4px solid #fff',
                borderBottom: '4px solid #fff',
                backgroundColor: 'rgba(204, 208, 216, 0.27)',
            }
        }
    }
}

const ListHeader = (props) => {
    const classes = props.classes;
    return <div className= {`${classes.tableHeader} tableHeader `}>
        {typeof props.leftHeaderComponent !== "undefined" && props.leftHeaderComponent!==null && props.leftHeaderComponent}
        {typeof props.rightHeaderComponent !== "undefined" && props.rightHeaderComponent!==null && props.rightHeaderComponent}
    </div>
};



const RightHeaderListContent = (props) => {
    const {t, classes} = props;
    return (
        <div className={classes.rightHeader}>
            <NavLink to={props.hasOwnProperty("pathCreate") && props.pathCreate ?props.pathCreate: props.location.pathname+"/create"}>
                <Button  className={`${classes.notificationCreateButton}`}>
                    {/*<AddOutlinedIcon className={classes.addButton}/>*/}
                    <div className={classes.text}>{t("reactAdmin.label.create")}</div>
                </Button>
            </NavLink>
        </div>
    )
};


const LeftHeaderListContent = (props) => {
    const {classes} = props;
    return (
        <div className={`${classes.headerLeft} titleList`} >
            {props.titleList?props.titleList:""}
        </div>
    )
}

class DocumentRequestValidList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            forceUpdate: false,
            isLoading: false,
            tableMessages: {
                noData: "text.noResult"
            },
            documentRequestType:{},
        };
    }

    componentDidMount() {
        this.setState({isLoading: true})
        axios.get(API_DOCUMENT_REQUEST_VALID)
            .then(response=>{
                if(response.status === 200){
                    let documentRequestType = response.data;
                    this.setState({
                        documentRequestType: documentRequestType,
                        isLoading: false
                    })
                } else {
                    throw new Error();
                }
            }).catch((err) => {

        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const newState = {};
        if (this.state.forceUpdate){
            newState.forceUpdate = false;
        }
        if (Object.keys(newState).length){
            this.setState(newState);
        }
    }

    render() {
        const {classes, t} = this.props;
        const {tableMessages, documentRequestType, isLoading} = this.state;
        const RightHeaderList = <RightHeaderListContent
            {...this.props}
        />;

        let columns = [];
        const arrayColumn = [
            {
                source: 'minSize',
                label: 'reactAdmin.document_request_valid.minSize'
            },
            {
                source: 'maxSize',
                label: 'reactAdmin.document_request_valid.maxSize'
            },
            {
                source: 'fileFormat',
                label: 'reactAdmin.document_request_valid.fileFormat'
            },
            {
                source: 'fileType',
                label: 'reactAdmin.document_request_valid.fileType'
            }
        ];
        arrayColumn.map(item => {
            columns.push(
                {
                    name: item.source,
                    title: t(item.label),
                    getCellValue: rowData => rowData.hasOwnProperty(item.source) ? (item.source === "fileFormat" ?
                        rowData[item.source] && Array.isArray(rowData[item.source]) ? rowData[item.source].map((item, key) => {
                            return ((key === 0) ? "" : " ") + item;
                        }) :""
                            : rowData[item.source]) + (["minSize","maxSize"].includes(item.source)?t("reactAdmin.document_request_valid.sizeFileUnit"):"")  : ""
                });

        });
        columns.push(
            {
                name: "action",
                title:" ",
                getCellValue: rowData => <NavLink to={DOCUMENT_REQUEST_VALID_URL+"/"+rowData.id} className={classes.buttonEdit}><EditIcon/></NavLink>
            });

        const arrayList = Object.keys(documentRequestType);
        return (
            <Paper>
                {isLoading && <LoadingAction/>}
                {arrayList.map((item, key) => {
                    return <div className={classes.listBlock} key={key}>
                        <ListHeader
                            t={this.props.t}
                            leftHeaderComponent={<LeftHeaderListContent
                                {...this.props}
                                titleList={item}
                            />}
                            // rightHeaderComponent={RightHeaderList}
                            classes={classes}
                        />
                        <div className={"NotificationListAdmin"}>
                            <Grid
                                rows={item === "size" ? [documentRequestType[item]] : documentRequestType[item]}
                                columns={item === "size" ? columns.filter(item=> ["minSize","maxSize", "action"].includes(item.name)): columns.filter(item=>  !(["minSize","maxSize"].includes(item.name)))}
                            >
                                <Table messages={tableMessages}/>
                                <TableHeaderRow/>
                            </Grid>
                        </div>
                    </div>
                })}


            </Paper>
        );
    }
}
DocumentRequestValidList.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

export default compose(
    // connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withRouter,
    withTranslation()
)(DocumentRequestValidList);
