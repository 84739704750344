import React, {Component, useCallback, useEffect, useRef, useState} from 'react';
import 'react-dropzone-uploader/dist/styles.css'
import {makeStyles} from "@material-ui/core/styles";
import LoadingAction from "../../../theme/LoadingAction";
import {compose} from "redux";
import {withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import axios from "axios";
import * as links from "../../../constants/links";
import {Redirect} from "react-router";
import {API_ADD_REPORT_FOR_REQUEST_WITH_TOKEN} from "../../../constants/apiSuffix";
import notify from "devextreme/ui/notify";

const styles = theme => ({
    addQuotationWrapper: {

    },
})


const AddQuotation = (props) => {
    const {
        classes,
        match,
        t
    } = props;
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(null)

    useEffect(() => {
        try {
            const token = match?.params?.token;
            // console.log(token)
            if (token) {
                setLoading(true);

                axios.post(API_ADD_REPORT_FOR_REQUEST_WITH_TOKEN.replace('{token}', token))
                    .then(res => {
                        setLoading(false);
                        if (res.status === 200) {
                            setRedirect(links.REPORT);
                        } else {
                            throw new Error();
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        setRedirect(links.REQUEST);
                        notify(t('errors.addQuotation'), "error", 1000)
                    })
            } else {
                setRedirect(links.REQUEST);
                notify(t('errors.addQuotation'), "error", 1000)
            }
        } catch (e) {
            setRedirect(links.REQUEST);
            notify(t('errors.addQuotation'), "error", 1000)
        }
    }, [])


    if (redirect) {
        return <Redirect to={redirect}/>
    }
    return (
        <div className={classes.addQuotationWrapper}>
            {loading && <LoadingAction/>}
        </div>
    );
}

export default compose( withStyles(styles),withTranslation(),withRouter)(AddQuotation);
