import React from "react";
import {
    IconButton,
    InputAdornment,
    TextField,
    withStyles,
} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {compose} from "redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import axios from "axios";
import LoadingAction from "../../../../theme/LoadingAction";
import {Redirect, withRouter} from "react-router-dom";
import {translate} from "react-admin"
import Autocomplete from "@material-ui/lab/Autocomplete";
import SaveIcon from "@material-ui/icons/Save";
import {
    FALSE_NUMBER,
    FORM_EDIT, REQUEST_MAIL_TYPE,
    ROLE_GROUP_ADMIN, STRING, TRUE_NUMBER, USER_GROUPS, USER_TYPE_ADMIN,
} from "../../../../constants/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {connect} from "react-redux";
import Checkbox from '@material-ui/core/Checkbox';
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import {
    API_CREATE_USER,
    API_EDIT_USER,
    API_SHOW_USER
} from "../../../../constants/apiSuffix";
import CustomInput from "../../../../theme/CustomElements/CustomInput";
import {PROFILE} from "../../../../constants/links";
import RowFormColumn from "../../../../theme/form/RowFormColumn";
import { errorsMessPerson} from "../../../../functions/checkErrorEmpty";
import {checkSubmitSendDataERROR} from "../../../../functions/checkCondition";
// import * as links from "../../../../constants/links";
import AlertCustom from "../../../../theme/form/Alert/AlertCustom";
import arrowIcon from "../../../../images/icons/arrow.svg";
// import {MAIL_TYPE, REQUEST_MAIL_TYPE as REQUEST_MAIL_TYPE_LINK} from "../../linkRectAdmin";
import {SET_TIME_OUT_ALERT_SUCCESS} from "../../ReactAdmin";

const styles = {
    parameterWrapper: {
        '&.profilePage':{
            borderRadius: '13px 13px 0 0'
        },
        padding: '3rem',
        background: '#fff',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        // marginLeft: '1rem',
        borderRadius: 13
    },
    formControl: {
        background: '#eee',
        borderRadius: 4,
        marginBottom: 10,
        '& > div': {
            // height: 40,
            // paddingLeft: 10,
            // paddingRight: 10,
            width: '100% !important',
            // '&::before, &::after': {
            //     display: 'none'
            // }
        },
        '& input': {
            padding: 15
        },
    },
    label: {
        fontWeight: 700,
        fontSize: '1rem',
        marginBottom: 10
    },
    alertError: {
        color: '#f44336',
        fontSize: 12,
        background: '#fff'
    },
    submit: {
        height: 80,
        background: "radial-gradient(128.12% 128.12% at 50.06% -28.12%, #20C89F 0%, #00A739 100%)",
        borderBottomRightRadius: 15,
        borderBottomLeftRadius: 15,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    textSubmit: {
        color: 'white',
        paddingLeft: 8,
    },
}
const headTranslate = 'reactAdmin.user.form.';


class UserForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            successOpen: false,
            errorOpen: false,
            errors: {},
            langLocal: "",
            redirectList: false,
            formValue: {
                email: '',
                lastName: '',
                firstName: '',
                group: "",
                rolesUser: [],
                enabled: false,
                password: "",
                passwordConfirm: "",
                changePasswordForUser: true,
                phoneNumber: ""
            },
            groups: [],
            roles: [],
            isLoading: true,
            showPassword: {
                password: false,
                passwordConfirm: false
            },
            isProfilePage: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCloseNotice = this.handleCloseNotice.bind(this)
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    }

    handleCloseNotice() {
        this.setState({
            successOpen: false,
            errorOpen: false
        })
    }

    componentDidMount() {
        const {formType, match, location, dataUser} = this.props;
        let isProfilePage = false;
        if (location.pathname === PROFILE) {
            isProfilePage = true;
        }
        if ((formType && formType === FORM_EDIT && match && match.params.id) || isProfilePage) {
            const userId = !isProfilePage ? match.params.id : dataUser.data.id
            axios.get(API_SHOW_USER.replace("{id}", userId))
                .then(response => {
                    let userDetail = {};
                    if (response.status === 200 && response.data && response.data.item) {
                        const dataUser = response.data.item;
                        let rolesUser = [];
                        const dataRoles = dataUser.roles ?
                            (Array.isArray(dataUser.roles) ?
                                dataUser.roles :
                                (typeof dataUser.roles === "object" ? Object.values(dataUser.roles): [])) :
                            []
                        if(dataRoles.length){
                            dataRoles.map(item=>{
                                if(item.includes(ROLE_GROUP_ADMIN)) {
                                    rolesUser.push(item)
                                }
                            })
                        }
                        userDetail = {
                            email: dataUser.email ?? "",
                            lastName: dataUser.person && dataUser.person.lastName ? dataUser.person.lastName : "",
                            firstName: dataUser.person && dataUser.person.firstName ? dataUser.person.firstName : "",
                            group: dataUser.instanceof ?? "",
                            phoneNumber: dataUser.person && dataUser.person.phoneNumber ? dataUser.person.phoneNumber : "",
                            rolesUser: rolesUser,
                            enabled: dataUser.enabled,
                            changePasswordForUser: false
                        };
                    }
                    this.setState({
                        formValue: userDetail,
                        isLoading: false,
                        isProfilePage: isProfilePage
                    })
                })
        } else {
            this.setState({
                isLoading: false,
            })
        }
    }

    handleChangeSelect(event, newValue, field) {
        let {errors, formValue} = this.state;
        if (errors.hasOwnProperty(field) && newValue) {
            delete (errors[field]);
        }
        formValue[field] = newValue;
        this.setState({
            formValue: formValue,
            errors: errors,
        })
    }


    handleChange(event, field) {
        let {errors, formValue} = this.state;
        let valueField = null;
        switch (field) {
            case "emailSignature":
                valueField = event;
                break;
            case "changePasswordForUser":
            case "enabled":
                valueField = event.target.checked;
                break;
            case 'lastName':
                valueField = event.target.value.toUpperCase();
                break;
            case 'firstName':
                valueField = event.target.value;
                break;
            case 'passwordConfirm':
                valueField = event.target.value;
                if (valueField === formValue.password && errors.hasOwnProperty(field) && valueField) {
                    delete (errors[field]);
                }
                break;
            default:
                valueField = event.target.value;
                break;
        }

        if (field !== "passwordConfirm" && errors.hasOwnProperty(field) && valueField) {
            delete (errors[field]);
        }

        formValue[field] = valueField;
        this.setState({
            formValue: formValue,
            errors: errors
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        let {formValue, errors, isProfilePage} = this.state;
        const {t, dataUser} = this.props;
        const isFormEdit = this.props.formType && this.props.formType === FORM_EDIT;

        for (let [key, value] of Object.entries(formValue)) {
            if (errorsMessPerson.hasOwnProperty(key)) {
                errors = checkSubmitSendDataERROR(value, errorsMessPerson[key].type, errors, key, errorsMessPerson[key].errorByField, t)
            }
        }

        if (formValue.password !== formValue.passwordConfirm) {
            errors.passwordConfirm = t(headTranslate+"errors.confirmPasswordMatch");
        }
        // if(!formValue.email){
        //     errors.email = t("additional_information.text.validate.requiredFieldName", {field: translate('resources.user.fields.email')});
        // }
        // if(!formValue.group || JSON.stringify(formValue.group)==="{}"){
        //     errors.group = t("additional_information.text.validate.requiredFieldName", {field:translate('resources.user.fields.group.name')});
        // }
        // if(formValue.changePasswordForUser && !formValue.password){
        //     errors.password = t("additional_information.text.validate.requiredFieldName", {field:translate('resources.user.fields.password')});
        // }
        //
        // if(formValue.changePasswordForUser && !formValue.passwordConfirm && formValue.passwordConfirm !== formValue.password){
        //     errors.passwordConfirm = t("additional_information.text.validate.requiredFieldName", {field:translate('resources.user.fields.passwordConfirmNotMatch')});
        // }
        if (Object.keys(errors) < 1) {
            this.setState({
                isLoading: true
            })
            let routerApiName = API_CREATE_USER;
            if (isFormEdit || isProfilePage) {
                const userId = dataUser && dataUser.data ? dataUser.data.id : this.props.match.params.id;
                routerApiName = API_EDIT_USER.replace("{id}", userId);

            }
            const formData = new FormData();
            if(!isProfilePage) {
                if(!isFormEdit) {
                    formData.append('person[email]', formValue.email);
                    formData.append('person[user][userType]', formValue.group);
                }
                formData.append("person[user][enabled]", formValue.enabled ? TRUE_NUMBER : FALSE_NUMBER);
                if (Array.isArray(formValue.rolesUser) && formValue.rolesUser.length) {
                    formValue.rolesUser.map((item, key) => {
                        formData.append("person[user][rolesAdmin][" + key + "]", item);
                    })
                }
            }
            formData.append('person[firstName]', formValue.firstName);
            formData.append('person[lastName]', formValue.lastName);
            formData.append('person[phoneNumber]', formValue.phoneNumber);
            if (formValue.changePasswordForUser && formValue.password) {
                formData.append('person[user][password][first]', formValue.password);
                formData.append('person[user][password][second]', formValue.passwordConfirm);
            }

            axios.post(routerApiName, formData)
                .then(response => {
                    if (response.status === 200) {
                        const data = response.data.item;
                        formValue.changePasswordForUser = false;
                        this.setState({
                            // formValue:{
                            //     email:'', lastName:'', firstName:'', group: {}, rolesUser: []
                            // },
                            // formValue:formValue,
                            // isLoading: false,
                            successOpen: true,
                            // redirectList: data.id
                        })
                        setTimeout(function() {
                            this.setState({
                                redirectList: data.id
                            })
                        }.bind(this), SET_TIME_OUT_ALERT_SUCCESS);
                    } else {
                        throw new Error();
                    }
                })
                .catch(error => {
                    // if(error && ((error.errors && error.errors.children[plainPassword]) ||( error.response && error.response.status === 400 && error.response.response_code && error.response.response_code === 'CONFIRM_NEW_PASSWORD_INCORRECT'))){
                    //     errors.password = this.props.t('profile.changePassword.validate.newPassword.confirm');
                    //     errors.passwordConfirm = this.props.t('profile.changePassword.validate.confirmNewPassword.confirm');
                    //     this.setState({
                    //         isLoading: false,
                    //         errors: errors,
                    //         errorOpen: true
                    //     })
                    // }else {
                    this.setState({
                        isLoading: false,
                        errorOpen: true
                    })
                    // }
                });
        } else {
            this.setState({
                isLoading: false,
                errors: errors,
                errorOpen: true
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.redirectList) {
            this.setState({redirectList: false})
        }
    }

    handleClickShowPassword(field) {
        this.setState({
            showPassword: {
                ...this.state.showPassword,
                [field]: !this.state.showPassword[field]
            }
        })
    }

    render() {
        const {classes, t, formType} = this.props;
        const {
            formValue,
            redirectList,
            isLoading,
            errors,
            isProfilePage,
            showPassword
        } = this.state;
        if (redirectList && !isProfilePage) {
            return <Redirect to={"/users"}/>
        }

        return (
            <div className={"formUserWrapper"}>
                {isLoading && <LoadingAction/>}
                <AlertCustom
                    successOpen={this.state.successOpen}
                    errorOpen={this.state.errorOpen}
                    handleCloseNotice={()=>this.handleCloseNotice()}
                />
                <Grid container>
                    <Grid xs={12} item className={`${classes.parameterWrapper} ${isProfilePage? "profilePage":""}`}>
                        {!isProfilePage && <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={formValue.enabled}
                                                 onChange={(e) => this.handleChange(e, "enabled")}/>}
                                label={t(headTranslate + 'enabled')}
                            />
                        </FormGroup>}
                        {!formType && formType !== FORM_EDIT && !isProfilePage && <RowFormColumn
                            label={t(headTranslate + 'group')}
                            input={
                                <Autocomplete
                                    value={formValue.group}
                                    onChange={(event, newValue) => this.handleChangeSelect(event, newValue, "group")}
                                    // inputValue={formValue.group}
                                    getOptionLabel={(option) => option ? this.props.t("reactAdmin.user.groups." + option) : ""}
                                    id="select-group"
                                    options={USER_GROUPS}
                                    style={{width: 300}}
                                    renderInput={(params) => <TextField {...params} label={t(headTranslate + 'group')}
                                                                        variant="outlined"/>}
                                />
                            }
                            textValidate={errors.group}
                        />}
                        {formValue.group === USER_TYPE_ADMIN && Array.isArray(ROLE_GROUP_ADMIN) && ROLE_GROUP_ADMIN.length > 0 && !isProfilePage &&
                        <RowFormColumn
                            label={t(headTranslate + 'roles')}
                            input={
                                <Autocomplete
                                    multiple
                                    value={formValue.rolesUser}
                                    onChange={(event, newValue) => this.handleChangeSelect(event, newValue, "rolesUser")}
                                    // inputValue={formValue.rolesUser}
                                    getOptionLabel={(option) => this.props.t("reactAdmin.user.permission." + option)}
                                    id="select-roles"
                                    options={ROLE_GROUP_ADMIN}
                                    style={{width: 300}}
                                    renderInput={(params) => <TextField {...params} label={t(headTranslate + 'roles')}
                                                                        variant="outlined"/>}
                                />
                            }
                        />}
                        <RowFormColumn
                            label={t(headTranslate + 'email')}
                            input={
                                <CustomInput
                                    value={formValue.email}
                                    onChange={(e) => this.handleChange(e, "email")}
                                    validate={errors.email}
                                    disabled={(formType && formType === FORM_EDIT) || (isProfilePage)}
                                />

                            }
                            textValidate={errors.email}
                        />
                        <RowFormColumn
                            label={t(headTranslate + 'lastName')}
                            input={
                                <CustomInput
                                    value={formValue.lastName}
                                    onChange={(e) => this.handleChange(e, "lastName")}
                                    validate={errors.lastName}/>
                            }
                            textValidate={errors.lastName}
                        />
                        <RowFormColumn
                            label={t(headTranslate + 'firstName')}
                            input={
                                <CustomInput
                                    value={formValue.firstName}
                                    onChange={(e) => this.handleChange(e, "firstName")}
                                    validate={errors.firstName}
                                />
                            }
                            textValidate={errors.firstName}
                        />
                        <RowFormColumn
                            label={t(headTranslate + 'phoneNumber')}
                            input={
                                <CustomInput
                                    value={formValue.phoneNumber}
                                    type={"number"}
                                    onChange={(e) => this.handleChange(e, "phoneNumber")}
                                    validate={errors.phoneNumber}
                                />
                            }
                            textValidate={errors.phoneNumber}
                        />
                        <div>
                            {((formType && formType === FORM_EDIT) || (isProfilePage)) &&
                            <FormControlLabel
                                control={<Checkbox checked={formValue.changePasswordForUser}
                                                   onChange={(e) => this.handleChange(e, "changePasswordForUser")}
                                                   name="changePasswordForUser"/>}
                                label={t(headTranslate + 'changePassword')}
                            />
                            }

                        </div>
                        {
                            formValue.changePasswordForUser &&
                            <RowFormColumn
                                label={t(headTranslate + 'password')}
                                input={
                                    <CustomInput
                                        value={formValue.password}
                                        type={showPassword.password ? "text" : "password"}
                                        onChange={(e) => this.handleChange(e, "password")}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => this.handleClickShowPassword("password")}
                                                        onMouseDown={() => this.handleClickShowPassword("password")}
                                                    >
                                                        {this.state.showPassword.password ? <VisibilityIcon/> :
                                                            <VisibilityOffIcon/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                }
                                textValidate={errors.password}
                            />
                        }
                        {formValue.changePasswordForUser &&
                        <RowFormColumn
                            label={t(headTranslate + 'passwordConfirm')}
                            input={
                                <CustomInput
                                    value={formValue.passwordConfirm}
                                    type={showPassword.passwordConfirm ? "text" : "password"}
                                    onChange={(e) => this.handleChange(e, "passwordConfirm")}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => this.handleClickShowPassword("passwordConfirm")}
                                                    onMouseDown={() => this.handleClickShowPassword("passwordConfirm")}
                                                >
                                                    {this.state.showPassword.passwordConfirm ? <VisibilityIcon/> :
                                                        <VisibilityOffIcon/>}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            }
                            textValidate={errors.passwordConfirm}
                        />
                        }

                        {!isProfilePage && <Button type={"submit"}
                                                  variant={"contained"}
                                                  className={classes.btnSave}
                                                  color={"primary"}
                                                  startIcon={isLoading ? <CircularProgress/> :
                                                      <SaveIcon className={classes.saveIcon}/>}
                                                  onClick={this.handleSubmit}
                            >
                                {this.props.t('button.save')}
                            </Button>
                        }
                    </Grid>
                    <Grid item xs={12} >
                        {isProfilePage &&
                        <div className={classes.submit}>
                            <Button
                                onClick={(e) => this.handleSubmit(e)}>
                                <img alt={"information icon"} src={arrowIcon}/>
                                <h1 className={classes.textSubmit}
                                >{t("registration.submitYourRequest")}</h1>
                            </Button>
                        </div>}
                    </Grid>
                </Grid>

            </div>
        )
    }
}

UserForm.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        dataUser: state.authReducer && state.authReducer.dataUser? state.authReducer.dataUser : null,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter
)(translate(UserForm));
