import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
// import InformationPage from "./InformationPage";
import InformationPageCustom from "../InformationPageCustom";
import RequestNew, {INFO_STEP} from "../request/RequestNew";
import {NavLink} from "react-router-dom";
import * as links from "../../../constants/links";
import CustomSvg from "../../../theme/CustomElements/CustomSvg";
import returnIcon from "../../../images/navbar/Groupe 8620.svg";
import AlertCustom from "../../../theme/form/Alert/AlertCustom";
import {NUNITO} from "../../../constants/fontCss";
import TabRequestDate from "../requestDetail/TabRequestDate";
// import logoClient from "../../../images/navbar/logo2.png";
import {LG, MD, SM, XS} from "../../../constants/constants";
import banner from "../../../images/banner/banner.png";
import {connect} from "react-redux";

const styles = theme => ({
    container: {
        padding: 50,
    },
    navbar: {
        height: 80,
        display: 'flex',
        alignItems: 'center',
        // padding:'0 80px',
        padding: '0 4rem',
        background: '#fff',
        justifyContent: 'space-between',
        [theme.breakpoints.down(XS)]: {
            height: 60
        },
        [theme.breakpoints.between(MD, LG)]: {
            padding: '0 4rem'
        },
        [theme.breakpoints.between(XS, MD)]: {
            padding: '0 2rem'
        },
        '& a': {
            '& img': {
                [theme.breakpoints.down(XS)]: {
                    width: 80
                }
            }
        }
    },
    header: {
        background: `url(${banner})`,
        backgroundSize: 'cover',
        height: 154,
        position: 'relative',
        '&::before':{
            content:'""',
            height: '100%',
            position: 'absolute',
            background: '#2020ac',
            opacity:'0.5',
            boxShadow: 'inset 5px -38px 44px -18px rgba(0,0,0,0.54)',
            width: '100%',
        },
        '& .headerWrapper': {
            position: 'relative',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        [theme.breakpoints.down(MD)]:{
            height: 80
        }
    },
    returnRequestList: {
        borderRadius: 24,
        border: 'solid 1px #ffffff',
        color: '#fff',
        textDecoration: 'none',
        padding: '0.5rem 1rem',
        marginLeft: '3rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        maxWidth: 150,
        [theme.breakpoints.between(XS, SM)]: {
            marginLeft: '1rem',
        },
        '&:hover': {
            //background:'linear-gradient(270deg, rgba(14, 56, 96, 0) 8.24%, #0E2F60 34.94%, #0A0730 97.58%)',
            background: '#ffffff70',
            //color: '#0c1c49',
            color: '#fff',
            backgroundPosition: '100% 0',
            transition: 'all .4s ease-in-out',
        },
        '& > span': {
            fontSize: '1.1125rem',
            paddingLeft: '0.5rem',
            lineHeight: 0.9,
            fontFamily: NUNITO
        }
    },
    formBlock: {
        background: '#fff',
        borderRadius: 10,
        padding: '0.5rem',
        '& .returnRequest':{
            width: 'fit-content'
        },
        '& .returnHistory': {
            borderRadius: 24,
            border: 'solid 1px #001529',
            textDecoration: 'none',
            padding: '0.5rem 1rem',
            display: 'flex',
            alignItems: 'center',
            color: '#001529',
            margin: '0.5rem',
            '& svg':{
                '& path':{
                    stroke: '#001529',
                }
            },
            '&:hover':{
                background:'#ebebeb',
                backgroundPosition: '100% 0',
                transition: 'all .4s ease-in-out',
            },
            '& > span':{
                fontSize: '1.1125rem',
                paddingLeft: '0.5rem',
                lineHeight: 0.9,
                fontFamily: NUNITO
            }
        },
    }
});

class InformationAdditional extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stepActiveDefault: INFO_STEP,
            successOpen: false
        };
    }

    render() {
        const {stepActiveDefault} = this.state
        const {match, t,dataUser, classes} = this.props
        // let {paid} = this.state
        const requestToken = match && match.params && match.params.tokenEnabled ? match.params.tokenEnabled : null;
        // console.log(requestToken)
        return (
            <div>
                {/*<div className={classes.navbar}>*/}
                {/*    <NavLink to={links.HOME}><img src={logoClient} alt={'logo'}/></NavLink>*/}
                {/*</div>*/}
                <div className={classes.header}>
                    {!!(dataUser?.data) && <div className="headerWrapper">
                        <NavLink to={links.REQUEST} className={`${classes.returnRequestList} returnRequestList`}>
                            <img alt={t("information.header.returnHistory")} src={returnIcon} />
                            <span>{t("information.header.returnHistory")}</span>
                        </NavLink>
                    </div>}
                </div>
                <div className={classes.container}>
                    <TabRequestDate
                        requestToken={requestToken}
                    />
                </div>
            </div>
        )
        return (
            <InformationPageCustom
                content={
                    <div className={classes.formBlock}>
                        <div className={"returnRequest"}>
                            {!!(dataUser?.data) && <NavLink to={links.REQUEST} className={"returnHistory"}>
                                <CustomSvg
                                    svgProps={{
                                        src: returnIcon,
                                    }}
                                    lable={t("information.header.returnHistory")}
                                />
                                {/*<img alt={t("information.header.returnHistory")} src={returnIcon}/>*/}
                                <span>{t("information.header.returnHistory")}</span>
                            </NavLink>}

                        </div>
                        <RequestNew onClose={() => this.onCloseModal()} hiddenButtonReturn={true}
                                    loadRequest={(newItem) => {
                                        this.setState({
                                            successOpen: true
                                        })
                                        this.getDataRequest()
                                    }} stepActiveDefault={stepActiveDefault}
                                    removeStepActionDefault={() => this.setState({stepActiveDefault: ""})}
                                    editRequestById={((match?.params?.tokenEnabled) ?? null)}
                        />
                        <AlertCustom
                            successOpen={this.state.successOpen}
                            handleCloseNotice={() => {
                                this.setState({
                                    successOpen: true
                                })
                            }}
                            messageError={this.state.messageError}
                        />
                    </div>
                }
            />
        )
    }
}

InformationAdditional.defaultProps = {}

InformationAdditional.propTypes = {
    classes: PropTypes.object.isRequired
}


const mapStateToProps = (state) => {
    return {
        dataUser: state.authReducer.dataUser,
    }
}

export default  compose(
    connect(mapStateToProps, null),
    withStyles(styles),
    withTranslation(),
    withRouter
)(InformationAdditional);