import * as links from "./links";
import React from "react";
import Login from "../components/auth/Login";
// import InformationAdditional from "../components/client/information/InformationAdditional";

const arrayRoutesPublic = [
    {
        path: links.LOGIN,
        component: (match) => <Login match={match} />,
        exact: true,
        permission: ["superadmin", "admin", "user", "ROLE_USER"]
    },
    {
        path: links.CHANGE_PASSWORD,
        component: (match) => <Login match={match} />,
        exact: true,
        permission: ["superadmin", "admin", "user", "ROLE_USER"]
    }
];


export default arrayRoutesPublic;
