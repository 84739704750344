import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import testIcon from "../../../images/request/detail/test.svg"
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {OPENSANS,
    // SEGOEUI
} from "../../../constants/fontCss";
import CustomCalendar from "../../../theme/Calendar/CustomCalendar";
import {API_GET_REQUEST_BY_TOKEN, API_GET_SHOW_REQUEST} from "../../../constants/apiSuffix";
import {withRouter} from "react-router";
import axios from "axios";
import LoadingAction from "../../../theme/LoadingAction";
import {
    // LG,
    MD, SM, USER_TYPE_CLIENT, XL, XS
} from "../../../constants/constants";
import SuccessAlert from "../../../theme/form/Alert/SuccessAlert";
import {convertTimezoneHour} from "../../../functions/functions";
import moment from "moment";
import * as applicationActions from "../../../_actions/application";
import * as applicationAction from "../../../_actions/application";
import {connect} from "react-redux";
import {withWidth} from "@material-ui/core";

const styles = theme => ({
    tabRequestDateBlock:{
        background: '#fefeff',
        borderRadius: 13,
        boxShadow: '0 3px 6px 0 rgba(62, 81, 99, 0.16)',
        padding: '4rem',
        marginBottom: '40px',
        [theme.breakpoints.down(XS)]:{
            padding: '20px',
            marginTop: '20px',

        },
        [theme.breakpoints.down(SM)]:{

        }
    },
    titleTab:{
        display: 'flex',
        // fontFamily: SEGOEUI,
        fontWeight: 100,
        fontSize: '1.5rem',
        color: '#707070',
        alignItems: 'center',
        "& .titleDetail":{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '2.3rem',
            fontWeight: '600',
            color: '#605e72',

            '& span':{
                lineHeight: 1.15
            },

            [theme.breakpoints.down(XS)]:{
                fontSize: '20px',
                marginBottom: '20px',
            }
        },
        [theme.breakpoints.down(XS)]:{
            padding: '1rem',
            fontSize: '1rem'
        }
    },
    calendarTabBlock:{
        padding: '4rem',
        paddingBottom: 0,
        '& .circularProgressCustom':{
            color: '#2376cc'
        },
        '& .wrapperBlock':{
            flexDirection: 'row',
            // height: 310,
            flexWrap: 'wrap',
            '& .errorNoDate':{
                // marginLeft: '4rem'
                width: '100%'
            },
            '& .calendarBlock':{
                width: '55%',
                justifyContent: 'center',
                paddingRight: '4rem',
                borderRight: '1px dotted #707070',
                '& .dx-calendar':{
                    width: '65%',
                    maxWidth: 450,
                    '& .dx-button-text':{
                        color: '#707070!important',
                        fontWeight: 600,
                        fontFamily: OPENSANS,
                        fontSize: '1rem!important'
                    },
                    '& table':{
                        '& th':{
                            color: '#707070!important',
                            fontWeight: '600!important',
                            fontFamily: OPENSANS,
                            fontSize: '1rem!important'
                        }
                    },
                    '& .dx-calendar-empty-cell>span':{
                        color: '#b0b0b0!important'
                    },
                    '& .dx-calendar-cell>span':{
                        color: '#474747',
                        fontWeight: 600,
                        fontSize: '1rem'
                    },
                    '& .dx-calendar-selected-date>span':{
                        backgroundImage: 'linear-gradient(215deg, #cbe8ff 100%, #b7f2f2 -2%)',
                        color: '#474747',
                        fontWeight: 'bold',
                        height: '38px',
                        width: '38px',
                        padding: '4px',
                    }
                },
                '& .timeWrapper':{
                    fontFamily: OPENSANS,
                    width: '35%',
                    maxWidth: 222,
                    paddingRight: 22,
                    '& .timeElement':{
                        border: '1px dotted #707070',
                        fontSize: '1rem',
                        color: '#707070'
                    },
                    '& .timeSelected':{
                        backgroundImage: 'linear-gradient(251deg, #afdbfd, #b7f2f2)',
                        border: 'none',
                        fontWeight: 'bold',
                        color: '#474747'
                    },
                    '& .dateSelected':{
                        fontWeight: 600,
                        color: '#707070',
                        fontSize: '1rem',
                        textTransform: 'capitalize'
                    },
                    '& .listTime':{
                        width: '100%',
                        minWidth: '160px',
                    },
                    '& .listTimeDisable':{
                        height: 'auto!important'
                    }
                },



            },
            '& .customDateTimeSelected':{
                width: '45%',
                paddingLeft: '4rem',
                '& .dateTimeSelected':{
                    backgroundImage: 'linear-gradient(264deg, #afdbfd 126%, #b7f2f2 -45%)',
                    borderRadius: 9,
                    height: 62,
                    width: 489,
                    maxWidth: '90%',
                    justifyContent: 'space-between',
                    textTransform: 'capitalize',
                    paddingLeft: '1.75rem',
                    fontSize: '1.125rem',
                    color: '#474747',
                    fontWeight: 600,

                    '& .timeRangeCustom':{
                        fontWeight: 300,
                        marginLeft: '1rem'
                    },
                    '& .closeButton':{
                        color: '#fff',
                        cursor: 'pointer',
                        marginTop: -22,
                        marginRight: 3,
                        fontWeight: 100,
                        '&:hover':{
                            color: '#474747'
                        },

                        [theme.breakpoints.down(SM)]:{

                                position: 'relative',
                                top: '-3px',
                                right: '5px',

                        }
                    },

                    [theme.breakpoints.down(MD)]:{
                    margin: '1.2rem auto 1rem auto',

                     },

                }
            }

        },
        '& .buttonSubmitWrapper':{
            textAlign: 'center',
            '& button':{
                width: 415,
                height: 70,
                borderRadius: 65,
                color: '#fff',
                backgroundImage: 'linear-gradient(279deg,#00c1d1, #0070c7, #00c1d1)',
                backgroundSize: '200%!important',
                transition: 'all .4s ease-in-out',
                marginTop: '1rem',
                fontSize: '1.3rem',
                maxWidth: '95%',
                margin: '0 20px',
                lineHeight: '1.2rem',
                textTransform: 'inherit',
                textAlign: 'left',

                '&:before':{
                    background: 'none!important'
                },
                '&:hover':{
                    backgroundPosition: '100% 0!important',
                    transition: 'all .4s ease-in-out',
                },
                '& .MuiButton-startIcon':{
                    marginRight:'20px',
                },
            },
            '& .disabledButton':{
                //background: '#707070!important',
                cursor: 'default'
            }
        },
        [theme.breakpoints.down(MD)]:{
            '& .wrapperBlock':{
                flexDirection: 'column',
                height: 'auto',
                '& .calendarBlock':{
                    width: '100%!important',
                    borderRight: 'none!important',
                    paddingRight: '0px !important',
                },
                '& .timeWrapper':{
                    width: '100%!important',
                    '& .dateSelected':{
                        textAlign: 'center'
                    }
                },
                '& .customDateTimeSelected':{
                    paddingLeft: '0!important',
                    width: '100%!important'
                }
            }
        },
        [theme.breakpoints.down(SM)]:{
            padding: '4rem 1rem',
            '& .wrapperBlock':{
                '& .calendarBlock':{
                    paddingRight: '0!important'
                },
                '& .timeWrapper':{

                },
                '& .customDateTimeSelected':{

                }
            }
        },
        [theme.breakpoints.down(SM)]:{
            padding: '4rem 0 0 0',
            '& .wrapperBlock':{
                flexDirection: 'row',
                maxHeight: 'unset',
                '& .calendarBlock':{
                    justifyContent: 'center',
                    flexWrap: 'wrap',

                    '& .dx-calendar':{
                        width: '95%!important',
                        margin: 'auto!important',
                        marginBottom: 8
                    }
                },
                '& .timeWrapper':{
                    margin:'10px auto!important'
                },
                '& .customDateTimeSelected':{

                },
                '& .dateTimeSelected':{

                    '& span':{
                        paddingRight: '0!important'
                    }
                },
                '& .errorNoDate':{
                    textAlign: 'center'
                },
            },
            '& .buttonSubmitWrapper':{
                '& button': {
                    height: '75px!important',
                    margin: '1rem 0 0 0 !important',
                    maxWidth: '103% !important',

                    '& .MuiButton-startIcon':{
                        marginRight: '10px !important',
                      },

                }
            }
        },
        [theme.breakpoints.up(XL)]:{
            padding: '4rem 4rem 0rem 4rem',
            '& .wrapperBlock':{
                '& .calendarBlock':{

                    '& .dx-calendar':{
                        maxWidth: '500px!important'
                    },
                    '& .timeWrapper':{
                        maxWidth: '240px!important'
                    },
                },
                '& .customDateTimeSelected':{

                }
            }
        },

    },
})

class TabRequestDate extends Component {
    constructor(props) {
        super(props);
        this.state={
            dataChangeDate: {},
            isLoading: false,
            successOpen: false,
            message: "",
            paid: false
        }

    }
    componentDidMount() {
        const {match, requestToken} = this.props
        let {paid} = this.state
        const requestId = match && match.params && match.params.id ? match.params.id : null;
        const checkIdExist = !(requestId === null || requestId === "undefined") || requestToken;
        let url = requestToken ? API_GET_REQUEST_BY_TOKEN.replace("{id}", requestToken) : API_GET_SHOW_REQUEST.replace(":id", requestId)
        if(url && checkIdExist){
            axios.get(url)
                .then(response => {
                    if(response.status === 200){
                        let data = response.data.item
                        if(data){
                            let {payment} = data
                            if(payment.paid) paid = true
                            this.setState({
                                paid: paid,
                                dataChangeDate: {id:data.id, dateSelected: data.registrationStart, startTime: convertTimezoneHour(data.registrationTimeStart, data.registrationStart), endTime: convertTimezoneHour(data.registrationTimeEnd, data.registrationStart)}
                            })
                        }
                    } else{
                        throw new Error();
                    }
                })
                .catch(err=>{
                    console.log(err)
                    }
                )
        }
    }
    callBackFromCalendar = (isLoading, successOpen ) => {
        this.setState({
            isLoading: isLoading,
            successOpen: successOpen
        })
    }
    handleCloseNotice = () => {
        this.setState({
            successOpen: false
        })
    }

    render() {
        const {t, classes, dataUser, requestToken} = this.props
        const {dataChangeDate, successOpen, paid} = this.state;
        const groupUser = dataUser && dataUser.data ? dataUser.data.group : "";

        return (
            <React.Fragment>
                {this.state.isLoading && <LoadingAction/>}
                <SuccessAlert
                    snackbarProps={{
                        open: successOpen,
                        onClose: this.handleCloseNotice,
                    }}
                />
                <Grid xs={12} container className={classes.tabRequestDateBlock}>
                    <Grid xs={12} item className={classes.titleTab}>
                        <img src={testIcon} alt="test icon"/>
                        <div className={'titleDetail'}>
                            <span>{t('requestDate.validateText')}</span>
                            <span>{t('requestDate.letUs')}</span>
                        </div>
                    </Grid>
                    <Grid xs={12} item className={classes.calendarTabBlock}>
                        {dataChangeDate.hasOwnProperty('id') &&
                            <CustomCalendar
                                admin={groupUser !== USER_TYPE_CLIENT}
                                forDetail={true}
                                dataDateTime = {dataChangeDate}
                                callBackFromCalendar={this.callBackFromCalendar}
                                paid = {paid}
                                requestToken={requestToken}
                                changeDateCalenderSelected={(registrationStart, registrationTimeStart, registrationTimeEnd) => {
                                    if(moment(registrationStart).valueOf() && (parseInt(registrationTimeStart) || registrationTimeStart === 0) &&  (parseInt(registrationTimeEnd) || registrationTimeEnd === 0)) {
                                        this.setState({
                                            dataChangeDate: {
                                                ...dataChangeDate,
                                                dateSelected: registrationStart,
                                                startTime: convertTimezoneHour(registrationTimeStart, registrationStart),
                                                endTime: convertTimezoneHour(registrationTimeEnd, registrationStart)
                                            }
                                        })
                                    }
                                }}
                                clearDateCalenderSelected={() => {
                                    this.setState({
                                        dataChangeDate: {
                                            ...dataChangeDate,
                                            dateSelected: "",
                                            startTime: null,
                                            endTime: null
                                        }
                                    })
                                }}
                            />
                        }
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        dataUser: state.authReducer.dataUser,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter,
    withWidth()
)(TabRequestDate);
