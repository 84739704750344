import React, {Suspense} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import {createMuiTheme, MuiThemeProvider, responsiveFontSizes, withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {connect} from "react-redux";
import {enUS, frFR} from "@material-ui/core/locale";
import {CssBaseline} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import LoadingAction from "./theme/LoadingAction";
import ErrorBoundary from "./theme/PageError/ErrorBoundary";
import RoutesMap from "./RoutesMap";
import AppMuiPickersUtilsProvider from "./theme/form/DatePicker/AppMuiPickersUtilsProvider";
import Navbar from "./components/Navbar";
import {LOGIN, SHOW_SHARED_DOCUMENTS_NOT_LOGIN} from "./constants/links";
import arrayRoutesPublic from "./constants/routePublic";
import PublicRoute from "./PublicRoute";
import arrayRoutes from "./constants/routes";
import * as applicationActions from "./_actions/application";
import axios from "axios";
import {BASE_URL, DATA_USER} from "./constants/constants";
import * as auth from "./_actions/auth";
import {API_GET_USER_INFOS, /*CHECK_TOKEN_AUTH*/} from "./constants/apiSuffix";
import './font/segoeui/stylesheet.css';

import AlertCustom from "./theme/form/Alert/AlertCustom";
const styles = {
  main: {
    '& .barReactAdmin': {
      display: 'none'
    }
  }
}

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }
   actionDidAmountFnc = async () => {
     await this.props.getParameterCurrent();
     await this.props.showParamterContact();
     if (localStorage.getItem(DATA_USER) !== null) {
       const dataUserLocal = JSON.parse(localStorage.getItem(DATA_USER)) ?? {};
       if (dataUserLocal && dataUserLocal.token) {
         axios.defaults.headers.common['Authorization'] = 'Bearer ' + dataUserLocal.token;
         await axios.get(BASE_URL + API_GET_USER_INFOS)
             .then(response => {
               if (response.status === 200) {
                 this.props.setDataUser(response.data ? response.data : null);
                 this.setState({
                   isLoading: false
                 })
               } else {
                 throw new Error();
               }
             })
             .catch(error => {
               this.props.setDataUser(null);
               this.setState({
                 isLoading: false
               })
             });
       } else {
         this.setState({
           isLoading: false
         })
       }
     } else {
       this.setState({
         isLoading: false
       })
     }
   }

   componentDidMount() {
    this.actionDidAmountFnc().then(r => {})
  }


  render() {
    const {
      classes,
      tReady,
      location
    } = this.props;

    const { isLoading } = this.state

    let theme = createMuiTheme({
      palette: {
        primary: {
          light: '#2b2bf8',
          main: '#0000ff',
          dark: "#0000c7"
        },
        secondary:{
          main: '#00d5ff',
        }
      },
      typography: {
        fontFamily: `"Nunito", sans-serif`,
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            html: {
              WebkitFontSmoothing: 'auto',
              fontSize: 16
            },
            body: {
              fontFamily: `"Nunito", sans-serif`,
              "& .appWSPreline":{
                whiteSpace: "pre-line"
              }
            }
          },
        },
        MuiButton: {
          contained: {
            fontFamily: `"Nunito", sans-serif`,
            fontWeight: 400,
            fontStyle: 'normal',
            lineHeight: '19px',
            boxShadow: 'none!important',
            borderRadius: 10
          },
          containedSizeSmall: {
            fontSize: '0.875rem',
            padding: '0.6rem 1.4rem',
          },
          containedSizeLarge: {
            fontSize: '1.1rem',
            padding: '0.85rem 1.65rem',
          }
        }
      },
      props: {
        MuiButtonBase: {
          disableRipple: true
        },
        MuiInput: {
          disableUnderline: true
        }
      },
      MuiRadio: {
        root:{
          padding: '0 5px'
        },
        colorSecondary: {
          '&$checked': {
            color: '#BDBDBD !important',
          },
        }
      },
    }, this.props.i18n.language==="fr"?frFR:enUS);
    theme = responsiveFontSizes(theme);

    return (
        <ErrorBoundary>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            {(!!this.props.openNoticeSuccess || !!this.props.openNoticeError) &&
            <AlertCustom
                successOpen={this.props.openNoticeSuccess}
                errorOpen={this.props.openNoticeError}
                handleCloseNotice={this.props.handleCloseNoticeRedux}
                messageSuccess={this.props.messageNoticeSuccess}
                messageError={this.props.messageNoticeError}
            />}
            {isLoading ? <LoadingAction /> :
                <AppMuiPickersUtilsProvider>
                  <div className={classes.main}>
                    {(location.pathname !== LOGIN && !location.pathname.startsWith(SHOW_SHARED_DOCUMENTS_NOT_LOGIN.replace(":token", ""))) &&
                    <React.Fragment>
                      <Navbar />
                      {/*<Banner />*/}
                    </React.Fragment>
                    }
                  {
                !!tReady?<Suspense fallback={<LoadingAction/>}>
                  <Switch>
                    {arrayRoutesPublic.map(item=>{
                      return <PublicRoute
                          key={`public-route-${item.path}`}
                          path={item.path}
                          exact={item.exact}
                          component={item.component}
                      />;
                    })}
                    {arrayRoutes.map(item=>{
                      return <Route
                          key={`route-${item.path}`}
                          path={item.path}
                          exact={item.exact}
                          component={item.component}
                      />;
                    })}
                    {/*<div className={classes.wrapper}>*/}
                    {/*  <div className={classes.main}>*/}

                        <RoutesMap/>
                    {/*  </div>*/}
                    {/*</div>*/}
                  </Switch>
                </Suspense>:<LoadingAction/>
              }
                  </div>
            </AppMuiPickersUtilsProvider>}
          </MuiThemeProvider>
        </ErrorBoundary>

    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    dataUser: state.authReducer.dataUser,
    openNoticeError: state.applicationReducer.openNoticeError,
    openNoticeSuccess: state.applicationReducer.openNoticeSuccess,
    messageNoticeError: state.applicationReducer.messageNoticeError,
    messageNoticeSuccess: state.applicationReducer.messageNoticeSuccess,
  }
};
const mapDispatchToProps = (dispatch)=>{
  return {
    getParameterCurrent: () => dispatch(applicationActions.getParameterCurrent()),
    showParamterContact: () => dispatch(applicationActions.showParamterContact()),
    setDataUser: (dataUser) => dispatch(auth.setDataUser(dataUser)),
    handleCloseNoticeRedux: () => dispatch(applicationActions.handleCloseNoticeRedux()),
  }
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withRouter,
    withTranslation()
)(App);
