import React from 'react';
import {compose} from "redux";
import PopoverCustom from "../../../../theme/CustomElements/PopoverCustom";
import {Checkbox, Grid, Popover, withStyles} from "@material-ui/core";
import CustomButton from "../../../../theme/CustomElements/CustomButton";
import CustomInput from "../../../../theme/CustomElements/CustomInput";
import returnIcon from "../../../../images/request/return.svg";
import {withTranslation} from "react-i18next";
import {MONTSERRAT} from "../../../../constants/fontCss";
import ErrorInput from "../../../../theme/form/Alert/ErrorInput";
import {MAYBE, NOT_BE_REPAIRED, REPAIRED, SM, WAITING_FOR_REPLY, XS} from "../../../../constants/constants";
import GeneralModal from "../../../../theme/Modal/GeneralModal";
import closeModal from "../../../../images/theme/close.svg";
import CustomInlineSvg from "../../../../theme/CustomElements/CustomInlineSvg";
import tickIcon from "../../../../images/request/tick.svg";
import xIcon from "../../../../images/request/x.svg";
import maybeIcon from "../../../../images/request/maybe.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import ChatBox from "../../../chat/ChatBox";

const styles = (theme) => ({
    addChatPopover: {
        borderRadius: 13,
        '& > .MuiPaper-root': {
            borderRadius: 13,
        }
    },
    addNoteModal: {
        borderRadius: 13,
        border: 'none',
        height: 800,
        maxHeight: 'calc(100vh - 60px)',
        width: 800,
        maxWidth: 'calc(100vw - 60px)',
        [theme.breakpoints.between(XS, SM)]: {
            maxWidth: 'calc(100%)',
        },
        '& .headerModalWrapper': {
            display: 'none'
        },
        '& .bodyModalWrapper': {
            padding: 0,
            height: '100%',
            maxHeight: 'unset'
        }
    },
});

class PopoverAddChat extends React.Component{
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const { anchorEl ,onClose, classes, t, formValuePopover} = this.props;
        return <Popover
            className={classes.addChatPopover}
            open={true}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
        >
            <div
                className={classes.addNoteModal}
            >
                <ChatBox dataRequest={formValuePopover} onClose={onClose}/>
            </div>
        </Popover>;
    }
}

export default compose(withStyles(styles), withTranslation())(PopoverAddChat);
