import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';
import {
    // translate,
    MenuItemLink,
    Responsive,
} from 'react-admin';

import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core";
import {compose} from "redux";
import * as links from "./linkRectAdmin"
// import {GENERAL_DOCUMENT_URL, IMPORT_INTERVENTION} from "./linkRectAdmin";
// import {IMAGE_TYPE_URL} from "./linkRectAdmin";
const styles = {
    menu: {
        display: 'flex',
        '& [class^="MuiListItemIcon-root-"]': {
            // display: 'none'
            '& img':{
                width: 30
            }
        },
        '& .menu_active': {
            borderRadius: 5,
            background: '#ccd0d8',
            textAlign: 'left',
            color: '#0000ff',
            fontWeight: 'bold !important',
            margin: '1px 0',
            '& a': {
                fontWeight: 'bold',
            },
            '& span': {
                fontWeight: 'bold',
            },
            '& p': {
                fontWeight: 'bold',
            }
        },
        '& .menuWrapper': {
            // width: '50%',
            paddingRight: 7,
        },
    }
};

const arrayMenu = [
    // {
    //     link: links.CATEGORY,
    //     label: "reactAdmin.menu.category"
    // },
    {
        link: links.MAIL_TYPE,
        label: "reactAdmin.menu.mailType"
    },
    {
        link: links.REQUEST_MAIL_TYPE,
        label: "reactAdmin.menu.requestMailType"
    },
    {
        link: links.MAIL_URL,
        label:"reactAdmin.menu.mail"
    },
    {
        link: links.USER,
        label:"reactAdmin.menu.user"
    },
    {
        link: links.CURREN,
        label:"reactAdmin.menu.currency"
    },
    {
        link: links.WORK_TIME_URL,
        label:"reactAdmin.menu.work_time"
    },
    {
        link: links.BREAK_TIME_URL,
        label:"reactAdmin.menu.break_time"
    },
    {
        link: links.DAY_OF_WEEK_URL,
        label:"reactAdmin.menu.day_of_week"
    },
    {
        link: links.HOLIDAY_URL,
        label:"reactAdmin.menu.holiday"
    },
    {
        link: links.CALL_TIME_URL,
        label:"reactAdmin.menu.call_time"
    },
    {
        link: links.BLICKO_CONTACT_URL,
        label:"reactAdmin.menu.blicko_contact"
    },
    {
        link: links.PRICE_PAY_DEFAULT_URL,
        label:"reactAdmin.menu.price_pay_default"
    },
    {
        link: links.EMAIL_ADDRESS_BCC_URL,
        label:"reactAdmin.menu.email_address_bcc"
    },
    {
        link: links.LEASE_TYPE_URL,
        label:"reactAdmin.menu.lease_type"
    },
    {
        link: links.VIDEO_TYPE_URL,
        label:"reactAdmin.menu.video_type"
    },
    {
        link: links.REQUEST_DOCUMENT_TYPE_URL,
        label:"reactAdmin.menu.request_document_type"
    },
    {
        link: links.IMAGE_TYPE_URL,
        label:"reactAdmin.menu.image_type"
    },
    {
        link: links.DOCUMENT_REQUEST_VALID_URL,
        label:"reactAdmin.menu.document_request_valid"
    },
    {
        link: links.IMPORT_INTERVENTION,
        label:"reactAdmin.menu.import_intervention"
    },
    {
        link: links.APP_ADVERTISE_URL,
        label:"reactAdmin.menu.app_advertise"
    },
    {
        link: links.GENERAL_DOCUMENT_URL,
        label:"reactAdmin.menu.general_document"
    },
    {
        link: links.SHARE_DOCUMENT_EXPIRE_NUMBER_DAY_URL,
        label:"reactAdmin.menu.share_document_expire_number_day"
    },
    {
        link: links.REQUEST_URL,
        label:"reactAdmin.menu.request"
    },
    {
        link: links.SMS_TYPE_URL,
        label:"reactAdmin.menu.smsType"
    },
    {
        link: links.VOICE_TYPE_URL,
        label:"reactAdmin.menu.voiceType"
    },
    {
        link: links.PARAM_TOKEN_URL,
        label:"reactAdmin.menu.apiParameterSmsAndVoice"
    },
]

class MenuLayout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pathNameLocation: props.location.pathName,
            pathNameSearch:props.location.search
        };
    }

    // static getDerivedStateFromProps(props, state) {
    //     if (props.location.pathname && (props.location.pathname !== state.pathNameLocation ||  props.location.search !== state.pathNameSearch) && state.changePathNameLocation){
    //         let paramPathName = props.location.pathname;
    //         return {
    //             pathNameLocation: paramPathName,
    //             changePathNameLocation: false,
    //             pathNameSearch: props.location.search
    //         }
    //     }
    //     return null;
    // }

    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };

    handleMenu = (pathName) => {
        this.setState(
            state => (
                {pathNameLocation: pathName})
        )
    }

    render() {
        const {onMenuClick, classes,location, t} = this.props;
        const {pathNameLocation,pathNameSearch} = this.state;
        this.menuLinkReactAdminRefs = []
        return (
            <div className={"sideBarWrapper"}>
                {' '}
                {/*<DashboardMenuItem onClick={onMenuClick}/>*/}
                <div className={`${classes.menu} menuItemWrapper `}>
                    <div className="menuWrapper">
                        <div className="menuParent">
                            {/*<div className="menu">*/}
                            {/*    <div className="section">*/}
                            {arrayMenu.map((item, index)=>{
                                return (
                                    <MenuItemLink
                                        ref={ref => {this.menuLinkReactAdminRefs.push(ref)}}
                                        key={`menu-item-${index}`}
                                        to={item.link}
                                        primaryText={t(item.label)}
                                        // leftIcon={<SettingsIcon />}
                                        onClick={(e) => {
                                            if (onMenuClick) onMenuClick(e);
                                            this.handleMenu(item.link)
                                        }}
                                        sidebarIsOpen={false}
                                        className={pathNameLocation === item.link && ((item.search&&location.search && pathNameSearch.indexOf(item.search) !== -1)||!item.search)? "menu_active" : ""}
                                    />
                                )
                            })}

                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                {/*<Responsive*/}
                {/*    xsmall={*/}
                {/*        // <MenuItemLink*/}
                {/*        //     to={links.CONFIGURATION}*/}
                {/*        //     primaryText={translate('pos.configuration')}*/}
                {/*        //     // leftIcon={<SettingsIcon />}*/}
                {/*        //     onClick={() => {*/}
                {/*        //         onMenuClick;*/}
                {/*        //         this.handleMenuGeneral(true);*/}
                {/*        //     }}*/}
                {/*        //     className={pathNameLocation === links.CONFIGURATION && ((item.search&&location.search && pathNameSearch.indexOf(item.search) !== -1)||!item.search)? "menu_active" : ""}*/}
                {/*        // />*/}
                {/*    }*/}
                {/*    medium={null}*/}
                {/*/>*/}
                <Responsive
                    // small={logout}
                    medium={null} // Pass null to render nothing on larger devices
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    theme: state.theme,
    // locale: state.i18n.locale,
});

MenuLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

const enhance = compose(
    withStyles(styles),
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
    withTranslation(),
    // translate
);

export default enhance(MenuLayout);
