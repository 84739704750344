import React from "react";
import {withStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";
// import {Grid,} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {STRING} from "../../constants/constants";

const styles = {
    formRow:{
        // paddingBottom: '0.5rem',
        width: '100%',
        padding: '0 5px 10px 5px',

                
        '& @media screen and (max-width:700px)':{
            display: 'flex',
            justifyContent: 'space-between',
        }

    },
    validate: {
        color: '#d01631',
        fontWeight: 'normal',
        fontSize: "0.8rem",
        lineHeight:'1rem',
        marginTop: "0.5rem",
        "&>span":{
            fontSize: "0.8rem",
            color: '#d01631 !important',
            lineHeight:'1rem',
        }
    },
    label:{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        padding: '0.2rem 0rem',
        color: '#1A1732',
        '&.labelHasImg':{
            alignItems: 'center',
        },
        '& img':{
            paddingRight: '0.5rem'
        },
        '& .isRequired': {
            fontWeight: 600,
            padding: '0 5px',
            color: 'blue',
            display: 'flex',
            textAlign: 'left',
        },
    },
    input:{
        height: 'min-content'
    }
};


class RowFormColumn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const {
            classes,
            label,
            input,
            textValidate,
            errorKey,
            imgSrcLabel,
            className,
            isRequired,
            componentLabelRight
        } = this.props;
        return (
            <div className={`${classes.formRow} rowFormColumn ${className?className:""}`}>
                <div className={`${classes.label} labelRowFormColumn ${imgSrcLabel ? "labelHasImg": ""}`}>
                    {imgSrcLabel ? (typeof imgSrcLabel === STRING ?<img src={imgSrcLabel} className={"imgSrcLabelRowFormColumn"} alt=""/>: imgSrcLabel ):""} {label} {isRequired && <span className="isRequired">*</span>} {componentLabelRight ?? ""}
                </div>
                <div className={`${classes.input} inputRowFormColumn`}>
                    {input}
                </div>
                { textValidate && <div className={classes.validate}>
                    {errorKey && <span>{errorKey}: </span>}
                    <span>{textValidate}</span>
                </div> }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = (dispatch)=>{
    return {
    }
};

RowFormColumn.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    input: PropTypes.node,
    textValidate: PropTypes.string,
    errorKey: PropTypes.string,
    className: PropTypes.string,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withRouter,
    withTranslation())
(RowFormColumn);
