import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {NavLink, withRouter} from "react-router-dom";
import * as authActions from "../../../_actions/auth";
import deleteIcon from "../../../images/report/bin.svg"
import docPdfIcon from "../../../images/report/Composant 28 – 61.svg"
import CustomInlineSvg from "../../../theme/CustomElements/CustomInlineSvg";
import repairerIcon from "../../../images/report/Groupe 167.svg"
import lockIcon from "../../../images/report/Groupe 8895.svg"
import cardPayIcon from "../../../images/report/cb_visa_mastercard_logo-1.png"
import CustomInput from "../../../theme/CustomElements/CustomInput";
import {MONTSERRAT, NUNITO} from "../../../constants/fontCss";
import Grid from "@material-ui/core/Grid";
import CustomButton from "../../../theme/CustomElements/CustomButton";
import cardIcon from "../../../images/report/credit-card2.svg"
import watterIcon from "../../../images/report/Tracé 3854.png"
import bigIcon from "../../../images/report/Groupe 8825.svg"
import axios from "axios";
import {
    API_GET_REQUEST_REPORT_LIST,
    API_REQUEST_REPORT_DELETE
} from "../../../constants/apiSuffix";
import LoadingAction from "../../../theme/LoadingAction";
import DialogForm from "../../../theme/CustomElements/DialogForm";
import NavBar2 from "../../NavBar2";
import banner from "../../../images/banner/banner.png";
import {
    IS_SENT_TO_CLIENT,
    IS_TRASH, MD,
    PAYMENT_CARD_MASTERCARD,
    PAYMENT_CARD_VISA,
    PAYMENT_REPORT,
    SM,
    XS
} from "../../../constants/constants";
import AlertCustom from "../../../theme/form/Alert/AlertCustom";
import parse from "html-react-parser";
// import Payment from "../information/Payment";
import checked from "../../../images/payment/checked.svg";
import * as links from "../../../constants/links";
import {Button, Popover, withWidth} from "@material-ui/core";
import {
    PAYMENT_CARD,
    PAYMENT_TAB_LYDIA,
    PAYMENT_TAB_MASTER_CARD,
    PAYMENT_TAB_PAYPAL,
    PAYMENT_TAB_VISA,
    paymentArray
} from "../../../constants/paymentMethod";
import PaypalPayment from "../information/payment/PaypalPayment";
import LydiaPayment from "../information/payment/LydiaPayment";
import SogeCommercePayment from "../information/payment/SogeCommercePayment";
import BgTrashIcon from "../../../images/diagnostic/bg-trash.svg";
// import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
// import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import copyIcon from "../../../images/report/copy.svg"
// import closeButton from "../../../images/theme/close.svg";
import * as application from "../../../_actions/application";
const styles = theme => ({
    reportPageWrapper:{
        '& .headerRequestPageClient': {
            background: `url(${banner})`,
            backgroundSize: 'cover',
            height: 154,
            position: 'relative',
            '&::before':{
                content:'""',
                //width: '55%',
                height: '100%',
                position: 'absolute',
               //background: 'linear-gradient(270deg, rgba(14, 56, 96, 0) 8.24%, #0E2F60 34.94%, #0A0730 97.58%)',
               background: '#2020ac',
               opacity:'0.5',
               boxShadow: 'inset 5px -38px 44px -18px rgba(0,0,0,0.54)',
               width: '100%',
            },
            [theme.breakpoints.down(MD)]:{
                height: 150
            }
        },
    },
    reportPage:{
        padding: '2rem',
        [theme.breakpoints.between(XS, SM)]: {
            padding: '1rem',
        },
        '& .reportPageRight':{
            padding: '2rem',
            [theme.breakpoints.between(XS, SM)]: {
                width: '100%',
                padding: '2rem 1rem',
            },
        },
        '& .reportPageLeft':{
            padding: '2rem',
            [theme.breakpoints.between(XS, SM)]: {
                width: '100%',
                padding: '2rem 1rem',
                lineHeight: 1.1
            },
            '& .reportListWrapper':{
                margin: '1.5rem 0',
                width: '100%',
                maxHeight: 500,
                overflowY: 'auto',

                '& .td-amount':{
                    textAlign: 'right',
               }
            }
        }
    },
    reportPageContent:{
        background: '#fff',
        borderRadius: 22,
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        width: '100%'
    },
    reportList:{
        color: '#9292ae',
        width: '100%',
        padding: '1.5rem 0',
        borderSpacing: '0 !important',
        '& tr':{
            '& td':{
                padding: '0.5rem',
                borderBottom: '1px dotted #9292ae',
                fontFamily:NUNITO,
            }
        },
        '& thead':{
            '& td':{
                fontSize:' 0.875rem',
                fontWeight: 300,
                textTransform:'uppercase'
            }
        },
        '& tbody':{
            '& tr':{
                '&.isTrashStatus':{
                    backgroundImage: `url(${BgTrashIcon})`,
                    '& $deleteSvg':{
                        background:'#fff'
                    }
                },
                '& td':{
                    padding: '1rem 0.5rem',
                    color: '#0000f9',
                    fontSize: '1rem',
                    lineHeight: '1.1rem',
                    '&:first-child':{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    },
                    '&:last-child':{
                        fontSize: 20,
                        minWidth: '150px',
                        textAlign: 'right',
                    },
                    '& .amountNumberInput': {
                        width: 80,
                        borderRadius: 5,
                        border: 'solid 1px #707070',
                        background: '#fff'
                    },
                    '& .pdfFile': {
                        '& svg': {
                          minWidth: '48px',
                        }

                    }
                }
            },
        },
        '& .aBtn': {
            textDecoration: 'none',
            color: '#fff',
        },
        '& .btnPayment': {
            backgroundImage: 'linear-gradient(95deg,#00c1d1, #0070c7, #00c1d1) !important',
            boxShadow: '0px 4px 20px rgba(15, 15, 137, 0.1) !important',
            borderRadius: 20,
            maxWidth: '365px',
            backgroundSize: '200%',
            transition: 'all .4s ease-in-out',
            textTransform: 'none',
            color: '#fff',
            '&:before':{
                background: 'none!important'
            },
            '&:hover':{
                backgroundPosition: '100% 0',
                transition: 'all .4s ease-in-out',
            },
        },
        '& .td-rowAmount':{
            minWidth: '110px',
            textAlign: 'right'
        },
    },
    reportBoxRightWrapper: {
        '& .reportBoxRightContent':{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            padding: '2.5rem',
            background: `url(${watterIcon}) no-repeat -25px 15px`,
            '& > div':{
                padding: '1rem 0',
            },
        },
        borderRadius: 14,
        backgroundImage: 'linear-gradient(120deg, #afdbfd 14%, #b7f2f2 90%)',
        '& > div': {
            fontFamily: NUNITO,
        },
        '& .labelSecurity':{
            display: 'flex',
            alignItems: 'center',
            '& > div':{
                fontSize: 13,
                fontWeight: 700,
                color: '#2b2b2b',
                padding: '0 0.5rem'
            }
        },
        '& .needHelp': {
            fontSize: 29,
            fontWeight: 600,
            color: '#0000f9',
        },
        '& .labelContact':{
            fontSize: 18,
            fontWeight: 500,
            color: '#3e3e3e'
        },
        '& .labelPhone':{
            fontSize: 26,
            fontWeight: 600,
            color: '#0000f9',
            lineHeight: 1
        },
    },
    iconButton: {
        // height:30
        padding: '0 1rem'
    },
    btnPaymentAccess: {
        width: '100%',
        margin: '1rem 0',
        borderRadius: 7,
        boxShadow: ' 0 2px 3px 0 rgba(0, 0, 0, 0.16)',
        backgroundImage: 'linear-gradient(to bottom, #0070c7, #00c1d1)',
        fontSize: 20,
        fontWeight: 700,
        padding: '1.6rem',
        lineHeight: '1.8rem',
        textTransform: 'uppercase',

    },
    saleCode:{
        '& .labelInput':{
            fontFamily: NUNITO,
            fontSize: 13,
            color: '#0000f9',
            textTransform:'uppercase',
            padding: '0 0.5rem'
        },
        '& .saleInput':{
            width: 200,
            borderRadius: 5,
            backgroundImage: 'linear-gradient(96deg, #afdbfd 11%, #b7f2f2 105%)'
        }
    },
    totalAllWrapper:{
        display: 'flex',
        justifyContent: 'flex-end',
        fontFamily: NUNITO,
        fontSize: 16,
        color: '#0000f9',
        textTransform:'uppercase',
        fontWeight: 600,
        [theme.breakpoints.between(XS, SM)]: {
            justifyContent: 'center',
            padding: '0.5rem 0',
        },
        '& div':{
            minWidth: 150,
        },
        '& .numberTotal':{
            fontSize:36
        }
    },
    deleteSvg:{
        padding: '0.25rem 0.5rem',
        marginRight: 13,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        '&:hover':{
            background: '#f2f0f0',
        }
    },
    reportInfoWrapper:{
        fontFamily: NUNITO,
        padding: '0 0.5rem',
        paddingLeft: '1.5rem',

        '& .problemObserved':{
            fontSize: 19,
            fontWeight: 500,
            color: '#323136',
            lineHeight: '22px',
            paddingBottom: '6px',
            paddingTop: '3px',
            paddingRight: '30px',
        },
        '& .labelIntervention':{
            fontSize: 13,
            color: '#0000f9'
        }

    },
    reportId:{
        fontSize: 13, fontWeight: 500,
        color: '#605e72'
    },
    right :{
        // marginLeft: 20,
        // width: '70%',
        display: 'flex',
        flexDirection: 'column',
        // width: 500,
        '& .btnClosePayment': {
            backgroundColor: '#989898',
            color: '#fff',
            width: 40,
            height: 40,
            minWidth: 'unset'
        },
        '& .message': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            '& .linkHome': {
                textDecoration: 'none',
                marginTop: 10
            },
            '& .btnToHome': {
                color: '#fff',
                backgroundColor: '#0000ff',
                textDecoration: 'none',
                textTransform: 'initial',
                fontWeight: 600,
                padding: '8px 16px'
            }
        },
        '& .paidText': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '1.65rem',
            fontWeight: 600,
            '& .text': {
                paddingLeft: 10
            }
        },


        '& .payment_content':{
            //background: '#deebf2',
            border: '16px solid #e9f0f4',
            borderRadius: '15px',
            padding: '15px 35px',
             //background: 'linear-gradient(180deg, rgb(233, 240, 244) 0%, rgb(255, 255, 255) 100%)',

            '& .payment_method':{
                marginTop: 20,
            },
            '& .payment_body': {
                flexGrow: 1,
                padding: 8,
                overflow: 'hidden',
                '& .paramLabel': {
                    color: 'red!important'
                }
            },






        },


        '& iframe': {
            width: '100%',
            height: '100%',
            border: 'none'
        },
        '& .payment_title': {
            fontWeight: 700,
            color: '#535353',
            fontSize: '0.875rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        '& .payment_list_method': {
            display: 'flex',
            // justifyContent: 'space-between',
            marginTop: 7,
            overflowX: 'auto'
        },
        '& .payment_element': {
            height: 54,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            margin: '0 4px',
            border:'1px solid #BDBDBD',
            borderRadius: 10,
            '& img': {
                margin: '6px 18px',
            },
        },
        '& .not_lydia':{
            border:'1px solid #BDBDBD',
            borderRadius: 10,
            '& img': {
                margin: '6px 18px',
                width: '55px'
            },
        },
        '& .paymentSelected':{
            border:'2px solid #0000FF',
            borderRadius: 8,
        },
        '& .payment_detail':{
            display: 'flex',
            justifyContent:'space-between',
            '& input':{
                width: '100%',
                height: 40,
                border: '1px solid #E0E0E0',
                borderRadius: 6,
                marginTop: 10,
                fontFamily: `${MONTSERRAT}`,
                paddingLeft: 9
            },
            '& .payment_detail_left':{
                width: '60%',
                marginRight: 10
            },
            '& .payment_detail_right':{
                width: '40%',
                marginLeft: 10
            },
        },
        [theme.breakpoints.down(SM)]:{
            width: '60%',
            '& .payment_list_method':{
                flexWrap: 'wrap'
            },
            '& .not_lydia':{
                '& img':{
                    width: '50px'
                }
            },
            '& .payment_element:first-child':{
                width: '100px'
            },
        },
        [theme.breakpoints.down(XS)]:{
            width: '100%',
            marginLeft: 0,
            '& .paypal-buttons ':{
                minWidth: '100%!important'
            },
            '& .not_lydia':{
                width: '30%!important',
                justifyContent: 'center',
                '& img':{
                    margin: '0!important'
                }
            },
            '& .payment_element':{
                width: '30%!important',
            }
        }
    },
    copySuccess: {
        backgroundColor: '#e6edfc',
        padding: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '& .text': {
            color: '#48489f',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                marginLeft: 10,
                '& path': {
                    fill: '#0099cc',
                }
            }
        },
        '& .link': {
            color: '#0099cc',
            fontSize: '0.8rem'
        }
    }
});

class ReportPage extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            reportsList: [],
            isLoading: false,
            dialogForm: null,
            successOpen: false,
            errorOpen: false,
            openPaymentForm: false,
            loadingMethod: false,
            methodPayment: PAYMENT_TAB_MASTER_CARD,
            discountCode:"",
            openDetailReport:[],
            copySuccess: null,
            copyLink: '',
        }
    }




    handleChangeFiledState = (value, field)=>{
        this.setState({
            [field]: value
        })
    }
    handleAgreeDialog = () => {
        this.deleteReport()
    }

    handleCloseNotice = () => {
        this.setState({
            successOpen: false,
            errorOpen: false
        })
    }
    deleteReport = (item) => {
        this.setState({isLoading: true, dialogForm: null})
        axios.post(API_REQUEST_REPORT_DELETE.replace("{id}", this.state.dialogForm.id))
            .then(response => {
                if(response.status ===200) {
                    this.setState({
                        successOpen: true,
                    }, function (){
                        this.getData()
                    })
                }else {
                    throw new Error();
                }
            })
            .catch(error => {
                this.setState({
                    isLoading: false,
                    errorOpen: true
                })
            })
    }

    getData = () =>{
        axios.get(API_GET_REQUEST_REPORT_LIST, {
            params: {
                maxPerPageAll: 1,
                notPaid: 1
            }
        })
            .then(res => {
                if (res.status === 200) {
                    this.setState({
                        reportsList: res.data.items,
                        isLoading:false
                    }, function () {
                        this.props.setReportRequestNotPaid(parseInt(res.data.nbResults) ?? (res.data.items.length))
                    })

                } else {
                    throw new Error();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading:false
                })
            })
    }
    componentDidMount() {
        this.setState({isLoading: true})
        this.getData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.loadingMethod) {
            this.setState({
                loadingMethod: false
            })
        }
    }

    showPaymentForm(totalAllReport) {
        const {
            methodPayment,
            paymentPrice,
            paymentCurrency,
            reportsList
        } = this.state;
        const {
            classes,
            dataUser
        } = this.props;
        const infoPayer = {
            email: dataUser?.data?.email ?? "",
            firstName: dataUser?.data?.firstName ?? "",
            lastName: dataUser?.data?.lastName ?? "",
            address: dataUser?.data?.addressDescription ?? "",
            phoneNumber: dataUser?.data?.phoneNumber ?? "",
        }
        let listReportId = reportsList.map(reportRequest => {
            if(reportRequest.report.reportStatus === IS_SENT_TO_CLIENT) {
                return reportRequest.report.id;
            }
        })
        switch (methodPayment) {
            case PAYMENT_TAB_LYDIA:
                return  <LydiaPayment
                    // updateDataRequest={this.updateDataRequest}
                    // requestId={this.props.requestId}
                />;
            case PAYMENT_CARD:
                return <SogeCommercePayment
                    paymentPrice={totalAllReport}
                    paymentCurrency={paymentCurrency}
                    paymentType={PAYMENT_REPORT}
                    listReportPayment={listReportId}
                    // updateDataRequest={this.updateDataRequest}
                    // requestId={this.props.requestId}
                    infoPayer={infoPayer}
                />;
            case PAYMENT_TAB_MASTER_CARD:
                return <SogeCommercePayment
                    paymentCard={PAYMENT_CARD_MASTERCARD}
                    paymentPrice={totalAllReport}
                    paymentCurrency={paymentCurrency}
                    paymentType={PAYMENT_REPORT}
                    listReportPayment={listReportId}
                    // updateDataRequest={this.updateDataRequest}
                    // requestId={this.props.requestId}
                    infoPayer={infoPayer}
                />;
            case PAYMENT_TAB_VISA:
                return <SogeCommercePayment
                    paymentCard={PAYMENT_CARD_VISA}
                    paymentPrice={totalAllReport}
                    paymentCurrency={paymentCurrency}
                    paymentType={PAYMENT_REPORT}
                    listReportPayment={listReportId}
                    // updateDataRequest={this.updateDataRequest}
                    // requestId={this.props.requestId}
                    infoPayer={infoPayer}
                />;
            case PAYMENT_TAB_PAYPAL:
                return <PaypalPayment
                    paymentPrice={totalAllReport}
                    paymentCurrency={paymentCurrency}
                    setLoadingReportPage={this.setLoadingReportPage}
                    requestId={dataUser.data.id}
                    paymentType={PAYMENT_REPORT}
                    listReportPayment={listReportId}
                    infoPayer={infoPayer}
                />;
            default:
                return <div>

                </div>;
        }
    }

    setLoadingReportPage = () => {
        window.location.reload();
    }
    getMethodPayment = (event) => {
        this.setState({
            methodPayment : event,
            errors: {},
            loadingMethod: true
        })
    }

    renderTbodyReportListWidthLessThanMd = (itemReport,dataIntervention)=>{
        const {openDetailReport} = this.state;
        const {classes,t,currency} = this.props;
        return <tr>
            <td>
                {/*{openDetailReport.includes(itemReport.id) ? <ExpandLessRoundedIcon/> :*/}
                {/*    <ExpandMoreRoundedIcon/>}*/}
                <div className={classes.deleteSvg} onClick={() => this.setState({
                    dialogForm: itemReport
                })}>
                    <CustomInlineSvg svgProps={{
                        src: deleteIcon
                    }}/>
                </div>
                <div className={"pdfFile"}>
                {itemReport.reportPdf && itemReport.reportPdf.pathUrl ?
                    <a href={itemReport.reportPdf.pathUrl} target="_blank" rel="noreferrer" download>
                        <CustomInlineSvg svgProps={{
                            src: docPdfIcon
                        }}/>
                    </a> :
                    <CustomInlineSvg svgProps={{
                        src: docPdfIcon
                    }}/>
                }
                </div>
                <div className={classes.reportInfoWrapper}>
                    <div className={"labelIntervention"}>{t("client.reportList.intervention")}</div>
                    <div className={'problemObserved'}> {dataIntervention.problemObserved}</div>
                    <div className={classes.reportId}> N° {dataIntervention.id}</div>
                </div>
            </td>
            <td>
                {itemReport.report.otherInfos &&
                <span>{itemReport.report.otherInfos.totalWithoutTax ?? 0} {currency ? currency.unit : ""}</span>}
            </td>
        </tr>

    }



    render() {
        const {copySuccess,copyLink, reportsList, isLoading, openPaymentForm, loadingMethod, paymentRequest, paymentPrice, paymentCurrency, methodPayment,discountCode} = this.state;
        const {
            classes,t,blickoContact,currency, width,
            dataUser,location
        } = this.props;
        const blickoContactHtml = parse(blickoContact ?? "");
        let totalAllReport = 0;
        let listReportId = [];
        console.log(reportsList)
        if(Array.isArray(reportsList) && reportsList.length){
            listReportId =  reportsList.map(itemReport => {
                if(itemReport.report.reportStatus === IS_SENT_TO_CLIENT) {
                    totalAllReport += parseFloat(itemReport?.report?.otherInfos?.totalFinally ?? 0);
                    return itemReport.report.id;
                }
            })
        }
        const infoPayer = {
            email: dataUser?.data?.email ?? "",
            firstName: dataUser?.data?.firstName ?? "",
            lastName: dataUser?.data?.lastName ?? "",
            address: dataUser?.data?.addressDescription ?? "",
            phoneNumber: dataUser?.data?.phoneNumber ?? "",
        }
        const methodArray = paymentArray.map((item, index) => {
            switch (item.name) {
                case PAYMENT_TAB_MASTER_CARD:
                case PAYMENT_TAB_VISA:
                    return (
                        <div className={`payment_element not_lydia ${item.name===this.state.methodPayment?'paymentSelected':''}`} onClick={()=>this.getMethodPayment(item.name)}>
                            <img src={item.logo}/>
                        </div>
                    );
                case PAYMENT_TAB_PAYPAL:
                    return <div className='payment_element'>
                        <PaypalPayment
                            paymentPrice={totalAllReport}
                            paymentCurrency={paymentCurrency}
                            setLoadingReportPage={this.setLoadingReportPage}
                            requestId={dataUser.data.id}
                            listReportPayment={listReportId}
                            paymentType={PAYMENT_REPORT}
                            infoPayer={infoPayer}
                        />
                    </div>;
                case PAYMENT_TAB_LYDIA:
                    return <div className='payment_element'>
                        <LydiaPayment
                            updateDataRequest={this.updateDataRequest}
                            requestId={this.props.requestId}
                        />
                    </div>;
            }
        })

        return (
            <div className={classes.reportPageWrapper}>
                <div className={"headerRequestPageClient"}>
                    <NavBar2 />
                </div>
                {isLoading && <LoadingAction />}
                <Grid container item xs={12} className={classes.reportPage}>
                    <Grid container item xs={12} className={classes.reportPageContent}>
                        <Grid item sm={12} md={openPaymentForm ? 7 : 8} className={"reportPageLeft"}>
                            <div className={"reportListWrapper"}>
                            <table className={classes.reportList}>
                                {![XS, SM].includes(width) && <thead>
                                <tr>
                                    <td>
                                        {t("client.reportList.description")}
                                    </td>
                                    <td>
                                        {t("client.reportList.timeLimit")}

                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                        {t("client.reportList.amount")}
                                    </td>
                                    <td className={"td-amount"}>
                                        {t("client.reportList.total")}
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                                </thead>}
                                <tbody>
                                {Array.isArray(reportsList) && reportsList.length ?
                                    reportsList.map(itemReport => {
                                            const dataIntervention = itemReport.report.otherInfos.initialData ? JSON.parse(itemReport.report.otherInfos.initialData) : {};
                                        let viewPrice = itemReport?.report?.otherInfos && !Number.isNaN(Number(itemReport.report.otherInfos.totalFinally)) ? new Intl.NumberFormat('fr-FR').format(Number(itemReport.report.otherInfos.totalFinally).toFixed(2)) : '';
                                        if ([XS, SM].includes(width)){
                                                return this.renderTbodyReportListWidthLessThanMd(itemReport, dataIntervention)
                                            } else {
                                                return <tr
                                                    className={`rowData ${itemReport.report.reportStatus === IS_TRASH ? "isTrashStatus" : ""}`}>
                                                    <td>
                                                        <div className={classes.deleteSvg} onClick={() => this.setState({
                                                            dialogForm: itemReport
                                                        })}>
                                                            <CustomInlineSvg svgProps={{
                                                                src: deleteIcon
                                                            }}/>
                                                        </div>
                                                        <div className={"pdfFile"}>
                                                        {itemReport.reportPdf && itemReport.reportPdf.pathUrl ?
                                                            <a href={itemReport.reportPdf.pathUrl} target="_blank" download>
                                                                <CustomInlineSvg svgProps={{
                                                                    src: docPdfIcon
                                                                }}/>
                                                            </a> :
                                                            <CustomInlineSvg svgProps={{
                                                                src: docPdfIcon
                                                            }}/>
                                                        }
                                                        </div>
                                                        <div className={classes.reportInfoWrapper}>
                                                            <div
                                                                className={"labelIntervention"}>{t("client.reportList.intervention")}</div>
                                                            <div
                                                                className={'problemObserved'}> {dataIntervention.problemObserved}</div>
                                                            <div
                                                                className={classes.reportId}> N° {dataIntervention.id}</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        Délai court
                                                    </td>
                                                    <td>
                                                        {dataIntervention.solution}
                                                    </td>
                                                    <td>
                                                        <CustomInput
                                                            className={"amountNumberInput"}
                                                            type={"number"}
                                                            value={1}
                                                        />
                                                    </td>
                                                    <td class="td-rowAmount">
                                                       <span>{ viewPrice} {currency?currency.unit:""}</span>
                                                    </td>
                                                    {/* <td>
                                                        {itemReport?.report?.token && <Button
                                                            onClick={(event) => {
                                                                try {
                                                                    const base = window.location.origin;
                                                                    const copyLink = base + links.PAYMENT_REPORT_REQUEST.replace(':token', itemReport.report.token)
                                                                    navigator.clipboard.writeText(copyLink);
                                                                    this.setState({
                                                                        copySuccess: event.currentTarget,
                                                                        copyLink: copyLink
                                                                    })
                                                                } catch (e) {

                                                                }
                                                            }}
                                                            // className="aBtn"
                                                            className="btnPayment"
                                                            // to={links.PAYMENT_REPORT_REQUEST.replace(':token', itemReport.report.token)}
                                                        >
                                                                {t('payment.payment_share')}
                                                        </Button>}
                                                    </td> */}
                                                </tr>
                                            }
                                        }
                                    ) : ""}
                                </tbody>
                            </table>
                            </div>
                            <div className={classes.saleCode}>
                                <div className={"labelInput"}>   {t("client.reportList.saleCode")}</div>
                                <CustomInput
                                    className={"saleInput"}
                                    value={discountCode}
                                    onChange={(e)=>this.handleChangeFiledState(e.target.value,"discountCode" )}
                                />
                            </div>
                            <div className={classes.totalAllWrapper}>
                                <div>
                                    {t("client.reportList.totalAll")}
                                </div>
                                <div className={"numberTotal"}>  {new Intl.NumberFormat('fr-FR').format(totalAllReport)} {currency?currency.unit:""}</div>
                            </div>
                        </Grid>
                        <Grid item  sm={12} md={openPaymentForm ? 5 : 4} className={"reportPageRight"}>
                            {openPaymentForm
                                ?
                                <div className={classes.right}>
                                    {
                                        !paymentRequest
                                            ?
                                            <React.Fragment>
                                                <div className={'payment_content'}>
                                                <div className={'payment_method'}>
                                                    <div className={'payment_title'}>
                                                        <div>{t('information.formTab3.methodPayment')}</div>
                                                        <Button
                                                            onClick={() => {
                                                                this.setState({
                                                                    openPaymentForm: false
                                                                })
                                                            }}
                                                            className="btnClosePayment"
                                                        >
                                                            x
                                                        </Button>
                                                    </div>
                                                    <div className={'payment_list_method'}>
                                                        {methodArray}
                                                    </div>
                                                </div>
                                                {!loadingMethod && <div className="payment_body">
                                                    {this.showPaymentForm(totalAllReport)}
                                                </div>}
                                                </div>
                                            </React.Fragment>
                                            :
                                            <div className="message">
                                                <div className="paidText">
                                                    <CustomInlineSvg
                                                        svgProps={{src : checked}}
                                                    />
                                                    <div className="text">
                                                        {this.props.t("payment.paid_text")}
                                                    </div>
                                                </div>
                                                <NavLink to={links.HOME} className="linkHome">
                                                    <Button className="btnToHome">{this.props.t('payment.return_home')}</Button>
                                                </NavLink>
                                            </div>
                                    }
                                </div>
                                :
                                <>
                                    <div className={classes.reportBoxRightWrapper}>
                                        <div className={"reportBoxRightContent"}>
                                            <img src={repairerIcon} alt={""}/>
                                            <div className={"needHelp"}>{t("client.report.labelNeedHeld")}</div>
                                            <div className={"labelContact"}>{t("client.report.labelContact")}</div>
                                            <div className={"labelPhone"}>{blickoContactHtml??""}</div>
                                            <div className={"labelSecurity"}>
                                                <img src={lockIcon} alt={t("client.report.securityPayment")}/>
                                                <div>{t("client.report.securityPayment")}</div>
                                            </div>
                                            <img alt="" src={cardPayIcon}/>
                                        </div>
                                    </div>
                                    {totalAllReport >0 && <div>
                                        <CustomButton
                                            title={t("client.report.paymentAccess")}
                                            buttonProps={{
                                                startIcon: <React.Fragment><img src={cardIcon} alt=""
                                                                                className={classes.iconButton}/></React.Fragment>,
                                                endIcon: <React.Fragment><img src={bigIcon} alt=""
                                                                              className={classes.iconButton}/></React.Fragment>,
                                            }}
                                            className={classes.btnPaymentAccess}
                                            onClick={() => {
                                                this.setState({
                                                    openPaymentForm: true
                                                })
                                            }}
                                        />
                                    </div>}
                                </>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {!!(this.state.dialogForm) && <DialogForm
                    styleNumber={2}
                    dialogProps={{
                        open: !!(this.state.dialogForm)
                    }}
                    disagreeButtonProps={{
                        handleDisagree: () => this.setState({dialogForm: null})
                    }}
                    agreeButtonProps={{
                        handleAgree: () =>  this.handleAgreeDialog()
                    }}
                />}
                <AlertCustom
                    successOpen={this.state.successOpen}
                    errorOpen={this.state.errorOpen}
                    handleCloseNotice={()=>this.handleCloseNotice()}
                />
                <Popover
                    open={!!copySuccess}
                    anchorEl={copySuccess}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    onClose={() => {
                        this.setState({
                            copySuccess: null,
                        })
                    }}
                >
                    <div className={classes.copySuccess}>
                        <div className="text">
                            {t('payment.copy_success')}
                            <CustomInlineSvg
                                svgProps={{
                                    src: copyIcon,
                                }}
                            />
                        </div>
                        <div className="link">
                            {copyLink}
                        </div>
                    </div>
                </Popover>
            </div>
        )
    }
}

ReportPage.defaultProps = {

}

ReportPage.propTypes = {
    classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
    return {
        dataUser : state.authReducer.dataUser,
        blickoContact: state.applicationReducer.blickoContact,
        currency:  state.applicationReducer.currency,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        logout: ()=>{
            dispatch(authActions.logout())
        },
        setReportRequestNotPaid: (countReportRequestNotPaid) => dispatch(application.setReportRequestNotPaid(countReportRequestNotPaid) )
    }
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter,
    withWidth()
)(ReportPage);

