import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import {Button} from "@material-ui/core";
import LydiaLogo from './../../../../images/payment/lydia_logo.svg'
import {BASE_URL} from "../../../../constants/constants";
import {GET_SIGNATURE_LYDIA, GET_SIGNATURE_SOGE_COMMERCE} from "../../../../constants/apiSuffix";

const styles = theme => ({
    lydiaPaymentWrapper: {
        height: '100%',
        // width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    btnPayment: {
        padding: 0,
        borderRadius: 8,
        height: '100%'
    }
})

function LYDIAProcess() {
    this.configKey	= [
        'vendor_token',
        'amount',
        'recipient',
        'order_ref',
        'browser_success_url',
        'browser_cancel_url',
        'confirm_url',
        'sale_desc',
        'payer_desc',
        'collector_desc',
        'expire_time',
        'end_date',
        'provider_token',
        'payment_recipient',
        'notify_payer',
        'notify_collector',
        'display_conf',
        'payment_method',
        'env',
        'render'
    ];
    this.configToSkip = [
        'env',
        'render'
    ]
    this.data = {
        vendor_token		: "",
        amount				: "",
        recipient			: "",
        order_ref 			: "",
        browser_success_url : "",
        browser_cancel_url 	: "",
        confirm_url 		: "",
        sale_desc 			: "",
        payer_desc 			: "",
        collector_desc 		: "",
        expire_time 		: "",
        end_date 			: "",
        provider_token 		: "",
        payment_recipient	: "",
        notify_payer 		: "",
        notify_collector 	: "",
        display_conf 		: "",
        payment_method		: "auto",
        currency			: "EUR",
        type				: "phone"
    }
    this.baseUrl	= "https://lydia-app.com/";
}



class LydiaPayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            srcIframe: '',
        };
        this.LYDIASendRequest = this.LYDIASendRequest.bind(this);

    }
    componentDidMount() {

    }

    // sendRequest & get mobile url
    LYDIASendRequest(baseUrl, data) {
        window.open("https://homologation.lydia-app.com/collect/payment/6001a16b99089580656966/auto", "", "width=700,height=600")
        const formData = new FormData();
        Object.keys(data).forEach(function (k, v) {
            formData.append(k, v);
        });
        axios.post(baseUrl+"api/request/do.json", formData)
            .then(res => {
                if (res.data.error == 0) {
                    data = res.data;
                    window.open(data.mobile_url, "", "width=700,height=600")
                } else {
                    console.log(data);
                }
            })
            .catch(err => {
                console.log(err)
            });
    }


    payWithLYDIA(data) {
        let lydiaProcess = new LYDIAProcess();
        for (let i = 0; i < lydiaProcess.configKey.length; i++) {
            if (lydiaProcess.configToSkip.indexOf(lydiaProcess.configKey[i]) < 0 && data[lydiaProcess.configKey[i]] != undefined) {
                lydiaProcess.data[lydiaProcess.configKey[i]] = data[lydiaProcess.configKey[i]];
            }
        }
        if (process.env.NODE_ENV!=="production") {
            lydiaProcess.baseUrl	= "https://homologation.lydia-app.com/";
        }
        this.LYDIASendRequest(lydiaProcess.baseUrl, lydiaProcess.data);
    }

    createPaymentLydia() {
        const self = this;
        axios.get(BASE_URL + GET_SIGNATURE_LYDIA.replace("{id}", this.props.requestId))
            .then(res => {
                if (res.status === 200) {
                    this.payWithLYDIA(res.data)
                } else {
                    throw new Error();
                }
            })
            .catch(err => {
                self.setState({
                    errorPayment: true,
                    isLoadingSignature: false
                })
            })
    }

    render() {
        const {
            srcIframe
        } = this.state;
        const {
            classes
        } = this.props;
        return (
           <div className={classes.lydiaPaymentWrapper}>
               <Button
                    onClick={() => this.createPaymentLydia()}
                    className={classes.btnPayment}
               >
                   <img src={LydiaLogo} alt=""/>
               </Button>
           </div>
        );
    }
}


LydiaPayment.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
}
export default compose(
    withStyles(styles),
    withTranslation()
)(LydiaPayment);
