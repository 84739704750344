import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import {withRouter} from "react-router";
import ManagerCalendarPageLeft from "./ManagerCalendarPageLeft";
import ManagerCalendarPageRight, {
    CALENDAR,
    COMPANY_HOLIDAY,
    REST_DAY,
    REQUEST_TASK
} from "./ManagerCalendarPageRight";
import moment from "moment";
import {
    API_GET_PARAMETER,
    API_RESTDAY_REPAIRER_LIST,
    REQUEST_LIST
} from "../../../constants/apiSuffix";
import axios from "axios";
import qs from "qs";
import {HOLIDAY, SM, XS} from "../../../constants/constants";
import {ConvertToDateY_M_D} from "../../../functions/datetime";
import LoadingAction from "../../../theme/LoadingAction";

const styles = theme => ({
    managerCalendarPage: {
        background: '#f8f8ff',
        padding: '2rem',
        [theme.breakpoints.between(XS, SM)]: {
            padding: '1rem',
        },
    }
});

class ManagerCalenderPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateShowInCalendar: moment(),
            countValidateRestDay: {},
            dataResourceCalender: [],
            countDataHolidayByMonthYear: {},
            countRequestTask: {},
            arrayGroupsId: [],
            isLoading: false,
            forceUpdate: false,
            tabShow: CALENDAR
        };
        this.paramsName = "page_"+window.location.pathname.replace("/","_")+"_params";
        let pageInfos = localStorage.getItem(this.paramsName);
        if (!pageInfos){
            pageInfos = {};
        }
        else{
            pageInfos = JSON.parse(pageInfos)
        }
        if (pageInfos.hasOwnProperty("tabShow")){
            this.state.tabShow = pageInfos.tabShow;
        }
        if (pageInfos.hasOwnProperty("dateShowInCalendar")){
            this.state.dateShowInCalendar = moment(pageInfos.dateShowInCalendar).isValid() ? moment(pageInfos.dateShowInCalendar): moment();
        }
        this.getData = this.getData.bind(this);
    }

    setArrayGroupsId = (arrayValue) => {
        this.setState({
            arrayGroupsId: arrayValue
        })
    }

    setDateShowInCalender = (value) => {
        const yearOld = this.state.dateShowInCalendar.format("YYYY");
        const monthOld = this.state.dateShowInCalendar.format("MM");
        const yearNew = value.format("YYYY");
        const monthNew = value.format("MM");
        localStorage.setItem(this.paramsName,JSON.stringify({dateShowInCalendar: ConvertToDateY_M_D(value)}));
        if (yearOld !== yearNew || monthOld !== monthNew) {
            this.setState({
                isLoading: true
            },function () {
                this.getData(value);
            })
        } else {
            this.setState({
                dateShowInCalendar: value,
            })
        }
    }

    createJsonWithKeyIsDate = (jsonKeyIsDate, startDateDefault, endDateDefault, valueOfKeyDateDefault, returnArray) =>{
        let convertStartDate = ConvertToDateY_M_D(startDateDefault);
        let convertEndDate = ConvertToDateY_M_D(endDateDefault);
        if (convertEndDate === convertStartDate) {
            let dataConvertStartDate = [valueOfKeyDateDefault];
            if (jsonKeyIsDate.hasOwnProperty(convertStartDate)) {
                dataConvertStartDate = dataConvertStartDate.concat(jsonKeyIsDate[convertStartDate]);
            }
            if(returnArray){
                jsonKeyIsDate.push(valueOfKeyDateDefault)
            }else {
                jsonKeyIsDate = {
                    ...jsonKeyIsDate,
                    [convertStartDate]: dataConvertStartDate
                };
            }
        } else {
            if(moment(startDateDefault).valueOf() < moment(endDateDefault).valueOf()  ) {
                let startDateNew = startDateDefault;
                do {
                    let itemNewNew = {...valueOfKeyDateDefault};
                    let convertStartDateNew = ConvertToDateY_M_D(startDateNew);
                    itemNewNew.startDate = new Date(startDateNew);
                    itemNewNew.endDate = ((convertStartDateNew !== ConvertToDateY_M_D(endDateDefault)) ?
                        new Date(moment(startDateNew).format("YYYY-MM-DD 23:59:59")) : new Date(endDateDefault));
                    let dataConvertStartDateNew = []
                    if (returnArray) {
                        jsonKeyIsDate.push(itemNewNew)
                    } else {
                        if (jsonKeyIsDate.hasOwnProperty(convertStartDateNew)) {
                            dataConvertStartDateNew = [...jsonKeyIsDate[convertStartDateNew]];
                            dataConvertStartDateNew.push({...itemNewNew});
                        } else {
                            dataConvertStartDateNew = [{...itemNewNew}]
                        }
                        jsonKeyIsDate = {
                            ...jsonKeyIsDate,
                            [convertStartDateNew]: dataConvertStartDateNew
                        };
                    }
                    startDateNew = moment(startDateNew).add(1, 'days').format("YYYY-MM-DD 00:00:00")
                } while (moment(ConvertToDateY_M_D(startDateNew)).valueOf() <= moment(ConvertToDateY_M_D(endDateDefault)).valueOf());
            }
        }

        return jsonKeyIsDate;
    }

    getData(dateShowInCalendar) {

        const urlRestday = API_RESTDAY_REPAIRER_LIST + "?" + qs.stringify({
            maxPerPageAll: 1,
            monthYear: ConvertToDateY_M_D(dateShowInCalendar)
        })
        const urlRequest = REQUEST_LIST + "?" + qs.stringify({
            maxPerPageAll: 1,
            monthYearRegister: ConvertToDateY_M_D(dateShowInCalendar),
            existManager: 1
        })
        const urlHoliday = API_GET_PARAMETER + "?" + qs.stringify({
            code: HOLIDAY,
            monthYear: ConvertToDateY_M_D(dateShowInCalendar)
        })
        axios.all([
            axios.get(urlRequest),
            axios.get(urlRestday),
            axios.get(urlHoliday)
        ])
            .then(res => {
                if(res[0].status === 200  || res[1].status === 200 || res[2].status === 200) {
                    let dataRestdayByMonthYear = [];
                    let countValidateRestDay = {};
                    let requestTask = {};
                    let countDataHolidayByMonthYearParameter = {};
                    if (res[0].status === 200 && res[0].data.items && res[0].data.items.length) {
                        let dataItems0 = res[0].data.items;
                        // eslint-disable-next-line array-callback-return
                        dataItems0.map(item => {
                            let dataItem0 = {
                                text: item.categories && item.categories.length >0  && item.categories[0]? item.categories[0].name ?item.categories[0].name:"": "",
                                startDate: new Date(item.registrationStart),
                                endDate: new Date(item.registrationEnd),
                                userId: item.manager.id,
                                typeItem: REQUEST_TASK,
                                dataDefault: item
                            };
                            requestTask = this.createJsonWithKeyIsDate(requestTask, item.registrationStart, item.registrationEnd, dataItem0)
                        });
                    }


                    if (res[1].status === 200 && res[1].data.items && res[1].data.items.length) {
                        let dataItems = res[1].data.items;
                        // eslint-disable-next-line array-callback-return
                        dataItems.map(item => {
                            let dataItemNew = {
                                text: item.subject ?? "",
                                startDate: new Date(item.startRestAt),
                                endDate: new Date(item.endRestAt),
                                userId: item.account.id,
                                typeItem: REST_DAY,
                                dataDefault: item
                            };
                            // dataRestdayByMonthYear.push(dataItemNew)
                            countValidateRestDay = this.createJsonWithKeyIsDate(countValidateRestDay, item.startRestAt, item.endRestAt, dataItemNew)
                            dataRestdayByMonthYear = this.createJsonWithKeyIsDate(dataRestdayByMonthYear, item.startRestAt, item.endRestAt, dataItemNew, true)
                        });
                    }

                    if (res[2].status === 200 && res[2].data.items && res[2].data.items.length) {
                        let dataItems1 = res[2].data.items;
                        // eslint-disable-next-line array-callback-return
                        dataItems1.map((item, key) => {
                            let itemNew1 = {
                                text: item.description ?? "",
                                startDate: new Date(item.startDate),
                                endDate: new Date(item.endDate),
                                dataDefault: item,
                                typeItem: COMPANY_HOLIDAY
                            }
                            countDataHolidayByMonthYearParameter = this.createJsonWithKeyIsDate(countDataHolidayByMonthYearParameter, item.startDate, item.endDate, itemNew1)


                        });
                    }
                    // console.log("OK API");
                    this.setState({
                        countValidateRestDay: countValidateRestDay,
                        countDataHolidayByMonthYear: countDataHolidayByMonthYearParameter,
                        dateShowInCalendar: dateShowInCalendar,
                        dataResourceCalender: dataRestdayByMonthYear,
                        countRequestTask: requestTask,
                        isLoading: false,
                        forceUpdate: false
                    })
                } else {
                    throw new Error();
                }
            })
            .catch(err=>{
                // console.log(err.response)
                this.setState({
                    isLoading: false,
                    forceUpdate: false
                })
            })
    }

    componentDidMount() {
        this.setState({
            isLoading: true
        }, function () {
            // console.log("OK");
            this.getData(this.state.dateShowInCalendar)
        })
    }

    setTabShow = (value) => {
        localStorage.setItem(this.paramsName,JSON.stringify({tabShow: value}));
        this.setState({
            tabShow: value
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.forceUpdate){
            this.setState({
                forceUpdate: false,
                isLoading: true
            }, function () {
                this.getData(this.state.dateShowInCalendar)
            })
        }
    }

    setForceUpdate = (value) =>{
        this.setState({
            forceUpdate: value,
        })
    }

    render() {
        const {
            classes
        } = this.props;
        const {
            dateShowInCalendar,
            isLoading,
            dataResourceCalender,
            countValidateRestDay,
            countDataHolidayByMonthYear,
            arrayGroupsId,
            countRequestTask
        } = this.state;
        return (
            <div className={classes.managerCalendarPage}>
                {isLoading ? <LoadingAction/> :
                    <Grid container className={"contentInformationPage"}>
                        <Grid container item md={3}>
                            <ManagerCalendarPageLeft setDateShowInCalender={this.setDateShowInCalender}
                                                     countDataHolidayByMonthYear={countDataHolidayByMonthYear}
                                                     dateShowInCalendar={dateShowInCalendar}
                                                     countValidateRestDay={countValidateRestDay}
                                                     countRequestTask={countRequestTask}
                            />
                        </Grid>
                        <Grid container item md={9} className={"contentPage"}>
                            <ManagerCalendarPageRight arrayGroupsId={arrayGroupsId}
                                                      setArrayGroupsId={this.setArrayGroupsId}
                                                      countDataHolidayByMonthYear={countDataHolidayByMonthYear}
                                                      isLoading={isLoading}
                                                      dateShowInCalendar={dateShowInCalendar}
                                                      countValidateRestDay={countValidateRestDay}
                                                      countRequestTask={countRequestTask}
                                                      tabShow={this.state.tabShow}
                                                      setTabShow={this.setTabShow}
                                                      setForceUpdate={this.setForceUpdate}
                                                      dataResourceCalender={dataResourceCalender}/>
                        </Grid>

                    </Grid>
                }
            </div>
        )
    }

}

ManagerCalenderPage.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(
withStyles(styles),
withTranslation(),
withRouter
)(ManagerCalenderPage);
