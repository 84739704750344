import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Edit,
    SimpleForm,
    EditButton,
    TextInput,
    Create,
    DatagridBody,
    useRecordContext
    // required
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import Pagination from "../Pagination";

import { withStyles } from '@material-ui/core/styles';
import {styleCreate, styleEdit, styleList} from "../style";
import {MyDatagridRow} from "../MyDatagridRow";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import ListEmpty from "./ListEmpty";
import {makeStyles} from "@material-ui/core";
const styles = {
    body:{
        maxHeight: "5rem",
        overflow: "hidden",
        display: "block"
    }
};

const useStyles = makeStyles(styles);

const BodyField = (props)=>{
    const classes = useStyles();
    return <TextField className={classes.body} dangerouslySetInnerHTML={{__html:props.record.body}}/>;
}

const properties = [
    {
        source: "name",
        label: "reactAdmin.smsType.name"
    },
    {
        source: "content",
        label: "reactAdmin.smsType.content"
    },
];

export const SmsTypeList = compose(withStyles(styleList), withTranslation())(({classes, t, ...props}) => {
    const {tReady, ...newProps} = props
    return (
        <List {...newProps} empty={<ListEmpty arrayColumn={properties} titleList={""}/>}
              actions={null}
            // filters={<CustomFilters classes={classes} t={t} titleList={LABEL_MAIL_TYPE}/>}
              pagination={<Pagination {...props}/>} className={classes.listWrapper}>
            <Datagrid  className={classes.tableWrapper}>
                {properties.map((item, key) => {
                        if (item.source === "body") {
                            return <BodyField/>;
                        }
                        return <TextField source={item.source} label={t(item.label)} sortable={false} key={key}/>;
                    }
                )}
                <EditButton className={classes.editButton}/>
            </Datagrid>
        </List>
    )
});


