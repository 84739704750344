import React from "react";
import UserForm from "./UserForm";

class UserNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }



    render() {
        return (
           <div>
               <UserForm/>
           </div>
        )
    }
}

UserNew.propTypes = {
};

export default UserNew;
