import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import {FORM_CONTROL} from "../../constants/constants";

const styles = theme => ({
    customInput: {
        border: '1px solid #E0E0E0',
        borderRadius: 6,
        '& input': {
            border: 'none',
            height: 23,
            padding: '8px 10px',
            fontSize: '0.875rem',
            // marginBottom: '0.2rem'
            borderRadius: 6,
        },
        '& fieldset':{
            border: 'none'
        },
        '&.validateStyle': {
            border: '1px solid #ff0000'
        },
        '&.inputDetail':{
            '& .Mui-disabled':{
                color: 'inherit'
            }
        }
    },
    customInput2: {
        '& input': {
            padding: '0.7rem 1rem'
        },
        '& textarea': {
            height: 'auto !important'
        }
    },
    validateInput: {
            borderColor: '#ff0000 !important'
    }
});

class CustomInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            smallButtonProps: {
                size: 'small',
                variant: 'contained'
            },
            largeButtonProps: {
                size: 'large',
                variant: 'contained'
            }
        }
    }
    render() {
        const {classes, value, ref, name, onChange, className, typeInput, validate, multiline, rowsMax, rows, placeholder, disabled, onKeyPress} = this.props;
        return (
            <React.Fragment>
                <FormControl fullWidth className={`${classes.customInput} ${this.props.className ? this.props.className : ''} ${validate ? "validateStyle" : ''} ${className}`}>
                {typeInput === FORM_CONTROL ?
                        <Input
                            id={name + '-' + value}
                            value={value}
                            onChange={onChange}
                            name={name}
                            placeholder={placeholder?placeholder:""}
                            onKeyPress={onKeyPress}
                            disabled={disabled}
                            type={this.props.type ?? "text"}
                            ref={ref}
                        />
                    :
                    <TextField
                        multiline={multiline? true: false}
                        // classes ={(validate ? classes.validateInput : {})}
                        // className={`${classes.customInput2} ${this.props.className ? this.props.className : ''} ${(validate ? classes.validateInput : '')} hhhhh`}
                        onChange={onChange}
                        variant={this.props.variant ? this.props.variant : "outlined"}
                        type={this.props.type ?? "text"}
                        name={name}
                        value={value}
                        fullWidth
                        rowsMax={rowsMax?rowsMax:1}
                        rows={rows?rows:1}
                        placeholder={placeholder?placeholder:""}
                        InputProps={this.props.InputProps?this.props.InputProps: null}
                        disabled={disabled}
                        onKeyPress={onKeyPress}
                        ref={ref}
                    />
                }
                </FormControl>
            </React.Fragment>
        )
    }
}

CustomInput.defaultProps = {
    onClick: () => {},
    name: '',
    value: '',
    onChange: () => {},
    className: '',
    validate: '',
    placeholder: '',
    onKeyPress: () => {},
    disable: false
}

CustomInput.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func,
    type: PropTypes.string,
    variant: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    inputProps: PropTypes.object,
    typeInput: PropTypes.string,
    validate: PropTypes.string,
    placeholder: PropTypes.string,
    disable: PropTypes.bool
}

export default compose(
    withStyles(styles),
    withTranslation()
)(CustomInput);
