import axios from "axios";
// import * as types from "../../_constants/auth";
import * as links from "../../constants/links";
// import {Redirect} from "react-router-dom";
// import React from "react";
import {DATA_USER} from "../../constants/constants";

const authProvider = {
    login: async ({username, password}) => {
        const dataUser = {
            '_username': username,
            '_password': password
        };
        const response = await axios.post('/api/login', dataUser);
        if (response.status===200){
            localStorage.setItem(response.data)
        }
        return Promise.resolve();
    },

    logout: async () => {
        // const response = await axios.get(links.LOGOUT);
        // localStorage.removeItem(DATA_USER);
        // window.location.href = links.LOGIN;
        // return Promise.reject();
        //  <Redirect to={}/>;
    },

    checkError: ({status}) => {
        if (status === 401) {
            localStorage.removeItem(DATA_USER);
            window.location.href = links.LOGIN;
            return Promise.reject();
        }else if(status === 403){
            window.location.href = links.PAGE403;
        }
        return Promise.resolve();
    },

    checkAuth: () => {
        return Promise.resolve();
    },

    getPermissions: () => Promise.resolve(),
}

export default authProvider;

// export default {
//     login: async ({username, password}) => {
//         const dataUser = {
//             '_username': username,
//             '_password': password
//         };
//         const response = await axios.post('/api/login', dataUser);
//         if (response.status===200){
//             localStorage.setItem(response.data)
//         }
//         return Promise.resolve();
//     },

//     logout: async () => {
//         // const response = await axios.get(links.LOGOUT);
//         // localStorage.removeItem(DATA_USER);
//         // window.location.href = links.LOGIN;
//         // return Promise.reject();
//         //  <Redirect to={}/>;
//     },

//     checkError: ({status}) => {
//         if (status === 401) {
//             localStorage.removeItem(DATA_USER);
//             window.location.href = links.LOGIN;
//             return Promise.reject();
//         }else if(status === 403){
//             window.location.href = links.PAGE403;
//         }
//         return Promise.resolve();
//     },

//     checkAuth: () => {
//         return Promise.resolve();
//     },

//     getPermissions: () => Promise.resolve(),
// }

