import React from 'react'
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import * as applicationAction from "../../../../_actions/application";
import qs from "qs"
import { makeStyles } from "@material-ui/core/styles";
import {
    ADD_PARAMS_FILTERS_LIST,
    REMOVE_PARAMS_FILTERS_LIST,
    STATUS_ALL_LIST,
    USER_TYPE_CLIENT
} from '../../../../constants/constants';
import documentIcon from "../../../../images/request/Groupe 9102.svg"
import CustomSvg from "../../../../theme/CustomElements/CustomSvg";
import {PARAM_REQUEST_LIST_LOCALSTORAGE} from "../RequestPage";
const useStyles = makeStyles(theme => ({
    '@keyframes blinker': {
        from: {opacity: 0.2},
        '20%': {opacity: 1},
        '80%': {opacity: 1},
        to: {opacity: 0.2}
    },
    documentNewFilter: {
        color: '#ff3a7b',
        cursor: 'pointer',
        borderRadius: 25,
        padding: '4px 4px 4px 20px',
        width: 'fit-content',
        lineHeight: 1,
        fontWeight: 'bold',
        // animation: '$blinker 1s linear infinite',
        display: 'flex',
        alignItems: 'center',
        transition:"0.1s ease",
        position: 'relative',
        marginBottom: 4,
        marginTop: 4,
        '& svg':{
            // marginLeft: 8,
            marginTop: 8,
            '& path':{
                fill: '#fff !important'
            }
        },
        '&.checkedDiv': {
            background: '#7b60ff3d',
            pointerEvents: 'none',
            minWidth: 100
        },
        '&:hover:not(.checkedDiv)': {
            // background: 'rgba(0,0,0,0.1) !important',
            background: 'transparent !important',
            // transform: 'scale(1.1)',
            transition:"0.1s ease",
        },


        '& .numberCircle':{
            background: '#ff0077',
            borderRadius: '50%',
            width: 25,
            height: 25,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 13,
            color: '#fff',
            position: 'absolute',
            zIndex: '1',
            top: '3px',
            left: '32px',
            transition: '0.2s ease',
            border: '1px solid #192f8a',

            '&~svg':{
                '& path':{
                    fill: '#bcc9ff'
                }
            },


        },

        '&:hover':{
            '& .numberCircle':{
                transition: '0.2s ease',
                left: '34px',
                '&~ svg path':{
                    fill: '#ff0077 !important'
                }
            },
        },

        '&.notAlertNew':{
            animation: 'none',
            background: 'transparent',
            pointerEvents:'none',

            '& .numberCircle':{
                background: '#94a3ff4d',
                color: '#1d3694',
                display: 'none',
            },

            '& svg':{
                '& path':{
                    fill: '#4156b2 !important'
                }
            },

        },
        '&:not(.notAlertNew, .checkedDiv)':{
            '&:hover':{
                background: '#7b60ff3d',
                transition:"0.1s ease",
            },
        },
        '& .closeFilterWrapper':{
            color: 'rgba(255, 255, 255, 0.5)',
            fontSize: '21px',
            lineHeight: '1',
            marginLeft: '5px',
            fontWeight: '300',
            padding: '2px 12px',
            pointerEvents: 'initial !important',

            '&:hover': {
                color: 'rgba(255, 255, 255, 0.9)',
            },
        }
    },

}));

function AlertCountDocumentManagerNotSeen({ location, requestDocumentManagerNotSeen,dataUser, t,apiParamsList, ...propsData }) {
    const classes = useStyles();
    const groupUser = dataUser && dataUser.data ? dataUser.data.group : "";
    const fieldFilter = (groupUser === USER_TYPE_CLIENT) ? "clientSawLastDocumentManagerUpload" : "managerSawLastDocumentClientUpload";
    let urlParams = qs.parse(location.search, {ignoreQueryPrefix: true});
    const enableFilterDocumentCount = urlParams[fieldFilter] !== undefined && urlParams[fieldFilter] === "false";
    const actionFilter = () => {
        if (enableFilterDocumentCount) {
            delete urlParams[fieldFilter]
        } else {
            urlParams = {...urlParams, [fieldFilter]: "false"}
            if(propsData?.changeStatusToAllFilter && typeof propsData.changeStatusToAllFilter === "function") {
                propsData.changeStatusToAllFilter()
            }
        }
        let pageInfos = localStorage.getItem(PARAM_REQUEST_LIST_LOCALSTORAGE);
        pageInfos = !pageInfos ? {} : JSON.parse(pageInfos);
        const filterListLocal = pageInfos["filters"] ? pageInfos["filters"]: {}
        pageInfos = {
            ...pageInfos, filters: { ...filterListLocal,
                statusCode: STATUS_ALL_LIST
            }
        }
        localStorage.setItem(PARAM_REQUEST_LIST_LOCALSTORAGE, JSON.stringify(pageInfos));
        propsData.setForceUpdateListChangeApiParamsRedux(true)
        propsData.history.push(location.pathname + '?' + qs.stringify(urlParams));
        const params = {...apiParamsList,...urlParams}
        if (enableFilterDocumentCount) {
            delete (params[fieldFilter])
        }
        propsData.getCountRequestByStatus(params);
    }

    return (
        <div className={`${classes.documentNewFilter} ${!Array.isArray(requestDocumentManagerNotSeen) || requestDocumentManagerNotSeen.length < 1 ? "notAlertNew":""} ${enableFilterDocumentCount ? "checkedDiv" : ""}`}
             onClick={() => {if(!enableFilterDocumentCount){actionFilter()}}}>
            <div className={"numberCircle"}>{requestDocumentManagerNotSeen.length < 100 ?requestDocumentManagerNotSeen.length: "99+"}</div>
            <CustomSvg
                svgProps={{
                    src: documentIcon,
                    title:t("request.documentNotSeen")
                }}
            />
            {/*{t("request.documentNotSeen")}*/}
            {enableFilterDocumentCount && <div className={"closeFilterWrapper"} onClick={() => actionFilter()}>
                &#x00d7;
            </div>}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        requestDocumentManagerNotSeen: state.applicationReducer.requestDocumentManagerNotSeen,
        dataUser: state.authReducer.dataUser
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setForceUpdateListChangeApiParamsRedux: (value) => dispatch(applicationAction.setForceUpdateListChangeApiParamsRedux(value)),
        getCountRequestByStatus: (apiParamCount) => dispatch(applicationAction.getCountRequestByStatus(apiParamCount)),
    }
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation(), withRouter)(AlertCountDocumentManagerNotSeen)
