import * as types from "./../_constants/auth";
import {DATA_USER} from "../constants/constants";
import {PARAM_REQUEST_LIST_LOCALSTORAGE} from "../components/client/request/RequestPage";

let dataUser = null;
let dataLogin = null;


if (localStorage.getItem(DATA_USER) !== null) {
    try {
        if (JSON.parse(localStorage.getItem(DATA_USER)).token) {
            dataUser = JSON.parse(localStorage.getItem(DATA_USER));
        } else {
            localStorage.removeItem(DATA_USER);
        }
    } catch(e) {
        localStorage.removeItem(DATA_USER);
    }
}
if (localStorage.getItem("dataLogin") !== null) {
    dataLogin = JSON.parse(localStorage.getItem("dataLogin"));
}
const initState = {
    dataUser: dataUser,
    dataLogin: dataLogin,
    canAccessPage: true,
    beBadRequest: false,
    beServerError: false,
    successRequest: false,
    checkCreateNewClient: false,
    typeFormAuth: null,
    checkSwitch: null,
    changePasswordSuccess: false,
    groupBaseUser: ""
};

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case types.AUTH_DATA_USER:
            if (action.dataUser !== null && action.dataUser.token) {
                const showLocalStorage = {
                    token: action.dataUser.token,
                    // refresh_token : action.dataUser.refresh_token,
                    data: {
                        group: action.dataUser.data ? action.dataUser.data.group : "",
                        timezoneDefault: action.dataUser.data ? action.dataUser.data.timezoneDefault : "",
                        ...action.dataUser.data
                    }
                }
                localStorage.setItem(DATA_USER, JSON.stringify(showLocalStorage));
                // localStorage.setItem(DATA_USER, JSON.stringify(action.dataUser));
            } else {
                localStorage.removeItem(DATA_USER);
            }
            return {
                ...state,
                dataUser: action.dataUser,
                groupBaseUser: action?.dataUser?.data?.group ? action.dataUser.data.group : ""
            };
        case types.AUTH_DATA_LOGIN:
            if (action.dataLogin !== null) {
                localStorage.setItem("dataLogin", JSON.stringify(action.dataLogin));
            } else {
                localStorage.removeItem("dataLogin");
            }
            return {
                ...state,
                dataLogin: action.dataLogin
            };
        case "LOGOUT":
            localStorage.removeItem(DATA_USER);
            localStorage.removeItem(PARAM_REQUEST_LIST_LOCALSTORAGE);
            return {
                ...state,
                dataUser: null,
                groupBaseUser:""
            };

        case"ACCESS_DENY":
            return {
                ...state,
                canAccessPage: false
            };

        case "CHANGE_URL":
            return {
                ...state,
                canAccessPage: true,
                beBadRequest: false,
                beServerError: false
            };

        case "BAD_REQUEST":
            return {
                ...state,
                beBadRequest: true
            };
        case "SERVER_ERROR":
            return {
                ...state,
                beServerError: true
            };
        case types.REQUEST_RESET_PASSWORD:
            return {
                ...state,
                successRequest: action.successRequest
            }
        case types.CHECK_CREATE_NEW_CLIENT:
            return {
                ...state,
                checkCreateNewClient: action.checkCreateNewClient
            }
        case types.SAVE_TYPE_FORM:
            return {
                ...state,
                typeFormAuth: action.typeFormAuth
            }
        case types.CHECK_SWITCH_AUTH_FORM:
            return {
                ...state,
                checkSwitch: action.checkSwitch
            }
        case types.CHECK_CHANGE_PASSWORD:
            return {
                ...state,
                changePasswordSuccess: action.changePasswordSuccess
            }
        default:
            return state;
    }
};

export default authReducer;
