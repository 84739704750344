import React, {useEffect, useState} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {Tooltip, withWidth} from "@material-ui/core";
import {listStatusClient} from "../client/request/constantRequestPage";
import {SM, USER_TYPE_ADMIN, USER_TYPE_CLIENT, USER_TYPE_SUPER_ADMIN, XS} from "../../constants/constants";
import CustomInlineSvg from "../../theme/CustomElements/CustomInlineSvg";
import assignedActive from "../../images/request/requestAdmin/assignedActive.svg";
import unassignedActive from "../../images/request/requestAdmin/unassignedActive.svg";
import moment from "moment";
import {returnStatusInColumnToggle} from "../client/request/RequestPage";
import parse from "html-react-parser";
import {NUNITO} from "../../constants/fontCss";

const styles = (theme) => ({
    round: {
        width: '100%',
        padding: '20px 30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        border: '1px dotted #2b80af',
        borderRadius: 20,
        boxShadow: '0 2px 1px 0 rgb(56 86 130 / 11%)',
        [theme.breakpoints.between(XS, SM)]: {
            padding: '13px 20px',
            justifyContent: 'flex-start',
            '& .status':{
                width: 50,
                padding: '0 !important'
            },
            '& .borderLeft':{
                height: 'fit-content  !important'
            },
            '& .createdAt':{
                display: 'flex',
                flexWrap: 'wrap',
                '& > div:first-child':{
                    paddingRight: '10px'
                }
            },
            '& > div': {
                border: 'none !important',
            }
        },
        '& .problem': {
            color: '#605e72',
            fontFamily: NUNITO,
            fontWeight: 'bold',
            fontSize: '1.1rem',
            paddingRight: 20,
            flex: 1,
            lineHeight: 1.2,
            overflow: 'hidden',
            height: 60,
            '& p:first-child, li:first-child': {
                display: 'block'
            },
            '& p, li': {
                display: 'none',
                paddingRight: "40px",
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap!important',
                [theme.breakpoints.down(XS)]: {
                    paddingRight: "0px",
                    whiteSpace: 'initial !important',
                    lineHeight: '17px',
                },
            },
            [theme.breakpoints.down(XS)]: {
                fontSize: '0.875rem',
                '& p, & ol': {
                    margin: 0,
                    padding: 0
                }
            }
        },
        '& .borderLeft': {
            borderLeft: 'solid 1px #a2c5d9',
            padding: '0 20px',
            height: 40,
        },
        '& .status': {
            display: 'flex',
            alignItems: 'center',
            '& .paidShow': {
                marginLeft: 10,
                padding: '0.5rem',
                lineHeight: 1,
                fontSize: '0.875rem',
                color: '#fff',
                borderRadius: 10,
                justifyContent: 'flex-start',
                alignItems: 'center',
                display: 'flex',
                maxWidth: 90,
                overflow: 'hidden'
            },
            '& .notPaid': {
                background: '#e33838',
                height: 33
            },
            '& .paid': {
                background: '#13be2a'
            },
            '& img': {
                height: 30,
                width: 'auto',
            },
            '& .repairer': {
                display: 'flex',
                alignItems: 'center',
                marginLeft: 10
            }
        },
        '& .createdAt, .id': {
            color: '#605e72',
            fontFamily: NUNITO,
            fontWeight: 500,
            fontSize: '0.9rem',
            textAlign: 'right'
        },
        '& .contentRightMobileWrapper':{
            width: 'calc( 100% - 50px)',
            '& > div':{
                textAlign: 'left',
                border: 'none !important',
                padding: 0
            }
        }
    },

});
const HeaderChat = (props) => {
    const {
        dataRequest,
        groupBaseUser,
        t,
        classes,
        width
    } = props;

    const paid = dataRequest.payment ? dataRequest.payment.paid : false
    const repairer = dataRequest.manager ? dataRequest.manager.id : null;
    const problemTitle = parse(dataRequest.subject ? dataRequest.subject : (dataRequest.problem ?? ""));
    const statusDefault = returnStatusInColumnToggle(dataRequest);

    const renderShowStatusIcon = () => {
        return !!(dataRequest?.status) ? <div className="borderLeft status">
            {
                listStatusClient.hasOwnProperty(statusDefault) ?
                    <div className={classes.statusWrapperGrid}><img src={listStatusClient[statusDefault].icon}
                                                                    className="" alt={""}/></div> : ""
            }
            {
                [USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN].includes(groupBaseUser) && paid &&
                <span className={'repairer'}>
                                <CustomInlineSvg
                                    svgProps={{
                                        src: repairer ? assignedActive : unassignedActive
                                    }}
                                    scale='1'
                                />
                            </span>
            }
            {dataRequest.payment && groupBaseUser !== USER_TYPE_CLIENT && <Tooltip
                title={
                    <React.Fragment>
                        {new Intl.NumberFormat(dataRequest.payment.currency, {
                            style: 'currency',
                            currency: dataRequest.payment.currency
                        }).format(dataRequest.payment.price)}
                    </React.Fragment>
                }
                enterDelay={100}
                leaveDelay={200}
                arrow
                interactive
                placement={"top"}
            >
                <div
                    className={`paidShow ${dataRequest.payment.paid ? "paid" : "notPaid"}  paidWrapper`}>{new Intl.NumberFormat(dataRequest.payment.currency, {
                    style: 'currency',
                    currency: dataRequest.payment.currency
                }).format(dataRequest.payment.price)}</div>
            </Tooltip>}
        </div> : ""
    }

    const renderShowCreateAt = () => {
        return !!(dataRequest?.createdDate) ? <div className="borderLeft createdAt">
            <div>
                {t("chat.chatBox.createdAt")} {moment(dataRequest.createdDate).format('L')}
            </div>
            <div>
                {t("chat.chatBox.to")} {moment(dataRequest.createdDate).format('LT')}
            </div>
        </div> : ""
    }

    const renderShowRequestId = () => {
        return !!(dataRequest?.id) ? <div className="borderLeft id">
            {t("chat.chatBox.no")} {dataRequest.id}
        </div> : ""
    }
    return ([XS, SM].includes(width) ?
            <div className={`${classes.round} round`}>
                {renderShowStatusIcon()}
                <div className={"contentRightMobileWrapper"}>
                    {renderShowRequestId()}
                    <Tooltip title={problemTitle}>
                        <div className="problem">
                            {problemTitle}
                        </div>
                    </Tooltip>
                    {renderShowCreateAt()}
                </div>
            </div>
            :
            <div className={`${classes.round} round`}>
                <Tooltip title={problemTitle}>
                    <div className="problem">
                        {problemTitle}
                    </div>
                </Tooltip>
                {renderShowStatusIcon()}
                {renderShowRequestId()}
                {renderShowCreateAt()}
            </div>
    )
}

const mapStateToProps = (state) => {
    return {
        groupBaseUser: state.authReducer.groupBaseUser,

    }
};
export default compose(connect(mapStateToProps, null), withStyles(styles),
    withTranslation(),
    withRouter,
    withWidth()
)(HeaderChat);