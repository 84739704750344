export const CREATE_CLIENT = "/api/create/client";
export const REQUEST_LIST = "/api/list/request";
export const API_CREATE_REQUEST = "/api/create/request";
export const API_SHOW_REQUEST_INITIAL = "/api/show/request-initial";
export const API_EDIT_SUBJECT_REQUEST = "/api/edit-request-subject/{id}";
export const API_LOGIN = "/api/login";
export const API_GET_SHOW_REQUEST = "/api/show/request/:id";
export const API_EDIT_REQUEST_BY_TOKEN = "/api/edit/request-with-token/{id}";
export const API_GET_REQUEST_BY_TOKEN = "/api/show/request-with-token/{id}";
export const API_GET_SHOW_REQUEST_UPLOAD_FILE_TOKEN =
  "/api/request-by-upload-file-token/show";
export const API_CREATE_REQUEST_UPLOAD_FILE_TOKEN =
  "/api/request-upload-token/{id}";
export const API_SEND_SMS_UPLOAD_FILE_REQUEST = "/api/request/{id}/send-sms";

export const FORGOT_PASSWORD = "/api/forgot-password";
export const CREATE_NEW_PASSWORD = "/api/change-password/";
export const CREATE_REQUEST = "/api/create/request";
export const API_ADD_NOTE_REQUEST = "/api/add/request-note/{requestId}";
export const API_CHANGE_STATUS_REQUEST = "/api/add/request-status/{id}";
export const REQUEST_NOT_LOGIN = "/api/create/request-not-login";
export const API_EDIT_REQUEST = "/api/edit/request/{id}";
export const API_SHOW_USER = "/api/show/user/{id}";
export const API_CREATE_USER = "/api/create/user";
export const API_EDIT_USER = "/api/edit-user/{id}";
export const API_LOGIN_SOCIAL = "/api/login/social";
export const API_GET_USER_INFOS = "/api/user/infos";

export const API_RESTDAY_REPAIRER_LIST = "/api/restday/list";
export const API_RESTDAY_REPAIRER_CREATE = "/api/create/restday";
export const API_RESTDAY_REPAIRER_EDIT = "/api/restday/list";
export const API_ADD_ASSIGN_REPAIRER = "/api/add-repairer/request/{id}";

export const API_GET_CATEGORY = "/api/category/list";
export const API_EDIT_REQUEST_CATEGORIES = "/api/edit/request/{id}/categories";
export const API_ACTION_CATEGORY = "/api/category/action";

export const API_COUNT_REQUEST_BY_STATUS = "/api/count/request/by/status";
export const API_GET_PARAMETER = "/api/list/parameter";
export const API_SHOW_PARAMETER = "/api/parameters/{id}";
export const API_CREATE_PARAMETER = "/api/create/parameter";
export const API_EDIT_PARAMETER = "/api/edit/parameter/{id}";
export const API_GET_PARAMETER_CURRENCY = "/api/list/parameter/currency";
export const API_GET_MAIL_TYPE = "/api/mail_type/{id}";
export const API_DELETE_PARAMETER = "/api/parameter/{id}/delete";

export const API_EDIT_MAIL_TYPE = "/api/mail_type/{id}";

export const API_GET_SMS_TYPE = "/api/sms_types/{id}";
export const API_PUT_SMS_TYPE = "/api/sms_types/{id}";

export const API_GET_STATUS_TYPE = "/api/request_status_types";
export const API_ENABLE_USER = "/api/update/user-enabled/:tokenEnabled";

export const API_DOCUMENT_REQUEST_VALID = "/api/list/document-request-valid";

export const API_CHECK_TOKEN = "/api/check-token/{token}";
export const API_GET_USER_LIST = "/api/list/user";

export const SHOW_TIME_REGISTRATION = "/api/show-time-registration/request";
export const SHOW_TIME_REGISTRATION_NOT_LOGIN =
  "/api/show-time-registration/request-not-login";
export const API_REGISTRATION_TIME = "/api/registration-time/request/{id}";
export const API_REGISTRATION_TIME_NOT_LOGIN =
  "/api/registration-time/request-not-login/{id} ";
export const API_CHANGE_REGISTRATION_TIME_NOT_LOGIN =
  "/api/change-registration-time-request-not-auth/{id} ";

export const CHECK_TOKEN_AUTH = "/api/check-token-auth";
export const API_PAYMENT_REQUEST = "/api/payment/request/{id}";
export const CHECK_PAYMENT_SOGE_COMMERCE = "/check-payment-soge-commerce/{id}";
export const CHECK_PAYMENT_LYDIA = "/check-payment-lydia/{id}";
export const GET_SIGNATURE_SOGE_COMMERCE =
  "/api/get-signature-sogecommerce/{id}";
export const GET_SIGNATURE_LYDIA = "/api/get-signature-lydia/{id}";
export const GET_LEASE_TYPE_LIST = "/api/lease/type/list";

export const SHOW_PARAMETER_CONTACT = "/api/show/parameter/contact";

export const GET_REQUEST_DOCUMENT_TYPE_LIST = "/api/request_document_type/list";
export const GET_VIDEO_TYPE_LIST = "/api/video/type/list";
export const UPLOAD_REQUEST_DOCUMENT = "/api/upload_request_document/{id}";
export const REQUEST_DOCUMENT_LIST = "/api/request_document/list";
export const REQUEST_DOCUMENT_LIST_BY_TOKEN =
  "/api/request_document/list/by-token";
export const REQUEST_DOCUMENT_EDIT = "/api/request_document/{id}/edit";
export const REQUEST_DOCUMENT_DELETE = "/api/request_document/{id}/delete";
export const REQUEST_DOCUMENT_CLIENT_NOT_SEEN_LIST =
  "/api/request_document/client-not-seen-list";
export const VIDEO_TYPE_LIST = "/api/video/type/list";

export const PAGE_DOWNLOAD_REQUEST_DOCUMENT = "/download_request_document";
export const NEW_DOC_MAIL_CUSTOMER = "/api/new_doc/mail_to_customer/{id}";
export const CANCEL_DATE_REQUEST = "/api/cancel/registration_time_request/{id}";
export const CANCEL_DATE_REQUEST_NOT_AUTH =
  "/api/cancel/registration_time_request_not_auth/{id}";

export const GET_DATA_FILTER_DIAGNOSTIC = "/api/intervention/list";
export const GET_SUPPLIES_LIST = "/api/supplies/list";
export const GET_SMALL_SUPPLIES_LIST = "/api/small_supplies/list";
export const GET_LARGE_SUPPLIES_LIST = "/api/large_supplies/list";
export const GET_TOOLS_LIST = "/api/tools/list";
export const CREATE_REPORT = "/api/report/create/:id";
export const LIST_REPORT = "/api/report/list";
export const API_GET_REQUEST_REPORT_LIST = "/api/request/report/list";
export const API_GET_REQUEST_CHAT_NOT_SEEN = "/api/request/chat-not-seen";
export const API_GET_REQUEST_DOCUMENT_NOT_SEEN =
  "/api/request/document-not-seen";
export const API_GET_LIST_DOCUMENT_CLIENT_CREATE_NOT_SEEN_BY_REQUEST =
  "/api/request/{id}/document-not-seen";
export const API_UPDATE_LAST_VIEW_DOCUMENT_BY_TYPE =
  "/api/request/{id}/update-last-view-document";
export const API_REQUEST_REPORT_CREATE = "/api/request/report/create";
export const API_REQUEST_REPORT_DELETE = "/api/request/report/{id}/delete";
export const CHANGE_REPORT_STATUS = "/api/change/report-status/{id}";
export const ROLLBACK_REPORT_STATUS_PDF =
  "/api/rollback-report-status-pdf/{id}";
export const EDIT_REPORT = "/api/report/edit/{id}";
export const SEND_REPORT_TO_CLIENT = "/api/send/report-to-client/{id}";
export const GET_SIGNATURE_SOGE_COMMERCE_REPORT =
  "/api/get-signature-soge-commerce-report";
export const GET_SIGNATURE_SOGE_COMMERCE_REPORT_NOT_LOGIN =
  "/api/get-signature-soge-commerce-report-not-login";
export const API_PAYMENT_REPORT = "/api/payment/report";
export const CHANGE_ARCHIVE_REPEAT_REQUEST =
  "/api/change-archive-repeat-request/{id}";
export const INTERVENTION_IMPORT = "/api/intervention/import";
export const API_ADD_CHAT = "/api/add/request-chat/{requestId}";
export const API_LIST_CHAT = "api/list/chat";
export const GET_SUB_DOCUMENT_TYPE_LIST = "/api/sub_document_type/list";
export const GET_SUB_DOCUMENT_TYPE_CREATE = "/api/sub_document_type/create";
export const API_SUB_DOCUMENT_TYPE_RESOURCE = "sub_document_type";
export const API_MAIL_LIST = "/api/mail/list";
export const API_SEND_MAIL = "/api/send/mail/{id}";
export const API_UPDATE_LAST_VIEW_CHAT =
  "/api/request/{id}/update-last-view-chat";

export const API_SHARE_DOCUMENTS_CREATE =
  "/api/request/{id}/share-documents-create";
export const API_SHARE_DOCUMENTS = "/api/request/{id}/share-documents";
export const API_SHARE_DOCUMENTS_BY_TOKEN = "/api/share/{token}";
export const API_ADD_REPORT_FOR_REQUEST_WITH_TOKEN =
  "/api/add-report-with-token/{token}";

export const API_CREATE_GENERAL_DOCUMENT = "/api/create/general-document";
export const API_LIST_GENERAL_DOCUMENT = "/api/general_documents";
export const API_SHOW_GENERAL_DOCUMENT = "/api/general_documents/{id}";
export const API_EDIT_GENERAL_DOCUMENT = "/api/edit/general-document/{id}";
export const API_CHANGE_VERSION_DOCUMENT = "/api/change/version-document/{id}";
export const API_SHOW_REPORT_WITH_TOKEN = "/api/show-report-with-token/{token}";
export const API_DELETE_GENERAL_DOCUMENT = "/api/general_documents/{id}";
export const API_PAYMENT_REQUEST_REPORT_SEEN =
  "/api/payment-request-report/seen";
export const API_PAYMENT_REQUEST_REPORT_ADMIN_SEEN =
  "/api/payment-request-report/{requestId}/admin-seen";

export const API_CREATE_MEETING_ID = "/api/request/{requestId}/meeting-call";
export const API_END_VIDEO_CALL = "/api/end-video-call/{meetId}";

export const API_REQUEST_CLEAR_FILTER = "/api/request/clear-filter";

export const API_GET_PARAMETER_SHARE_DOCUMENT_EXPIRE_NUMBER_DAY =
  "/api/show/parameter/share/document/expire";

export const API_GET_REQUEST_BY_REPAIR_TOKEN =
  "/api/request-by-repair-token/show";
export const API_ACTION_REPAIR_REQUEST = "/api/request/action-repair";
