import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import {Router} from "react-router-dom"
import axios from 'axios';
import {applyMiddleware, createStore} from 'redux';
import reducer from "./_reducers";
import {Provider} from "react-redux";
import history from "./history";
import thunk from "redux-thunk";
import {BASE_URL} from "./constants/constants";
import moment from "moment";
if (process.env.NODE_ENV !== "development" && process.env.NODE_ENV !== "dev")
    console.log = () => {};
const store = createStore(reducer,applyMiddleware(
    thunk,
));

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common['timezone'] = moment().utcOffset()

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router history={history}>
                <I18nextProvider i18n={ i18n }>
                    <App/>
                </I18nextProvider>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
