import React, {Component} from 'react';
import {Grid as MuiGrid, TextField, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import CustomInlineSvg from "../CustomElements/CustomInlineSvg";
import endIcon from "../../images/request/requestAdmin/endIcon.svg";
import nextIcon from "../../images/request/requestAdmin/nextIcon.svg";
import AppFreeSoloAutocomplete from "../form/Autocomplete/AppFreeSoloAutocomplete";

const styles = theme => ({
    paginationWrapper:{
        margin: "20px 0",
        alignItems: "center",
        width: '100%',
        justifyContent:'flex-end',
        display: 'flex',
        '& .pagingInfos':{
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            '& .goToPage':{
                marginRight: 10
            }
        },
        '& .paginateCustom':{
            padding: '2rem 0',
            alignItems:'center',
            justifyContent: 'flex-end',
            '& .perPage':{
                marginRight: 10
            }
        },
        '& .selectPageSize':{
            alignItems: 'center',
            display: 'flex',
            '& .perPage':{
                marginRight: 10
            },
            '& .MuiOutlinedInput-root':{
                padding: '0.8rem 2.5rem 0.8rem 1rem'
            }
        },
        '& .goToPageWrapper':{
            display: 'flex',
            alignItems: 'center',
            '& .goToPage':{
                marginRight: 10
            }
        },
        '& .buttonWrapper':{
            display: 'flex',
            marginLeft: 20,
            '& .Mui-disabled':{
                '& svg>path':{
                    stroke: '#D8DDE6'
                },
                '& svg>rect':{
                    fill: '#D8DDE6'
                }
            }
        },
        "& button":{
            minWidth: "2.5rem"
        }
    },
    pageSizeButton:{
        "&.active":{
            border: "1px solid #0000ff",
        }
    },
    paginationPageNum :{
        padding:"0",
        minWidth: 40,
        minHeight: 40,
        "&.active":{
            background: "#0000ff",
            color:"white",
            margin: '0 5px'
        },
        "&:hover:not(.active)":{
            background: "rgba(70,67,90,0.81)",
            color:"white"
        },
        "&.prevBtn,&.nextBtn":{
            fontSize: "1.2rem",
            width: 49,
            border: '1px solid #D8DDE6',
        },
        "&.prevBtn":{
            '& svg':{
                transform: 'rotate(180deg)!important'
            }
        }
    },
    paginationResultInfos:{
        padding: "0 5px",
        margin: "0 1rem",

    },
})

const pageSizes = [10, 30, 50]

class CustomPagination extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        const {totalCount, totalPages, currentPage, pageSize, classes, t} = this.props;
        let pageSizeOptions = pageSizes;

        if (!pageSizeOptions.includes(pageSize)) {
            pageSizeOptions.push(pageSize);
        }
        pageSizeOptions.sort(function (a, b) {
            return a - b;
        });
        return (
            <React.Fragment>
                <div className={classes.paginationWrapper}>
                    {
                        totalCount > 0 && !!pageSizes && pageSizes.length &&
                        <MuiGrid item  className="selectPageSize">
                            <span className={'perPage'}>{t('list.resultPerPage')}</span>
                            <AppFreeSoloAutocomplete
                                fullWidth={false}
                                options={pageSizeOptions ? pageSizeOptions.map((item) => item.toString()) : []}
                                disableClearable
                                value={pageSize}
                                style={{
                                    height: "auto",
                                    borderRadius: 10
                                }}
                                onChange={(e, value) => {
                                    this.props.onPageSizeChange(parseInt(value));
                                    // this.props.setPageSize(value);
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password',
                                            value: pageSize
                                        }}
                                        variant={"outlined"}
                                        onChange={(e) => this.props.onPageSizeChange(e.target.value)}
                                    />

                                )}
                            />
                        </MuiGrid>
                    }
                    {totalCount > 0 &&
                    <MuiGrid item className="pagingInfos" >
                        <MuiGrid item className={classes.paginationResultInfos}>{t("text.table.paging.info").replace("{from}",  ((currentPage-1) * pageSize) + 1)
                            .replace("{to}",  (currentPage < totalPages - 1 ? pageSize * (currentPage + 1) : totalCount)).replace("{count}", totalCount)}</MuiGrid>
                        {totalPages > 1 && <React.Fragment>
                            <span className={'goToPage'}>{t('list.goToPage')}</span>
                            {currentPage > 1 &&
                            <MuiGrid item><Button className={classes.paginationPageNum + " paginationPageNum"}
                                                  onClick={(e) => this.props.onCurrentPageChange(currentPage-1)}>{currentPage-1}</Button></MuiGrid>}
                            <MuiGrid item><Button
                                className={classes.paginationPageNum + " paginationPageNum active"}>{currentPage}</Button></MuiGrid>
                            {currentPage < totalPages &&
                            <MuiGrid item><Button className={classes.paginationPageNum + " paginationPageNum"}
                                                  onClick={(e) => this.props.onCurrentPageChange(currentPage + 1)}>{currentPage + 1}</Button></MuiGrid>}
                            <MuiGrid className={'buttonWrapper'}>
                                <MuiGrid item>
                                    <Button className={classes.paginationPageNum + " paginationPageNum prevBtn"}
                                            onClick={() => this.props.onCurrentPageChange(1)}
                                            disabled={currentPage <= 1}
                                    >
                                        <CustomInlineSvg
                                            svgProps={{src: endIcon}}
                                        />
                                    </Button>
                                </MuiGrid>
                                <MuiGrid item>
                                    <Button className={classes.paginationPageNum + " paginationPageNum prevBtn"}
                                            onClick={() => this.props.onCurrentPageChange(currentPage - 1)}
                                            disabled={currentPage <= 1}
                                    >
                                        <CustomInlineSvg
                                            svgProps={{src: nextIcon}}
                                        />
                                    </Button>
                                </MuiGrid>
                                <MuiGrid item>
                                    <Button className={classes.paginationPageNum + " paginationPageNum nextBtn"}
                                            onClick={() => this.props.onCurrentPageChange(currentPage + 1)}
                                            disabled={currentPage >= totalPages}
                                    >
                                        <CustomInlineSvg
                                            svgProps={{src: nextIcon}}
                                        />
                                    </Button>
                                </MuiGrid>
                                <MuiGrid item>
                                    <Button className={classes.paginationPageNum + " paginationPageNum nextBtn"}
                                            onClick={() => this.props.onCurrentPageChange(totalPages)}
                                            disabled={currentPage >= totalPages}
                                    >
                                        <CustomInlineSvg
                                            svgProps={{src: endIcon}}
                                        />
                                    </Button>
                                </MuiGrid>
                            </MuiGrid>
                        </React.Fragment>}
                    </MuiGrid>}
                </div>
            </React.Fragment>
        );
    }
}

CustomPagination.defaultProps = {
    onPageSizeChange: () => {
    },
    // setPageSize: () => {},
    name: '',
    value: '',
    onCurrentPageChange: () => {
    },
    className: '',
    totalCount: 0,
    totalPages: 0,
    currentPage: 0,
    pageSize: 0
}

CustomPagination.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    onCurrentPageChange: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    // setPageSize: PropTypes.func,
    totalCount: PropTypes.number,
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    pageSize: PropTypes.number
}

CustomPagination.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
}

export default compose(withStyles(styles), withTranslation())(CustomPagination);
