import React, {Component} from 'react';
import Calendar from 'devextreme-react/calendar';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {CircularProgress, /*Grid, */ withStyles} from "@material-ui/core";
import {NUNITO} from "../../constants/fontCss";
import {BASE_URL, SM, XS} from "../../constants/constants";
import axios from "axios";
import {
    API_CHANGE_REGISTRATION_TIME_NOT_LOGIN,
    API_REGISTRATION_TIME,
    CANCEL_DATE_REQUEST, CANCEL_DATE_REQUEST_NOT_AUTH,
    SHOW_TIME_REGISTRATION,
    SHOW_TIME_REGISTRATION_NOT_LOGIN
} from "../../constants/apiSuffix";
import moment from "moment";
import CustomButton from "../CustomElements/CustomButton";
import CustomSvg from "../CustomElements/CustomSvg";
import closeIcon from "../../images/dateClient/close.svg";
import okIcon from "../../images/request/check_validate-white.svg";
import notokIcon from "../../images/request/NOT_ELIGIBLE_BLUE.svg";
// import addStaffIcon from "../../images/request/addStaff.svg";
// import {datePickerDefaultProps} from "@material-ui/pickers/constants/prop-types";
import {compareDate} from "../../functions/functions";
import {withRouter} from "react-router";
import DialogForm from "../CustomElements/DialogForm";
import PropTypes from "prop-types";
import * as links from "../../constants/links";
import returnIcon from "../../images/navbar/Groupe 8620.svg";
import {NavLink} from "react-router-dom";
// import stopActive from "../../images/request/requestAdmin/stopActive.svg";

const styles = theme => ({
    wrapper:{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(90vh - 180px)',
        overflow: 'auto',
    },
    calendarBlock:{
        display: 'flex'
    },
    calendarWrapper:{
        width: 340,
        '& tr':{
            height: '30px',
            '& td':{
                padding: 0,
                border: 'none'
            },
            '& th':{
                color: '#1D243E!important',
                fontWeight: '400!important',
                fontSize: '0.875rem!important',
                fontFamily: `${NUNITO}!important`,
                textTransform: 'capitalize!important'
            }
        },
        '& .dx-calendar-other-view':{
            color: '#BDBDBD!important',
            visibility: 'hidden'
        },
        '& .dx-calendar':{
            minWidth: 'unset',
            width: '100%'
        },
        '& .dx-calendar-caption-button':{
            left: 0,
            right: 'unset',
            background: 'none!important',
            '& .dx-button-content':{
                cursor: 'default!important',
                padding: '2px 10px 4px!important'
            },
        },
        '& .dx-button':{
            border: 'none!important',
        },
        '& .dx-calendar-cell':{
            fontFamily: NUNITO,
            fontSize: '0.875rem',
            fontWeight: 700,
            color:'#1D243E'
        },
        '& .dx-calendar-empty-cell':{
            background: 'none!important',
            color:'#BDBDBD!important'
        },
        '& .dx-button-text':{
            color:'#1D243E',
            fontWeight: "700",
            fontSize: '0.875rem',
            fontFamily: NUNITO,
            textTransform: 'capitalize'
        },
        '& .dx-calendar-navigator-previous-view':{
            left: 'unset',
            right: '25px',
            width: '25px',
            height: '25px',
            borderRadius: '50%'
        },
        '& .dx-calendar-navigator-next-view':{
            width: '25px',
            height: '25px',
            borderRadius: '50%'
        },
        '& .dx-icon::before':{
            color: '#1D243E'
        },
        '& .dx-calendar-selected-date':{
            boxShadow: 'none!important',
            background: 'none!important',
            color: '#fff',
            '& span':{
                background: '#0000FF',
                borderRadius: '50%',
                // padding: 4,
                display: 'inline-block',
                width: 30,
                height: 30,
                lineHeight: '30px'
            }
        },
        [theme.breakpoints.down(XS)]:{
            borderRadius: '0 0 15px 15px',
            border: '0px solid #E0E0E0',
            borderTop: 'none',
        }
    },
    listTime: {
        width: 170,
        height: '220px',
        overflow: 'auto',
        margin: '20px 0',
        '&::-webkit-scrollbar': {
            width: '5px'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor:'#C4C4C4',
            borderRadius: 3
        },
    },
    timeElement: {
        border: '1px solid #E0E0E0',
        borderRadius: '10px',
        height: '40px',
        lineHeight: '40px',
        textAlign: 'center',
        marginRight: 10,
        cursor: 'pointer',
        color: '#1D243E',
        transition: '0.2s ease',
        '&:not(:last-child)':{
            marginBottom: '10px'
        },

        '&:hover':{
            background:'#dfeffd',
            border: '1px dotted #dfeffd !important',
            transition: '0.2s ease',
        }
    },
    timeSelected:{
        border: '2px solid #0000FF'
    },
    dateTimeSelected:{
        fontWeight: 700,
        fontSize: '0.875rem',
        color: '#1D243E',
        maxWidth: 270,
        height: 50,
        borderRadius: 15,
        border: '1px solid #E0E0E0',
        boxShadow: '0px 4px 20px rgba(0, 0, 116, 0.1)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
        '& span':{
            paddingRight: 5
        },
        '& svg':{
            cursor: 'pointer',
            '&:hover > path':{
                fill: '#3f57a7',
            }

        }
    },
    timeWrapper:{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 20,
        position: 'relative',
        '& .dateSelected':{
            fontWeight: 700,
            fontSize: '0.875rem'
        }
    },
    loadingTime:{
        width: 170,
        margin: 'auto',
        textAlign: 'center'
    },
    buttonSubmitWrapper:{
        textAlign: 'end',
        padding: '15px 10px',
        margin: '30px 20px 0px 20px',

        '& .buttonCancel':{
            backgroundColor: 'transparent !important',
            border: '1px solid #0071c7',

            '& span':{
                color:'#0071c7',
                transition:'O.3s ease',
            },

            '&:hover':{
                backgroundColor: '#c5c5c5 !important',
                border: '1px solid #c5c5c5',
                transition:'O.1s ease',

                '& span':{
                    color:'#fff',
                    transition:'O.1s ease',

                    '& img':{
                        filter: 'contrast(0)',
                        transition:'O.1s ease',
                    },
                },

            }
        },

        '& img':{
            height: '20px',
            transition:'O.1s ease',
        },

        [theme.breakpoints.down(XS)]:{
            margin:'30px 0px 10px 0px',

            '& button':{
                margin: '1rem 0 1rem 0',
            },
        }

    },
    buttonSubmit:{
        background: '#0000FF',
        height: 40,
        width: 120,
        fontWeight: 700,
        fontSize: '1.125rem',
        margin: '0 10px'
    },
    buttonCancel:{
        background: '#DF373C!important',
        height: 40,
        width: 120,
        fontWeight: 700,
        fontSize: '1.125rem',
        '&:before':{
            background: '#DF373C!important',
        },
        '&:hover':{
            '&:before':{
                background: '#ce2e33!important'
            }
        }
    },
    hiddenButton:{
        opacity: 0,
        // visibility: 'hidden',
        display: 'none'
    },
    errorNoDate:{
        color: 'red',
    },
    disableTime:{
        cursor: 'default'
    },
    cancelDialog:{

    },
    returnRequestList: {
        borderRadius: 24,
        border: 'solid 1px #ffffff',
        color:'black',
        textDecoration: 'none',
        padding: '0.5rem 1rem',
        margin: '1rem auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        maxWidth: 150,
        '&:hover':{
            //background:'linear-gradient(270deg, rgba(14, 56, 96, 0) 8.24%, #0E2F60 34.94%, #0A0730 97.58%)',
            //color: '#0c1c49',
            fontWeight: 700,
            textDecoration: "underline",
            backgroundPosition: '100% 0',
            transition: 'all .4s ease-in-out',
        },
        '& > span':{
            fontSize: '1.1125rem',
            paddingLeft: '0.5rem',
            lineHeight: 0.9,
            fontFamily: NUNITO
        }
    },
})

class CustomCalendar extends Component {
    constructor(props) {
        super(props);
        this.state={
            callTimeFree : [],
            dateSelected : this.props.dataDateTime.dateSelected ?? null,
            dateRestDay: this.props.dataDateTime.dateRestDay ?? [],
            callTime: this.props.dataDateTime.startTime && this.props.dataDateTime.endTime ? this.props.dataDateTime.endTime - this.props.dataDateTime.startTime : 0,
            callTimeMonth: {},
            monthSelected: null,
            isLoading: false,
            timeSelected: this.props.dataDateTime.startTime ?? null,
            dateCheckDisable: this.props.dataDateTime.dateSelected ?? new Date(),
            errors: {},
            checkDisableCalendar: false,
            dialogForm: false,
            idDialogForm: null,
            checkCancelButton: this.props.dataDateTime && this.props.dataDateTime.dateSelected
        }
    }
    handleChange=(e)=>{
        const {monthSelected, callTimeMonth} = this.state
        this.setState({
            dateSelected: e.value,
            loadingTime: true,
            timeSelected: null,
            errors: {}
        })
        if(monthSelected!==moment(e.value).format('MM')){
            this.getAxiosDate(e.value)
        }
        else {
            let {callTimeFree} = callTimeMonth[`day_${moment(e.value).format('D')}`]
            this.setState({
                callTimeFree: callTimeFree,
            })
            setTimeout(()=>this.setState({loadingTime: false}),2000)
        }
    }
    handleChangeMonth = (e) => {
        const {monthSelected /*, callTime*/} = this.state
        if(e.name === 'currentDate'){
            let date = e.value
            if(monthSelected!==moment(date).format('MM')){
                this.setState({
                    dateCheckDisable: date,
                    errors: {}
                })
                this.getAxiosDate(date)
            }
        }
    }
    getAxiosDate = (dataDateTime, checkInitial) => {
        let date = moment(dataDateTime).format('YYYY-MM-DD')
        const {errors} = this.state
        const {forDetail, /*admin,*/ paid, match} = this.props
        let requestId = match.params.id
        if(this.props.dataDateTime && this.props.dataDateTime.id) requestId = this.props.dataDateTime.id
        this.setState({loadingTime: true})
        axios.get(`${this.props.requestToken ? SHOW_TIME_REGISTRATION_NOT_LOGIN : SHOW_TIME_REGISTRATION}?date=${date}&requestId=${requestId}`)
            .then(response => {
                let data = response.data
                let callTimeFree = []
                let {startTime, endTime} = this.props.dataDateTime
                let callTime = endTime - startTime + 1
                startTime = moment().hour(startTime/60).minute(startTime%60).second(0).format('HH:mm')
                endTime = moment().hour((endTime)/60).minute((endTime)%60).second(0).format('HH:mm')
                let timeRange = startTime + ' - ' + endTime
                if(data.dataCallTime) callTimeFree= data.dataCallTime[`day_${moment(dataDateTime).format('D')}`].callTimeFree
                // if(!forDetail || (forDetail && !paid)){
                if(forDetail && !paid){
                    if(checkInitial && ((callTimeFree && callTimeFree.indexOf(this.props.dataDateTime.startTime) === -1 ) || callTime !== data.callTime)){
                        errors.timeExpired = this.props.t('information.formTab2.timeExpired',{date:`(${moment(dataDateTime).format("ddd")+', '+moment(dataDateTime).format("DD MMM YYYY") + ', ' + timeRange})`})
                        timeRange = null
                        this.setState({
                            // dateSelected: null,
                            timeSelected: null,
                        })
                    }
                }
                this.setState({
                    dateRestDay: data.dateRestday ?? [],
                    callTimeFree: callTimeFree ?? [],
                    callTime: data.callTime ?? 0,
                    callTimeMonth: data.dataCallTime ?? {},
                    monthSelected: data.month ?? null,
                    timeRange: timeRange
                })
            })
            .catch(err=>{
                if(err.response && err.response.data && err.response.data.message){
                    errors.backServer = err.response.data.message
                    this.setState({
                        errors: errors
                    })
                }
            })
            .finally(()=>this.setState({loadingTime: false}))
    }
    showTime = () => {
        const { classes, paymentRequest, forDetail, admin, paid } = this.props
        let { timeSelected, callTimeFree, callTime, checkDisableCalendar, dateSelected} = this.state
        if(forDetail && (checkDisableCalendar || (callTimeFree.length === 0 && paid))){
            callTimeFree = timeSelected ? [timeSelected] : []
        }
        const hour = Math.floor(timeSelected/60);
        // const dateTimeSelected = moment(dateSelected).set({
        //     // hour: hour,
        //     // minute: timeSelected-60*hour,
        //     hour: 23,
        //     minute: 59
        // });
        const dateTimeSelected = moment(moment(dateSelected).format("YYYY-MM-DD")).set(timeSelected ? {
            hour: hour,
            minute: timeSelected-60*hour,
        } : {
            hour: 23,
            minute: 59
        });
        const checkDateTimePast = !admin && dateTimeSelected < moment();
        return  callTimeFree.map(item=>{
            let starTime = moment().hour(item/60).minute(item%60).second(0)
            let endTime = moment(starTime).add(`${callTime}`,'minute').format('HH:mm')
            let timeRange = moment(starTime).format('HH:mm') + ' - ' + endTime
            return <div
                className={`${classes.timeElement} timeElement ${timeSelected===item ? classes.timeSelected + " timeSelected" : ""} ${(!admin && (paymentRequest || checkDisableCalendar)) ? classes.disableTime : ""}`}
                onClick={()=>(checkDateTimePast || (forDetail && checkDisableCalendar)) ? ()=>{} : this.handleSetTime(item, timeRange)}
            >
                {timeRange}
            </div>
        })
    }
    checkDisableDate = (date) => {
        const {admin} = this.props;
        const {dateRestDay, /*dateCheckDisable, callTimeMonth,*/ monthSelected, dateSelected, timeSelected} = this.state;
        const hour = Math.floor(timeSelected/60);
        // const dateTimeSelected = moment(dateSelected).set({
        //     // hour: hour,
        //     // minute: timeSelected-60*hour,
        //     hour: 23,
        //     minute: 59
        // });
        const dateTimeSelected = moment(moment(dateSelected).format("YYYY-MM-DD")).set(timeSelected ? {
            hour: hour,
            minute: timeSelected-60*hour,
        } : {
            hour: 23,
            minute: 59
        });
        return !admin && dateTimeSelected < moment();
        // const {admin} = this.props
        // let month = moment(dateCheckDisable).format("M")
        // let year = moment(dateCheckDisable).format("Y")
        // if(dateRestDay.length && date.view === "month") for(let i in dateRestDay) {
        //     if (moment(date.date).format("M") === month && moment(date.date).format("Y") === year && moment(`${year}-${month}-${dateRestDay[i]}`).format("YYYY-MM-DD") === moment(date.date).format("YYYY-MM-DD")) return true
        // }
        if(dateRestDay.length && date.view === "month") for(let i in dateRestDay) {
            // console.log(moment(date.date).format("MM"))
            if(moment(date.date).format("MM") === monthSelected){
                // console.log('vao day', date)
                if(dateRestDay[i] === moment(date.date).format("D")*1) return true

            }
        }
        // if(!admin && date && date.date && moment(date.date).format('DD MM YYYY') === moment().format('DD MM YYYY')){
        //     let timeCheck = moment().format('HH')*60
        //     let callTimeFree = callTimeMonth[moment(date.date).format('D')]
        //     if(callTimeFree) {
        //         callTimeFree = callTimeFree.callTimeFree
        //         callTimeFree = callTimeFree.filter(item => parseInt(item) > timeCheck)
        //         if(callTimeFree && callTimeFree.length === 0) return true
        //     }
        // }
        return undefined
    }
    handleSetTime = (time, timeRange) => {
        this.setState({
            timeSelected: time,
            timeRange: timeRange,
            errors: {}
        })
    }
    deleteDate = () => {
        this.setState({
            dateSelected: null,
            timeSelected: null
        })
    }
    saveDateSelected = () => {
        const { t, dataDateTime, requestToken } = this.props
        const { dateSelected, timeSelected, errors } = this.state
        const formData = new FormData()
        if(!dateSelected || (!timeSelected && timeSelected!==0)){
            errors.notDateTime = t('information.formTab2.errorNoDate')
            this.setState({
                errors: errors
            })
        }
        if(this.checkDisableCalendar()){
            errors.notDateTime = t('information.formTab2.timeExpired')
            this.setState({
                errors: errors
            })
        }
        if(dateSelected && (timeSelected || timeSelected === 0) && !this.checkDisableCalendar()){
            let dateFormat = moment(dateSelected).format('YYYY-MM-DD')
            formData.append('date',dateFormat)
            formData.append('callTimeRequest',timeSelected)
            let url = requestToken ? API_CHANGE_REGISTRATION_TIME_NOT_LOGIN.replace('{id}',requestToken) : API_REGISTRATION_TIME.replace('{id}',dataDateTime.id)
            this.props.callBackFromCalendar(true, false, false)
            axios.post(BASE_URL + url, formData)
                .then(response=>{
                    this.setState({
                        checkCancelButton: true
                    })
                    const dataItem = response?.data?.item
                    if (dataItem && moment(dataItem.registrationStart).valueOf()
                        && (parseInt(dataItem.registrationTimeStart) || dataItem.registrationTimeStart === 0)
                        && (parseInt(dataItem.registrationTimeEnd) || dataItem.registrationTimeEnd === 0)) {
                        this.props.changeDateCalenderSelected(dataItem.registrationStart, dataItem.registrationTimeStart, dataItem.registrationTimeEnd)
                    }
                    this.props.closeModal()
                    this.props.callBackFromCalendar(false, true, true)
                })
                .catch(err=>{
                    console.log(err)
                    if(err.response && err.response.data && err.response.data.message){
                        if (err.response.data.response_code === "REGISTRATION_TIME_REQUEST_EXPIRES_TO_CHANGE_TIME"){
                            errors.backServer = t('information.formTab2.errorExpireToChangeTime')
                        }else {
                            errors.backServer = err.response.data.message
                        }
                        this.setState({
                            errors: errors
                        })
                    }
                    this.props.callBackFromCalendar(false, false, false)
                })
        }
    }
    checkDisableCalendar = () => {
        let {dateSelected, timeSelected} = this.state
        const {admin} = this.props
        let dateNow = moment();
        let timeNow = moment(dateNow).get('hour')*60 + moment(dateNow).get('minute')*1
        let checkDate = moment(dateSelected).diff(dateNow, 'days')
        let checkMinute = timeSelected - timeNow
        if(checkDate === 0){
            checkDate = moment(dateSelected).get('date')*1 - moment(dateNow).get("date")*1
        }
        if(!dateSelected) return false
        else if(admin) return false
        else if(checkDate<0) return true
        else if(checkDate > 0) return false
        else return checkMinute <= 0
    }
    cancelDate = id => {
        const {t,admin, dataDateTime, requestToken} = this.props
        const {errors} = this.state;
        const expireToChangeTime = dataDateTime?.dateSelected && moment(dataDateTime.dateSelected).isValid() && moment(dataDateTime.dateSelected).valueOf() <= moment().valueOf();
        if (!admin && expireToChangeTime) {
            this.setState({
                dialogForm: false,
                idDialogForm: null,
                isLoading: false,
                errors: {...errors, backServer: t('information.formTab2.errorExpireToChangeTime')}
            })
        } else {
            this.props.callBackFromCalendar(true, false, false)
            axios.post(requestToken? CANCEL_DATE_REQUEST_NOT_AUTH.replace("{id}", requestToken) : CANCEL_DATE_REQUEST.replace('{id}', id))
                .then(res => {
                    this.deleteDate()
                    this.setState({
                        checkCancelButton: false
                    })
                    this.props.clearDateCalenderSelected()
                    this.props.closeModal()
                    this.props.callBackFromCalendar(false, true, true)
                })
                .catch(err => {
                    if(err.response && err.response.data && err.response.data.message){
                        let errorsAlert = err.response.data.message
                        if (err.response.data.response_code === "REGISTRATION_TIME_REQUEST_EXPIRES_TO_CHANGE_TIME"){
                            errorsAlert = t('information.formTab2.errorExpireToChangeTime')
                        }
                        this.setState({
                            errors: {...errors, backServer: errorsAlert}
                        })
                    }
                    this.props.callBackFromCalendar(false, false, false)
                })
                .finally(() => {
                    this.setState({
                        dialogForm: false,
                        idDialogForm: null,
                        isLoading: false
                    })
                })
        }
    }

    componentDidMount() {
        const {dataDateTime, paid, /*match,*/ checkDisableProp, forDetail, requestToken} = this.props
        let date = moment(dataDateTime).format('YYYY-MM-DD')
        // let requestId = match.params.id
        if(this.checkDisableCalendar() && (paid || checkDisableProp || (!paid && forDetail))){
            // if(paid || checkDisableProp){
            this.setState({
                checkDisableCalendar: true
            })
        } else if(dataDateTime.hasOwnProperty('dateSelected') && dataDateTime.dateSelected){
            this.getAxiosDate(dataDateTime.dateSelected, true)
        }
        else {
            date = moment().format('YYYY-MM-DD')
            let requestId = this.props.dataDateTime && this.props.dataDateTime.id ? this.props.dataDateTime.id : null
            this.setState({loadingTime: true})
            axios.get(`${requestToken? SHOW_TIME_REGISTRATION_NOT_LOGIN : SHOW_TIME_REGISTRATION}?date=${date}&requestId=${requestId}`)
                .then(res=> {
                    let {data} = res
                    if(data){
                        this.setState({
                            dataCallTime: data.dataDateTime,
                            dateRestDay: data.dateRestday ?? [],
                            callTime: data.callTime ?? 0,
                            callTimeMonth: data.dataCallTime ?? {},
                            monthSelected: data.month ?? null,
                        })
                    }
                })
                .catch(err=> console.log(err))
                .finally(()=>this.setState({loadingTime: false}))
        }
    }


    render() {
        const {classes, t, forDetail, admin, paid, dataDateTime} = this.props
        const { dateSelected, loadingTime, errors, timeRange, timeSelected, checkDisableCalendar, checkCancelButton} = this.state
        const expireToChangeTime = dataDateTime?.dateSelected && moment(dataDateTime.dateSelected).isValid() ? moment(dataDateTime.dateSelected): null;
        let minDate = moment()
        if(dateSelected && compareDate(moment(dateSelected), minDate, 'days')) minDate = moment(dateSelected)
        const hour = Math.floor(timeSelected/60);
        // const dateTimeSelected = moment(dateSelected).set({
        //     // hour: hour,
        //     // minute: timeSelected-60*hour,
        //     hour: 23,
        //     minute: 59
        // });
        const dateTimeSelected = moment(moment(dateSelected).format("YYYY-MM-DD")).set(timeSelected ? {
            hour: hour,
            minute: timeSelected-60*hour,
        } : {
            hour: 23,
            minute: 59
        });

        const checkDateTimePast = !admin && dateTimeSelected < moment();
        return (
            <div>
                <div className={classes.wrapper + " wrapperBlock"}>
                    <div className={classes.calendarBlock + " calendarBlock"}>
                        {forDetail && paid ?
                            <Calendar
                                value={dateSelected}
                                className={classes.calendarWrapper}
                                min = {(admin) ? null : minDate}
                                focusStateEnabled={false}
                                onValueChanged={this.handleChange}
                                minZoomLevel={'month'}
                                maxZoomLevel={'month'}
                                disabled={forDetail && checkDisableCalendar ? checkDisableCalendar : loadingTime}
                                onOptionChanged={(e)=>this.handleChangeMonth(e)}
                                disabledDates={(date)=>this.checkDisableDate(date)}
                                activeStateEnabled={false}

                            />
                            :
                            <Calendar
                                value={dateSelected}
                                className={classes.calendarWrapper}
                                min = {(admin) ? null : minDate}
                                focusStateEnabled={false}
                                onValueChanged={this.handleChange}
                                minZoomLevel={'month'}
                                maxZoomLevel={'month'}
                                disabled={forDetail && checkDisableCalendar ? checkDisableCalendar : loadingTime}
                                onOptionChanged={(e)=>this.handleChangeMonth(e)}
                                disabledDates={(date)=>this.checkDisableDate(date)}
                                activeStateEnabled={false}
                            />
                        }
                        {dateSelected && <div className={classes.timeWrapper + " timeWrapper"}>
                        <span className={'dateSelected'}>
                            {forDetail ? moment(dateSelected).format("DD MMMM YYYY") : moment(dateSelected).format("MMM DD, YYYY")}
                        </span>
                            {!loadingTime ?
                                <div className={`${classes.listTime} listTime ${forDetail && checkDisableCalendar ? "listTimeDisable" : ""}`}>
                                    {this.showTime()}
                                </div>
                                :
                                <div className={classes.loadingTime}><CircularProgress className={"circularProgressCustom"}/></div>
                            }
                        </div>}
                        {!dateSelected && loadingTime && <div className={classes.loadingTime}><CircularProgress className={"circularProgressCustom"}/></div>}
                    </div>
                    {dateSelected && (timeSelected !== null && timeSelected !== undefined) && timeRange && !loadingTime && <div className={"customDateTimeSelected"}>
                        <div className={classes.dateTimeSelected + " dateTimeSelected"}>
                            {!this.props.forDetail ?
                                <span>{moment(dateSelected).format("ddd")+', '+moment(dateSelected).format("DD MMM YYYY")+" - "+timeRange}</span>
                                :
                                <span>
                                    <span>{moment(dateSelected).format("DD MMMM YYYY")}</span>
                                    <span className={"timeRangeCustom"}>{timeRange}</span>
                                </span>
                            }
                            {!checkDateTimePast ? <span className={'closeButton'} onClick={()=>forDetail && checkDisableCalendar ? ()=>{} : this.deleteDate()}>
                                {this.props.forDetail ? forDetail && checkDisableCalendar ? null  : "X"  :
                                    <CustomSvg
                                        svgProps={{
                                            src: closeIcon,
                                        }}
                                    />
                                }
                        </span>
                                :
                                <></>}
                        </div>
                    </div>}
                    {errors && errors.notDateTime && <span className={classes.errorNoDate + " errorNoDate"}>{errors.notDateTime}</span>}
                    {errors && errors.backServer && <span className={classes.errorNoDate + " errorNoDate"}>{errors.backServer}</span>}
                    {errors && errors.timeExpired && <span className={classes.errorNoDate + " errorNoDate"}>{errors.timeExpired}</span>}
                </div>
                <div className={classes.buttonSubmitWrapper + " buttonSubmitWrapper"}>
                    {!admin  && expireToChangeTime && expireToChangeTime.valueOf() <= moment().valueOf() ? "": this.props.forDetail ?
                        <React.Fragment>
                            {checkCancelButton && <CustomButton
                                className={`${classes.buttonCancel + " buttonCancel"}  ${loadingTime  ? classes.disabledButton + " disabledButton"  : ""} ${(forDetail && checkDisableCalendar) ? classes.hiddenButton : ""}`}
                                title={t('requestDate.cancelAppointment')}
                                onClick={()=>forDetail && checkDisableCalendar ? ()=>{} : this.setState({dialogForm: true, idDialogForm: dataDateTime.id})}
                                disabled={loadingTime}
                                buttonProps={{
                                    startIcon: <img src={notokIcon} alt=""/>
                                }}
                            />}
                            <CustomButton
                                className={`${classes.buttonSubmit} ${loadingTime  ? classes.disabledButton + " disabledButton"  : ""} ${(forDetail && checkDisableCalendar) ? classes.hiddenButton : ""}`}
                                title={t(!checkCancelButton?'requestDate.save':'requestDate.modifyDate')}
                                onClick={()=>forDetail && checkDisableCalendar ? ()=>{} : this.saveDateSelected()}
                                disabled={loadingTime}
                                buttonProps={{
                                    startIcon: <img src={okIcon} alt=""/>
                                }}
                            />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {checkCancelButton &&
                            <CustomButton
                                className={`${classes.buttonCancel} ${loadingTime ? classes.disabledButton : ""}`}
                                title={t('button.cancel')}
                                onClick={()=>this.setState({dialogForm: true, idDialogForm: dataDateTime.id})}
                                disabled={loadingTime}
                            />
                            }
                            <CustomButton
                                className={`${classes.buttonSubmit} ${loadingTime ? classes.disabledButton : ""}`}
                                title={t(!checkCancelButton?'information.formTab2.save':'requestDate.modifyDate')}
                                onClick={()=>this.saveDateSelected()}
                                disabled={loadingTime}
                            />
                        </React.Fragment>

                    }
                    <NavLink to={links.HOME} className={`${classes.returnRequestList} returnRequestList`}>
                        <img alt={t("information.header.returnHistory")} src={returnIcon} />
                        <span>{t("information.header.returnHistory")}</span>
                    </NavLink>

                </div>
                {this.state.dialogForm && <DialogForm
                    styleNumber={2}
                    dialogProps={{
                        open: this.state.dialogForm
                    }}
                    disagreeButtonProps={{
                        handleDisagree: () => this.setState({dialogForm: false, idDialogForm: null})
                    }}
                    agreeButtonProps={{
                        handleAgree: () => this.cancelDate(this.state.idDialogForm)
                    }}
                    messageProps={{
                        content: <div className={classes.cancelDialog}>
                            {this.props.t('requestDate.cancelDate')}
                        </div>
                    }}
                />}
            </div>

        );
    }
}
CustomCalendar.defaultProps = {
    dataDateTime: [],
    forDetail: false,
    callBackFromCalendar: ()=>{},
    closeModal: () => {},
    admin: false,
    checkDisableProp: false,  clearDateCalenderSelected: ()=>{},
    changeDateCalenderSelected: (registrationStart, registrationTimeStart, registrationTimeEnd) => {
    },
    requestToken: ""
}

CustomCalendar.propTypes = {
    classes: PropTypes.object.isRequired,
    clearDateCalenderSelected: PropTypes.func,
    changeDateCalenderSelected: PropTypes.func,
    requestToken: PropTypes.string
}
export default compose(withTranslation(), withStyles(styles), withRouter) (CustomCalendar);
