import React, { useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import {SM, USER_TYPE_CLIENT, XS} from "../../constants/constants";
import {Tooltip} from "@material-ui/core";
import moment from "moment";
import {connect, useSelector} from "react-redux";
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import CustomInlineSvg from "../../theme/CustomElements/CustomInlineSvg";
import playButton from "../../images/theme/playButton.svg";
import zoomIn from "../../images/theme/zoomIn.svg";
import docIcon from "../../images/request/doc.svg";
import DownloadDoc from "../../images/request/download-arrow.svg";
import GeneralModal from "../../theme/Modal/GeneralModal";
import ContentPreviewModal2 from "../../theme/Modal/ContentPreviewModal2";
import * as application from "../../_actions/application";
import {compose} from "redux";
import PopoverReadOrUnReadMessageChat from "./PopoverReadOrUnReadMessageChat";

const useStyles = makeStyles((theme)=>({
    avatarClient: {
        backgroundColor: '#f99b2d'
    },
    avatarAdmin: {
        backgroundColor: '#007ec8'
    },
    chatMessage: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        padding: '0 5px',
        '& .MuiAvatar-root': {
            width: 40,
            height: 40
        },
        '&.isOwn': {
            justifyContent: 'flex-end',
            '& .message': {
                backgroundColor: '#f1f1f2',
                marginLeft: 0,
            },
            '& $messageWrapper': {
                maxWidth: 'calc(100% - 100px)',
            }
        },
        '&:hover': {
            '& $chatPopover': {
                display: 'block'
            }
        }
    },
    messageWrapper: {
        maxWidth: 'calc(100% - 200px)',
        position: 'relative',
        paddingRight: 30,
        paddingLeft: 0,
        [theme.breakpoints.between(XS, SM)]: {
            maxWidth: 'calc(100% - 100px)',
        },
        '&.isOwn': {
            paddingLeft: 30,
            paddingRight: 0
        },
    },
    message: {
        width: '100%',
        // maxWidth: 'calc(100% - 200px)',
        marginLeft: 10,
        backgroundColor: '#e8f3ff',
        padding: 10,
        borderRadius: 10,
        color: '#605e72',
        fontWeight: 600,
        position: 'relative',
        '& .isClient': {
            position: 'absolute',
            right: -10,
            top: 0,
            // backgroundColor: '#0000fe',
            '& svg path, circle': {
                fill: '#605e72',
            }
        },
        '& .content':{
            whiteSpace: "pre-line"
        },
        '& .listDocuments': {
            marginTop: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            overflowX: 'auto',
            padding: '10px 0',
            '&::-webkit-scrollbar': {
                width: '7px',
                height: '7px'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor:'#5072ee',
                borderRadius: 7
            },
            '& img, & video':{
                width: 60,
                height: 60,
                position: 'absolute',
                zIndex: 0,
                objectFit: 'contain'
            },
            '&:hover > .playButton':{
                opacity: 1,
                visibility: 'visible',
                filter: 'blur(4px)',
            },
        },
    },

    documentWrapper:{
        position: 'relative',
        display: 'flex',
        padding: '0.25rem',
        cursor: 'pointer',
        height: 70,
        minWidth: 70,
        maxWidth: 70,
        margin: '0 10px',
        backgroundColor:'#fff',
        borderRadius: 10,
        '& .playButton':{
            width: 70,
            height:70,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
            opacity: 0,
            '& svg':{
                fill: '#635151!important'
            },
            [theme.breakpoints.down(XS)]:{
                opacity: 1,
                visibility: 'visible',
            }
        },
        '& .closeButton': {
            position: 'absolute',
            top: -10,
            right: -10,
            backgroundColor: '#5072ee',
            borderRadius: '50%',
            width: 25,
            height: 25,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
                fill: '#fff'
            }
        },
        '&:hover > .playButton':{
            opacity: 1,
            visibility: 'visible',
            // filter: 'blur(4px)',
        },
        '&:hover':{
            // filter: 'blur(4px)',
            '& .playButton':{
                opacity: 1,
            },
            '& .closeButton':{
                opacity: 1,
                visibility: 'visible',
            },
            '& img, & video, & .doc':{
                filter: 'blur(4px)',
            }
        },
        [theme.breakpoints.down(XS)]:{
            '& img, & video, & .doc':{
                filter: 'blur(2px)',
            }
        }
    },
    previewImage: {
        width: '100%',
        height: '100%',
        // borderRadius: '20px',
        objectFit: 'contain'
    },
    previewDoc: {
        width: 70,
        height: 70,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        position: 'absolute',
        '& svg': {
            '& path': {
                stroke: '#0070c7'
            }
        }
    },
    previewVideo: {
        width: '100%',
        height: '100%',
        borderRadius: '20px',
        objectFit: 'cover!important',
        [theme.breakpoints.down(XS)]:{
            background: '#dedede'
        }
    },
    chatPopover: {
        position: 'absolute',
        right: 0,
        top: 0,
        fontSize: '1.2rem',
        fontWeight: 700,
        cursor: 'pointer',
        display: 'none',
        '&.isOwn': {
            left: 0,
            right: 'unset',
        },
        '&.viewPopover': {
            display: 'block',
        }
    },

}))

const ChatMessage = (props) => {
    const {
        chatMessage,
        countChatNotSeen,
        setCountChatNotSeen
    } = props;
    const [openModal, setOpenModal] = useState(false);
    const [valueModal, setValueModal] = useState(null)
    const dataUser = useSelector((state)=> state.authReducer.dataUser);
    const userAuthGroup =dataUser?.data?.group
    const idCurrentUser = dataUser && dataUser.data && dataUser.data.id ? dataUser.data.id : null
    const classes = useStyles();
    const isOwn = (chatMessage.sender && idCurrentUser == chatMessage.sender.id);
    const avatarUrl = chatMessage.sender && chatMessage.sender.person && chatMessage.sender.person.avatar && chatMessage.sender.person.avatar.pathUrl ? chatMessage.sender.person.avatar.pathUrl : null;
    const documents = Array.isArray(chatMessage.documents) ? chatMessage.documents : []
    const [chatPopover, setChatPopover] = useState(null)
    const handleOpenModal = (data) => {
        setOpenModal(true);
        setValueModal({
            key: data
        })
    }
    const handleCloseModal = () => {
        setOpenModal(false);
        setValueModal(null)
    }
    const viewDocumentItem = (item, index) => {
        const mimeType = item instanceof File ? item.type : item.mimeType;
        const url = item instanceof File ? URL.createObjectURL(item) : item.pathUrl;
        if (mimeType && mimeType.includes("video")) {
            return <React.Fragment>
                <div className={classes.documentWrapper} key={index}>
                    <video controls={false} className={classes.previewVideo}>
                        <source src={url}/>
                    </video>
                    <div onClick={() => handleOpenModal(index)}
                         className={"playButton"}
                    >
                        <CustomInlineSvg
                            svgProps={{
                                src: playButton,
                            }}
                            scale="0.3"
                        />
                    </div>
                </div>
            </React.Fragment>
        } else if (mimeType && mimeType.includes("image")) {
            return <React.Fragment>
                <div className={classes.documentWrapper} key={index}>
                    <img className={classes.previewImage} alt={'file upload'}
                         src={url}/>
                    <div onClick={() => handleOpenModal(index)}
                         className={"playButton"}>
                        <CustomInlineSvg
                            svgProps={{
                                src: zoomIn,
                            }}
                            scale="0.2"
                        />
                    </div>
                </div>
            </React.Fragment>
        } else return <div className={classes.documentWrapper} key={index}>
            <div className={classes.previewDoc + " doc"}>
                <CustomInlineSvg
                    svgProps={{
                        src: docIcon,
                    }}
                />
            </div>
            {
                (mimeType && mimeType.includes("pdf")) ?
                    <div onClick={() => handleOpenModal(index)}
                         className={"playButton"}>
                        <CustomInlineSvg
                            svgProps={{
                                src: zoomIn
                            }}
                            scale="0.2"
                        />
                    </div>
                    :
                    <a href={url} target="_blank" className={"playButton"}>
                        <CustomInlineSvg
                            svgProps={{
                                src: DownloadDoc,
                            }}
                            scale="0.5"
                        />
                    </a>
            }
        </div>
    }

    return (
        <div className={classes.chatMessage + (isOwn ? " isOwn" : "")}>
            { !isOwn && <Tooltip title={chatMessage.sender && chatMessage.sender.person && chatMessage.sender.person.fullName ? chatMessage.sender.person.fullName : ''}>
                {avatarUrl
                    ?
                    <Avatar src={avatarUrl}/>
                    :
                    <Avatar
                        className={chatMessage.sender && chatMessage.sender.instanceof === USER_TYPE_CLIENT ? ("" + classes.avatarClient) : ("" + classes.avatarAdmin)}>
                        {chatMessage.sender && chatMessage.sender.person && chatMessage.sender.person.firstName && chatMessage.sender.person.firstName.length ? chatMessage.sender.person.firstName.charAt(0) : ''}{chatMessage.sender && chatMessage.sender.person && chatMessage.sender.person.lastName && chatMessage.sender.person.lastName.length ? chatMessage.sender.person.lastName.charAt(0) : ''}
                    </Avatar>
                }
            </Tooltip> }
            <div className={classes.messageWrapper + (isOwn ? " isOwn" : "")}>
                <Tooltip title={chatMessage.createdDate ? moment(chatMessage.createdDate).format("DD/MM/YYYY HH:mm") : ""}>
                    <div className={classes.message + " message"}>
                            <div className="content">
                                {chatMessage.content}
                            </div>
                            {!!documents && documents.length > 0 && <div className="listDocuments">
                                {
                                    documents.map((item, index) => {
                                        return viewDocumentItem(item, index);
                                    })
                                }
                            </div>}
                            {!isOwn && chatMessage.sender && chatMessage.sender.instanceof === USER_TYPE_CLIENT && <div className="isClient"><EmojiPeopleIcon /></div>}
                        </div>
                </Tooltip>
                {!isOwn &&  (
                    (userAuthGroup === USER_TYPE_CLIENT && chatMessage.typeMess !== 'client') ||
                    (userAuthGroup !== USER_TYPE_CLIENT && chatMessage.typeMess === 'client')
                ) && <div onClick={(event) => {
                    setChatPopover(event.currentTarget)
                }} className={classes.chatPopover + (isOwn ? " isOwn" : "") + (chatPopover ? " viewPopover" : "")}>
                    ...
                </div>}
            </div>

            {openModal && valueModal && <GeneralModal
                className={"previewModal"}
                open={openModal}
                handleClose={handleCloseModal}
                content={<ContentPreviewModal2 {...props} files={documents} keyFile={valueModal.key}/>}
                saveButton={false}
                cancelButton={false}
            />}
            {chatPopover && <PopoverReadOrUnReadMessageChat
                onClose={() => {
                    setChatPopover(null)
                }}
                anchorEl={chatPopover}
                isStatusUnRead={false}
                requestId = {chatMessage.request.id}
                unReadMessageId={chatMessage.id}
                submitReadOrUnReadMessage={() => {
                    if (Array.isArray(countChatNotSeen) && !countChatNotSeen.includes(chatMessage.request?.id)) {
                        setCountChatNotSeen([
                            ...countChatNotSeen,
                            chatMessage.request.id
                        ])
                    }
                    setChatPopover(null)
            }}/>}
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        countChatNotSeen:state.applicationReducer.countChatNotSeen,

    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setCountChatNotSeen: (value) => dispatch(application.setCountChatNotSeen(value) )
    }
};
export default compose(connect(mapStateToProps, mapDispatchToProps)) (ChatMessage);

