import React from "react";
import {components} from "react-select";
import Select from "react-select";
import i18n from "../../../i18n";
import {Tooltip} from "@material-ui/core";

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg className="MuiSvgIcon-root MuiSelect-icon" focusable="false"
                     viewBox="0 0 24 24" aria-hidden="true" ref={props.selectProps.dropdownIndicatorRef}>
                    <path d="M7 10l5 5 5-5z"></path>
                </svg>
            </components.DropdownIndicator>
        )
    );
}

const LoadingMessage = props => {
    return (
        <div
            {...props.innerProps}
            style={props.getStyles('loadingMessage', props)}
        >
            {i18n.t("text.select.loading")}
        </div>
    );
};

const Option = (props) => {
    return (
        <Tooltip content={props.label} truncateText>
            <components.Option {...props}/>
        </Tooltip>
    );
};

const NoOptionsMessage = props => {
    return (
        <div
            {...props.innerProps}
            style={props.getStyles('noOptionsMessage', props)}
        >
            {i18n.t("text.select.noOption")}
        </div>
    );
};

const styles = {
    menu: (provided) => ({
        ...provided,
        marginTop: 0,
        zIndex: 999
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    container: (provided) => ({
        ...provided,
        width: '100%'
    }),
    control: (provided, state) => ({
        ...provided,
        boxShadow: 'none'
    })
};

class AppSelect extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            menuIsOpen: props.menuIsOpen || !!props.defaultMenuIsOpen,
            closeMenuOnSelect: typeof props.closeMenuOnSelect === "undefined" || !!props.closeMenuOnSelect,
            closeMenuOnBlur: typeof props.closeMenuOnBlur === "undefined" || !!props.closeMenuOnBlur,
            forceCloseMenu: false,
            onBlur:false,
            clickedElem: null
        };
        let stateOpenStorageId = props.stateOpenStorageId?props.stateOpenStorageId:"";
        if (stateOpenStorageId){
            stateOpenStorageId = btoa(window.location.pathname+"_"+stateOpenStorageId);
            const menuIsOpen = parseInt(localStorage.getItem(stateOpenStorageId));
            if (!isNaN(menuIsOpen)){
                this.state.menuIsOpen = !!menuIsOpen;
            }
        }
        this.onMenuOpen = this.onMenuOpen.bind(this);
        this.onMenuClose = this.onMenuClose.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.dropdownIndicatorRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("click",this.handleClickedElem);
    }

    handleClickedElem = (event) => {
        this.setState({
            clickedElem:event?.target
        });
    }

    onInputChange(e,callback){
        let self = this;
        let action = e.detail.action;
        if ((typeof this.props.getInputChange === 'function') && action === 'input-change') {
            this.props.getInputChange(e.text);
        }
        const newState = {};
        switch (action) {
            case "menu-close":
                newState.forceCloseMenu = true;
                break;
            case "input-blur":
                newState.onBlur = true;
                break;
            default:
                newState.onBlur = false;
                break;
        }

        if (Object.keys(newState).length){
            this.setState(newState,function () {
                if (newState.forceCloseMenu){
                    self.onMenuClose(self.props.callback);
                }
                if ((typeof this.props.getInputChange === 'function') && action !== 'input-change') {
                    return true;
                }
                if (typeof callback === "function"){
                    callback(e.text,e.detail);
                }
            });
        }
        else if (typeof callback === "function"){
            if ((typeof this.props.getInputChange === 'function') && action !== 'input-change') {
                return true;
            }
            callback(e.text,e.detail);
        }
    }

    onMenuOpen(callback){
        let stateOpenStorageId = this.props.stateOpenStorageId?this.props.stateOpenStorageId:"";
        if (stateOpenStorageId){
            stateOpenStorageId = btoa(window.location.pathname+"_"+stateOpenStorageId);
            localStorage.setItem(stateOpenStorageId,"1");
        }
        this.setState({
            menuIsOpen: true
        },function () {
            if (typeof callback === "function"){
                callback();
            }
        });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.onBlur){
            this.setState({
                onBlur: false
            });
        }
    }

    onMenuClose(callback){
        if (this.state.closeMenuOnSelect || (this.state.forceCloseMenu && (!this.state.onBlur || this.state.closeMenuOnBlur) /*&& (this.dropdownIndicatorRef && this.dropdownIndicatorRef.current &&  (this.state.clickedElem===this.dropdownIndicatorRef.current || this.dropdownIndicatorRef.current.contains(this.state.clickedElem)))*/)){
            let stateOpenStorageId = this.props.stateOpenStorageId?this.props.stateOpenStorageId:"";
            if (stateOpenStorageId){
                stateOpenStorageId = btoa(window.location.pathname+"_"+stateOpenStorageId);
                localStorage.setItem(stateOpenStorageId,"0");
            }
            this.setState({
                menuIsOpen: false,
                forceCloseMenu: false,
            },function () {
                if (typeof callback === "function"){
                    callback();
                }
            });
        }
        else{
            // this.setState({
            //     menuIsOpen: true
            // });
        }

    }

    render() {
        let props = {
            components:{
                NoOptionsMessage,
                LoadingMessage,
                Option,
                DropdownIndicator
            },
            placeholder: i18n.t("text.select.placeholder"),
            styles: styles,
        };
        const {menuIsOpen} = this.state;
        let propsStyles = props.styles;
        if (this.props.styles){
            propsStyles = Object.assign(propsStyles,this.props.styles);
        }
        props = Object.assign(props,{...this.props,styles:propsStyles});
        return <Select
            {...props}
            onMenuOpen={()=>this.onMenuOpen(props.onMenuOpen)}
            onMenuClose={()=>this.onMenuClose(props.onMenuClose)}
            menuIsOpen={menuIsOpen || props.menuIsOpen}
            onInputChange={(text,detail)=>this.onInputChange({text:text,detail:detail},props.onInputChange)}
            dropdownIndicatorRef={this.dropdownIndicatorRef}
        />
    }
}

export default AppSelect;
