import React from 'react';
import {TextField, withStyles} from "@material-ui/core";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import {XS} from "../../../constants/constants";
// import justify from "../../../../images/justify.png"

const styles = theme => ({
    appFreeSoloAutocomplete:{
        backgroundColor: 'rgb(232, 242, 253)!important',
        border: "0 !important",
        borderRadius: 7,
        '& > div': {
            width: '100%',
        },
        "& input":{
            padding: "0 !important",
            color: '#54516a',
            fontFamily: `"Nunito", sans-serif`,
            fontSize: '1rem',
            "&::placeholder":{
                opacity:0.8,
                fontWeight: 100,
            }
        },
        "& fieldset":{
            border: "0 !important"
        },
        '& .MuiInput-underline:before': {
            content: 'none!important'
        },
        '& .MuiInput-underline:after': {
            content: 'none!important'
        },
        '& .MuiOutlinedInput-root': {
            padding: '13px 45px 11px 43px',
            position: 'relative',
            '&::before': {
                content: `''`,
                // background: `url(${justify}) no-repeat`,
                backgroundSize: 'contain',
                width: 34,
                height: 35,
                position: 'absolute',
                left: 6
            }
        },
        // "& label":{
        //     "&:not(.Mui-focused):not(.MuiFormLabel-filled)":{
        //         top: "-5px",
        //         "& + .MuiAutocomplete-inputRoot fieldset":{
        //             border: "0 !important"
        //         }
        //     }
        // }
        [theme.breakpoints.down(XS)] : {
            '& .MuiOutlinedInput-root': {
                padding: '0.5rem',
            }
        }
    }
});
const filter = createFilterOptions();

class AppFreeSoloAutocomplete extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            values: [],
            searchApi: false,
        };
        this.timeout =  0;
        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    handleSearchChange(event){
        const searchText = event.target.value;
        if(this.timeout) clearTimeout(this.timeout);
        const { apiNameList, apiParams } = this.props;
        let result =(apiParams) ? apiParams : {};
        if(searchText){
            result = Object.assign({}, result,  {search: searchText});
        }
        this.timeout = setTimeout(() => {

            axios.get(apiNameList,{params:result})
                .then(response => {
                    this.setState({
                        values: response.data.items,
                        searchApi: true
                    });
                })
                .catch(error => {

                });
        }, 300);
    }

    render() {
        const {classes,i18n,tReady,...otherProps} = this.props;
        const { values,searchApi } = this.state;
        let self = this;

        let stateSelect = {...otherProps}
        delete (stateSelect["t"])
        delete (stateSelect["inputPlaceHolder"])

        return(
        <FormControl fullWidth={!this.props.hasOwnProperty("fullWidth") || this.props.fullWidth} className={`${classes.appFreeSoloAutocomplete} ${(self.props.className) ? self.props.className : ''}`}>
            <Autocomplete
                freeSolo
                forcePopupIcon
                options={values}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            name: this.props.t('appForm.add') + ' ' + params.inputValue,
                        });
                    }
                    return filtered;
                }}
                getOptionLabel={option => {
                    if (typeof option !== 'object') {
                        return option.toString();
                    }
                    if (option.inputValue) {
                        return option.inputValue.toString();
                    }
                    return option.name.toString();
                }}
                renderOption={option => {
                    if (typeof option !== 'object') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.name
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        placeholder={ self.props.hasOwnProperty("inputPlaceHolder") ? self.props.inputPlaceHolder: '' }
                        onChange={this.handleSearchChange}
                        onClick={(searchApi) ? '' : this.handleSearchChange}
                    />

                )}
                {...stateSelect}
            />
        </FormControl>
        );
    }
}

AppFreeSoloAutocomplete.propsType = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withTranslation()(AppFreeSoloAutocomplete));
