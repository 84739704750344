import React, {Component} from 'react';
import {withStyles, Checkbox} from "@material-ui/core";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import smartPhoneIcon from "../../../../images/request/smartphone3.svg";
import CustomButton from "../../../../theme/CustomElements/CustomButton";
import {NUNITO} from "../../../../constants/fontCss";
import GeneralModal from "../../../../theme/Modal/GeneralModal";
const styles = theme => ({
    modalAlertUseSmartPhoneWrapper:{
        maxHeight: 566,
        borderRadius: 17,
        boxShadow: '0 3px 3px 0 rgba(58, 111, 158, 0.45)',
        backgroundColor: '#bbe8ff',
        '& .closeButtonModal':{
            color: '#0000f9',
            fontSize: '1.875rem',
            lineHeight: 1,
            '& > span':{
                '&:hover':{
                    color: '#fff'
                }
            }
        }
    },
    modalAlertUseSmartPhone: {
        // width: 1000px;
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '& .contentAlert': {
            fontSize: '1.6rem',
            fontFamily: NUNITO,
            fontWeight: 500,
            lineHeight: 1.1,
            letterSpacing: -0.3,
            color: '#0000f9',
            textAlign: 'center',
            padding: '1rem 2.5rem',
        },
        '& .notDisplay':{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
           
            '&.notChecked': {
           
                '& .MuiCheckbox-root': {
                    color: '#fff',
                    padding: '0.25rem',
                    '& input ~ &': {
                        boxShadow: 'none',
                        background: '#fff',
                    }
                }
            },
            '& .MuiCheckbox-root':{
                color: '#fff',
                padding:0,
                '& .MuiIconButton-label':{

                },
                '& svg':{
                    width: '3rem',
                    height: '3rem'
                }
            },
            '& svg path':{
                fill:'#0000f9',
            },
        },
        '& .labelCheckbox': {
            fontSize: '1.5rem',
            fontFamily: '"OpenSans", sans-serif',
            fontWeight: 600,
            lineHeight: 1.13,
            letterSpacing: -0.24,
            color: '#00218d',
            paddingLeft: '1rem'
        },
        '& .btnGotIt':{
            background: '#0000f9 !important',
            borderRadius: 33,
            fontSize: '1.1rem',
            margin: '1.5rem',
            padding: '1.5rem 4.5rem',
            '& .MuiButton-label':{
                fontSize: 21,
                fontWeight: 600,
                textTransform: 'uppercase',
                fontFamily: '"SegoeUI", sans-serif',
                lineHeight: 1.33,
                letterSpacing: 4.26
            }
        }
    },
    rootCheckbox: {
        // '&:hover': {
        //     backgroundColor: '#000',
        // },
    },
    iconCheckBox: {
        borderRadius: 6,
        width: '2.4rem',
        height: '2.4rem',
        backgroundColor: '#fff',
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
    },
})

class ModalAlertUseSmartPhone extends Component {
    constructor(props) {
        super(props);
        this.state={
            notDisplayCheckbox: false
        }
    }

    componentDidMount() {

    }

    handleChange (value, field){
        this.setState({
            [field]: value
        })
    }
    saveModal = () => {
        const {checkbox} = this.state
        if(checkbox) {
            if(!localStorage.getItem('notUseSmartPhone')){
                localStorage.setItem('notUseSmartPhone', 'true');
            }
        } else {
            if(!localStorage.getItem('notUseSmartPhone')){
                localStorage.setItem('notUseSmartPhone', 'temporary');
            }
        }
        this.props.handleCloseModal()
    }
    closeModal = () => {
        if(!localStorage.getItem('notUseSmartPhone')){
            localStorage.setItem('notUseSmartPhone', 'temporary');
        }
        this.props.handleCloseModal()
    }
    render() {
        let {notDisplayCheckbox} = this.state
        let { openModal, t, classes} = this.props
        return (
            <React.Fragment>
                <GeneralModal
                    className={classes.modalAlertUseSmartPhoneWrapper}
                    open={openModal}
                    handleClose={()=>this.props.handleCloseModal("openModal")}
                    hideFooter={true}
                    iconCloseModal={<span>&#10005;</span>}
                    content={
                        <div className={classes.modalAlertUseSmartPhone}>
                            <img alt={"phone"} src={smartPhoneIcon}/>
                            <div className={"contentAlert"}>{t("request.modalUseSmartphone.youPassBySmartphone")} </div>
                            <div className={`notDisplay ${notDisplayCheckbox?"": 'notChecked'}` }>
                                <Checkbox size={'medium'} value={notDisplayCheckbox}
                                          // checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                          icon={<span className={classes.iconCheckBox} />}
                                          className={classes.rootCheckbox}
                                          onChange={(e) => this.handleChange(e.target.checked, 'notDisplayCheckbox')}/>
                                <div
                                    className={"labelCheckbox"}>{t("request.modalUseSmartphone.doNotDisplayAnymore")}</div>
                            </div>
                            <CustomButton className={"btnGotIt"} title={t("request.modalUseSmartphone.iGetIt")} onClick={this.saveModal}/>

                        </div>
                    }
                    />
            </React.Fragment>

        );
    }
}
const mapStateToProps = (state) => {
    return {
         }
};
const mapDispatchToProps = (dispatch) => {
    return {
          }
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation(), withStyles(styles)) (ModalAlertUseSmartPhone);
