import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {CircularProgress, Grid, Tooltip} from "@material-ui/core";
import {withRouter} from "react-router";
import {
    API_RESTDAY_REPAIRER_LIST, API_SHOW_USER,
} from "../../../constants/apiSuffix";
import axios from "axios";
import qs from "qs";
import LoadingAction from "../../../theme/LoadingAction";
import DocumentPdfIon from "../../../images/staff/documentPdf.svg";
import CustomPagination from "../../../theme/list/CustomPagination";
import {convertToDateH_m_D_M_Y} from "../../../functions/datetime";
import {NavLink} from "react-router-dom";
import {EDIT_STAFF} from "../../../constants/links";
import excelIcon from "../../../images/staff/excel.svg";
import docIcon from "../../../images/staff/doc.svg";
import userIcon from "../../../images/infomation/user.svg"
import addressIcon from "../../../images/infomation/address.svg"
import homeIcon from "../../../images/infomation/home.svg"
import villeIcon from "../../../images/infomation/corporation.svg"
import mailIcon from "../../../images/infomation/mail.svg"
import phoneIcon from "../../../images/infomation/phone.svg"
import {convertByteToNumberUnit} from "../../../functions/functions";
import CustomInlineSvg from "../../../theme/CustomElements/CustomInlineSvg";
import goIcon from "../../../images/request/status/go.svg";
import GeneralModal from "../../../theme/Modal/GeneralModal";
import StaffModal from "./StaffModal";
import countries from "i18n-iso-countries";
import {SM, XS} from "../../../constants/constants";

const styles = theme => ({
    staffDetailPage: {
        padding: '2rem',
        [theme.breakpoints.between(XS, SM)]: {
            padding: '1rem'
        },
        '& .titleBlock':{
            fontSize: '20px',
            fontWeight: 700,
            padding: '0 1rem',
        }
    },
    blockLeftWrapper:{
        padding: '1rem',
        [theme.breakpoints.between(XS, SM)]: {
            padding: '1rem 0'
        },
    },
    block1Wrapper: {
        color: '#001529',
        background: '#FFF',
        borderRadius: 15,
        '& .avatarWrapper': {
            height: 120,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '15px 15px 0 0',
            overflow: 'hidden',
            '& img': {
                height: '100%',
                objectFit: 'contain'
            },
            '& .imgAvatarPath':{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                textTransform: 'uppercase',
                fontWeight: 900,
                fontSize: '1.5rem',
                background: '#dfdfe9',
                color: '#fff'
            }
        },
        '& .block1Body':{
            padding: '2rem',
            '& .block1Item':{
                padding: '0.3rem 0',
                alignItems: 'center'
            },
            '& .block1ItemFirst':{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                '& a':{
                    textDecoration: 'none'
                },
                '& .btnEdit':{
                    background: '#00d5ff',
                    borderRadius: 15,
                    padding: '0.25rem 1.5rem',
                    color: '#fff',
                    cursor: 'pointer',
                    '&:hover':{
                        background: '#0000c7'
                    }
                }
            },
            '& .block1ItemName':{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 700,
                '& .fullName':{
                    textAlign: 'center',
                    fontSize: '20px',
                    fontWeight: 700
                },
                '& .statusItem': {
                    '&.on':{
                        background: '#e1f2ea',
                        color: '#0DBE2A',
                    },
                    '&.off':{
                        background: '#f6e5eb',
                        color: '#DF373C',
                    },
                    textAlign: 'center',
                    borderRadius: 15,
                    padding: '0.25rem 1rem',
                    textTransform: 'uppercase',
                    marginLeft: 10
                },
            },

            '& .labelRow':{
                fontWeight: 700,
                fontSize: '1rem',
                display: 'flex',
                alignItems: 'flex-start',
                // padding: ' 0 0.5rem',
                lineHeight: '1.25rem',
                '& svg':{
                    marginRight: '0.625rem',
                    fontSize:'0.9375rem',
                    width: 20,
                    height: 20
                }
            },
            '& .contentRow':{
                fontSize: '1rem',
                display: 'flex',
                alignItems: 'flex-start'
            },
        },
    },
    block2Wrapper:{
        paddingTop: '2rem',
        color: '#001529',
        '& .block2Body': {
            background: '#FFF',
            borderRadius: 15,
            padding: '1rem',
            '& .contentDocumentsWrapper': {
                padding: '1rem 0',
                maxHeight: 285,
                overflow: 'auto',
                '& .documentItemWrapper': {
                    padding: '0.5rem 1rem',
                    '& .documentWrapper': {
                        border: ' 1px solid #E0E0E0',
                        boxSizing: 'border-box',
                        borderRadius: 15,
                        padding: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        '& img': {
                            height: 40
                        },
                        '& .docItemInfo': {
                            paddingLeft: '0.5rem',
                            display: 'flex',
                            flexDirection: 'column',
                            width: '95%',
                            // alignItems: 'center',
                            '& .nameFile': {
                                fontSize: '1rem',
                                fontWeight: 700,
                                width: '85%',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                cursor:'pointer',
                                '& a': {
                                    textDecoration: 'none'
                                }
                            },
                            '& .sizeFile': {
                                fontWeight: 600,
                                color: '#929AAE',
                                fontSize: '0.75rem'
                            }
                        }
                    }
                }
            }
        }
    },
    block3Wrapper:{
        padding: '1rem',
        color: '#001529',
        [theme.breakpoints.between(XS, SM)]: {
            padding: '1rem 0'
        },
        '& .block3Body': {
            background: '#FFF',
            borderRadius: 15,
            padding: '1rem',
            display: 'block',
            '& .contentRestDayWrapper': {
                padding: '1rem',
                '& .emptyData':{
                    fontSize: '1.1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    color: '#918b8b'
                },
                '& .restDayItemWrapper': {
                    maxHeight: 450,
                    overflow: 'auto',
                    minHeight: 100,
                    '& .loadingList':{
                        display: 'flex',
                        justifyContent: 'center',
                    },
                    '& .restDayWrapper':{
                        display: 'flex',
                        border: ' 1px solid #E0E0E0',
                        boxSizing: 'border-box',
                        borderRadius: 15,
                        padding: '1rem',
                        justifyContent: 'space-between',
                        margin: '1rem 0',
                        alignItems: 'center',
                        '& .restDayInfo':{
                            '& .titleContent':{
                                fontSize: '1rem',
                                display: 'flex',
                                cursor: 'pointer',
                                '& .subject':{
                                    fontWeight: 700,
                                },
                                '& .startEndDatetime':{
                                    paddingLeft: '0.5rem',
                                    color: '#929AAE'
                                },
                            },
                            '& .description':{
                                fontSize: '1rem',
                                marginTop: '0.625rem'
                            }
                        },
                        '& .restDayStatus':{
                            display: 'flex',
                            alignItems: 'center',
                            '& .nameFile':{
                                marginLeft: '0.625rem',
                                fontWeight: 700,
                                color: '#0DBE2A',
                                fontSize: '1.5rem'
                            }
                        },
                    }
                }
            }
        }
    },
    titleRequestModal:{
        color: '#001529',
        fontWeight: 700,
        fontSize: '1.125rem',
    },
    requestModal:{
        borderRadius: 15,
        '& .headerModalWrapper':{
           borderBottom: '1px solid #E0E0E0'
        },
        '& .bodyModalWrapper':{
            marginTop: '1.25rem'
        }
    }
});

class StaffDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            userInfo: {},
            currentPage: 1,
            pageSize:  10,
            totalPages:  1,
            totalCount: 1,
            apiParams: {},
            dataRestDayByUser: [],
            isLoadingRestDayList: false,
            modalRequest: false,
            dataModal: null
        };
        this.getData = this.getData.bind(this);
    }

    onCurrentPageChange = (value) => {
        this.setState({
            currentPage: value,
            isLoadingRestDayList: true
        }, function (){
            this.getRestDayListByUser();
        })
    }
    onPageSizeChange = (value) => {
        this.setState({
            pageSize: value,
            isLoadingRestDayList: true
        }, function (){
            this.getRestDayListByUser();
        })
    }


    getRestDayListByUser = () => {
        const urlRestday = API_RESTDAY_REPAIRER_LIST + "?" + qs.stringify({
            maxPerPage: this.state.pageSize,
            account: this.props.match.params.id,
            page: this.state.currentPage,
        })
        axios.get(urlRestday)
            .then(res => {
                let stateNew = {...this.state};
                stateNew.isLoadingRestDayList = false;
                if (res.status === 200) {
                    const resData2 = res.data;
                    stateNew = {
                        ...stateNew,
                        dataRestDayByUser: resData2.items ?? [],
                        currentPage: resData2.currentPage ?? 1,
                        pageSize: resData2.maxPerPage ?? 10,
                        totalPages: resData2.nbPages ?? 1,
                        totalCount: resData2.nbResults ?? 1,
                    }
                }
                this.setState(stateNew)
            })
            .catch(err => {
                // console.log(err);
                // console.log(err.response)
            })
    }

    getData(notLoading) {
        if(!notLoading) {
            this.setState({
                isLoading: true
            })
        }

        const urlUser = API_SHOW_USER.replace("{id}", this.props.match.params.id)
        const urlRestday = API_RESTDAY_REPAIRER_LIST + "?" + qs.stringify({
            maxPerPage: this.state.pageSize,
            account: this.props.match.params.id,
            page:this.state.currentPage,
        })
        axios.all([
            axios.get(urlUser),
            axios.get(urlRestday)
        ])
            .then(res => {
                let stateNew  = {...this.state};
                stateNew.isLoading= false;
                if(res[0].status === 200){
                    stateNew.userInfo = res[0].data.item;
                }
                if(res[1].status === 200){
                    const resData2 = res[1].data;
                    stateNew = {
                        ...stateNew,
                        dataRestDayByUser: resData2.items??[],
                        currentPage: resData2.currentPage??1,
                        pageSize: resData2.maxPerPage??10,
                        totalPages: resData2.nbPages??1,
                        totalCount: resData2.nbResults??1,
                    }
                }
                this.setState(stateNew)
            })
            .catch(err=>{
                // console.log(err);
                // console.log(err.response)
            })
    }
    handleOpenModal = (data) => {
        this.setState({
            modalRequest: true,
            dataModal: data
        })
    }
    handleCloseModal = () => {
        this.setState({
            modalRequest: false
        })
    }

    componentDidMount() {
        this.getData(this.state.dateShowInCalendar)
    }


    render() {
        const {
            classes, t
        } = this.props;
        const {
            userInfo,
            isLoading,
            totalCount,
            totalPages,
            currentPage,
            pageSize,
            dataRestDayByUser,
            isLoadingRestDayList,
            modalRequest,
            dataModal
        } = this.state;
        const personInfo = userInfo.person ?? {};
        const altImg = userInfo.person && userInfo.person.fullName ? userInfo.person.fullName :( userInfo.email ?  userInfo.email :"");
        const documents = personInfo.documents ?? []
        return (
            <div className={classes.staffDetailPage}>
                {isLoading && <LoadingAction /> }
                <Grid container className={"contentInformationPage"}>
                    <Grid container item sm={12} md={4}  className={classes.blockLeftWrapper}>
                    <Grid container item xs={12}  className={classes.block1Wrapper}>
                        <div className={"avatarWrapper"}>
                            {personInfo.avatar && personInfo.avatar.pathUrl ? <img src={personInfo.avatar.pathUrl}
                                                                                   alt={altImg}/> :
                                <div className={"imgAvatarPath"}> {altImg}</div>
                            }
                        </div>
                        <Grid container item xs={12}  className={"block1Body"}>
                            <Grid item xs={12} className={"block1Item block1ItemFirst"}>
                                <div className={"block1ItemName"}>
                                    <div className={"fullName"}>{personInfo.fullName}</div>
                                    {
                                        userInfo.enabled ?
                                            <div
                                                className={"statusItem on"}>{t("reactAdmin.user.detail.block1.enabledStatus.on")}</div>
                                            : <div
                                                className={"statusItem off"}>{t("reactAdmin.user.detail.block1.enabledStatus.off")}</div>

                                    }
                                </div>
                                <NavLink to={EDIT_STAFF.replace(":id", userInfo.id)}><div className={"btnEdit"}>
                                    {t("reactAdmin.user.detail.block1.btnEdit")}
                                </div></NavLink>
                            </Grid>
                            <Grid container item xs={12} className={"block1Item"}>
                                <Grid item xs={5} className={"labelRow"}>
                                    <CustomInlineSvg
                                        svgProps={{src: userIcon}}
                                    />
                                    {/*<PersonIcon/>*/}
                                    <div>{t("reactAdmin.user.detail.block1.roles")}</div>
                                </Grid>
                                <Grid item xs={7} className={"contentRow"}>
                                    {t("reactAdmin.user.groups." + userInfo.instanceof)}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} className={"block1Item"}>
                                <Grid item xs={5} className={"labelRow"}>
                                    {/*<LocationOnIcon/>*/}
                                    <CustomInlineSvg
                                        svgProps={{src: addressIcon}}
                                    />
                                    <div>{t("reactAdmin.user.detail.block1.addressCountry")}</div>
                                </Grid>
                                <Grid item xs={7} className={"contentRow"}>
                                    {personInfo.addressCountry ? countries.getName(personInfo.addressCountry, t('translations.lang'))  : ""}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} className={"block1Item"}>
                                <Grid item xs={5} className={"labelRow"}>
                                    {/*<LocationOnIcon/>*/}
                                    <CustomInlineSvg
                                        svgProps={{src: addressIcon}}
                                    />
                                    <div>{t("reactAdmin.user.detail.block1.addressCity")}</div>
                                </Grid>
                                <Grid item xs={7} className={"contentRow"}>
                                    {personInfo.addressCity ? personInfo.addressCity  : ""}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} className={"block1Item"}>
                                <Grid item xs={5} className={"labelRow"}>
                                    {/*<LocationOnIcon/>*/}
                                    <CustomInlineSvg
                                        svgProps={{src: homeIcon}}
                                    />
                                    <div>{t("reactAdmin.user.detail.block1.addressPostalCode")}</div>
                                </Grid>
                                <Grid item xs={7} className={"contentRow"}>
                                    {personInfo.addressPostalCode ? personInfo.addressPostalCode  : ""}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} className={"block1Item"}>
                                <Grid item xs={5} className={"labelRow"}>
                                    {/*<LocationOnIcon/>*/}
                                    <CustomInlineSvg
                                        svgProps={{src: villeIcon}}
                                    />
                                    <div>{t("reactAdmin.user.detail.block1.addressDescription")}</div>
                                </Grid>
                                <Grid item xs={7} className={"contentRow"}>
                                    {personInfo.addressDescription ? personInfo.addressDescription  : ""}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} className={"block1Item"}>
                                <Grid item xs={5} className={"labelRow"}>
                                    {/*<PhoneIcon/>*/}
                                    <CustomInlineSvg
                                        svgProps={{src: phoneIcon}}
                                    />
                                    <div>{t("reactAdmin.user.detail.block1.phoneNumber")}</div>
                                </Grid>
                                <Grid item xs={7} className={"contentRow"}>
                                    {personInfo ? personInfo.phoneNumber : ""}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} className={"block1Item"}>
                                <Grid item xs={5} className={"labelRow"}>
                                    {/*<MailIcon/>*/}
                                    <CustomInlineSvg
                                        svgProps={{src: mailIcon}}
                                    />
                                    <div>{t("reactAdmin.user.detail.block1.email")}</div>
                                </Grid>
                                <Grid item xs={7} className={"contentRow"}>
                                    {userInfo ? userInfo.email : ""}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                        <Grid container item xs={12} className={classes.block2Wrapper}>
                            <Grid container item xs={12} className={"block2Body"}>
                                <div className={"titleBlock"}>{t("reactAdmin.user.detail.block2.title")}</div>
                                <Grid container item xs={12} className={"contentDocumentsWrapper"}>
                                    {/*<Grid container item xs={6} className={"documentItemWrapper"}>*/}
                                        {
                                            documents.length > 0 && documents.map((item, key)=>{
                                                return <Grid container item xs={6} className={"documentItemWrapper"} key={key}>
                                                        <div className={"documentWrapper"}>
                                                            <img src={
                                                                item.pathUrl ?
                                                                    (item.pathUrl.includes(".xls") || item.pathUrl.includes(".xlsm") || item.pathUrl.includes(".xlsx")) ?
                                                                        excelIcon :
                                                                        (item.pathUrl.includes(".doc") || item.pathUrl.includes(".docx") || item.pathUrl.includes(".dotx")) ?
                                                                            docIcon : DocumentPdfIon
                                                                    : DocumentPdfIon
                                                            }
                                                                 alt=""
                                                            />
                                                            <div className="docItemInfo">
                                                                <Tooltip title={item.originalName} arrow>
                                                                    <div className={"nameFile"}><a className={"certificate"} target={"_blank"} rel="noreferrer"
                                                                                                   href={item.pathUrl}>{item.originalName}</a></div>
                                                                </Tooltip>
                                                                <div className={"sizeFile"}>{item.fileSize && convertByteToNumberUnit(item.fileSize)}</div>
                                                            </div>
                                                        </div>
                                                </Grid>
                                            })
                                        }
                                        {/*<div className={"documentWrapper"}>*/}
                                        {/*    <img src={DocumentPdfIon} alt=""/>*/}
                                        {/*    <div className="docItemInfo">*/}
                                        {/*        <div className={"nameFile"}>bfhdjfh.pdf</div>*/}
                                        {/*        <div className={"sizeFile"}>12Mb</div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    {/*</Grid>*/}
                                    {/*<Grid container item xs={6} className={"documentWrapper"}>*/}
                                    {/*</Grid>*/}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item sm={12} md={8} className={classes.block3Wrapper}>
                        <Grid container item xs={12} className={"block3Body"}>
                            <div className={"titleBlock"}>{t("reactAdmin.user.detail.block3.title")}</div>
                            {dataRestDayByUser && dataRestDayByUser.length > 0 ?
                                <Grid container item xs={12} className={"contentRestDayWrapper"}>
                                    <Grid item xs={12} className={"restDayItemWrapper"}>
                                        { isLoadingRestDayList ?
                                            <div className={"loadingList"}><CircularProgress /></div>:
                                            dataRestDayByUser.map((item, indexRestDay) => {
                                                return (
                                                    <div className={"restDayWrapper"} key={indexRestDay}>
                                                        <div className="restDayInfo">
                                                            <div className={"titleContent"} onClick={()=>this.handleOpenModal(item)}>
                                                                <div className={"subject"}>{item.subject ?? ""}</div>
                                                                <div
                                                                    className={"startEndDatetime"}>{convertToDateH_m_D_M_Y(item.startRestAt)} - {convertToDateH_m_D_M_Y(item.endRestAt)}</div>
                                                            </div>
                                                            <div
                                                                className={"description"}>{item.description ?? ""}</div>
                                                        </div>
                                                        <div className="restDayStatus">
                                                            <CustomInlineSvg
                                                                svgProps={{src: goIcon}}
                                                            />
                                                            <div className={"nameFile"}>{t('request.status.REPAIRED')}</div>
                                                        </div>
                                                    </div>
                                                )

                                            })
                                        }

                                    </Grid>
                                    <CustomPagination
                                        onCurrentPageChange={this.onCurrentPageChange}
                                        onPageSizeChange={this.onPageSizeChange}
                                        // setPageSize={this.setPageSize}
                                        totalCount={totalCount}
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        pageSize={pageSize}
                                    />
                                </Grid>
                                :
                                <Grid item xs={12} className="contentRestDayWrapper">
                                    <div className={"emptyData"}>
                                        {t("text.noResult")}
                                    </div>
                                </Grid>}
                        </Grid>
                    </Grid>

                </Grid>
                {modalRequest && <GeneralModal
                    className={classes.requestModal}
                    open={modalRequest}
                    handleClose={()=>this.handleCloseModal()}
                    // enableOnClose={false}
                    title={<span className={classes.titleRequestModal}>{dataModal && dataModal.hasOwnProperty("subject") && dataModal["subject"]}</span>}
                    content={<StaffModal data={dataModal}/>}
                    cancelButton={false}
                    saveButton={false}
                />}
            </div>
        )
    }

}

StaffDetail.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(
    withStyles(styles),
    withTranslation(),
    withRouter
)(StaffDetail);