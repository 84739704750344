import React, {Component} from 'react';
import {compose} from "redux";
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import crypto from "crypto";
import {
    BASE_URL,
    CURRENCY_DEFAULT, PAYMENT_CARD_MASTERCARD, PAYMENT_CARD_VISA, PAYMENT_REPORT,
    PAYMENT_SOGE_COMMERCE_KEY,
    PAYMENT_SOGE_COMMERCE_SHOP_ID, PAYMENT_TYPE_SOGE_COMMERCE,
    PRICE_DEFAULT
} from "../../../../constants/constants";
import moment from "moment";
import {
    CHECK_PAYMENT_SOGE_COMMERCE,
    GET_SIGNATURE_SOGE_COMMERCE,
    GET_SIGNATURE_SOGE_COMMERCE_REPORT, GET_SIGNATURE_SOGE_COMMERCE_REPORT_NOT_LOGIN
} from "../../../../constants/apiSuffix";
import * as links from "../../../../constants/links";
import axios from "axios";
import {CircularProgress} from "@material-ui/core";
import {connect} from "react-redux";
const styles = theme => ({
    sogeCommercePayment: {
        height: '100%',
        width: '100%',
        overflow: "auto"
    },
    loadingWrapper: {

    },
    paymentIframe:{
        minHeight: 400,
        minWidth: 400
    }
})

const currencyOptions = {
    AUD: "036",
    CAD: "124",
    CNY: "156",
    DKK: "208",
    HKD: "344",
    INR: "356",
    JPY: "392",
    KRW: "410",
    KWD: "414",
    MYR: "458",
    MXN: "484",
    NZD: "554",
    NOK: "554",
    RUB: "643",
    SGD: "702",
    ZAR: "710",
    SEK: "752",
    CHF: "756",
    THB: "764",
    GBP: "826",
    USD: "840",
    EUR: "978",
    BRL: "986",
}

class SogeCommercePayment extends Component {
    constructor(props) {
        super(props);
        this.state={
            isLoadingSignature: true,
            errorPayment: false,
            dataSoge: {
                vads_action_mode: null,
                vads_cust_first_name: null,
                vads_cust_last_name: null,
                vads_cust_cell_phone: null,
                vads_order_id: null,
                vads_amount: null,
                vads_ctx_mode: null,
                vads_currency: null,
                vads_iframe_options: null,
                vads_language: null,
                vads_page_action: null,
                vads_payment_cards: null,
                vads_payment_config: null,
                vads_return_mode: null,
                vads_site_id: null,
                vads_theme_config: null,
                vads_trans_date: null,
                vads_trans_id: null,
                vads_version: null,
                vads_redirect_success_timeout: null,
                vads_redirect_error_timeout: null,
                vads_url_success: null,
                vads_url_return: null,
                signature: null,
            }
        };
        this.btnSubmitRef = React.createRef();
        this.submitFormSoge = this.submitFormSoge.bind(this);
    }

    componentDidMount() {
        this.setState({
            isLoadingSignature: true
        })
        const {paymentType, listReportPayment, infoPayer, dataUser } = this.props;
        const linkPaymentFront = paymentType === PAYMENT_REPORT ? window.location : window.location ? window.location.origin+links.INFORMATION_PAGE.replace(":id", this.props.requestId) + "?tab=payment" : null;
        const formData = new FormData();
        console.log("SogeCommercePayment componentDidMount",this.props)
        formData.append("paymentPrice", this.props.paymentPrice);
        formData.append("paymentCard", this.props.paymentCard);
        formData.append("linkPaymentFront", linkPaymentFront);
        formData.append("language", this.props.t('language'));
        formData.append("baseUrl", BASE_URL);
        const self = this;
        if (paymentType === PAYMENT_REPORT) {
            listReportPayment.forEach((item, index) => {
                formData.append("listReportPayment["+index+"]", item);
            })
            if (infoPayer) {
                for (let [key, value] of Object.entries(infoPayer)) {
                    formData.append(`infoPayer[${key}]`, value);
                }
            }
            axios.post(BASE_URL + (dataUser ? GET_SIGNATURE_SOGE_COMMERCE_REPORT : GET_SIGNATURE_SOGE_COMMERCE_REPORT_NOT_LOGIN), formData)
                .then(res => {
                    if (res.status === 200) {
                        let dataParams = res.data;
                        console.log("SogeCommercePayment componentDidMount RETURN",BASE_URL + (dataUser ? GET_SIGNATURE_SOGE_COMMERCE_REPORT : GET_SIGNATURE_SOGE_COMMERCE_REPORT_NOT_LOGIN),dataParams)
                        this.setState({
                            dataSoge: {
                                ...dataParams
                            },
                            isLoadingSignature: false
                        }, () => {
                            if (this.state.dataSoge.signature != null) {
                                this.submitFormSoge();
                            }
                        });
                    } else {
                        throw new Error();
                    }
                })
                .catch(err => {
                    self.setState({
                        errorPayment: true,
                        isLoadingSignature: false
                    })
                })
        } else {
            axios.post(BASE_URL + GET_SIGNATURE_SOGE_COMMERCE.replace("{id}", this.props.requestId), formData)
                .then(res => {
                    if (res.status === 200) {
                        let dataParams = res.data;
                        this.setState({
                            dataSoge: {
                                ...dataParams
                            },
                            isLoadingSignature: false
                        }, () => {
                            if (this.state.dataSoge.signature != null) {
                                this.submitFormSoge();
                            }
                        });
                    } else {
                        throw new Error();
                    }
                })
                .catch(err => {
                    self.setState({
                        errorPayment: true,
                        isLoadingSignature: false
                    })
                })
        }
    }

    submitFormSoge() {
        if (this.btnSubmitRef.current) {
            this.btnSubmitRef.current.click();
        }
    }

    render() {
        const {classes, t} = this.props;
        const {
            errorPayment,
            isLoadingSignature
        } = this.state;
        const {
            vads_action_mode,
            vads_amount,
            vads_ctx_mode,
            vads_currency,
            vads_iframe_options,
            vads_language,
            vads_page_action,
            vads_payment_cards,
            vads_payment_config,
            vads_return_mode,
            vads_site_id,
            vads_theme_config,
            vads_trans_date,
            vads_trans_id,
            vads_version,
            vads_redirect_success_timeout,
            vads_redirect_error_timeout,
            vads_url_success,
            vads_url_return,
            signature,
            vads_order_id,
            vads_cust_first_name,
            vads_cust_last_name,
            vads_cust_cell_phone,
        } = this.state.dataSoge;

        // console.log({
        //     vads_action_mode,
        //     vads_amount,
        //     vads_ctx_mode,
        //     vads_currency,
        //     vads_iframe_options,
        //     vads_language,
        //     vads_page_action,
        //     vads_payment_cards,
        //     vads_payment_config,
        //     vads_return_mode,
        //     vads_site_id,
        //     vads_theme_config,
        //     vads_trans_date,
        //     vads_trans_id,
        //     vads_version,
        //     vads_redirect_success_timeout,
        //     vads_redirect_error_timeout,
        //     vads_url_success,
        //     vads_url_return,
        //     signature,
        //     vads_order_id,
        //     vads_cust_first_name,
        //     vads_cust_last_name,
        //     vads_cust_cell_phone,
        // } )

        return (
            <React.Fragment>
                {
                    isLoadingSignature
                        ?
                        <div className={classes.loadingWrapper}>
                            <CircularProgress />
                        </div>
                        :
                        <div className={classes.sogeCommercePayment}>
                            <form
                                name="member_signup"
                                method="POST"
                                action="https://sogecommerce.societegenerale.eu/vads-payment/"
                                target="paymentFrame"
                                // ref={this.btnSubmitRef}
                            >
                                {/*<input type="hidden" name="vads_action_mode" value={vads_action_mode}/>*/}
                                {/*<input type="hidden" name="vads_cust_first_name" value={vads_cust_first_name}/>*/}
                                {/*<input type="hidden" name="vads_cust_last_name" value={vads_cust_last_name}/>*/}
                                {/*<input type="hidden" name="vads_cust_cell_phone" value={vads_cust_cell_phone}/>*/}
                                {/*<input type="hidden" name="vads_order_id" value={vads_order_id}/>*/}
                                {/*<input type="hidden" name="vads_amount" value={vads_amount}/>*/}
                                {/*<input type="hidden" name="vads_ctx_mode" value={vads_ctx_mode}/>*/}
                                {/*<input type="hidden" name="vads_currency" value={vads_currency}/>*/}
                                {/*<input type="hidden" name="vads_page_action" value={vads_page_action}/>*/}
                                {/*<input type="hidden" name="vads_payment_cards" value={vads_payment_cards}/>*/}
                                {/*<input type="hidden" name="vads_payment_config" value={vads_payment_config}/>*/}
                                {/*<input type="hidden" name="vads_site_id" value={vads_site_id}/>*/}
                                {/*<input type="hidden" name="vads_trans_date" value={vads_trans_date}/>*/}
                                {/*<input type="hidden" name="vads_trans_id" value={vads_trans_id}/>*/}
                                {/*<input type="hidden" name="vads_version" value={vads_version}/>*/}
                                {/*<input type="hidden" name="vads_return_mode" value={vads_return_mode}/>*/}
                                {/*<input type="hidden" name="vads_theme_config" value={vads_theme_config}/>*/}
                                {/*/!*<input type="hidden" name="vads_iframe_options" value={vads_iframe_options}/>*!/*/}
                                {/*<input type="hidden" name="vads_language" value={vads_language}/>*/}
                                {/*<input type="hidden" name="signature" value={signature}/>*/}

                                {/*/!*return payment response*!/*/}
                                {/*<input type="hidden" name="vads_redirect_success_timeout"*/}
                                {/*       value={vads_redirect_success_timeout}/>*/}
                                {/*<input type="hidden" name="vads_redirect_error_timeout"*/}
                                {/*       value={vads_redirect_error_timeout}/>*/}
                                {/*<input type="hidden" name="vads_url_success" value={vads_url_success}/>*/}
                                {/*<input type="hidden" name="vads_url_return" value={vads_url_return}/>*/}
                                {Object.keys(this.state.dataSoge).map((key)=>{
                                    return <input key={`dataSoge-${key}`} type={"hidden"} name={key} value={this.state.dataSoge[key]}/>
                                })}
                                <button
                                    type="submit"
                                    name="pay"
                                    ref={this.btnSubmitRef}
                                    style={{
                                        display: 'none'
                                    }}
                                >{"Pay"}</button>
                            </form>
                            <iframe
                                src={""}
                                className={classes.paymentIframe}
                                sandbox
                                // scrolling="no"
                                name="paymentFrame"
                            />
                        </div>
                }
            </React.Fragment>
        );
    }
}
SogeCommercePayment.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
    return {
        dataUser: state.authReducer.dataUser,
    };
};
export default compose(
    connect(mapStateToProps, null),
    withStyles(styles),
    withTranslation()
)(SogeCommercePayment);
