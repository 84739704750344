import React from "react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import i18n from './../../i18n';
// import "@ckeditor/ckeditor5-build-classic/build/translations/fr";

class AppCKEditor extends React.Component{
    // eslint-disable-next-line no-useless-constructor
    // constructor(props) {
    //     super(props);
    // }

    render() {
        let props = {
            editor:ClassicEditor,
            config:{
                language:i18n.language
            }
        };
        let classProps = {...this.props};
        if (classProps.config){
            classProps.config = Object.assign(props.config,classProps.config);
        }

        props = Object.assign(props,classProps);
        return <CKEditor
            {...props}
        />
    }
}

export default AppCKEditor;