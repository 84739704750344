import React from 'react'
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import * as applicationAction from "../../../../_actions/application";
import qs from "qs"
import { makeStyles } from "@material-ui/core/styles";
import {
    ADD_PARAMS_FILTERS_LIST,
    REMOVE_PARAMS_FILTERS_LIST, STATUS_ALL_LIST,
    USER_TYPE_CLIENT,
} from '../../../../constants/constants';
import chatIcon from "../../../../images/request/chat.svg"
import CustomSvg from "../../../../theme/CustomElements/CustomSvg";
import {PARAM_REQUEST_LIST_LOCALSTORAGE} from "../RequestPage";
const useStyles = makeStyles(theme => ({
    '@keyframes blinker': {
        from: {opacity: 0.2},
        '20%': {opacity: 1},
        '80%': {opacity: 1},
        to: {opacity: 0.2}
    },
    chatInfo: {
        color: '#ff3a7b',
        cursor: 'pointer',
        borderRadius: 25,
        padding: '4px 4px 4px 20px',
        width: 'fit-content',
        lineHeight: 1,
        fontWeight: 'bold',
        // animation: '$blinker 1s linear infinite',
        display: 'flex',
        alignItems: 'center',
        transition:"0.1s ease",
        position: 'relative',
        '& svg':{
            // marginLeft: 8,
            marginTop: 8,
            '& path':{
                fill: '#fff !important'
            }
        },

        '&:hover:not(.checkedDiv)': {
            // background: 'rgba(0,0,0,0.1) !important',
            background: 'transparent !important',
           // transform: 'scale(1.1)',
            transition:"0.1s ease",

        },
        '& .numberCircle':{
            background: '#ff0077',
            borderRadius: '50%',
            width: 25,
            height: 25,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 13,
            color: '#fff',
            position: 'absolute',
            zIndex: '1',
            top: '3px',
            left: '32px',
            transition: '0.2s ease',
            border: '1px solid #192f8a',
            // [the3px
        },

        '&:hover':{
            '& .numberCircle':{
                transition: '0.2s ease',
                left: '34px',
                '&~ svg path':{
                    fill: '#ff0077 !important'
                }
            },
        },

        '&.notAlertNew':{
            animation: 'none',
            background: 'transparent',
            pointerEvents:'none',
            transition:"0.2s ease",
            '& .numberCircle':{
                background: '#94a3ff4d',
                color: '#1d3694',
                display: 'none',
            },
            '& svg':{
                '& path':{
                    fill: '#4156b2 !important'
                }
            },

        },
        '&:not(.notAlertNew, .checkedDiv)':{
            '&:hover':{
                background: '#7b60ff3d',
                transition:"0.1s ease",
            },
        },
        '&.checkedDiv': {
            background: '#7b60ff3d',
            pointerEvents: 'none',
            minWidth: 100
        },
        '& .closeFilterWrapper':{
            color: 'rgba(255, 255, 255, 0.5)',
            fontSize: '21px',
            lineHeight: '1',
            marginLeft: '5px',
            fontWeight: '300',
            padding: '2px 12px',
            pointerEvents: 'initial !important',

            '&:hover': {
                color: 'rgba(255, 255, 255, 0.9)',
            },
        }
    },

}));

function AlertCountChatNotSeen({ location, countChatNotSeen, t,apiParamsList,dataUser, ...propsData }) {
    const classes = useStyles();
    let urlParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const groupUser = dataUser && dataUser.data ? dataUser.data.group : "";
    const fieldFilter = groupUser === USER_TYPE_CLIENT ? "clientSawManagerLastChat" : "managerSawClientLastChat"
    const enableFilterChatCount =  urlParams.hasOwnProperty(fieldFilter) && urlParams[fieldFilter] === "false";

    const actionFilter = () => {
        if(countChatNotSeen.length || countChatNotSeen.length <1) {
            if (enableFilterChatCount) {
                delete (urlParams[fieldFilter])
            } else {
                urlParams = {...urlParams, [fieldFilter]: "false"}
                if(propsData?.changeStatusToAllFilter && typeof propsData.changeStatusToAllFilter === "function") {
                    propsData.changeStatusToAllFilter()
                }
            }
            let pageInfos = localStorage.getItem(PARAM_REQUEST_LIST_LOCALSTORAGE);
            pageInfos = !pageInfos ? {} : JSON.parse(pageInfos);
            const filterListLocal = pageInfos["filters"] ? pageInfos["filters"]: {}
            pageInfos = {
                ...pageInfos, filters: { ...filterListLocal,
                    statusCode: STATUS_ALL_LIST
                }
            }
            localStorage.setItem(PARAM_REQUEST_LIST_LOCALSTORAGE, JSON.stringify(pageInfos));
            propsData.setForceUpdateListChangeApiParamsRedux(true)
            propsData.history.push(location.pathname + '?' + qs.stringify(urlParams));
            propsData.getCountRequestByStatus({...apiParamsList,...urlParams});
        }
    }
    return (
        <div className={`${classes.chatInfo} ${countChatNotSeen.length <1? "notAlertNew":""} ${ enableFilterChatCount ? "checkedDiv" : ""}`} onClick={() => {
            if (!enableFilterChatCount) {
                actionFilter()
            }}}>
            <div className={"numberCircle"}>{countChatNotSeen.length < 100 ?countChatNotSeen.length: "99+"}</div>
            <CustomSvg
                svgProps={{
                    src: chatIcon,
                    title:t("request.chatNotSeen")
                }}
            />
            {enableFilterChatCount && <div className={"closeFilterWrapper"} onClick={() => actionFilter()}>
                &#x00d7;
            </div>}
            {/*{countChatNotSeen.length ?? 0} {t("request.chatNotSeen")}*/}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        countChatNotSeen: state.applicationReducer.countChatNotSeen,
        dataUser: state.authReducer.dataUser
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setForceUpdateListChangeApiParamsRedux: (value) => dispatch(applicationAction.setForceUpdateListChangeApiParamsRedux(value)),
        getCountRequestByStatus: (apiParamCount) => dispatch(applicationAction.getCountRequestByStatus(apiParamCount)),
    }
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation(), withRouter)(AlertCountChatNotSeen)
