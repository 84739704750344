import {DatagridBody, Create, Datagrid, Toolbar, Edit, SaveButton,
    // useNotify, useRedirect,
    EditButton, List, SimpleForm, TextField, TextInput, required} from "react-admin";
import React from "react";
import Pagination from "../Pagination";

import { withStyles } from '@material-ui/core/styles';
import {styleCreate, styleEdit, styleList} from "../style";
import {MyDatagridRow} from "../MyDatagridRow";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import ListEmpty from "./ListEmpty";
import {ListActions} from "../custom/customActionsList";

const MyDatagridBody = props => <DatagridBody {...props} row={<MyDatagridRow />} />;
const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody />} />;
const arrayColumn = [
    {
        source: 'name',
        label: 'reactAdmin.lease_type.name'
    }
];
const CustomToolbar = props => {
    const {t, ...newProps} = props
    return <Toolbar {...newProps}>
        <SaveButton className={"btnSave"} label={props.t("button.save")}/>
    </Toolbar>
};
export const LeaseTypeList = compose(withStyles(styleList), withTranslation())(({ classes, t, ...props }) => {
    const {tReady, ...newProps} = props
    return (
        <List {...newProps}
              empty={<ListEmpty arrayColumn={arrayColumn} titleList={``}/>}
              actions={<ListActions t={t} classes={classes} props={newProps}/>}
            // filters={<CustomFilters classes={classes} t={t} titleList={LABEL_CATEGORY}/>}
              pagination={<Pagination {...props}/>} className={classes.listWrapper}
        >
            <Datagrid className={classes.tableWrapper}>
                {arrayColumn.map((item, key) =>
                    <TextField key={key} source={item.source} label={t(item.label)} sortable={false}/>
                )}
                {/*<TextField source="code" label={t('admin.label.code')}/>*/}
                <EditButton className={classes.editButton}/>
            </Datagrid>
        </List>
    )
});

export const LeaseTypeCreate = compose(withStyles(styleCreate), withTranslation())(({ classes, t, ...props }) => {
    // const notify = useNotify();
    // const redirect = useRedirect();
    // const onSuccess = () => {
    //     notify(t("form.success.save_ok"))
    //     redirect('list', props.basePath);
    // }
    // const onError = (error) => {
    //     notify(t("form.error.occurred"), 'error')
    // }
    const {tReady, ...newProps} = props

    return <Create {...newProps}  className={classes.createWrapper}>
        <SimpleForm className={classes.createSimpleForm} redirect="list">
            <Form t={t} classes={classes} {...props}/>
        </SimpleForm>
    </Create>
});

export const LeaseTypeEdit = compose(withStyles(styleEdit), withTranslation())(({ classes, t, ...props }) => {
    // const notify = useNotify();
    // const redirect = useRedirect();
    // const onSuccess = () => {
    //     notify(t("form.success.save_ok"))
    //     redirect('list', props.basePath);
    // }
    // const onError = (error) => {
    //         notify(t("form.error.occurred"), 'error')
    // }
    const {tReady, ...newProps} = props

    return <Edit {...newProps} undoable={false}  className={classes.editWrapper}>
        <SimpleForm className={classes.editSimpleForm} toolbar={<CustomToolbar t={t}/>}>
            <Form t={t} classes={classes} {...props} isFormEdit={true}/>
        </SimpleForm>
    </Edit>
});

const Form = ({t, ...props}) => {
    return (
        <React.Fragment>
            <TextInput source="name" fullWidth label={t('reactAdmin.categories.name')} validate={[required()]}/>
        </React.Fragment>
    )
}
