import React from "react";
import ReactAvatar from "react-avatar";
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";

const styles = {
    avatar: {

    }
};

class AppAvatar extends React.Component{
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const {classes,...otherProps} = this.props;
        // console.log(this.props)
        const defaultProps = {
            round: true,
            fgColor: "#0000ff",
            color: "white"
        };
        return <ReactAvatar className={classes.avatar+" "+otherProps.classeName?otherProps.classeName:""} {...defaultProps} {...otherProps}/>;
    }
}

PropTypes.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppAvatar);
