export const SET_IS_LOADING = 'SET_IS_LOADING';

export const SET_PROBLEM_DESCRIBE = 'SET_PROBLEM_DESCRIBE';
export const SET_PROBLEM_DESCRIBE_REGIS = 'SET_PROBLEM_DESCRIBE_REGIS'

export const SET_SERVER_500 = 'SET_SERVER_500';
export const GET_COUNT_REQUEST_BY_STATUS = 'GET_COUNT_REQUEST_BY_STATUS';
export const ERROR_ALERT = 'ERROR_ALERT';
export const SUCCESS_ALERT = 'SUCCESS_ALERT';
export const SET_SHOW_NOTE_CELL = 'SET_SHOW_NOTE_CELL';
export const SET_SHOW_ALL_NOTE = 'SET_SHOW_ALL_NOTE';
export const GET_DOCUMENT_REQUEST_VALID = 'GET_DOCUMENT_REQUEST_VALID'
export const GET_DOCUMENT_REQUEST_REGISTRATION = 'GET_DOCUMENT_REQUEST_REGISTRATION'
export const GET_PARAMETER_CURRENCY = 'GET_PARAMETER_CURRENCY'
export const SET_DATE_SELECTED = 'SET_DATE_SELECTED';
export const SET_OPEN_MODAL_NEW_REQUEST_IN_LIST = 'SET_OPEN_MODAL_NEW_REQUEST_IN_LIST'
export const SET_CLOSE_NOTICE = 'SET_CLOSE_NOTICE'
export const SET_OPEN_NOTICE_SUCCESS = 'SET_OPEN_NOTICE_SUCCESS'
export const SET_OPEN_NOTICE_ERROR = 'SET_OPEN_NOTICE_ERROR'
export const SHOW_PARAMETER_CONTACT = 'SHOW_PARAMETER_CONTACT';
export const TOGGLE_NOTIFICATION = 'TOGGLE_NOTIFICATION';

export const CREATE_REPORT = 'CREATE_REPORT';
export const DETAIL_REPORT = 'DETAIL_REPORT';

export const GET_COUNT_REPORT_REQUEST_NOT_PAID = 'GET_COUNT_REPORT_REQUEST_NOT_PAID';
export const SET_COUNT_REPORT_REQUEST_NOT_PAID = 'SET_COUNT_REPORT_REQUEST_NOT_PAID';

export const GET_COUNT_CHAT_NOT_SEEN = 'GET_COUNT_CHAT_NOT_SEEN';
export const SET_COUNT_CHAT_NOT_SEEN = 'SET_COUNT_CHAT_NOT_SEEN';

export const SET_FORCE_UPDATE_LIST_REDUX = 'SET_FORCE_UPDATE_LIST_REDUX';
export const GET_COUNT_UPCOMMING_APPOINTMENT = 'GET_COUNT_UPCOMMING_APPOINTMENT';
export const SET_COUNT_UPCOMMING_APPOINTMENT = 'SET_COUNT_UPCOMMING_APPOINTMENT';

export const GET_COUNT_REQUEST_DOCUMENT_NOT_SEEN = 'GET_COUNT_REQUEST_DOCUMENT_NOT_SEEN';
export const SET_COUNT_REQUEST_DOCUMENT_NOT_SEEN = 'SET_COUNT_REQUEST_DOCUMENT_NOT_SEEN';
export const ADD_VALUE_TO_COUNT_REQUEST_DOCUMENT_NOT_SEEN = 'ADD_VALUE_TO_COUNT_REQUEST_DOCUMENT_NOT_SEEN';
export const REMOVE_VALUE_TO_COUNT_REQUEST_DOCUMENT_NOT_SEEN = 'REMOVE_VALUE_TO_COUNT_REQUEST_DOCUMENT_NOT_SEEN';

export const GET_COUNT_PAYMENT_REQUEST_REPORT_SEEN = 'GET_COUNT_PAYMENT_REQUEST_REPORT_SEEN';
export const SET_COUNT_PAYMENT_REQUEST_REPORT_SEEN = 'SET_COUNT_PAYMENT_REQUEST_REPORT_SEEN';

export const SET_QUERY_FURNITURE = 'SET_QUERY_FURNITURE'
