import taskIcon from "../../../../images/managerCalendar/type/task.svg";
import {convertToDateH_m, ConvertToDateY_M_D} from "../../../../functions/datetime";
import React from "react";
import clockIcon from "../../../../images/managerCalendar/clock.svg";
import {
    REQUEST_TASK
} from "../ManagerCalendarPageRight";
import sunIcon from "../../../../images/managerCalendar/type/sun.svg";
import {Tooltip} from "@material-ui/core";
import CustomInlineSvg from "../../../../theme/CustomElements/CustomInlineSvg";
import settingIcon from "../../../../images/navbar/setting.svg";

export const appointmentView = (model, props) => {
    const {classes, t, countValidateRestDay, countRequestTask, countDataHolidayByMonthYear} = props;
    const {appointmentData} = model.data;
    return (
            <div
                className={`${classes.contentItemCalenderWrapper} ${countDataHolidayByMonthYear[ConvertToDateY_M_D(appointmentData.startDate)] ? "companyHolidayWrapper" : ""} `}>
                {countDataHolidayByMonthYear[ConvertToDateY_M_D(appointmentData.startDate)] &&
                <div className={"companyHoliday"}>
                    <CustomInlineSvg
                        svgProps={{
                            src: sunIcon
                        }}
                        scale={2}
                    />
                    <div>
                        {t("managerCalendarPage.managerCalendarRight.companyHoliday")}
                    </div>
                </div>
                }
                {countRequestTask[ConvertToDateY_M_D(appointmentData.startDate)] &&
                <div className={`showtime-preview ${classes.contentItemCalender} typeTask`}>
                    <div className={`${classes.iconStatusWrapper} taskIconWrapper`}>
                        <img alt="" src={taskIcon}/>
                    </div>
                    <div className={classes.numberByStatus}>
                        {countRequestTask[ConvertToDateY_M_D(appointmentData.startDate)] ? countRequestTask[ConvertToDateY_M_D(appointmentData.startDate)].length : 0}
                    </div>
                    <div className={'statusName'}>
                        {t("managerCalendarPage.type.task")}
                    </div>
                </div>
                }
                {countValidateRestDay[ConvertToDateY_M_D(appointmentData.startDate)] &&
                <div className={`showtime-preview ${classes.contentItemCalender} typeHoliday`}>
                    <div className={`${classes.iconStatusWrapper} sunIconWrapper`}>
                        <img alt="" src={sunIcon}/>
                    </div>
                    <div className={classes.numberByStatus}>
                        {countValidateRestDay[ConvertToDateY_M_D(appointmentData.startDate)] ? countValidateRestDay[ConvertToDateY_M_D(appointmentData.startDate)].length : 0}
                    </div>
                    <div className={'statusName'}>
                        {t("managerCalendarPage.type.holiday")}
                    </div>
                </div>}
            </div>
        );
}

const viewAppointmentTimelineView = (appointmentData, classes, component) => {
    return (
        <div className={classes.contentItemCalenderWrapper}>
            <div className={`showtime-preview ${classes.contentItemCalender} typeTask typeTaskTimeline`}>

                <div className={`${classes.iconStatusWrapper} ${appointmentData.typeItem === REQUEST_TASK ?"taskIconWrapper":"sunIconWrapper"} iconStatusWrapper2`}>
                    <img alt="taskIcon" src={appointmentData.typeItem === REQUEST_TASK ? taskIcon : sunIcon}/>
                </div>
                <div>
                    <div className={"titleItem"}>
                        {appointmentData.text}
                    </div>
                    <div className={'startEndTime'}>
                        <img alt="clockIcon" src={clockIcon}/>
                        <div>{convertToDateH_m(appointmentData.startDate)}-{convertToDateH_m(appointmentData.endDate)}</div>
                    </div>
                </div>

            </div>
            {/*<CustomButton*/}
            {/*    title={"REsponse"}*/}
            {/*    className={`${classes.btnResponse} btnResponse`}*/}
            {/*/>*/}
        </div>
    )
}
export const appointmentTimelineView = (model, props, component) => {
    const {appointmentData} = model.data;
    const {classes} = props;
        return (
            <Tooltip
                key={"item"+ appointmentData.id}
                title={viewAppointmentTimelineView(appointmentData,classes,component)}
                classes={{
                    tooltip: classes.customTooltip,
                    arrow: classes.customArrow
                }}
                arrow
                interactive
                placement={"top"}
            >
                {viewAppointmentTimelineView(appointmentData,classes,component)}
            </Tooltip>
        );
    }