import React, {Component} from 'react';
import {Grid, withStyles} from "@material-ui/core";
import GeneralModal from "../../../../theme/Modal/GeneralModal";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import LoadingAction from "../../../../theme/LoadingAction";
import CustomButton from "../../../../theme/CustomElements/CustomButton";
import SuccessAlert from "../../../../theme/form/Alert/SuccessAlert";
import returnIcon from "../../../../images/request/return.svg";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AppPersonAvatar from "../../../../theme/Avatar/AppPersonAvatar";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import {convertToDateH_m_D_M_Y, ConvertToDateY_M_DH_m_s} from "../../../../functions/datetime";
import MessageIcon from "@material-ui/icons/Message";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import TitleIcon from "@material-ui/icons/Title";
import closeModal from "../../../../images/theme/close.svg";
const styles = theme => ({
    modalRestdayWrapper: {
        padding: '4rem 0rem'
    },
    modalEditRestdayWrapper:{
        background: 'transparent',
        border: 'none',
        '& .headerModalWrapper':{
            display: 'none'
        }
    },
    itemWrapper: {
        background: ' #FFFFFF',
        boxShadow: '0px 4px 20px rgba(0, 0, 254, 0.05)',
        borderRadius: 15,
        width: '100%',
        color: '#001529',
        padding: '1rem 0 4rem 0',
        position: 'relative',
        '& .headerItem':{
            marginTop: '-4rem',
            // display: 'flex',
            // justifyContent: 'space-between',
            '& .avatarWrapper':{
                display: 'flex',
                justifyContent: 'center',
            },
            '& .headerRight':{
                padding: '0.5rem 0 0.5rem 0.5rem',
                textTransform: 'uppercase',
                '& .statusItem': {
                    '&.on':{
                        background: '#e1f2ea',
                        color: '#0DBE2A',
                    },
                    '&.off':{
                        background: '#f6e5eb',
                        color: '#DF373C',
                    },
                    textAlign: 'center',
                    borderRadius: 15,
                    padding: '0.25rem 0.5rem'
                }
            }
        },
        '& .fullName':{
            textAlign: 'center',
            fontSize: '20px',
            fontWeight: 700
        },
        '& .itemBody':{
            padding: '0.3rem 1rem'
        },
        '& .itemBodyReason':{
            background: '#F4F7FF',
            borderRadius: 15,
            padding: '0.5rem',
            margin: '0.5rem',
            '& .content':{
                padding: '0.5rem'
            }
        },
        '& .label':{
            fontWeight: 700,
            fontSize: '1rem',
            display: 'flex',
            alignItems: 'flex-start',
            padding: ' 0 0.5rem',
            lineHeight: 1,
            '& svg':{
                marginRight: '0.3rem',
                fontSize:'0.9375rem'
            }
        },
        '& .content':{
            fontSize: '1rem',
            display: 'flex',
            alignItems: 'flex-start'
        },
        '& .viewDetail':{
            // paddingTop: '1rem',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            height: '3rem',
            '& a':{
                textDecoration: 'none'
            }
        }
    },
    headerWrapper: {
        padding: '2rem',
        '& .headerBlock': {
            padding: '1rem',
            display: 'flex',
            justifyContent: 'center',
            '& .searchInput':{
                background: '#F8F8FF',
                border: '1px solid #D8DDE6',
                borderRadius: 20

            }
        },
    },
    closeButton:{
        display: 'flex',
        justifyContent: 'flex-end',
        '& img': {
            cursor: 'pointer',
            padding: '0.3rem',
            '&:hover': {
                borderRadius: 6,
                border: ' 1px solid #999'
            }
        }
    }
})

class ModalEditRestday extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let { successOpen} = this.state
        let {isLoading, t, classes, openModal, modalValue} = this.props
        const headTrans = "managerCalendarPage.managerCalendarRight.modalRestday."
        const dataItem = modalValue ? modalValue.dataDefault:{}
        const userFullNameOrEmail= dataItem && dataItem.person && dataItem.person.fullName ? dataItem.person.fullName : dataItem.email;

        return (
            <React.Fragment>
                {isLoading && <LoadingAction/>}
                <GeneralModal
                    className={classes.modalEditRestdayWrapper}
                    open={openModal}
                    enableOnClose={this.props.handleCloseModal}
                    title={dataItem ? dataItem.text : ""}
                    buttonModal={<CustomButton title={t(headTrans + "btnSave")}
                                               className={classes.btnAddRestday}
                                               buttonProps={{
                                                   startIcon: <React.Fragment><img
                                                       src={returnIcon} className={"iconAdd"} alt=""/></React.Fragment>
                                               }} onClick={this.submitRequest}/>}
                    content={
                        <Grid container className={classes.modalRestdayWrapper}>
                            <div className={classes.itemWrapper}>
                                <Grid container>
                                    <Grid container item xs={12} className={"headerItem"}>
                                        <Grid item xs={4} className={"headerLeft"}></Grid>
                                        <Grid item xs={4} className={"avatarWrapper"}>
                                            <AppPersonAvatar
                                                name={
                                                    <React.Fragment>
                                                        <div>{userFullNameOrEmail}</div>
                                                    </React.Fragment>
                                                }
                                                alt={userFullNameOrEmail}
                                                variant="rounded"
                                                src={dataItem && dataItem.person && dataItem.person.avatar && dataItem.person.avatar.pathUrl ? dataItem.person.avatar.pathUrl : ""}
                                                avatarImgClassName={"avatarRepairer"}/>
                                        </Grid>
                                        <Grid item xs={4} className={"headerRight"}>
                                            <div
                                                className={`${classes.closeButton} closeButtonModal`}
                                            >
                                                <img alt={'close button'} src={closeModal} onClick={this.props.handleCloseModal}/>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className={"itemBody"}>
                                        <div className={"fullName"}>{userFullNameOrEmail}</div>
                                    </Grid>

                                    <Grid container item xs={12} className={"itemBody"}>
                                        <Grid item xs={5} className={"label"}>
                                            <TitleIcon />
                                            <div>{t(headTrans + "subject")}</div>
                                        </Grid>
                                        <Grid item xs={7} className={"content"}>
                                            {dataItem.subject ? dataItem.subject : ""}
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} className={"itemBody"}>
                                        <Grid item xs={5} className={"label"}>
                                            <WatchLaterIcon />
                                            <div>{t(headTrans + "startRestAt")}</div>
                                        </Grid>
                                        <Grid item xs={7} className={"content"}>
                                            {dataItem.startRestAt ? convertToDateH_m_D_M_Y(dataItem.startRestAt) : ""}
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} className={"itemBody"}>
                                        <Grid item xs={5} className={"label"}>
                                            <WatchLaterIcon />
                                            <div>{t(headTrans + "endRestAt")}</div>
                                        </Grid>
                                        <Grid item xs={7} className={"content"}>
                                            {dataItem.endRestAt ? convertToDateH_m_D_M_Y(dataItem.endRestAt) : ""}
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} className={"itemBodyReason"}>
                                        <Grid item xs={12} className={"label"}>
                                            <MessageIcon/>
                                            <div>{t(headTrans + "description")}</div>
                                        </Grid>
                                        <Grid item xs={12} className={"content"}>
                                            {dataItem.description ? dataItem.description : ""}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    }
                    saveButton={false}
                    cancelButton={false}
                    hideFooter ={true}
                    hiddenXClose={true}
                />
                <SuccessAlert
                    snackbarProps={{
                        open: successOpen,
                        onClose: this.handleCloseNotice
                    }}
                    // message={t('modal.upload.requestAdded')}
                />
            </React.Fragment>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        problemRequest: state.applicationReducer.problemRequest,
        isLoading: state.applicationReducer.isLoading,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {

    }
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation(), withStyles(styles))(ModalEditRestday);