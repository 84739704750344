import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import Modal from "@material-ui/core/Modal";
import closeModal from "../../images/theme/close.svg"
// import CheckInput from "../CustomElements/CheckInput";
// import RowForm from "../form/RowForm";
import CustomButton from "../CustomElements/CustomButton";
import {SM, XS} from "../../constants/constants";
import CustomInlineSvg from "../CustomElements/CustomInlineSvg";
import personIcon from "../../images/navbar/person.svg";

const styles = theme => ({
    modalContent: {
        background: '#fff',
        border: '1px solid #E0E0E0',
        borderRadius: 4,
        outline: 0,
        // position: 'fixed',
        // top: '50%',
        // left: '50%',
        // transform: 'translate(-50%,-50%)',
        animation: '$topToBottom 300ms linear',
        width: 800,
        // minHeight: '80%',
        maxHeight: '90%',
        margin: 'auto'
    },
    '@keyframes topToBottom': {
        '0%': {
            opacity: 0,
            visibility: 'hidden',
            top: '45%'
        },
        '100%': {
            opacity: 1,
            visibility: 'visible',
            top: '50%'
        }
    },
    headerModal: {
        padding: '1.125rem 1rem 1rem 1rem',
        display: 'flex',
        alignItems: 'center',
        // borderBottom: '1px solid #E0E0E0'
    },
    titleModal: {
        fontSize: '0.875rem',
        textTransform: 'initial',
        lineHeight: '19px',
        flexGrow: 1
    },
    bodyModal: {
        padding: '0.5rem 0.5rem 0rem 1rem',
        maxHeight: 'calc(90vh - 10px)',
        overflow: 'auto',

    

    },
    closeButton:{
        cursor: 'pointer',
        '& svg':{
            width: 25,
            height: 25,
            borderRadius: '50%',
            padding: 3,
            '& path':{
                stroke: '#000'
            },
            '&:hover':{
                background: '#BDBDBD'
            }
        }
    },
    footerModal: {
        padding: '0.5rem 1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: '10px',
        '& button': {
            '&:not(:first-child)': {
                marginLeft: 7
            }
        },
        [theme.breakpoints.down(XS)]:{
            justifyContent: 'center'
        }
    },
    cancelButton: {
        background: '#b7b7b7 !important',
        border: '1px solid transparent',
        color: 'white',
        textTransform: 'initial',
        '&:hover': {
            background: '#0e1c6c!important',
            '&::before': {
                display: 'none'
            }
        }
    },
    modalWrapper:{
        display: 'flex',
        '& .previewModal':{
            width: 550
        },
        // '& .problemModal':{
        //     height: '400px'
        // },
        '& .previewRequestModal':{
            width: '580px',
            // height: '350px'
        },
        [theme.breakpoints.down(SM)]:{
            '& $modalContent':{
                width: 'calc(100% - 1rem)'
            }
        },
        [theme.breakpoints.down(XS)]:{
            '& $modalContent':{
                
            }
        }
    },
    errorInfo: {
        color: 'red',
        marginLeft: '15px',
        fontStyle: 'italic'
    }

});

class GeneralModal extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const {
            t,
            classes,
            open,
            handleClose,
            enableOnClose,
            className,
            title,
            content,
            buttonModal,
            hideFooter,
            cancelButton,
            saveButton,
            error,
            handleSave,
            iconCloseModal,
            hideHeaderModal
        } = this.props;
        return (
            <Modal
                open={open}
                onClose={enableOnClose?handleClose:()=>{}}
                className={classes.modalWrapper}
            >
                <div className={`${classes.modalContent} ${className? className :""}`}>
                    {!hideHeaderModal && <div className={`${classes.headerModal} headerModalWrapper`}>
                        <div className={`${classes.titleModal} titleModal`}>
                            {title ? title : ""}
                        </div>
                        <div
                            className={`${classes.closeButton} closeButtonModal`}
                            onClick={handleClose}
                        >{iconCloseModal ? iconCloseModal :
                            <CustomInlineSvg  svgProps={{
                                src: closeModal
                            }}
                            title={"close button"}
                            />
                            // <img alt={'close button'} src={closeModal}/>
                        }
                        </div>
                    </div>}
                    <div className={`${classes.bodyModal} bodyModalWrapper`}>
                        {content ? content : <div>{this.props.t('modal.content')}</div>}
                    </div>
                    {error && JSON.stringify(error) !== "{}" && <div className={classes.errorInfo}>{error}</div>}
                    {!hideFooter && <div className={classes.footerModal}>
                        {cancelButton && <CustomButton className={classes.cancelButton} title={t('modal.cancel')} onClick={handleClose}/>}
                        {saveButton && <CustomButton title={t('modal.save')} onClick={handleSave}/>}
                        {buttonModal ? buttonModal : ""}
                    </div>}
                </div>
            </Modal>

        )
    }
}

GeneralModal.defaultProps = {
    handleClose: () => {},
    hideFooter: false,
    hideSave: true,
    hideXClose: false,
    handleSave: () => {},
    open: false,
    cancelButton: true,
    saveButton: true,
    enableOnClose: true,
    hideHeaderModal: false
}

GeneralModal.propTypes = {
    classes: PropTypes.object.isRequired,
    content: PropTypes.node.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    style: PropTypes.object,
    hideFooter: PropTypes.bool,
    hideXClose: PropTypes.bool,
    hideSave: PropTypes.bool,
    addIcon: PropTypes.array,
    handleSave: PropTypes.func,
    enableOnClose: PropTypes.bool,
    title: PropTypes.node,
    buttonModal: PropTypes.node,
    cancelButton: PropTypes.bool,
    saveButton: PropTypes.bool,
    hideHeaderModal: PropTypes.bool,
}


export default  compose(
    withStyles(styles),
    withTranslation()
)(GeneralModal);
