import React, {Component} from 'react';
// import _ from "lodash";
import {compose} from "redux";
import {IconButton, withStyles, withWidth} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import {SM, XS} from "../../constants/constants";
import Pdf from "../../components/client/requestDetail/Pdf";
// import {connect} from "react-redux";
import CustomInlineSvg from "../CustomElements/CustomInlineSvg";
import DownloadDoc from "../../images/request/download-arrow.svg";

const styles = theme => ({
    showFileModal:{
        width: '100%',
        height: '500px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    imageContent:{
        width: '80%',
        height: '100%',
        objectFit: 'contain'
    },
    videoContent:{
        width: '80%',
        height: 'auto',
        maxHeight: '100%',
        objectFit: 'contain'
    },
    buttonShow:{
        '&:hover':{
            background: '#b4cae6'
        }
    },
    downloadDoc: {
        width: 50,
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px solid #7d9ecf',
        '& svg':{
           '& path': {
               fill: '#7d9ecf!important',
               stroke: '#7d9ecf!important'
           }
        },
    }
})

const showElement = (props) =>{
    let {itemShow, files, classes, width} = props
    // let showedFile = files[itemShow];
    console.log(itemShow)
    const mimeType = itemShow instanceof File ? itemShow.type : itemShow.mimeType;
    const url = itemShow instanceof File ? URL.createObjectURL(itemShow) : itemShow.pathUrl
    // console.log(url)
    // console.log(mimeType)
    // console.log(files)
    if (mimeType && mimeType.includes("image")) {
        // let urlImage = URL.createObjectURL(showedFile.file)
        return <img alt="" className={classes.imageContent} src={url}/>
    }
    else if (mimeType && mimeType.includes("video")) {
        // let url = URL.createObjectURL(showedFile.file)
        return  <video controls className={classes.videoContent} autoPlay> <source src={url}/> </video>
    }else if (mimeType && mimeType.includes("pdf")) {
        // let url = URL.createObjectURL(showedFile.file)
        return  <React.Fragment>
            {width === XS || width === SM ?
                <Pdf src={url} filePdf={url}/>
                :
                <object type="application/pdf" className={classes.zoomInModal} width="100%" height="100%"
                        data={url}>
                    <embed src={url} type="application/pdf"  width="100%" height="100%"/>
                </object>
            }
        </React.Fragment>
    } else return <div>
        <a href={url} target="_blank" rel="noreferrer" className={classes.downloadDoc}>
            <CustomInlineSvg
                svgProps={{
                    src: DownloadDoc,
                }}
                scale="0.6"
            />
        </a>
    </div>
}
export const ShowElementComponent = compose(withStyles(styles), withTranslation())(showElement)

class ContentPreviewModal2 extends Component {
    constructor(props) {
        super(props);
        let {
            files,
            // meta
        } = props
        // console.log(files)
        // if(files.length>0) files = files.filter(item => item.meta && item.meta.status === "done")
        let itemShow = files.find((item, key) =>key === props.keyFile)
        this.state={
            itemShow : itemShow,
            files: this.props.files,
            keyShow: props.keyFile
            // meta: this.props.meta
        }
    }
    buttonAction = (field) => {
        let {keyShow, files} = this.state
        if (field === 'leftButton'){
            if(keyShow === 0) keyShow = files.length - 1
            else keyShow -= 1
        }
        if (field === 'rightButton'){
            if(keyShow === files.length - 1) keyShow = 0
            else keyShow += 1
        }
        this.setState({
            itemShow: files[keyShow],
            keyShow: keyShow
        })


    }
    render() {
        let { files } = this.state
        let { classes,width } = this.props
        return (
            <div className={classes.showFileModal}>
                {files.length > 1 && <IconButton onClick={()=>this.buttonAction('leftButton')} className={`${classes.buttonShow}`}>
                    <ChevronLeftOutlinedIcon/>
                </IconButton>}
                <ShowElementComponent {...this.state} width={width}/>
                {files.length > 1 && <IconButton onClick={()=>this.buttonAction('rightButton')} className={`${classes.buttonShow}`}>
                    <ChevronRightOutlinedIcon/>
                </IconButton>}
            </div>
        );
    }
}

ContentPreviewModal2.defaultProps = {
    files : [],
    keyFile: null
}

export default  compose(
    withStyles(styles),
    withWidth()
)(ContentPreviewModal2);
