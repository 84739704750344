export const styleList = {
    listWrapper: {
        // background: '#fff',
        borderRadius: '4px',
        '& svg': {
            '& path': {
                fill: '#000',
                stroke: '#000',
            }
        },
        '& span': {
            color: '#000',
        },
        '& a[aria-label="Create"]': {
            '&:hover': {
                background: "#0000c7",
            },
            textTransform: "uppercase",
            fontSize: '0.75rem',
            color: "white",
            background: "#0000ff",
            padding: '0.5rem 1rem',
            fontWeight: 700,
            '& span, & svg': {
                color: 'white',
            },
            "& svg path":{
                fill: "white",
                stroke: "white"
            }
        },
        '& button[aria-label="Export"]': {
            backgroundColor: '#fff',
            display: 'none'
        },
        '& .btnCreate': {
            backgroundColor: '#0000ff',
            '&:hover':{
                backgroundColor: '#0404d0'
            },
            '& span': {
                color: '#fff',
                fontSize: 10,
                fontWeight: 700
            },
            '& svg':{
                display: 'none'
            }
        },
        '& .btnExport': {
            backgroundColor: '#fff'
        },
        '&.userList': {
            '& form.filtersOnList': {
                direction: 'rtl',
                '& .searchInput': {
                    direction: 'ltr'
                }
            },
        },
        '&>[class*="MuiToolbar-gutters-"]': {
            alignItems: 'baseline',
            direction: 'rtl',
            flexDirection: 'row-reverse',
            marginTop: 1,
            '& .toolBarAction':{
                paddingTop: 10
            },
            '& form.filtersOnList': {
                direction: 'ltr',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                width: '100%',
                '& [data-source="title_list"]': {
                    width: 300
                },
                '& div[data-source="input"]': {
                    '&.filter-field': {
                        width: 'calc(100% - 300px)',
                        justifyContent: 'flex-end',
                        marginTop: 10
                    }
                }
            }
        }
    },
    tableWrapper: {
        padding: '20px 30px',
        borderCollapse: 'unset',
        '& th': {
            color: '#0000ff',
            textTransform: 'uppercase',
            fontWeight: 400,
            borderBottom: 'none',
            "& span":{
                color: '#000000',
                fontWeight: 600
            }
        },
        '& thead tr': {
            '& th:first-of-type': {
                display: 'none'
            }
        },
        '& tbody tr': {
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                // borderBottom: '2px solid black'
            },

            '& td:first-of-type': {
                display: 'none'
            },
            '& td':{
                backgroundColor: 'rgba(204, 208, 216, 0.27)',
                borderBottom: '4px solid #fff',
                borderTop: '4px solid #fff',
                color: '#0000ff',

                '& span': {
                    fontWeight: 500
                }
            }
        }
    },
    tableCell: {
        backgroundColor: 'rgba(204, 208, 216, 0.27)',
        borderBottom: '4px solid #fff',
        borderTop: '4px solid #fff',
        color: '#0000ff',

        '& span': {
            fontWeight: 500
        }
    },
    tableRow: {
        '&:hover': {
            backgroundColor: '#ccd0d8',
            // borderBottom: '2px solid black'
        },
    },
    editButton: {
        '& span span' : {
            display: 'none'
        },
        '& svg': {
            '& path': {
                fill: '#0000ff',
                stroke: '#0000ff',
            }
        },
        '&.requestEditButton': {
            height: 28,
            width: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 6,
            '&:hover': {
                backgroundColor: 'rgba(63, 81, 181, 0.05)'
            }
        }
    },
    showButton: {
        '& span span' : {
            display: 'none'
        },
        '& svg': {
            '& path': {
                fill: '#0000ff',
                stroke: '#0000ff',
            }
        }
    },
    titleList:{
        "& input":{
            padding: "0 !important",
            color: '#54516a',
            fontFamily:"'Open Sans', sans-serif",
            fontSize: '1rem',
            "&::placeholder":{
                opacity:0.8,
                fontWeight: 100,
            }
        },

        "& div[class*='MuiFilledInput-root-']":{
            background: 'transparent',
        },
        '& [class*="MuiFilledInput-underline-"]':{
            // background: 'transparent',
            '&:before': {
                border: 'none'
            },
            '&:after':{
                border: 'none'
            }
        },
        "& label[class*='MuiInputLabel-filled-']":{
            position: 'inherit',
            fontWeight: 600,
            textTransform: 'uppercase'
        },
        }
};

export const styleCreate = {
    createWrapper: {

    },
    createSimpleForm: {
        '& label': {
            '& span': {
                color: '#0000ff',
                fontWeight: 600
            },
        },
        '& ul [class*="RaSimpleFormIterator"]':{
            marginTop:'3px'
        },
        '& .ra-input div div': {
            background:'#fff',
            // border:'1px solid #ddd',
            '&:before': {
                borderBottom: 'none',
                height:'0'
            },
            '& :hover:before':{
               height: 0
            },
            '&:after': {
                borderBottom: 'none',
            },
            '&:focus:after':{
                transform: 'scaleX(0)'
            }
        },
        '& input': {
            color: '#0000ff',
            fontWeight: 500,
            // backgroundColor: 'rgba(204, 208, 216, 0.27)',
            background:'#fff',
            border: '1px solid #E0E0E0',
            borderRadius: 6
        },
        '& button[type=submit]': {
            backgroundColor: '#0000ff',
            color: '#fff',
            textTransform: 'capitalize'
        },
        '& > div': {
            backgroundColor: '#fff'
        },
        '& .radioGroupCustom':{
            '& div[role=radiogroup]':{
                '& label':{
                    width:'50%',
                    margin:0
                }
            }
        },
        '& .MuiFilledInput-underline':{
            color: '#0000ff',
            fontWeight: 500,
            background:'#fff',
            border:'2px solid #ddd',
            borderRadius: '5px 5px 0 0'
        },
        '& .ra-rich-text-input':{
            color: '#0000ff',
            fontWeight: 500,
            background:'#fff',
            border:'2px solid #ddd',
            borderRadius: '5px 5px 0 0',
            padding: '0.4rem',
            '& div[data-testid="quill"]':{
                height: 100
            }
        },
    },
    titleForm:{
        color: '#54516a',
        fontFamily: "'Open Sans', sans-serif",
        fontSize: '1rem',
        // opacity:0.8,
        // fontWeight: 100,
        fontWeight: 700,
        textTransform: 'uppercase',
        marginBottom: 5
    }
};

export const styleEdit = {
    editWrapper: {

    },
    editSimpleForm: {
        '& label': {
            '& span': {
                color: '#0000ff',
                fontWeight: 600,
            },
        },
        '& ul [class*="RaSimpleFormIterator"]':{
            marginTop:'3px'
        },
        '& .ra-input div div': {
            background:'#fff',
            // border:'1px solid #ddd',
            '&:before': {
                borderBottom: 'none',
                height:'0'
            },
            '& :hover:before':{
                height: 0
            },
            '&:after': {
                borderBottom: 'none',
            },
            '&:focus:after':{
                transform: 'scaleX(0)'
            }
        },
        '& input': {
            color: '#0000ff',
            fontWeight: 500,
            background:'#fff',
            // border:'1px solid #ddd'
            border: '1px solid #E0E0E0',
            borderRadius: 6
        },
        '& button[type=submit]': {
            backgroundColor: '#0000ff',
            color: '#fff',
            textTransform: 'capitalize'
        },
        '& > div': {
            backgroundColor: '#fff'
        },
        '& .radioGroupCustom':{
            '& div[role=radiogroup]':{
                '& label':{
                    width:'50%',
                    margin:0
                }
            }
        },
        '& .MuiFilledInput-underline':{
            color: '#0000ff',
            fontWeight: 500,
            background:'#fff',
            border:'2px solid #ddd',
            borderRadius: '5px 5px 0 0'
        },
        '& .ra-rich-text-input':{
            color: '#0000ff',
            fontWeight: 500,
            background:'#fff',
            border:'2px solid #ddd',
            borderRadius: '5px 5px 0 0',
            padding: '0.4rem',
            '& div[data-testid="quill"]':{
                height: 100
            }
        },
    },
    titleForm:{
        color: '#54516a',
        fontFamily: "'Open Sans', sans-serif",
        fontSize: '1rem',
        // opacity:0.8,
        // fontWeight: 100,
        fontWeight: 700,
        textTransform: 'uppercase',
        marginBottom: 5
    }
};
