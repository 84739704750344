import {withStyles} from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import {styleList} from "./style";
const styles = styleList;

export const MyDatagridRow = withStyles(styles) (({ classes, record, resource, id, onToggleItem, children, selected, basePath }) => (
    <TableRow key={`table-row-${id}`} className={classes.tableRow}>
        {/* first column: selection checkbox */}
        <TableCell padding="none" className={classes.tableCell}>
            <Checkbox
                disabled={record.selectable}
                checked={selected}
                onClick={() => onToggleItem(id)}
            />
        </TableCell>
        {/* data columns based on children */}
        {React.Children.map(children, field => (
            <TableCell key={`${id}-${field.props.source}`} className={classes.tableCell}>
                {React.cloneElement(field, {
                    record,
                    basePath,
                    resource,
                })}
            </TableCell>
        ))}
    </TableRow>
));