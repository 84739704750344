import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {
    API_CREATE_PARAMETER,
    API_EDIT_PARAMETER,
    API_SHOW_PARAMETER
} from "../../../../constants/apiSuffix";
import {
    APP_ADVERTISE,
    arrAppAdvertisec,
    arrayParameters, arrBlickoContact,
    arrBreakTimeForm,
    arrCallTimeForm,
    arrCurrentForm,
    arrDayOfWeekForm, arrEmailAddressBcc,
    arrHolidayForm, arrPricePayDefault, arrShareDocumentExpireNumberDay,
    arrWordTimeForm,
    BLICKO_CONTACT,
    BREAK_TIME,
    CALL_TIME,
    CALL_TIME_OPTIONS,
    CURRENCY,
    DAY_OF_WEEK, EMAIL_ADDRESS_BCC,
    FORM_EDIT,
    FRIDAY,
    HOLIDAY,
    MONDAY,
    NUMBER,
    OBJECT, PRICE_PAY_DEFAULT,
    ROLE_GROUP_ADMIN,
    SATURDAY, SHARE_DOCUMENT_EXPIRE_NUMBER_DAY,
    STRING,
    SUNDAY,
    THURSDAY,
    TUESDAY,
    WEDNESDAY,
    WORK_TIME
} from "../../../../constants/constants";
import CustomInput from "../../../../theme/CustomElements/CustomInput";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import RowFormColumn from "../../../../theme/form/RowFormColumn";
// import AppKeyboardDatePicker from "../../../../theme/form/DatePicker/AppKeyboardDatePicker";
import AppKeyboardTimePicker from "../../../../theme/form/DatePicker/AppKeyboardTimePicker";
import CustomButton from "../../../../theme/CustomElements/CustomButton";
import AppCurrencyAutocomplete from "../../../../theme/form/Autocomplete/AppCurrencyAutocomplete";
import moment from "moment";
import AppSelect from "../../../../theme/form/Select/AppSelect";
import {formDataValue, PARAMETER_IS_NOT_VIEW_LIST} from "./ParameterPage";
import {Redirect} from "react-router";
import SaveIcon from '@material-ui/icons/Save';
import AppKeyboardDateTimePicker from "../../../../theme/form/DatePicker/AppKeyboardDateTimePicker";
import {TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LoadingAction from "../../../../theme/LoadingAction";
import {ConvertToDateY_M_D} from "../../../../functions/datetime";
import {convertNumberIntMinuteToHour} from "../../../../functions/functions";
import AppCKEditor from "../../../../theme/CustomElements/AppCKEditor";
import {SET_TIME_OUT_ALERT_SUCCESS} from "../../ReactAdmin";
import AlertCustom from "../../../../theme/form/Alert/AlertCustom";
import {errorsMessMailType} from "../../../../functions/checkErrorEmpty";
import {checkSubmitSendDataERROR} from "../../../../functions/checkCondition";
import ErrorInput from "../../../../theme/form/Alert/ErrorInput";
const styles = {
    selectCurrency: {
        borderRadius: 4,
        height: 40,
        marginBottom: 10,
        border: '1px solid #ddd',
        '& input': {
            padding: '0 10px'
        }
    },
    numberCallTime:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '& .unitCallTime':{
            fontSize:'1rem',
            paddingLeft: '0.5rem',
            fontWeight: 600
        },
        '&.errorWrapper':{
            '& .selectCallTime':{
                border: '1px solid  #f80606',
                borderRadius: 5,
                marginBottom: '0.5rem'
            },
        }
    },
    btnResponse:{
        background: '#0000ff !important',
        margin: '1rem 0'
    },
    ckEditor: {
        '& .ck-content': {
            height: 200,
            '& p':{
                margin: 0
            }
        }
    }
};



const headLabel = "reactAdmin.parameter.form.";
const TIME = "time";
const DATE = "date";
const arrForm = [
    {
        name: "description",
        type: STRING,
        label: `${headLabel}description`
    },
    {
        name: "value",
        type: STRING,
        label: `${headLabel}value`
    },
    {
        name: "callTime",
        type: NUMBER,
        label: `${headLabel}callTime`
    },
    {
        name: "startTime",
        type: TIME,
        label: `${headLabel}startTime`
    },   {
        name: "endTime",
        type: TIME,
        label: `${headLabel}endTime`
    },
    {
        name: "startDate",
        type: DATE,
        label: `${headLabel}startDate`
    },
    {
        name: "endDate",
        type: DATE,
        label: `${headLabel}endDate`
    },
]
class FormParameter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formValue: this.props.formValue ? {...this.props.formValue} :formDataValue,
            errors: {},
            redirectUrl: "",
            successOpen: false,
            errorOpen: false,
        };
        if(!props.arrayColumnItem || !Array.isArray(props.arrayColumnItem) || props.arrayColumnItem.length<1) {
            this.propertiesForm =  [];
        }else{
            this.propertiesForm =  props.arrayColumnItem;
        }
    }
    handleCloseNotice = () => {
        this.setState({
            successOpen: false,
            errorOpen: false
        })
    }

    handleChange = (value, field) => {
        let {formValue, errors} = this.state;
        if(errors.hasOwnProperty(field)){
            delete (errors[field]);
        }
        formValue[field] = value;
        this.setState({formValue: formValue, errors:errors})
    }

    daysOfWeek = () =>{
        const {t} = this.props;
        const headDaysOfWeek = "reactAdmin.parameter.form.dayOfWeek.";
        return [
            {value: MONDAY, label: t(headDaysOfWeek + "monday")},
            {value: TUESDAY, label: t(headDaysOfWeek + "tuesday")},
            {value: WEDNESDAY, label: t(headDaysOfWeek + "wednesday")},
            {value: THURSDAY, label: t(headDaysOfWeek + "thursday")},
            {value: FRIDAY, label: t(headDaysOfWeek + "friday")},
            {value: SATURDAY, label: t(headDaysOfWeek + "saturday")},
            {value: SUNDAY, label: t(headDaysOfWeek + "sunday")},

        ]
    }

    getShowDataParameter = (idParameter)=>{
        const url = API_SHOW_PARAMETER.replace("{id}", idParameter);
        axios.get(url)
            .then(response => {
                if(response.status === 200) {
                    let formValueNew = {...this.state.formValue};
                    const  dataValue=response.data;
                    let valueData = dataValue.value ? dataValue.value : "";
                    if (dataValue.code === DAY_OF_WEEK) {
                        valueData = (dataValue.value ?
                            this.daysOfWeek().filter(item=>item.value == dataValue.value).shift()
                            : null)
                    }

                    formValueNew = {
                        ...formValueNew,
                        code: dataValue.code??"",
                        value: valueData,
                        description: dataValue.description ?? "",
                        callTime: dataValue.callTime ?? "",
                        startTime: dataValue.startTime ? convertNumberIntMinuteToHour(dataValue.startTime): null,
                        endTime: dataValue.endTime ? convertNumberIntMinuteToHour(dataValue.endTime): null,
                        startDate: dataValue.startDate ?? null,
                        endDate: dataValue.endDate ?? null
                    }
                    this.setState({
                        formValue: formValueNew,
                        formType: FORM_EDIT,
                        isLoading: false
                    })
                }
            })
            .catch(error => {
                this.setState({
                    formType: FORM_EDIT,
                    isLoading: false
                })
            })
    }

    componentDidMount() {
        const {match, location} = this.props;
        const filterGetCodeByUrl = arrayParameters.filter(item => location.pathname.startsWith(`/${item}`))
        const codePathName = filterGetCodeByUrl.shift();
        if(this.propertiesForm.length <1 ) {
            this.propertiesForm = codePathName === CURRENCY ? arrCurrentForm :
                codePathName === BREAK_TIME ? arrBreakTimeForm :
                    codePathName === WORK_TIME ? arrWordTimeForm :
                        codePathName === DAY_OF_WEEK ? arrDayOfWeekForm :
                            codePathName === HOLIDAY ? arrHolidayForm :
                                codePathName === CALL_TIME ? arrCallTimeForm :
                                    codePathName === BLICKO_CONTACT ? arrBlickoContact :
                                        codePathName === PRICE_PAY_DEFAULT ? arrPricePayDefault :
                                            codePathName === EMAIL_ADDRESS_BCC ?arrEmailAddressBcc:
                                                codePathName === APP_ADVERTISE ?arrAppAdvertisec:
                                                    codePathName === SHARE_DOCUMENT_EXPIRE_NUMBER_DAY ? arrShareDocumentExpireNumberDay :[];
        }

        if (match && match.params && match.params.id) {
           this.getShowDataParameter(match.params.id)
        } else {
            if (!this.state.formValue.code) {
                this.setState({
                    formValue: {...this.state.formValue, code:codePathName }
                })
            }
        }
    }

    handleSave = (e) =>{
        const {match, location}= this.props;
        let {formValue, errors}= this.state;
        Object.keys(this.state.formValue).map((key) => {
            if (this.propertiesForm.includes(key)) {
                switch (key) {
                    case "value":
                    case "callTime":
                    case "startDate":
                    case "endDate":
                        if(!this.state.formValue[key]){
                            errors = {...errors, [key]: this.props.t("form.error.NOT_NULL")}
                        }
                        break;
                    case "endTime":
                    case "startTime":
                        if ((formValue.code === BREAK_TIME  || formValue.code === WORK_TIME )&& !this.state.formValue[key]) {
                            errors = {...errors, [key]: this.props.t("form.error.NOT_NULL")}
                        }
                    default:
                        break;
                }
            }
        });
        if (Object.keys(errors) < 1) {
            this.setState({isLoading: true})
            let url = API_CREATE_PARAMETER;
            const formData = new FormData();
            if (this.props.formType === FORM_EDIT || (match && match.params && match.params.id)) {
                url = API_EDIT_PARAMETER.replace("{id}", match.params.id)
            } else {
                formData.append("code", this.state.formValue.code);
            }

            Object.keys(this.state.formValue).map((key) => {
                if (this.propertiesForm.includes(key)) {
                    switch (key) {
                        case "value":
                            formData.append(key, formValue[key] ? (typeof formValue[key] === OBJECT && formValue[key].value ? formValue[key].value : formValue[key]) : "");
                            break;
                        case "description":
                        case "callTime":
                            formData.append(key, this.state.formValue[key] ?? "");
                            break;
                        case "startTime":
                        case "endTime":
                            formData.append(key, this.state.formValue[key] ? parseInt(moment(this.state.formValue[key]).format("HH") * 60) + parseInt(moment(this.state.formValue[key]).format("mm")) : "");
                            break;
                        case "startDate":
                        case "endDate":
                            formData.append(key, this.state.formValue[key] ? moment(this.state.formValue[key]).format("YYYY-MM-DD HH:mm:ss") : "");
                            break;
                        default:
                            break;
                    }
                }
            });
            axios.post(url, formData)
                .then(response => {
                    if (response.status === 200) {
                        let redirectUrl = ""
                        if (location.pathname !== "/" + formValue.code) {
                            redirectUrl = "/" + formValue.code;
                        }
                        const dataItemResponse = response.data.item;
                        this.setState({
                            // formValue: {...this.props.formValue},
                            errors: {},
                            // isLoading: false,
                            successOpen: true,
                        }, function () {
                            if (!redirectUrl) {
                                this.props.setForceUpdate(true);
                            }
                        })
                        if(!PARAMETER_IS_NOT_VIEW_LIST.includes(dataItemResponse.code)) {
                            setTimeout(function () {
                                this.setState({
                                    redirectUrl: redirectUrl,
                                })
                            }.bind(this), SET_TIME_OUT_ALERT_SUCCESS);
                        }else{
                            this.getShowDataParameter(dataItemResponse.id)
                        }
                    }
                })
                .catch(error => {
                    this.setState({
                        isLoading: false,
                        errorOpen: true
                    })
                })
        }else {
            this.setState({
                isLoading: false,
                errors: errors,
                errorOpen: true
            })
        }
    }


    render() {
        const {formValue,errors, redirectUrl, isLoading, formType}= this.state;
        const  { t, classes} = this.props;
        if(redirectUrl){
            if(formType === FORM_EDIT){
                this.setState({isLoading: true})
            }
            return <Redirect to={redirectUrl}/>;
        }

        return (
            <div>
                {isLoading && <LoadingAction />}
                <AlertCustom
                    successOpen={this.state.successOpen}
                    errorOpen={this.state.errorOpen}
                    handleCloseNotice={()=>this.handleCloseNotice()}
                />
                {arrForm.map((item, key)=>{
                    if (this.propertiesForm.includes(item.name)) {
                        switch (item.type) {
                            case STRING:
                                const codeFormValue = this.state.formValue.code;
                                if(item.name === "value" && [CURRENCY, DAY_OF_WEEK].includes(codeFormValue)){
                                    if(codeFormValue === CURRENCY){
                                        return <RowFormColumn
                                            key={key}
                                            label={t(item.label)}
                                            input={<AppCurrencyAutocomplete
                                                onChange={(e, value) => this.handleChange(value.abbr, item.name)}
                                                value={{abbr: formValue[item.name]}}
                                                onKeyUp={(e) => this.handleChange(e.target.value.abbr, item.name)}
                                                classBonusName={classes.selectCurrency}
                                                inputPlaceHolder={""}
                                            />}
                                            textValidate={errors[item.name]}
                                        />
                                    }else if(codeFormValue === DAY_OF_WEEK) {
                                        return <RowFormColumn
                                            label={t(item.label)}
                                            key={key}
                                            input={<AppSelect
                                                options={this.daysOfWeek()}
                                                onChange={(value) => this.handleChange(value, item.name)}
                                                value={formValue[item.name]}
                                                classNamePrefix={"listByTimeSelect"}
                                            />}
                                            textValidate={errors[item.name]}
                                        />
                                    }
                                } else if (item.name === "value" && formValue.code === BLICKO_CONTACT) {
                                    return <RowFormColumn
                                        label={t(item.label)}
                                        className={classes.ckEditor}
                                        key={key}
                                        input={
                                            <AppCKEditor
                                                data={formValue[item.name]}
                                                onChange={(event, editor) => this.handleChange(editor.getData(), item.name)}
                                                config={
                                                    {
                                                        toolbar: ['Heading', 'Link', 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', 'CreatePlaceholder', 'fontColor', 'fontBackgroundColor', 'undo', 'redo'],

                                                    }
                                                }
                                            />}
                                        textValidate={errors[item.name]}
                                    />
                                } else {
                                    let labelInput = this.state.formValue.code === EMAIL_ADDRESS_BCC && item.name==="value"? headLabel+"email" :item.label;
                                        return (<RowFormColumn
                                            label={t(labelInput)}
                                            key={key}
                                            input={<CustomInput
                                                name={item.name}
                                                value={formValue[item.name]}
                                                onChange={(e) => this.handleChange(e.target.value, item.name)}
                                                validate={errors[item.name]}
                                            />}
                                            textValidate={errors[item.name]}
                                        />);
                                    }

                            case NUMBER:
                                if(item.name === "callTime" && this.state.formValue.code === CALL_TIME){
                                return<div key={key}><div className={`${classes.numberCallTime} ${errors[item.name] ? "errorWrapper":""}`}> <Autocomplete
                                    value={formValue[item.name]}
                                    onChange={(event, newValue) => this.handleChange(newValue, item.name)}
                                    className={"selectCallTime"}
                                    getOptionLabel={(option) => option+""}
                                    id="select-call-time"
                                    options={CALL_TIME_OPTIONS}
                                    style={{width: 300}}
                                    renderInput={(params) => <TextField {...params} variant="outlined"/>}
                                /><div className={"unitCallTime"}>{t(headLabel+"unitCallTime")}</div></div>
                                    <ErrorInput textValidate={errors[item.name]}/>
                                </div>
                            }else {
                                return <RowFormColumn
                                    key={key}
                                    label={t(item.label)}
                                    input={<CustomInput
                                        type={item.type}
                                        name={item.name}
                                        value={formValue[item.name]}
                                        onChange={(e) => this.handleChange(e.target.value, item.name)}
                                        validate={errors[item.name]}
                                        textValidate={errors[item.name]}
                                    />}
                                />;
                            }
                            case TIME:
                                return <RowFormColumn
                                    key={key}
                                    label={t(item.label)}
                                    input={<AppKeyboardTimePicker
                                        value={formValue[item.name]}
                                        onChange={(value) => this.handleChange(value, item.name)}
                                        // label={this.props.t("certificateMase.list.startDate")}
                                        emptyLabel={''}
                                        // name={"startDate"}
                                        minutesStep={15}
                                        error={(errors[item.name])}
                                        textValidate={errors[item.name]}
                                    />}
                                    textValidate={errors[item.name]}
                                />;
                            case DATE:
                                return <RowFormColumn
                                    key={key}
                                    label={t(item.label)}
                                    input={<div>
                                        <AppKeyboardDateTimePicker
                                            value={formValue[item.name]}
                                            onChange={(value) => this.handleChange(value, item.name)}
                                            // label={this.props.t("certificateMase.list.startDate")}
                                            emptyLabel={''}
                                            name={"startDate"}
                                            error={(errors[item.name])}
                                            textValidate={errors[item.name]}
                                        />
                                    </div>}
                                    textValidate={errors[item.name]}
                                />;
                            default:
                                return "";
                        }
                    }
                })}

                <CustomButton
                    className={classes.btnResponse}
                    title={this.props.t("button.save")}
                    buttonProps={{
                        startIcon: <React.Fragment><SaveIcon className={"iconAdd"} /></React.Fragment>
                    }}
                    onClick={this.handleSave}/>

            </div>
        )
    }
}

FormParameter.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

export default   compose(
    // connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter
) (FormParameter);
