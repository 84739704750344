import * as types from "../_constants/application";
import axios from "axios";
import {
    API_COUNT_REQUEST_BY_STATUS,
    API_DOCUMENT_REQUEST_VALID,
    API_GET_PARAMETER_CURRENCY,
    API_GET_REQUEST_CHAT_NOT_SEEN,
    API_GET_REQUEST_DOCUMENT_NOT_SEEN,
    API_GET_REQUEST_REPORT_LIST, API_PAYMENT_REQUEST_REPORT_SEEN,
    REQUEST_LIST,
    SHOW_PARAMETER_CONTACT
} from "../constants/apiSuffix";
import { BASE_URL } from "../constants/constants";
import * as currency from "country-currency-map";
import React from "react";
import qs from "qs";
import {GET_COUNT_PAYMENT_REQUEST_REPORT_SEEN} from "../_constants/application";
import {useSelector} from "react-redux";
import {ADMIN_REQUEST} from "../constants/links";
// import {
//     GET_COUNT_REPORT_REQUEST_NOT_PAID,
//     GET_COUNT_UPCOMMING_APPOINTMENT,
//     SET_COUNT_REPORT_REQUEST_NOT_PAID
// } from "../_constants/application";
export const setIsLoading = (isLoading) => {
    return {
        type: types.SET_IS_LOADING,
        isLoading: isLoading
    }
}

export const setOpenNoticeSuccess = (messageNotice) => {
    return {
        type: types.SET_OPEN_NOTICE_SUCCESS,
        messageNotice: messageNotice ?? ""
    }
}
export const setOpenNoticeError = (messageNotice) => {
    return {
        type: types.SET_OPEN_NOTICE_ERROR,
        messageNotice: messageNotice ?? ""
    }
}

export const handleCloseNoticeRedux = () => {
    return {
        type: types.SET_CLOSE_NOTICE,
    }
}

export const getDocumentRequestValid = () => {
    return (dispatch) => {
        let apiUrlType = API_DOCUMENT_REQUEST_VALID;
        axios.get(BASE_URL + apiUrlType)
            .then(response => {
                if (response.status === 200) {
                    let documentRequestType = response.data;
                    if (documentRequestType.size && documentRequestType.size.maxSize === null) {
                        documentRequestType.size.maxSize = 200
                    }
                    if (documentRequestType.type && documentRequestType.type.length === 0) {
                        let type = [
                            {
                                fileType: "image",
                                fileFormat: ['png']
                            },
                            {
                                fileType: "video",
                                fileFormat: ['mp4']
                            }
                        ]
                        documentRequestType.type = type
                    }
                    dispatch({
                        type: types.GET_DOCUMENT_REQUEST_VALID,
                        documentRequestType: documentRequestType
                    })
                } else {
                    throw new Error();
                }
            }).catch((err) => {
                dispatch({
                    type: types.SET_SERVER_500,
                    server500: true
                });
            })
    }
}

export const setProblemDescribe = (problemRequest) => {
    // console.log(problemRequest)
    return {
        type: types.SET_PROBLEM_DESCRIBE,
        problemRequest: problemRequest
    }
};

export const setProblemDescribeRegis = (problemRequestRegis) => {
    return {
        type: types.SET_PROBLEM_DESCRIBE_REGIS,
        problemRequest: problemRequestRegis,
    }
}

export const getCountRequestByStatus = (apiParamCount) => {
    return (dispatch) => {
        let apiUrlCount = API_COUNT_REQUEST_BY_STATUS + "?" + (apiParamCount ? qs.stringify(apiParamCount) : "");

        axios.get(apiUrlCount)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: types.GET_COUNT_REQUEST_BY_STATUS,
                        countRequestByStatus: response.data
                    });
                } else {
                    throw new Error();
                }
            }).catch((err) => {
                console.log(err)
                dispatch({
                    type: types.SET_SERVER_500,
                    server500: true
                });
            })
    }
}


export const getParameterCurrent = () => {
    return (dispatch) => {
        axios.get(API_GET_PARAMETER_CURRENCY)
            .then((response) => {
                if (response.status === 200) {
                    const dataCurrency = response.data.item;
                    let currencyValue = null;
                    if (dataCurrency && dataCurrency.value) {
                        const filterCurrency = currency.getCurrencyList().filter(item => item.abbr === dataCurrency.value);
                        if (filterCurrency.length > 0) {
                            currencyValue = filterCurrency.shift();
                            currencyValue = { ...currencyValue, value: currencyValue.abbr, unit: <span dangerouslySetInnerHTML={{ __html: currencyValue.symbolFormat ? currencyValue.symbolFormat.replace(/\s*\{#\}\s*/gi, "") : "" }} /> }
                        }
                    }
                    dispatch({
                        type: types.GET_PARAMETER_CURRENCY,
                        currency: currencyValue
                    });
                } else {
                    throw new Error();
                }
            }).catch((err) => {
                console.log(err)
                dispatch({
                    type: types.SET_SERVER_500,
                    server500: true
                });
            })
    }
}

export const showParamterContact = () => {
    return (dispatch) => {
        axios.get(SHOW_PARAMETER_CONTACT)
            .then((response) => {
                if (response.status === 200) {
                    const dataParameter = response.data.item;
                    dispatch({
                        type: types.SHOW_PARAMETER_CONTACT,
                        blickoContact: dataParameter.value
                    });
                } else {
                    throw new Error();
                }
            }).catch((err) => {
                console.log(err)
                dispatch({
                    type: types.SHOW_PARAMETER_CONTACT,
                    blickoContact: null
                });
            })
    }
}

export const setErrorAlert = (errorOpen, errorMessage) => {
    return {
        type: types.ERROR_ALERT,
        errorOpen: errorOpen,
        errorMessage: errorMessage,
    }
};


export const setShowAllNote = (showAllNote) => {
    return {
        type: types.SET_SHOW_ALL_NOTE,
        showAllNote: showAllNote
    };
};
export const setShowNoteCell = (rowId, actionType) => {
    return {
        type: types.SET_SHOW_NOTE_CELL,
        rowId: rowId,
        actionType: actionType
        // showAppreciationCellCheck: showAppreciationCellCheck
    };
};

export const setDataFormTab2 = (dataTab2, field) => {
    return {
        type: types.SET_DATE_SELECTED,
        dataTab2: dataTab2,
        field: field
    }
}


export const setOpenModalAddRequestInList = (openModalAddRequestInList) => {
    return {
        type: types.SET_OPEN_MODAL_NEW_REQUEST_IN_LIST,
        openModalAddRequestInList: openModalAddRequestInList
    };
};

export const createReport = (data) => {
    return {
        type: types.CREATE_REPORT,
        dataCreateReport: data,
    }
}


export const setDataDetailReport = (data) => {
    return {
        type: types.DETAIL_REPORT,
        dataDetailReport: data,
    }
}


export const getCountReportRequestNotPaid = (apiParamCount) => {
    return (dispatch) => {
        axios.get(API_GET_REQUEST_REPORT_LIST, {
            params: {
                maxPerPageAll: 1,
                notPaid: 1,
                getApiCount: 1
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: types.GET_COUNT_REPORT_REQUEST_NOT_PAID,
                        reportRequestNotPaid: response.data.items.length ?? 0
                    });
                } else {
                    throw new Error();
                }
            }).catch((err) => {
                dispatch({
                    type: types.SET_SERVER_500,
                    server500: true
                });
            })
    }
}


export const getCountChatNotSeen = (apiParams ) => {
    let apiParamsNew = typeof apiParams === 'object' && !Array.isArray(apiParams) ? {...apiParams} : {}
    let addParams = {    clientSawManagerLastChat: false}
    if (window.location.pathname === ADMIN_REQUEST) {
        addParams = {
            managerSawClientLastChat: false,
        }
    }
    delete (apiParamsNew["filters"])
    delete (apiParamsNew["statusCode"])
    return (dispatch) => {
        // axios.get(API_GET_REQUEST_CHAT_NOT_SEEN, {
        //     params: {
        //         ...apiParamsNew,
        //         maxPerPageAll: 1
        //     }
        // })
        axios.get(REQUEST_LIST+"?"+ qs.stringify({
            ...apiParamsNew,
            ...addParams,
            filters:{
                onlyGetId: 1
            },
            maxPerPageAll: 1
        }))
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: types.GET_COUNT_CHAT_NOT_SEEN,
                        countChatNotSeen: Array.isArray(response?.data?.items) ? response.data.items.map(item => {
                            return item.id;
                        }) : []
                    });
                } else {
                    throw new Error();
                }
            }).catch((err) => {
            dispatch({
                type: types.SET_SERVER_500,
                server500: true
            });
        })
    }
}


export const getCountPaymentRequestReport = (apiParams) => {
    let apiParamsNew = typeof apiParams === 'object' && !Array.isArray(apiParams) ? {...apiParams} : {}
    delete (apiParamsNew["filters"])
    delete (apiParamsNew["statusCode"])
    return (dispatch) => {
        axios.get(API_PAYMENT_REQUEST_REPORT_SEEN, {
            params: {
                ...apiParamsNew,
                maxPerPageAll: 1
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: types.GET_COUNT_PAYMENT_REQUEST_REPORT_SEEN,
                        countPaymentRequestReport: Array.isArray(response?.data?.items) ? response.data.items.map(item => {
                            return item.requestId
                        }) : []
                    });
                } else {
                    throw new Error();
                }
            }).catch((err) => {
            dispatch({
                type: types.SET_SERVER_500,
                server500: true
            });
        })
    }
}

export const setCountPaymentRequestReport = (value = []) => {
    return {
        type: types.SET_COUNT_PAYMENT_REQUEST_REPORT_SEEN,
        countPaymentRequestReport: value
    };
}

export const setCountChatNotSeen = (value = []) => {
    return {
        type: types.SET_COUNT_CHAT_NOT_SEEN,
        countChatNotSeen: value
    };
}


export const setReportRequestNotPaid = (countReportRequestNotPaid) => {
    return {
        type: types.SET_COUNT_REPORT_REQUEST_NOT_PAID,
        reportRequestNotPaid: countReportRequestNotPaid
    };
}

export const toggleNotification = (notification) => {
    return {
        type: types.TOGGLE_NOTIFICATION,
        notification: notification
    };
}


export const setForceUpdateListChangeApiParamsRedux = (value) => {
    return {
        type: types.SET_FORCE_UPDATE_LIST_REDUX,
        forceUpdateListChangeApiParams: value
    };
}

export const getCountUpcomingAppointment = (apiParams) => {
    let apiParamsNew = typeof apiParams === 'object' && !Array.isArray(apiParams) ? {...apiParams}: {}
    // console.log({
    //     ...apiParamsNew,
    //     upcomingAppointment: 1,
    //     pageSize: 1
    // })
    delete (apiParamsNew["filters"])
    delete (apiParamsNew["statusCode"])
    return (dispatch) => {
        axios.get(REQUEST_LIST +"?"+ qs.stringify({
                ...apiParamsNew,
                upcomingAppointment: 1,
                pageSize: 1
            }))
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: types.GET_COUNT_UPCOMMING_APPOINTMENT,
                        requestUpcomingAppointment: response.data.nbResults ?? 0
                    });
                } else {
                    throw new Error();
                }
            }).catch((err) => {
            dispatch({
                type: types.SET_SERVER_500,
                server500: true
            });
        })
    }
}

export const getRequestDocumentManagerNotSeen = (apiParams) => {
    let apiParamsNew = typeof apiParams === 'object' && !Array.isArray(apiParams) ? {...apiParams}: {}
    delete (apiParamsNew["filters"])
    delete (apiParamsNew["statusCode"])
    let filterField = "clientSawLastDocumentManagerUpload";
    if (window.location.pathname === ADMIN_REQUEST) {
        filterField = "managerSawLastDocumentClientUpload"
    }
    return (dispatch) => {

        // axios.get(API_GET_REQUEST_DOCUMENT_NOT_SEEN, {
        //     params: {
        //         ...apiParamsNew,
        //         maxPerPageAll: 1
        //     }
        // })
        axios.get(REQUEST_LIST + "?" + qs.stringify({
            ...apiParamsNew,
            [filterField] : "false",
            filters: {
                    onlyGetId: 1
                },
                maxPerPageAll: 1
            }))
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: types.GET_COUNT_REQUEST_DOCUMENT_NOT_SEEN,
                        requestDocumentManagerNotSeen: (response.data.items ?? []).map(item=>{
                            return item.id;
                        })
                    });
                } else {
                    throw new Error();
                }
            }).catch((err) => {
                dispatch({
                    type: types.SET_SERVER_500,
                    server500: true
                });
            })
    }
}

export const setRequestDocumentManagerNotSeen = (value) => {
    return {
        type: types.SET_COUNT_REQUEST_DOCUMENT_NOT_SEEN,
        requestDocumentManagerNotSeen: value
    };
}

export const setCountUpcomingAppointment = (value) => {
    return {
        type: types.SET_COUNT_UPCOMMING_APPOINTMENT,
        changeValue: value
    };
}

export const removeValueToRequestDocumentManagerNotSeenRedux = (value) => {
    if (value && parseInt(value)) {
        return {
            type: types.REMOVE_VALUE_TO_COUNT_REQUEST_DOCUMENT_NOT_SEEN,
            valueRemove: value
        };
    }else{
        return {}
    }
}

export const addValueToRequestDocumentManagerNotSeenRedux = (value) => {
    if (value && parseInt(value)) {
        return {
            type: types.ADD_VALUE_TO_COUNT_REQUEST_DOCUMENT_NOT_SEEN,
            valueAdd: value
        };
    }else{
        return {}
    }
}

export const setQueryFurniture = (value) => {
    return {
        type: types.SET_QUERY_FURNITURE,
        queryFurniture: value
    };
}
