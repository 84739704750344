import React from "react";
import { CircularProgress, Paper, Typography, Grid as MuiGrid, TextField } from "@material-ui/core";
import {
    Grid,
    PagingPanel,
    Table,
    TableEditRow,
    TableHeaderRow,
    TableFilterRow,
    VirtualTable, 
    TableColumnResizing, TableTreeColumn, TableRowDetail
} from "@devexpress/dx-react-grid-material-ui";
import {
    CustomPaging,
    EditingState,
    FilteringState,
    IntegratedFiltering,
    PagingState,
    SortingState, GroupingState, IntegratedGrouping, TreeDataState, CustomTreeData, RowDetailState
} from "@devexpress/dx-react-grid";
import axios from "axios";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import i18n from "../i18n";
import XLSX from 'xlsx';
import LoadingAction from "./LoadingAction";
import AppFreeSoloAutocomplete from "./form/Autocomplete/AppFreeSoloAutocomplete";
import FilterCell from "./list/FilterCell";
import qs from 'qs';
import _ from 'lodash';
import { getParamInUrl } from "../functions/functions";
import { useTranslation, withTranslation } from "react-i18next";
import { compose } from "redux";
import nextIcon from "../images/request/requestAdmin/nextIcon.svg"
import endIcon from "../images/request/requestAdmin/endIcon.svg"
import CustomInlineSvg from "./CustomElements/CustomInlineSvg";
import goIconClient from "../images/request/status/checktick.svg";
import nogoClient from "../images/request/status/nogonew.svg";
import waitClient from "../images/request/status/hourglassList.svg";
import stopClient from "../images/request/status/stopList.svg";
import underAnalyse from "../images/request/UNDER_ANALYSIS_BY_THE_EXPERT.svg"
import { ADD_PARAMS_FILTERS_LIST, REMOVE_PARAMS_FILTERS_LIST, SM, XS } from "../constants/constants";
import * as applicationAction from "../_actions/application";
import {connect} from "react-redux";
const styles = theme => ({
    cellHeader: {
        padding: "15px 5px !important",
        lineHeight: "1rem",
        textTransform: 'uppercase',
        letterSpacing: -0.53,
        fontWeight: 'bold',
        fontFamily: `"Nunito", sans-serif`,
    },
    tablePaginationBlock: {
        margin: "20px 0",
        alignItems: "center",
        width: '100%',
        justifyContent:'flex-end',
        [theme.breakpoints.down(XS)]:{
            justifyContent:'center',

            '& .MuiAccordionSummary-root':{
            padding: '0px 3px',
            },
            '& .MuiAccordionSummary-content':{
             margin: '3px 0 !important',
            },
            '& .Mui-expanded':{
                marginBottom: '0 !important',
                minHeight: '63px',

                '& .SectionTitleWrapper':{
                    borderBottomLeftRadius: 0,
                }
            },
        },
        '& .pagingInfos': {
            display: 'flex',
            '& .goToPage': {
                marginRight: 10
            },
            [theme.breakpoints.down(XS)]: {
                display: 'flex',
                flexDirection: 'column',
            }
        },
        '& .paginateCustom': {
            padding: '2rem 0',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '& .perPage': {
                marginRight: 10
            },
            [theme.breakpoints.down(XS)]: {
                justifyContent: 'center'
            }
        },
        '& .selectPageSize': {
            alignItems: 'center',
            display: 'flex',
            '& .perPage': {
                marginRight: 10,
                [theme.breakpoints.down(XS)]: {
                    fontSize: '0.8rem',
                }
            },
            '& input': {
                [theme.breakpoints.down(XS)]: {
                    fontSize: '0.8rem',
                }
            }
        },
        '& .goToPageWrapper': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .goToPage': {
            marginRight: 10,
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down(XS)]: {
                fontSize: '0.8rem',
            }
        },
        '& .buttonWrapper': {
            display: 'flex',
            marginLeft: 20,
            [theme.breakpoints.down(XS)]: {
                marginTop: 10
            },
            '& .Mui-disabled': {
                '& svg>path': {
                    stroke: '#D8DDE6'
                },
                '& svg>rect': {
                    fill: '#D8DDE6'
                }
            }
        },
        "& button": {
            minWidth: "2.5rem"
        },
        "& .noResult": {
            margin: 'auto',
            fontSize: '1rem',
            marginTop: 10
        },
        '& .MuiCircularProgress-root': {
            margin: 'auto'
        },

    },
    tablePaginationBlockCustom: {
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        '& .paginateClient': {
            maxWidth: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            '& .pagingInfos': {
                flexWrap: 'wrap'
            }
        },
        [theme.breakpoints.down(SM)]: {
            '& .paginateClient': {
                justifyContent: 'flex-end'
            },
            '& .selectPageSize': {
                marginBottom: '0.8rem'
            }
        },
        [theme.breakpoints.down(XS)]: {
            '& .selectPageSize': {
                margin: '0.25rem 0',
                fontSize: '0.8rem'
            },
            '& .paginateClient': {
                justifyContent: 'center'
            },
            '& .buttonWrapper': {
                marginLeft: 0,
                marginTop: '0.5rem'
            }
        }
    },
    pageSizeButton: {
        "&.active": {
            border: "1px solid #0000ff",
        }
    },
    paginationPageNum: {
        padding: "0",
        minWidth: 40,
        minHeight: 40,
        [theme.breakpoints.down(XS)]: {
            height: 30,
            width: 30,
            minWidth: 'unset',
            minHeight: 'unset',
        },
        "&.active": {
            background: "#0000ff",
            color: "white",
            margin: '0 5px'
        },
        "&:hover:not(.active)": {
            background: "rgba(70,67,90,0.81)",
            color: "white"
        },
        "&.prevBtn,&.nextBtn": {
            fontSize: "1.2rem",
            width: 49,
            border: '1px solid #D8DDE6',
        },
        "&.prevBtn": {
            '& svg': {
                transform: 'rotate(180deg)!important'
            }
        }
    },
    paginationResultInfos: {
        padding: "0 5px",
        margin: "0 1rem",
        alignItems: "center",
        display: "flex",
        [theme.breakpoints.down(XS)]: {
            width: '100%',
            textAlign: 'center',
            margin: '0.5rem 0',
            fontSize: '0.8rem'
        }
    },
    tableBlock: {
        borderSpacing: '0px 15px',
        tableLayout: "auto",
        "& tr>td:first-child":{
            //paddingLeft: "10px",
            padding:0,
            // paddingLeft: "10px !important",
            // minWidth: 82
        },

    },
    tableHeader: {
        display: 'flex',
        alignItems: 'center',
        '& h6': {
            color: '#0000ff',
            textTransform: 'uppercase',
            fontSize: 23,
            fontWeight: 'bold',
            marginLeft: 15,
            lineHeight: "25px",
            paddingRight: "26px",
            flexGrow: 1,
        },
        '&.customHeaderColumn': {
            justifyContent: 'space-between'
        }
    },
    linkNew: {
        float: 'right',
        marginTop: 30,
        marginRight: 40,
    },
    customHeader: {
        display: 'flex',
        alignItems: 'center',
        '&.columnStyle': {
            flexDirection: 'column',
            '& h6': {
                margin: 0,
                padding: 0
            },
            '& img': {
                marginBottom: 5
            }
        }
    },
    statusDescription: {
        display: 'flex',
        flexWrap: 'wrap',
        width: 'auto',
        position: 'relative',
        marginLeft: '16px',
        marginBottom: '0.8rem',

        '&:before': {
            position: 'absolute',
            content: '""',
            width: 1,
            height: '70%',
            background: '#707070',
            opacity: 0.16,
            left: '27%',
            top: '50%',
            transform: 'translateY(-50%)'

        },
        '& .statusComponent': {
            width: '34%',
            display: 'flex',
            alignItems: 'center',
            color: '#6d6d6d',
            fontWeight: 300,
            fontSize: '1rem',
          
            padding: '4px',
            '& svg': {
                width: 30
            },
            '& img': {
                width: 20,
                height: 20,
                marginRight: 10
            },
            '& span':{
                maxWidth: '140px',
                lineHeight: '16px',
            },
        },
        [theme.breakpoints.down(XS)]: {
            justifyContent: 'center'
        }
    }
});
const pageSizes = [10, 30, 50];
// const dateCompare = (a, b) => {
//     let momentA = moment(a, "DD/MM/YYYY HH:mm");
//     let momentB = moment(b, "DD/MM/YYYY HH:mm");
//     if (!momentB.isValid()) {
//         return 1;
//     }
//     if (!momentA.isValid()) {
//         return -1;
//     }
//     return momentA.format("X") < momentB.format("X") ? -1 : (momentA.format("X") === momentB.format("X") ? 0 : 1);
// };

// const booleanCompare = (a, b) => {
//     if (!a) {
//         return -1;
//     }
//     if (!b) {
//         return 1;
//     }
//     return dateCompare(a.props.title, b.props.title);
// };

const useStyles = makeStyles(styles);


const CellHeaderComponent = (props) => {
    const classes = useStyles();
    if (props.column.hasOwnProperty('imageTitle')) {
        return <TableHeaderRow.Cell
            {...props}
            className={(props.column.title ? " " : "childrenDisplayNone ") + classes.cellHeader}
        >
            {
                props.column.imageTitle
                    ?
                    <div>{props.column.imageTitle}</div>
                    :
                    <span>
                        <span>{props.column.title}</span>
                    </span>
            }
        </TableHeaderRow.Cell>
    } else {
        return <TableHeaderRow.Cell {...props} className={(props.column.title ? " " : "childrenDisplayNone ") + classes.cellHeader}  children={props.children} resizingEnabled={!!props.resizingEnabled}
                                    draggingEnabled={!!props.draggingEnabled}/>;
    }
};

const customSetPageSize = (e, action) => {
    let value = e.target.value
    if (value) value = value.replace(/[^0-9]/g, "")
    if (action) action(value)
}
const customSetPageSizeSolo = (value, pageSize, action1, action2) => {
    if (value) value = value.replace(/[^0-9]/g, "")
    if (parseInt(value) !== 0 && !(typeof (value) === 'string' && value.length === 0)) {
        if (action1) action1(parseInt(value))
        if (action2) action2(value)
    }
}

const PagingPageSizeComponent = (props) => {
    // const classes = useStyles();
    const { t } = useTranslation()
    let pageSizeOptions = pageSizes;
    const [pageSize, setPageSize] = React.useState(props.pageSize);
    if (!pageSizeOptions.includes(props.pageSize)) {
        pageSizeOptions.push(props.pageSize);
    }
    pageSizeOptions.sort(function (a, b) {
        return a - b;
    });
    return <React.Fragment>
        {
            // props.totalCount >0 && props.pageSizes && props.pageSizes.length &&
            // <MuiGrid container item xs={3} justify={"left"}>{props.pageSizes.map((item)=>{
            //     return <MuiGrid item><Button className={classes.pageSizeButton+(item===props.pageSize?" active":"")} onClick={(e)=>props.onPageSizeChange(item)} noWrap>{item}</Button></MuiGrid>;
            // })}</MuiGrid>
            props.totalCount > 0 && props.pageSizes && props.pageSizes.length &&
            <MuiGrid item className="selectPageSize">
                <span className={'perPage'}>{t('list.resultPerPage')}</span>
                <AppFreeSoloAutocomplete
                    fullWidth={false}
                    options={pageSizeOptions ? pageSizeOptions.map((item) => item.toString()) : []}
                    disableClearable
                    value={props.pageSize}
                    style={{ height: "auto", borderRadius: 10 }}
                    onChange={(e, value) => {
                        customSetPageSizeSolo(value, pageSize, props.onPageSizeChange, setPageSize)
                        // props.onPageSizeChange(parseInt(value));
                        // setPageSize(value);
                    }}
                    renderOption={undefined}
                    filterOptions={undefined}
                    renderInput={params => (
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                                value: pageSize
                            }}
                            variant={"outlined"}
                            onChange={(e) => customSetPageSize(e, setPageSize)}
                        />

                    )}
                />
            </MuiGrid>
        }
    </React.Fragment>;
};

const PagingInfosComponent = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    // console.log(props)
    return <React.Fragment>
        {props.totalCount > 0 && <MuiGrid item className="pagingInfos">
            <MuiGrid item className={classes.paginationResultInfos}>
                {props.getMessage("info",
                    {
                        from: props.currentPage < props.totalPages ?
                            props.currentPage * props.pageSize + 1 :
                            (props.totalPages - 1) * props.pageSize + 1,
                        to: (props.currentPage < props.totalPages - 1 ? props.pageSize * (props.currentPage + 1) : props.totalCount), count: props.totalCount
                    })}
            </MuiGrid>
            {props.totalPages > 1 && <React.Fragment>
                <MuiGrid item style={{
                    display: 'flex'
                }}>
                    <span className={'goToPage'}>{t('list.goToPage')}</span>
                    {props.currentPage > 0 && <MuiGrid item><Button className={classes.paginationPageNum + " paginationPageNum"} onClick={(e) => props.onCurrentPageChange(props.currentPage - 1)}>{props.currentPage}</Button></MuiGrid>}
                    <MuiGrid item><Button className={classes.paginationPageNum + " paginationPageNum active"}>{props.currentPage + 1}</Button></MuiGrid>
                    {props.currentPage < props.totalPages - 1 && <MuiGrid item><Button className={classes.paginationPageNum + " paginationPageNum"} onClick={(e) => props.onCurrentPageChange(props.currentPage + 1)}>{props.currentPage + 2}</Button></MuiGrid>}
                </MuiGrid>
                <MuiGrid className={'buttonWrapper'}>
                    <MuiGrid item>
                        <Button className={classes.paginationPageNum + " paginationPageNum prevBtn"}
                            onClick={() => props.onCurrentPageChange(0)}
                            disabled={props.currentPage <= 0}
                        >
                            <CustomInlineSvg
                                svgProps={{ src: endIcon }}
                            />
                        </Button>
                    </MuiGrid>
                    <MuiGrid item>
                        <Button className={classes.paginationPageNum + " paginationPageNum prevBtn"}
                            onClick={() => props.onCurrentPageChange(props.currentPage - 1)}
                            disabled={props.currentPage <= 0}
                        >
                            <CustomInlineSvg
                                svgProps={{ src: nextIcon }}
                            />
                        </Button>
                    </MuiGrid>
                    <MuiGrid item>
                        <Button className={classes.paginationPageNum + " paginationPageNum nextBtn"}
                            onClick={() => props.onCurrentPageChange(props.currentPage + 1)}
                            disabled={props.currentPage >= props.totalPages - 1}
                        >
                            <CustomInlineSvg
                                svgProps={{ src: nextIcon }}
                            />
                        </Button>
                    </MuiGrid>
                    <MuiGrid item>
                        <Button className={classes.paginationPageNum + " paginationPageNum nextBtn"}
                            onClick={() => props.onCurrentPageChange(props.totalPages - 1)}
                            disabled={props.currentPage >= props.totalPages - 1}
                        >
                            <CustomInlineSvg
                                svgProps={{ src: endIcon }}
                            />
                        </Button>
                    </MuiGrid>
                </MuiGrid>
            </React.Fragment>}
        </MuiGrid>}
    </React.Fragment>;
};

const PagingPanelComponent = (props) => {
    // console.log(props)
    const classes = useStyles();
    //return <PagingPanel.Container {...props} className={classes.tablePaginationBlock} />;
    return <MuiGrid container direction={"row"}  className={classes.tablePaginationBlock + " tablePaginationBlock"}>
        {/*{props.totalCount >0 && <div>Hello</div>}*/}
        <PagingPageSizeComponent {...props} />
        <PagingInfosComponent {...props} />
    </MuiGrid>;
};

const PagingPanelComponentCustom = (props) => {
    const classes = useStyles();
    const { t } = useTranslation()
    const statusArr = [
        {
            name: 'valid',
            title: t('request.status.valid'),
            icon: goIconClient
        },
        {
            name: 'inProgress',
            title: t('request.status.inProgress'),
            icon: waitClient
        },
        {
            name: 'notEligible',
            title: t('request.status.notEligible'),
            icon: nogoClient
        },
        {
            name: 'UNDER_ANALYSIS_BY_THE_EXPERT',
            title: t('request.list.statusRequest.CLIENT_STATUS_UNDER_ANALYSIS_BY_THE_EXPERT'),
            icon: underAnalyse
        },
        {
            name: 'canceled',
            title: t('request.status.canceled'),
            icon: stopClient
        }
    ]
    //return <PagingPanel.Container {...props} className={classes.tablePaginationBlock} />;
    return <MuiGrid container direction={"row"} spacing={3} className={classes.tablePaginationBlock + " " + classes.tablePaginationBlockCustom + " tablePaginationBlock"}>
        {props.totalCount > 0 &&
            <div className={classes.statusDescription}>
                {
                    statusArr.map((item, key) => {
                        return <span className={"statusComponent"} key={key}>
                            {/*<CustomInlineSvg svgProps={{src: item.icon}} scale={0.5}/>*/}
                            <img src={item.icon} alt="" />
                            <span>{item.title}</span>
                        </span>
                    })
                }
            </div>
        }
        <div className={'paginateClient'}>
            <PagingPageSizeComponent {...props} />
            <PagingInfosComponent {...props} />
        </div>
    </MuiGrid>;
}

const ListHeader = (props) => {
    const classes = useStyles();
    return <div className={`${classes.tableHeader} ${'tableHeader'} ${typeof props.hasChangeStyle === "object" && Object.keys(props.hasChangeStyle).length !== 0 ? 'customHeaderColumn' : ''}`}>
        {typeof props.hasChangeStyle === "object" && Object.keys(props.hasChangeStyle).length !== 0 ?
            <div className={`${classes.customHeader} ${"customTableHeader"} ${props.hasChangeStyle.type === 'column' ? "columnStyle" : ''}`}>
                {props.iconList && <img src={props.iconList} alt="iconList" />}
                <Typography variant="h6">{props.t(props.titleList)}</Typography>
            </div>
            :
            <React.Fragment>
                {props.iconList && <img src={props.iconList} alt="iconList" />}
                <Typography variant="h6">{props.t(props.titleList)}</Typography>
            </React.Fragment>
        }
        {/*{props.routeNameNew?*/}
        {/*    props.routeNameList === links.PERSON_LIST ?*/}
        {/*    <NavLink to={props.routeNameNew}>*/}
        {/*        <Button className="personNewButton">*/}
        {/*            <PersonAddIcon/>*/}
        {/*            <span>{props.t('newButton.employee')}</span>*/}
        {/*        </Button>*/}
        {/*    </NavLink>*/}
        {/*    :*/}
        {/*    <NavLink className={classes.linkNew} to={props.routeNameNew}>{props.iconNew && <img src={props.iconNew} alt="iconNew"/>}</NavLink>:""*/}
        {/*}*/}
        {typeof props.leftHeaderComponent !== "undefined" && props.leftHeaderComponent !== null && props.leftHeaderComponent}
        {typeof props.rightHeaderComponent !== "undefined" && props.rightHeaderComponent !== null && props.rightHeaderComponent}
    </div>
};
const ToggleCellComponent = ({ row, ...restProps }) => {
    return <TableRowDetail.ToggleCell row={row} {...restProps} />;
}
const TableComponent = (props) => {
    const classes = useStyles();
    return <Table.Table {...props} className={classes.tableBlock} />
};
const HeadComponent = props => {
    return <Table.TableHead {...props} className={'tableHeadBlock'} />
}

class List extends React.Component {

    constructor(props) {
        super(props);
        this.currentUrl = null;

        this.state = Object.assign({
            urlParams: {},
            language: i18n.language,
            iconList: '',
            hasChangeStyle: {},
            iconNew: '',
            classList: '',
            columns: [],
            rows: [],
            allRows: [],
            currentPage: 1,
            pageSize: 10,
            sorting: [],
            totalCount: 0,
            totalPage: 1,
            hiddenColumns: [],
            search: props.searchValue ? props.searchValue : null,
            searchInProcess: props.searchInProcess ? props.searchInProcess : false,
            deleteHand: false,
            sortingStateColumnExtensions: [],
            sortingColumnStates: [],
            tableProps: {},
            CellHeaderProps: {},
            groupingColumns: [],
            tableMessages: {
                noData: this.props.noResultText ? this.props.noResultText : "text.noResult"
            },
            tablePagingMessages: {
                rowsPerPage: "text.table.paging.rowsPerPage",
                info: "text.table.paging.info",
                showAll: "text.table.paging.showAll"
            },
            error: '',
            success: '',
            titleList: '',
            loading: true,
            nbPages: 1,
            filters: [],
            defaultFilters: [],
            filtersState: [],
            filterColumnsEnabled: [],
            /*api link*/
            apiNameList: '',
            apiParams: {},
            apiNameSearch: '',
            apiNameDelete: '',
            columnWidths: [],
            columnOrders: [],
            /*route link*/
            routeNameNew: '',
            routeNameList: '',
            forceUpdate: false,
            personId: '',
            exportExcelRequest: false,
            exportExcelRequestAll: false,
            exportExcelIgnoredColumnList: [],
            changeWidthColumns: true,
            useScollable: props.useScollable ?? false,
            tableHeightScollable: props.tableHeightScollable ?? null,
            hiddenPaginate: false,
            exportExcelInProcess: false,
            componentState: props.componentState,
            addItemRow: false,
            additionalRowsFromParent: [],
            defaultColumnWidths: props.defaultColumnWidths ?? [],
            defaultRows: [],
            aggregations: null,
            rowsApi: [],
            expandedRowIds: [],
            isApiPlatform: false,
            pageSizeChange: null,
            columnSetDefaultWidths:[]
        }, props.componentState ? props.componentState : {});
        // this.change = this.change.bind(this);
        this.tableBlockRef = React.createRef();
        this.state.columns = this.state.columns.filter((column) => !column.name || !this.state.hiddenColumns.includes(column.name));
        this.state.columnOrders = this.state.columns.map(column => column.name);
        this.paramsName = "list_" + this.state.apiNameList + "_" + window.location.pathname.replace("/", "_") + "_params";
        let pageInfos = localStorage.getItem(this.paramsName);
        if (!pageInfos) {
            pageInfos = {};
        }
        else {
            pageInfos = JSON.parse(pageInfos)
        }
        if (pageInfos.hasOwnProperty("pageSize")) {
            this.state.pageSize = pageInfos.pageSize;
        }
        if (pageInfos.hasOwnProperty("sorting")) {
            this.state.sorting = pageInfos.sorting;
        }
        if (pageInfos.hasOwnProperty("columnOrders")) {
            this.state.columnOrders = pageInfos.columnOrders;
        }
        this.hasSaveColumnWidths = false;
        if (pageInfos.hasOwnProperty("columnWidths")) {
            this.hasSaveColumnWidths = true;
            this.state.columnWidths = pageInfos.columnWidths;
        } else {
            if (Array.isArray(this.state.columnSetDefaultWidths) && this.state.columnSetDefaultWidths.length) {
                this.state.columnSetDefaultWidths.forEach(itemProps => {
                    const widthColumnIndex = this.state.columnWidths.findIndex((columnWidthProps) => columnWidthProps.columnName === itemProps.columnName)
                    if (widthColumnIndex !== -1) {
                        this.state.columnWidths[widthColumnIndex] = itemProps;
                    } else {
                        this.state.columnWidths.push(itemProps);
                    }
                })
            }
        }
        if (!this.state.columnWidths) {
            this.state.columnWidths = this.state.defaultColumnWidths;
        }
        this.state.columns.forEach((columnProps, idx, table) => {
            if (!columnProps.name) {
                columnProps.name = `col_${idx}`;
                let columns = [...this.state.columns]
                columns[idx] = columnProps;
                this.setState({
                    columns: [...columns]
                })
            }
            if (!this.state.defaultColumnWidths) {
                // this.setState({ defaultColumnWidths: [] });
                this.state.defaultColumnWidths = [];
            }
            if (this.state.defaultColumnWidths.findIndex((columnWidthProps) => columnWidthProps.columnName === columnProps.name) === -1) {
                this.state.defaultColumnWidths.push({ "columnName": columnProps.name, "width": "auto" });
                // let defaultColumnWidths = [...this.state.defaultColumnWidths, { "columnName": columnProps.name, "width": "auto" }];
                // this.setState({ defaultColumnWidths });
            }
            if (this.state.columnWidths.findIndex((columnWidthProps) => columnWidthProps.columnName === columnProps.name) === -1) {
                this.state.columnWidths.push({ "columnName": columnProps.name, "width": 40 });
                // let columnWidths = [...this.state.columnWidths, { "columnName": columnProps.name, "width": 40 }];
                // this.setState({ columnWidths });
            }
            else if (this.state.columnWidths.findIndex((columnWidthProps) => columnWidthProps.columnName === columnProps.name) > -1 && !this.state.columnWidths[this.state.columnWidths.findIndex((columnWidthProps) => columnWidthProps.columnName === columnProps.name)].width) {
                this.state.columnWidths[this.state.columnWidths.findIndex((columnWidthProps) => columnWidthProps.columnName === columnProps.name)] = {
                    ...this.state.columnWidths[this.state.columnWidths.findIndex((columnWidthProps) => columnWidthProps.columnName === columnProps.name)],
                    width: 40
                };
            }
            if (!this.state.columnOrders.includes(columnProps.name)) {
                this.state.columnOrders.push(columnProps.name);
                // this.setState({ columnOrders: [...this.state.columnOrders, columnProps.name] });
            }
        });

        this.getData = this.getData.bind(this);
        this.loadData = this.loadData.bind(this);
        this.exportExcelCurrentPage = this.exportExcelCurrentPage.bind(this);
        this.exportExcelAll = this.exportExcelAll.bind(this);
        this.sortData = this.sortData.bind(this);
        this.changeFilters = this.changeFilters.bind(this);
        this.onColumnOrdersChange = this.onColumnOrdersChange.bind(this);
        this.treatExportExcelData = this.treatExportExcelData.bind(this);
        this.saveTableStateParameters = this.saveTableStateParameters.bind(this);
        this.commitChanges = this.commitChanges.bind(this);
        this.addEmptyRow = this.addEmptyRow.bind(this);
        this.onColumnWidthsChange = this.onColumnWidthsChange.bind(this);
        this.generateApiUrl = this.generateApiUrl.bind(this);
        this.onCurrentPageChange = this.onCurrentPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.setExpandedRowIds = this.setExpandedRowIds.bind(this)
    }

    onPageSizeChange(setPageSize) {
        // console.log(setPageSize)
        if (setPageSize && typeof (setPageSize) === "string") setPageSize = setPageSize.replace(/[^0-9]/g, "")
        let { urlParams, pageSize } = this.state;
        urlParams = { ...urlParams, maxPerPage: setPageSize, currentPage: 1 }
        const self = this;
        if (parseInt(setPageSize) !== 0 && parseInt(pageSize) !== parseInt(setPageSize) && !(typeof (setPageSize) === 'string' && setPageSize.length === 0)) {
            this.setState({
                pageSize: setPageSize,
                currentPage: 1,
                urlParams: urlParams,
                loading: true,
                isFiltering: true
            }, () => {
                const path = self.props.urlParamId ? self.props.match.path.replace(":id", self.props.urlParamId) : self.props.match.path;
                self.props.history.push(path + '?' + qs.stringify(urlParams));
            });
        }
    }
    keydownCustom = (e) => {
        let value = e.target.value
        value = value.replace(/[^0-9]/g, "")
        let { urlParams, pageSize, 
            // pageSizeChange 
        } = this.state;
        urlParams = { ...urlParams, maxPerPage: value, currentPage: 1 }
        const self = this;
        if (parseInt(e.keyCode) === 13 && parseInt(value) !== 0 && parseInt(pageSize) !== parseInt(value) && !(typeof (value) === 'string' && value.length === 0)) {
            this.setState({
                pageSize: value,
                loading: true,
                isFiltering: true,
                pageSizeChange: null,
                urlParams: urlParams
            }, () => {
                const path = self.props.urlParamId ? self.props.match.path.replace(":id", self.props.urlParamId) : self.props.match.path;
                self.props.history.push(path + '?' + qs.stringify(urlParams));
            })
        }
    }
    onPageSizeChangeCustom = (event, setPageSize) => {
        setPageSize = setPageSize.replace(/[^0-9]/g, "")
        this.setState({
            pageSizeChange: setPageSize,
        }, () => {
            // const path = self.props.urlParamId ?  self.props.match.path.replace(":id", self.props.urlParamId) : self.props.match.path;
            // self.props.history.push(path + '?' + qs.stringify(urlParams));
        });
    }

    onCurrentPageChange(setCurrentPage) {
        let { urlParams } = this.state;
        urlParams = { ...urlParams, currentPage: setCurrentPage + 1 }
        const self = this;
        this.setState({
            currentPage: setCurrentPage + 1,
            loading: true,
            urlParams: urlParams,
            isFiltering: true
        }, () => {
            const path = self.props.urlParamId ? self.props.match.path.replace(":id", self.props.urlParamId) : self.props.match.path;
            self.props.history.push(path + '?' + qs.stringify(urlParams));
        });
        if (this.props.loadEdit) {
            this.props.loadEdit()
        }
        if (this.props.onCurrentPageChange) {
            this.props.onCurrentPageChange()
        }

    }

    addEmptyRow() {
        // let { rows } = this.state;
    }

    commitChanges = ({ added, changed, deleted }) => {
        let { rows } = this.state;
        let changedRows;
        if (added) {
            const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
            changedRows = [
                ...rows,
                ...added.map((row, index) => ({
                    id: startingAddedId + index,
                    ...row,
                })),
            ];
        }
        if (changed) {
            changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
        }
        if (deleted) {
            const deletedSet = new Set(deleted);
            changedRows = rows.filter(row => !deletedSet.has(row.id));
        }
        this.setState({
            rows: changedRows
        })
    };

    onColumnOrdersChange(columnOrders) {
        // const self = this;
        this.setState({
            columnOrders: columnOrders
        }, function () {
            // self.saveTableStateParameters();
        });
    }

    /**
     *
     * @param filters
     */
    changeFilters(filters) {
        let self = this;
        if (self.filterDelay) {
            clearTimeout(this.filterDelay);
            self.filterDelay = null;
        }
        self.setState({
            filtersState: filters
        });

        let urlFilters = {};
        filters.forEach(item => {
            if (item.value !== '') {
                urlFilters = { ...urlFilters, [item.columnName]: item.value }
            }

        })
        let { urlParams } = this.state;
        urlParams = { ...urlParams, filters: urlFilters, currentPage: 1 }

        self.filterDelay = setTimeout(function () {
            self.setState({
                loading: true,
                currentPage: 1,
                filters: filters,
                urlParams: urlParams,
                isFiltering: true
            }, () => {
                const path = self.props.urlParamId ? self.props.match.path.replace(":id", self.props.urlParamId) : self.props.match.path;
                self.props.history.push(path + '?' + qs.stringify(urlParams));
            });
        }, 800);
    }

    exportExcelAll() {
        // axios.get(Routing.generate(this.state.apiNameList, Object.assign({}, this.state.apiParams, {maxPerPageAll: 1})))
        axios.get(this.state.apiNameList, {
            params: Object.assign({}, this.state.apiParams, { maxPerPageAll: 1 })
        })
            .then(response => {
                if (response.status === 200) {
                    let rows = response.data.items;
                    if (typeof this.state.addAdditionalRowsCallback === "function") {
                        rows = this.state.addAdditionalRowsCallback(rows);
                    }
                    this.treatExportExcelData(rows);
                    this.setState({
                        exportExcelInProcess: false
                    });
                }
                else {
                    throw new Error();
                }
            })
            .catch(error => {
                this.setState({
                    exportExcelInProcess: false
                });
            });
    }

    treatExportExcelData(rows) {
        const excelData = [];
        const orderColumns = this.state.columnOrders.filter((column) => !this.state.hiddenColumns.includes(column) && !(this.state.exportExcelIgnoredColumnList && this.state.exportExcelIgnoredColumnList.includes(column)));
        const columns = orderColumns.map((column) => {
            return this.state.columns.find((item) => item.name === column);
        });
        const excelHeaderRow = columns.map((item) => {
            return this.props.t(item.title);
        });
        excelData.push(excelHeaderRow);
        rows.forEach((rowData) => {
            let excelRow = [];
            columns.forEach((item) => {
                let cellData = "";
                if (item.getExportValue && typeof item.getExportValue === "function") {
                    cellData = item.getExportValue(rowData);
                }
                else if (item.getCellValue && typeof item.getCellValue === "function") {
                    cellData = item.getCellValue(rowData);
                    if (React.isValidElement(cellData)) {
                        let cellProps = cellData.props;
                        while (typeof cellData.type !== "string" && cellProps && cellProps.children) {
                            cellData = cellData.props.children;
                            cellProps = cellData.props;
                        }
                        const type = cellData.type;
                        switch (type) {
                            case "img":
                            case "a":
                                cellData = cellData.props.src ? cellData.props.src : "";
                                if (cellData && !cellData.match(/http/ig)) {
                                    cellData = window.location.protocol + "://" + window.location.host + cellData;
                                }
                                break;
                            default:
                                if (typeof type === "string") {
                                    if (cellData.props && cellData.props.children) {
                                        cellData = cellData.props.children;
                                    }
                                }
                                break;
                        }
                    }
                }
                else if (item.name && rowData.hasOwnProperty(item.name)) {
                    cellData = rowData[item.name];
                }
                excelRow.push(cellData);
            });
            excelData.push(excelRow);
        });
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(excelData);
        XLSX.utils.book_append_sheet(wb, ws);
        XLSX.writeFile(wb, (this.state.titleList ? this.props.t(this.state.titleList) : "data") + ".xlsx");
    }

    exportExcelCurrentPage() {
        this.treatExportExcelData(this.state.rows);
        this.setState({
            exportExcelInProcess: false
        });

    }


    setExpandedRowIds(row) {
        // console.log(row)
        this.props.onChangeExpandedListIds(row)
        this.setState({
            expandedRowIds: row
        })
    }

    componentDidMount() {
        this._isMounted = true;
        this.axiosCancelSource = axios.CancelToken.source();
        const search = this.props.location ? this.props.location.search : ""

        let { urlParams, sorting, filters } = this.state;

        const currentUrlParams = qs.parse(search, { ignoreQueryPrefix: true });
        if (currentUrlParams.filters) {
            filters = Object.keys(currentUrlParams.filters).map(item => {
                return { columnName: item, value: currentUrlParams.filters[item] }
            })
        }
        if (this.props.defaultSorting) {
            sorting = Object.keys(this.props.defaultSorting).map(item => {
                return { columnName: item, direction: this.props.defaultSorting[item] }
            })
        }
        if (currentUrlParams.sorts) {
            sorting = Object.keys(currentUrlParams.sorts).map(item => {
                return { columnName: item, direction: currentUrlParams.sorts[item] }
            })
        }

        urlParams = Object.assign(urlParams, currentUrlParams);
        const self = this;
        let newState = {};
        newState.urlParams = urlParams;
        newState.filters = filters;
        newState.sorting = sorting;
        if (urlParams.currentPage) {
            newState.currentPage = urlParams.currentPage;
        }
        if (urlParams.maxPerPage) {
            newState.maxPerPage = urlParams.maxPerPage ? urlParams.maxPerPage : 10;
        }
        newState.loading = false;
        newState.initLoading = true;
        this.setState(newState, () => {
            self.loadData(true);
        })

        // this.setState({
        //     urlParams: urlParams,
        //     filters: filters,
        //     sorting: sorting,
        //     currentPage: urlParams.currentPage ? urlParams.currentPage : 1,
        //     loading: false,
        //     initLoading: true,
        //     maxPerPage: urlParams.maxPerPage ? urlParams.maxPerPage : 10,
        // }, () => {
        //     self.loadData(true);
        // })
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.axiosCancelSource.cancel('Component Unmounted.')
    }

    getData(url) {
        let { currentPage, pageSize, nbPages, totalCount, isApiPlatform } = this.state;
        let self = this;
        if (this.currentUrl !== url || this.state.forceUpdate) {
            this.setState({
                rows: [],
                additionalRowsFromParent: []
            }, function () {
                // if (this.state.searchInProcess && typeof this.props.endSearch === "function") {
                //     this.props.endSearch();
                // }
            });
            this.currentUrl = url;
            this.calledApi = true;
            axios.get(url, { cancelToken: self.axiosCancelSource.token })
                .then(response => {
                    this.calledApi = false;
                    if (response.status === 200) {
                        if (self._isMounted) {
                            const data = response.data;
                            let rows = [];
                            if (data.hasOwnProperty('items') && data.items) {
                                rows = data.items
                            } else if (data.hasOwnProperty("hydra:member") && data["hydra:member"]) {
                                rows = data["hydra:member"];
                                isApiPlatform = true;
                            }
                            const newItem = this.state.newItem;
                            const oldNewItem = this.state.oldNewItem;
                            const newItemArray = this.state.newItemArray;
                            const oldNewItemArray = this.state.oldNewItemArray;
                            const aggregations = data.aggregations;
                            const { defaultRows } = this.state
                            if (defaultRows && defaultRows.length > 0) {
                                defaultRows.forEach(item => rows.unshift(item))
                            }
                            if (newItem && (!oldNewItem || newItem !== oldNewItem)) {
                                const newItemIndex = rows.findIndex((item) => item.id === newItem.id);
                                if (newItemIndex === -1) {
                                    rows.unshift(newItem);
                                }
                                else {
                                    rows[newItemIndex] = newItem;
                                }
                            }
                            if (newItemArray && !oldNewItemArray) {
                                for (let i = 0; i < newItemArray.length; i++) {
                                    const newItemIndexArray = rows.findIndex((item) => item.id === newItemArray[i].id);
                                    if (newItemIndexArray === -1) {
                                        rows.unshift(newItemArray[i])
                                    }
                                    else rows[newItemIndexArray] = newItemArray[i]
                                }
                            }
                            else if (newItemArray && oldNewItemArray) {
                                let check = []
                                for (let i = 0; i < newItemArray.length; i++) {
                                    for (let j = 0; j < oldNewItemArray.length; j++) {
                                        if (newItemArray[i] === oldNewItemArray[j]) {
                                            check.push(newItemArray[i])
                                        }
                                    }
                                }
                                if (check.length === 0) {
                                    for (let i = 0; i < newItemArray.length; i++) {
                                        const newItemIndexArray = rows.findIndex((item) => item.id === newItemArray[i].id);
                                        if (newItemIndexArray === -1) {
                                            rows.unshift(newItemArray[i])
                                        }
                                        else rows[newItemIndexArray] = newItemArray[i]
                                    }
                                }
                            }
                            if (this.state.addItemRow) {
                                rows.unshift({ newRowData: true });
                            }
                            if (typeof this.state.addAdditionalRowsCallback === "function") {
                                rows = this.state.addAdditionalRowsCallback(rows);
                            }

                            if (this.props.dataPropsOfPermissionPersonal) {
                                // Done
                                // const addArrayDone = this.props.dataPropsOfPermissionPersonal.addArrayDone;
                                // const removeArrayDone = this.props.dataPropsOfPermissionPersonal.removeArrayDone;
                                // if (addArrayDone.length) {
                                //     let addArraysDoneId = [];
                                //     addArrayDone.map((item, index) => {
                                //         addArraysDoneId.push(item.id);
                                //     });
                                //     rows = rows.filter((item) => !removeArrayDone.includes(item.id));
                                //     for(let i=0; i < addArraysDoneId.length; i++){
                                //         let indexAddArraysDone = rows.findIndex((item)=>item.id === addArrayDone[i].id);
                                //         if (indexAddArraysDone === -1) {
                                //             let itemAddDone = addArrayDone[i];
                                //             delete itemAddDone.checkAdd;
                                //             rows.unshift(itemAddDone);
                                //         } else {
                                //             let itemRowUpdate = rows[indexAddArraysDone];
                                //             delete itemRowUpdate.checkAdd;
                                //             rows[indexAddArraysDone] = itemRowUpdate;
                                //         }
                                //     }
                                // }

                                // Not Yet Done
                                const addArray = this.props.dataPropsOfPermissionPersonal.addArray;
                                const removeArray = this.props.dataPropsOfPermissionPersonal.removeArray;
                                if (addArray.length) {
                                    let addArrayId = [];
                                    addArray.forEach((item, index) => {
                                        addArrayId.push(item.id);
                                    });
                                    rows = rows.filter((item) => ((!item.checkAdd && !removeArray.includes(item.id)) || (item.checkAdd && addArrayId.includes(item.id))));
                                    for (let i = 0; i < addArray.length; i++) {
                                        if (rows.findIndex((item) => item.id === addArray[i].id) === -1) {
                                            rows.unshift(addArray[i])
                                        }
                                    }
                                } else {
                                    rows = rows.filter((item) => !item.checkAdd && !removeArray.includes(item.id));
                                }
                            }
                            let totalCountList = totalCount;
                            let currentPageList = currentPage;
                            let pageSizeList = pageSize;
                            let nbPagesList = nbPages;
                            if (!isApiPlatform) {
                                totalCountList = data.nbResults;
                                currentPageList = data.currentPage;
                                pageSizeList = data.maxPerPage;
                                nbPagesList = data.nbPages;
                            } else {
                                totalCountList = data.hasOwnProperty("hydra:totalItems") && data["hydra:totalItems"] ? data["hydra:totalItems"] : 0;
                                if (data.hasOwnProperty("hydra:view") && data['hydra:view']) {
                                    const hydraView = data['hydra:view'];
                                    const paramsCurrent = getParamInUrl(hydraView["@id"]);
                                    currentPageList = paramsCurrent.hasOwnProperty("page") ? paramsCurrent.page : currentPageList;
                                    pageSizeList = paramsCurrent.hasOwnProperty("maxPerPage") ? paramsCurrent.maxPerPage : pageSizeList;
                                    const paramsPageLast = getParamInUrl(hydraView["hydra:last"]);
                                    nbPagesList = paramsPageLast.hasOwnProperty("page") ? paramsPageLast.page : nbPagesList;
                                }
                            }
                            self.setState({
                                rows: rows.map((item, index) => ({
                                    ...item,
                                    indexRow: index
                                })),
                                rowsApi:rows.map((item, index) => ({
                                    ...item,
                                    indexRow: index
                                })),
                                currentPage: currentPageList,
                                pageSize: pageSizeList,
                                nbPages: nbPagesList,
                                totalCount: totalCountList,
                                loading: false,
                                deleteHand: false,
                                timeout: null,
                                forceUpdate: false,
                                newItem: null,
                                oldNewItem: newItem,
                                newItemArray: [],
                                oldNewItemArray: newItemArray,
                                aggregations: aggregations,
                                isFiltering: false,
                                initLoading: false,
                                isApiPlatform: isApiPlatform
                            }, function () {
                                self.saveTableStateParameters();
                                if (self.props.callbackData) {
                                    self.props.callbackData(rows)
                                }
                                if (self.props.callbackAggregations) {
                                    self.props.callbackAggregations(aggregations)
                                }
                            });
                        }
                    }
                    else {
                        throw new Error();
                    }
                })
                .catch(error => {
                    this.calledApi = false;
                    if (self._isMounted) {
                        self.setState({
                            currentPage: 1,
                            loading: false,
                            forceUpdate: false,
                            initLoading: false
                        })
                    }
                });
        }
    }

    saveTableStateParameters() {
        let pageInfos = localStorage.getItem(this.paramsName);
        if (!pageInfos) {
            pageInfos = {};
        }
        else {
            pageInfos = JSON.parse(pageInfos)
        }
        const { hiddenColumns, columnOrders, columnWidths } = this.state;
        for (let i = 0; i < hiddenColumns.length; i++) {
            if (!columnOrders.includes(hiddenColumns[i])) {
                columnOrders.push(hiddenColumns[i]);
            }
        }
        pageInfos.pageSize = this.state.pageSize;
        pageInfos.sorting = this.state.sorting;
        pageInfos.columnOrders = columnOrders;
        pageInfos.columnWidths = columnWidths;
        if (this.props.callbackWidth && pageInfos.columnWidths) {
            this.props.callbackWidth(pageInfos.columnWidths)
        }
        localStorage.setItem(this.paramsName, JSON.stringify(pageInfos));
    }

    sortData(sortColumns) {
        let urlSorts = {};
        sortColumns.forEach(item => {
            if (item.direction) {
                urlSorts = { [item.columnName]: item.direction }
            }

        })
        let { urlParams } = this.state;
        urlParams.sorts = urlSorts;
        const self = this;

        this.setState({
            loading: true,
            currentPage: 1,
            sorting: sortColumns,
            urlParams: urlParams,
            isFiltering: true
        }, () => {
            const path = self.props.urlParamId ? self.props.match.path.replace(":id", self.props.urlParamId) : self.props.match.path;
            self.props.history.push(path + '?' + qs.stringify(urlParams));
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (state.forceUpdate && !state.loading) {
            return {
                forceUpdate: false,
            }
        }
        else if (props.exportExcelRequest) {
            return {
                exportExcelRequest: true,
                exportExcelRequestAll: props.hasOwnProperty("exportExcelRequestAll") ? props.exportExcelRequestAll : state.exportExcelRequestAll
            }
        }
        else if ((props.searchValue !== state.search || props.searchUrl === window.location.pathname) && props.searchInProcess) {
            return {
                search: props.searchValue,
                loading: true,
                searchInProcess: true,
                currentPage: 1
            }
        }
        else if (props.searchInProcess !== state.searchInProcess) {
            return {
                loading: props.searchInProcess || state.loading,
                searchInProcess: props.searchInProcess,
            }
        }
        else if (props.componentState !== state.componentState || !!(props.forceUpdate && props.forceUpdate !== state.forceUpdate) || !!(props.loading && props.loading !== state.loading)) {
            if (state.expandedRowIds.length > 0) {
                state.expandedRowIds = []
            }
            if (props.componentState.columns) {
                props.componentState.columns.forEach((columnProps, idx, table) => {
                    if (!columnProps.name) {
                        columnProps.name = `col_${idx}`;
                        state.columns[idx] = columnProps;
                    }
                    if (!state.defaultColumnWidths) {
                        state.defaultColumnWidths = [];
                    }
                    if (state.defaultColumnWidths.findIndex((columnWidthProps) => columnWidthProps.columnName === columnProps.name) === -1) {
                        state.defaultColumnWidths.push({ "columnName": columnProps.name, "width": "auto" });
                    }
                    if (state.columnWidths.findIndex((columnWidthProps) => columnWidthProps.columnName === columnProps.name) === -1) {
                        state.columnWidths.push({ "columnName": columnProps.name, "width": 40 });
                    }
                    else if (state.columnWidths.findIndex((columnWidthProps) => columnWidthProps.columnName === columnProps.name) > -1 && !state.columnWidths[state.columnWidths.findIndex((columnWidthProps) => columnWidthProps.columnName === columnProps.name)].width) {
                        state.columnWidths[state.columnWidths.findIndex((columnWidthProps) => columnWidthProps.columnName === columnProps.name)] = {
                            ...state.columnWidths[state.columnWidths.findIndex((columnWidthProps) => columnWidthProps.columnName === columnProps.name)],
                            width: 40
                        };
                    }
                });
            }
            let newState = Object.assign(state, props.componentState);
            if ((props.componentState && props.componentState.apiNameList !== state.apiNameList && !props.dataList) || props.reset) {
                newState = {
                    ...newState,
                    rows: [],
                    currentPage: 1,
                    totalCount: 0,
                    totalPage: 1,
                }
            }
            if (props.forceUpdate && props.componentState.newItem) {
                newState.newItem = props.componentState.newItem;
            }
            if (props.forceUpdate && props.componentState.newItemArray) {
                newState.newItemArray = props.componentState.newItemArray;
            }
            if (props.forceUpdate && props.componentState.newItemArrays) {
                newState.newItemArrays = props.componentState.newItemArrays;
            }
            return {
                ...newState,
                componentState: props.componentState,
                forceUpdate: !!props.forceUpdate,
                loading: !!props.forceUpdate || !!props.loading || !!state.loading,
            };
        }
        return null;
    }

    generateApiUrl(init, prevState) {
        const { currentPage, loading, nbPages, search, deleteHand, /*apiNameList,*/ forceUpdate, pageSize, sorting, filters } = this.state;
        let apiParams = { ...this.state.apiParams, maxPerPage: pageSize };
        apiParams["sorts"] = apiParams["sorts"] ? apiParams["sorts"] : {};
        apiParams["filters"] = apiParams["filters"] ? apiParams["filters"] : {};
        for (let i = 0; i < filters.length; i++) {
            let filter = filters[i];
            if (filter["value"] !== '') {
                apiParams["filters"][filter["columnName"]] = filter["value"];
            }
        }
        for (let i = 0; i < sorting.length; i++) {
            let sortColumn = sorting[i];
            apiParams["sorts"][sortColumn["columnName"]] = sortColumn.direction && sortColumn.direction.toLowerCase() === "desc" ? "desc" : "asc";
        }
        const { searchUrl } = this.props;

        if (search && searchUrl === window.location.pathname) {
            apiParams = Object.assign(apiParams, { search: search });
        }
        if (init || deleteHand || (forceUpdate && prevState && !prevState.forceUpdate)) {
            // apiParams = {...apiParams, page: currentPage}
            apiParams = Object.assign(apiParams, currentPage > 1 ? { page: currentPage } : {});

            // url = Routing.generate(apiNameList, apiParams);
        }
        else if (loading) {
            if (currentPage > nbPages) {
                apiParams = {};
                // url = Routing.generate(apiNameList,{});
            }
            else {
                apiParams = Object.assign(apiParams, currentPage > 1 ? { page: currentPage } : {});
                // url = Routing.generate(apiNameList,apiParams);
            }
        }
        return  this.state.apiNameList + "?" + qs.stringify(apiParams);
    }

    loadData(init, prevState) {
        const url = this.generateApiUrl(init, prevState);
        if (url && !this.props.dataList) {
            this.getData(url);
        }
        if (this.props.dataList) {
            this.setState({
                rows: this.props.dataList,
                forceUpdate: false,
                initLoading: false,
                loading: false
            }, () => {
                this.saveTableStateParameters()
            })
        }
    }


    componentDidUpdate(prevProps, prevState, snapShot) {
        // const newState = {}
        const search = this.props.location ? this.props.location.search : "";
        // const self = this;
        let { urlParams, isFiltering, sorting, forceUpdate } = this.state;
        const currentUrlParams = qs.parse(search, { ignoreQueryPrefix: true });
        if (forceUpdate) {
            this.loadData(true, prevState);
        }
        if (!_.isEqual(this.props.dataList, prevProps.dataList)) {
            this.setState({
                rows: this.props.dataList,
            })
        }
        // console.log('!_.isEqual(urlParams, currentUrlParams)', !_.isEqual(urlParams, currentUrlParams))
        // console.log('isFiltering', isFiltering)
        if (!_.isEqual(urlParams, currentUrlParams) && isFiltering) {
            let filters = [];
            // let sorting = [];
            if (currentUrlParams.filters) {
                filters = Object.keys(currentUrlParams.filters).map(item => {
                    return { columnName: item, value: currentUrlParams.filters[item] }
                })
            }

            if (currentUrlParams.sorts) {
                sorting = Object.keys(currentUrlParams.sorts).map(item => {
                    return { columnName: item, direction: currentUrlParams.sorts[item] }
                })
            }
            this.setState({
                filters: filters,
                sorting: sorting,
                urlParams: currentUrlParams,
                loading: true,
                currentPage: currentUrlParams.currentPage ? currentUrlParams.currentPage : 1,
                maxPerPage: urlParams.maxPerPage ? urlParams.maxPerPage : 10,
            }, function () {
                this.loadData(true, prevState);
            });
        }
        if (this.props.forceUpdateListChangeApiParams) {
            const forceUpdateListChangeApiParamsData = {...this.props.forceUpdateListChangeApiParams};
            if(forceUpdateListChangeApiParamsData.hasOwnProperty(REMOVE_PARAMS_FILTERS_LIST) && Array.isArray(forceUpdateListChangeApiParamsData[REMOVE_PARAMS_FILTERS_LIST])){
                const filtersNew = this.state.filters.filter(itemFil=> !forceUpdateListChangeApiParamsData[REMOVE_PARAMS_FILTERS_LIST].includes(itemFil.columnName));
                this.setState({
                    loading: true,
                    currentPage: 1,
                    filters: filtersNew,
                    isFiltering: true
                })
            }else if(forceUpdateListChangeApiParamsData.hasOwnProperty(ADD_PARAMS_FILTERS_LIST) && typeof forceUpdateListChangeApiParamsData[ADD_PARAMS_FILTERS_LIST] === "object"){
                const filtersNew = this.state.filters;
                let dataFileNew = [];
                Object.keys(forceUpdateListChangeApiParamsData[ADD_PARAMS_FILTERS_LIST]).forEach(itemAddNewFil=>{
                    const columnExistIndex = filtersNew.findIndex(itemFil=> itemFil.columnName === itemAddNewFil);
                    const valueFilter = forceUpdateListChangeApiParamsData[ADD_PARAMS_FILTERS_LIST][itemAddNewFil];
                    if(columnExistIndex !== -1){
                        filtersNew[columnExistIndex] = valueFilter
                    }else{
                        dataFileNew.push(valueFilter)
                    }
                })
                this.setState({
                    loading: true,
                    currentPage: 1,
                    filters: filtersNew.concat(dataFileNew),
                    isFiltering: true
                })
            } else if (forceUpdateListChangeApiParamsData) {
                this.setState({
                    loading: true,
                    currentPage: 1,
                    isFiltering: true
                })
            }
            this.props.setForceUpdateListChangeApiParamsRedux(null)
        }
        if (this.state.changeWidthColumns && !this.hasSaveColumnWidths && this.tableBlockRef.current) {
            this.hasSaveColumnWidths = true;
            let tableWidth = this.tableBlockRef.current.offsetWidth;

            this.hasSaveColumnWidths = true;
            let columnSetWidthsChange =[]
            let columnWidthsNew = [];
            // if(this.props.rowDetail){
            //     tableWidth = tableWidth - 66
            // }
            this.state.columnWidths.forEach(item => {
                if (item.width === 40) {
                    columnSetWidthsChange.push(item)
                } else if (parseFloat(item.width)) {
                    tableWidth = tableWidth - parseFloat(item.width)
                    columnWidthsNew.push(item)
                }
            })
            if (tableWidth){
                if(columnSetWidthsChange.length){
                    columnSetWidthsChange.forEach(itemChange=>{
                        const widthNew = tableWidth/columnSetWidthsChange.length;
                        itemChange.width = widthNew>0 ?widthNew:-widthNew;
                        columnWidthsNew.push(itemChange)
                    })
                }
                this.onColumnWidthsChange(columnWidthsNew);
            }
        }
        if (this.state.exportExcelRequest) {
            this.setState({
                exportExcelRequest: false,
                exportExcelInProcess: true
            });
            if (!this.state.exportExcelRequestAll) {
                this.exportExcelCurrentPage();
            }
            else {
                this.exportExcelAll();
            }
        }
        if (this.props.addNewRow) {
            const dataRows = this.state.rows;
            const found = dataRows.find(item => item.newRowData);
            if (!found) {
                dataRows.unshift({ newRowData: true });
                this.setState({
                    rows: dataRows
                });
            }
        }
        if (this.props.addItemRow === true) {
            let dataRows = this.state.rows;
            if (dataRows.length === 0 || (dataRows.length > 0 && !dataRows[0].newRowData)) {
                dataRows.unshift({ newRowData: true });
                dataRows = dataRows.filter((item, index) => {
                    return (!(index && item.newRowData));
                });
                this.setState({
                    rows: dataRows,
                    addItemRow: this.props.addItemRow
                });
            }
        }
        if (this.props.addItemRow === false) {
            const dataRows = this.state.rows;
            if (dataRows.length > 0 && dataRows[0].newRowData === true) {
                dataRows.shift();
                this.setState({
                    rows: dataRows,
                    addItemRow: this.props.addItemRow
                });
            }
        }

        if (this.props.deleteNewRow) {
            let rows = this.state.rows;
            const newRowData = rows.find(item => item.newRowData === true);
            if (newRowData) {
                rows = rows.filter(row => row.newRowData !== true);
                this.setState({
                    rows: rows
                });
            }
        }
        if (this.props.editRow) {
            let rows = this.state.rows;
            rows = rows.map(item => {
                return this.props.editRow === item.id ? { ...item, editRowData: true } : { ...item, editRowData: false }
            })
            this.setState({
                rows: rows
            })
        }
        if (this.props.cancelEdit) {
            let rows = this.state.rows;
            rows = rows.map(item => {
                return item.editRowData ? { ...item, editRowData: false } : { ...item }
            })
            this.setState({
                rows: rows
            })
        }
        if (this.props.addNewItemRow) {
            let { newItemArrays } = this.state;
            let rows = this.state.rows;
            const found = rows.find(item => item.newRowData)
            if (found && Array.isArray(newItemArrays) && newItemArrays.length) {
                for (let i = 0; i < newItemArrays.length; i++) {
                    if (rows.findIndex((item) => item.id === newItemArrays[i].id) === -1) {
                        rows.unshift(newItemArrays[i])
                    }
                }
                this.setState({
                    rows: rows,
                })
            }
        }
    }

    onColumnWidthsChange(nextColumnWidths) {
        const self = this;
        nextColumnWidths = nextColumnWidths.map((columnWidth) => {
            const width = parseFloat(columnWidth.width);
            if (isNaN(width)) {
                columnWidth.width = 40;
            }
            return columnWidth;
        });
        this.setState({
            columnWidths: nextColumnWidths
        }, function () {
            self.saveTableStateParameters();

        });
    }

    gridRoot(props) {
        // const classes = useStyles();
        return <Grid.Root className="rootGrid" {...props} />;
    }


    tableContainer(props) {
        // const classes = useStyles();
        return <Table.Container className="tableContainer" {...props} />;
    };

    render() {
        const { className, classes, t, expandedListIds } = this.props;
        const {
            rows,
            currentPage,
            pageSize,
            routeNameNew,
            titleList,
            totalCount,
            sorting,
            sortingStateColumnExtensions,
            iconList,
            hasChangeStyle,
            iconNew,
            classList,
            routeNameList,
            hiddenColumns,
            changeWidthColumns,
            defaultColumnWidths,
            columnWidths,
            useScollable,
            tableHeightScollable,
            hiddenPaginate,
            hiddenPagingState,
            exportExcelRequestAll,
            exportExcelInProcess,
            additionalRowsFromParent,
            initLoading,
        } = this.state;
        // if (Array.isArray(expandedListIds)) {
        //     console.log(rows.map((item, index) => {
        //         if (expandedListIds.includes(item.id)) {
        //             return index;
        //         }
        //     }).filter(item2 => item2 !== undefined))
        // }
        const listRows = [...rows];
        if (additionalRowsFromParent && additionalRowsFromParent.length) {
            additionalRowsFromParent.map((item) => listRows.push(item));
        }
        let columns = this.state.columns.map((column) => {
            return {
                ...column,
                title: this.props.t(column.title)
            }
        });
        columns = columns.filter((column) => !column.name || !hiddenColumns.includes(column.name));
        let tableMessages = {};
        Object.keys(this.state.tableMessages).forEach((item) => {
            tableMessages[item] = this.props.t(this.state.tableMessages[item]);
        });
        if ((this.state.loading || initLoading)) {
            tableMessages.noData = <CircularProgress />;
        }
        let tablePagingMessages = {};
        Object.keys(this.state.tablePagingMessages).forEach((item) => {
            tablePagingMessages[item] = this.props.t(this.state.tablePagingMessages[item]);
        });
        if (!pageSizes.includes(parseInt(pageSize))) {
            pageSizes.push(parseInt(pageSize));
        }
        pageSizes.sort(function (a, b) {
            return a - b;
        });
        return (
            <React.Fragment>
                {!this.props.gridList ?
                    <div className={`listWrapper ${className ? " " + className : ""}`}>
                        {exportExcelInProcess && exportExcelRequestAll && <LoadingAction />}
                        <Paper>
                            <ListHeader
                                routeNameNew={routeNameNew}
                                titleList={titleList}
                                iconList={iconList}
                                hasChangeStyle={hasChangeStyle}
                                iconNew={iconNew}
                                routeNameList={routeNameList}
                                t={this.props.t}
                                rightHeaderComponent={this.state.rightHeaderComponent}
                                leftHeaderComponent={this.state.leftHeaderComponent}
                            />
                            <div className={classList} ref={this.tableBlockRef}>
                                <Grid
                                    rows={listRows}
                                    columns={columns}
                                    rootComponent={this.gridRoot}
                                >
                                    {/*<DragDropProvider />*/}
                                    <PagingState
                                        currentPage={currentPage - 1}
                                        onCurrentPageChange={(setCurrentPage) => this.onCurrentPageChange(setCurrentPage)}
                                        pageSize={pageSize}
                                        onPageSizeChange={(setPageSize) => this.onPageSizeChange(setPageSize)}
                                    />
                                    <CustomPaging
                                        totalCount={totalCount}
                                    />
                                    <SortingState
                                        sorting={sorting}
                                        onSortingChange={this.sortData}
                                        columnExtensions={sortingStateColumnExtensions}
                                    />
                                    <GroupingState
                                        grouping={this.state.groupingColumns}
                                    />
                                    <IntegratedGrouping />
                                    <TreeDataState />
                                    {this.props.getTreeDataChildRows && <CustomTreeData
                                        getChildRows={this.props.getTreeDataChildRows}
                                    />}
                                    <FilteringState
                                        // defaultFilters={this.state.defaultFilters}
                                        filters={this.state.filters}
                                        onFiltersChange={this.changeFilters}
                                        columnExtensions={this.state.filterColumnsEnabled}
                                    />
                                    <IntegratedFiltering
                                        columnExtensions={columns.map((item) => {
                                            return {
                                                columnName: item.name, predicate: (value, filter, row) => {
                                                    return true;
                                                }
                                            };
                                        })}
                                    />
                                    {/*filter and search*/}
                                    {/*<IntegratedSorting columnExtensions={sortingColumnStates}/>*/}
                                    <EditingState onCommitChanges={this.commitChanges} />
                                    {useScollable ?
                                        !this.props.customNoResult ?
                                            <VirtualTable
                                                {...this.state.tableProps}
                                                messages={tableMessages}
                                                tableComponent={TableComponent}
                                                headComponent={HeadComponent}
                                                height={tableHeightScollable}
                                                containerComponent={this.tableContainer}
                                                columnExtensions={!changeWidthColumns && defaultColumnWidths ? defaultColumnWidths : undefined}
                                            /> :
                                            <VirtualTable
                                                {...this.state.tableProps}
                                                noDataRowComponent={() => this.props.customNoResult(this.state.initLoading || this.state.loading)}
                                                tableComponent={TableComponent}
                                                headComponent={HeadComponent}
                                                height={tableHeightScollable}
                                                containerComponent={this.tableContainer}
                                                columnExtensions={!changeWidthColumns && defaultColumnWidths ? defaultColumnWidths : undefined}
                                            />
                                        : !this.props.customNoResult ?
                                            <Table
                                                {...this.state.tableProps}
                                                messages={tableMessages}
                                                tableComponent={TableComponent}
                                                containerComponent={this.tableContainer}
                                                columnExtensions={!changeWidthColumns && defaultColumnWidths ? defaultColumnWidths : undefined}
                                            /> :
                                            <Table
                                                {...this.state.tableProps}
                                                noDataRowComponent={() => this.props.customNoResult(this.state.initLoading || this.state.loading)}
                                                // messages={tableMessages}
                                                tableComponent={TableComponent}
                                                containerComponent={this.tableContainer}
                                                columnExtensions={!changeWidthColumns && defaultColumnWidths ? defaultColumnWidths : undefined}
                                            />
                                    }
                                    {/*<TableColumnReordering*/}
                                    {/*    order={columnOrders}*/}
                                    {/*    onOrderChange={this.onColumnOrdersChange}*/}
                                    {/*/>*/}
                                    {changeWidthColumns && <TableColumnResizing
                                        columnWidths={columnWidths ? columnWidths : undefined}
                                        defaultColumnWidths={defaultColumnWidths}
                                        onColumnWidthsChange={this.onColumnWidthsChange}
                                    />
                                    }
                                    {!this.props.hiddenHeaderRow && <TableHeaderRow
                                        cellComponent={CellHeaderComponent}
                                        {...this.state.CellHeaderProps}
                                        showSortingControls
                                        messages={{ sortingHint: this.props.t("label.sort") }}
                                    />}
                                    {this.props.expandedRowIds ? <RowDetailState
                                        expandedRowIds={Array.isArray(expandedListIds) ? expandedListIds : this.state.expandedRowIds}
                                        onExpandedRowIdsChange={this.setExpandedRowIds}
                                    /> : <RowDetailState />}
                                    {this.props.rowDetail && <TableRowDetail rowComponent={this.props.rowDetail}
                                        toggleCellComponent={this.props.toggleCellComponent ? this.props.toggleCellComponent : ToggleCellComponent}
                                    />}
                                    {this.props.treeDataColumn && <TableTreeColumn
                                        for={this.props.treeDataColumn}
                                    />}
                                    <TableFilterRow
                                        cellComponent={FilterCell}
                                    />
                                    <TableEditRow />
                                    {/*<Toolbar/>*/}
                                    {/*<SearchPanel/>*/}
                                    {!hiddenPaginate &&
                                        <PagingPanel
                                            containerComponent={this.props.customPaginate ? PagingPanelComponentCustom : PagingPanelComponent}
                                            messages={tablePagingMessages}
                                            pageSizes={pageSizes}
                                            hiddenPagingState={hiddenPagingState}
                                        />}
                                </Grid>
                            </div>
                        </Paper>
                    </div>
                    :
                    <React.Fragment>
                        <MuiGrid className={classes.tablePaginationBlock}>
                            <MuiGrid container className={'gridListWrapper'} spacing={3}>
                                {rows.length !== 0 && rows.map(item => {
                                    return this.props.gridComponent(item) ?? null
                                })}
                                {(this.state.loading || initLoading) && <CircularProgress />}
                                {!this.state.loading && !initLoading && rows.length === 0 && <div className={"noResult"}>{this.props.noResultText ? this.props.noResultText : t("list.noResult")}</div>}
                            </MuiGrid>
                            {totalCount > 0 && <MuiGrid className={'paginateCustom'} container>
                                {/*{pageSizes && pageSizes.length && <MuiGrid container item xs={3} justify={"left"} className="selectPageSize">*/}
                                <span className={'perPage'}>{this.props.t('list.resultPerPage')}</span>
                                <AppFreeSoloAutocomplete
                                    fullWidth={false}
                                    options={pageSizes ? pageSizes.map((item) => item.toString()) : []}
                                    disableClearable
                                    value={pageSize}
                                    style={{
                                        height: "auto",
                                        background: "rgb(225, 235, 247)",
                                        borderRadius: 10
                                    }}
                                    onChange={(e, value) => {
                                        this.onPageSizeChange(value)
                                        // console.log(e, value)
                                    }
                                    }
                                    renderOption={undefined}
                                    filterOptions={undefined}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                                value: this.state.pageSizeChange !== null ? this.state.pageSizeChange : pageSize,
                                                // type: 'number'

                                            }}
                                            variant={"outlined"}
                                            onChange={(e) => this.onPageSizeChangeCustom(e, e.target.value)}
                                            onKeyDown={(e) => this.keydownCustom(e)}
                                        />
                                    )
                                    }
                                />
                                {/*</MuiGrid>*/}
                                {/*}*/}
                                {/*<MuiGrid justify={"center"} item container xs={6} className="pagingInfos" spacing={1} alignItems={"center"}>*/}
                                <MuiGrid item className={classes.paginationResultInfos}>
                                    {currentPage - 1 < Math.ceil(totalCount / pageSize) ? (currentPage - 1) * pageSize + 1 : (Math.ceil(totalCount / pageSize) - 1) * pageSize + 1}-{currentPage < totalCount / pageSize ? currentPage * pageSize : totalCount} {t('list.of')} {totalCount}
                                </MuiGrid>
                                {totalCount / pageSize > 1 &&
                                    <React.Fragment>
                                        <MuiGrid item className={"goToPageWrapper"}>
                                            <span className={'goToPage'}>{t('list.goToPage')}</span>
                                            {currentPage > 1 && <MuiGrid item>
                                                <Button className={classes.paginationPageNum + " paginationPageNum"}
                                                    onClick={() => this.onCurrentPageChange(currentPage - 2)}
                                                >
                                                    {currentPage - 1}
                                                </Button>
                                            </MuiGrid>}
                                            <MuiGrid item><Button className={classes.paginationPageNum + " paginationPageNum active"}>{currentPage}</Button></MuiGrid>
                                            {currentPage < totalCount / pageSize && <MuiGrid item>
                                                <Button className={classes.paginationPageNum + " paginationPageNum"}
                                                    onClick={() => this.onCurrentPageChange(currentPage)}
                                                >
                                                    {currentPage + 1}
                                                </Button>
                                            </MuiGrid>}
                                        </MuiGrid>
                                        <MuiGrid item className={"buttonWrapper"}>
                                            {/*{currentPage>1 && <MuiGrid item>*/}
                                            <MuiGrid item>
                                                <Button
                                                    className={classes.paginationPageNum + " paginationPageNum prevBtn"}
                                                    onClick={() => this.onCurrentPageChange(0)}
                                                    disabled={currentPage <= 1}
                                                >
                                                    <CustomInlineSvg
                                                        svgProps={{ src: endIcon }}
                                                    />
                                                </Button>
                                            </MuiGrid>
                                            <MuiGrid item>
                                                <Button
                                                    className={classes.paginationPageNum + " paginationPageNum prevBtn"}
                                                    onClick={() => this.onCurrentPageChange(currentPage - 2)}
                                                    disabled={currentPage <= 1}
                                                >
                                                    <CustomInlineSvg
                                                        svgProps={{ src: nextIcon }}
                                                    />
                                                </Button>
                                            </MuiGrid>
                                            <MuiGrid item>
                                                <Button className={classes.paginationPageNum + " paginationPageNum nextBtn"}
                                                    onClick={() => this.onCurrentPageChange(currentPage)}
                                                    disabled={currentPage > totalCount / pageSize}
                                                >
                                                    <CustomInlineSvg
                                                        svgProps={{ src: nextIcon }}
                                                    />
                                                    {/*{">"}*/}
                                                </Button>
                                            </MuiGrid>
                                            <MuiGrid item>
                                                <Button className={classes.paginationPageNum + " paginationPageNum nextBtn"}
                                                    onClick={() => this.onCurrentPageChange(Math.ceil(totalCount / pageSize) - 1)}
                                                    disabled={currentPage > totalCount / pageSize}
                                                >
                                                    <CustomInlineSvg
                                                        svgProps={{ src: endIcon }}
                                                    />
                                                    {/*{">"}*/}
                                                </Button>
                                            </MuiGrid>
                                        </MuiGrid>
                                    </React.Fragment>
                                }
                                {/*</MuiGrid>*/}
                            </MuiGrid>
                            }
                        </MuiGrid>
                    </React.Fragment>
                }
            </React.Fragment>

        );
    }
}

List.propTypes = {
    t: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => {
    return {
        forceUpdateListChangeApiParams: state.applicationReducer?state.applicationReducer.forceUpdateListChangeApiParams:false
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setForceUpdateListChangeApiParamsRedux: (value) => dispatch(applicationAction.setForceUpdateListChangeApiParamsRedux(value))
    }
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation(), withStyles(styles))(List);
