import React, { Component } from 'react';
import { withStyles } from "@material-ui/core";
import GeneralModal from "../../../../theme/Modal/GeneralModal";
// import UploadFile, { SubmitUploadButton, TitleUpload } from "../../../../theme/Modal/UploadFile";
import { connect } from "react-redux";
import { getDocumentRequestValid, setProblemDescribe } from "../../../../_actions/application";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import * as application from "../../../../_actions/application";
import LoadingAction from "../../../../theme/LoadingAction";
import axios from "axios"
import { CREATE_REQUEST } from "../../../../constants/apiSuffix";
import { ACTION_ADD, ACTION_DELETE, BASE_URL, LG, MD, SM, XL, XS } from "../../../../constants/constants";
import CustomButton from "../../../../theme/CustomElements/CustomButton";
import returnIcon from "../../../../images/navbar/Groupe 8620.svg";
import { NUNITO } from "../../../../constants/fontCss";
import * as applicationAction from "../../../../_actions/application";
import SuccessAlert from "../../../../theme/form/Alert/SuccessAlert";
import circleIcon from "../../../../images/banner/circle.svg"
import alarmIcon from "../../../../images/banner/alarm.svg"
import CustomInlineSvg from "../../../../theme/CustomElements/CustomInlineSvg";
import { NavLink } from "react-router-dom";
import * as links from "../../../../constants/links";
import { withRouter } from "react-router";
import reportIcon from "../../../../images/request/toolbox.svg";
// import stopBigIcon from "../../../../images/request/requestAdmin/stopActive.svg";
import RequestNew from "../RequestNew";
import AlertCountChatNotSeen from '../AdminHeaderAlert/AlertCountChatNotSeen';
import AlertCountDocumentManagerNotSeen from "../AdminHeaderAlert/AlertCountDocumentManagerNotSeen";

const styles = theme => ({
    returnRequestList: {
        borderRadius: 24,
        border: 'solid 1px #ffffff',
        color: '#fff',
        textDecoration: 'none',
        padding: '0.5rem 1rem',
        marginLeft: '3rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        maxWidth: 150,
        [theme.breakpoints.between(XS, SM)]: {
            marginLeft: '1rem',
        },
        [theme.breakpoints.down(MD)]: {
            marginTop: 8
        },
        '&:hover': {
            //background:'linear-gradient(270deg, rgba(14, 56, 96, 0) 8.24%, #0E2F60 34.94%, #0A0730 97.58%)',
            background: '#ffffff70',
            //color: '#0c1c49',
            color: '#fff',
            backgroundPosition: '100% 0',
            transition: 'all .4s ease-in-out',
        },
        '& > span': {
            fontSize: '1.1125rem',
            paddingLeft: '0.5rem',
            lineHeight: 0.9,
            fontFamily: NUNITO
        }
    },
    addRequestBlock: {
        marginRight: '6rem',
        padding: '35px',
        display: 'flex',
        alignItems: 'center',
        '& .reportIconWrapper': {
            padding: '0.6rem 0.6rem 0.3rem 0.6rem',
            margin: '16px 50px 0px 50px',
            position: 'relative',
            fontFamily: NUNITO,
            minWidth: '100px',
            transition: '0.2s ease',
            top: '0px',
            // '&:hover': {
            //     background: 'rgba(233, 232,232, 1)'
            // },
            '& a': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                textDecoration: 'none',
                '& svg': {
                    '& path': {
                        fill: '#fff'
                    }
                }
            },
            '& .circleNumber': {
                background: '#fff',
                color: '#18409a',
                fontSize: '1rem',
                borderRadius: '50%',
                height: 30, width: 30,
                position: 'absolute',
                right: 3,
                top: -8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            },
            '& .labelReportIcon': {
                fontSize: '0.975rem',
                fontWeight: 600,
                color: '#fff'
            },
            [theme.breakpoints.between(SM, MD)]: {
                marginLeft: '2rem !important',
                marginRight: '20px !important',
            },
            [theme.breakpoints.down(SM)]: {
                marginRight: '20px !important',
            },
            '&:hover': {
                top: '-2px',
                transition: '0.2s ease',
                background: 'rgba(255,255,255,0.1)',
                borderRadius: '8px',
            }


        },
        [theme.breakpoints.down(XS)]: {
            padding: '0px!important'
        },
        [theme.breakpoints.down(MD)]: {
            marginRight: '4rem',
            padding: '0px!important'
        },
        [theme.breakpoints.down(SM)]: {
            width: '100%',
            textAlign: 'center',
            margin: '0.3125rem 0',
            justifyContent: 'space-between',
            paddingTop: '26px',
            //paddingBottom: '0',
            paddingRight: '24px',
            paddingBottom: '20px',
        },
    },
    btnAdd: {
        backgroundImage: 'linear-gradient(95deg,#00c1d1, #0070c7, #00c1d1) !important',
        boxShadow: '0px 4px 20px rgba(15, 15, 137, 0.1) !important',
        borderRadius: 55,
        width: 438,
        maxWidth: '365px',
        height: 75,
        backgroundSize: '200%',
        transition: 'all .4s ease-in-out',
        textTransform: 'none',
        paddingRight: '72px',
        '&:before': {
            background: 'none!important'
        },
        '& .MuiButton-startIcon': {
            marginRight: '35px',
            position: 'relative',
            top: '-2px',
            left: '-3px',
        },
        '& svg': {
            width: 50,
            height: 50
        },
        '& .MuiButton-label': {
            fontFamily: NUNITO,
            fontSize: '1.3rem',
            lineHeight: '1.5rem',
            fontWeight: '600',

        },
        '&:hover': {
            // backgroundImage: 'linear-gradient(90deg, #0070c7 0%, #00c1d1 100%)!important',
            backgroundPosition: '100% 0',
            transition: 'all .4s ease-in-out',
            // backgroundSize: '100% 100%'
            // '&::before':{
            //     background: 'linear-gradient(110deg,#00c1d1 10%, #0070c7 100%)!important',
            // }
        },
        [theme.breakpoints.down(XS)]: {
            width: '250px !important',
            paddingRight: '27px',
            height: '60px !important',
            '& .MuiButton-label': {
                fontSize: '1rem',
                lineHeight: '1rem',
                maxWidth: '194px',
            },
            '& .MuiButton-startIcon': {
                marginRight: '5px',
                left: '-6px',
            },
            '& svg': {
                width: '34px',
                height: '36px',
            }
        }
    },
    notificationUser: {
        display: 'flex',
        flexDirection: 'column',
        color: '#fff',
        marginLeft: '4rem',
        wordBreak: 'break-word',

        '& .alertCharAndDocumentHeader':{
            [theme.breakpoints.down(MD)]: {
                display: 'flex',
                '& > div':{
                    margin: '0 10px 0 0'
                }
            },
        },
        '& .clientName': {
            fontWeight: 200,
            fontSize: '1.6rem',
            marginBottom: '0.5rem',
            lineHeight: '1.9rem',
            marginTop: '2rem',
            marginRight: '2rem',

            [theme.breakpoints.down(MD)]: {
                fontSize: '1.45rem',
                lineHeight: '1.6rem',
                marginRight: '1rem !important',
            },
        },
        '& .alertElement': {
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.875rem',
            cursor: 'pointer',
            '&:hover': {
                background: '#0c204e',
                borderRadius: '10px 10px 0 0'
            },
            '&.activeFilter': {
                background: '#0e2d5e',
                borderRadius: '10px 10px 0 0'
            },
            '& .alarmDetail': {
                marginRight: '1rem',
                position: 'relative',
                '& svg': {
                    '& path': {
                        fill: '#00d5ff'
                    }
                },
                '& .circleAlarm': {
                    display: 'inline-block',
                    width: 19,
                    height: 19,
                    fontWeight: 800,
                    fontSize: '0.875rem',
                    lineHeight: '19px',
                    textAlign: 'center',
                    borderRadius: '50%',
                    background: '#00d5ff',
                    color: '#13387b',
                    position: 'absolute',
                    top: -7,
                    right: -10
                }
            },
            '& .underline': {
                marginLeft: 5,
                position: 'relative',
                '&:before': {
                    content: '""',
                    width: '100%',
                    height: 1,
                    background: '#fff',
                    position: 'absolute',
                    bottom: 2
                }
            }
        },
        [theme.breakpoints.down(MD)]: {
            width: '100%',
            marginLeft: '6rem',

        },
        [theme.breakpoints.down(XS)]: {

            '& .alertElement': {
                justifyContent: 'center'
            }
        },
    },
    requestNewModal: {
        width: 1200,
        maxWidth: 'calc(100% - 60px)',
        //backgroundColor: '#f6f8fd',
        backgroundColor: '#f5f7fd',
        '& .headerModalWrapper': {
            display: 'none'
        },
        '& .bodyModalWrapper': {
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: 'calc(100vh - 160px)',
            '&::-webkit-scrollbar': {
                width: 9,
                height: 9
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#0000ff',
                borderRadius: 5
            },
        },

        [theme.breakpoints.down(XS)]:{
            maxWidth: 'calc(100% - 10px)',
        }
    },
    chatInfo:{
        color: '#fff',
        // cursor: 'pointer',
        borderRadius: 5,
        padding: 5,
        width: 'fit-content',
        lineHeight: 1,
        '&:hover':{
            background:'rgba(0,0,0,0.3)',
        }
    }
})

class AddRequestModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            getDataProblem: false,
            error: "",
            successOpen: false,
            countReportRequestNotPaid: 0
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.reportRequestNotPaid !== state.countReportRequestNotPaid) {
            return {
                ...state,
                countReportRequestNotPaid: props.reportRequestNotPaid
            }
        }
        return null;
    }

    handleOpenModal = () => {
        this.setState({
            open: true
        })
    }
    handleCloseModal = () => {
        this.setState({
            open: false,
            error: ""
        })
    }
    submitRequest = (event) => {
        event.preventDefault()
        const formData = new FormData();
        let { error } = this.state
        let seft = this
        error = {}
        let dataRequest = this.props.problemRequest
        // console.log(dataRequest)
        if (!dataRequest.hasOwnProperty("problemDetail") ||
            !dataRequest.hasOwnProperty("files") ||
            (dataRequest.hasOwnProperty("problemDetail") && dataRequest.problemDetail === "") ||
            (dataRequest.hasOwnProperty("files") && dataRequest.files.length === 0)
        ) {
            error = this.props.t('modal.upload.errorInfo')
        }
        if (JSON.stringify(error) === "{}") {
            formData.append('request[problem]', dataRequest.problemDetail)
            if (dataRequest.files && dataRequest.files.length > 0) for (let index in dataRequest.files) {
                formData.append(`request[documents][${index}][documentFile]`, dataRequest.files[index].file)
            }
            this.props.setIsLoading(true);
            axios.post(BASE_URL + CREATE_REQUEST, formData)
                .then(response => {
                    let newItem = response.data.item
                    this.props.setIsLoading(false)
                    this.setState({
                        open: false,
                        successOpen: true
                    }, () => {
                        if (this.props.getNewItem) this.props.getNewItem(newItem);
                        this.props.getCountRequestByStatus()
                    })
                })
                .catch(error => {
                    console.log(error.response)
                    if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.form) {
                        this.setState({
                            error: error.response.data.errors.form
                        })
                    }
                    console.log(error)
                })
                .finally(() => {
                    seft.props.setIsLoading(false);
                })
        }
        this.setState({
            error: error
        })
    }
    handleCloseNotice = () => {
        this.setState({
            successOpen: false,
            // errorOpen: false
        })
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !(nextState === this.state &&
            nextProps.isLoading === this.props.isLoading &&
            nextProps.problemRequest === this.props.problemRequest &&
            nextProps.countRequestByStatus === this.props.countRequestByStatus);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const newState = {};
        const { getDataProblem, error } = this.state
        if (getDataProblem) newState.getDataProblem = false
        if (JSON.stringify(error) !== "{}" && prevProps.problemRequest !== this.props.problemRequest) newState.error = {}
        if (Object.keys(newState).length) {
            this.setState(newState);
        }
    }
    componentDidMount() {
        this.props.getDocumentRequestValid();
        if (this.props.openModalAddRequestInList) {
            this.props.setOpenModalAddRequestInList(false);
            this.setState({
                open: true
            })
        }

    }

    render() {
        let { open, error, getDataProblem, successOpen, countReportRequestNotPaid } = this.state
        let { isLoading, t, classes, dataUser, countRequestNotPaid, location, apiParams } = this.props
        let dataClient = dataUser.data;
        return (
            <React.Fragment>
                {isLoading && <LoadingAction />}
                {location.pathname !== links.REQUEST ?
                    <div className={classes.addRequestBlock + " addRequestBlock"}><NavLink to={links.REQUEST} className={`${classes.returnRequestList} returnRequestList`}>
                        <img alt={t("information.header.returnHistory")} src={returnIcon} />
                        <span>{t("information.header.returnHistory")}</span>
                    </NavLink></div>
                    :
                    <div className={classes.notificationUser + " notificationUser"}>
                        <span className={'clientName'}>{t('banner.hello')} {dataClient && dataClient.fullName}</span>
                        {countRequestNotPaid > 0 &&
                            <span className={`alertElement ${apiParams.notPaid ? "activeFilter" : ""}`} onClick={() => this.props.handleChangeApiParams(1, "notPaid", (apiParams.hasOwnProperty("notPaid") ? ACTION_DELETE : ACTION_ADD))}>
                                <span className={'alarmDetail'}>
                                    <CustomInlineSvg svgProps={{ src: alarmIcon }} />
                                    <span className={'circleAlarm'}>{countRequestNotPaid ?? 0}</span>
                                </span>
                                {t('banner.youHave')}
                                <span className={'underline'}>{t('banner.alarm', { field: countRequestNotPaid })}</span>
                            </span>
                        }
                        <div className="alertCharAndDocumentHeader">
                            <AlertCountChatNotSeen />
                            <AlertCountDocumentManagerNotSeen apiParamsList={apiParams} />
                        </div>
                    </div>
                }
                <div className={classes.addRequestBlock + " addRequestBlock"}>
                    <div className={`reportIconWrapper`}>
                        <div className={"circleNumber"} > {countReportRequestNotPaid ?? 0}</div>
                        <NavLink to={links.REPORT}>
                            <CustomInlineSvg svgProps={{ src: reportIcon }} />
                            <div className={"labelReportIcon"}>
                                {t("client.report.monPanien")}
                            </div>
                        </NavLink>
                    </div>
                    <CustomButton
                        className={classes.btnAdd + " btnAdd"}
                        title={t("request.btnAddRequest")}
                        // buttonProps={{
                        //     startIcon: <React.Fragment><img
                        //         src={addIcon} className={"iconAdd"} alt=""/></React.Fragment>
                        // }}
                        buttonProps={{
                            startIcon: <CustomInlineSvg svgProps={{ src: circleIcon }} />
                        }}
                        onClick={this.handleOpenModal}
                    />
                    {/*<Button onClick={this.handleOpenModal}>Test Modal</Button>*/}
                    {/*{open && <GeneralModal*/}
                    {/*    open={open}*/}
                    {/*    handleClose={this.handleCloseModal}*/}
                    {/*    title={<TitleUpload/>}*/}
                    {/*    buttonModal={<SubmitUploadButton onClick={this.submitRequest}/>}*/}
                    {/*    content={<UploadFile getDataProblem={getDataProblem}/>}*/}
                    {/*    saveButton={false}*/}
                    {/*    cancelButton={false}*/}
                    {/*    enableOnClose={false}*/}
                    {/*    error = {error}*/}
                    {/*/>}*/}
                    {open && <GeneralModal
                        open={open}
                        hideHeaderModal={true}
                        content={<RequestNew onClose={this.handleCloseModal} loadRequest={(newItem) => {
                            if (this.props.getNewItem && newItem) this.props.getNewItem(newItem);
                            this.props.getCountRequestByStatus()
                        }} />}
                        saveButton={false}
                        cancelButton={false}
                        enableOnClose={false}
                        error={error}
                        className={classes.requestNewModal}
                    />}
                </div>
                <SuccessAlert
                    snackbarProps={{
                        open: successOpen,
                        onClose: this.handleCloseNotice
                    }}
                    message={t('modal.upload.requestAdded')}
                />
            </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        problemRequest: state.applicationReducer.problemRequest,
        isLoading: state.applicationReducer.isLoading,
        openModalAddRequestInList: state.applicationReducer.openModalAddRequestInList,
        dataUser: state.authReducer.dataUser,
        countRequestByStatus: state.applicationReducer.countRequestByStatus,
        reportRequestNotPaid: state.applicationReducer.reportRequestNotPaid
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setProblemDescribe: (problemRequest) => dispatch((setProblemDescribe(problemRequest))),
        setIsLoading: (isLoading) => dispatch(application.setIsLoading(isLoading)),
        getDocumentRequestValid: () => dispatch((getDocumentRequestValid())),
        getCountRequestByStatus: () => dispatch(applicationAction.getCountRequestByStatus()),
        setOpenModalAddRequestInList: (openModalAddRequestInList) => dispatch(application.setOpenModalAddRequestInList(openModalAddRequestInList))
    }
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation(), withStyles(styles), withRouter)(AddRequestModal);
