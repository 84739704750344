import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import LoadingAction from "../../theme/LoadingAction";
import axios from "axios";
import {API_ENABLE_USER, API_GET_USER_INFOS, API_LOGIN} from "../../constants/apiSuffix";
import {BASE_URL, DATA_USER, LOGIN_PAGE, SIGN_UP} from "../../constants/constants";
import * as auth from "../../_actions/auth";
import * as application from "../../_actions/application";
import AlertCustom from "../../theme/form/Alert/AlertCustom";
import * as links from "../../constants/links"
import {Redirect} from "react-router";
import {CAPTCHA_ERROR} from "../../constants/responseCode";
import {withRouter} from "react-router-dom";
const styles = theme => ({

});

class LoginSocial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            successOpen: false,
            errorOpen: false,
            messageError: "",
            redirectUrl: ""
        };
        const {match} = this.props;
        const dataUser = match?.params?.dataUser ?? "";
        if (dataUser){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + dataUser;
            axios.get(BASE_URL + API_GET_USER_INFOS)
                .then(response => {
                    const isTempSocial = response.data?.data?.isSocial && response.data?.data?.isTempSocial;
                    if (isTempSocial) {
                        window.location.href = `${links.LOGIN}?typePage=${SIGN_UP}&firstName=${response.data?.data?.firstName ?? ""}&lastName=${response.data?.data?.lastName ?? ""}&facebookId=${response.data?.data?.facebookId ?? null}`;
                    } else {
                        if (response.data?.data?.enabled) {
                            localStorage.setItem(DATA_USER, JSON.stringify(response.data));
                            window.location.href = links.HOME;
                        } else {
                            this.props.history.push(links.LOGIN);
                        }
                    }
                })
                .catch(error => {
                    window.location.href = links.HOME;
                });
        }
        else{
            window.location.href = links.HOME;
        }
    }

    handleCloseNotice = () => {
        this.setState({
            successOpen: false,
            errorOpen: false
        })
    }

    // componentDidMount() {
    //     const {match} = this.props;
    //     const dataUser = match && match.match.params && match.match.params.dataUser ? match.match.params.dataUser : "";
    //     if (dataUser){
    //         axios.defaults.headers.common['Authorization'] = 'Bearer ' + dataUser;
    //         axios.get(BASE_URL + API_GET_USER_INFOS)
    //             .then(response => {
    //                 console.log(response.data);
    //                 localStorage.setItem(DATA_USER, JSON.stringify(response.data));
    //                 window.location.href = links.HOME;
    //             })
    //             .catch(error => {
    //                 window.location.href = links.HOME;
    //             });
    //     }
    //     else{
    //         window.location.href = links.HOME;
    //     }
    // }

    render() {
        const {
            isLoading,messageError
        } = this.state;
        return (
            <div>
                {isLoading && <LoadingAction />}
                <AlertCustom
                    successOpen={this.state.successOpen}
                    errorOpen={this.state.errorOpen}
                    handleCloseNotice={()=>this.props.setErrorAlert(false, "")}
                    messageError={messageError}
                />
            </div>
        )
    }
}

LoginSocial.defaultProps = {

}

LoginSocial.propTypes = {
    classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
    return {
        dataUser : state.authReducer.dataUser
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setErrorAlert: (successOpenAlert, message) => dispatch(application.setErrorAlert(successOpenAlert, message)),
        saveTypeFormAuth: (typeFormAuth) => dispatch(auth.saveTypeFormAuth(typeFormAuth)),
        setDataUser: (dataUser) => dispatch(auth.setDataUser(dataUser)),
        checkSwitchAuthForm: (checkSwitch) => dispatch(auth.checkSwitchAuthForm(checkSwitch)),
        setDataLogin: (dataLogin) => dispatch(auth.setDataLogin(dataLogin)),
    }
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter
)(LoginSocial);
