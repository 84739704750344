import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import {NavLink, withRouter} from "react-router-dom";
import CustomInput from "../../../../theme/CustomElements/CustomInput";
import RowFormColumn from "../../../../theme/form/RowFormColumn";
import UploadDoc from "../../../../images/request/document/upload_doc.svg";
import {FORM_CREATE, FORM_EDIT} from "../../../../constants/constants";
import axios from "axios";
import {
    API_CREATE_GENERAL_DOCUMENT, API_EDIT_GENERAL_DOCUMENT, API_SHOW_GENERAL_DOCUMENT,
    GET_REQUEST_DOCUMENT_TYPE_LIST,
} from "../../../../constants/apiSuffix";
import Dropzone from "react-dropzone";
import 'react-dropzone-uploader/dist/styles.css'
import CustomInlineSvg from "../../../../theme/CustomElements/CustomInlineSvg";
import closeButton from "../../../../images/theme/close.svg";
import AppAsyncPaginate from "../../../../theme/form/Select/AppAsyncPaginate";
import qs from "qs";
import CustomButton from "../../../../theme/CustomElements/CustomButton";
import SaveIcon from "@material-ui/icons/Save";
import Checkbox from "@material-ui/core/Checkbox";
import LoadingAction from "../../../../theme/LoadingAction";
import {GENERAL_DOCUMENT_URL} from "../../linkRectAdmin";
import ViewVersionDocument from "./ViewVersionDocument";

const styles = (theme) => ({
    generalDocumentFormWrapper: {
        '& .header': {
            display: 'flex',
            marginBottom: 10,
        },
        '& a.returnList':{
            '&:hover': {
                background: "#0000c7",
            },
            textTransform: "uppercase",
            fontSize: '0.75rem',
            color: "white",
            background: "#0000FF",
            padding: '0.5rem 1rem',
            borderRadius: 5,
            fontWeight: 700,
            textDecoration: 'none'
        },
        '& .selectPaginate__menu-list':{
            zIndex: 999
        },
        '& .selectPaginate__control': {
            zIndex: 999
        },
        '& .selectPaginate__menu': {
            zIndex: 999
        },
    },
    filePreview: {
        position: 'relative',
        paddingTop: 20,
        '& object': {
            height: 240,
        },
        '& .closeButton':{
            position: 'absolute',
            top: 10,
            left: 10,
            backgroundColor: '#cbeeff',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 20,
            height: 20,
            cursor: 'pointer',
            '& svg':{
                '& path': {
                    fill: '#e33838',
                    stroke: '#e33838'
                }
            }
        },
    },
    inputUploadFile: {
        "& .uploadIcon": {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#bed5f9',
            borderRadius: 5,
            padding: '10px 0',
            '& img': {
                height: 80
            }
        }
    },
    btnResponse:{
        background: '#0000ff !important',
        margin: '1rem 0'
    },
    deleteSvg:{
        padding: '0.25rem 0.5rem',
        marginRight: 5,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        '&:hover':{
            background: '#f2f0f0',
        }
    },
    changeVersionDocument: {
        cursor: 'pointer',
        borderRadius: 5,
        '& img': {
            height: 48,

        }
    },
    listVersionDocument: {
        '& .title': {
            fontWeight: 'bold',
            fontSize: '1rem',
        },
        '& .versionDocItem': {
            // display: 'flex',
            // justifyContent: 'space-between',
            '& .name': {

            },
            '& .createdAt': {

            },
            '& .changeVersionDocument': {
                backgroundColor: '#0000ff !important',
                color: '#fff',
                borderRadius: 5,
                textAlign: 'center',
                maxWidth: 180,
                fontWeight: 600,
                padding: 5,
                cursor: 'pointer',
            }
        }
    },
    viewIcon: {
        padding: '0 10px',
        '& img': {
            width: 30,
        }
    },
})

const dataInitial = {

}
class GeneralDocumentForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            file: null,
            type: null,
            enabled: true,
            documents: [],
            removeDocuments: [],
            //
            fileVersion: null,
            isLoading: false,


            //dropzone
            preparing: 0,
            done: 0,
            removed: 0,
            maxFile: false,
            errorFileType: false,
            formType: FORM_CREATE,
            openModal: false,
            dataModal: null
        };
    }

    componentDidMount() {
        const {match, location} = this.props;

        if (match && match.params && match.params.id) {
            this.setState({
                formType: FORM_EDIT
            })
            this.getShowGeneralDocument(match.params.id)
        }
    }

    getShowGeneralDocument = (id) => {
        if (id) {
            axios.get(API_SHOW_GENERAL_DOCUMENT.replace('{id}', id))
                .then(res => {
                    if (res.data) {
                        console.log(res.data.type)
                        this.setState({
                            name: res.data.name,
                            description: res.data.description,
                            file: null,
                            enabled: !!res.data.enabled,
                            type: {
                                ...res.data.type,
                                value: res.data.type.id,
                                label: res.data.type.name
                            },
                            documents: res.data.versionDocuments
                        })
                    }
                })
                .catch(err => {

                })
        }
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    loadDocumentType = async (search, loadedOptions, {page}) => {
        let params = {filters: {name: search}, page: page};
        const url = GET_REQUEST_DOCUMENT_TYPE_LIST + "?" + qs.stringify(params)
        const response = await axios.get(url);
        const data = response.data;
        let dataItems = data.items;
        let hasMore = data.currentPage < data.nbPages;
        const options = dataItems.map((item) => {
            return {label: item.name, value: item.id, ...item};
        });

        return {
            options: options,
            hasMore: hasMore,
            additional: {
                page: page + 1,
            },
        };
    }

    handleSave = () => {
        const {match, location} = this.props;

        const {
            name,
            description,
            file,
            type,
            documents,
            enabled,
            formType,
            removeDocuments
        } = this.state;
        this.setState({
            isLoading: true
        })
        const formData = new FormData();
        formData.append('generalDocument[name]', name);
        formData.append('generalDocument[description]', description);
        if (type?.value) {
            formData.append('generalDocument[type]', type.value);
        }
        formData.append('generalDocument[enabled]', enabled ? 1 : 0);
        if (file) {
            formData.append('generalDocument[versionDocuments][0][documentFile]', file);
        }
        if (Array.isArray(removeDocuments)) {
            removeDocuments.forEach((item, index) => {
                    formData.append(`removeDocuments[${index}]`, item)
            })
        }
        let url = API_CREATE_GENERAL_DOCUMENT
        if (formType === FORM_EDIT){
            url = API_EDIT_GENERAL_DOCUMENT.replace('{id}', match.params.id)
        }
        axios.post(url, formData)
            .then(res => {
                this.props.history.push(GENERAL_DOCUMENT_URL)

                this.setState({
                    isLoading: false
                })
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
            })
    }

    removeDocument = (docItem) => {
        const {
            documents,
            removeDocuments
        } = this.state;

        this.setState({
            removeDocuments: removeDocuments.concat(docItem.id),
            documents: documents.filter(item => docItem.id != item.id),
        })
    }

    render() {
        const {
            name,
            description,
            file,
            type,
            documents,
            enabled,
            isLoading,
            formType,
            removeDocuments,
            openModal,
            dataModal,
            fileVersion
        } = this.state;
        const {
            classes,
            match
        } = this.props;
        const filePreview = file ? URL.createObjectURL(file) : null;
        const fileVersionPreview = fileVersion ? URL.createObjectURL(fileVersion) : null;
        console.log(removeDocuments)
        return (
            <div className={classes.generalDocumentFormWrapper}>
                <div className="header">
                    <NavLink to={GENERAL_DOCUMENT_URL} className="returnList">
                        {this.props.t('reactAdmin.generalDoc.returnList')}
                    </NavLink>
                </div>
                {isLoading && <LoadingAction/>}
                <RowFormColumn
                    label={this.props.t('reactAdmin.generalDoc.enabled')}
                    input={<Checkbox
                        checked={enabled}
                        onChange={(e) => {
                            this.handleChange("enabled", e.target.checked)
                        }}
                    />}
                />
                <RowFormColumn
                    label={this.props.t('reactAdmin.generalDoc.name')}
                    input={<CustomInput
                        type="text"
                        name="name"
                        value={name}
                        onChange={(e) => {
                            this.handleChange("name", e.target.value)
                        }}
                    />}
                />
                <RowFormColumn
                    label={this.props.t('reactAdmin.generalDoc.description')}
                    input={<CustomInput
                        type="text"
                        name="description"
                        value={description}
                        onChange={(e) => {
                            this.handleChange("description", e.target.value)
                        }}
                    />}
                />
                <RowFormColumn
                    label={this.props.t('reactAdmin.generalDoc.type')}
                    input={ <AppAsyncPaginate
                        classNamePrefix={"selectPaginate"}
                        value={type}
                        loadOptions={(search, loadedOptions, {page}) => this.loadDocumentType(search, loadedOptions, {page})}
                        onChange={(value) => this.handleChange("type", value)}
                        debounceTimeout={500}
                        additional={{
                            page: 1
                        }}
                        // placeholder={"type"}
                        // closeMenuOnSelect={false}
                    />}
                />
                {formType === FORM_EDIT && Array.isArray(documents) && <ViewVersionDocument
                    documents={documents}
                    removeDocument={this.removeDocument}
                    changeVersionDocumentDone={() => {
                        this.setState({
                            name: '',
                            description: '',
                            file: null,
                            type: null,
                            enabled: true,
                            documents: [],
                            removeDocuments: [],
                            //
                            fileVersion: null,
                            isLoading: false,
                            openModal: false,
                            dataModal: null
                        }, () => {
                            this.getShowGeneralDocument(match.params.id);
                        })
                    }}
                    isEdit={true}
                />}
                <Dropzone
                    ref={(fileUpload) => {
                        this.fileUpload = fileUpload;
                    }}
                    // accept={}
                    minSize={0}
                    maxFiles={10}
                    multiple={true}
                    onDrop={acceptedFiles => {
                        this.handleChange("file", acceptedFiles[0])
                    }}>
                    {({getRootProps, getInputProps}) => {
                        return (<section>
                            <div {...getRootProps()} className={classes.inputUploadFile}>
                                <input {...getInputProps()} />
                                <div className="uploadIcon">
                                    <img src={UploadDoc} alt=""/>
                                </div>
                            </div>
                        </section>)
                    }}
                </Dropzone>
                {filePreview && <div className={classes.filePreview}>
                    <object className={classes.zoomInModal} width="100%" height="100%"
                            data={filePreview}>
                        <embed src={filePreview}  width="100%" height="100%"/>
                    </object>
                    <div className={"closeButton"}
                         onClick={()=>{
                             this.handleChange("file", null)
                         }}
                    >
                        <CustomInlineSvg
                            svgProps={{
                                src: closeButton,
                            }}
                            scale="0.6"
                        />
                    </div>
                </div>}



                <CustomButton
                    className={classes.btnResponse}
                    title={this.props.t("button.save")}
                    buttonProps={{
                        startIcon: <React.Fragment><SaveIcon className={"iconAdd"} /></React.Fragment>
                    }}
                    onClick={this.handleSave}/>
            </div>
        )
    }
}

GeneralDocumentForm.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

export default   compose(
    // connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter
) (GeneralDocumentForm);
