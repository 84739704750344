import React from "react";
import {Snackbar, withStyles} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {withTranslation} from "react-i18next";

const styles = {

};

class ErrorAlert extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            props: {
                anchorOrigin:{
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration:5000
            },
            alertProps: {
                severity:"error",
                elevation:6,
                variant:"filled"
            }
        };
    }

    render() {
        const props = Object.assign(this.state.props,this.props.snackbarProps?this.props.snackbarProps:{});
        const alertProps = Object.assign(this.state.alertProps,this.props.alertProps?this.props.alertProps:{});
        return <Snackbar
            {...props}
        >
            <Alert {...alertProps}>
                {this.props.message?this.props.message:(props.message?props.message:this.props.t("form.error.occurred"))}
            </Alert>
        </Snackbar>;
    }
}

export default withStyles(styles)(withTranslation()(ErrorAlert));
