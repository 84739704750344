import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {customRenderShowBtnAction} from "../constantRequestPage";
import chatIcon from "../../../../images/request/chat-admin.svg";
import {POPOVER_ADD_CHAT, POPOVER_READ_OR_UNREAD_CHAT} from "../RequestPage";
import alertIcon from "../../../../images/request/Groupe 9096.png";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withWidth} from "@material-ui/core";
import * as applicationAction from "../../../../_actions/application";
import PropTypes from "prop-types";
import PopoverAddChat from "../popover/PopoverAddChat";

const styles = theme => ({
    iconAlertNotSeen: {
        width: 27,
        height:27,
        position: 'absolute',
        top: -15,
        right: -15,
        backgroundImage: `url(${alertIcon})`,
        backgroundRepeat: 'no-repeat',
        '&.iconAlertNotSeen2':{
            top: -7,
            right: -15,
        },
        '&.viewClient': {
            top: -7,
            right: -7,
        }
    },
    openPopoverMenuThreeDots:{
        '& $actionChatWrapper':{
            display: 'block',
            background:'rgba(0,0,0,0.1)',
            borderRadius: '10px',

        },
    },
    btnActionChatWrapper:{
        position: 'relative',
        '&:hover':{
            '& $actionChatWrapper':{
                display: 'block',
                background: '#f2f2ff',
                right: '-16px',
                borderRadius: '25px',

                '&:hover':{
                    background: '#bcc3db',
                }
            }
        }
    },
})

class ButtonChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            formValuePopover: {},
            popoverType: "",
        }
        this.handleOpenPopOver = this.handleOpenPopOver.bind(this);
        this.handleClosePopOver = this.handleClosePopOver.bind(this)
    }

    handleOpenPopOver(event, row, type) {
        this.setState({
            anchorEl: event.currentTarget,
            formValuePopover: row,
            popoverType: type,
        });
    }

    handleClosePopOver() {
        this.setState({
            anchorEl: null,
            formValuePopover: {},
            popoverType: "",
        });
    }

    render() {
        const {dataClient, classes, t, countChatNotSeen, isClient} = this.props
        const {
            anchorEl,
            formValuePopover,
            popoverType,
        } = this.state;
        let disableOnClickChat = false;
        if (!dataClient) {
            return <></>
        }

        return (
            <>
                {customRenderShowBtnAction({
                    onClick: (e) => {if(!disableOnClickChat) (this.handleOpenPopOver(e, dataClient, POPOVER_ADD_CHAT))},
                    icon: chatIcon,
                    iconComponent: Array.isArray(countChatNotSeen) && countChatNotSeen.includes(dataClient.id) &&
                        <div className={classes.iconAlertNotSeen + (isClient ? " viewClient" : "")}/>,
                    className: `${classes.btnActionChatWrapper} ${anchorEl && popoverType === POPOVER_READ_OR_UNREAD_CHAT && (formValuePopover?.id === dataClient.id)? classes.openPopoverMenuThreeDots:""}`,
                    title: t("request.list.chat")
                })}
                {anchorEl && formValuePopover && popoverType === POPOVER_ADD_CHAT &&
                    <PopoverAddChat
                        anchorEl={anchorEl}
                        onClose={this.handleClosePopOver}
                        formValuePopover={formValuePopover}
                    />
                }
            </>
        );
    }
}
ButtonChat.defaultProps = {

}

ButtonChat.propTypes = {
    classes: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => {
    return {
        countChatNotSeen:state.applicationReducer.countChatNotSeen,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCountChatNotSeen: (apiParams) => dispatch(applicationAction.getCountChatNotSeen(apiParams)),
        setCountChatNotSeen: (value) => dispatch(applicationAction.setCountChatNotSeen(value)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter,
    withWidth()
)(ButtonChat);