import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import NavBar2 from "./../NavBar2";
import banner from "./../../images/banner/banner.png"
import {withRouter} from "react-router";
import {LG, MD, SM, XS} from "../../constants/constants";
const styles = theme => ({
    informationPage:{
        '& .headerInformationPage': {
            background: `url(${banner})`,
            backgroundSize: 'cover',
            height: 154,
            '&::before':{
                content:'""',
                width: '100%',
                height: 154,
                // position: 'absolute',
                background: 'linear-gradient( rgba(14, 56, 96, 0) 21.5%, #0E2F60 78.76%, #0A0730 131.14%)',
                zIndex: 1,
            },
            [theme.breakpoints.down(MD)]:{
                height: 120
            },
            [theme.breakpoints.down(SM)]:{
                height: 80
            }
            // background: 'linear-gradient(270deg, rgba(14, 56, 96, 0) 21.5%, #0E2F60 78.76%, #0A0730 131.14%)'
        },
        '& .contentInformationPage':{
            marginTop: -80,
            position: 'absolute',
            zIndex: 10,
            '& .contentPage':{
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: 20,
                maxWidth: 1200,
                margin: 'auto'
            },
            [theme.breakpoints.down(MD)]: {
                // padding: '0 4rem'
                marginTop: -15
            },
            [theme.breakpoints.down(SM)]: {
                // padding: '0 2rem'
                marginTop: -8,
                '& .contentPage':{
                    maxWidth: '95%'
                }
            },
        },
        "& .tabsInformation": {
            boxShadow: '0px 4px 20px rgba(0, 0, 254, 0.05)',
            borderRadius: '15px 0px 15px 15px',
        },
        '& .contentTab':{
            background: '#fff',
            // borderRadius: '0 0 15px 15px',
            boxShadow: '0px 4px 20px rgba(0, 0, 254, 0.05)',
            padding: '1rem',
            minHeight: '500px',
        },
    },
});

class InformationPageCustom extends React.Component {
    constructor(props) {
        super(props);
        this.state={
        };
    }

    render() {
        const {
            classes, content, local
        } = this.props;
        return (
            <div className={classes.informationPage}>
                <div className={"headerInformationPage"}>
                    <NavBar2/>
                </div>
                <Grid container className={"contentInformationPage"}>
                    <Grid container item xs={11} md={10} lg={9} className={"contentPage"}>
                        {content ? content : ""}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

InformationPageCustom.defaultProps = {}

InformationPageCustom.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(
    withStyles(styles),
    withTranslation(),
    withRouter
)(InformationPageCustom);
