import React from "react";
import {KeyboardDatePicker} from "@material-ui/pickers";
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core";
import i18n from "../../../i18n";
import moment from "moment";
import EventIcon from '@material-ui/icons/Event';

const styles = {
    inputBlock: {
        // background: "white",
        backgroundColor: 'transparent',
        borderRadius: 5,
        border: '1px solid #d1d1d1',
        overflow: 'hidden',
        "& input":{
            padding: 10
        },
        "&:hover,&.Mui-focused":{
            backgroundColor: 'transparent',
        },
        "&.Mui-focused+p":{
            display: "none"
        },
    },

    inputBlockError: {
        background: "white",
        border: '1px solid #f80606',
        borderRadius: 5,
        "& input":{
            padding: '0.7rem'
        },
        "&:hover,&.Mui-focused":{
            background: "white",
        },
        "&.Mui-focused+p":{
            display: "none"
        }
    },
    datePicker:{
        width: "100%",
        // border: '1px solid #d1d1d1',
        // borderRadius: 4,
        "&>label":{
            transform: "translate(14px, 11px) scale(1)",
            "&.MuiInputLabel-shrink":{
                display: "none"
            }
        },
        "&>$inputBlock.Mui-focused+.Mui-error":{
            display:"none"
        },
        '& .MuiInputBase-root': {
            paddingRight: 0
        },
        '& .MuiIconButton-label': {
            '& img': {
                width: 25
            }
        }
    }
};

class AppKeyboardDatePicker extends React.Component{
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const {classes,InputProps,...otherProps} = this.props;
        const maxDate = this.props.maxDate?this.props.maxDate:new Date(((new Date()).getFullYear()+100).toString()+"-12-31");
        const minDate = this.props.minDate?this.props.minDate:new Date("1900-01-01");
        let props = {
            className: "appDatePicker "+classes.datePicker,
            margin:"none",
            variant:"dialog",
            inputVariant:"filled",
            format:"DD/MM/YYYY",
            KeyboardButtonProps:{
                'aria-label': 'change date',
            },
            InputProps:{
                className: (this.props.error? classes.inputBlockError: classes.inputBlock) + " inputBlockDatePicker",
                disableUnderline: true,
                placeholder: this.props.placeholder ? this.props.placeholder : i18n.t("form.datepicker.placeholder")
            },
            minDate: minDate,
            maxDate: maxDate,
            emptyLabel: "",
            invalidDateMessage: i18n.t("form.error.invalid_date"),
            maxDateMessage: i18n.t("form.error.maxDateMessage").replace("{maxDate}",moment(maxDate).format(this.props.format?this.props.format:"DD/MM/YYYY")),
            minDateMessage: i18n.t("form.error.minDateMessage").replace("{minDate}",moment(minDate).format(this.props.format?this.props.format:"DD/MM/YYYY")),
            cancelLabel: i18n.t("button.cancel"),
            clearLabel: i18n.t("button.clear"),
            okLabel: i18n.t("button.ok"),
            todayLabel: i18n.t("label.today"),
            showTodayButton:true,
            keyboardIcon: this.props.iconCalendar ? this.props.iconCalendar : <EventIcon/>
         };
        props = Object.assign(props,InputProps ? Object.assign(props.InputProps,InputProps) : {});
        props = Object.assign(props,{...otherProps});
        return <KeyboardDatePicker
            {...props}
        />;
    }
}

AppKeyboardDatePicker.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AppKeyboardDatePicker);