import React from 'react';
import {
    withStyles
} from '@material-ui/core/styles';
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

const styles = (theme) => ({
    popoverAlertAndButton: {
        position: 'relative',
        '& .arrowLeft': {
            position: 'absolute',
            zIndex: 1,
            top: -6,
            left: 'calc(50% - 5px)',
            width: 0,
            height: 0,
            borderLeft: '6px solid transparent',
            borderRight: '6px solid transparent',
            borderBottom: '7px solid #4ea5ff'
        },
        '& .popoverAlertText': {
            width: 'fit-content',
            padding: '4px 10px',
            borderRadius: 7,
            backgroundColor: '#4ea5ff',
            color: '#fff',
            fontSize: 12,
            visibility: 'visible',
            position: 'absolute',
            zIndex: 1,
            top: 8,
            left: -28,
            minWidth: 85,
            textAlign: 'center'
        }
    }
});


class PopoverAlertTooltip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const {classes, t, title} = this.props;
        return (
            <div className={`${classes.popoverAlertAndButton} popoverAlertAndButtonCustom`}>
                <div className={"popoverAlertText"}>
                    <div className="arrowLeft"/>
                    {title ?? t("request.uploadFileToken.linkCopied")}
                </div>
            </div>
        );
    }
}

PopoverAlertTooltip.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
}

export default compose(
    withTranslation(),
    withStyles(styles)
)(PopoverAlertTooltip);
