import React, {Component} from 'react';
import {Grid, withStyles} from "@material-ui/core";
import GeneralModal from "../../../../theme/Modal/GeneralModal";
import {connect} from "react-redux";
import {getDocumentRequestValid, setProblemDescribe} from "../../../../_actions/application";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import * as application from "../../../../_actions/application";
import LoadingAction from "../../../../theme/LoadingAction";
import axios from "axios"
import {
    API_GET_USER_LIST,
    API_RESTDAY_REPAIRER_CREATE,
} from "../../../../constants/apiSuffix";
import {USER_TYPE_ADMIN, USER_TYPE_REPAIRER} from "../../../../constants/constants";
import CustomButton from "../../../../theme/CustomElements/CustomButton";
import * as applicationAction from "../../../../_actions/application";
import SuccessAlert from "../../../../theme/form/Alert/SuccessAlert";
import RowFormColumn from "../../../../theme/form/RowFormColumn";
import AppKeyboardDateTimePicker from "../../../../theme/form/DatePicker/AppKeyboardDateTimePicker";
import qs from "qs";
import {convertToDateH_m_D_M_Y, ConvertToDateY_M_DH_m_s} from "../../../../functions/datetime";
import CustomInput from "../../../../theme/CustomElements/CustomInput";
import returnIcon from "../../../../images/request/return.svg";
import AppPersonAvatar from "../../../../theme/Avatar/AppPersonAvatar";
import searchIcon from "../../../../images/managerCalendar/search.svg";
import TextField from '@material-ui/core/TextField';
import lodash from 'lodash';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from '@material-ui/core/Checkbox';
import AppAutocomplete from "../../../../theme/form/Autocomplete/AppAutocomplete";
import {errorsMessAddRestDay, errorsMessPerson} from "../../../../functions/checkErrorEmpty";
import {checkSubmitSendDataERROR} from "../../../../functions/checkCondition";
import moment from "moment";
import {OVERLAP_DATE_TIME, START_AFTER_END, USER_NOT_EXIT} from "../../../../constants/responseCode";
import MessageIcon from "@material-ui/icons/Message";
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import TitleIcon from '@material-ui/icons/Title';
import PropTypes from "prop-types";
import AlertCustom from "../../../../theme/form/Alert/AlertCustom";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const styles = theme => ({
    modalRestdayWrapper: {
        maxHeight: '80%',
        mimHeight: '50%',
        overflow: 'auto',
        '& .headerModalWrapper':{
            '& .titleModal':
                {
                    fontWeight: 700,
                    fontSize: '1.2rem'
                }
        }
    },
    modalRestday:{
        "& .labelRowFormColumn": {
            fontSize: '0.875rem',
            fontWeight: 700,
            color: '#001529',
            '& svg':{
                fontSize: '0.875rem',
            }
        },
            '& .column1':{
                paddingRight: '1rem',
            },
        '& .column2':{
            paddingLeft: '1rem'
        }
    },
    btnAddRestday: {
        background: '#0000FF !important',
        borderRadius: 18
    },
    selectRepairerWrapper: {
        '&.errorValidate':{
            '& .inputRowFormColumn':{
                border: '1px solid #d01631',
            },
        },
        '& .inputRowFormColumn': {
            height: 300,
            padding: '0.5rem',
            border: '1px solid #E0E0E0',
            borderRadius: 15,

            '& .MuiOutlinedInput-root':{
                background: '#F8F8FF',
                border: '1px solid #D8DDE6',
                borderRadius: 20,
                width: '100%',
                padding: '0 0.5rem'
            },
        },
    },
    selectItemRepairerWrapper: {
        border: '1px solid #E0E0E0 !important',
        borderRadius: 6,
        // margin: '0.5rem 0',
        padding: '0.5rem',
        boxShadow: ' 0px 4px 20px rgba(0, 0, 254, 0.05)',
        fontSize: '1rem',
        color: '#001529',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        '& .selectedValue': {
            color: '#001529',
            display: 'flex',
            alignItems: 'center',
            '& img': {
                paddingRight: '0.3rem'
            }
        },
        '& .avatarRepairer': {
            height: '30px !important',
            width: '30px !important',
            marginRight: '0.5rem'
        }
    },
    messErrorsWrapper:{
        color: '#d01631',
        '& .userNotExist':{
            display: 'flex',
            '& .label':{
                fontWeight: 700,
                paddingLeft: '0.3rem',
                fontSize:'1rem'
            }
        },
        '& .overlap':{
            fontSize:'1rem',
            fontWeight: 800,
        },
        '& .messError':{
            display: 'flex',
            '& .fullName':{
                fontWeight: 700,
                paddingRight: '0.5rem',
                fontSize:'0.875rem'
            }
        }
    }
})

const ALL_SELECT = "ALL_SELECT";
class ModalRestday extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            getDataProblem: false,
            errors: {},
            successOpen: false,
            errorOpen: false,
            userList: [],
            formValue: {
                startRestAt: null,
                endRestAt: null,
                user: [],
                subject: "",
                description: ""
            },
            errorUserDatetime: {},
            errorUserNotExist: []
        }
    }

    handleChange = (value, field) => {
        let {formValue, errors, userList} = this.state;
        console.log(value)
        if (field === "user") {
            let valueNew = value;
            let getValueNotCheck = lodash.differenceWith(userList, this.state.formValue.user, function (o1, o2) {
                return o1.value === o2.value
            });
            if (getValueNotCheck && getValueNotCheck.length) {
                const valueFilterValueAll = value.filter(item => item.value === ALL_SELECT);
                if (valueFilterValueAll && valueFilterValueAll.length) {
                    valueNew = userList;
                }
            } else {
                valueNew = value.filter(item => item.value !== ALL_SELECT);
            }
            formValue[field] = valueNew;
        } else {
            formValue[field] = value;
        }
        if (errors.hasOwnProperty(field)) {
            delete (errors[field]);
        }
        this.setState({
            formValue: formValue
        })
    }

    submitRequest = (event) => {
        event.preventDefault()
        const formData = new FormData();
        const {t} = this.props;
        let {errors, formValue, errorUserDatetime} = this.state
        let seft = this
        for (let [key, value] of Object.entries(formValue)) {
            if (errorsMessAddRestDay.hasOwnProperty(key)) {
                errors = checkSubmitSendDataERROR(value, errorsMessAddRestDay[key].type, errors, key, errorsMessAddRestDay[key].errorByField, t)
            }
        }

        if(Object.keys(errors).length < 1){
            if(moment(formValue.startRestAt).valueOf() >= moment(formValue.endRestAt).valueOf()){
                errors["endRestAt"] = t("managerCalendarPage.managerCalendarRight.modalRestday.errors.startDateIsMoreThanEndDate")
            }
        }

        if (Object.keys(errors).length < 1) {
            this.setState({
                isLoading: true,
                errorUserDatetime: {},
                errorUserNotExist: []
            })
            formValue.user.map((item, key) => {
                if(item.value !== ALL_SELECT) {
                    formData.append('restday[account][' + key + "]", item.value)
                }
            })
            // formData.append('restday[repairer]', formValue.user.value)
            formData.append('restday[subject]', (formValue.subject))
            formData.append('restday[description]', (formValue.description))
            formData.append('restday[startRestAt]', ConvertToDateY_M_DH_m_s(moment(formValue.startRestAt).format("YYYY-MM-DD HH:mm:00")))
            formData.append('restday[endRestAt]', ConvertToDateY_M_DH_m_s(moment(formValue.endRestAt).format("YYYY-MM-DD HH:mm:00") ))


            axios.post(API_RESTDAY_REPAIRER_CREATE, formData)
                .then(response => {
                    this.props.setIsLoading(false)
                    this.setState({
                        isLoading: false,
                        successOpen: true
                    }, () => {
                        this.props.closeModalAndUpdateData();
                    })
                })
                .catch(error => {
                    console.log(error.response)
                    let stateNew = {isLoading: false, errorOpen: true};
                    if (error.response && error.response.status === 400 && error.response.data && error.response.data.hasOwnProperty(OVERLAP_DATE_TIME)) {
                        let dataDateOverlap = error.response.data[OVERLAP_DATE_TIME]
                        dataDateOverlap.map(item => {
                            let fulNamePerson = item.account.person && item.account.person.fullName ? item.account.person.fullName : item.account.email;
                            let startEndDate = "";
                            item.overlap.map(child => {
                                let startDateOverlap = child.startDate ? child.startDate : child.startRestAt ? child.startRestAt : child.registrationStart ? child.registrationStart : ""
                                let endDateOverlap = child.endDate ? child.endDate : child.endRestAt ? child.endRestAt : child.registrationEnd ? child.registrationEnd : "";
                                startEndDate += (startEndDate ? ", " : "") + convertToDateH_m_D_M_Y(startDateOverlap) + " - " + convertToDateH_m_D_M_Y(endDateOverlap)
                            })

                            errorUserDatetime = {...errorUserDatetime, [fulNamePerson]: startEndDate}
                        })
                        stateNew = {...stateNew, errorUserDatetime: errorUserDatetime};
                    }
                    if (error.response && error.response.status === 400 && error.response.data && error.response.data.hasOwnProperty(USER_NOT_EXIT)) {
                        let dataDateOverlap = error.response.data[USER_NOT_EXIT]
                        let fulNamePersonExist = []
                            dataDateOverlap.map(item => {
                             fulNamePersonExist.push(item.person && item.person.fullName ? item.person.fullName : item.email);
                        })
                        stateNew = {...stateNew, errorUserNotExist: fulNamePersonExist};
                    }
                    if (error.response && error.response.status === 400 && error.response.data && error.response.data.response_code === START_AFTER_END) {
                        errors["endRestAt"] = t("managerCalendarPage.managerCalendarRight.modalRestday.errors.startDateIsMoreThanEndDate")
                        stateNew = {...stateNew, errors: errors};
                    }
                    this.setState(stateNew)

                })
                .finally(() => {
                    this.props.setIsLoading(false);
                })
        }else{
            this.setState({
                errors: errors,
                errorOpen: true
            })
        }
    }
    handleCloseNotice = () => {
        this.setState({
            successOpen: false,
            errorOpen: false
        })
    }

    componentDidMount() {
            const urlRepairer = API_GET_USER_LIST + "?" + qs.stringify({userType: [USER_TYPE_REPAIRER, USER_TYPE_ADMIN],checkEnableUser: 1, maxPerPageAll: 1})
            axios.get(urlRepairer).then(res => {
                if (res.status === 200) {
                    let dataItems = res.data.items;
                    let groupsDataRepairer = [{value: ALL_SELECT, label: this.props.t("managerCalendarPage.managerCalendarRight.modalRestday.allUser")}];
                    dataItems.map(item => {
                        const labelText = item.person && item.person.fullName ? item.person.fullName : item.email;
                        let itemArray = {
                            value: item.id,
                            label: labelText,
                            avatar: item.person && item.person.avatar ? item.person.avatar : null
                        };
                        groupsDataRepairer.push(itemArray)
                    });
                    this.setState({
                        userList: groupsDataRepairer,
                    })
                }

            })
    }

    render() {
        let {userList, errors, formValue, successOpen, errorUserDatetime, errorUserNotExist} = this.state
        let {isLoading, t, classes, openModal} = this.props
        const headTrans = "managerCalendarPage.managerCalendarRight.modalRestday."
        return (
            <React.Fragment>
                {isLoading && <LoadingAction/>}
                <GeneralModal
                    className={classes.modalRestdayWrapper}
                    open={openModal}
                    handleClose={this.props.handleCloseModal}
                    title={t(headTrans + "titleAdd")}
                    buttonModal={<CustomButton title={t(headTrans + "btnSave")}
                                               className={classes.btnAddRestday}
                                               buttonProps={{
                                                   startIcon: <React.Fragment><img
                                                       src={returnIcon} className={"iconAdd"} alt=""/></React.Fragment>
                                               }} onClick={this.submitRequest}/>}
                    content={
                        <Grid container className={classes.modalRestday}>
                            <Grid container item xs={12} className={classes.messErrorsWrapper}>
                                {Object.keys(errorUserDatetime).length > 0 &&
                                <div>
                                    <div className={"overlap"}>{t(headTrans + "errors.overlapDatetime")}</div>
                                    {Object.keys(errorUserDatetime).map(item => {
                                        return (<div className={"messError"}>
                                            <div className={"fullName"}>{item}:</div>
                                            <div>{errorUserDatetime[item]}</div>
                                        </div>);
                                    })}
                                </div>
                                }

                                { errorUserNotExist.length > 0 &&
                                <div className={"userNotExist"}>
                                        <div className={"label"}>{t(headTrans + "errors.userNotExist")}</div>
                                        {errorUserNotExist.map((item, key) => {
                                            return (key > 0 ? ", " : "") +item;
                                        })}
                                    </div>
                                }
                            </Grid>
                            <Grid container item md={12}>
                                <RowFormColumn
                                    label={t(headTrans + "subject")}
                                    // className={"column2"}
                                    imgSrcLabel={<TitleIcon />}
                                    input={
                                        <CustomInput
                                            value={formValue.subject}
                                            onChange={(e) => this.handleChange(e.target.value, "subject")}
                                            validate={errors.subject}
                                        />
                                    }
                                    textValidate={errors.subject}

                                />
                            </Grid>
                            <Grid container item md={6}>
                                <RowFormColumn
                                    label={t(headTrans + "startRestAt")}
                                    className={"column1"}
                                    imgSrcLabel={<WatchLaterIcon />}
                                    input={
                                        <AppKeyboardDateTimePicker
                                            value={formValue.startRestAt}
                                            onChange={(value) => this.handleChange(value, "startRestAt")}
                                            // label={this.props.t("certificateMase.list.startDate")}
                                            emptyLabel={''}
                                            name={"startRestAt"}
                                            error={(errors.startRestAt)}
                                        />
                                    }
                                    textValidate={errors.startRestAt}
                                />
                            </Grid>
                            <Grid container item md={6}>
                                <RowFormColumn
                                    label={t(headTrans + "endRestAt")}
                                    imgSrcLabel={<WatchLaterIcon />}
                                    className={"column2"}
                                    input={
                                        <AppKeyboardDateTimePicker
                                            value={formValue.endRestAt}
                                            onChange={(value) => this.handleChange(value, "endRestAt")}
                                            // label={this.props.t("certificateMase.list.startDate")}
                                            emptyLabel={''}
                                            name={"endRestAt"}
                                            error={(errors.endRestAt)}
                                        />
                                    }
                                    textValidate={errors.endRestAt}

                                />
                            </Grid>

                            <Grid container item md={12}>
                                <RowFormColumn
                                    label={t(headTrans + "description")}
                                    imgSrcLabel={<MessageIcon />}
                                    className={classes.description}
                                    input={
                                        <CustomInput
                                            multiline={true}
                                            rowsMax={3}
                                            rows={3}
                                            value={formValue.description}
                                            onChange={(e) => this.handleChange(e.target.value,"description")}
                                        />
                                    }
                                    textValidate={errors.description}

                                />
                            </Grid>
                            <Grid container item md={12}>
                                <RowFormColumn
                                    label={t(headTrans + "staff")}
                                    className={classes.selectRepairerWrapper + (errors.user? " errorValidate":"")}
                                    input={
                                        <AppAutocomplete
                                            multiple
                                            value={formValue.user}
                                            options={userList}
                                            className={"appAutocomplete"}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => `${option.label}`}
                                            onChange={(e, newValue) => this.handleChange(newValue,"user")}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    <div className={classes.selectItemRepairerWrapper}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{marginRight: 8}}
                                                            checked={selected}
                                                        />
                                                        {option.value !== ALL_SELECT &&
                                                        <AppPersonAvatar
                                                            name={
                                                                <React.Fragment>
                                                                    <div>{option.label}</div>
                                                                </React.Fragment>
                                                            }
                                                            alt={option.label}
                                                            variant="rounded"
                                                            src={option.avatar && option.avatar.pathUrl ? option.avatar.pathUrl : ""}
                                                            avatarImgClassName={"avatarRepairer"}/>
                                                        }
                                                        <div className={"selectedValue"}>{option.label}</div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                            style={{ width: '100%' }}
                                            renderInput={(params) => (
                                                <TextField variant="outlined"
                                                           {...params}
                                                    endAdornment={
                                                        <img alt={""} src={searchIcon}/>
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    textValidate={errors.user}

                                />
                            </Grid>
                        </Grid>
                    }
                    saveButton={false}
                    cancelButton={false}
                    enableOnClose={false}
                />
                <AlertCustom
                    successOpen={this.state.successOpen}
                    errorOpen={this.state.errorOpen}
                    handleCloseNotice={()=>this.handleCloseNotice()}
                />
            </React.Fragment>

        );
    }
}

ModalRestday.propsType = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        problemRequest: state.applicationReducer.problemRequest,
        isLoading: state.applicationReducer.isLoading,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setProblemDescribe: (problemRequest) => dispatch((setProblemDescribe(problemRequest))),
        setIsLoading: (isLoading) => dispatch(application.setIsLoading(isLoading)),
        getDocumentRequestValid: () => dispatch((getDocumentRequestValid())),
    }
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation(), withStyles(styles))(ModalRestday);
