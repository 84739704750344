import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {NUNITO, OPENSANS} from "../../constants/fontCss";
import mailInfoIcon from "../../images/login/Groupe 9107.png"
import {LOGIN_PAGE, SIGN_UP, SM, XS} from "../../constants/constants";

const styles = theme => ({
    alertRegistrationSuccessBlock: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '& > div': {
            width: '100%',
            padding: '1.5rem',
            [theme.breakpoints.between(XS,SM)]: {
                margin: '1rem'
            },
            '&:last-child': {
                padding: 0,
                margin: '1.5rem',
                [theme.breakpoints.between(XS,SM)]: {
                    margin: '1rem'
                },
            }
        }
    },
    titlePage: {
        fontFamily: NUNITO,
        fontSize: 33,
        fontWeight: 500,
        letterSpacing: -0.33,
        lineHeight: 0.39,
        color: '#fff',
        textAlign: 'center'
    },
    message1: {
        fontFamily: OPENSANS,
        fontSize: 21,
        fontWeight: 600,
        letterSpacing: -0.21,
        lineHeight: 1.14,
        color: '#5affee',
        textAlign: 'center'
    },
    message2: {
        fontFamily: OPENSANS,
        fontSize: 15,
        fontWeight: 300,
        letterSpacing: -0.15,
        lineHeight: 1.27,
        color: '#fff',
        textAlign: 'center'
    },
    footerBlock: {
        background: '#78e7ff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.5rem',
        '& .iconWrapper': {
            width: 150,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.between(XS,SM)]: {
                width: 80
            },
        }
    },
    noteBlock: {
        width: 'calc( 100% - 150px)',
        padding: '1rem',
        borderLeft: '1px dotted #2c2cd0',
        [theme.breakpoints.between(XS,SM)]: {
            width: 'calc( 100% - 100px)',
            padding: '0.5rem',
        },
    },
    backToLogin: {
        display: 'flex',
        color: '#fff',
        padding: '0.4rem 1rem',
        border: '1px solid #fff',
        borderRadius: 33,
        margin: 'auto',
        width: 'fit-content',
        cursor: 'pointer',
        position: 'absolute',
        top: '18px',
        left: '28px',
        textDecoration: 'none',
        opacity: '0.6',
        '&:hover': {
            background: '#fff',
            color: '#0b0b9a',
            textDecoration: 'none',
            opacity: '1',
        },
        [theme.breakpoints.down(XS)]: {
            marginTop: '18px',
            marginLeft: '15px',
        },
    },
    returnRegistration:{
        color: '#fff',
        cursor: 'pointer',
        marginLeft: 28,
        padding: '1rem 0.5rem',
        fontWeight: 500,
        '&:hover': {
            opacity: '0.6',
        }

    }
});

class AlertRegistrationSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {t, classes, returnToSignUp, email, isResetPassword} = this.props;

        return (
            <div>
                {
                    !isResetPassword && <>
                   <span
                       className={classes.backToLogin}
                       onClick={(event) => this.props.backLogin(event, LOGIN_PAGE)}
                   >
                            &lt; {t('button.login')}
                        </span>
                        <div className={classes.returnRegistration}
                             onClick={(event) => this.props.backLogin(event, SIGN_UP)}>
                            &lt; {this.props.t('button.back')}
                        </div>
                    </>
                }
                <div className={classes.alertRegistrationSuccessBlock}>
                    {
                        !isResetPassword && <>
                            <div className={classes.titlePage} dangerouslySetInnerHTML={{
                                __html: t("successRegistrationUser.titlePage")
                            }}
                            />
                            <div className={classes.message1} dangerouslySetInnerHTML={{
                                __html: t("successRegistrationUser.message1", {email: email??""})
                            }}
                            />
                            <div className={classes.message2} dangerouslySetInnerHTML={{
                                __html: t("successRegistrationUser.message2")
                            }}
                            />
                        </>
                    }

                    <div className={classes.footerBlock}>
                        <div className={"iconWrapper"}>
                            <img src={mailInfoIcon} alt={""}/>
                        </div>
                        <div className={classes.noteBlock}>
                            <div dangerouslySetInnerHTML={{
                                __html: t("successRegistrationUser.noteTitle")
                            }}
                            />
                            <div dangerouslySetInnerHTML={{
                                __html: t("successRegistrationUser.note")
                            }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AlertRegistrationSuccess.defaultProps = {
    backLogin: (event) => {
    },
    email: ""
}

AlertRegistrationSuccess.propTypes = {
    classes: PropTypes.object.isRequired,
    backLogin: PropTypes.func,
    email: PropTypes.string
}
const mapStateToProps = (state) => {
    return {}
};
const mapDispatchToProps = (dispatch) => {
    return {}
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation()
)(AlertRegistrationSuccess);