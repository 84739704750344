import React from "react";
import axios from "axios";
import {
    ARRAY_PARAMS_REQUEST_MAIL_TYPE,
    FALSE_NUMBER,
    GO_DOC,
    // MAIL_TYPE_AFFECT_REPAIRER,
    MAIL_TYPE_APPOINTMENT_TIME_CHANGE_CUSTOMER,
    MAIL_TYPE_APPOINTMENT_TIME_CHANGE_REPAIRER,
    MAIL_TYPE_CONFIRM_REGISTRATION,
    MAIL_TYPE_FORGOT_PASSWORD,
    MAIL_TYPE_PAID_CUSTOMER,
    PARAMS_MAIL_TYPE_CONFIRM_REGISTRATION,
    PARAMS_MAIL_TYPE_FORGOT_PASSWORD,
    PARAMS_MAIL_TYPE_PAID_CUSTOMER,
    MAIL_TYPE_CONFIRM_APPOINTMENT_CUSTOMER_GO_FREE,
    // PARAMS_REQUEST_MAIL_TYPE_CANCEL_APPOINTMENT,
    PARAMS_REQUEST_MAIL_TYPE_GO_DOC,
    // PARAMS_REQUEST_MAIL_TYPE_GO_FREE,
    PARAMS_REQUEST_MAIL_TYPE_STATUS,
    PARAMS_REQUEST_MAIL_TYPE_STATUS_REPAIRED,
    PARAMS_TYPE_APPOINTMENT_TIME_CHANGE_CUSTOMER,
    PARAMS_TYPE_APPOINTMENT_TIME_CHANGE_REPAIRER,
    REPAIRED,
    REQUEST_MAIL_TYPE,
    STRING,
    TRUE_NUMBER,
    MAIL_TYPE_SEND_PDF_REPORT_CUSTOMER,
    PARAMS_MAIL_TYPE_SEND_PDF_REPORT_CUSTOMER,
    ARRAY_PARAMS_SMS_SEND_UPLOAD_FILE_REQUEST_URL, SMS_SEND_UPLOAD_FILE_REQUEST_URL
} from "../../../constants/constants";
import CustomInput from "../../../theme/CustomElements/CustomInput";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import RowFormColumn from "../../../theme/form/RowFormColumn";
import CustomButton from "../../../theme/CustomElements/CustomButton";
import SaveIcon from '@material-ui/icons/Save';
import {Redirect} from "react-router";
import AppCKEditor from "../../../theme/CustomElements/AppCKEditor";
import {Switch, Tooltip} from "@material-ui/core";
import AppAsyncPaginate from "../../../theme/form/Select/AppAsyncPaginate";
import {
    API_EDIT_MAIL_TYPE,
    API_GET_MAIL_TYPE, API_GET_SMS_TYPE,
    API_GET_STATUS_TYPE, API_PUT_SMS_TYPE
} from "../../../constants/apiSuffix";
import qs from "qs";
import {getParamInUrl} from "../../../functions/functions";
import {errorsMessMailType} from "../../../functions/checkErrorEmpty";
import {checkSubmitSendDataERROR} from "../../../functions/checkCondition";
import {MAIL_TYPE, REQUEST_MAIL_TYPE as REQUEST_MAIL_TYPE_LINK, SMS_TYPE_URL} from "../linkRectAdmin";
import AlertCustom from "../../../theme/form/Alert/AlertCustom";
import LoadingAction from "../../../theme/LoadingAction";
import {connect} from "react-redux";
import {SET_TIME_OUT_ALERT_SUCCESS} from "../ReactAdmin";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

const styles = {
    btnResponse:{
        background: '#0000ff !important'
    },
    ckEditorWrapper:{
        '& .tagParamBody': {
            border: '1px solid',
            /* padding: 0.5rem; */
            borderRadius: 6,
            fontSize: '0.75rem',
            padding: '0.5rem',
            display: 'flex',
            color: '#5151d2',
            cursor: 'pointer',
            background: '#f7f3f3',
            transition: '0.2s',
            fontWeight: 700,
            margin: '0.25rem'
        },
        '& .ck-content': {
            height: 200,
            '& p':{
                margin: 0
            }
        }
    },
    mailTags:{
        padding: '0.5rem 0',
        display: 'flex',
        flexFlow: 'row wrap',
    },
    textareaForm:{
        width: '100%',
        border: '1px solid #E0E0E0',
        borderRadius: 6,
        padding: '8px 10px',
    }
};
const headLabel = "reactAdmin.mailType.";
const RELATION_SHIP = "RELATION_SHIP";
const CKEDITOR = "CKEDITOR";
const SWITCH = "SWITCH";
const TEXTAREA = "TEXTAREA";

const arrForm = [
    {
        name: "enabled",
        type: SWITCH,
        label: `${headLabel}enabled`
    },
    {
        name: "name",
        type: STRING,
        label: `${headLabel}name`
    },
    {
        name: "subject",
        type: STRING,
        label: `${headLabel}subject`
    },
    {
        name: "statusType",
        type: STRING,
        label: `${headLabel}requestStatusType`
    },

    {
        name: "body",
        type: CKEDITOR,
        label: `${headLabel}body`
    },
    {
        name: "content",
        type: TEXTAREA,
        label: `reactAdmin.smsType.content`
    }

]

const MAIL_CONTENT_CLICK = 'MAIL_CONTENT_CLICK';
const MAIL_CONTENT_SELECT = 'MAIL_CONTENT_SELECT';

const formValueDefault = {
    subject:"",
    body: "",
    name:"",
    statusType: null,
    mailType:"",
    enabled: true,
    content: ""
}
class MailTypeAndSmsTypeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formValue: {...formValueDefault},
            errors: {},
            redirectUrl: "",
            isLoading: false,
            initialContent: "",
            mailVariables: '',
            cursorPosition: null,
            textBeforeCursorPosition: null,
            textAfterCursorPosition: null,
            editorData: null,
            contentFirstPosition: null,
            contentLastPosition: null,
            mailActionType: null,
            selectedTextStart: null,
            selectedTextEnd: null,
            tagParamsBodyMail: [],
            errorOpen: false,
            successOpen: false
        };
        this.contentInputRef = React.createRef();

    }
    handleCloseNotice = () => {
        this.setState({
            successOpen: false,
            errorOpen: false
        })
    }

    handleChange = (value, field) => {
        let {formValue, errors} = this.state;
        if(errors.hasOwnProperty(field)){
            delete (errors[field]);
        }
        formValue[field] = value;
        this.setState({formValue: formValue, errors:errors})
    }


    componentDidMount() {
        let {formValue} = this.state;
        const {basePath} = this.props;
        this.setState({isLoading: true})

        if(basePath === SMS_TYPE_URL) {
            axios.get(API_GET_SMS_TYPE.replace("{id}",this.props.match.params.id))
                .then(response =>{
                    if(response.status === 200) {
                        const data = response.data;
                        let tagParamsBodyMail = [];
                        switch (data.code) {
                            case SMS_SEND_UPLOAD_FILE_REQUEST_URL:
                                tagParamsBodyMail = ARRAY_PARAMS_SMS_SEND_UPLOAD_FILE_REQUEST_URL;
                                break;
                        }

                        formValue = {
                            name: data.name ?? "",
                            content: data.content ?? "",
                            enabled: data.enabled
                        };
                        this.setState({formValue: formValue,isLoading: false, tagParamsBodyMail:tagParamsBodyMail})
                    }else{
                        this.setState({isLoading: false})
                    }

                })
                .catch(err=>{
                    this.setState({isLoading: false})
                })
        }else{
            axios.get(API_GET_MAIL_TYPE.replace("{id}",this.props.match.params.id))
                .then(response =>{
                    if(response.status === 200) {
                        const data = response.data.item;
                        let tagParamsBodyMail = [];
                        if(data.mailType === REQUEST_MAIL_TYPE){
                            if (data.statusType && data.statusType.code) {
                                switch (data.statusType.code) {
                                    case REPAIRED:
                                        tagParamsBodyMail = PARAMS_REQUEST_MAIL_TYPE_STATUS_REPAIRED
                                        break;
                                    case GO_DOC:
                                        tagParamsBodyMail = PARAMS_REQUEST_MAIL_TYPE_GO_DOC;
                                        break;
                                    default:
                                        tagParamsBodyMail = PARAMS_REQUEST_MAIL_TYPE_STATUS;
                                        break;
                                }
                            } else {
                                switch (data.code) {
                                    case MAIL_TYPE_APPOINTMENT_TIME_CHANGE_CUSTOMER:
                                        tagParamsBodyMail = PARAMS_TYPE_APPOINTMENT_TIME_CHANGE_CUSTOMER;
                                        break;
                                    case MAIL_TYPE_APPOINTMENT_TIME_CHANGE_REPAIRER:
                                        tagParamsBodyMail = PARAMS_TYPE_APPOINTMENT_TIME_CHANGE_REPAIRER;
                                        break;
                                    case MAIL_TYPE_PAID_CUSTOMER:
                                    case MAIL_TYPE_CONFIRM_APPOINTMENT_CUSTOMER_GO_FREE:
                                        tagParamsBodyMail = PARAMS_MAIL_TYPE_PAID_CUSTOMER;
                                        break;
                                    case MAIL_TYPE_SEND_PDF_REPORT_CUSTOMER:
                                        tagParamsBodyMail = PARAMS_MAIL_TYPE_SEND_PDF_REPORT_CUSTOMER;
                                        break;

                                    default:
                                        tagParamsBodyMail = ARRAY_PARAMS_REQUEST_MAIL_TYPE;
                                        break;
                                }
                            }
                        }else if(data.code === MAIL_TYPE_FORGOT_PASSWORD){
                            tagParamsBodyMail = PARAMS_MAIL_TYPE_FORGOT_PASSWORD
                        }else if(data.code === MAIL_TYPE_CONFIRM_REGISTRATION){
                            tagParamsBodyMail = PARAMS_MAIL_TYPE_CONFIRM_REGISTRATION
                        }
                        formValue = {
                            subject: data.subject??"",
                            body: data.body??"",
                            name: data.name??"",
                            statusType: data.statusType?data.statusType.name:null,
                            mailType: data.mailType,
                            enabled: data.enabled
                        };
                        this.setState({formValue: formValue,isLoading: false, tagParamsBodyMail:tagParamsBodyMail})
                    }else{
                        this.setState({isLoading: false})
                    }

                })
                .catch(err=>{
                    this.setState({isLoading: false})
                })
        }

    }

    handleContentBlur = (event) => {
        this.setState({
            contentFirstPosition: event.target.selectionStart,
            contentLastPosition: event.target.selectionEnd,
            mailActionType: event.target.selectionStart === event.target.selectionEnd ? MAIL_CONTENT_CLICK : MAIL_CONTENT_SELECT,
        })
    }

    async loadStatusTypeOptions(search, loadedOptions, {page}) {
        let params = {search: search, page: page};
        params = {...params, group: 'showSelect', employee: 1};
        const url = API_GET_STATUS_TYPE + "?" + qs.stringify(params)
        const response = await axios.get(url);
        const data = response.data;
        let dataItems = [];
        let hasMore = false;
        if (data.hasOwnProperty('items') && data.items) {
            dataItems = data.items;
            hasMore = data.currentPage < data.nbPages;
        } else if (data.hasOwnProperty('hydra:member')) {
            dataItems = data["hydra:member"];
            const hydraView = data["hydra:view"];
            const paramsCurrent = getParamInUrl(hydraView["@id"]);
            const paramsPageLast = getParamInUrl(hydraView["hydra:last"]);
            const currentPageList = paramsCurrent.hasOwnProperty("page") ? paramsCurrent.page : 1;
            const nbPagesList = paramsPageLast.hasOwnProperty("page") ? paramsPageLast.page : 1;
            hasMore = currentPageList < nbPagesList;

        }
        const options = dataItems.map((item) => {
            return {label: item.name, value: item.id, ...item};
        });

        return {
            options: options,
            hasMore: hasMore,
            additional: {
                page: page + 1,
            },
        };
    }


    handleSave = (e) =>{
        const {t, match, basePath} = this.props;
        let {errors, formValue}= this.state;
        for (let [key, value] of Object.entries(formValue)) {
            if (errorsMessMailType.hasOwnProperty(key)) {
                errors = checkSubmitSendDataERROR(value, errorsMessMailType[key].type, errors, key, errorsMessMailType[key].errorByField, t)
            }
        }
        if (Object.keys(errors) < 1) {
            this.setState({
                isLoading: true
            })
            const userId = match.params.id;
            if(basePath === SMS_TYPE_URL) {
                if(!formValue.content){
                    this.setState({
                        isLoading: false,
                        errors: {content: t('form.error.NOT_NULL')},
                        errorOpen: true
                    })
                }else {
                    axios.put(API_PUT_SMS_TYPE.replace("{id}", userId), {
                        content: formValue.content,
                        name: formValue.name,
                        enabled: formValue.enabled
                    })
                        .then(response => {
                            if (response.status === 200) {
                                this.setState({
                                    successOpen: true
                                })
                                setTimeout(function () {
                                    this.setState({
                                        redirectUrl: SMS_TYPE_URL
                                    })
                                }.bind(this), SET_TIME_OUT_ALERT_SUCCESS);
                            } else {
                                throw new Error();
                            }
                        })
                        .catch(error => {
                            this.setState({
                                isLoading: false,
                                errorOpen: true
                            })
                        });
                }
            }else {
                let routerApiName = API_EDIT_MAIL_TYPE.replace("{id}", userId);

                const formData = new FormData();

                Object.keys(formValue).forEach((key, index) => {
                        switch (key) {
                            case 'name':
                            case 'subject':
                            case 'body':
                                formData.append("mailType[" + key + "]", formValue[key]);
                                break;
                            // case 'statusType':
                            //     if (formValue.mailType === REQUEST_MAIL_TYPE) {
                            //         formData.append("mailType["+key+"]", formValue[key].value);
                            //     }
                            //     break;
                            case "enabled":
                                formData.append("mailType[" + key + "]", formValue[key] ? TRUE_NUMBER : FALSE_NUMBER);
                                break;
                            default:
                                // formData.append(key, Object.values(data)[index]);
                                break;
                        }
                    }
                );

                axios.post(routerApiName, formData)
                    .then(response => {
                        if (response.status === 200) {
                            // formValue.changePasswordForUser = false;
                            this.setState({
                                // formValue:formValue,
                                successOpen: true
                            })
                            setTimeout(function () {
                                this.setState({
                                    redirectUrl: (formValue.mailType === REQUEST_MAIL_TYPE) ? REQUEST_MAIL_TYPE_LINK : MAIL_TYPE,
                                })
                            }.bind(this), SET_TIME_OUT_ALERT_SUCCESS);
                        } else {
                            throw new Error();
                        }
                    })
                    .catch(error => {
                        this.setState({
                            isLoading: false,
                            errorOpen: true
                        })
                    });
            }
        } else {
            this.setState({
                isLoading: false,
                errors: errors,
                errorOpen: true
            })
        }
    }
    selectTag = (event, valueItem) =>{
        let {contentFirstPosition, contentLastPosition, editorData, mailActionType} = this.state;
        let textToInsert = valueItem ? valueItem : event.target.value;
        switch (mailActionType) {
            case MAIL_CONTENT_CLICK:
                if (contentFirstPosition !== null) {
                    editorData.model.change( writer => {
                        writer.insertText(textToInsert, contentFirstPosition);
                        editorData.editing.view.focus();
                    });
                }
                break;
            case MAIL_CONTENT_SELECT:
                if (contentFirstPosition !== null && contentLastPosition !== null) {
                    editorData.model.change( writer => {
                        const range = writer.createRange( contentFirstPosition, contentLastPosition );
                        writer.remove(range);
                        writer.insertText(textToInsert, contentFirstPosition);
                        editorData.editing.view.focus();
                    });
                }
                break;
            default:
                break;
        }

        this.setState({
            editorData: editorData,
        });
    }
    
    selectTextAreaTags  = (event, valueItem) =>{
        let {contentFirstPosition, contentLastPosition, formValue, mailActionType} = this.state;
        let textToInsert = valueItem ? valueItem : event.target.value;
        let content = formValue.content
        let updateState = {};

        switch (mailActionType) {
            case MAIL_CONTENT_CLICK:
                if (contentFirstPosition !== null) {
                    let str1 = content.substr(0, contentFirstPosition);
                    let str2 = content.substr(contentFirstPosition);
                    updateState = {
                        ...updateState,
                        contentFirstPosition: contentFirstPosition + textToInsert.length,
                        contentLastPosition: contentFirstPosition + textToInsert.length,
                        formValue: {...formValue, content: str1 + textToInsert + str2}
                    }
                }
                break;
            case MAIL_CONTENT_SELECT:
                if (contentFirstPosition !== null && contentLastPosition !== null) {
                    let string1 = content.substring(0, contentFirstPosition);
                    let string2 = content.substring(contentLastPosition, content.length);
                    updateState = {
                        ...updateState,
                        contentFirstPosition: contentFirstPosition + textToInsert.length,
                        contentLastPosition: contentFirstPosition + textToInsert.length,
                        formValue: {...formValue, content: string1 + textToInsert + string2}

                    }
                }
                break;
        }
        this.setState(updateState);
    }

    handleBodyChange = (editor, field) =>{
        let {formValue,errors} = this.state;
        formValue[field] = editor.getData();
        if(errors.hasOwnProperty(field)){
            delete (errors[field]);
        }
        this.setState({
            formValue: formValue,
            errors: errors
        });
    }

    handleBodyBlur=(event, editor) =>{
        const stickiness = editor.model.document.selection.getFirstPosition().stickiness;
        this.setState({
            editorData: editor,
            contentFirstPosition: editor.model.document.selection.getFirstPosition(),
            contentLastPosition: editor.model.document.selection.getLastPosition(),
            mailActionType: stickiness === 'toNone' ? MAIL_CONTENT_CLICK : MAIL_CONTENT_SELECT,
        })
    }

    render() {
        const {formValue,errors, redirectUrl, tagParamsBodyMail, isLoading}= this.state;
        const  { t, classes, basePath} = this.props
        if(redirectUrl){
            return  <Redirect to={redirectUrl}/>;
        }
        let newArrayForm = basePath === SMS_TYPE_URL ? [...arrForm].filter(itemFilter => ["name", "content", "enabled"].includes(itemFilter.name)) :
            [...arrForm].filter(itemFilter => !["content"].includes(itemFilter.name))
        return (
            <div>
                {newArrayForm.map((item, key) => {
                        switch (item.type) {
                            case STRING:
                                    return <RowFormColumn
                                        key={key}
                                        label={t(item.label)}
                                        input={<CustomInput
                                            name={item.name}
                                            value={formValue[item.name] ?? ""}
                                            onChange={(e) => this.handleChange(e.target.value, item.name)}
                                            validate={errors[item.name]}
                                            disabled={item.name==="statusType"}
                                        />}
                                        textValidate={errors[item.name]}
                                    />
                            case CKEDITOR:
                                return <RowFormColumn
                                    key={key}
                                    label={t(item.label)}
                                    input={
                                        <div className={classes.ckEditorWrapper}>
                                        {tagParamsBodyMail && Array.isArray(tagParamsBodyMail) && tagParamsBodyMail.length > 0 ?
                                        <div className={classes.mailTags}>
                                            {tagParamsBodyMail.map((item, indexTag) => {
                                                    return <React.Fragment>
                                                        <Tooltip
                                                            key={key+indexTag+10}
                                                            title={t('reactAdmin.mailType.variables.' + item.replace(/%/g, ""))}
                                                            classes={{
                                                                tooltip: classes.customTooltip,
                                                                arrow: classes.customArrow
                                                            }}
                                                            arrow
                                                            interactive
                                                            placement={"top"}
                                                        >
                                                            <div className={"tagParamBody"}
                                                                 onClick={(event) => this.selectTag(event, item)}
                                                                >
                                                                {item}
                                                            </div>
                                                        </Tooltip>
                                                    </React.Fragment>
                                                }
                                            )}
                                        </div>: ""}
                                        <AppCKEditor
                                            data={formValue[item.name] ?? ""}
                                            onChange={(event, editor) => this.handleBodyChange(editor, item.name)}
                                            config={
                                                {
                                                    // extraPlugins : 'placeholder',
                                                    // placeholder: 'Type the content here!',
                                                    toolbar: ['Heading', 'Link', 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', 'CreatePlaceholder',  'fontColor', 'fontBackgroundColor', 'undo', 'redo'],

                                                }
                                            }
                                            onBlur={this.handleBodyBlur}
                                        /></div>}
                                    textValidate={errors[item.name]}
                                />
                            case RELATION_SHIP:
                                if(formValue.mailType ===  REQUEST_MAIL_TYPE && formValue.statusType) {
                                    return <RowFormColumn
                                        key={key}
                                        label={t(item.label)}
                                        input={<AppAsyncPaginate
                                            classNamePrefix={"selectPaginate"}
                                            value={formValue[item.name]}
                                            loadOptions={(search, loadedOptions, {page}) => this.loadStatusTypeOptions(search, loadedOptions, {page})}
                                            onChange={(value) => this.handleChange(value, item.name)}
                                            debounceTimeout={500}
                                            additional={{
                                                page: 1
                                            }}
                                            // closeMenuOnSelect={false}
                                        />}
                                        textValidate={errors[item.name]}
                                    />
                                }else{
                                    return  ""
                                }
                            case SWITCH:
                                return <RowFormColumn
                                    key={key}
                                    label={t(item.label)}
                                    input={<Switch
                                        checked={formValue[item.name]}
                                        onChange={(e) => this.handleChange(e.target.checked, item.name)}
                                        name={item.name}
                                    />
                                    }
                                    />;
                            case TEXTAREA:
                                return <RowFormColumn
                                    key={key}
                                    label={t(item.label)}
                                    input={<div className={classes.ckEditorWrapper}>
                                        {tagParamsBodyMail && Array.isArray(tagParamsBodyMail) && tagParamsBodyMail.length > 0 ?
                                            <div className={classes.mailTags}>
                                                {tagParamsBodyMail.map((item, indexTag) => {
                                                        return <React.Fragment>
                                                            <Tooltip
                                                                key={key + indexTag + 10}
                                                                title={t('reactAdmin.smsType.variables.' + item.replace(/%/g, ""))}
                                                                classes={{
                                                                    tooltip: classes.customTooltip,
                                                                    arrow: classes.customArrow
                                                                }}
                                                                arrow
                                                                interactive
                                                                placement={"top"}
                                                            >
                                                                <div className={"tagParamBody"}
                                                                     onClick={(event) => this.selectTextAreaTags(event, item)}
                                                                >
                                                                    {item}
                                                                </div>
                                                            </Tooltip>
                                                        </React.Fragment>
                                                    }
                                                )}
                                            </div> : ""}
                                        <TextareaAutosize className={classes.textareaForm}
                                                          aria-label="text area"
                                                          rowsMin={10}
                                                          rowsMax={10}
                                                          value={formValue[item.name]}
                                                          onChange={(e) => this.handleChange(e.target.value, item.name)}
                                                          onBlur={this.handleContentBlur}
                                                          ref={this.contentInputRef}

                                        />
                                    </div>
                                    }
                                    textValidate={errors[item.name]}
                                />;
                            default:
                                return "";
                        }
                })
                }
                <CustomButton
                    className={classes.btnResponse}
                    title={this.props.t("button.save")}
                    buttonProps={{
                        startIcon: <React.Fragment><SaveIcon className={"iconAdd"} /></React.Fragment>
                    }}
                    onClick={this.handleSave}/>
                <AlertCustom
                    successOpen={this.state.successOpen}
                    errorOpen={this.state.errorOpen}
                    handleCloseNotice={()=>this.handleCloseNotice()}
                />
                {isLoading && <LoadingAction />}
            </div>
        )
    }
}

// MailTypeAndSmsTypeForm.propTypes = {
//     classes: PropTypes.object.isRequired,
//     t: PropTypes.func.isRequired
// };

const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter
) (MailTypeAndSmsTypeForm);
