import React, {Component} from 'react';
import {compose} from "redux";
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import paymentBG from '../../../images/payment/payment.png'
import logo from '../../../images/payment/logoBlicko.svg'
import {MONTSERRAT} from "../../../constants/fontCss";
import {
    PAYMENT_CARD,
    PAYMENT_TAB_LYDIA,
    PAYMENT_TAB_MASTER_CARD, PAYMENT_TAB_PAYPAL,
    PAYMENT_TAB_VISA,
    paymentArray
} from "../../../constants/paymentMethod";
import PropTypes from "prop-types";
import {
    BASE_URL, CURRENCY_LOCAL,
    MD,
    PAYMENT_CARD_MASTERCARD, PAYMENT_CARD_VISA,
    SM, XS
} from "../../../constants/constants";
import moment from "moment";
import SogeCommercePayment from "./payment/SogeCommercePayment";
import PaypalPayment from "./payment/PaypalPayment";
import axios from "axios";
import {API_PAYMENT_REQUEST} from "../../../constants/apiSuffix";
import LydiaPayment from "./payment/LydiaPayment";
import {Button, Tooltip} from "@material-ui/core";
import LoadingAction from "../../../theme/LoadingAction";
import parse from "html-react-parser";
import * as applicationActions from "../../../_actions/application";
import * as applicationAction from "../../../_actions/application";
import {connect} from "react-redux";
import {NavLink, withRouter} from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInlineSvg from "../../../theme/CustomElements/CustomInlineSvg";
import checked from "../../../images/payment/checked.svg";
import * as links from "../../../constants/links";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
const RequestProblemTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#F5F5F5',
        color: 'rgba(0, 0, 0, 0.87)',
        borderRadius: 11,
        fontSize: 13,
        maxHeight: 400,
        overflow: 'auto',
    },
}))(Tooltip);
const styles = theme => ({
    payment: {
        width: '84%',
        margin: 'auto',
        fontFamily: `${MONTSERRAT}`,
        fontSize: '0.875rem',
        display:'flex',
        marginBottom: 40,
        height: '100%',
        minHeight: '380px',
        [theme.breakpoints.down(XS)]:{
            width: '100%',
            flexDirection: 'column'
        },
    },
    left :{
        borderRadius: '16px 0 0 16px',
        backgroundImage: `url(${paymentBG})`,
        backgroundSize: 'cover',
        height: '100%',
        position: 'relative',
        zIndex: 0,
        color:'#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '40%',
        '&::before':{
            content:'""',
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: 'linear-gradient(rgba(14, 56, 96, 0) 21.5%, #0E2F60 78.76%, #0A0730 131.14%)',
            zIndex: 1,
            borderRadius: '16px 0 0 16px',
        },
        '& .payment_left':{
            zIndex: 2,
            padding:'20px 0 20px 25px',
            '& .payment_amount':{
                fontSize: '1.5rem',
                fontWeight: 700,
                lineHeight: '24px',
                marginTop: 5,
                display: 'inline-block'
            },
            '& .payment_phoneNumber':{
                fontWeight: 700
            }
        },
        [theme.breakpoints.up(MD)]:{
            width: '30%'
        },
        '& .requestProblemHtml': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            wordBreak: 'break-all',
            marginBottom: 10
        },
        [theme.breakpoints.down(XS)]:{
            width: '100%',
            height: '45%',
            borderRadius: '16px 16px 0 0',
            '& .payment_left':{
                padding: '0.5rem 1rem',
            }
        }
    },
    right :{
        marginLeft: 20,
        width: '70%',
        display: 'flex',
        flexDirection: 'column',
        // width: 500,
        '& .message': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            '& .linkHome': {
                textDecoration: 'none',
                marginTop: 10
            },
            '& .btnToHome': {
                color: '#fff',
                backgroundColor: '#0000ff',
                textDecoration: 'none',
                textTransform: 'initial',
                fontWeight: 600,
                padding: '8px 16px'
            }
        },
        '& .paidText': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '1.65rem',
            fontWeight: 600,
            '& .text': {
                paddingLeft: 10
            }
        },
        '& .payment_method':{
            marginTop: 20,
        },
        '& .payment_body': {
            flexGrow: 1,
            padding: 8,
            overflow: 'hidden',
            '& .paramLabel': {
                color: 'red!important'
            }
        },
        '& iframe': {
            width: '100%',
            height: '100%',
            border: 'none'
        },
        '& .payment_title': {
            fontWeight: 700,
            color: '#001034',
            fontSize: '0.875rem'
        },
        '& .payment_list_method': {
            display: 'flex',
            // justifyContent: 'space-between',
            marginTop: 7,
            overflowX: 'auto'
        },
        '& .payment_element': {
            height: 54,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            margin: '0 4px',
            border:'1px solid #BDBDBD',
            borderRadius: 10,
            '& img': {
                margin: '6px 18px',
            },
        },
        '& .not_lydia':{
            border:'1px solid #BDBDBD',
            borderRadius: 10,
            '& img': {
                margin: '6px 18px',
                width: '55px'
            },
        },
        '& .paymentSelected':{
            border:'2px solid #0000FF',
            borderRadius: 8,
        },
        '& .payment_detail':{
            display: 'flex',
            justifyContent:'space-between',
            '& input':{
                width: '100%',
                height: 40,
                border: '1px solid #E0E0E0',
                borderRadius: 6,
                marginTop: 10,
                fontFamily: `${MONTSERRAT}`,
                paddingLeft: 9
            },
            '& .payment_detail_left':{
                width: '60%',
                marginRight: 10
            },
            '& .payment_detail_right':{
                width: '40%',
                marginLeft: 10
            },
        },
        [theme.breakpoints.down(SM)]:{
            width: '60%',
            '& .payment_list_method':{
                flexWrap: 'wrap'
            },
            '& .not_lydia':{
                '& img':{
                    width: '50px'
                }
            },
            '& .payment_element:first-child':{
                width: '100px'
            },
        },
        [theme.breakpoints.down(XS)]:{
            width: '100%',
            marginLeft: 0,
            '& .paypal-buttons ':{
                minWidth: '100%!important'
            },
            '& .not_lydia':{
                width: '30%!important',
                justifyContent: 'center',
                '& img':{
                    margin: '0!important'
                }
            },
            '& .payment_element':{
                width: '30%!important',
            }
        }
    },
    errorField:{
        color: 'red',
        // position: 'relative',
        '& .payment_title': {
            color: 'red',
        },
        '& input':{
            border: '1px solid red!important',
            '&:focus':{
                outlineColor: 'red'
            }
        },
        '& .errors':{
            // position: 'absolute',
            fontSize: '0.8rem',
            // bottom: -20,
            // left: 0,

        }
    },
    btn_confirm:{
        height: 50,
        background: '#0000FF',
        borderRadius: 15,
        width: 240,
        color: '#fff',
        fontWeight: 700,
        outline: 'none',
        border: 'none',
        fontFamily: `${MONTSERRAT}`,
        display: 'block',
        marginLeft: 'auto',
        marginTop: 20
    },
    paymentBtn: {
        '& button': {
            textTransform: 'initial',
            fontWeight: 600,
            fontSize: '1.2rem',
            padding: "4px 16px",
            border: '2px solid'
        }
    }
})

class Payment extends Component {
    constructor(props) {
        super(props);
        this.state={
            formPayment:{
                owner:'',
                expirationDate: '',
                cardNumber: '',
                verificationCode: ''
            },
            errors: {},
            cursorEnd: 0,
            deleteKeyboard: false,
            methodPayment: "",
            loadingMethod: false,
            paymentPrice: props.paymentPrice,
            paymentCurrency: props.paymentCurrency,
            isLoading: false,
            paymentRequest: this.props.paymentRequest
        }
        this.cursorEnd = React.createRef();
        this.cardNumber =  React.createRef();
        this.updateDataRequest = this.updateDataRequest.bind(this);
        // localStorage.setItem(CURRENCY_LOCAL, props.paymentCurrency);
    }


    handleSubmit = (event) => {
        event.preventDefault();
        let {formPayment} = this.state
        let errors = {}
        for (let item in formPayment){
            errors = this.checkInfo(item,formPayment[item])
        }
        if(JSON.stringify(errors)!=='{}'){
            this.setState({
                errors: errors
            })
        }

        const formData = new FormData();
    }
    checkInfo = (infoType, value) => {
        let {errors} = this.state
        const {t} = this.props
        let letters = /^[a-zA-Z ]*$/;
        if (!value.length){
            errors[infoType] = t("information.formTab3.error.fieldNull", {field: t(`information.formTab3.error.${infoType}`)})
        }
        if (infoType==='owner' && (!value.match(letters) | (value.length && value.trim().split("").length === 0))) {
            errors[infoType] = t("information.formTab3.error.ownerError")
        }
        if (infoType==='expirationDate'){
            if(value.trim().split("").length === 0){
                errors[infoType] = t("information.formTab3.error.fieldNull", {field: t(`information.formTab3.error.${infoType}`)})
            } else {
                let valueArr = this.checkExpirationDate(value)
                let {month, year} = valueArr
                if(!(month>0 && month<=12) || year.length<2) {
                    errors[infoType] = t("information.formTab3.error.invalidDate")
                } else {
                    let expirationDate=moment(month+"/20"+year,"DD-YYYY")
                    let today = moment(moment().month()+'/'+moment().year(), "DD-YYYY")
                    if(expirationDate.diff(today)<0){
                        errors[infoType] = t("information.formTab3.error.futureDate")
                    }
                }
            }
        }
        if(infoType==='cardNumber'){
            value = value.replace(/[^0-9]/g,"").split('').map(Number)
            value = value.map((digit, index)=> index % 2 ===  value.length % 2 ? (digit * 2 > 9 ? digit * 2 -9 : digit * 2) : digit)
            let check = value.reduce((acc, digit)=>acc+=digit,0)
            if(check%10!==0){
                errors[infoType] = t("information.formTab3.error.invalidCard")
            }
        }
        return errors

    }
    checkExpirationDate = (value)=>{
        value = value.replace(/[^0-9]/g,"")
        let month, year
        month = value.substr(0,2)
        year = value.substr(2,2)
        return {month: month, year: year}
    }
    getValueCardNumber = (value, cursorEnd, deleteKeyboard) => {
        if(value.length>=4){
            for(let index in value){
                index = parseInt(index)
                if(index === 4){
                    value = value.substring(0,index)+' '+value.substring(index)
                    if(cursorEnd === 5 && !deleteKeyboard) cursorEnd = cursorEnd+1
                }
                if(index === 8){
                    value = value.substring(0,9)+' '+value.substring(9)
                    if(cursorEnd === 10 && !deleteKeyboard) cursorEnd = cursorEnd+1
                }
                if(index === 12){
                    value = value.substring(0,14)+' '+value.substring(14)
                    if(cursorEnd === 15 && !deleteKeyboard) cursorEnd = cursorEnd+1
                }
            }
        }
        return {value:value, cursorEnd:cursorEnd}
    }
    handleChange = e => {
        let {name, value} = e.target
        let {errors, deleteKeyboard} = this.state
        let cursorEnd = e.target.selectionEnd
        delete errors[name]
        if (name === 'expirationDate'){
            value=value.replace(/[^0-9]/g,"")
            let valueArr = this.checkExpirationDate(value)
            let {month, year} = valueArr
            if(month.length === 2 ){
                month = month + '/'
                if(cursorEnd === 2){
                    if(deleteKeyboard){
                        if(year.length){
                            month = month.replace(month[1], year[0])
                            year = year.substr(1)
                        } else month = month[0]
                        cursorEnd=1
                    } else  cursorEnd=cursorEnd+1
                }
            }
            value = month+year
            this.cursorEnd.current.value = value
            this.cursorEnd.current.setSelectionRange(cursorEnd,cursorEnd)
        }
        if (name === 'cardNumber'){
            value=value.replace(/[^0-9]/g,"").substring(0,16)
            let check = this.getValueCardNumber(value, cursorEnd, deleteKeyboard)
            value = check.value
            cursorEnd = check.cursorEnd
            this.cardNumber.current.value = value
            this.cardNumber.current.setSelectionRange(cursorEnd,cursorEnd)
        }
        if (name === 'verificationCode'){
            value=value.replace(/[^0-9]/g,"")
        }
        this.setState({
            formPayment:{
                ...this.state.formPayment,
                [name] : value
            },
            errors: errors,
            cursorEnd: cursorEnd,
            // deleteKeyboard: false
        })
    }
    setKeyDown = (e) =>{
        let {deleteKeyboard} = this.state
        if(e.keyCode === 8){
            deleteKeyboard = true
        } else deleteKeyboard = false
        this.setState({
            deleteKeyboard: deleteKeyboard
        })
    }
    getMethodPayment = (event) => {
        this.setState({
            methodPayment : event,
            errors: {},
            loadingMethod: true
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let {formPayment, cursorEnd, deleteKeyboard} = this.state
        let {expirationDate} =this.state.formPayment
        if(formPayment!==prevState.formPayment && this.deleteKeyboard){
            this.setState({
                deleteKeyboard : false
            })
        }
        if (this.state.loadingMethod) {
            this.setState({
                loadingMethod: false
            })
        }

        if (this.state.paymentRequest !== this.props.paymentRequest) {
            this.setState({
                paymentRequest: this.props.paymentRequest
            })
        }
    }

    paymentRequest() {
        this.setState({
            isLoading: true
        })
        axios.post(BASE_URL + API_PAYMENT_REQUEST.replace('{id}', this.props.requestId), {
            'token':this.props.token
        })
            .then(res => {
                if (res.status === 200) {
                    this.props.updateDataRequest(res.data.item);
                    this.setState({
                        isLoading: false
                    })
                } else {
                    throw new Error();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
            })
    }

    updateDataRequest(dataPayment) {
        this.props.updateDataRequest(dataPayment);
    }

    showPaymentForm() {
        const {
            methodPayment,
            paymentPrice,
            paymentCurrency
        } = this.state;
        const {
            classes
        } = this.props;
        // return <div className={classes.paymentBtn}>
        //     <Button
        //         onClick={() => this.paymentRequest()}
        //     >
        //         {this.props.t("payment.payment_btn")}
        //     </Button>
        // </div>;
        switch (methodPayment) {
            case PAYMENT_TAB_LYDIA:
               return  <LydiaPayment
                   updateDataRequest={this.updateDataRequest}
                   requestId={this.props.requestId}
               />;
            case PAYMENT_CARD:
                return <SogeCommercePayment
                    paymentPrice={paymentPrice}
                    paymentCurrency={paymentCurrency}
                    updateDataRequest={this.updateDataRequest}
                    requestId={this.props.requestId}
                />;
            case PAYMENT_TAB_MASTER_CARD:
                return <SogeCommercePayment
                    paymentCard={PAYMENT_CARD_MASTERCARD}
                    paymentPrice={paymentPrice}
                    paymentCurrency={paymentCurrency}
                    updateDataRequest={this.updateDataRequest}
                    requestId={this.props.requestId}
                />;
            case PAYMENT_TAB_VISA:
                return <SogeCommercePayment
                    paymentCard={PAYMENT_CARD_VISA}
                    paymentPrice={paymentPrice}
                    paymentCurrency={paymentCurrency}
                    updateDataRequest={this.updateDataRequest}
                    requestId={this.props.requestId}
                />;
            case PAYMENT_TAB_PAYPAL:
                return <PaypalPayment
                    paymentPrice={paymentPrice}
                    paymentCurrency={paymentCurrency}
                    updateDataRequest={this.updateDataRequest}
                    requestId={this.props.requestId}
                />;
            default:
                return <div>

                </div>;
        }
    }

    render() {
        const {classes, t, blickoContact, currency, requestProblem} = this.props;
        const {errors, methodPayment, loadingMethod,paymentPrice, paymentCurrency, isLoading, paymentRequest} = this.state;
        const methodArray = paymentArray.map((item, index) => {
            switch (item.name) {
                case PAYMENT_TAB_MASTER_CARD:
                case PAYMENT_TAB_VISA:
                    return (
                        <div className={`payment_element not_lydia ${item.name===this.state.methodPayment?'paymentSelected':''}`} onClick={()=>this.getMethodPayment(item.name)}>
                            <img src={item.logo}/>
                        </div>
                    );
                case PAYMENT_TAB_PAYPAL:
                    return <div className='payment_element'>
                        <PaypalPayment
                            paymentPrice={paymentPrice}
                            paymentCurrency={paymentCurrency}
                            updateDataRequest={this.updateDataRequest}
                            requestId={this.props.requestId}
                        />
                    </div>;
                case PAYMENT_TAB_LYDIA:
                    return <div className='payment_element'>
                        <LydiaPayment
                            updateDataRequest={this.updateDataRequest}
                            requestId={this.props.requestId}
                        />
                    </div>;
            }
        })
        const blickoContactHtml = parse(blickoContact ?? "");
        let requestProblemHtml = parse(requestProblem ?? "");
        // console.log(requestProblem);
        // console.log(requestProblemHtml);
        if (requestProblemHtml.length > 0 && typeof problemTitle !== 'string') {
            requestProblemHtml = requestProblemHtml[0]
        }
        if(requestProblemHtml.props && requestProblemHtml.props.children &&  requestProblemHtml.props.children.length>0 && typeof requestProblemHtml.props.children !== 'string'){
            requestProblemHtml = requestProblemHtml.props.children[0]
        }

        return (
            <div className={classes.payment}>
                {isLoading && <LoadingAction />}
                <div className={classes.left}>
                    <div className={'payment_logo payment_left'}>
                        <img src={logo} alt={'logo'}/>
                    </div>
                    <div className={'payment_amount payment_left'}>
                        <RequestProblemTooltip
                            title={
                                <div>
                                    {parse(requestProblem ?? "")}
                                </div>
                            }
                            interactive
                        >
                            <span className="requestProblemHtml">
                                {requestProblemHtml}
                            </span>
                        </RequestProblemTooltip>
                        <div>
                            {t('information.formTab3.amount')}: <span className={'payment_amount'}>{paymentPrice}  {currency && currency.unit ? currency.unit :""}</span>
                        </div>
                    </div>
                    <div className={'contact payment_left'}>
                        {t('information.formTab3.contact')}: <span className={'payment_phoneNumber'}>{blickoContactHtml}</span>
                    </div>
                </div>
                <div className={classes.right}>
                    {
                        !paymentRequest
                        ?
                            <React.Fragment>
                                <div className={'payment_method'}>
                                    <div className={'payment_title'}>
                                        {t('information.formTab3.methodPayment')}
                                    </div>
                                    <div className={'payment_list_method'}>
                                        {methodArray}
                                    </div>
                                </div>
                                {!loadingMethod && <div className="payment_body">
                                    {this.showPaymentForm()}
                                </div>}
                            </React.Fragment>
                            :
                            <div className="message">
                                <div className="paidText">
                                    <CustomInlineSvg
                                        svgProps={{src : checked}}
                                    />
                                    <div className="text">
                                        {this.props.t("payment.paid_text")}
                                    </div>
                                </div>
                                <NavLink to={links.HOME} className="linkHome">
                                    <Button className="btnToHome">{this.props.t('payment.return_home')}</Button>
                                </NavLink>
                            </div>
                    }

                </div>
            </div>
        );
    }
}
Payment.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
}
const mapStateToProps = (state) => {
    return {
        blickoContact: state.applicationReducer.blickoContact,
        currency: state.applicationReducer.currency,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter
)(Payment);
