import React, {useCallback, useEffect, useState} from 'react';
import 'react-dropzone-uploader/dist/styles.css'
import LoadingAction from "../../../theme/LoadingAction";
import {compose} from "redux";
import {Button, Grid, Table, TableCell, TableHead, TableRow, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {NavLink, withRouter} from "react-router-dom";
import {Redirect} from "react-router";
import axios from "axios";
import {API_ADD_REPORT_FOR_REQUEST_WITH_TOKEN, API_SHOW_REPORT_WITH_TOKEN} from "../../../constants/apiSuffix";
import * as links from "../../../constants/links";
import notify from "devextreme/ui/notify";
import {
    CURRENCY_DEFAULT,
    IS_SENT_TO_CLIENT,
    LG,
    MD,
    PAYMENT_CARD_MASTERCARD,
    PAYMENT_CARD_VISA,
    PAYMENT_REPORT,
    SM,
    XS
} from "../../../constants/constants";
import logoClient from "../../../images/navbar/logo2.png";
import returnIcon from "../../../images/navbar/Groupe 8620.svg";
import {MONTSERRAT, NUNITO} from "../../../constants/fontCss";
import banner from "../../../images/banner/banner.png";
import CustomInlineSvg from "../../../theme/CustomElements/CustomInlineSvg";
import checked from "../../../images/payment/checked.svg";
import {
    PAYMENT_CARD,
    PAYMENT_TAB_LYDIA,
    PAYMENT_TAB_MASTER_CARD,
    PAYMENT_TAB_PAYPAL,
    PAYMENT_TAB_VISA,
    paymentArray
} from "../../../constants/paymentMethod";
import PaypalPayment from "../information/payment/PaypalPayment";
import LydiaPayment from "../information/payment/LydiaPayment";
import SogeCommercePayment from "../information/payment/SogeCommercePayment";
import {useSelector} from "react-redux";
import deleteIcon from "../../../images/report/bin.svg";
import docPdfIcon from "../../../images/report/Composant 28 – 61.svg";
import RowFormColumn from "../../../theme/form/RowFormColumn";
import CustomInput from "../../../theme/CustomElements/CustomInput";
import {value} from "lodash/seq";
import {setIsLoading} from "../../../_actions/application";
import PaidIcon from "../../../images/diagnostic/paid.svg";
import {debounce} from "lodash";
import AlertCustom from "../../../theme/form/Alert/AlertCustom";

const styles = theme => ({
    paymentReportWrapper: {

    },
    navbar: {
        height: 80,
        display: 'flex',
        alignItems: 'center',
        // padding:'0 80px',
        padding: '0 4rem',
        background: '#fff',
        justifyContent: 'space-between',
        [theme.breakpoints.down(XS)]: {
            height: 60
        },
        [theme.breakpoints.between(MD, LG)]: {
            padding: '0 4rem'
        },
        [theme.breakpoints.between(XS, MD)]: {
            padding: '0 2rem'
        },
        '& a': {
            '& img': {
                [theme.breakpoints.down(XS)]: {
                    width: 80
                }
            }
        }
    },
    header: {
        background: `url(${banner})`,
        backgroundSize: 'cover',
        height: 154,
        position: 'relative',
        '&::before':{
            content:'""',
            height: '100%',
            position: 'absolute',
            background: '#2020ac',
            opacity:'0.5',
            boxShadow: 'inset 5px -38px 44px -18px rgba(0,0,0,0.54)',
            width: '100%',
        },
        '& .headerWrapper': {
            position: 'relative',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        [theme.breakpoints.down(SM)]:{
            height: 174
        }
    },
    returnRequestList: {
        borderRadius: 24,
        border: 'solid 1px #ffffff',
        color: '#fff',
        textDecoration: 'none',
        padding: '0.5rem 1rem',
        marginLeft: '3rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        maxWidth: 150,
        [theme.breakpoints.between(XS, SM)]: {
            marginLeft: '1rem',
        },
        '&:hover': {
            //background:'linear-gradient(270deg, rgba(14, 56, 96, 0) 8.24%, #0E2F60 34.94%, #0A0730 97.58%)',
            background: '#ffffff70',
            //color: '#0c1c49',
            color: '#fff',
            backgroundPosition: '100% 0',
            transition: 'all .4s ease-in-out',
        },
        '& > span': {
            fontSize: '1.1125rem',
            paddingLeft: '0.5rem',
            lineHeight: 0.9,
            fontFamily: NUNITO
        }
    },
    bodyPaymentReport: {
        padding: '2rem',
        [theme.breakpoints.between(XS, SM)]: {
            padding: '1rem',
        },
        '& .content': {
            background: '#fff',
            borderRadius: 22,
            boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
            width: '100%',
            padding: '30px 20px',
            '& .infoReport': {
                padding: '0 10px',
                '& .title': {
                    fontWeight: 'bold',
                    fontSize: '1.2rem'
                }
            },
            '& .infoPayer': {
                padding: '0 10px',
                '& .title': {
                    fontWeight: 'bold',
                    fontSize: '1.2rem'
                }

            }
        },
        '& .freeForReport': {
            color: '#605e72',
            fontWeight: 'bold',
            fontSize: '1.5rem',
            paddingRight: 10,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& .paidForRequest': {
            height: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& .text': {
                color: '#605e72',
                fontWeight: 'bold',
                fontSize: '1.5rem',
                paddingRight: 10,
            }
        }
    },
    right :{
        // marginLeft: 20,
        // width: '70%',
        display: 'flex',
        flexDirection: 'column',
        // width: 500,
        '& .btnClosePayment': {
            backgroundColor: '#989898',
            color: '#fff',
            width: 40,
            height: 40,
            minWidth: 'unset'
        },
        '& .message': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            '& .linkHome': {
                textDecoration: 'none',
                marginTop: 10
            },
            '& .btnToHome': {
                color: '#fff',
                backgroundColor: '#0000ff',
                textDecoration: 'none',
                textTransform: 'initial',
                fontWeight: 600,
                padding: '8px 16px'
            }
        },
        '& .paidText': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '1.65rem',
            fontWeight: 600,
            '& .text': {
                paddingLeft: 10
            }
        },
        '& .payment_method':{
            marginTop: 20,
        },
        '& .payment_body': {
            flexGrow: 1,
            padding: 8,
            overflow: 'hidden',
            '& .paramLabel': {
                color: 'red!important'
            }
        },
        '& iframe': {
            width: '100%',
            height: '100%',
            border: 'none'
        },
        '& .payment_title': {
            fontWeight: 700,
            color: '#535353',
            fontSize: '0.875rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        '& .payment_list_method': {
            display: 'flex',
            // justifyContent: 'space-between',
            marginTop: 7,
            overflowX: 'auto'
        },
        '& .payment_element': {
            height: 54,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            margin: '0 4px',
            border:'1px solid #BDBDBD',
            borderRadius: 10,
            '& img': {
                margin: '6px 18px',
            },
        },
        '& .not_lydia':{
            border:'1px solid #BDBDBD',
            borderRadius: 10,
            '& img': {
                margin: '6px 18px',
                width: '55px'
            },
        },
        '& .paymentSelected':{
            border:'2px solid #0000FF',
            borderRadius: 8,
        },
        '& .payment_detail':{
            display: 'flex',
            justifyContent:'space-between',
            '& input':{
                width: '100%',
                height: 40,
                border: '1px solid #E0E0E0',
                borderRadius: 6,
                marginTop: 10,
                fontFamily: `${MONTSERRAT}`,
                paddingLeft: 9
            },
            '& .payment_detail_left':{
                width: '60%',
                marginRight: 10
            },
            '& .payment_detail_right':{
                width: '40%',
                marginLeft: 10
            },
        },
        [theme.breakpoints.down(SM)]:{
            width: '60%',
            '& .payment_list_method':{
                flexWrap: 'wrap'
            },
            '& .not_lydia':{
                '& img':{
                    width: '50px'
                }
            },
            '& .payment_element:first-child':{
                width: '100px'
            },
        },
        [theme.breakpoints.down(XS)]:{
            width: '100%',
            marginLeft: 0,
            '& .paypal-buttons ':{
                minWidth: '100%!important'
            },
            '& .not_lydia':{
                width: '30%!important',
                justifyContent: 'center',
                '& img':{
                    margin: '0!important'
                }
            },
            '& .payment_element':{
                width: '30%!important',
            }
        }
    },
    bgCannotPay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        zIndex: 9999,
    }
})

const dataInitialInfoPayer = {
    firstName: '',
    lastName:  '',
    phoneNumber: '',
    address: '',
    email: ''
}
const PaymentReport = (props) => {
    const {
        classes,
        match,
        t
    } = props;
    const dataUser = useSelector((state)=> state.authReducer.dataUser);
    const [openNoticeError, setOpenNoticeError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(null);
    const [showPayment, setShowPayment] = useState(false);
    const [loadingMethod, setLoadingMethod] = useState(false);
    const [methodPayment, setMethodPayment] = useState(PAYMENT_TAB_MASTER_CARD);
    const [report, setReport] = useState(null);
    const [updateSogo, setUpdateSogo] = useState(false);
    const [checkRequired, setCheckRequired] = useState(false);
    const totalFinallyReport = Number(parseFloat(report?.otherInfos?.totalFinally ?? 0).toFixed(2));

    const [infoPayer, setInfoPayer] = useState({
        ...dataInitialInfoPayer,
        ...(dataUser?.data ? {
            email: dataUser?.data?.email ?? "",
            firstName: dataUser?.data?.firstName ?? "",
            lastName: dataUser?.data?.lastName ?? "",
            address: dataUser?.data?.fullAddress ?? "",
            phoneNumber: dataUser?.data?.phoneNumber ?? "",
        } : {})
    })

    const debounceUpdate = useCallback(debounce((nextValue) => {
        setUpdateSogo(nextValue);
    }, 1000), [])

    useEffect(() => {
        debounceUpdate(true)
    }, [infoPayer])

    useEffect(() => {
        if (updateSogo) {
            setUpdateSogo(false);
        }
    }, [updateSogo])
    const {
        currency
    } = useSelector((state)=> state.applicationReducer);

    let listReportId = report ? [report.id] : [];


    useEffect(() => {
        try {
            const token = match?.params?.token;
            // console.log(token)
            if (token) {
               setLoading(true);
                axios.get(API_SHOW_REPORT_WITH_TOKEN.replace('{token}', token))
                    .then(res => {
                        const data = res.data;
                        if (data) {
                            if (!data?.item?.paid) {
                                setShowPayment(true);
                            }
                        }
                        setReport(data.item);
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                    })
            }
        } catch (e) {

        }
    }, [])

    useEffect(() => {
        if (loadingMethod) {
            setLoadingMethod(false)
        }
    }, [loadingMethod])

    if (redirect) {
        return <Redirect to={redirect}/>
    }
    console.log(currency)
    const showPaymentForm = () => {
        switch (methodPayment) {
            case PAYMENT_TAB_LYDIA:
                return  <LydiaPayment
                    // updateDataRequest={this.updateDataRequest}
                    // requestId={this.props.requestId}
                />;
            case PAYMENT_CARD:
                if (updateSogo) {
                    return <></>
                }
                return <SogeCommercePayment
                    paymentPrice={totalFinallyReport}
                    paymentCurrency={currency?.value ?? CURRENCY_DEFAULT}
                    paymentType={PAYMENT_REPORT}
                    listReportPayment={listReportId}
                    infoPayer={infoPayer}
                    // updateDataRequest={this.updateDataRequest}
                    // requestId={this.props.requestId}
                />;
            case PAYMENT_TAB_MASTER_CARD:
                if (updateSogo) {
                    return <></>
                }
                return <SogeCommercePayment
                    paymentCard={PAYMENT_CARD_MASTERCARD}
                    paymentPrice={totalFinallyReport}
                    paymentCurrency={currency?.value ?? CURRENCY_DEFAULT}
                    paymentType={PAYMENT_REPORT}
                    listReportPayment={listReportId}
                    infoPayer={infoPayer}
                    // updateDataRequest={this.updateDataRequest}
                    // requestId={this.props.requestId}
                />;
            case PAYMENT_TAB_VISA:
                if (updateSogo) {
                    return <></>
                }
                return <SogeCommercePayment
                    paymentCard={PAYMENT_CARD_VISA}
                    paymentPrice={totalFinallyReport}
                    paymentCurrency={currency?.value ?? CURRENCY_DEFAULT}
                    paymentType={PAYMENT_REPORT}
                    listReportPayment={listReportId}
                    infoPayer={infoPayer}
                    // updateDataRequest={this.updateDataRequest}
                    // requestId={this.props.requestId}
                />;
            case PAYMENT_TAB_PAYPAL:
                return <PaypalPayment
                    paymentPrice={totalFinallyReport}
                    paymentCurrency={currency?.value ?? CURRENCY_DEFAULT}
                    setLoadingReportPage={setLoadingReportPage}
                    // requestId={dataUser?.data?.id}
                    paymentType={PAYMENT_REPORT}
                    listReportPayment={listReportId}
                    infoPayer={infoPayer}
                />;
            default:
                return <div>

                </div>;
        }
    }
    const setLoadingReportPage = () => {
        window.location.reload();
    }
    const getMethodPayment = (event) => {
        setMethodPayment(event);
        setLoadingMethod(true)
    }

    const handleChangeInfoPayer = (name, value) => {
        setInfoPayer(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const methodArray = paymentArray.map((item, index) => {
        switch (item.name) {
            case PAYMENT_TAB_MASTER_CARD:
            case PAYMENT_TAB_VISA:
                return (
                    <div className={`payment_element not_lydia ${item.name===methodPayment?'paymentSelected':''}`} onClick={()=>getMethodPayment(item.name)}>
                        <img src={item.logo}/>
                    </div>
                );
            case PAYMENT_TAB_PAYPAL:
                return <div className='payment_element'>
                    <PaypalPayment
                        paymentPrice={totalFinallyReport}
                        paymentCurrency={currency?.value ?? CURRENCY_DEFAULT}
                        setLoadingReportPage={setLoadingReportPage}
                        // requestId={dataUser.data.id}
                        listReportPayment={listReportId}
                        paymentType={PAYMENT_REPORT}
                        infoPayer={infoPayer}
                    />
                </div>;
            case PAYMENT_TAB_LYDIA:
                return <></>
                // return <div className='payment_element'>
                //     <LydiaPayment
                //         updateDataRequest={this.updateDataRequest}
                //         requestId={this.props.requestId}
                //     />
                // </div>;
        }
    })
    let viewPrice = report?.otherInfos && !Number.isNaN(Number(report.otherInfos.totalFinally)) ? new Intl.NumberFormat('fr-FR').format(Number(report.otherInfos.totalFinally).toFixed(2)) : 0;
    const dataIntervention = report?.otherInfos?.initialData ? JSON.parse(report.otherInfos.initialData) : {};


    const canPay = infoPayer?.firstName !== "" && infoPayer?.lastName !== "";

    return (
        <div className={classes.paymentReportWrapper}>
            {loading && <LoadingAction/>}
            {/*<div className={classes.navbar}>*/}
            {/*    <NavLink to={links.HOME}><img src={logoClient} alt={'logo'}/></NavLink>*/}
            {/*</div>*/}
            <div className={classes.header}>
                <div className="headerWrapper">
                    <NavLink to={links.HOME} className={`${classes.returnRequestList} returnRequestList`}>
                        <img alt={t("information.header.returnHistory")} src={returnIcon} />
                        <span>{t("information.header.returnHistory")}</span>
                    </NavLink>
                </div>
            </div>
            <div className={classes.bodyPaymentReport}>
                {!loading && <div className="content">
                    {
                        report
                        && showPayment
                        ? <Grid container>
                        <Grid item xs={12} sm={12} md={totalFinallyReport !== 0  ? 6 : 6} lg={totalFinallyReport !== 0  ? 4 : 6} className="infoReport">
                            <div className="title">
                                {t('payment_report.title.info_report')}
                            </div>
                            <Table>
                                <TableRow>
                                    <TableCell>
                                        {t("client.reportList.description")}
                                    </TableCell>
                                    <TableCell>
                                        {/*<div className={"pdfFile"}>*/}
                                        {/*    {report?.reportPdf?.pathUrl ?*/}
                                        {/*        <a href={report.reportPdf.pathUrl} target="_blank" rel="noreferrer" download>*/}
                                        {/*            <CustomInlineSvg svgProps={{*/}
                                        {/*                src: docPdfIcon*/}
                                        {/*            }}/>*/}
                                        {/*        </a> :*/}
                                        {/*        <CustomInlineSvg svgProps={{*/}
                                        {/*            src: docPdfIcon*/}
                                        {/*        }}/>*/}
                                        {/*    }*/}
                                        {/*</div>*/}
                                        <div className={classes.reportInfoWrapper}>
                                            <div
                                                className={"labelIntervention"}>{t("client.reportList.intervention")}</div>
                                            <div
                                                className={'problemObserved'}> {dataIntervention.problemObserved}</div>
                                            <div
                                                className={classes.reportId}> N° {dataIntervention.id}</div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        {dataIntervention.solution}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        {t("client.reportList.total")}
                                    </TableCell>
                                    <TableCell>
                                        { viewPrice} {currency?currency.unit:""}
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </Grid>
                        {
                            totalFinallyReport != 0
                            ?
                                <>
                                    <Grid xs={12} sm={12} item md={6} lg={3} className="infoPayer">
                                        <div className="title">
                                            {t('payment_report.title.info_payer')}
                                        </div>
                                        <RowFormColumn
                                            label={t('login.fields.firstName')}
                                            isRequired={true}
                                            input={<CustomInput
                                                type="text"
                                                name="firstName"
                                                value={infoPayer?.firstName ?? ""}
                                                onChange={(e) => {
                                                    handleChangeInfoPayer("firstName", e.target.value)
                                                }}
                                                validate={checkRequired && infoPayer?.firstName === ""}
                                            />}
                                        />
                                        <RowFormColumn
                                            label={t('login.fields.lastName')}
                                            isRequired={true}
                                            input={<CustomInput
                                                type="text"
                                                name="lastName"
                                                value={infoPayer?.lastName ?? ""}
                                                onChange={(e) => {
                                                    handleChangeInfoPayer("lastName", e.target.value)
                                                }}
                                                validate={checkRequired && infoPayer?.lastName === ""}
                                            />}
                                        />
                                        <RowFormColumn
                                            label={t('login.fields.phone')}
                                            // isRequired={true}
                                            input={<CustomInput
                                                type="text"
                                                name="phoneNumber"
                                                value={infoPayer?.phoneNumber ?? ""}
                                                onChange={(e) => {
                                                    handleChangeInfoPayer("phoneNumber", e.target.value)
                                                }}
                                            />}
                                        />
                                        <RowFormColumn
                                            label={t('login.fields.email')}
                                            // isRequired={true}
                                            input={<CustomInput
                                                type="text"
                                                name="email"
                                                value={infoPayer?.email ?? ""}
                                                onChange={(e) => {
                                                    handleChangeInfoPayer("email", e.target.value)
                                                }}
                                            />}
                                        />
                                        <RowFormColumn
                                            label={t('login.fields.address')}
                                            // isRequired={true}
                                            input={<CustomInput
                                                type="text"
                                                name="address"
                                                value={infoPayer?.address ?? ""}
                                                onChange={(e) => {
                                                    handleChangeInfoPayer("address", e.target.value)
                                                }}
                                            />}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={12} item md={12} lg={5} style={{
                                        position: 'relative',
                                    }}>
                                        {!canPay && <div className={classes.bgCannotPay} onClick={() => {
                                            setOpenNoticeError(true)
                                            setCheckRequired(true);
                                        }}>
                                            <AlertCustom
                                                errorOpen={openNoticeError}
                                                handleCloseNotice={() => {
                                                    setOpenNoticeError(false)
                                                }}
                                                messageError={t('payment_report.title.cannot_payment')}
                                            />
                                        </div>}
                                        <div className={classes.right}>
                                            {/*{*/}
                                            {/*    !paymentRequest*/}
                                            {/*        ?*/}
                                            <React.Fragment>
                                                <div className={'payment_method'}>
                                                    <div className={'payment_title'}>
                                                        <div>{t('information.formTab3.methodPayment')}</div>
                                                    </div>
                                                    <div className={'payment_list_method'}>
                                                        {methodArray}
                                                    </div>
                                                </div>
                                                {!loadingMethod && <div className="payment_body">
                                                    {showPaymentForm(totalFinallyReport)}
                                                </div>}
                                            </React.Fragment>
                                            {/*//         :*/}
                                            {/*//         <div className="message">*/}
                                            {/*//             <div className="paidText">*/}
                                            {/*//                 <CustomInlineSvg*/}
                                            {/*//                     svgProps={{src : checked}}*/}
                                            {/*//                 />*/}
                                            {/*//                 <div className="text">*/}
                                            {/*//                     {this.props.t("payment.paid_text")}*/}
                                            {/*//                 </div>*/}
                                            {/*//             </div>*/}
                                            {/*//             <NavLink to={links.HOME} className="linkHome">*/}
                                            {/*//                 <Button className="btnToHome">{this.props.t('payment.return_home')}</Button>*/}
                                            {/*//             </NavLink>*/}
                                            {/*//         </div>*/}
                                            {/*// }*/}
                                        </div>
                                    </Grid>
                                </>
                                :
                                <Grid xs={12} sm={12} item md={6} lg={6}>
                                    <div className="freeForReport">
                                        {t('payment_report.title.freeForReport')}
                                    </div>
                                </Grid>
                        }
                    </Grid> : <div className="paidForRequest">
                        <div className="text">
                            {t('payment_report.title.paidForRequest')}
                        </div>
                        <CustomInlineSvg
                            svgProps={{src : PaidIcon}}
                        />
                    </div>}
                </div>}
            </div>
        </div>
    );
}

export default compose( withStyles(styles),withTranslation(),withRouter)(PaymentReport);
