import React, {useEffect, useState} from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {
    REGISTERED_PHONE_NUMBER,
    SMS_SENDER,
    SMS_TOKEN,
    VOICE_MESSAGE_TOKEN,
} from "../../../constants/constants";
import CustomInput from "../../../theme/CustomElements/CustomInput";
import RowFormColumn from "../../../theme/form/RowFormColumn";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import SaveIcon from "@material-ui/icons/Save";
import CustomButton from "../../../theme/CustomElements/CustomButton";
import axios from "axios";
import LoadingAction from "../../../theme/LoadingAction";
import AlertCustom from "../../../theme/form/Alert/AlertCustom";
import infoIcon from "../../../images/icons/inforIcon.svg"
import {Tooltip} from "@material-ui/core";
import CustomInlineSvg from "../../../theme/CustomElements/CustomInlineSvg";

const useStyles = makeStyles((theme) => ({
    messageAndVoiceTokenContainer: {
        '& .info-icon-wrapper':{
            marginLeft: 10,
            '& svg path': {
                fill: '#657a9c'
            }
        }
    },
    saveButtonContainer: {
        minWidth: 130,
        marginTop: 15
    },
    customTooltip: {
        fontSize: 12
    }

}))

const SmsAndVoiceToken = (props) => {
    const {
        t
    } = props;
    const [smsToken, setSmsToken] = useState("");
    const [voiceToken, setVoiceToken] = useState("")
    const [smsSender, setSmsSender] = useState("")
    const [registeredPhoneNumber, setRegisteredPhoneNumber] = useState("")

    const [isLoading, setIsLoading] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)
    const [successOpen, setSuccessOpen] = useState(false)

    const classes = useStyles();
    useEffect(() => {
            setIsLoading(true)
            axios.get("/api/parameter/token").then(res => {
                if (res.status === 200) {
                    res.data.forEach(item => {
                        switch (item.code){
                            case SMS_TOKEN:
                                setSmsToken(item.value);
                                break;
                            case VOICE_MESSAGE_TOKEN:
                                setVoiceToken(item.value);
                                break
                            case SMS_SENDER:
                                setSmsSender(item.value);
                                break;
                            case REGISTERED_PHONE_NUMBER:
                                setRegisteredPhoneNumber(item.value);
                                break;
                            default:
                                break;
                        }
                    })
                    setIsLoading(false)
                } else {
                    throw new Error();
                }
            })
                .catch(() => {
                    setIsLoading(false)

                })
        },
        []
    )
    const saveData = () => {
        setIsLoading(true)
        const formData = new FormData();
        formData.append(SMS_TOKEN, smsToken.trim());
        formData.append(VOICE_MESSAGE_TOKEN, voiceToken.trim());
        formData.append(SMS_SENDER, smsSender.trim());
        formData.append(REGISTERED_PHONE_NUMBER, registeredPhoneNumber.trim());

        axios.post("/api/parameter-token/edit", formData).then(res => {
            if (res.status === 200 && (res?.data?.items)) {
                setSuccessOpen(true)
                res.data.items.forEach(item => {
                    if (item.code === SMS_TOKEN) {
                        setSmsToken(item.value)
                    } else if (item.code === VOICE_MESSAGE_TOKEN) {
                        setVoiceToken(item.value)
                    }
                })
                setIsLoading(false)

            } else {
                throw new Error();
            }
        })
            .catch(error => {
                if (error?.response?.data) {
                    setErrorOpen(true)
                }
                setIsLoading(false)
            })
    }

    return (
        <div className={classes.messageAndVoiceTokenContainer}>
            {isLoading && <LoadingAction/>}
            <AlertCustom
                successOpen={successOpen}
                errorOpen={errorOpen}
                handleCloseNotice={() => {
                    setSuccessOpen(false)
                    setErrorOpen(false)
                }}
            />
            <RowFormColumn
                label={t("reactAdmin.smsAndVoiceParams.smsToken")}
                input={
                    <CustomInput
                        value={smsToken}
                        onChange={(e) => setSmsToken(e.target.value)}
                        // validate={!smsToken}
                    />}
                // textValidate={!smsToken ? t("errors.notNull") : ""}

            />
            <RowFormColumn
                label={t("reactAdmin.smsAndVoiceParams.voiceToken")}
                input={<CustomInput
                    value={voiceToken}
                    onChange={(e) => setVoiceToken(e.target.value)}
                    // validate={!voiceToken}
                />}
                // textValidate={!voiceToken ? t("errors.notNull") : ""}

            />
            <RowFormColumn
                label={t("reactAdmin.smsAndVoiceParams.smsSender")}
                input={<CustomInput
                    value={smsSender}
                    onChange={(e) => setSmsSender(e.target.value)}
                />}

            />
            <RowFormColumn
                label={t("reactAdmin.smsAndVoiceParams.registeredPhoneNumber")}
                componentLabelRight={<Tooltip title={t("reactAdmin.smsAndVoiceParams.noteSender")} classes={{
                    tooltip: classes.customTooltip,
                }} arrow  placement={"top"}>
                    <div className="info-icon-wrapper">
                        <CustomInlineSvg
                            svgProps={{
                                src: infoIcon
                            }}
                        />
                    </div>
                </Tooltip>}
                input={<CustomInput
                    value={registeredPhoneNumber}
                    onChange={(e) => setRegisteredPhoneNumber(e.target.value)}
                />}

            />
            <CustomButton
                className={classes.saveButtonContainer}
                title={t("button.save")}
                onClick={() => saveData()}
                buttonProps={{
                    startIcon: <SaveIcon className={"iconAdd"}/>
                }}
            />
        </div>
    );
}

SmsAndVoiceToken.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

export default compose(
    withTranslation(),
    withRouter
)(SmsAndVoiceToken);

