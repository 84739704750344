import React from "react";
import {AsyncPaginate} from "react-select-async-paginate";
import i18n from "../../../i18n";
import AppSelect from "./AppSelect";
import withStyles from "@material-ui/core/styles/withStyles";
import {components} from "react-select";
// import {Tooltip} from "@material-ui/core";
import PropTypes from "prop-types";

const styles = {
    reactSelectPaginate: {
        width: '100%',
        // zIndex: 1000,
        '& .MuiSelect-icon': {
            top: 'auto!important',
            right: 'auto!important',
            position: 'relative!important'
        },
        // '& > div': {
        //     zIndex: 1000
        // }
    }
};

// const DropdownIndicator = props => {
//     return (
//         components.DropdownIndicator && (
//             <components.DropdownIndicator {...props}>
//                 <svg className="MuiSvgIcon-root MuiSelect-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
//                     <path d="M7 10l5 5 5-5z"></path>
//                 </svg>
//             </components.DropdownIndicator>
//         )
//     );
// }

const Option = (props) => {
    // return (
    //     <Tooltip content={props.label} truncateText>
    //         <components.Option {...props}/>
    //     </Tooltip>
    // );
    return <components.Option {...props}/>
};

const LoadingMessage = props => {
    return (
        <div
            {...props.innerProps}
            style={props.getStyles('loadingMessage', props)}
        >
            {i18n.t("text.select.loading")}
        </div>
    );
};

const NoOptionsMessage = props => {
    return (
        <div
            {...props.innerProps}
            style={props.getStyles('noOptionsMessage', props)}
        >
            {i18n.t("text.select.noOption")}
        </div>
    );
};

class AppAsyncPaginate extends AppSelect{
    constructor(props) {
        super(props);
    }

    render() {
        let props = {
            components:{
                NoOptionsMessage,
                LoadingMessage,
                Option
                // DropdownIndicator
            },
            placeholder: i18n.t("text.select.placeholder"),
            styles: styles,
        };
        const {classes} = this.props;
        // const {menuIsOpen, forceCloseMenu} = this.state;
        const { menuIsOpen } = this.state;
        let propsStyles = props.styles;
        // if (this.props.styles){
        //     propsStyles = Object.assign(propsStyles, this.props.styles);
        // }
        props = Object.assign(props,{...this.props, styles: propsStyles});
        return <AsyncPaginate
            {...props}
            SelectComponent={AppSelect}
            className={`${props.className?props.className:""} ${classes.reactSelectPaginate}`}
            onMenuOpen={()=>this.onMenuOpen(props.onMenuOpen)}
            onMenuClose={()=>this.onMenuClose(props.onMenuClose)}
            menuIsOpen={menuIsOpen||props.menuIsOpen}
            onInputChange={(text,detail)=>this.onInputChange({text:text,detail:detail},props.onInputChange)}
        />
    }
}

AppAsyncPaginate.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppAsyncPaginate);
