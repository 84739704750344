import React, {useEffect, useState} from "react";
import CallIcon from "../../images/request/call_2.svg"
import CustomSvg from "../../theme/CustomElements/CustomSvg";
import {compose} from "redux";
import {connect, useSelector} from "react-redux";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import axios from "axios";
import {API_CREATE_MEETING_ID, API_END_VIDEO_CALL} from "../../constants/apiSuffix";
import LoadingAction from "../../theme/LoadingAction";
import {
    CALLING,
    REACT_APP_DOMAIN_VIDEO_CALL,
    ROLE_GROUP_ADMIN,
    USER_TYPE_ADMIN,
    USER_TYPE_REPAIRER,
    USER_TYPE_SUPER_ADMIN, XS
} from "../../constants/constants";
import {InfoOutlined} from "@material-ui/icons";
import * as links from "../../constants/links"
import DialogForm from "../../theme/CustomElements/DialogForm";

const styles = (theme) => ({
    container: {
        display: 'flex',
        padding: '5px 10px',
        // height: 80,
        alignItems: 'center',
        '& a': {
            textDecoration: 'none',
        }
    },
    buttonWrapper: {
        position: 'relative',
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px 20px',
        cursor: 'pointer',
        backgroundColor: '#eb35b4',
        borderRadius: 32,
        [theme.breakpoints.down(XS)]: {
            '& .text': {
                fontSize: '0.8125rem',
                lineHeight: 1.2
            },
            '& svg': {
                width: 25,
            },
        },
        '& .text': {
            paddingLeft: 5,
            color: '#fff',
            fontWeight: '600',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            flexGrow: 1,
            whiteSpace: "pre-line",
        },
        '& svg': {
            width: 30,
            '& path': {
                fill: '#fff!important'
            }
        }
    },
    endCall: {
        cursor: 'pointer',
        position: 'absolute',
        left: '100%',
        bottom: '50%',
        backgroundColor: '#0000f9',
        color: '#fff',
        padding: '5px 10px',
        borderRadius: 15,
        textTransform: 'uppercase',
        fontWeight: 600,
        zIndex: 999,
    }
});

const CREATE_MEETING = "CREATE_MEETING";
const ENDED_MEETING = "ENDED_MEETING";
const VideoCallBlock = (props) => {
    const {
        t,
        request,
        classes,
        centerButton
    } = props;
    const dataUser = useSelector((state) => state.authReducer.dataUser);
    const [loading, setLoading] = useState(false);
    const [dialogForm, setDialogForm] = useState("");
    const [meetingId, setMeetingId] = useState(request.videoCalling?.status === CALLING ? request.videoCalling?.meetingId ?? null : null);
    const isAdmin = [USER_TYPE_REPAIRER, USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN].includes(dataUser.data?.group)
    useEffect(() => {
        setMeetingId(request.videoCalling?.status === CALLING ? request.videoCalling?.meetingId ?? null : null)
    }, [request])

    const createVideoCall = () => {
        setLoading(true);
        axios.post(API_CREATE_MEETING_ID.replace('{requestId}', request.id))
            .then(res => {
                if (res.status === 200 && res.data?.item?.meetingId) {
                    setMeetingId(res.data.item.meetingId);
                    if(request.videoCalling) {
                        request.videoCalling.meetingId = res.data.item.meetingId
                        request.videoCalling.status = CALLING
                    }else{
                        request.videoCalling = {
                            meetingId: res.data.item.meetingId,
                            status: CALLING
                        }
                    }
                    window.open(createLinkMeeting(res.data.item.meetingId), '_blank', 'noopener,noreferrer')
                }
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })
    }

    const endVideoCall = () => {
        setLoading(true);
        axios.post(API_END_VIDEO_CALL.replace('{meetId}', meetingId))
            .then(res => {
                if (res.status === 200) {
                    setMeetingId(null);
                    request.videoCalling = null
                }
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })
    }

    const createLinkMeeting = (meetingIdNew = "")=>{
        let requestTemp = {...request};
        delete requestTemp.documents;
        delete requestTemp.payment;
        delete requestTemp.categories;
        delete requestTemp.chatDocuments;
        delete requestTemp.notesByCondition;
        delete requestTemp.paymentReports;
        delete requestTemp.person;
        delete requestTemp.reports;
        delete requestTemp.requestStatuses;
        delete requestTemp.status;
        delete requestTemp.totalAmountPayment;
        delete requestTemp.videoCalling;
        delete requestTemp.advertise;
        delete requestTemp.lastChat;
        return `${REACT_APP_DOMAIN_VIDEO_CALL}?m=${meetingIdNew? meetingIdNew:meetingId}&p=${isAdmin ? 'admin' : 'user'}${!isAdmin ? "" : ("&rs=" + btoa(encodeURIComponent(JSON.stringify(requestTemp))))}`
    }

    return (<React.Fragment>
        {meetingId ?
            <div className={classes.container} style={centerButton ? {
                justifyContent: 'center'
            } : {}}>
                {loading && <LoadingAction/>}
                <div className={`${classes.buttonWrapper} buttonVideoCallAdminWrapper`}>
                    <a target="_blank" rel="noreferrer"
                       href={createLinkMeeting()}>
                        <div className={classes.button}>
                            <InfoOutlined fontSize={"large"}/>
                            <div className="text">{t('video_call.label.join')}</div>
                        </div>
                    </a>
                    {isAdmin && <div className={classes.endCall} onClick={() => {
                        setDialogForm(ENDED_MEETING);
                    }}>
                        {t('video_call.label.end')}
                    </div>}
                </div>
            </div> :
            !isAdmin ? <div/> :
                <div className={classes.container} style={centerButton ? {
                    justifyContent: 'center'
                } : {}}>
                    {loading && <LoadingAction/>}
                    <div className={classes.button} onClick={() => {
                        setDialogForm(CREATE_MEETING);
                    }}>
                        <CustomSvg
                            svgProps={{
                                src: CallIcon,
                                // title:t("request.documentNotSeen")
                            }}
                        />
                        <div className="text">{t('video_call.label.start')}</div>
                    </div>
                </div>}
        {!!dialogForm && <DialogForm
            styleNumber={2}
            dialogProps={{
                open: !!dialogForm
            }}
            disagreeButtonProps={{
                handleDisagree: () => {
                    setDialogForm("");
                }
            }}
            agreeButtonProps={{
                handleAgree: () => {
                    setDialogForm("");
                    if (dialogForm === ENDED_MEETING) {
                        endVideoCall()
                    } else {
                        createVideoCall();

                    }
                }
            }}
        />}
    </React.Fragment>)
}

VideoCallBlock.defaultProps = {}

VideoCallBlock.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(
    withStyles(styles),
    withTranslation(),
    withRouter
)(VideoCallBlock);
