import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Edit,
    SimpleForm,
    EditButton,
    TextInput,
    Create,
    DatagridBody,
    useRecordContext
    // required
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import Pagination from "../Pagination";

import { withStyles } from '@material-ui/core/styles';
import {styleCreate, styleEdit, styleList} from "../style";
import {MyDatagridRow} from "../MyDatagridRow";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import ListEmpty from "./ListEmpty";
// import {ListActions} from "../custom/customActionsList";
import {toolbarOptionsReactQuill} from "../../../constants/constants";
import CustomEditActions from "../custom/customEditActions";
import {makeStyles} from "@material-ui/core";
// import {CustomFilters} from "../customFiltersList";
// import {AddTitleForm} from "../customTitleForm";
const styles = {
    body:{
        maxHeight: "5rem",
        overflow: "hidden",
        display: "block"
    }
};

const useStyles = makeStyles(styles);

const MyDatagridBody = props => {
    return <DatagridBody {...props} row={<MyDatagridRow />}/>
};
const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody />} />;
const BodyField = (props)=>{
    const classes = useStyles();
    return <TextField className={classes.body} dangerouslySetInnerHTML={{__html:props.record.body}}/>;
}

const properties = [
    {
        source: "name",
        label: "reactAdmin.mailType.name"
    },
    {
        source: "subject",
        label: "reactAdmin.mailType.subject"
    },
    {
        source: "body",
        label: "reactAdmin.mailType.body"
    }
];

export const MailTypeList = compose(withStyles(styleList), withTranslation())(({classes, t, ...props}) => {
    const {tReady, ...newProps} = props
    return (
        <List {...newProps} empty={<ListEmpty arrayColumn={properties} titleList={""}/>}
              actions={null}
            // filters={<CustomFilters classes={classes} t={t} titleList={LABEL_MAIL_TYPE}/>}
              pagination={<Pagination {...props}/>} className={classes.listWrapper}>
            {/*<MyDatagrid className={classes.tableWrapper}>*/}
            <Datagrid  className={classes.tableWrapper}>
                {properties.map((item, key) => {
                        if (item.source === "body") {
                            return <BodyField/>;
                        }
                        return <TextField source={item.source} label={t(item.label)} sortable={false} key={key}/>;
                    }
                )}
                <EditButton className={classes.editButton}/>
            </Datagrid>
            {/*</MyDatagrid>*/}
        </List>
    )
});

export const MailTypeEdit =  compose(withStyles(styleEdit), withTranslation())(({ classes, ...props }) => (
    <Edit {...props} undoable={false} className={classes.editWrapper} >
        <SimpleForm className={classes.editSimpleForm}  toolbar={<CustomEditActions />}>
            <MailTypeForm classes={classes} {...props}/>
        </SimpleForm>
    </Edit>
));

export const MailTypeCreate =  compose(withStyles(styleCreate), withTranslation())(({ classes, ...props }) => (
    <Create {...props} className={classes.createWrapper}>
        <SimpleForm className={classes.createSimpleForm}>
            <MailTypeForm classes={classes}  {...props}/>
        </SimpleForm>
    </Create>
));

const MailTypeForm = ({...props}) => {
    return (
        <React.Fragment>
            {/*<AddTitleForm {...props} titleForm={LABEL_MAIL_TYPE}/>*/}
            <TextInput source={"code"} fullWidth/>
            {properties.map((item, key)=>
                {return item.source==="body"?<RichTextInput source={item.source} label={props.t(item.label)} toolbar={toolbarOptionsReactQuill} fullWidth key={key}/>:
                    <TextInput source={item.source} label={props.t(item.label)} fullWidth key={key}/>}
            )}
        </React.Fragment>
    )
};
