import React from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
import {
    BASE_URL,
    CURRENCY_DEFAULT, CURRENCY_LOCAL,
    DATA_USER,
    PAYMENT_PAYPAL_CLIENT_ID, PAYMENT_REPORT,
    PRICE_DEFAULT, XS
} from "../../../../constants/constants";
import axios from "axios";
import {API_PAYMENT_REPORT, API_PAYMENT_REQUEST} from "../../../../constants/apiSuffix";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import LoadingAction from "../../../../theme/LoadingAction";
import {PAYMENT_TAB_PAYPAL} from "../../../../constants/paymentMethod";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";

let PayPalButton = null;
let currency = CURRENCY_DEFAULT.toString();
const currencyOptions = [
    "EUR",
    "USD",
    "CAD"
];
const optionsCustomize = {
    'disable-funding': 'credit,card',
    'locale': 'fr_FR',
    'currency': "EUR"
};
let linkScript = `https://www.paypal.com/sdk/js?client-id=${PAYMENT_PAYPAL_CLIENT_ID}`;
Object.entries(optionsCustomize).forEach(([key, value]) => {
    linkScript += '&' + key + "=" + value;
})
const STATUS_PAYPAL_COMPLETE = 'COMPLETED';

const styles = theme => ({
    paypalBtnWrapper: {
        height: '100%',
        // padding: '0 4px',
        '& > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            background: '#ffc439',
            borderRadius: 8,
            '&:hover': {
                filter: 'brightness(0.95)'
            }
        },
        [theme.breakpoints.down(XS)]:{
            width: '100%',
            '& div':{
                width: '100%!important'
            }
        }
    }
});
class PaypalPayment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPaymentButton: false,
            loading: true,
            paid: false,
            isLoading: false,

        };

        window.React = React;
        window.ReactDOM = ReactDOM;
        this.onApprove = this.onApprove.bind(this);
        this.createOrder = this.createOrder.bind(this);
    }

    componentDidMount() {
        const { isScriptLoaded, isScriptLoadSucceed } = this.props;

        if (isScriptLoaded && isScriptLoadSucceed) {
            PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
            this.setState({ loading: false, showPaymentButton: true });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

        const scriptJustLoaded =
            !this.state.showPaymentButton && !this.props.isScriptLoaded && isScriptLoaded;

        if (scriptJustLoaded) {
            if (isScriptLoadSucceed) {
                PayPalButton = window.paypal.Buttons.driver("react", {
                    React,
                    ReactDOM
                });
                this.setState({ loading: false, showPaymentButton: true });
            }
        }
    }
    createOrder = (data, actions) => {
        const currencyOptions = [
            "EUR",
            "USD",
            "CAD"
        ];
        return actions.order.create({
            purchase_units: [
                {
                    description: +"Blicko",
                    amount: {
                        currency_code: currencyOptions.includes(this.props.paymentCurrency) ? this.props.paymentCurrency : CURRENCY_DEFAULT,
                        value: Number(Number(this.props.paymentPrice).toFixed(2)),
                    },
                    custom_id: this.props.requestId
                }
            ]
        });
    };

    onApprove = (data, actions) => {
        const {
            paymentType,
            listReportPayment,
            infoPayer
        } = this.props;
        this.setState({
            isLoading: true
        })
        actions.order.capture().then(details => {
            if (details.status === STATUS_PAYPAL_COMPLETE) {
                const dataBill = details;
                this.setState({
                    isLoading: true
                })
                const formData = new FormData();
                formData.append('dataBill', JSON.stringify(dataBill));
                formData.append('paymentType', PAYMENT_TAB_PAYPAL);
                formData.append('token', this.props.token);
                if (infoPayer) {
                    for (let [key, value] of Object.entries(infoPayer)) {
                        formData.append(`infoPayer[${key}]`, value);
                    }
                }
                if (paymentType === PAYMENT_REPORT) {
                    listReportPayment.forEach((item, index) => {
                        formData.append("listReportPayment["+index+"]", item);
                    })
                    axios.post(BASE_URL + API_PAYMENT_REPORT, formData)
                        .then(res => {
                            if (res.status === 200) {
                                this.setState({
                                    showPaymentButton: false,
                                    paid: true,
                                    isLoading: false
                                }, () => {
                                    this.props.setLoadingReportPage();
                                });
                            } else {
                                throw new Error();
                            }
                        })
                        .catch(err => {
                            this.setState({
                                isLoading: false
                            });
                        })
                } else {
                    axios.post(BASE_URL + API_PAYMENT_REQUEST.replace('{id}', this.props.requestId), formData)
                        .then(res => {
                            if (res.status === 200) {
                                this.setState({
                                    showPaymentButton: false,
                                    paid: true,
                                    isLoading: false
                                });
                                this.props.updateDataRequest(res.data.item);
                            } else {
                                throw new Error();
                            }
                        })
                        .catch(err => {
                            this.setState({
                                isLoading: false
                            });
                        })
                }
            } else {
                this.setState({
                    isLoading: false
                });
            }
        }).catch(err => {
            this.setState({
                isLoading: false
            });
        });
    };

    render() {
        const { showPaymentButton, paid, isLoading } = this.state;
        const {
            classes,
            infoPayer
        } = this.props;
        console.log(infoPayer)

        return (
            <React.Fragment>
                {isLoading && <LoadingAction />}
                {showPaymentButton && (
                    <div
                        className={classes.paypalBtnWrapper}
                    >
                        <PayPalButton
                            createOrder={(data, actions) => this.createOrder(data, actions)}
                            onApprove={(data, actions) => this.onApprove(data, actions)}
                        />
                    </div>
                )}
            </React.Fragment>
        );
    }
}

PaypalPayment.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
}
const mapStateToProps = (state) => {
    return {
        blickoContact: state.applicationReducer.blickoContact,
        currency: state.applicationReducer.currency,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter,
    scriptLoader(linkScript)
)(PaypalPayment);

