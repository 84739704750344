import { withStyles } from "@material-ui/core";
import CallIcon from "@material-ui/icons/Call";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import countries from "i18n-iso-countries";
import HomeIcon from "@material-ui/icons/Home";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PersonIcon from "@material-ui/icons/Person";
import EmailIcon from "@material-ui/icons/Email";
import { SM } from "../../../constants/constants";
const styles = (theme) => ({
  container: {
    width: "100%",
    padding: "10px 15px",
    // backgroundColor: "#fff",
    // borderRadius: 10,
    // maxWidth: "calc(100vw - 40px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    // height: "100%",
    // [theme.breakpoints.down(SM)]: {
    //   width: 480,
    //   height: "unset",
    // },
    "& .fullName": {
      fontSize: 18,
      fontWeight: "bold",
      marginBottom: 5,
    },
    "& .phoneNumber": {
      fontSize: 16,
      fontWeight: "bold",
      color: "#0071c7",
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: 5,
      },
    },
    "& .interventionAddress": {
      marginTop: 10,
      width: "100%",
      "& .interventionAddress__title": {
        fontWeight: "bold",
        fontSize: 16,
      },
      "& .interventionAddress__content": {
        padding: 5,
        "& .interventionAddress__item": {
          display: "flex",
          marginBottom: 10,
          "& .interventionAddress__label": {
            width: 40,
            color: "#0071c7",
          },
          "& .interventionAddress__value": {
            "& .addressInfo-full": {
              "& span": {
                marginRight: 5,
              },
            },
          },
        },
      },
    },
  },
});
const RequestInfo = (props) => {
  const { data, t, classes } = props;
  if (!data) return <></>;

  return (
    <div className={classes.container}>
      <div className="fullName">{data.fullName}</div>
      <a className="phoneNumber" href={`tel:${data.phoneNumber}`}>
        <CallIcon />
        {data.phoneNumber}
      </a>
      <div className="interventionAddress">
        <div className="interventionAddress__title">
          {t("requestInfo.interventionAddress")}
        </div>
        <div className="interventionAddress__content">
          <div className="interventionAddress__item">
            <div className="interventionAddress__label">
              <PersonIcon />
            </div>
            <div className="interventionAddress__value">
              {data.addressFirstName} {data.addressLastName}
            </div>
          </div>
          <div className="interventionAddress__item">
            <div className="interventionAddress__label">
              <LocationOnIcon />
            </div>
            <div className="interventionAddress__value">
              <div className={"addressInfo-description"}>
                {data.addressDescription ?? ""}
              </div>
              <div className={"addressInfo-full"}>
                <span>{data.addressPostalCode ?? ""}</span>
                <span>{data.addressCity ?? ""}</span>
                <span>
                  {data.addressCountry
                    ? countries.getName(
                        data.addressCountry,
                        t("translations.lang")
                      )
                    : ""}
                </span>
              </div>
            </div>
          </div>
          {!!data?.phoneNumberAddress && (
            <div className="interventionAddress__item">
              <div className="interventionAddress__label">
                <CallIcon />
              </div>
              <a
                className="phoneNumber"
                href={`tel:${data.phoneNumberAddress}`}
              >
                <div className="interventionAddress__value">
                  {data.phoneNumberAddress}
                </div>
              </a>
            </div>
          )}
          {!!data?.addressEmail && (
            <div className="interventionAddress__item">
              <div className="interventionAddress__label">
                <EmailIcon />
              </div>
              <div className="interventionAddress__value">
                {data.addressEmail}
              </div>
            </div>
          )}
          <div className="interventionAddress__item">
            <div className="interventionAddress__label">
              <HomeIcon />
            </div>
            <div className="interventionAddress__value">
              {data.isThereAnElevator === "0" ? "Pas d'" : "Avec "}
              {t("information.formTab1.isThereAnElevator")}
              {data.floorNumber
                ? `, ${t("information.formTab1.floorNumber")} ${
                    parseInt(data.floorNumber) === 0 ? "RDC" : data.floorNumber
                  }`
                : ""}
              {data.doorPassword !== "" && data.doorPassword !== null && (
                <div>
                  {t("information.formTab1.doorPassword")} : {data.doorPassword}{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default compose(withStyles(styles), withTranslation())(RequestInfo);
