import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {connect} from "react-redux";
import * as links from "./constants/links"
import * as authActions from "./_actions/auth";
import axios from "axios";
import {DATA_USER, REDIRECT} from "./constants/constants";
// import {red} from "@material-ui/core/colors";

const PublicRoute = ({component: Component, ...rest}) => {
    let checkLogin = false;
    if (localStorage.getItem(DATA_USER) !== null) {
        const dataUserLocal = JSON.parse(localStorage.getItem(DATA_USER))??{};

        try {
            if (dataUserLocal &&
                dataUserLocal.token
            ) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + dataUserLocal.token;
                checkLogin = true;
            }
        } catch(e) {
            if(!rest.openModalLoginProduct) {
                rest.setDataUser(null);
            }
        }
    } else {
        if(!rest.openModalLoginProduct) {
            rest.setDataUser(null);
        }
    }
    let redirect = null;
    try {
        redirect = localStorage.getItem(REDIRECT);
        // if (redirect) {
        //     localStorage.removeItem(REDIRECT);
        // }
    } catch (e) {

    }
    return (
        <Route {...rest} render={props => {
            if (checkLogin === false){
                return <Component {...props} />;
            }
            if (redirect) {
                return <Redirect to={redirect} />
            }
            return <Redirect to={links.HOME} />;
        }} />
    );
};

const mapStateToProps = (state, props) => {
    return {
        dataUser: state.authReducer.dataUser,
        openModalLoginProduct: state.authReducer.openModalLoginProduct
    }
};
const mapDispatchToProps = (dispatch)=>{
    return {
        setDataUser: (dataUser) => dispatch(authActions.setDataUser(dataUser))
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute);
