import React,{lazy} from 'react';
import * as links from "../constants/links";
import EnableUser from "../components/auth/EnableUser";
import LoginSocial from "../components/auth/LoginSocial";
import InformationAdditional from "../components/client/information/InformationAdditional";
import ReadShareDocuments from "../components/share/ReadShareDocuments";
import PaymentReport from "../components/client/report/PaymentReport";
import EndCallPage from "../components/videocall/EndCallPage";
import RequestDetailPage from "../components/client/requestDetail/RequestDetailPage";
import {REQUEST_UPLOAD_FILE_TOKEN} from "../constants/links";
import RequestRepairPage from '../components/admin/repair/RequestRepairPage';

const Page403 = lazy(() => import("../theme/PageError/Page403"));
const Page404 = lazy(() => import("../theme/PageError/Page404"));
const Page400 = lazy(() => import("../theme/PageError/Page400"));
const Page500 = lazy(() => import("../theme/PageError/Page500"));
// const RegistrationNotLogin = lazy(() => import("../components/client/registrationInformation/RegistrationNotLogin"));

const arrayRoutes = [
    /* Page 403 */
    {
        path: links.PAGE403,
        component: () => <Page403 />,
        exact: true,
    },
    {
        path: links.PAGE400,
        component: () => <Page400 />,
        exact: true,
    },
    {
        path: links.PAGE500,
        component: ({match})=> <Page500 match={match} />,
        exact: true,
    },
    {
        path: links.ENABLE_USER,
        component: (match) => <EnableUser match={match} />,
        exact: true,
    },
    {
        path: links.LOGIN_SOCIAL,
        component: (match) => <LoginSocial match={match} />,
        exact: false,
    },
    {
        path: links.PAGE404,
        component: () => <Page404 />,
        exact: false,
    },
    // {
    //     path: links.REGISTRATION_INFORMATION,
    //     component: () => <RegistrationNotLogin />,
    //     exact: true,
    // },
    {
        path: links.EDIT_REQUEST_NOT_LOGIN,
        component: (match) => <InformationAdditional  match={match} />,
        exact: true,
    },
    {
        path: links.SHOW_SHARED_DOCUMENTS_NOT_LOGIN,
        component: (match) => <ReadShareDocuments  match={match} />,
        exact: true,
    },
    {
        path: links.PAYMENT_REPORT_REQUEST,
        component: (match => <PaymentReport match={match}/>)
    },
    {
        path: links.END_VIDEO_CALL,
        component: (match => <EndCallPage match={match}/>)
    },
    {
        path: links.REQUEST_UPLOAD_FILE_TOKEN,
        component: (match => <RequestDetailPage match={match}/>)
    },
    {
        path: links.REQUEST_REPAIR,
        component: (match) => <RequestRepairPage match={match} />,
    },
];


export default arrayRoutes;
