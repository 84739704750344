import React, {Component, /*useState*/} from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {CircularProgress, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import CustomInput from "../../../theme/CustomElements/CustomInput";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const styles = theme => ({
    viewPdfWrapper:{
        height: '95%',
        display: 'flex',
        flexDirection: 'column',
        '& .react-pdf__Page__canvas':{
            maxWidth: '100%!important',
            maxHeight: '100%!important',
            margin: 'auto',
            height: 'auto!important'
        },
        '& .react-pdf__Page__textContent':{
            // left: '0!important',
            maxWidth: '100%',
            maxHeight: '100%'
        },
        '& .react-pdf__Document':{
            height: '100%',
            '& .react-pdf__Page':{
                height: '100%'
            }
        }
    },
    changeNumber:{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        bottom: 10,
        marginTop: '1rem',
        '& .buttonBlock':{
            display: 'flex',
            margin:'5px 0',
            '& .buttonNextPre':{
                // height: 20,
                // width: 30,
                margin: '0 2.5px',
                display: 'flex',
                alignItems: 'center',
                border: '1px solid black',
                borderRadius: 5,
                justifyContent: 'center',
                padding: '5px 10px',
            },
            '& .disableButton':{
                opacity: '0.5',
                border: '1px solid #dedede',
            }
        },
        '& .inputChangePage':{
            width: 50,
            margin: '0 5px',
            '& input':{
                padding: '5px 10px!important',
                height: '20px!important'
            }
        },
        '& .inputChangePageWrapper':{
            display: 'flex',
            alignItems: 'center',
            // marginTop: '1rem',
            // width: '100%',
            justifyContent: 'center',
        },
        '& .goButton':{
            width: 'min-content',
            height: 30,
            padding: '5px 10px',
            margin: '0 5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 5,
            border: '1px solid black',
        }
    },
    loadingFile:{
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',

        '& .circleProgress':{
            margin: '10px auto'
        }
    }
})

class Pdf extends Component {
    constructor(props) {
        super(props);
        this.state={
            numPages: null,
            pageNumber: 1,
            pageInput: 1,
        }
    }
    // const [numPages, setNumPages] = useState(null);
    // const [pageNumber, setPageNumber] = useState(1);
    //changPageNumber
    handleChange = (pageNumber) => {
        if(pageNumber && typeof(pageNumber === "string")) {
            pageNumber = pageNumber.replace(/[^0-9]/g, '')
        }
        this.setState({
            pageInput: pageNumber
        })
    }
    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({
            numPages: numPages,
        })
    }
    changPageNumber = (pageNumber) => {
     this.setState({
            pageNumber: pageNumber,
            pageInput: pageNumber
        })
    }
    render() {
        const {numPages, pageNumber, pageInput} = this.state
        const {t, classes} = this.props
        return (
            <div className={classes.viewPdfWrapper}>
                <Document
                    file={this.props.src}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                    onLoadError={(error)=>console.log(error)}
                    onSourceError={(error)=>console.log(error)}
                    error={t('pdfShow.failedToLoad')}
                    loading={<div className={classes.loadingFile}>
                        <span>{t('pdfShow.loadingFile')}</span>
                        <CircularProgress className={'circleProgress'}/>
                    </div>}
                >
                    <Page pageNumber={pageNumber} />
                </Document>
                {numPages && <div className={classes.changeNumber}>
                    <div className={'buttonBlock'}>
                        <span className={`buttonNextPre ${pageNumber===1 ? "disableButton" : ""}`} onClick={()=>this.changPageNumber(pageNumber===1 ? 1 : pageNumber-1)} disabled={pageNumber===1}>&#60;</span>
                        <span className={`buttonNextPre ${pageNumber===numPages ? "disableButton" : ""}`} onClick={()=>this.changPageNumber(pageNumber===numPages ? numPages : pageNumber+1)} disabled={pageNumber===numPages}>&#62;</span>
                    </div>
                    <div className={'inputChangePageWrapper'}>{t('list.page')} <CustomInput
                        onChange={(e)=>this.handleChange(e.target.value)}
                        // type={"number"}
                        value={pageInput}
                        className={'inputChangePage'}
                    />
                        {t('list.of')} {numPages}
                        <span className={'goButton'} onClick={()=>this.changPageNumber(pageInput && pageInput > 0 && pageInput <= numPages ? parseInt(pageInput) : pageNumber)}>{t('list.go')}</span>
                    </div>
                </div>}
            </div>
        );
    }


}
export default compose(withStyles(styles), withTranslation()) (Pdf)
