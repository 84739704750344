import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";

class LogoutButton extends React.Component{
    constructor(props) {
        super(props);

        this.logout = this.logout.bind(this);
    }
    logout() {
        this.props.logout();
        this.props.setCorporation(null)
    }
    render() {
        return (
            <MenuItem
                // onClick={this.logout}
                // ref={ref}
            >
                {/*<ExitIcon />*/}
                Logout
            </MenuItem>
        )
    }
}

LogoutButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default compose(connect(null, mapDispatchToProps),withRouter)(withTranslation()(LogoutButton));