import React from "react";
import {Grid, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";

const styles = {
    validate: {
        color: '#d01631 !important',
        fontWeight: 'normal',
        fontSize: "0.8rem",
        lineHeight: "1rem",
        marginTop: "0.5rem",
        "& span":{
            fontSize: "0.8rem",
            color: '#d01631 !important',
            lineHeight:'1rem',
        }
    },
};

class ErrorInput extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const {textValidate, errorKey, classes} =this.props;
        return(
            <div>
                {textValidate && <Grid container>
                    <Grid item xs={12}>
                        { textValidate && <div className={`${classes.validate} validateCustom`}>
                            {errorKey && <span className="errorLabel">{errorKey}: </span>}
                            <span>{textValidate}</span>
                        </div> }
                    </Grid></Grid>
                }
            </div>
        )
    }
}

ErrorInput.propTypes = {
    classes: PropTypes.object.isRequired,
    textValidate: PropTypes.string,
    errorKey: PropTypes.string
};

export default withStyles(styles)(withTranslation()(ErrorInput));
