import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {customRenderShowBtnAction} from "../constantRequestPage";
import alertIcon from "../../../../images/request/Groupe 9096.png";
import {OBJECT, REPAIRED, XS} from "../../../../constants/constants";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withWidth} from "@material-ui/core";
import qs from "qs";
// import cameraIcon from "../../../../images/request/Groupe 9087.svg";
import filesIcon from "../../../../images/request/icon-files.svg";
import GeneralModal from "../../../../theme/Modal/GeneralModal";
import FilesUploadBlock from "../../../admin/FilesUploadBlock";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";

const styles = theme => ({
    filesModal: {
        backgroundColor: '#e6edfc',
        minWidth: 'calc(100% - 20px)',
        width: 'calc(100% - 150px)',
        height: 'calc(100vh - 150px)',
        borderRadius: 34,
        [theme.breakpoints.down(XS)]: {
            height: 'calc(100vh - 20px)',
        },
        '& .closeIcon': {
            '& svg': {
                '& path': {
                    fill: '#8da9d6'
                }
            }
        },
        '& .headerModalWrapper': {
            padding: '10px 20px 0px 20px',
        },
        '& .bodyModalWrapper': {
            maxHeight: 'calc(100% - 80px)',
        }
    },
    btnActionDocument: {
        position: 'relative',
    },
    iconAlertNotSeen: {
        width: 27,
        height:27,
        position: 'absolute',
        top: -15,
        right: -15,
        backgroundImage: `url(${alertIcon})`,
        backgroundRepeat: 'no-repeat',
        '&.iconAlertNotSeen2':{
            top: -7,
            right: -15,
        }
    },
})

class ButtonDocument extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openModalFiles: false,
            newDocumentByManager: false
        }
        this.handleCloseModal = this.handleCloseModal.bind(this)
    }

    handleCloseModal = (field, stateUpdate) => {
        const {location, requestDocumentManagerNotSeen} = this.props
        let stateUpdateNew = {
            [field]: false,
            modalValue: {},
        }
        if (!!stateUpdate && typeof stateUpdate === OBJECT) {
            stateUpdateNew = Object.assign(stateUpdateNew, stateUpdate);
        }
        if (field === "openModalFiles" && requestDocumentManagerNotSeen.length < 1) {
            let urlParams = qs.parse(location.search, {ignoreQueryPrefix: true});
            if (urlParams.hasOwnProperty("filters") && urlParams["filters"].hasOwnProperty("managerSawLastDocumentClienUpload")) {
                delete (urlParams["filters"]["managerSawLastDocumentClienUpload"])
                this.props.history.push(location.pathname + '?' + qs.stringify(urlParams));
                stateUpdateNew = {...stateUpdateNew, loadingList: true}
            }
        }
        this.setState(stateUpdateNew)
    }
    render() {
        const {dataClient, classes, t, requestDocumentManagerNotSeen} = this.props
        const {
            newDocumentByManager,
            requestId,
            openModalFiles,
        } = this.state;
        if (!dataClient) {
            return <></>
        }
        return (
            <>
                {customRenderShowBtnAction({
                    icon: filesIcon,
                    onClick: () => {
                        this.setState({
                            openModalFiles: true,
                            requestId: dataClient.id ?? null,
                            newDocumentByManager: dataClient.newDocumentByManager
                        })
                    },
                    iconComponent: !!(Array.isArray(requestDocumentManagerNotSeen) && requestDocumentManagerNotSeen.length && requestDocumentManagerNotSeen.includes(dataClient.id)) ?
                        <div className={classes.iconAlertNotSeen}/>
                        : "",
                    className: classes.btnActionDocument,
                    title: t("request.list.viewDocument")
                })}
                {openModalFiles && requestId && <GeneralModal
                    className={classes.filesModal}
                    open={openModalFiles}
                    handleClose={()=>this.handleCloseModal('openModalFiles')}
                    content={<FilesUploadBlock requestId={requestId} checkNewDoc={newDocumentByManager}/>}
                    saveButton={false}
                    cancelButton={false}
                    hideFooter={true}
                    iconCloseModal={
                        <div className="closeIcon">
                            <CloseIcon />
                        </div>
                    }
                />}
            </>
        );
    }
}
ButtonDocument.defaultProps = {

}

ButtonDocument.propTypes = {
    classes: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => {
    return {
        requestDocumentManagerNotSeen: state.applicationReducer.requestDocumentManagerNotSeen,
    };
};


export default compose(
    connect(mapStateToProps, null),
    withStyles(styles),
    withTranslation(),
    withRouter,
    withWidth()
)(ButtonDocument);