import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Edit,
    SimpleForm,
    EditButton,
    TextInput,
    Create,
    required, Toolbar, SaveButton
} from 'react-admin';
import Pagination from "../Pagination";

import {withStyles} from '@material-ui/core/styles';
import {styleCreate, styleEdit, styleList} from "../style";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import ListEmpty from "./ListEmpty";

const CustomToolbar = props => {
    const {t, ...newProps} = props
    return <Toolbar {...newProps}>
        <SaveButton className={"btnSave"} label={props.t("button.save")}/>
    </Toolbar>
};
const properties = [
    {
        source: "name",
        label: "reactAdmin.voiceType.name"
    },
    {
        source: "tokenAudio",
        label: "reactAdmin.voiceType.tokenAudio"
    },
];

export const VoiceTypeList = compose(withStyles(styleList), withTranslation())(({classes, t, ...props}) => {
    const {tReady, ...newProps} = props
    return (
        <List {...newProps} empty={<ListEmpty arrayColumn={properties} titleList={""}/>}
              actions={null}
            // filters={<CustomFilters classes={classes} t={t} titleList={LABEL_MAIL_TYPE}/>}
              pagination={<Pagination {...props}/>} className={classes.listWrapper}>
            <Datagrid className={classes.tableWrapper}>
                {properties.map((item, key) => {
                        return <TextField source={item.source} label={t(item.label)} sortable={false} key={key}/>;
                    }
                )}
                <EditButton className={classes.editButton}/>
            </Datagrid>
        </List>
    )
});

export const VoiceTypeEdit = compose(withStyles(styleEdit), withTranslation())(({classes, t, ...props}) => {
    const {tReady, ...newProps} = props

    return <Edit {...newProps} undoable={false} className={classes.editWrapper}>
        <SimpleForm className={classes.editSimpleForm} toolbar={<CustomToolbar t={t}/>}>
            <Form t={t} classes={classes} {...props} isFormEdit={true}/>
        </SimpleForm>
    </Edit>
});

const Form = ({t}) => {
    return (
        <React.Fragment>
            {properties.map((item, key) => {
                    return <TextInput source={item.source} fullWidth label={t(item.label)} validate={[required()]}
                                      key={key}/>
                }
            )}
        </React.Fragment>
    )
}



