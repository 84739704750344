import React, { Component } from 'react';
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
// import waitingIcon from "../../../images/request/requestAdmin/waiting.svg"
// import noGoIcon from "../../../images/request/requestAdmin/noGo.svg"
// import maybeIcon from "../../../images/request/requestAdmin/maybe.svg"
// import stopIcon from "../../../images/request/requestAdmin/stop.svg"
// import waittingActive from "../../../images/request/requestAdmin/waittingActive.svg"
// import maybeActive from "../../../images/request/requestAdmin/maybeActive.svg"
// import noGoActive from "../../../images/request/requestAdmin/noGoActive.svg"
// import stopActive from "../../../images/request/requestAdmin/stopActive.svg"
import goFilterIcon from "../../../images/request/Groupe 9099.svg"
import allIcon from "../../../images/request/Groupe 9130.svg"
import archiveIcon from "../../../images/request/Groupe 9100.svg"
import {
    LG,ARCHIVED, SM, STRING, XS, USER_TYPE_REPAIRER, UNARCHIVED_AND_NOT_REPEAT, REPEAT
} from "../../../constants/constants";
import CustomInlineSvg from "../../../theme/CustomElements/CustomInlineSvg";
import PropTypes from "prop-types";
// import GoDocActionIcon from "../../../images/request/go_doc_action.svg";
// import GoActionIcon from "../../../images/request/go_action.svg";
import qs from "qs"
import AlertCountChatNotSeen from './AdminHeaderAlert/AlertCountChatNotSeen';
import AlertCountDocumentManagerNotSeen from './AdminHeaderAlert/AlertCountDocumentManagerNotSeen';
import {NUNITO} from "../../../constants/fontCss";
import AlertCountCallManager from "./AdminHeaderAlert/AlertCountCallManager";
import {ADMIN_REQUEST} from "../../../constants/links";
import AlertCountPaymentRequestReport from "./AdminHeaderAlert/AlertCountPaymentRequestReport";
import axios from "axios";
import {API_REQUEST_CLEAR_FILTER} from "../../../constants/apiSuffix";
import * as applicationAction from "../../../_actions/application";
import DialogForm from "../../../theme/CustomElements/DialogForm";
import LoadingAction from "../../../theme/LoadingAction";
import CustomButton from "../../../theme/CustomElements/CustomButton";
import circleIcon from "../../../images/banner/circle.svg";
import GeneralModal from "../../../theme/Modal/GeneralModal";
import RequestNew from "./RequestNew";
const styles = theme => ({
    headerListWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        position: 'relative',
        // top: 15,
        width: '100%',
        paddingLeft: '4rem',
        paddingRight: '4rem',
        justifyContent: 'space-between',
        height: '100%',
        // alignItems: 'center',
        [theme.breakpoints.between(XS, SM)]: {
            display: 'block',
            paddingLeft: '1rem',
            paddingRight: '1rem',
        },
        '&  .mainBackgroundLoadingWrapper':{
            padding: '1rem',
            '& svg':{
                color: '#fff'
            }
        },
        '& .titleHeaderBlock': {
            // maxWidth: 300,
            minWidth: "260px",
            // display: 'flex',
            // alignItems: 'center',
        },
        '& .headerFilterLeft':{
            columnCount: 2,
            minWidth: 128,
            [theme.breakpoints.between(XS, SM)]: {
                display: 'flex',
                flexWrap: 'wrap',
                columnCount: "auto",
                justifyContent: 'center',
            },
        },
        '& .requestHeaderRightBlock' : {
            display: 'flex',
            flexWrap: 'wrap',
            minWidth: '500px',
            justifyContent: 'flex-end',
            alignItems: 'center',
            [theme.breakpoints.between(XS, SM)]: {
                justifyContent: 'center',
                minWidth: 'auto',
            },
        },
        '& .statusFilterBlock': {
            display: 'flex',
            flexWrap: 'wrap',
            minWidth: '300px',
            justifyContent: 'flex-end',
            alignItems: 'center',
            [theme.breakpoints.between(XS, SM)]: {
                justifyContent: 'center',
                minWidth: 'auto',
            },
            // MC remi
            // width: '300px',
            // marginRight: '5%',
            // justifyContent: 'space-between',
            // alignItems: 'center',
            // marginLeft: 'auto',
            // //marginTop: '38px',

            // '& .statusWrapper':{
            //     position: 'relative',
            //     height: '120px',
            //     borderRadius: '5px',
            //     display: 'flex',
            //     justifyContent: 'flex-end',
            //     paddingBottom:'15px',

            //     '&.all':{
            //         '&:before':{
            //             content: '""',
            //             background: `url(${iconAll}) no-repeat`,
            //             display: 'inline-block',
            //             height: '35px',
            //             width: '35px',
            //             position: 'absolute',
            //             top: '18px',
            //         },

            //     },

            //     '&.archived':{
            //             '&:before':{
            //                 content: '""',
            //                 background: `url(${iconArchived}) no-repeat`,
            //                 display: 'inline-block',
            //                 height: '35px',
            //                 width: '35px',
            //                 position: 'absolute',
            //                 top: '18px',
            //             },

            //     },

            //     '&.unarchived':{
            //             '&:before':{
            //                 content: '""',
            //                 background: `url(${iconUnarchived}) no-repeat`,
            //                 display: 'inline-block',
            //                 height: '35px',
            //                 width: '25px',
            //                 position: 'absolute',
            //                 top: '18px',
            //                },

            //      },
            //     },
            //     [theme.breakpoints.down(MD)]: {
            //         top: 100,

            //     },
            //     [theme.breakpoints.down(XS)]: {
            //         top: 80,
            //     },
        },
    },
    statusWrapper: {
        minWidth: 100,
        // background: 'rgba(255, 255, 255, 0.6)',
        // borderRadius: 8,
        height: 'fit-content',
        display: 'flex',
        // flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        fontWeight: 300,
        color: '#fff',
        fontSize: '12px',
        padding: '0px 20px 0px 20px',
        cursor: 'pointer',
        margin: '3px 0',
        textTransform: 'uppercase',
        //borderLeft: '1px dotted #fff',
        transition: '0.4s',
        backgroundPosition: '0px 9px',
        minHeight: '120px',

        // MC remi
        //fontSize:'1rem',
        // '& .statusFilterNumber':{
        //     color:'#19e0e8',
        //     fontWeight: 'normal',
        //     fontSize: '23px',
        //     lineHeight: '23px',
        // },
        // '& .statusFilterName':{
        //     color:'#fff',
        //     fontWeight: 'normal',
        // },

        '&:first-child':{
            borderLeft: 'none'
        },
        '& .numberWrapper':{
            color: '#19e0e8',
            fontSize: 15,
            fontFamily: NUNITO,
            padding: '5px',
        },
        '& svg': {
            marginRight: 5
        },
        [theme.breakpoints.only(LG)]: {
            // minWidth: 'auto',
            marginBottom: 0,
            marginTop: 0,
            padding: '0 15px'
        },
        [theme.breakpoints.down(SM)]: {
            minWidth: 100,
            padding: '0 15px',
            height: 36
        },
        [theme.breakpoints.down(XS)]: {
            minWidth: 'auto',
            padding: '0 10px',
            height: 25,
            fontSize: '0.65rem',
            // '& svg': {
            //     width: 14,
            //     height: 14
            // }
        }
    },
    statusWrapperHover: {
        '&:hover': {
            //background: '#ffffff12',
            backgroundPosition: '0px 9px',
            background: 'radial-gradient(circle, rgba(0, 159, 255, .7) -11%, rgba(100,189,255,0) 47%)',
        },
        '& svg': {
            '& path': {
                fill: '#fff'
            }
        }
    },
    statusWrapperActive: {
        backgroundPosition: '0px 9px',
        background: 'radial-gradient(circle, rgba(0, 159, 255, .7) -11%, rgba(100,189,255,0) 47%)',
        color: '#fff',
        // cursor: 'auto !important',
        // pointerEvents: 'none',
        fontWeight: 600,

        '& svg': {
            '& path': {
                fill: '#fff !important'
            }
        },
        '&.repaired': {
            '& svg': {
                '& path': {
                    fill: '#0000fe'
                }
            }
        },
        '&.go_doc': {
            '& svg': {
                '& path': {
                    fill: '#0DBE2A'
                }
            }
        },
        '&.maybe': {
            '& svg': {
                '& path': {
                    fill: '#707070'
                }
            }
        },
        '&.notBeRepaired': {
            '& svg': {
                '& path': {
                    fill: '#d24545'
                }
            }
        }
    },
    titleRequest: {
        color: '#deeeff',
        fontWeight: 400,
        // fontSize: '19px',
        marginRight: 24,
        lineHeight: '20px',
        textTransform: 'uppercase',
        fontSize: '11px',
        letterSpacing: '5px',
        [theme.breakpoints.down(SM)]: {
           // fontSize: '1.125rem',
            marginRight: 15,
           // lineHeight: '40px',
        },
        [theme.breakpoints.down(XS)]: {
            marginRight: 10,
           // lineHeight: '25px',
           // fontSize: '0.9rem',
        }
    },
    btnClearFilterWrapper:{
        minHeight: 20,
       '& > div': {
           color: '#2020ac',
           cursor: 'pointer',
           '&:hover':{
               opacity: 1
           },
           background: '#fff',
           padding: '0.5rem 1rem',
           width: 'fit-content',
           maxWidth: 200,
           borderRadius: 25,
           margin: 5,
           opacity: 0.8
       }
    },
    btnAdd: {
        backgroundImage: 'linear-gradient(95deg,#00c1d1, #0070c7, #00c1d1) !important',
        boxShadow: '0px 4px 20px rgba(15, 15, 137, 0.1) !important',
        borderRadius: 55,
        width: 280,
        maxWidth: '365px',
        height: 75,
        backgroundSize: '200%',
        transition: 'all .4s ease-in-out',
        textTransform: 'none',
        paddingRight: '20px',
        margin: 5,
        '&:before': {
            background: 'none!important'
        },
        '& .MuiButton-startIcon': {
            marginRight: '15px',
            position: 'relative',
            top: '-2px',
            left: '-3px',
        },
        '& svg': {
            width: 50,
            height: 50
        },
        '& .MuiButton-label': {
            fontFamily: NUNITO,
            fontSize: '1.225rem',
            lineHeight: '1.3rem',
            fontWeight: '700',

        },
        '&:hover': {
            backgroundPosition: '100% 0',
            transition: 'all .4s ease-in-out',
        },
        [theme.breakpoints.down(XS)]: {
            width: '250px !important',
            paddingRight: '27px',
            height: '60px !important',
            '& .MuiButton-label': {
                fontSize: '1rem',
                lineHeight: '1rem',
                maxWidth: '194px',
            },
            '& .MuiButton-startIcon': {
                marginRight: '5px',
                left: '-6px',
            },
            '& svg': {
                width: '34px',
                height: '36px',
            }
        }
    },
    requestNewModal: {
        width: 1200,
        maxWidth: 'calc(100% - 60px)',
        //backgroundColor: '#f6f8fd',
        backgroundColor: '#f5f7fd',
        '& .headerModalWrapper': {
            display: 'none'
        },
        '& .bodyModalWrapper': {
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: 'calc(100vh - 160px)',
            '&::-webkit-scrollbar': {
                width: 9,
                height: 9
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#0000ff',
                borderRadius: 5
            },
        },

        [theme.breakpoints.down(XS)]:{
            maxWidth: 'calc(100% - 10px)',
        }
    },

})

class RequestHeaderAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogAction: false,
            open: false,
            error: ""
        }
    }

    handleCloseModal = () => {
        this.setState({
            open: false,
            error: ""
        })
    }

    openAddModal = ()=>{
        this.setState({
            open: true,
        })
    }
    showStatus = () => {
        const { t, countRequestByStatus, classes, statusCode, statusListFilter } = this.props
        let statusList = [];
        if (statusListFilter) {
            statusList = [...statusListFilter];
        } else {
            statusList = [{
                name: t('request.status.unarchivedAndNotRepeat'),
                icon: goFilterIcon,
                // iconActive: maybeActive,
                number: (countRequestByStatus && countRequestByStatus[UNARCHIVED_AND_NOT_REPEAT]) ?? 0,
                status: UNARCHIVED_AND_NOT_REPEAT,
            },
                {
                    name: t('request.status.repeat'),
                    icon: allIcon,
                    // iconActive: <BookmarkIcon />,
                    number: (countRequestByStatus && countRequestByStatus.repeat) ?? 0,
                    status: REPEAT
                },
                {
                    name: t('request.status.archived'),
                    icon: archiveIcon,
                    // iconActive: noGoActive,
                    number: (countRequestByStatus && countRequestByStatus.archived) ?? 0,
                    status: ARCHIVED,
                }
                //     {
                //     name: t('request.status.all'),
                //     icon: allIcon,
                //     // iconActive: <BookmarkIcon />,
                //     number: (countRequestByStatus && countRequestByStatus.all) ?? 0,
                //     status: STATUS_ALL
                // }
                ]
            // }
        }
        const statusCodeArr = statusCode && Array.isArray(statusCode) && statusCode.length ? statusCode: []
        return statusList.map((item, key) => {
            return <div key={key} className={`${classes.statusWrapper} ${item.status} statusWrapper ${ statusCodeArr.includes(item.status) ? classes.statusWrapperActive : classes.statusWrapperHover}`} onClick={() => {
                // if(!(statusCodeArr.includes(item.status) && statusCodeArr.length === 1)) {
                    this.props.changeStatusClick(item.status)
                // }
            }}>
                {typeof item.icon === STRING ? <CustomInlineSvg
                    svgProps={{
                        src: statusCodeArr.includes(item.status)  && item.iconActive ? item.iconActive : item.icon
                    }}
                /> : (typeof item.icon === "object" ? item.icon : "")}
                <div className={"numberWrapper"}>
                    {item.number}
                </div>{item.name}
            </div>

            // MC remi
            //<span className="statusFilterNumber">{item.number}</span>
            //<span className="statusFilterName">{item.name}</span>


        })
    }
    onActionClearFilter = ()=>{
        const {classes, apiParamsList, dataUser, location, t} = this.props;
        const formData = new FormData();
        if(apiParamsList.startDate) {
            formData.append('startDate', apiParamsList.startDate??"");
        }
        if(apiParamsList.endDate) {
            formData.append('endDate', apiParamsList.endDate??"");
        }
        this.setState({
            dialogAction: false,
            isLoadingBlock: true
        })
        axios.post(API_REQUEST_CLEAR_FILTER, formData)
            .then( res=> {
                    if (res.status === 200) {
                        let apiParamCount ={...apiParamsList};
                        delete apiParamCount["adminNotSawPaymentReport"]
                        delete apiParamCount["managerSawLastDocumentClientUpload"]
                        delete apiParamCount["managerSawClientLastChat"]

                        if(this.props.getCountRequestByStatus) {
                            this.props.getCountRequestByStatus(apiParamCount);
                        }
                        this.props.getRequestDocumentManagerNotSeen(apiParamCount)
                        this.props.getCountChatNotSeen(apiParamCount)
                        this.props.getCountUpcomingAppointment(apiParamCount)
                        this.props.getCountPaymentRequestReport(apiParamCount)
                        let urlParams = qs.parse(location.search, {ignoreQueryPrefix: true});
                        if(Object.keys(urlParams).length){
                            let urlParamsNew = {}
                            if(urlParams.upcomingAppointment){
                                urlParamsNew= {upcomingAppointment: 1}
                            }
                            this.props.history.push(location.pathname+"?"+qs.stringify(urlParamsNew));
                        }
                        this.setState({
                            isLoadingBlock: false
                        })
                    }else {
                        throw new Error();
                    }
                }
            )
            .catch(err=>{
                this.setState({
                    isLoadingBlock: false
                })
            })
    }
    openDialogAction = () =>{
        this.setState({
            dialogAction: true
        })
    }
    render() {
        const {classes, apiParamsList, dataUser, location, t} = this.props;
        const userGroupRole = dataUser?.data?.group
        const pathName = (location?.pathname) ?? ""
        const {open, error} = this.state
        return (
            <div className={`${classes.headerListWrapper} headerListWrapper`}>
                {this.state.isLoadingBlock ? <LoadingAction isLoadingBlock={true}/> :
                    <React.Fragment>
                        <div className={"titleHeaderBlock"}>
                            {userGroupRole !== USER_TYPE_REPAIRER ?
                            <div className={classes.btnClearFilterWrapper} onClick={() => this.openDialogAction()}>
                                <div>
                                    {t("request.seenAllFilter")}
                                </div>
                            </div>: <div className={classes.btnClearFilterWrapper} />}
                            {pathName === ADMIN_REQUEST && <div className={"headerFilterLeft"}>
                                {/*<span className={classes.titleRequest}>{titleHeader ? titleHeader : t('request.titleRequest')}</span>*/}
                                <AlertCountPaymentRequestReport apiParamsList={apiParamsList}
                                                                changeStatusToAllFilter={this.props.changeStatusToAllFilter}/>
                                <AlertCountChatNotSeen apiParamsList={apiParamsList}
                                                       changeStatusToAllFilter={this.props.changeStatusToAllFilter}/>
                                <AlertCountDocumentManagerNotSeen apiParamsList={apiParamsList}
                                                                  changeStatusToAllFilter={this.props.changeStatusToAllFilter}/>
                                {/*{userGroupRole !== USER_TYPE_REPAIRER &&*/}
                                <AlertCountCallManager apiParamsList={apiParamsList}
                                                       changeStatusToAllFilter={this.props.changeStatusToAllFilter}/>
                            </div>}
                            {/*{pathName === ADMIN_REQUEST && <AlertCountPaymentRequestReport apiParamsList={apiParamsList} changeStatusToAllFilter={this.props.changeStatusToAllFilter}/>}*/}
                        </div>
                        <div className="requestHeaderRightBlock">
                            {pathName === ADMIN_REQUEST && <CustomButton
                                className={classes.btnAdd + " btnAdd"}
                                title={t("request.btnAddRequest")}
                                buttonProps={{
                                    startIcon: <CustomInlineSvg svgProps={{ src: circleIcon }} />
                                }}
                                onClick={this.openAddModal}
                            />}
                            <div className="statusFilterBlock">
                                {this.showStatus()}
                            </div>
                        </div>

                    </React.Fragment>}
                {!!(this.state.dialogAction) && <DialogForm
                    styleNumber={2}
                    dialogProps={{
                        open: !!(this.state.dialogAction)
                    }}
                    disagreeButtonProps={{
                        handleDisagree: () => this.setState({dialogAction: null})
                    }}
                    agreeButtonProps={{
                        handleAgree: () => this.onActionClearFilter()
                    }}
                />}
                {open && <GeneralModal
                    open={open}
                    hideHeaderModal={true}
                    content={<RequestNew onClose={this.handleCloseModal} loadRequest={(newItem) => {
                        if (this.props.getNewItem && newItem) this.props.getNewItem(newItem);
                        this.props.getCountRequestByStatus()
                    }} />}
                    saveButton={false}
                    cancelButton={false}
                    enableOnClose={false}
                    error={error}
                    className={classes.requestNewModal}
                />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dataUser: state.authReducer.dataUser,
        countRequestByStatus: state.applicationReducer.countRequestByStatus,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCountRequestByStatus: (apiParamCount) => dispatch(applicationAction.getCountRequestByStatus(apiParamCount)),
        getCountUpcomingAppointment: () => dispatch(applicationAction.getCountUpcomingAppointment()),
        getCountReportRequestNotPaid: () => dispatch(applicationAction.getCountReportRequestNotPaid()),
        getCountChatNotSeen: (apiParams) => dispatch(applicationAction.getCountChatNotSeen(apiParams)),
        getCountPaymentRequestReport: (apiParams) => dispatch(applicationAction.getCountPaymentRequestReport(apiParams)),
        getRequestDocumentManagerNotSeen: (apiParams) => dispatch(applicationAction.getRequestDocumentManagerNotSeen(apiParams)),
    }
};

RequestHeaderAdmin.defaultProps = {
    apiParamsList: {},
    changeStatusClick: (item)=>{},
    changeStatusToAllFilter: ()=>{}
}

RequestHeaderAdmin.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    changeStatusClick: PropTypes.func,
    titleHeader: PropTypes.string,
    statusListFilter: PropTypes.array,
    apiParamsList:PropTypes.object,
    changeStatusToAllFilter: PropTypes.func
}


export default compose(connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter
)(RequestHeaderAdmin);
