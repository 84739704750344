import React from "react";
import AppAvatar from "./AppAvatar";
// import {Tooltip} from "@material-ui/core";
import {Link} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import {compose} from "redux";
import {connect} from "react-redux";

const styles = {
    avatar:{
        "& a:visited": {
            color: "inherit"
        }
    },
    avatarImg:{
        "& img":{
            objectFit: 'contain'
        }
    }
};

class AppPersonAvatar extends React.Component{
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const defaultOptions = {
            color:"#00d5ff",
            fgColor:"white"
        };
        // const userRoles = this.props.dataUser.item.allRoles;

        let {name,personIdForUrl,classes,avatarImgClassName,...otherProps} = this.props;
        if (!avatarImgClassName){
            avatarImgClassName = "";
        }
        avatarImgClassName += " "+classes.avatarImg;
        let AvatarComponent;
        if (typeof name === "string"){
            // AvatarComponent = <Tooltip title={name}><AppAvatar {...defaultOptions} {...otherProps} className={avatarImgClassName} name={name}/></Tooltip>
            AvatarComponent = <AppAvatar {...defaultOptions} {...otherProps} className={avatarImgClassName} name={name}/>
        }
        else{
            AvatarComponent = <AppAvatar {...defaultOptions} {...otherProps} className={avatarImgClassName}/>;
        }
        if (personIdForUrl){
            return (
                    // checkPermission([ROLE_PERSON_VIEW_PROFILE], userRoles) ?
                        <Link className={classes.avatar}
                              // to={links.PERSON_DETAILS.replace(":id",personIdForUrl)}
                        >{AvatarComponent}</Link>
                        // :
                        // AvatarComponent
                )

        }
        return AvatarComponent;
    }
}

// export default withStyles(styles)(AppPersonAvatar);

const mapStateToProps = (state) => {
    return {
        dataUser: state.authReducer.user,
    }
};

export default compose(
    connect(
        mapStateToProps,
        null
    ),
    withStyles(styles),
) (AppPersonAvatar);
