import React, { useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import useChat from "./useChat";
import {useTranslation} from "react-i18next";
import {CircularProgress, FormControl, IconButton, TextField } from "@material-ui/core";
import CustomInlineSvg from "../../theme/CustomElements/CustomInlineSvg";
import SendIcon from '@material-ui/icons/Send';
import axios from "axios";
import {API_ADD_CHAT, API_GET_SHOW_REQUEST, API_UPDATE_LAST_VIEW_CHAT} from "../../constants/apiSuffix";
import ChatMessage from "./ChatMessage";
import {connect, useDispatch, useSelector} from "react-redux";
import { toggleNotification} from "../../_actions/application";
import { MD, REACT_APP_MECURE_URL, XS} from "../../constants/constants";
import blikco_assistance from "../../images/chat/blikco_assistance.png";
import logoBlicko from "../../images/chat/logo.png";
import chatIcon from "../../images/chat/chat.svg";
import {NUNITO, SEGOEUI} from "../../constants/fontCss";
import paperclip from "../../images/chat/paperclip.svg";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from "react-dropzone";
import playButton from "../../images/theme/playButton.svg";
import zoomIn from "../../images/theme/zoomIn.svg";
import closeButton from "../../images/theme/close.svg";
import docIcon from "../../images/request/doc.svg";
import DownloadDoc from "../../images/request/download-arrow.svg";
import ContentPreviewModal2 from "../../theme/Modal/ContentPreviewModal2";
import GeneralModal from "../../theme/Modal/GeneralModal";
import * as application from "../../_actions/application";
import {compose} from "redux";
import HeaderChat from "./HeaderChat";
import VideoCallBlock from "../videocall/VideoCallBlock";

const useStyles = makeStyles((theme)=>({
    chatBoxWrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& > div': {

        }
    },
    chatBoxHeader: {
        backgroundColor: '#0000ff',
        backgroundImage: 'linear-gradient(95deg, #afdbfd 11%, #b7f2f2 105%)',
        height: 145,
        color: '#fff',
        fontWeight: 700,
        fontSize: '1rem',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        padding: '0 20px',
        position: 'relative',
        // paddingLeft: 80,
        '& .closePopover':{
            cursor: 'pointer',
            margin: '20px 0',
            padding: '0.5rem',
            borderRadius: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 999,
            '&:hover':{
               background: 'rgba(0, 0, 0, 0.1)'
            },
            '& svg path':{
                stroke: '#fff'
            }
        },
        '& .headerLeft': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            zIndex: 999,
            height: '100%',
            paddingLeft: 60,
            [theme.breakpoints.between(XS, MD)]: {
                paddingLeft: 10,
            },
            '& .chatIcon': {
                padding: '0 20px',
            },
            '& .logoRight': {
                textTransform: 'uppercase',
                fontFamily: NUNITO,
                '& .underLogo1': {
                    fontSize: '1.4rem',
                    fontWeight: 'bold',
                    color: '#fff'
                },
                '& .underLogo2': {
                    fontSize: '1rem',
                    fontWeight: 400,
                    color: '#0072c7',
                }
            }
        },
        '& .headerRight': {
            width: '100%',
            height: '145px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& .headerRightBG': {
                position: 'absolute',
                right: 0,
                top: 0,
                bottom: 0,
                width: 480,
                backgroundImage: `url(${blikco_assistance})`,
            },
            '& .client': {
                zIndex: 9,
                color: '#0072c7',
                fontSize: '1.2rem',
                fontWeight: '500',
                textTransform: 'uppercase'
            }
        },
        '& .label': {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        '& .btnNotification': {
            '& svg path': {
                fill: '#fff'
            }
        },
        '& .requestInfo': {
            fontSize: '1.2rem'
        }
    },
    chatBoxRequestInfo: {
        padding: '0 40px',
        height: 140,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.between(XS, MD)]: {
            padding: '0 20px',
            '& .round': {
                padding: '16px 20px',
                flexWrap: 'wrap',
                justifyContent: 'center',
                // overflowY: 'auto'
            }
        },
    },
    chatBoxListMessage: {
        // height: 'calc(100% - 365px)',
        // height: 'calc(100% - 445px)',
        flex: 1,
        overflowY: 'auto',
        margin: '0 40px',
        '&::-webkit-scrollbar': {
            height: 9,
            width: 9
        },
        '&::-webkit-scrollbar-track': {
            background: '#fff',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 6,
            background: '#0000fe',
            zIndex: 999,
        },
        '& .loadingMore': {
            textAlign: 'center',
            paddingTop: 10,
            '& svg': {
                '& circle': {
                    stroke: "#555555"
                }
            }
        },
    },
    chatItem: {
        // height: 100,
        // backgroundColor: '#ebf2f8',
        margin: '5px 0'
    },
    chatBoxInput: {
        // position: 'relative',
        height: 110,
        padding: '0 16px',
        paddingBottom: '25px',
        display: 'flex',
        justifyContent:"space-between",
        alignItems: 'center',
        '& .chatInputContentBox':{
            width: 'calc( 100% - 95px )',
            position: 'relative',
        },
        '& .content': {
            height: '100%',
            borderRadius: '0 0 13px 13px',
            border: 'none',
            // paddingBottom: '25px',
            '& .MuiFormControl-root': {
                height: '100%',
                '& .MuiInputBase-root': {
                    height: '100%',
                }
            },
            '& textarea': {
                //backgroundColor: 'rgba(62, 128, 214, 0.54)',
                // backgroundColor: 'rgba(6, 98, 217, 0.54)',
                color: '#fff',
                fontFamily: SEGOEUI,
                fontWeight: 'normal',
                fontSize: '1.05rem',
                padding: '13px 10px',
                paddingLeft: '20px',

                '&::placeholder': {
                    opacity: '0.8 !important',
                    fontWeight: 'normal',
                    fontSize: '0.95rem',
               },
            },

        }
    },
    btnDropZone: {
        width: 45,
        height: 45,
        '& .dropzoneUploadFileChat':{
            display: 'flex',
            alignItems: 'center'
        },
        '& svg': {
            cursor: 'pointer',
            '& path': {
                fill: '#5886b4'
            },
        },
        '& input': {
            display: 'none',
        }
    },
    btnSendMessage: {
        '& svg path': {
            fill: '#0000fe'
        }
    },
    chatBoxDocument: {
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'flex-end',
        position: 'absolute',
        bottom: 100,
        // right: 60,
        // left: 40,
        right: 0,
        left: 0,
        backgroundColor: '#e6edfc',
        overflowX: 'auto',
        padding: '20px 0',
        borderRadius: '20px 20px 0 0',
        '&::-webkit-scrollbar': {
            width: '7px',
            height: '7px'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor:'#5072ee',
            borderRadius: 7
        },
        '& img, & video':{
            width: 90,
            height: 90,
            position: 'absolute',
            zIndex: 0,
            objectFit: 'contain'
        },
        '&:hover > .playButton':{
            opacity: 1,
            visibility: 'visible',
            filter: 'blur(4px)',
        },
        // '& > div': {
        //     // width: 50,
        //     // height: 50,
        //     // minWidth: 50,
        //     overflow: 'hidden',
        //     margin: '0 10px',
        //     '& img': {
        //         width: '100%',
        //         height: '100%'
        //     }
        // }
    },
    documentWrapper:{
        position: 'relative',
        display: 'flex',
        padding: '0.25rem',
        cursor: 'pointer',
        height: 100,
        minWidth: 100,
        maxWidth: 100,
        margin: '0 10px',
        backgroundColor:'#fff',
        borderRadius: 10,
        '& .playButton':{
            width: '100px',
            height:'100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
            opacity: 0,
            '& svg':{
                fill: '#635151!important'
            },
            [theme.breakpoints.down(XS)]:{
                opacity: 1,
                visibility: 'visible',
            }
        },
        '& .closeButton': {
            position: 'absolute',
            top: -10,
            right: -10,
            backgroundColor: '#5072ee',
            borderRadius: '50%',
            width: 25,
            height: 25,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
                fill: '#fff'
            }
        },
        '&:hover > .playButton':{
            opacity: 1,
            visibility: 'visible',
            // filter: 'blur(4px)',
        },
        '&:hover':{
            // filter: 'blur(4px)',
            '& .playButton':{
                opacity: 1,
            },
            '& .closeButton':{
                opacity: 1,
                visibility: 'visible',
            },
            '& img, & video, & .doc':{
                filter: 'blur(4px)',
            }
        },
        [theme.breakpoints.down(XS)]:{
            '& img, & video, & .doc':{
                filter: 'blur(2px)',
            }
        }
    },
    previewImage: {
        width: '100%',
        height: '100%',
        // borderRadius: '20px',
        objectFit: 'contain'
    },
    previewDoc: {
        width: 100,
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        position: 'absolute',
        '& svg': {
            '& path': {
                stroke: '#0070c7'
            }
        }
    },
    previewVideo: {
        width: '100%',
        height: '100%',
        borderRadius: '20px',
        objectFit: 'cover!important',
        [theme.breakpoints.down(XS)]:{
            background: '#dedede'
        }
    },
    customInput: {
        // border: '1px solid #E0E0E0',
        borderRadius: 6,
        backgroundColor: 'rgba(6, 98, 217, 0.54)',
        '& textarea': {
            border: 'none',
            height: 23,
            padding: '8px 10px',
            fontSize: '0.875rem',
            // marginBottom: '0.2rem'
            borderRadius: 6,
        },
        '& fieldset':{
            border: 'none'
        },
        '&.validateStyle': {
            border: '1px solid #ff0000'
        },
        '&.inputDetail':{
            '& .Mui-disabled':{
                color: 'inherit'
            }
        }
    },
    btnVideoCallBox: {
        padding: 10,
        '& .buttonVideoCallAdminWrapper':{
            padding: 5
        }
    }
}))
const listChatRealtime = [];
let playAudio = true;
const ChatBox = (props) => {
    const [checkInit, setCheckInit] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [content, setContent] = useState('');
    const [loadingSend, setLoadingSend] = useState(false);
    const [callMessage, setCallMessage] = useState(false);
    const [chatCurrent, setChatCurrent] = useState(null);
    const [scrollBottom, setScrollBottom] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [valueModal, setValueModal] = useState(null)
    const { t, } = useTranslation();
    const chatInputEl = useRef(null);
    const dispatch = useDispatch()

    const {
        dataRequest,
        countChatNotSeen
    } = props;
    const {
        chatMessages,
        hasMore,
        loading,
    } = useChat(pageNumber, dataRequest.id, callMessage, chatCurrent);
    const classes = useStyles();
    const chatListEl = useRef(null);
    const [dataVideoCall, setDataVideoCall] = useState(null);
    const dataUser = useSelector((state)=> state.authReducer.dataUser);
    const idCurrentUser = dataUser && dataUser.data && dataUser.data.id ? dataUser.data.id : null;
    const [request, setRequest] = useState(null);
    useEffect(() => {
        axios.get(API_GET_SHOW_REQUEST.replace(':id', dataRequest.id))
            .then(res => {
                if (res.status === 200) {
                    setRequest(res.data.item);
                } else {
                    setRequest(dataRequest)
                }
            })
            .catch(err => {
                setRequest(dataRequest)
            })
        updateLastTImeLastView()
        try{
            const eventSource = new EventSource(REACT_APP_MECURE_URL+"?topic=chat",{
                withCredentials: false
            });
            eventSource.onmessage = event => {
                const data = JSON.parse(event.data);
                const chat = data["chat"];
                if (chat && chat.id) {
                    updateLastTImeLastView()
                    getRealTime(chat);
                }
            }
            eventSource.onerror = () => {
                eventSource.close()
            }
        }
        catch (err){

        }

        try {
            const eventSource = new EventSource(REACT_APP_MECURE_URL+"?topic=videoCall",{
                withCredentials: false
            });
            eventSource.onmessage = event => {
                const dataVideoCall = JSON.parse(event.data);
                const videoCall = dataVideoCall["videoCall"];
                if (videoCall.request === dataRequest.id) {
                    setDataVideoCall({
                        meetingId: videoCall.meetingId,
                        status: videoCall.status,
                    })
                }
            }
            eventSource.onerror = () => {
                eventSource.close()
            }
        } catch (e) {
            // console.log(e);
        }
    }, [])

    const updateLastTImeLastView = () => {
        axios.post(API_UPDATE_LAST_VIEW_CHAT.replace('{id}', dataRequest.id))
            .then(res => {
                if (countChatNotSeen.includes(dataRequest.id)) {
                    props.setCountChatNotSeen(countChatNotSeen.filter(item => {
                        return item !== dataRequest.id;
                    }))
                }
            })
            .catch(err => {
            })
    }


    const getRealTime = (chat) => {
        if (!listChatRealtime.includes(chat.id) && chat.request && chat.request.id == dataRequest.id) {
            listChatRealtime.push(chat.id)
            const senderId = chat.sender ? chat.sender.id ?? null : null;
            if (senderId != idCurrentUser) {
                // if (playAudio) {
                //     audio.play();
                // }
                setChatCurrent(chat);
                setScrollBottom(prev => {
                    return !prev;
                })

            }
        }

    }
    useEffect(() => {
        chatListEl.current.scrollIntoView({ behavior: 'smooth' })
    }, [scrollBottom])

    useEffect(() => {
        if (!!chatMessages && chatMessages.length > 0 && !checkInit) {
            setCheckInit(true);
            chatListEl.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [chatMessages])

    const chatMessagesClone = [
        ...chatMessages
    ]
    chatMessagesClone.reverse()
    const sendMessage = () => {
        if ((content != undefined && content != '') || (Array.isArray(documents) && documents.length > 0)) {
            setLoadingSend(true);
            const formData = new FormData();
            formData.append("chat[content]", content ?? "");
            if (Array.isArray(documents)) {
                documents.forEach((item, index) => {
                    formData.append('chat[documents]['+index+'][documentFile]', item);
                })
            }
            axios.post(API_ADD_CHAT.replace("{requestId}", dataRequest.id), formData)
                .then(response => {
                    if (response.status === 200) {
                        setLoadingSend(false);
                        setContent('');
                        setDocuments([]);
                        setCallMessage(prev => {
                            return !prev;
                        });
                        setChatCurrent(response.data.item);
                        setScrollBottom(prev => {
                            return !prev;
                        })
                        if (chatListEl?.current?.scrollIntoView) {
                            chatListEl.current.scrollIntoView(false)
                        }
                        if (chatInputEl.current) {
                            chatInputEl.current.focus()
                        }
                    } else {
                        throw new Error();
                    }
                })
                .catch(error => {
                    setLoadingSend(false);
                    if (chatInputEl.current) {
                        chatInputEl.current.focus()
                    }
                })
        }
    }
    const  handleScroll = e => {
        let element = e.target;
        if (element.scrollTop===0) {
            if (loading) return
            if (hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        }
    }
   const  enterSendMessage = (event)=> {
        if (event.key === 'Enter' || event.charCode === 13) {
            // Do code here
            event.preventDefault();
            sendMessage(true)
        }
    }

    const toggleOnOffNotification = (notification) => {
        playAudio = notification;
        dispatch(toggleNotification(notification))
    }

    const viewDocumentItem = (item, index) => {
        const mimeType = item instanceof File ? item.type : item.mimeType;
        const url = item instanceof File ? URL.createObjectURL(item) : item.pathUrl;
        if (mimeType && mimeType.includes("video")) {
            return <React.Fragment>
                <div className={classes.documentWrapper} key={index}>
                    <div className={"closeButton"}
                                     onClick={()=>{
                                         removeFile(index)
                                     }}
                    >
                        <CustomInlineSvg
                            svgProps={{
                                src: closeButton,
                            }}
                            scale="0.6"
                        />
                    </div>
                    <video controls={false} className={classes.previewVideo}>
                        <source src={url}/>
                    </video>
                    <div onClick={() => handleOpenModal(index)}
                         className={"playButton"}
                    >
                        <CustomInlineSvg
                            svgProps={{
                                src: playButton,
                            }}
                            scale="0.3"
                        />
                    </div>
                </div>
            </React.Fragment>
        } else if (mimeType && mimeType.includes("image")) {
            return <React.Fragment>
                <div className={classes.documentWrapper} key={index}>
                    <div className={"closeButton"}
                                     onClick={()=>{
                                         removeFile(index)
                                     }}
                    >
                        <CustomInlineSvg
                            svgProps={{
                                src: closeButton,
                            }}
                            scale="0.6"
                        />
                    </div>
                    <img className={classes.previewImage} alt={'file upload'}
                         src={url}/>
                    <div onClick={() => handleOpenModal(index)}
                         className={"playButton"}>
                        <CustomInlineSvg
                            svgProps={{
                                src: zoomIn,
                            }}
                            scale="0.2"
                        />
                    </div>
                </div>
            </React.Fragment>
        } else return <div className={classes.documentWrapper} key={index}>
            <div className={"closeButton"}
                             onClick={()=>{
                                 removeFile(index)
                             }}
            >
                <CustomInlineSvg
                    svgProps={{
                        src: closeButton,
                    }}
                    scale="0.6"
                />
            </div>
            <div className={classes.previewDoc + " doc"}>
                <CustomInlineSvg
                    svgProps={{
                        src: docIcon,
                    }}
                />
            </div>
            {
                (mimeType && mimeType.includes("pdf")) ?
                    <div onClick={() => handleOpenModal(index)}
                         className={"playButton"}>
                        <CustomInlineSvg
                            svgProps={{
                                src: zoomIn
                            }}
                            scale="0.2"
                        />
                    </div>
                    :
                    <a href={url} target="_blank" className={"playButton"}>
                        <CustomInlineSvg
                            svgProps={{
                                src: DownloadDoc,
                            }}
                            scale="0.5"
                        />
                    </a>
            }
        </div>
    }

    const onChangeDocument = (value) => {
        setDocuments(prevState => {
            return prevState.concat(value)
        })
    }

    const handleOpenModal = (data) => {
        setOpenModal(true);
        setValueModal({
            key: data
        })
    }
    const handleCloseModal = () => {
        setOpenModal(false);
        setValueModal(null)
    }
    const removeFile = (keyDoc) =>{
        setDocuments(prevState => {
            return prevState.filter((item, index) => {
                return index !== keyDoc;
            })
        })
    }

    return (
        <div className={classes.chatBoxWrapper}>
            <div className={classes.chatBoxHeader}>
                <div className="closePopover" onClick={()=>{if(props.onClose){props.onClose()}}}>
                    <CustomInlineSvg  svgProps={{
                        src: closeButton
                    }}/>
                </div>
                <div className="headerLeft">
                    <div className="chatIcon">
                        <CustomInlineSvg  svgProps={{
                            src: chatIcon
                        }}/>
                    </div>
                    <div className="logoRight">
                        <img src={logoBlicko} alt=""/>
                        <div className="underLogo1">
                            {t("chat.chatBox.Assistance")}
                        </div>
                        <div className="underLogo2">
                            {t("chat.chatBox.response")}
                        </div>
                    </div>
                </div>
                <div className="headerRight">
                    <div className="headerRightBG"></div>
                    <div className="client">{dataRequest && dataRequest.person && dataRequest.person.fullName ? dataRequest.person.fullName : '' }</div>
                </div>
                {/*<div className="label">*/}
                {/*    {t("chat.titleChatRequest")}*/}
                {/*    <div>*/}
                {/*        <IconButton className="btnNotification" onClick={() => toggleOnOffNotification(!notification)}>*/}
                {/*            {notification ? <Tooltip title={t('chat.chatBox.turnOffNotification')}><NotificationsActiveIcon /></Tooltip> : <Tooltip title={t('chat.chatBox.turnOnNotification')}><NotificationsOffIcon /></Tooltip>}*/}
                {/*        </IconButton>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="requestInfo">*/}
                {/*    {t("chat.id")} {requestId}*/}
                {/*</div>*/}
            </div>
            <div className={classes.chatBoxRequestInfo}>
                <HeaderChat dataRequest={dataRequest}/>
            </div>
            <div className={classes.chatBoxListMessage} onScroll={ handleScroll} >
                <div className="loadingMore">
                    {
                        loading && <CircularProgress size={'1.6rem'}/>
                    }
                </div>
                {chatMessagesClone.map((chatMessage, index) => {
                    return <div className={classes.chatItem} key={index}>
                        <ChatMessage chatMessage={chatMessage}/>
                    </div>
                })}
                <div ref={chatListEl}>
                </div>
            </div>

            {request && <div className={classes.btnVideoCallBox}> <VideoCallBlock
                request={dataVideoCall ? {
                    ...request,
                    videoCalling: dataVideoCall
                } : request}
            /></div>}
            <div className={classes.chatBoxInput}>
                <IconButton
                    className={classes.btnDropZone}
                    onClick={() => {
                        // if (!loadingSend) {
                        //     sendMessage();
                        // }
                    }}
                >
                    {!loadingSend && <Dropzone
                        minSize={0}
                        maxFiles={10}
                        multiple={true}
                        onDrop={acceptedFiles => onChangeDocument(acceptedFiles)}>
                        {({getRootProps, getInputProps}) => {
                            return (
                                <section>
                                    <div {...getRootProps()}>
                                        <div className={"dropzoneUploadFileChat"}>
                                            <input {...getInputProps()} />
                                            <CustomInlineSvg svgProps={{
                                                src: paperclip
                                            }}/>
                                        </div>
                                    </div>
                                </section>)
                        }}
                    </Dropzone>}
                </IconButton>
                <div className={"chatInputContentBox"}>
                    {(Array.isArray(documents) && documents.length > 0) && <div className={classes.chatBoxDocument}>
                        {
                            documents.map((item, index) => {
                                return viewDocumentItem(item, index)
                            })
                        }
                    </div>}
                    <FormControl fullWidth className={classes.customInput}>
                        <TextField
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            placeholder={t("chat.chatBox.enterMessage")}
                            className={"content"}
                            disabled={loadingSend}
                            multiline
                            // rowsMin={2}
                            rowsMax={2}
                            // onKeyPress={(e) => {
                            //     if (!loadingSend) {
                            //         enterSendMessage(e)
                            //     }
                            // }}
                            inputRef={chatInputEl}
                        />
                    </FormControl>
                </div>
                <IconButton
                    className={classes.btnSendMessage}
                    onClick={() => {
                        if (!loadingSend) {
                            sendMessage();
                        }
                    }}
                >
                    {loadingSend ? <CircularProgress size={'1.5rem'}/> : <SendIcon/>}
                </IconButton>
            </div>
            {openModal && valueModal && <GeneralModal
                className={"previewModal"}
                open={openModal}
                handleClose={handleCloseModal}
                content={<ContentPreviewModal2 {...props} files={documents} keyFile={valueModal.key}/>}
                saveButton={false}
                cancelButton={false}
            />}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        countChatNotSeen:state.applicationReducer.countChatNotSeen,

    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setCountChatNotSeen: (value) => dispatch(application.setCountChatNotSeen(value) )
    }
};
export default compose(connect(mapStateToProps, mapDispatchToProps)) (ChatBox);
