import React from 'react';
import Google from "../../../../images/icons/google.svg";
import Facebook from "../../../../images/icons/facebook.svg";
import Blicko from "../../../../images/icons/blicko.svg";
import {
    List,
    DateField,
    TextField,
    EmailField,
    EditButton, DatagridBody, Datagrid,BooleanField,
    Filter
} from 'react-admin';
import {MyDatagridRow} from "../../MyDatagridRow";
import {withTranslation} from "react-i18next";
import {ROLE_GROUP_ADMIN} from "../../../../constants/constants";
import Pagination from "../../Pagination";
import {compose} from "redux";
import {withStyles} from "@material-ui/core/styles";
import {styleList} from "../../style";
import ListEmpty from "../ListEmpty";
import { SearchInput } from 'react-admin';
const headTranslate = 'reactAdmin.user.list.';

const RoleColumn = ({record, source, t})=>{
    let roleView = "";
    if(record[source] && Array.isArray(record[source] ) && record[source].length){
        record[source].filter(fil=> ROLE_GROUP_ADMIN.includes(fil)).map((item)=>{
            roleView += (roleView?", ":"")+t("reactAdmin.user.permission." + item);
        });
    }
    return <span>{roleView}</span>
}

const MyDatagridBody = props => <DatagridBody {...props} row={<MyDatagridRow />} />;
const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody />} />;


const properties = [
    {
        source: "email",
        label: headTranslate + 'email'
    },
    {
        source: "firstName",
        label: headTranslate + 'firstName'
    },
    {
        source: "lastName",
        label: headTranslate + 'lastName'
    },
];

const CustomGroup = ({source, record, t}) =>{
    return t("reactAdmin.user.groups." + record[source]);
}

const CustomIcon = ({ source, record }) => {
    return <div className="account-type"><img width="24" src={record[source] === 'GOO' ? Google : record[source] === 'FB' ? Facebook : Blicko } alt={record[source]} /></div>
}


const CustomFilters = (props) => {
    const {t, ...newProps} = props

    return (
        <Filter {...newProps} className={"filtersOnList"}>
            <SearchInput source="search" alwaysOn className="searchInput"/>
        </Filter>
    )
};

export const UserList = compose(withStyles(styleList), withTranslation())(({classes, t, ...props})  => {
    // const checkIsSuperAdmin = localStorage.setItem(DATA_USER).data.group === USER_TYPE_SUPER_ADMIN;
    const {tReady, ...newProps} = props
    return(  <List {...newProps} empty={<ListEmpty arrayColumn={properties} titleList={""}/>}
        // filters={<CustomFilters classes={classes} t={t} titleList={LABEL_MAIL_TYPE}/>}
                // filterDefaultValues={{ createdAt: true }}
                filters={<CustomFilters t={t}/>}
                sort={{ field: 'id', order: 'DESC' }}
                pagination={<Pagination {...newProps}/>} className={`${classes.listWrapper} userList`}>
        <Datagrid className={classes.tableWrapper}>
            <CustomIcon source="accountType"  label={t(headTranslate + 'accountType')} sortable={false} />
            <DateField source="createdAt" label={t(headTranslate + 'createdAt')} sortable={true}/>
            <EmailField source="email" label={t(headTranslate + 'email')} sortable={false}/>
            <TextField source="person.firstName" label={t(headTranslate + 'firstName')} sortable={false}/>
            <TextField source="person.lastName" label={t(headTranslate + 'lastName')} sortable={false}/>
            <CustomGroup source="instanceof" label={t(headTranslate + 'group')} t={t} sortable={false}/>
            <RoleColumn source="roles" {...props} label={t(headTranslate + 'roles')} t={t} sortable={false} />
            {/*<CustomBooleanInList source="enabled" {...props} label={headTranslate + 'enabled'} translate={translate}/>*/}
            <BooleanField source="enabled" label={t(headTranslate + 'enabled')} sortable={false}/>
            <EditButton />
        </Datagrid>
    </List>)
})

// const UserFilter = translate(({translate, ...props}) => {
//     const checkIsSuperAdmin = SESSION_USER_DATA  &&  SESSION_USER_DATA.item && SESSION_USER_DATA.item.group && SESSION_USER_DATA.item.group.code === SUPERADMIN;
//     return <Filter {...props}>
//         <TextInput label={headTranslate + 'email'} source="email" alwaysOn />
//         <TextInput label={headTranslate + 'firstName'} source="firstName" alwaysOn />
//         <TextInput label={headTranslate + 'lastName'} source="lastName" alwaysOn />
//         {checkIsSuperAdmin && <ReferenceInput label={headTranslate + 'group.name'} source="group" reference="group" allowEmpty>
//             <SelectInput optionText="name" />
//         </ReferenceInput>}
//         <SelectInput source="enabled" label={headTranslate + 'enabled'} choices={[
//             { id: true, name: translate("resources.yes") },
//             { id: false, name: translate("resources.no") },
//         ]} />
//     </Filter>
// });

