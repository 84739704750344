import Grid from "@material-ui/core/Grid";
import Dropzone from "react-dropzone-uploader";
import React from "react";
import UploadDoc from "../../../images/request/document/upload_doc.svg";
import {/*REQ_DOC_VIDEO,*/ XS} from "../../../constants/constants";
import axios from "axios";
import {INTERVENTION_IMPORT, /*UPLOAD_REQUEST_DOCUMENT*/} from "../../../constants/apiSuffix";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import LoadingAction from "../../../theme/LoadingAction";
import {styleList} from "../style";
import {Datagrid, DatagridBody, 
    // EditButton, 
    List, TextField, BooleanField, DateField, FileField} from "react-admin";
import ListEmpty from "./ListEmpty";
import Pagination from "../Pagination";
import {MyDatagridRow} from "../MyDatagridRow";
// import moment from "moment";

const styles = (theme) => ({
    uploadFilesWrapper: {
        padding: '0px 20px',
        '& header': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
        '& .MuiTab-root': {
            margin: '0 5px',
            backgroundColor: '#d6e4fc',
            minWidth: 100,
            maxWidth: 100,
            textTransform: 'initial',
            [theme.breakpoints.down(XS)]: {
                minWidth: 90,
                maxWidth: 90,
            },
        },
        '& .Mui-selected': {
            backgroundColor: '#bed5f9'
        },
        '& .MuiTabs-indicator': {
            backgroundColor: '#bed5f9'
        },
        // dropzone Style
        '& .dzu-dropzone': {
            height: 400,
            overflow: 'unset',
            backgroundColor: '#bed5f9',
            border: 'none',
            [theme.breakpoints.down(XS)]: {
                height: 270,
            },
            '& .dzu-inputLabel': {
                border: '1px dashed #737abd',
                position: 'absolute',
                top: 10,
                right: 10,
                bottom: 10,
                left: 10,
            }
        }
    },
    inputUploadFile: {
        padding: '10px 0',
        textAlign: 'center',
        '& img': {
            [theme.breakpoints.down(XS)]: {
                height: 100
            },
        },
        '& .textLabel': {
            width: 160,
            fontSize: '1.25rem',
            textAlign: 'center',
            fontWeight: 'bold',
            color: 'rgba(50, 50, 146, 0.57)',
            [theme.breakpoints.down(XS)]: {
                fontSize: '0.9rem',
            },
        }
    },
});

const properties = [
    {
        source: "updatedAt",
        label: "import_intervention.list.updatedAt"
    },
    {
        source: "executedAt",
        label: "import_intervention.list.executedAt"
    },
    {
        source: "updated",
        label: "import_intervention.list.updated"
    },
    {
        source: "fileExcel.pathUrl",
        label: "import_intervention.list.file"
    }
];
const MyDatagridBody = props => <DatagridBody {...props} row={<MyDatagridRow />} />;
const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody />} />;
export const MailTypeList = compose(withStyles(styleList))(({classes, t, ...props}) => {
    const {tReady, staticContext, ...newProps} = props

    return (
        <List {...newProps} empty={<ListEmpty arrayColumn={properties} titleList={""}/>}
              actions={null}
              pagination={<Pagination {...props}/>} className={classes.listWrapper}>
            <Datagrid className={classes.tableWrapper}>
                {properties.map((item, key) => {
                    switch (item.source) {
                        case "updatedAt":
                        case "executedAt":
                            return (
                                <DateField key={key} showTime source={item.source}
                                           label={t(item.label)} sortable={false}/>
                            )

                        case "updated":
                            return (
                                <BooleanField key={key} source={item.source} label={t(item.label)}
                                              sortable={false}/>
                            );

                        case "fileExcel.pathUrl":
                            return (
                                <FileField key={key} source={item.source} label={t(item.label)}
                                           sortable={false} title={t('import_intervention.label.fileExcel')}/>
                            );
                        default:
                            return (
                                <TextField key={key} source={item.source}
                                           label={t(item.label)} sortable={false}/>
                            )
                    }
                })}
                {/*<EditButton className={classes.editButton}/>*/}
            </Datagrid>
        </List>
    )
});

class ImportIntervention extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // dropzone
            preparing: 0,
            done: 0,
            removed: 0,
            maxFile: false,
            errorFileType: false,
            errorMessage: "",
            loadingDropzone: false,
            isLoading: false,

            //

        };
        this.handleChangeDropzoneStatus = this.handleChangeDropzoneStatus.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.loadingDropzone) {
            this.setState({
                loadingDropzone: false
            })
        }
    }

    handleChangeDropzoneStatus(action, status, allFiles) {
        const {
            requestId, documentRequestType
        } = this.props;
        let {preparing, done, maxFile, errorFileType, errorMessage, 
            /*listRequestDocType, valueDocType*/
        } = this.state;
        if (status === "preparing") {
            preparing += 1;
            errorFileType = false;
        }
        if (status === "done") {
            done += 1;
        }
        if (status === "error_file_size") {
            preparing -= 1;
            action.remove();
            errorFileType = true;
            errorMessage = this.props.t('modal.upload.errorSize', {size: documentRequestType && documentRequestType.size && documentRequestType.size.maxSize});
        }
        if (status === "remove") preparing -= 1;
        if (status === "rejected_max_files") maxFile = true;
        if (status === "rejected_file_type") {
            errorFileType = true;
            errorMessage = this.props.t('modal.upload.errorType')
        }
        this.setState({
            preparing: preparing,
            done: done,
            maxFile: maxFile,
            errorFileType: errorFileType,
            errorMessage: errorMessage,
            isLoading: true
        })
        if (preparing === done) {
            // upload File
            const formData = new FormData();
            let uploadFile = false;
            allFiles.forEach((file, index) => {
                if (file && file.file) {
                    formData.append('fileExcel[documentFile]', file.file);
                    uploadFile = true;
                    return null;
                }
            })
            if (uploadFile) {
                this.setState({
                    isLoading: true
                })
                axios.post(INTERVENTION_IMPORT.replace("{id}", requestId), formData)
                    .then(res => {
                        if (res.status === 200) {
                            // upload request document success
                            // update list request
                            this.setState({
                                isLoading: false,
                                loadingDropzone: true
                            })
                            // setTimeout(() => {
                            //     this.getRequestDocumentList();
                            // }, 1000);
                        } else {
                            throw new Error();
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        this.setState({
                            isLoading: false,
                        })
                    })
            }
        }
    }

    render() {
        const {
            classes, ...newProps
        } = this.props;
        const {
            isLoading,
            loadingDropzone
        } = this.state;
        return <Grid container>
            {isLoading && <LoadingAction/> }
            <Grid item xs={4} className={classes.uploadFilesWrapper}>
                {!loadingDropzone && <Dropzone
                    inputContent={<div className={classes.inputUploadFile}>
                        <img src={UploadDoc} alt=""/>
                        <div className="textLabel">
                            {this.props.t('import_intervention.label.uploadFile')}
                        </div>
                    </div>}

                    accept={".xlsx"}
                    minSize={0}
                    multiple={false}
                    onChangeStatus={this.handleChangeDropzoneStatus}
                />}
            </Grid>
            <Grid item xs={8}>
                {!loadingDropzone && <MailTypeList {...newProps}/>}
            </Grid>
        </Grid>

    }
}
ImportIntervention.defaultProps = {}

ImportIntervention.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(
    withStyles(styles),
    withTranslation(),
    withRouter
)(ImportIntervention);
