import moment from "moment";

export const ConvertToDateY_M_D = (datetime) =>{
    return moment(datetime).format("YYYY-MM-DD");
}

export const ConvertToDateY_M_DH_m_s = (datetime) =>{
    return moment(datetime).format("YYYY-MM-DD HH:mm:ss");
}

export const ConvertToDateD_M_Y = (datetime) =>{
    return moment(datetime).format("DD/MM/YYYY");
}

export const convertToDateD_M_Y_H_m = (datetime) =>{
    return moment(datetime).format("DD/MM/YYYY HH:mm");
}

export const convertToDateH_m = (datetime) =>{
    return moment(datetime).format("HH:mm");
}

export const convertToDateH_m_D_M_Y = (datetime) =>{
    return moment(datetime).format("HH:mm DD/MM/YYYY");
}