import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {withTranslation} from "react-i18next";
import {
    withStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import CustomButton from "./CustomButton";
import {XS} from "../../constants/constants";

const styles = (theme) => ({
    dialogForm: {
        [theme.breakpoints.down(XS)]: {
            maxWidth: 'calc(100vw - 20px)'
        },
        zIndex: '9999!important',
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(189,189,217,0.71)'
        },
        '& .MuiDialog-paper': {
            // minWidth: 600,
            background: '#fff',
            borderRadius: 22,
            margin: 0,
            padding: '2.5rem 3rem',
            alignItems: 'center',
            '& .MuiDialogContent-root': {
                paddingTop: 0,
                paddingBottom: '2rem',
                '& .MuiDialogContentText-root': {
                    color: '#0000ff',
                    fontWeight: 600,
                    fontSize: '1.4rem',
                    textAlign: 'center',
                    padding: '25px',
                }
            },
            '& .MuiDialogActions-root': {
                '& $dialogButton': {
                    background: 'transparent',
                    boxShadow: 'none',
                    border: '3px solid #0000ff',
                    borderRadius: 19,
                    color: '#0000ff',
                    fontSize: '1.7rem',
                    textTransform: 'lowercase',
                    fontWeight: 700,
                    minWidth: 250,
                    padding: '0.75rem',
                    '&$agreeButton': {
                        background: '#0000ff',
                        color: '#08f1a9'
                    },
                    '&:hover': {
                        background: '#2d2b3a!important',
                        color: '#08f1a9!important',
                        borderColor: '#2d2b3a'
                    }
                }
            }
        },
        '&$style2': {
            '& .MuiDialog-paper': {
                // minWidth: 400,
                padding: '1.5rem',
                background: '#f8f8ff',
                // borderRadius: 4,
                '& .MuiDialogContent-root': {
                    paddingBottom: 0
                }
            },
            '& $closeDialogIcon': {
                fontWeight: 400,
                top: 0
            },
            '& .MuiDialogContentText-root': {
                fontWeight: 500,
                fontSize: '1.5rem'
            },
            '& $otherText': {
                fontSize: '0.9rem'
            },
            '& $button2': {
                minWidth: '7rem',
                background: '#0000FF!important',
                '&$cancelButton': {
                    color: 'white',
                    border: '1px solid transparent !important',
                    background: '#b7b7b7',
                    textTransform: 'initial',
                    '&:hover': {
                        background: '#0e1c6c!important',
                        '&::before': {
                            display: 'none'
                        }
                    }
                },

                '&:hover':{
                    background: '#0e1c6c!important',

                    '&::before':{
                        opacity:0,
                    }
                }
            }
        }
    },
    cancelButton: {
  
  
    },
    dialogButton: {
        '& span':{
            textTransform: 'capitalize'
        }
    },
    style2: {

    },
    button2: {

    },
    closeDialogIcon: {
        position: 'absolute',
        top: 7,
        right: 16,
        color: '#0000ff',
        fontWeight: 700,
        fontSize: '2rem',
        cursor: 'pointer'
    },
    agreeButton: {

    },
    otherText: {
        fontSize: '1rem',
        marginTop: 2
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const DialogBody = (props) => {
    const {children} = props;
    return (
        <div
            className="MuiDialog-paper MuiDialog-paperScrollPaper Dialog-bodyWrapper"
        >
            {children}
        </div>
    )
};

class DialogForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            props: {
                disableBackdropClick: true,
                disableEscapeKeyDown: true,
                TransitionComponent: Transition,
                keepMounted: true
            }
        }
    }

    render() {
        let {
            classes,
            dialogProps,
            closeDialog,
            hiddenAgreeButton,
            otherTextProps,
            messageProps,
            disagreeButtonProps,
            agreeButtonProps,
            componentRender,
            styleNumber,
            className
        } = this.props;
        const {props} = this.state;
        const propsDialog = Object.assign(props, dialogProps ? dialogProps : {});
        return (
            <React.Fragment>
                <Dialog
                    className={`${classes.dialogForm} ${styleNumber === 2 ? classes.style2 : ''} ${className?className:""}`}
                    PaperComponent={DialogBody}
                    {...propsDialog}
                >
                    <div
                        className={classes.closeDialogIcon}
                        onClick={closeDialog ? closeDialog : disagreeButtonProps.handleDisagree}
                    >
                        {styleNumber === 2 ? <span>&times;</span> : <span>X</span>}
                    </div>
                    <DialogContent>
                        <DialogContentText>
                            <div style={{color: messageProps && messageProps.color ? messageProps.color : '#0000ff'}}>
                                {messageProps && messageProps.content ? messageProps.content : this.props.t('dialog.message')}
                            </div>
                            {otherTextProps && typeof otherTextProps === "object" && Object.keys(otherTextProps).length !== 0 && otherTextProps.content !== "" &&
                            <div className={classes.otherText}
                                 style={{color: otherTextProps.color ? otherTextProps.color : '#54516a'}}>
                                {otherTextProps.content}
                            </div>
                            }
                            {
                                componentRender ? componentRender : ''
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {styleNumber === 2 ?
                            <CustomButton
                                title={disagreeButtonProps && disagreeButtonProps.content ? disagreeButtonProps.content : this.props.t('button.cancel')}
                                onClick={disagreeButtonProps.handleDisagree}
                                className={`${classes.button2} ${classes.cancelButton} cancelButtonDialog`}
                            />
                            :
                            <Button
                                className={classes.dialogButton}
                                onClick={disagreeButtonProps.handleDisagree}
                                variant="contained"
                                style={{background: disagreeButtonProps && disagreeButtonProps.background ? disagreeButtonProps.background : 'transparent', color: disagreeButtonProps && disagreeButtonProps.color ? disagreeButtonProps.color : '#0000ff'}}
                            >
                                {disagreeButtonProps && disagreeButtonProps.content ? disagreeButtonProps.content : this.props.t('button.cancel')}
                            </Button>
                        }
                        {hiddenAgreeButton ?
                            "" :
                            (styleNumber === 2 ?
                                <CustomButton
                                    title={agreeButtonProps && agreeButtonProps.content ? agreeButtonProps.content : this.props.t('button.ok')}
                                    onClick={agreeButtonProps.handleAgree}
                                    className={classes.button2}
                                />
                                :
                                <Button
                                    onClick={agreeButtonProps.handleAgree}
                                    variant="contained"
                                    autoFocus
                                    className={`${classes.dialogButton} ${classes.agreeButton}`}
                                    style={{background: agreeButtonProps && agreeButtonProps.background ? agreeButtonProps.background : '#0000ff', color: agreeButtonProps && agreeButtonProps.color ? agreeButtonProps.color : '#08f1a9'}}
                                >
                                    {agreeButtonProps && agreeButtonProps.content ? agreeButtonProps.content : this.props.t('button.ok')}
                                </Button>
                            )
                        }
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

}

DialogForm.defaultProps = {
    otherTextProps: {},
    disagreeButtonProps: {handleDisagree: () => {}},
    agreeButtonProps: {handleAgree: () => {}},
    styleNumber: 1 || 2
};

DialogForm.propTypes = {
    classes: PropTypes.object.isRequired,
    otherTextProps: PropTypes.object,
    messageProps: PropTypes.object,
    disagreeButtonProps: PropTypes.object,
    agreeButtonProps: PropTypes.object,
    dialogProps: PropTypes.object,
    hiddenAgreeButton: PropTypes.bool,
    styleNumber: PropTypes.number
};

export default withStyles(styles)(withTranslation()(DialogForm));
