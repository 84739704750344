import React from 'react';
import {compose} from "redux";
import {Popover, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {
    USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN
} from "../../../../constants/constants";
import qs from "qs"
import {convertDatetimeToDDMMYYhhmm} from "../../../../functions/functions";
import * as applicationAction from "../../../../_actions/application";
import {connect} from "react-redux";
import axios from "axios";
import {API_GET_REQUEST_REPORT_LIST, API_PAYMENT_REQUEST_REPORT_ADMIN_SEEN} from "../../../../constants/apiSuffix";
import LoadingAction from "../../../../theme/LoadingAction";
import {ConvertToDateY_M_D} from "../../../../functions/datetime";
import moment from "moment";
import {withRouter} from "react-router-dom";

const styles = (theme) => ({
    addChatPopover: {
        borderRadius: 13,
        '& > .MuiPaper-root': {
            borderRadius: 13,
        }
    },
    tooltipPaymentReportWrapper: {
        // padding: '',
        '& .titleTable': {
            background: '#afdbfd',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.5rem 1rem',
            '& .btnSeenReportRow': {
            },
            '& .idRow': {
                textTransform: "uppercase",
                fontWeight: 700,
            }
        },
        '& .tableBlock':{
            padding: '1rem'
        },
        '& table': {
            '& tr': {
                '& td': {
                    border: 'none',
                    minWidth: 70,
                    fontSize: 12,
                    padding: '3px 5px',
                    '& .newAlert':{
                        width: 'fit-content',
                        fontSize: 11,
                        background: '#f80606',
                        color: '#fff',
                        borderRadius: 6,
                        padding: 4,
                        fontWeight: 700,
                        fontStyle: 'italic',
                        lineHeight: 1
                    },
                    '&.fullNameWrapper': {
                        minWidth: 50
                    },
                    '&.numberReport': {
                        maxWidth: 80,
                        minWidth: 40,
                    },
                    '&.problemObservedReport': {
                        minWidth: 100,
                        maxWidth: 160,
                    },
                    '&.priceWrapper': {
                        fontWeight: 700,
                        fontSize: 13,
                    },
                    '&.paymentDatetime': {
                        width: 80
                    },
                }
            }
        },
        '& .btnSeenReport': {
            cursor: 'pointer',
            backgroundColor: '#0000f9',
            color: '#fff',
            padding: '5px 10px',
            borderRadius: 15,
            textTransform: 'uppercase',
            fontWeight: 600,
            width: 'fit-content',
            opacity: 0.85,
            '&:hover': {
                opacity: 1
            }
        }
    },
});

class PopoverShowReportPaidByRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reportsPaidList: [],
            isLoading: false,
            loadingAllPage: false
        }
    }

    componentDidMount() {
     this.getDataList()
    }

    getDataList = () =>{
        this.setState({
            isLoading: true
        })
        axios.get(API_GET_REQUEST_REPORT_LIST+"?"+ qs.stringify({
                maxPerPageAll: 1,
                request: this.props.formValuePopover.id,
                paid: 1,
                sorts: {
                    paidAtReport: "DESC"
                }
            })
        )
            .then(res => {
                let stateNew = {isLoading: false}
                if (res.status === 200) {
                    const dataItemsReport = (res?.data?.items) ?? [];
                    stateNew = {
                        ...stateNew,
                        reportsPaidList: dataItemsReport
                    }
                }
                this.setState(stateNew)
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
            })
    }

    actionSeenReportPaidNew = (requestId)=>{
        const {endDate, startDate, location} = this.props
        this.setState({loadingAllPage: true})
        axios.post(API_PAYMENT_REQUEST_REPORT_ADMIN_SEEN.replace("{requestId}", requestId))
            .then(response=>{
                console.log(response.status )
                if (response.status === 200) {
                    const urlParams = qs.parse(location.search, {ignoreQueryPrefix: true});
                    let apiParamCount = urlParams ?? {}
                    if (moment(endDate) && endDate.isValid()) {
                        apiParamCount = {
                            ...apiParamCount,
                            endDate: ConvertToDateY_M_D(endDate)
                        }
                    }
                    if (moment(startDate) && startDate.isValid()) {
                        apiParamCount = {
                            ...apiParamCount,
                            startDate: ConvertToDateY_M_D(startDate)
                        }
                    }
                    setTimeout(() => {
                        this.props.getCountRequestByStatus(apiParamCount);
                    }, 600)

                    this.props.getCountPaymentRequestReport()
                    this.getDataList();
                    this.setState({
                        successOpen: true,
                        loadingAllPage: false
                    })
                } else {
                    throw new Error();
                }
            })
            .catch(error=>{
                this.setState({
                    errorOpen: true,
                    loadingAllPage: false
                })
            })
    }

    render() {
        const {
            anchorEl,
            onClose,
            classes,
            t,
            currency,
            countPaymentRequestReport,
            groupBaseUser,
            formValuePopover
        } = this.props;
        const {isLoading, reportsPaidList, loadingAllPage} = this.state
        return <Popover
            className={classes.addChatPopover}
            open={true}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            disableRestoreFocus
        >
            <div className={classes.tooltipPaymentReportWrapper}>
                {loadingAllPage && <LoadingAction />  }
                {isLoading ? <LoadingAction isLoadingBlock={true}/> :
                    <React.Fragment>
                        <div className={"titleTable"}>
                            <div className={"idRow"}>{t("request.list.id")}: {formValuePopover.id}</div>
                            {[USER_TYPE_ADMIN,
                                USER_TYPE_SUPER_ADMIN].includes(groupBaseUser) && countPaymentRequestReport.includes(formValuePopover.id) &&
                            <div className={"btnSeenReportRow"}>
                                <div className={"btnSeenReport"}
                                     onClick={() => this.actionSeenReportPaidNew(formValuePopover.id)}>{t("request.list.seenReportPaid")}</div>
                            </div>}
                        </div>
                        <div className={"tableBlock"}>
                            <table>
                                <tbody>
                                {Array.isArray(reportsPaidList) && reportsPaidList.length ?
                                    reportsPaidList.map(item => {
                                        const dataIntervention = item?.report?.otherInfos?.initialData ? JSON.parse(item.report.otherInfos.initialData) : {};
                                        return <tr className={"itemWrapper"}>
                                            <td className={"numberReport"}>
                                                N° {dataIntervention.number}
                                                {[USER_TYPE_ADMIN,
                                                    USER_TYPE_SUPER_ADMIN].includes(groupBaseUser) && !item.adminSawPaymentReport && <div className={"newAlert"}>
                                                    {t("text.new")}
                                                </div>}
                                            </td>
                                            <td className={"problemObservedReport"}>
                                                {dataIntervention.problemObserved}
                                                <div>
                                                    {dataIntervention.solution ? "(" + dataIntervention.solution + ")" : ""}
                                                </div>
                                            </td>
                                            <td className="fullNameWrapper">{(item?.paymentInfo?.fullNamePayment) ?? ""}</td>
                                            <td className="priceWrapper">{(item?.paymentInfo?.price) ? item.paymentInfo.price.toFixed(2) : ""} {currency ? currency.unit : ""}</td>
                                            <td className="paymentDatetime">{(item?.paymentInfo?.paymentDatetime) ? convertDatetimeToDDMMYYhhmm(item.paymentInfo.paymentDatetime) : ""}</td>
                                        </tr>
                                    }) :
                                    ""
                                }
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>}
            </div>
        </Popover>;
    }
}

const mapStateToProps = (state) => {
    return {
        dataUser: state.authReducer.dataUser,
        groupBaseUser: state.authReducer.groupBaseUser,
        currency: state.applicationReducer.currency,
        countPaymentRequestReport: state.applicationReducer.countPaymentRequestReport,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCountPaymentRequestReport: () => dispatch(applicationAction.getCountPaymentRequestReport()),
        getCountRequestByStatus: (value) => dispatch(applicationAction.getCountRequestByStatus(value)),
        setIsLoading: (isLoading) => dispatch(applicationAction.setIsLoading(isLoading)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withRouter, withTranslation())(PopoverShowReportPaidByRequest);
