import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {Button, FormControl, Grid, Input, InputAdornment, Typography, withWidth} from "@material-ui/core";
import mailIcon from "../../images/login/email.svg"
import emailIcon from "../../images/login/mail.svg"
import keyNew from "../../images/login/keyNew.svg"
import {
    BASE_URL, CAPTCHA_SITE_KEY,
    CHANGE_PASSWORD, FACEBOOK, GOOGLE,
    LOGIN_PAGE, MD, PASSWORD_COMPONENT,
    RESET_PASSWORD,
    SIGN_UP, SM,
    USER_TYPE_CLIENT, XS
} from "../../constants/constants";
import passwordIcon from "../../images/login/password.svg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import keyIcon from "../../images/login/key.svg"
import {connect} from "react-redux";
import * as auth from "../../_actions/auth";
import axios from "axios"
import {API_LOGIN, CREATE_CLIENT, CREATE_NEW_PASSWORD, FORGOT_PASSWORD} from "../../constants/apiSuffix";
import * as application from "../../_actions/application"
import LoadingAction from "../../theme/LoadingAction";
import FormHelperText from "@material-ui/core/FormHelperText";
import {withRouter} from "react-router";
import * as links from "../../constants/links";
import ReCAPTCHA from "react-google-recaptcha";
import {
    CAPTCHA_ERROR,
    CAPTCHA_REQUEST,
    DOMAIN_REQUEST,
    PASSWORD_MATCHES_OLD_PASSWORD
} from "../../constants/responseCode";
import ErrorInput from "../../theme/form/Alert/ErrorInput";
import CustomInlineSvg from "../../theme/CustomElements/CustomInlineSvg";
import {NUNITO, OPENSANS, SEGOEUI} from "../../constants/fontCss";
import fbIcon from "../../images/login/fb.png";
import fbSignUpIcon from "../../images/login/fb-icon.png";
import ggIcon from "../../images/login/gg.png";
import postalCodeSU from "../../images/login/signUp/code.svg"
import userSU from "../../images/login/signUp/user.svg"
import phoneSU from "../../images/login/signUp/smartphone.svg"
import keySU from "../../images/login/signUp/keySign.svg"
import emailSU from "../../images/login/signUp/email.svg"
import AlertRegistrationSuccess from "./AlertRegistrationSuccess";

const styles = theme => ({
    itemLogin:{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 12,
        position: 'relative',
        padding: '0 5px',
        '& .MuiCheckbox-root': {
            padding: '0 10px 0',
            '& .MuiFormControlLabel-label': {
                color: '#001529'
            }
        },
        [theme.breakpoints.down(SM)]: {
            // marginBottom: 45
        }
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '2.3rem',
        position: 'relative',
        '& .MuiCheckbox-root': {
            padding: '0 10px 0',
            '& .MuiFormControlLabel-label': {
                color: '#001529'
            }
        },
        [theme.breakpoints.down(SM)]: {
            marginBottom: 45
        }
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem',
        '& img': {
            height: 19,
            marginRight: 5,
            width: 20
        },
        '& span': {
            color: '#001529',
            fontSize: '0.9375rem',
            fontWeight: 700,
            flexGrow: 1
        }
    },
    input: {
        '& input': {
            // background: '#F9F9F9',
            // border: '1px solid #E0E0E0',
            // borderRadius: 6,
            // height: 20,
            // padding: '10px 15px',
            // color: '#001529!important'
        },
        '& .MuiFormHelperText-root': {
            fontSize: '0.9rem',
            position: 'absolute',
            bottom: '-100%',
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            lineHeight: 1.3,
            height: '100%',
            [theme.breakpoints.down(SM)]: {
                // bottom: -45
            }
        }
    },
    buttonSubmit: {
        margin: 'auto',
        marginBottom: 30,
        marginTop: 15,
        '& button': {
            fontFamily: SEGOEUI,
            background: '#3333e6',
            borderRadius: 33,
            color: '#fff',
            width: '100%',
            fontSize: '1.125rem',
            padding: '13px 8px',
            // zIndex: 9,
            fontWeight: 600,
            letterSpacing: '3.65px',
            transition: '0.2s ease',
            maxWidth: '330px',
            margin: 'auto',
            justifyContent: 'center',
            display: 'flex',

            '&:hover': {
                background: '#2f2fff',
                letterSpacing: '4.65px',
                transition: '0.2s ease',
            }
        },
        [theme.breakpoints.down(SM)]:{
            marginBottom: '30px',

        }

    },
    buttonSignUp:{
        width: 389,
        '& button':{
            background: '#fff',
            color: '#3333d3',
            fontWeight: 'bold',
            fontSize: '1.1rem',
            lineHeight: '1.4rem',
            letterSpacing: '0.19px',
            textTransform: "none",
            '&:hover':{
                background: '#6161ff',
                color: '#fff',
                letterSpacing: '0px',
                fontSize: '1.2rem',
            }
        },
        [theme.breakpoints.down(XS)]:{
            '& button':{
                padding: '1rem 0.5rem'
            }
        }

    },
    formWrapper: {
        // background: '#FFFFFF',
        // boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
        // borderRadius: 10,
        // width: 500,
        // width: 540,
        padding: '20px 20px',
        // minHeight: 580,
        height: '100%',
        // maxHeight: 680,
        overflow: 'auto',
        '& h2': {
            color: '#0000FF',
            fontFamily: NUNITO,
            fontWeight: 800,
            textTransform: 'uppercase',
            fontSize: '1.5625rem',
            textAlign: 'center',
            margin: '25px 0 34px 0',
            lineHeight: 1.22,
        },
        '&::-webkit-scrollbar': {
            width: '5px'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor:'#C4C4C4',
            borderRadius: 3
        },
        [theme.breakpoints.down(SM)]: {
            padding: '15px 40px 50px 40px',

            '& h2':{
                margin: '40px 0 55px 0',
            }
        },

    },
    gridItem: {
        marginBottom: "1.4rem !important"
    },
    customPostalItem:{
        [theme.breakpoints.down(MD)]:{
            paddingLeft: '10px!important',
            paddingRight: '0!important'
        }
    },
    noNameItem:{
        visibility: 'hidden',
        opacity: 0,
        [theme.breakpoints.down(XS)]:{
            position: 'absolute',
        }
    },
    customGridItem:{
        [theme.breakpoints.down(MD)]:{
            position: 'absolute',
            visibility: 'hidden',
            opacity: 0
        }
    },
    gridWrapper: {
        justifyContent: 'center',
        '& $gridItem': {
            '&:nth-child(odd)': {
                paddingLeft: 10,
                marginBottom: 11
            },
            '&:nth-child(even)': {
                paddingRight: 10,
                marginBottom: 11
            }
        }
    },
    forgotPassword: {
        color: '#494997',
        fontSize: '1rem',
        cursor: 'pointer',
        transition: '0.2s',
        textDecoration: 'underline',
        fontFamily: SEGOEUI,
        '&:hover': {
            color: '#494997'
        }
    },
    backBtm: {
        color: '#1e00ff',
        fontSize: '1rem',
        cursor: 'pointer',
        transition: '0.2s',
        marginBottom: 15,
        display: 'inline-block',
        '&:hover': {
            color: '#00afd2'
        }
    },
    resetText: {
        margin: '-10px 0 20px',
        color: '#001529',
        fontSize: '1rem',
        textAlign: 'center'
    },
    resetTextSignUp:{
        margin: '-10px 0 20px',
        color: '#fff',
        fontSize: '1rem',
        textAlign: 'center'
    },
    keyIcon: {
        opacity: 0.1,
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: 10,
        '& img': {
            height: 80
        }
    },
    validateInput: {
        '& $customInput':{
            border: '1px solid #f44336',
        },
        '& input': {
            // border: '1px solid #f44336'
        }
    },
    validateText: {
        color: '#f44336',
        fontSize: '0.9rem',
        textAlign: 'center',
        // position: 'absolute',
        // bottom: -22,
        width: '100%',
        // [theme.breakpoints.down(XS)]:{
        //     bottom: -35
        // }
    },
    // postalCode:{
    //     // paddingRight: '0.5rem',
    //     [theme.breakpoints.down(SM)]: {
    //         paddingRight: 0
    //     }
    // },
    captchaCode:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingBottom: '0.5rem',
        marginTop: '1rem',
        '&.signUpPage':{
            justifyContent: 'flex-start',
            '& .validateCustom':{
                marginTop: "2px"
            }
        },
        '& .rc-anchor-error-msg-container':{
            display: 'none'
        },
        '& .rc-anchor-aria-status':{
            display: 'none'
        },
        '& .rc-anchor-alert':{
            display: 'none'
        },
        [theme.breakpoints.down(SM)]:{
            marginTop: '40px',
            marginBottom: '10px',
        }
    },
    customInput:{
        background: '#DFEBFB',
        borderRadius: 11,
        height: 52,
        display: 'flex',
        justifyContent: 'center',
        padding: '1rem 1.5rem',
        maxWidth: '455px',
        margin: 'auto',
        '& svg':{
            '& path':{
                fill: '#0000f9'
            }
        },
        '& input':{
            '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus,&:-webkit-autofill:active': {
                textFillColor: '#222427!important',
                boxShadow: '0 0 0 30px #DFEBFB inset'
            },
            '&::placeholder':{
                opacity: 0.92,
                color: '#7683cf',
                letterSpacing: -0.03,
                fontSize: '1rem',
                fontFamily: OPENSANS
            }
        },
    },
    itemPassword: {
        position: 'relative',
    },
    itemForgetPw:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0 5px',
        position: 'relative',
        '& .rememberWrapper': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'nowrap',
            width: '100%'
        },
        '& .remember':{
            '& .MuiFormControlLabel-label': {
                fontFamily: SEGOEUI,
                color: '#312e2a',
                lineHeight: '1rem',
            },
            '& .MuiCheckbox-colorSecondary.Mui-checked': {
                color: '#165de3'
            }
        },

        [theme.breakpoints.down(SM)]: {
            flexDirection: 'column',
            // display: 'flex',
            // flexDirection: 'row',
            // maxWidth: '455px',
            // margin: 'auto',
        },




    },
    socialLogin:{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 15,
        marginBottom: 15,

        '& .or':{
            textAlign: 'center',
            position: 'relative',
            marginTop: '1rem',
            marginBottom: '1rem',
            fontFamily: SEGOEUI,
            width: '100%',
            '&:after':{
                content: '""',
                borderBottom: '1px dotted #707070',
                position: 'absolute',
                width: '45%',
                top: '44%',
                right: 0
            },
            '&:before':{
                content: '""',
                borderBottom: '1px dotted #707070',
                position: 'absolute',
                width: '45%',
                top: '44%',
                left: 0
            }
        },
        '& div.socialLogin':{
            fontFamily: OPENSANS,
            fontSize: '15px',
            lineHeight: 1.07,
            borderRadius: 9,
            display: 'flex',
            border: '1px solid #A7A8A9',
            color: '#717171',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '3px 10px',
            cursor: 'pointer',
            '&:hover':{
                background: '#d6e0f9',
                color: '#3d639d',
                border:'1px solid transparent',
            },
            '&.facebook':{
                width: '48%',
            },
            '&.google':{
                width: '48%'
            }
        },
        [theme.breakpoints.down(SM)]:{
            flexDirection: 'row',
            margin: 0,
            marginBottom: 10,
            '& .or':{
                marginTop: '2rem'
            },
            '& div.socialLogin':{
                margin: '4px auto',
                '&.facebook':{

                },
                '&.google':{

                },
                '& div':{
                    padding: '5px 0'
                }
            }
        },
        [theme.breakpoints.down(XS)]:{
            flexDirection: 'column',
            '& div.socialLogin':{
                '&.facebook':{
                    width: '90%!important',
                },
                '&.google':{
                    width: '90%!important'
                },
            }
        }
    },
    notSignUp:{
        overflow: 'hidden'
    },
    formWrapperLogin:{
        width: '93%',
        padding: '2rem 8rem',
        margin: 'auto',
        paddingTop: 0,
        maxHeight: 'calc(100% - 60px)',
        '& h2':{
            color: '#e8e8f8',
            fontWeight: 800,
            fontSize: '1.5625rem',
            marginTop: 0,
            marginBottom: 12,
            lineHeight: '32px',
        },
        '& $socialLogin':{
            marginBottom: 0,
            justifyContent: 'center',
            '& .or':{
                marginTop: 15,
                color: '#fff',
                fontSize: '1rem',
                fontWeight: 300,
                fontFamily: SEGOEUI,
                '&:after':{
                    borderBottom: '1px dotted #fff',
                },
                '&:before': {
                    borderBottom: '1px dotted #fff',
                }
            },
            '& div.socialLogin':{
                color: '#ececec',
                '&:hover':{
                    background: '#6161ff'
                },
            },
            '& .facebook':{
                marginRight: 10
            },
            '& .google': {
                marginLeft: 10
            }
        },
        '& $gridWrapper':{
            justifyContent: 'space-between',
            // '& $gridItem':{
            //     padding: 0
            // },
            '& $item':{
                marginBottom: 0,
                flexDirection: 'initial',
                width: '100%',
                '& $validateInput':{
                    border: '1px solid #f44336',
                },
                '& $input':{
                    //background: '#6161ff',
                    background: '#4545f2',
                    borderRadius: 8,
                    // marginBottom: '0.5rem',
                    display: 'flex',
                    height: 44,
                    width: '100%',
                    alignItems: 'center',
                    '& .MuiFormHelperText-root':{
                        bottom: '-2.45rem'
                    },
                    '& .MuiInputAdornment-positionStart':{
                        width: 50,
                        display: 'flex',
                        justifyContent: 'center',
                        marginLeft: 8
                    },
                    '& input':{
                        color: '#ececec!important',
                        caretColor: "#ececec",
                        '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus,&:-webkit-autofill:active': {
                            textFillColor: '#fff!important',
                            boxShadow: '0 0 0 30px #6161ff inset'
                        },
                        '&::placeholder':{
                            color: '#bfd2ff',
                            fontWeight: 200,
                            fontSize: '1rem',
                            opacity: 1
                        },
                        [theme.breakpoints.down(SM)]:{
                                fontSize: '0.875rem',
                        }
                    }
                }
            },
            [theme.breakpoints.down(XS)]:{
                '& $gridItem': {
                    padding: '0px!important',
                    // '&:nth-child(odd)': {
                    //     paddingLeft: 10,
                    //     marginBottom: 20
                    // },
                    // '&:nth-child(even)': {
                    //     paddingRight: 10,
                    //     marginBottom: 20
                    // }
                }
            }
        },
        '& .signUpPage':{
            marginTop: '-1rem'
        },
        [theme.breakpoints.down(MD)]:{
            '& .signUpPage':{
                marginTop: 0
            },
        },
        [theme.breakpoints.down(SM)]:{
            '& $socialLogin':{
                // flexDirection: 'column',
                '& div.socialLogin':{
                    // '& > div': {
                    //     width: '100%!important',
                    // }
                    // '&.facebook':{
                    //     width: '47%!important',
                    // },
                    // '&.google':{
                    //     width: '47%!important'
                    // },
                }
            },
        },
        [theme.breakpoints.down(XS)]:{
            '& $socialLogin':{
                flexDirection: 'row',
                '& div.socialLogin':{
                    margin: 'auto',
                    justifyContent: 'center',
                    padding: '3px 6px',
                    '&.facebook':{
                        width: '100%!important',
                        padding: '0.4rem 0',
                        marginBottom: '0.5rem'
                    },
                    '&.google':{
                        width: '100%!important',
                        padding: '0.4rem 0',

                    },
                }
            }
        }
    },
    backToLogin:{
        display: 'flex',
        color: '#fff',
        padding: '0.4rem 1rem',
        border: '1px solid #fff',
        borderRadius: 33,
        margin: 'auto',
        width: 'fit-content',
        cursor: 'pointer',
        position: 'absolute',
        top: '18px',
        left: '28px',
        textDecoration: 'none',
        opacity: '0.6',
        '&:hover':{
            background: '#fff',
            color: '#0b0b9a',
            textDecoration: 'none',
            opacity: '1',
        },
        [theme.breakpoints.down(XS)]:{
            marginTop: '18px',
            marginLeft: '15px',
        },
    },
    addInfoFacebookAcc: {
        textAlign: 'center',
        color: '#5affee',
        fontSize: '0.9rem',
        marginBottom: 10,
    }
});

class AuthForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lastName: props.dataInitial?.lastName ?? '',
            firstName: props.dataInitial?.firstName ?? '',
            mail: '',
            password: '',
            confirmPassword: '',
            phone: '',
            addressPostalCode: '',
            remember: false,
            requestSendingText: '',
            successfulText: '',
            errors: {},
            mailLogin: '',
            passwordLogin: '',
            mailReset: '',
            passwordReset: '',
            confirmPasswordReset: '',
            token: props.match.params && props.match.params.token ? props.match.params.token : '',
            captchaCode: ""
        }
        this._reCaptchaRef = React.createRef();
    }

    handleChange = (event) => {
        const {errors} = this.state;
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        delete errors[name];
        delete errors['credentials']
        if (name === 'password') {
            if (this.state.confirmPassword === value) {
                delete errors['confirmPassword']
            }
            delete errors['invalidToken']
        } else if (name === 'confirmPassword') {
            if (this.state.password === value) {
                delete errors['password']
            }
            delete errors['invalidToken']
        }
        if (name === 'passwordReset') {
            if (this.state.confirmPasswordReset === value) {
                delete errors['confirmPasswordReset']
            }
            delete errors['invalidToken']
        } else if (name === 'confirmPasswordReset') {
            if (this.state.passwordReset === value) {
                delete errors['passwordReset']
            }
            delete errors['invalidToken']
        }
        this.setState({
            [name]: value,
            errors: errors
        })
    }

    handleSubmit = () => {
        // event.preventDefault();
        const self = this;
        let {
            requestSendingText, mail, lastName, firstName, phone, password, confirmPassword, remember,addressPostalCode,
            successfulText, errors, mailLogin, passwordLogin, mailReset, passwordReset, confirmPasswordReset
        } = this.state;
        const hostNamePage = window.location.hostname;
        delete errors.credentials;
        delete errors.invalidToken;
        let phoneNumber = phone;
        if (this.props.typePage === SIGN_UP) {
            const arrayCheckNullSignUp = ["lastName", "firstName","phone", "addressPostalCode", "confirmPassword"];
            arrayCheckNullSignUp.map(item=>{
                if(!this.state[item]){
                    errors[item] = this.props.t('errors.notNull');
                }
            })
            // if (!lastName) {
            //     errors.lastName = this.props.t('errors.notNull');
            // }
            // if (!firstName) {
            //     errors.firstName = this.props.t('errors.notNull');
            // }
            // if (!phone) {
            //     errors.phone = this.props.t('errors.notNull');
            // }
            // if (!confirmPassword) {
            //     errors.confirmPassword = this.props.t('errors.notNull');
            // }
            if(phoneNumber){
                 phoneNumber = phoneNumber.replace(/\s/g, '');
                let re = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g
                if(!re.test(phoneNumber)) {
                    errors.phone = this.props.t("information.formTab1.errors.phoneNumberInvalid")
                }
            }
            if (password && confirmPassword) {
                if (password !== confirmPassword) {
                    errors.password = this.props.t('errors.passwordNotMatch');
                    errors.confirmPassword = this.props.t('errors.passwordNotMatch');
                } else {
                    delete errors['password'];
                    delete errors['confirmPassword'];
                }
            }
            if (!mail) {
                errors.mail = this.props.t('errors.notNull');
            } else {
                const pattern = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
                if (!pattern.test(mail)) {
                    errors.mail = this.props.t('errors.isNotValid');
                }
            }
            if (!password) {
                errors.password = this.props.t('errors.notNull');
            }
        }
        else if (this.props.typePage === LOGIN_PAGE) {
            if (!mailLogin) {
                errors.mailLogin = this.props.t('errors.notNull');
            } else {
                const pattern = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
                if (!pattern.test(mailLogin)) {
                    errors.mailLogin = this.props.t('errors.isNotValid');
                }
            }
            if (!passwordLogin) {
                errors.passwordLogin = this.props.t('errors.notNull');
            }
        }
        else if (this.props.typePage === RESET_PASSWORD) {
            if (!mailReset) {
                errors.mailReset = this.props.t('errors.notNull');
            } else {
                const pattern = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
                if (!pattern.test(mailReset)) {
                    errors.mailReset = this.props.t('errors.isNotValid');
                }
            }
        }
        else if (this.props.typePage === PASSWORD_COMPONENT) {
            if (!passwordReset) {
                errors.passwordReset = this.props.t('errors.notNull');
            }
            if (!confirmPasswordReset) {
                errors.confirmPasswordReset = this.props.t('errors.notNull');
            }
            if (passwordReset && confirmPasswordReset) {
                if (passwordReset !== confirmPasswordReset) {
                    errors.passwordReset = this.props.t('errors.passwordNotMatch');
                    errors.confirmPasswordReset = this.props.t('errors.passwordNotMatch');
                } else {
                    delete errors['passwordReset'];
                    delete errors['confirmPasswordReset'];
                }
            }
        }
        if(!this.state.captchaCode){
            if (this._reCaptchaRef && this._reCaptchaRef.current) {
                this._reCaptchaRef.current.reset()
            }
            errors.captchaCode = this.props.t('errors.CAPTCHA_NULL');
        }
        if(!hostNamePage){
            errors.captchaCode = this.props.t('errors.DOMAIN_NULL');
        }
        this.setState({
            errors: errors
        });
        const formData = new FormData();
        formData.append(DOMAIN_REQUEST, hostNamePage);
        formData.append(CAPTCHA_REQUEST, this.state.captchaCode);
        if (this.props.typePage === LOGIN_PAGE && Object.keys(errors).length < 1) {
            const data = {
                username: mailLogin,
                password: passwordLogin,
                [DOMAIN_REQUEST]:hostNamePage,
                [CAPTCHA_REQUEST]:this.state.captchaCode
            };
            this.props.setIsLoading(true);
            axios.post(BASE_URL + API_LOGIN, data)
                .then(response => {
                    this.props.setDataUser(response.data);
                    this.props.setIsLoading(false);
                    this.props.saveTypeFormAuth(LOGIN_PAGE);
                    if (remember) {
                        this.props.setDataLogin({
                            mail: mailLogin,
                            password: passwordLogin,
                            remember: remember
                        })
                    } else {
                        this.props.setDataLogin(null);
                        this.setState({
                            mailLogin: '',
                            passwordLogin: '',
                            errors: {},
                            remember: false
                        })
                    }
                })
                .catch(error => {
                    this.props.setIsLoading(false);
                    if (error.response && error.response.status === 400 && error.response.data ) {
                        const dataErrors = error.response.data;
                        let errorsCaptcha = "";
                        if( dataErrors.response_code === CAPTCHA_ERROR && dataErrors.errors && Array.isArray(dataErrors.errors)){
                            dataErrors.errors.map(item=>{
                                errorsCaptcha += this.props.t('errors.'+item)
                            });
                            errors.captchaCode = errorsCaptcha
                        }
                    }
                    if (error.response && error.response.status === 401) {
                        errors.credentials = this.props.t('errors.notLogin')
                    }
                    if (remember) {
                        this.props.setDataLogin({
                            mail: mailLogin,
                            password: passwordLogin,
                            remember: remember
                        })
                    } else {
                        this.props.setDataLogin(null)
                    }
                    if (this._reCaptchaRef && this._reCaptchaRef.current) {
                        this._reCaptchaRef.current.reset()
                    }
                    this.setState({
                        errors: errors,
                        captchaCode: ""
                    })
                })
        } else if (this.props.typePage === SIGN_UP && Object.keys(errors).length < 1){
            const urlEnableUser = window.location ? window.location.origin+links.ENABLE_USER : ""
            formData.append('person[user][userType]', USER_TYPE_CLIENT);
            formData.append('person[email]', mail);
            formData.append('person[user][password][first]', password);
            formData.append('person[user][password][second]', confirmPassword);
            formData.append('person[phoneNumber]', (phoneNumber ?? "").toString().trim());
            formData.append('person[firstName]', firstName);
            formData.append('person[lastName]', lastName);
            formData.append('link_confirm_registration', urlEnableUser);
            formData.append('person[addressPostalCode]', addressPostalCode);
            if (this.props.dataInitial?.facebookId) {
                formData.append('facebookId', this.props.dataInitial?.facebookId)
            }
            this.props.setIsLoading(true);
            axios.post(BASE_URL + CREATE_CLIENT, formData)
                .then(response => {
                    this.props.setIsLoading(false);
                    const data = response.data;
                    if (data.response_code === "CREATE_USER_SUCCESS") {
                        this.props.checkCreateNewClientSuccess(true);
                        if(this.props.registrationUserSuccessFunc) {
                            this.props.registrationUserSuccessFunc(mail);
                        }
                        this.props.saveTypeFormAuth(SIGN_UP);
                        successfulText = <span>{this.props.t('login.text.createNewClient_1')} <strong>{data.item.email}</strong>{this.props.t('login.text.createNewClient_2')}</span>;
                        this.setState({
                            successfulText: successfulText,
                            lastName: '',
                            firstName: '',
                            mail: '',
                            password: '',
                            confirmPassword: '',
                            phone: '',
                            errors: {}
                        })
                    }
                })
                .catch(error => {
                    this.props.setIsLoading(false);
                    if (error.response && error.response.status === 400 && error.response.data ) {
                        const dataErrors = error.response.data;
                        let errorsCaptcha = "";
                        if( dataErrors.response_code === CAPTCHA_ERROR && dataErrors.errors && Array.isArray(dataErrors.errors)){
                            dataErrors.errors.map(item=>{
                                errorsCaptcha += this.props.t('errors.'+item)
                            });
                            errors.captchaCode = errorsCaptcha
                        }else if (Object.keys(error.response.data.errors).length !== 0){
                            if (error.response.data.errors['user.email']) {
                                errors.mail = this.props.t('errors.uniqueEmail');
                            }
                        }
                    }
                    if (this._reCaptchaRef && this._reCaptchaRef.current) {
                        this._reCaptchaRef.current.reset()
                    }
                    this.setState({
                        errors: errors,
                        captchaCode: ""
                    })
                })
        } else if (this.props.typePage === RESET_PASSWORD && Object.keys(errors).length < 1) {
            this.props.setIsLoading(true);
            const formData = new FormData();
            formData.append('email', mailReset);
            formData.append('link_change_password', window.location.origin + CHANGE_PASSWORD);
            formData.append(DOMAIN_REQUEST, hostNamePage);
            formData.append(CAPTCHA_REQUEST, this.state.captchaCode);
            axios.post(BASE_URL + FORGOT_PASSWORD, formData)
                .then(response => {
                    if (response.status === 200) {
                        this.props.setIsLoading(false);
                        requestSendingText = <span>{this.props.t('login.text.successText_1')} <strong>{mailReset}</strong>{this.props.t('login.text.successText_2')}</span>;
                        this.setState({
                            requestSendingText: requestSendingText,
                            errors: {}
                        }, () => {
                            self.props.setRequestResetPassword(true);
                            self.props.saveTypeFormAuth(LOGIN_PAGE);
                        })
                    } else {
                        throw new Error();
                    }
                })
                .catch(error => {
                    this.props.setIsLoading(false);
                    if (error.response.status === 400) {
                        const dataErrors = error.response.data;
                        let errorsCaptcha = "";
                        if( dataErrors.response_code === CAPTCHA_ERROR && dataErrors.errors && Array.isArray(dataErrors.errors)){
                            dataErrors.errors.map(item=>{
                                errorsCaptcha += this.props.t('errors.'+item)
                            });
                            errors.captchaCode = errorsCaptcha
                        }else if (error.response.data.response_code === "NOT_EXIST_USERNAME") {
                            errors.mailReset = this.props.t('errors.mailNotExist')
                        }
                        if (this._reCaptchaRef && this._reCaptchaRef.current) {
                            this._reCaptchaRef.current.reset()
                        }
                        this.setState({
                            errors: errors,
                            captchaCode: ""
                        })
                    }
                })
        }
        else if (this.props.typePage === PASSWORD_COMPONENT && Object.keys(errors).length < 1) {
            const {token} = this.state;
            formData.append('password', passwordReset);
            formData.append('confirmPassword', confirmPasswordReset);
            formData.append(DOMAIN_REQUEST, hostNamePage);
            formData.append(CAPTCHA_REQUEST, this.state.captchaCode);
            this.props.setIsLoading(true);
            axios.post(BASE_URL + CREATE_NEW_PASSWORD + token, formData)
                .then(response => {
                    this.props.setDataUser(response.data);
                    this.props.setIsLoading(false);
                    this.props.setDataLogin(null);
                    this.props.saveTypeFormAuth(LOGIN_PAGE);
                    this.props.changePassword(true);
                    successfulText = this.props.t('login.text.changePasswordSuccess');
                    this.setState({
                        passwordReset: '',
                        confirmPasswordReset: '',
                        errors: {},
                        successfulText: successfulText
                    })
                })
                .catch(error => {
                    this.props.setIsLoading(false);
                    this.props.changePassword(false);
                    const dataErrors = error.response.data;
                    let errorsCaptcha = "";
                    if( dataErrors.response_code === CAPTCHA_ERROR && dataErrors.errors && Array.isArray(dataErrors.errors)){
                        dataErrors.errors.map(item=>{
                            errorsCaptcha += this.props.t('errors.'+item)
                        });
                        errors.captchaCode = errorsCaptcha
                    }else if (error.response && error.response.status === 400) {
                        if( dataErrors.response_code === PASSWORD_MATCHES_OLD_PASSWORD){
                            errors.invalidToken =this.props.t('login.text.newPasswordExist');
                        }else {
                            errors.invalidToken = this.props.t('errors.hasError')
                        }
                    }
                    if (this._reCaptchaRef && this._reCaptchaRef.current) {
                        this._reCaptchaRef.current.reset()
                    }
                    this.setState({
                        errors: errors,
                        captchaCode: ""
                    })
                })
        }
    }

    componentDidMount() {
        const {typePage, checkSwitch, dataLogin} = this.props;
        if (typePage === LOGIN_PAGE || checkSwitch === LOGIN_PAGE) {
            this.setState({
                mailLogin: dataLogin ? dataLogin.mail : '',
                passwordLogin: dataLogin ? dataLogin.password : '',
                remember: dataLogin ? dataLogin.remember : false
            })
        }
        if (this.props.typePage === PASSWORD_COMPONENT) {
            this.props.saveTypeFormAuth(LOGIN_PAGE);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {checkSwitch, dataLogin} = this.props;
        const self = this;
        if (prevProps.checkSwitch !== checkSwitch) {
            this.setState({
                errors: {}
            })
            if (checkSwitch === LOGIN_PAGE) {
                this.setState({
                    lastName: '',
                    firstName: '',
                    mail: '',
                    password: '',
                    confirmPassword: '',
                    phone: '',
                    successfulText: ''
                }, () => {
                    self.props.checkSwitchAuthForm(null)
                })
            } else if (checkSwitch === SIGN_UP || checkSwitch === RESET_PASSWORD) {
                if (!dataLogin) {
                    this.setState({
                        mailLogin: '',
                        passwordLogin: '',
                        remember: false
                    })
                }
                this.props.checkSwitchAuthForm(null);
            }
        }
    }

    handleChangeCaptcha = value => {
        let {errors} = this.state;
        if (value){
            delete (errors["captchaCode"])
        }
        this.setState({ captchaCode: value, errors: errors});
    };

    render() {
        const {
            classes,t,
            title,
            typePage,
            forgotPasswordFunc,
            backFunction,
            successRequest,
            isLoading,
            checkCreateNewClient,
            changePasswordSuccess,
            typePageNow,
            width
        } = this.props;
        const {
            lastName,
            firstName,
            mail,
            phone,
            password,
            confirmPassword,
            remember,
            requestSendingText,
            successfulText,
            errors,
            mailLogin,
            passwordLogin,
            mailReset,
            passwordReset,
            confirmPasswordReset,
            addressPostalCode
        } = this.state;
        const signupItems = [
            {
                icon: userSU,
                label: 'login.fields.lastName',
                name: 'lastName',
                value: lastName,
                validate: errors.lastName
            },
            (width === XS ? {
                icon: userSU,
                label: 'login.fields.firstName',
                name: 'firstName',
                value: firstName,
                validate: errors.firstName
            } : {
                icon: emailSU,
                label: 'E-mail', //login.fields.mail.js
                name: 'mail',
                value: mail,
                type: 'email',
                validate: errors.mail
            }),
            (width === XS ? {
                icon: emailSU,
                label: 'E-mail', //login.fields.mail.js
                name: 'mail',
                value: mail,
                type: 'email',
                validate: errors.mail
            } : {
                icon: userSU,
                label: 'login.fields.firstName',
                name: 'firstName',
                value: firstName,
                validate: errors.firstName
            }),
            {
                icon: keySU,
                label: 'login.fields.password',
                name: 'password',
                value: password,
                type: 'password',
                validate: errors.password
            },
            {
                name: 'noName'
            },
            {
                icon: keySU,
                label: 'login.fields.confirmPassword',
                name: 'confirmPassword',
                value: confirmPassword,
                type: 'password',
                validate: errors.confirmPassword
            },
            {
                icon: phoneSU,
                label: 'login.fields.phone',
                name: 'phone',
                value: phone,
                validate: errors.phone
            },

        ];

        return (
            <React.Fragment>
                {isLoading && <LoadingAction />}
                <form className={`${classes.formWrapper} ${typePage === SIGN_UP ?  `${classes.formWrapperLogin} formWrapperLogin` : typePageNow === SIGN_UP ? classes.notSignUp : ""}`}>
                    {typePage === RESET_PASSWORD || checkCreateNewClient || typePage === PASSWORD_COMPONENT ?
                        <div
                            className={classes.backBtm + " " + 'backBtm'}
                            onClick={backFunction}
                        >
                            {'< ' + (changePasswordSuccess ? this.props.t('button.login') : this.props.t('button.back'))}
                        </div>
                        :
                        ''
                    }
                    <Typography variant={"h2"}>
                        {typePage === LOGIN_PAGE ? t('login.fields.login') : title}
                    </Typography>
                    {typePage === PASSWORD_COMPONENT && changePasswordSuccess &&
                        <p className={classes.resetText}>
                            {changePasswordSuccess ? successfulText : ''}
                        </p>
                    }
                    {typePage === RESET_PASSWORD &&
                        <p className={classes.resetText}>
                            {successRequest ? requestSendingText : this.props.t('login.text.resetText')}
                            {successRequest ? <AlertRegistrationSuccess isResetPassword={true} email={mailReset}/> : this.props.t('login.text.resetText')}
                        </p>
                    }
                    {typePage === SIGN_UP && checkCreateNewClient &&
                        <p className={classes.resetTextSignUp}>
                            {checkCreateNewClient ? successfulText : ''}
                        </p>
                    }
                    <Grid container>
                        <Grid container className={classes.gridWrapper}>
                            {typePage === SIGN_UP && !checkCreateNewClient ?
                                <React.Fragment>
                                    <Grid  container className={classes.socialLogin}>
                                        {this.props.dataInitial?.facebookId && <Grid item xs={12}>
                                            <div className={classes.addInfoFacebookAcc}>{this.props.t('registration.addInfoFacebookAcc')}</div>
                                        </Grid>}
                                        <Grid xs={12} sm={5} md={4} item className={"socialLogin facebook"} onClick={()=>this.props.loginSocial(FACEBOOK)}>
                                            {/*<div className={"iconButton"}>*/}
                                            {/*<CustomInlineSvg*/}
                                            {/*svgProps={{src:facebook}}*/}
                                            {/*/>*/}
                                            <img src={fbSignUpIcon} alt="facebook"/>
                                            {/*</div>*/}
                                            <div>{t("login.text.loginWithFacebook")}</div>
                                        </Grid>
                                        <Grid  xs={12} sm={5} md={4} item className={"socialLogin google"} onClick={()=> this.props.loginSocial(GOOGLE)}>
                                            {/*<div className={"iconButton"}>*/}
                                            {/*<CustomInlineSvg*/}
                                            {/*svgProps={{src:google}}*/}
                                            {/*/>*/}
                                            <img src={ggIcon} alt="google"/>
                                            {/*</div>*/}
                                            <div>{t("login.text.loginWithGoogle")}</div>
                                        </Grid>
                                        <Grid item xs={12} className={'or'}>
                                            {t('login.text.or')}
                                        </Grid>
                                    </Grid>
                                    {signupItems.map((item, index) => {
                                        return (
                                            <Grid item xs={12} sm={6} key={index} className={`${classes.gridItem} ${item.name === "noName" ? classes.noNameItem : ""}`}>
                                                <Grid item xs={12} className={`${classes.item}`}>
                                                    {/*<div className={classes.label}>*/}
                                                    {/*    <CustomInlineSvg svgProps={{src: item.icon}}/>*/}
                                                    {/*    /!*<img src={item.icon} alt={this.props.t(item.label)}/>*!/*/}
                                                    {/*    <span>{this.props.t(item.label)}</span>*/}
                                                    {/*</div>*/}
                                                    {item.name && <div className={`${classes.input} ${item.validate ? classes.validateInput : ''}`}>
                                                        <FormControl fullWidth error={!!item.validate}>
                                                            <Input
                                                                onChange={this.handleChange}
                                                                name={item.name}
                                                                value={item.value}
                                                                type={item.type ? item.type : 'text'}
                                                                startAdornment={
                                                                    <InputAdornment position="start">
                                                                        <CustomInlineSvg svgProps={{src: item.icon}}/>
                                                                    </InputAdornment>
                                                                }
                                                                placeholder={t(item.label)}
                                                            />
                                                            {item.validate &&
                                                                <FormHelperText>{item.validate}</FormHelperText>
                                                            }
                                                        </FormControl>
                                                    </div>}
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </React.Fragment>
                                :
                                (typePage === LOGIN_PAGE
                                    ?
                                        <React.Fragment>
                                            <React.Fragment>
                                                    <Grid item xs={12} sm={10}>
                                                        <div className={classes.itemLogin}>
                                                            {/*<div className={classes.label}>*/}
                                                            {/*    <img src={mailIcon} alt={this.props.t('login.fields.mail.js')}/>*/}
                                                            {/*    <span>{this.props.t('login.fields.mail.js')}</span>*/}
                                                            {/*</div>*/}
                                                            <div className={`${classes.input} ${errors.mailLogin ? classes.validateInput : ''}`}>
                                                                <FormControl className={classes.customInput} fullWidth error={!!errors.mailLogin}>
                                                                    <Input
                                                                        type={'email'}
                                                                        onChange={this.handleChange}
                                                                        name={"mailLogin"}
                                                                        value={mailLogin}
                                                                        startAdornment={
                                                                            <InputAdornment position="start">
                                                                                <CustomInlineSvg svgProps={{src: emailIcon}}/>
                                                                            </InputAdornment>
                                                                        }
                                                                        placeholder={t('login.fields.email')}
                                                                    />
                                                                    {errors.mailLogin &&
                                                                        <FormHelperText>{errors.mailLogin}</FormHelperText>
                                                                    }
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={10}>
                                                        <div className={classes.itemLogin}>
                                                            {/*<div className={classes.label}>*/}
                                                            {/*    <img src={passwordIcon} alt={this.props.t('login.fields.password')}/>*/}
                                                            {/*    <span>{this.props.t('login.fields.password')}</span>*/}
                                                            {/*    */}
                                                            {/*</div>*/}
                                                            <div className={`${classes.input} ${errors.passwordLogin ? classes.validateInput : ''}`}>
                                                                <FormControl className={classes.customInput} fullWidth error={!!errors.passwordLogin}>
                                                                    <Input
                                                                        onChange={this.handleChange}
                                                                        name={"passwordLogin"}
                                                                        value={passwordLogin}
                                                                        type={"password"}
                                                                        startAdornment={
                                                                            <InputAdornment position="start">
                                                                                <CustomInlineSvg svgProps={{src: keyNew}}/>
                                                                            </InputAdornment>
                                                                        }
                                                                        placeholder={t('login.fields.password')}
                                                                    />
                                                                    {errors.passwordLogin &&
                                                                        <FormHelperText>{errors.passwordLogin}</FormHelperText>
                                                                    }
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={10}>
                                                        <div className={classes.itemForgetPw}>
                                                            <div className="rememberWrapper">
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={remember}
                                                                            onChange={this.handleChange}
                                                                            name="remember"
                                                                        />
                                                                    }
                                                                    className={'remember'}
                                                                    label={this.props.t('login.text.remember')}
                                                                />
                                                                <div
                                                                    className={classes.forgotPassword}
                                                                    onClick={forgotPasswordFunc}
                                                                >
                                                                    {this.props.t('login.text.forgotPassword')}
                                                                </div>
                                                            </div>
                                                            {errors.credentials &&
                                                                <div className={classes.validateText}>
                                                                    {errors.credentials}
                                                                </div>
                                                            }
                                                        </div>
                                                    </Grid>
                                                <Grid item xs={12} sm={10} >
                                                    <Grid container  className={classes.socialLogin}>
                                                        <div className={'or'}>
                                                            {t('login.text.or')}
                                                        </div>
                                                        <div className={"socialLogin facebook"} onClick={()=>this.props.loginSocial(FACEBOOK)}>
                                                            {/*<div className={"iconButton"}>*/}
                                                            {/*<CustomInlineSvg*/}
                                                            {/*svgProps={{src:facebook}}*/}
                                                            {/*/>*/}
                                                            <img src={fbIcon} alt="facebook"/>
                                                            {/*</div>*/}
                                                            <div>{t("login.text.loginWithFacebook")}</div>
                                                        </div>
                                                        <div className={"socialLogin google"} onClick={()=> this.props.loginSocial(GOOGLE)}>
                                                            {/*<div className={"iconButton"}>*/}
                                                            {/*<CustomInlineSvg*/}
                                                            {/*svgProps={{src:google}}*/}
                                                            {/*/>*/}
                                                            <img src={ggIcon} alt="google"/>
                                                            {/*</div>*/}
                                                            <div>{t("login.text.loginWithGoogle")}</div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                </React.Fragment>
                                        </React.Fragment>
                                    :
                                    (typePage === RESET_PASSWORD && !successRequest
                                        ?
                                        <React.Fragment>
                                            <Grid item xs={12}>
                                                <div className={classes.item}>
                                                    {/*<div className={classes.label}>*/}
                                                    {/*    <img src={mailIcon} alt={this.props.t('login.fields.mail.js')}/>*/}
                                                    {/*    <span>{this.props.t('login.fields.mail.js')}</span>*/}
                                                    {/*</div>*/}
                                                    <div className={`${classes.input} ${errors.mailReset ? classes.validateInput : ''}`}>
                                                        <FormControl className={classes.customInput} fullWidth error={!!errors.mailReset}>
                                                            <Input
                                                                onChange={this.handleChange}
                                                                name={"mailReset"}
                                                                value={mailReset}
                                                                startAdornment={
                                                                    <InputAdornment position="start">
                                                                        <CustomInlineSvg svgProps={{src: mailIcon}}/>
                                                                    </InputAdornment>
                                                                }
                                                                placeholder={t('login.fields.email')}
                                                            />
                                                            {errors.mailReset &&
                                                                <FormHelperText>{errors.mailReset}</FormHelperText>
                                                            }
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </React.Fragment>
                                        :
                                        (typePage === PASSWORD_COMPONENT && !changePasswordSuccess
                                            ?
                                            <React.Fragment>
                                                <Grid item xs={12}>
                                                    <div className={classes.item}>
                                                        {/*<div className={classes.label}>*/}
                                                        {/*    <img src={passwordIcon} alt={this.props.t('login.fields.newPassword')}/>*/}
                                                        {/*    <span>{this.props.t('login.fields.newPassword')}</span>*/}
                                                        {/*</div>*/}
                                                        <div className={`${classes.input} ${errors.passwordReset || errors.invalidToken ? classes.validateInput : ''}`}>
                                                            <FormControl className={classes.customInput} fullWidth error={!!(errors.passwordReset || errors.invalidToken)}>
                                                                <Input
                                                                    onChange={this.handleChange}
                                                                    name={"passwordReset"}
                                                                    value={passwordReset}
                                                                    type={"password"}
                                                                    startAdornment={
                                                                        <InputAdornment position="start">
                                                                            <CustomInlineSvg svgProps={{src: passwordIcon}}/>
                                                                        </InputAdornment>
                                                                    }
                                                                    placeholder={t('login.fields.newPassword')}
                                                                />
                                                                {errors.passwordReset &&
                                                                    <FormHelperText>{errors.passwordReset}</FormHelperText>
                                                                }
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div className={classes.item}>
                                                        {/*<div className={classes.label}>*/}
                                                        {/*    <img src={passwordIcon} alt={this.props.t('login.fields.confirmPassword')}/>*/}
                                                        {/*    <span>{this.props.t('login.fields.confirmPassword')}</span>*/}
                                                        {/*</div>*/}
                                                        <div className={`${classes.input} ${errors.confirmPasswordReset || errors.invalidToken ? classes.validateInput : ''}`}>
                                                            <FormControl className={classes.customInput} fullWidth error={!!(errors.confirmPasswordReset || errors.invalidToken)}>
                                                                <Input
                                                                    onChange={this.handleChange}
                                                                    name={"confirmPasswordReset"}
                                                                    value={confirmPasswordReset}
                                                                    type={"password"}
                                                                    startAdornment={
                                                                        <InputAdornment position="start">
                                                                            <CustomInlineSvg svgProps={{src: passwordIcon}}/>
                                                                        </InputAdornment>
                                                                    }
                                                                    placeholder={t('login.fields.confirmPassword')}
                                                                />
                                                                {errors.confirmPasswordReset ?
                                                                    <FormHelperText>{errors.confirmPasswordReset}</FormHelperText> :
                                                                    errors.invalidToken ? <FormHelperText>{errors.invalidToken}</FormHelperText> :""
                                                                }
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </React.Fragment>
                                            :
                                            ''
                                        )
                                    )
                                )
                            }
                            {!successRequest && !successfulText && typePage === SIGN_UP &&
                                <React.Fragment>
                                    <Grid item lg={6} className={classes.gridItem + " " + classes.customGridItem}></Grid>
                                    <Grid   item xs={12} sm={6} className={classes.gridItem + " " + classes.customPostalItem}>
                                        <div className={classes.item}>
                                            {/*<div className={classes.label}>*/}
                                            {/*    <img src={postalCode} alt={this.props.t('login.fields.addressPostalCode')}/>*/}
                                            {/*    <span>{this.props.t('login.fields.addressPostalCode')}</span>*/}
                                            {/*</div>*/}
                                            <div className={`${classes.input} ${errors.addressPostalCode ? classes.validateInput : ''}`}>
                                                <FormControl fullWidth error={!!errors.addressPostalCode}>
                                                    <Input
                                                        name="addressPostalCode"
                                                        value={addressPostalCode}
                                                        onChange={this.handleChange}
                                                        type={"text"}
                                                        startAdornment={
                                                            <InputAdornment position="start">
                                                                <CustomInlineSvg svgProps={{src: postalCodeSU}}/>
                                                            </InputAdornment>
                                                        }
                                                        placeholder={t('login.fields.addressPostalCode')}
                                                    />
                                                    {errors.addressPostalCode &&
                                                    <FormHelperText>{errors.addressPostalCode}</FormHelperText>
                                                    }
                                                </FormControl>
                                            </div>
                                        </div>
                                    </Grid>
                                </React.Fragment>

                            }
                            {!successRequest && !successfulText && <Grid item xs={12} lg={typePage === SIGN_UP ? 5 : 12} className={`${classes.captchaCode} ${typePage === SIGN_UP?"signUpPage":""}`}>
                                <ReCAPTCHA
                                    style={{display: "inline-block"}}
                                    theme="dark"
                                    ref={this._reCaptchaRef}
                                    sitekey={CAPTCHA_SITE_KEY}
                                    onChange={this.handleChangeCaptcha}
                                    hl={this.props.i18n.language}
                                    // asyncScriptOnLoad={this.asyncScriptOnLoad}
                                />
                                <ErrorInput textValidate={errors.captchaCode}/>
                            </Grid>}
                        </Grid>
                    </Grid>
                    {(!successRequest && !checkCreateNewClient && !changePasswordSuccess) &&
                        <Grid xs={12} sm={10} item className={`${classes.buttonSubmit} ${typePage === SIGN_UP ? classes.buttonSignUp : ""}`}>
                            <Button onClick={() => {
                                this.handleSubmit();
                            }}>
                                {typePage === LOGIN_PAGE ? this.props.t('button.login') : (typePage === SIGN_UP ? this.props.t('login.text.confirmRegistration') : (typePage === PASSWORD_COMPONENT ? this.props.t('button.submit') : this.props.t('button.sendRequest')))}
                            </Button>
                        </Grid>
                    }
                    {typePage === RESET_PASSWORD || typePage === PASSWORD_COMPONENT ?
                        <div className={classes.keyIcon}>
                            <img src={keyIcon} alt=""/>
                        </div>
                        :
                        ''
                    }
                    {
                        typePage === SIGN_UP && <span
                            className={classes.backToLogin}
                            onClick={(event)=>this.props.backLogin(event, LOGIN_PAGE)}
                        >
                            &lt; {t('button.login')}
                        </span>
                    }
                </form>
            </React.Fragment>
        )
    }
}

AuthForm.defaultProps = {
    title: '',
    forgotPasswordFunc: () => {},
    backFunction: () => {},
    backLogin: () => {}
}

AuthForm.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
    typePage: PropTypes.string,
    forgotPasswordFunc: PropTypes.func,
    backFunction: PropTypes.func,
    backLogin: PropTypes.func
}

const mapStateToProps = (state) => {
    return {
        successRequest: state.authReducer.successRequest,
        isLoading: state.applicationReducer.isLoading,
        checkCreateNewClient: state.authReducer.checkCreateNewClient,
        checkSwitch: state.authReducer.checkSwitch,
        dataLogin: state.authReducer.dataLogin,
        changePasswordSuccess: state.authReducer.changePasswordSuccess
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setRequestResetPassword: (successRequest) => dispatch(auth.setRequestResetPassword(successRequest)),
        setIsLoading: (isLoading) => dispatch(application.setIsLoading(isLoading)),
        checkCreateNewClientSuccess: (checkCreateNewClient) => dispatch(auth.checkCreateNewClientSuccess(checkCreateNewClient)),
        saveTypeFormAuth: (typeFormAuth) => dispatch(auth.saveTypeFormAuth(typeFormAuth)),
        setDataUser: (dataUser) => dispatch(auth.setDataUser(dataUser)),
        checkSwitchAuthForm: (checkSwitch) => dispatch(auth.checkSwitchAuthForm(checkSwitch)),
        setDataLogin: (dataLogin) => dispatch(auth.setDataLogin(dataLogin)),
        changePassword: (changePasswordSuccess) => dispatch(auth.changePassword(changePasswordSuccess))
    }
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withRouter,withWidth())(withTranslation()(AuthForm));
