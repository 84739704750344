import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import Scheduler, {View, Resource} from 'devextreme-react/scheduler';
import {withRouter} from "react-router";
import calenderIcon from "../../../images/managerCalendar/calendar.svg";
import calender1Icon from "../../../images/managerCalendar/calendar1.svg";
import timelineIcon from "../../../images/managerCalendar/timline.svg";
import timeline1Icon from "../../../images/managerCalendar/timeline1.svg";
import CustomButton from "../../../theme/CustomElements/CustomButton";
import taskIcon from "../../../images/managerCalendar/type/task.svg";
import sunIcon from "../../../images/managerCalendar/type/sun.svg";
import moment from "moment";
import LoadingAction from "../../../theme/LoadingAction";
import AppSelect from "../../../theme/form/Select/AppSelect";
import {Grid} from "@material-ui/core";
import {USER_TYPE_ADMIN, USER_TYPE_REPAIRER} from "../../../constants/constants";
import ModalRestday from "./modal/ModalRestday";
import axios from "axios";
import {API_GET_USER_LIST} from "../../../constants/apiSuffix";
import qs from "qs";
import {ConvertToDateY_M_D} from "../../../functions/datetime";
import {appointmentTimelineView, appointmentView} from "./scheduleFuntion/appointment";
import {stylesManagerCalenderPageRight} from "./scheduleFuntion/styleRight";
import CustomSvg from "../../../theme/CustomElements/CustomSvg";
import ModalAddRepairer from "../../client/request/modal/ModalAddRepairer";
import ModalEditRestday from "./modal/ModalEditRestday";
import CustomInlineSvg from "../../../theme/CustomElements/CustomInlineSvg";

export const CALENDAR = "CALENDAR";
export const TIMELINE = "TIMELINE";
export  const REQUEST_TASK = "REQUEST";
export const REST_DAY = "REST_DAY";
export const COMPANY_HOLIDAY = "COMPANY_HOLIDAY";
const MODAL_ADD_REST_DAY = "MODAL_ADD_REST_DAY";
export const MODAL_EDIT_REST_DAY = "MODAL_EDIT_REST_DAY"
export const MODAL_DETAIL_REQUEST = "MODAL_DETAIL_REQUEST"

class ManagerCalendarPageRight extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            filterGroupPerson: null,
            openModal: "",
            groupsDataRepairerDefault: [],
            groupsDataRepairer: [],
            dataSchedule: [],
            filterTypeItemTimeline: "",
            valueModal: {}
        };
    }

    handleOpenModal = (type, valueModal) => {
        this.setState({
            openModal: type,
            valueModal: valueModal? valueModal: {}
        })
    }
    handleCloseModal = (value) => {
        this.setState({
            openModal: "",
            valueModal: {}
        })
    }

    handleChangeField = (value, field) => {
        if (field === "tabShow") {
            if(value === TIMELINE && this.props.tabShow !== value) {
                this.getRepairerList()
            }
            this.props.setTabShow(value)
        }else {
            if (field === "filterTypeItemTimeline" && value === this.state.filterTypeItemTimeline) {
                value = "";
            }
            let newStateUpdate = {
                [field]: value,
            };
            if (field === "filterGroupPerson" && value) {
                let groupsDataRepairerNew = [];
                if (value.value) {
                    groupsDataRepairerNew = this.state.groupsDataRepairerDefault.filter(item => (item.value === value.value))
                } else {
                    groupsDataRepairerNew = this.state.groupsDataRepairerDefault;
                }
                newStateUpdate = {...newStateUpdate, groupsDataRepairer: groupsDataRepairerNew}
            }
            this.setState(newStateUpdate)
        }
    }

    getRepairerList = () => {
        this.setState({isLoading: true})
        const urlRepairer = API_GET_USER_LIST + "?" + qs.stringify({userType: [USER_TYPE_REPAIRER, USER_TYPE_ADMIN],checkEnableUser:1, maxPerPageAll: 1})
        axios.get(urlRepairer).then(res => {
            if (res.status === 200) {
                let dataItems = res.data.items;
                let groupsDataRepairer = [];
                let arrayGroupsId = []
                dataItems.map(item => {
                    const labelText = item.person && item.person.fullName ? item.person.fullName : item.email;
                    let itemArray = {
                        text: labelText,
                        id: item.id,
                        value: item.id,
                        label: labelText,
                    };
                    groupsDataRepairer.push(itemArray)
                    arrayGroupsId.push(item.id)
                });
                this.props.setArrayGroupsId(arrayGroupsId)
                this.setState({
                    groupsDataRepairerDefault: groupsDataRepairer,
                    groupsDataRepairer: groupsDataRepairer,
                    isLoading: false,
                })
            } else {
                throw new Error();
            }
        })
            .catch(err => {
                this.setState({
                    isLoading: false,
                })
            })
    }

    componentDidMount() {
        if (this.props.tabShow === TIMELINE) {
            this.getRepairerList()
        }
    }



    groupsRender = (item) => {
        return <div>{item.id}</div>
    }

    filterValueGroup = (value) => {

    }
    appointmentTooltip = (model) => {
        return (appointmentTimelineView(model, this.props))
    }

    closeModalAndUpdateData = ()=>{
        this.setState({
            openModal: "",
            valueModal: {}
        }, function () {
            this.props.setForceUpdate(true)
        })
    }
    render() {
        const {
            classes, dateShowInCalendar, t, countDataHolidayByMonthYear, dataResourceCalender,countRequestTask,countValidateRestDay,
            tabShow
        } = this.props;
        const {
            isLoading,
            filterGroupPerson,
            openModal,
            groupsDataRepairer,
            groupsDataRepairerDefault,
            filterTypeItemTimeline,
            valueModal
        } = this.state;
        const lengthSchedule = tabShow === CALENDAR ? 660 : (((groupsDataRepairer.length >= 7 ? 7 : groupsDataRepairer.length) * 70) + 60);
        const popsScheduler = tabShow === CALENDAR ? { appointmentComponent: (model) => appointmentView(model, this.props),
            dataCellRender:this.renderDataCell,
            onAppointmentDblClick:(model)=>{
                model.cancel = true;
            },
            onAppointmentClick: (model)=> {
                model.cancel = true;
            }
        } : {
            appointmentComponent: (model) => appointmentTimelineView(model, this.props, this),
            groups: ["userId"],
            crossScrollingEnabled:true,
            appointmentTooltipComponent: (model ) => this.appointmentTooltip(model),
            onAppointmentDblClick:(model)=>{
                model.cancel = true;
            },
            onAppointmentClick: (model)=> {
                model.cancel = true;
                if(model.appointmentData.typeItem === REQUEST_TASK){
                    this.handleOpenModal(MODAL_DETAIL_REQUEST , model.appointmentData.dataDefault ?? {})
                }else {
                    this.handleOpenModal( MODAL_EDIT_REST_DAY , model.appointmentData)
                }

            }
        };
        const dateSelectY_M_D = ConvertToDateY_M_D(dateShowInCalendar);
        let groupsDataRepairerSelect =  [{value: "", label: t("managerCalendarPage.managerCalendarRight.all")}].concat(groupsDataRepairerDefault);

        let dataResourceNew = tabShow !== CALENDAR && filterTypeItemTimeline === REQUEST_TASK ? [] : [...dataResourceCalender];
        if(tabShow === CALENDAR &&  Object.keys(countRequestTask).length > 0){
            // eslint-disable-next-line array-callback-return
            Object.values(countRequestTask).map((item, key) => {
                item.map(child=>{
                    dataResourceNew.push(child)

                })
            })
        }else if((!filterTypeItemTimeline || filterTypeItemTimeline === REQUEST_TASK) && countRequestTask.hasOwnProperty(dateSelectY_M_D) && countRequestTask[dateSelectY_M_D]){
            dataResourceNew =  dataResourceNew.concat(countRequestTask[dateSelectY_M_D])
        }

        if (tabShow === CALENDAR &&  Object.keys(countDataHolidayByMonthYear).length > 0) {
            // eslint-disable-next-line array-callback-return
            Object.values(countDataHolidayByMonthYear).map((item, key) => {
                item.map(child => {
                    dataResourceNew.push(child)
                })
            })
        }
        return (
            <div className={classes.managerCalendarPage}>
                {isLoading && <LoadingAction/>}
                {openModal && openModal === MODAL_ADD_REST_DAY && <ModalRestday openModal={openModal} closeModalAndUpdateData={this.closeModalAndUpdateData} groupsDataRepairerSelect={groupsDataRepairerSelect} handleCloseModal={this.handleCloseModal}/>}

                {/*{openModal && <ModalAddRepairer  openModal={openModal} handleCloseModal={this.handleCloseModal}/>}*/}
                {openModal && openModal === MODAL_DETAIL_REQUEST && <ModalAddRepairer openModal={openModal}
                                                                                      modalValue={valueModal}
                                                                                      setForceUpdate={this.props.setForceUpdate}
                                                                                      handleCloseModal={this.handleCloseModal}/>}
                {openModal && openModal === MODAL_EDIT_REST_DAY && <ModalEditRestday openModal={openModal}
                                                                                                                    modalValue={valueModal}
                                                                                                                    handleCloseModal={this.handleCloseModal}/>}

                <div className={`managerCalendarHeader ${tabShow === TIMELINE ? "managerCalendarHeaderTimeline" : ""}`}>
                    <Grid container item xs={12}>
                        {tabShow !== CALENDAR && <div
                            className={"monthSelectTimeline"}> {moment(dateShowInCalendar).format("dd, MMMM DD, YYYY")}</div>}
                        <Grid container item xs={tabShow === TIMELINE ? 12:6} lg={8} className={"headerOfCalenderWrapper"}>
                            {tabShow === CALENDAR ?
                                <div className={"monthSelect"}>{moment(dateShowInCalendar).format("MMMM")}</div> :
                                <Grid container className={"managerCalendarHeaderLeft"}>
                                    <Grid item xs={6} md={6} lg={3} className={"headerTypeStatusWrapper"}>
                                        <div>
                                        <AppSelect
                                            classNamePrefix={"groupPersonSelect"}
                                            value={filterGroupPerson}
                                            options={groupsDataRepairerSelect}
                                            onChange={(value) => this.handleChangeField(value, "filterGroupPerson")}
                                            debounceTimeout={500}
                                            cacheOptions={false}
                                            placeholder={t("managerCalendarPage.managerCalendarRight.selectStaff")}
                                        />
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={3} className={"headerTypeStatusWrapper"}>
                                        <div className={`headerTypeStatus ${filterTypeItemTimeline === REQUEST_TASK ? "tabActiveTask":""}`} onClick={()=>this.handleChangeField(REQUEST_TASK, "filterTypeItemTimeline")}>
                                            <div
                                                className={`${classes.iconStatusWrapper} iconStatusWrapper1 taskIconWrapper`}>
                                                <CustomSvg
                                                    svgProps={{
                                                        src: taskIcon,
                                                    }}
                                                    scale="1.4"
                                                />
                                            </div>
                                            <div className={classes.numberByStatus}>
                                                {countRequestTask[ConvertToDateY_M_D(dateShowInCalendar)] ?
                                                    filterGroupPerson && filterGroupPerson.value ?
                                                        countRequestTask[dateSelectY_M_D].filter(item=>item.userId ===  filterGroupPerson.value).length
                                                        :
                                                    countRequestTask[ConvertToDateY_M_D(dateShowInCalendar)].length : 0}
                                            </div>
                                            <div className={'statusName'}>
                                                {t("managerCalendarPage.type.task")}
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={3} className={"headerTypeStatusWrapper"}>
                                        <div className={`headerTypeStatus ${filterTypeItemTimeline === REST_DAY ? "tabActiveSun":""}`}  onClick={()=>this.handleChangeField(REST_DAY, "filterTypeItemTimeline")}>
                                            <div
                                                className={`${classes.iconStatusWrapper} iconStatusWrapper1 sunIconWrapper`}>
                                                <CustomSvg
                                                    svgProps={{
                                                        src: sunIcon,
                                                    }}
                                                    scale="1.4"
                                                />
                                            </div>
                                            <div className={classes.numberByStatus}>
                                                {
                                                    countValidateRestDay.hasOwnProperty(dateSelectY_M_D)?
                                                        filterGroupPerson && filterGroupPerson.value ?
                                                            countValidateRestDay[dateSelectY_M_D].filter(item=>item.userId ===  filterGroupPerson.value).length
                                                            :
                                                        countValidateRestDay[dateSelectY_M_D].length
                                                        : 0
                                                }
                                            </div>
                                            <div className={'statusName'}>
                                                {t("managerCalendarPage.type.holiday")}
                                            </div>
                                        </div>
                                    </Grid>
                                    {/*<Grid item xs={6} md={6} lg={3} className={"headerTypeStatusWrapper"}>*/}
                                    {/*    <div className={"headerTypeStatus"}>*/}
                                    {/*        <div*/}
                                    {/*            className={`${classes.iconStatusWrapper} iconStatusWrapper1 docIconWrapper`}>*/}
                                    {/*            <img alt="" src={docIcon}/>*/}
                                    {/*        </div>*/}
                                    {/*        <div className={classes.numberByStatus}>*/}
                                    {/*            6*/}
                                    {/*        </div>*/}
                                    {/*        <div className={'statusName'}>*/}
                                    {/*            {t("managerCalendarPage.type.request")}*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</Grid>*/}
                                </Grid>

                            }
                        </Grid>
                        <Grid container item xs={tabShow === TIMELINE ?12:6} lg={4} className={"managerCalendarHeaderRight"}>
                            <div
                                className={`tabShowContent tabShowContentLeft ${tabShow === CALENDAR ? "tabActive" : ""}`}
                                onClick={() => this.handleChangeField(CALENDAR, "tabShow")}><img
                                src={tabShow === CALENDAR ? calenderIcon : calender1Icon} alt=""/></div>
                            <div
                                className={`tabShowContent tabShowContentRight ${tabShow !== CALENDAR ? "tabActive" : ""}`}
                                onClick={() => this.handleChangeField(TIMELINE, "tabShow")}><img
                                src={tabShow === CALENDAR ? timelineIcon : timeline1Icon} alt=""/></div>
                            <CustomButton
                                title={t("managerCalendarPage.managerCalendarRight.btnAddRestday")}
                                onClick={() => this.handleOpenModal(MODAL_ADD_REST_DAY)}
                                className={'btnAddHoliday'}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div
                    className={`${classes.calenderDetailWrapper} ${tabShow === CALENDAR ? "" : "timelineCalenderWrapper"}`}>
                   <Scheduler
                        timeZone="Asia/Ho_Chi_Minh"
                        dataSource={dataResourceNew}
                        views={["timelineDay", "month"]}
                        currentView={(tabShow === CALENDAR ? "month" : "timelineDay")}
                        currentDate={dateShowInCalendar}
                        cellDuration={60}
                        // dataCellRender={this.renderDataCell}
                        // dateCellRender={this.renderDateCell}
                        // timeCellRender={this.renderTimeCell}
                        height= {lengthSchedule}
                        onAppointmentAdding={null}
                        onAppointmentAdded={null}
                        maxAppointmentsPerCell={1}
                        crossScrollingEnabled={true}
                        editing={{allowAdding: false, allowUpdating: false, allowDeleting: false}}
                        startDayHour={0}
                        endDayHour={24}
                        showAllDayPanel={false}
                        onAppointmentDblClick={(e)=>{
                            e.cancel = true;
                        }}
                        {...popsScheduler}
                    >
                        <Resource
                            fieldExpr="userId"
                            allowMultiple={false}
                            dataSource={groupsDataRepairer}
                            // label="Priority"
                            // displayExpr={this.groupsRender}
                        />
                    </Scheduler>
                </div>

            </div>
        )
    }

    renderDataCell = (itemData) => {
        const {countDataHolidayByMonthYear} = this.props;
        return <div
            className={countDataHolidayByMonthYear[ConvertToDateY_M_D(itemData.startDate)] ? "companyHolidayWrapper" : ""}>
            {itemData.text}
        </div>

    }

    renderDateCell(itemData) {
        return <div className={"renderDateCell"}>{moment(itemData.date).format("dddd")} </div>
    }

    renderTimeCell(itemData) {
        return <div className={"fjdkfjdkfj"}>
            {itemData.id}
            {/*{hasCoffeeCupIcon ? <div className='cafe' /> : null}*/}
        </div>
    }


}

ManagerCalendarPageRight.propTypes = {
    classes: PropTypes.object.isRequired
}

export default compose(
    withStyles(stylesManagerCalenderPageRight),
    withTranslation(),
    withRouter
)(ManagerCalendarPageRight);