import React, {Suspense} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withStyles} from "@material-ui/core";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import axios from "axios";
import LoadingAction from "./theme/LoadingAction";
import * as authActions from "./_actions/auth";
import PrivateRoute from "./PrivateRoute";
import * as links from "./constants/links";
import {DATA_USER, REDIRECT, USER_TYPE_CLIENT, USER_TYPE_SUPER_ADMIN} from "./constants/constants";
import arrayRoutesPrivate from "./constants/routesPrivate";
import {/*ADD_REPORT_TO_REQUEST,*/ LOGIN} from "./constants/links";
import Page404 from "./theme/PageError/Page404";
// import notify from "devextreme/ui/notify";

const styles = {
    mainContent: {
        //background: '#f8f8ff',
        background: '#f2f7fc',
        '& div[classesoverride="notificationBackOfficeWrapper"]':{
            top: '0 !important',
            alignItems: 'flex-start',
            paddingTop: '2rem',
            '& .notificationBackOffice':{
                backgroundColor: '#4caf50'
            }
        }
    }
};

class RoutesMap extends React.Component {
    constructor(props) {
        super(props);
        axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response) {
                    if( error.response.status === 401) {
                        localStorage.removeItem(DATA_USER)
                        window.location.href = links.LOGIN;
                    }else if( error.response.status === 404) {
                        window.location.href = links.PAGE404;
                    }else if( error.response.status === 403 && props.location.pathName !== LOGIN) {
                        window.location.href = links.PAGE403
                    }
                }
                return Promise.reject(error);
            }
        );
    }


    componentDidMount() {
        this.unlisten = this.props.history.listen( location =>  {
            if (!this.props.canAccessPage || this.props.beBadRequest || this.props.beServerError) {
                this.props.changeUrl();
            }
        });

    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {

        const {classes,
            // history,
            location,
            dataUser
        } = this.props;
        const routesMap = arrayRoutesPrivate.filter((route) => {
           return dataUser &&  dataUser.data &&  dataUser.data.group && ((route.path !== links.REQUEST && dataUser.data.group === USER_TYPE_SUPER_ADMIN) || (!!route.permission && route.permission.length > 0  && route.permission.includes(dataUser.data.group)));
        }).map((route, index)=> {
            const component = route.component;
            return <PrivateRoute
                key={index}
                path={route.path}
                exact={route.exact}
                component={component}
                // history={history}
            />
        });
        let checkAddQuotation = null;
        try {
            if (location.pathname.includes("/add-quotation/")) {
                checkAddQuotation = location.pathname;
            }
        } catch (e) {

        }
        if (dataUser?.data.group === USER_TYPE_CLIENT && dataUser?.data.isSocial) {
            const redirectTo = (localStorage.getItem(REDIRECT) ?? "").includes("/add-quotation/") ? localStorage.getItem(REDIRECT) : null;
            if (localStorage.getItem(REDIRECT)) {
                localStorage.removeItem(REDIRECT)
            }
            if (redirectTo) {
                return <Redirect to={redirectTo}/>;
            }
        }

        // if (!this.props.canAccessPage){
        //     return <Redirect to={links.PAGE403}/>
        // }
        // else if (this.props.beBadRequest){
        //     return <Redirect to={links.PAGE400}/>
        // }
        // else if (this.props.beServerError){
        //     return <Redirect to={links.PAGE500}/>
        // }
        if(!dataUser){
            return <Redirect to={links.LOGIN + (checkAddQuotation ? `?redirect=${checkAddQuotation}`: "")}/>;
        }
        return (
            <div className={classes.mainContent}>
                <Suspense fallback={<LoadingAction/>}>
                    <Switch>
                        {routesMap && routesMap.length >0 ? routesMap : ""}
                        <Route component={Page404}/>
                    </Switch>
                </Suspense>
            </div>
        );
    }
}

RoutesMap.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        dataUser: state.authReducer.dataUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: ()=>{
            dispatch(authActions.logout());
        },
        accessDeny: ()=>{
            dispatch(authActions.accessDeny());
        },
        changeUrl: ()=>{
            dispatch(authActions.changeUrl());
        },
        badRequest: ()=>{
            dispatch(authActions.badRequest());
        },
        serverError: ()=>{
            dispatch(authActions.serverError());
        }
    }
};

export default compose(connect(mapStateToProps,mapDispatchToProps),withStyles(styles),withTranslation(),withRouter)(RoutesMap);
