import React from "react";
import CustomSvg from "../../../theme/CustomElements/CustomSvg";
import {Tooltip} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import SET_OK_Icon from "../../../images/request/SET_OK.svg";
import NOT_ELIGIBLE_Icon from "../../../images/request/NOT_ELIGIBLE.svg";
import IN_PROGRESS_Icon from "../../../images/request/IN_PROGRESS.svg";
import MAKE_AN_APPOINTMENT_Icon from "../../../images/request/MAKE_AN_APPOINTMENT.svg";
import CANCELED_Icon from "../../../images/request/CANCELED.svg";
import DIAGNOSIS_RECEIVED_Icon from "../../../images/request/DIAGNOSIS_RECEIVED.svg";
import UNDER_ANALYSIS_BY_THE_EXPERT_Icon from "../../../images/request/UNDER_ANALYSIS_BY_THE_EXPERT.svg";
import SCHEDULED_APPOINTMENT_Icon from "../../../images/request/SCHEDULED_APPOINTMENT.svg";
import {
    CLIENT_DOC_TAB_BILL,
    CLIENT_DOC_TAB_IMAGE_VIDEO,
    CLIENT_DOC_TAB_OTHER,
    CLIENT_DOC_TAB_REPORT_QUOTATION,
    REQUEST_DOC_TYPE_BILL,
    REQUEST_DOC_TYPE_IMAGE, REQUEST_DOC_TYPE_OTHER,
    REQUEST_DOC_TYPE_QUOTATION, REQUEST_DOC_TYPE_REPORT,
    REQUEST_DOC_TYPE_VIDEO
} from "../../../constants/constants";

export const CLIENT_STATUS_SET_OK = 'CLIENT_STATUS_SET_OK';
export const CLIENT_STATUS_NOT_ELIGIBLE = 'CLIENT_STATUS_NOT_ELIGIBLE';
export const CLIENT_STATUS_IN_PROGRESS = 'CLIENT_STATUS_IN_PROGRESS';
export const CLIENT_STATUS_MAKE_AN_APPOINTMENT = 'CLIENT_STATUS_MAKE_AN_APPOINTMENT';
export const CLIENT_STATUS_CANCELED = 'CLIENT_STATUS_CANCELED';
export const CLIENT_STATUS_DIAGNOSIS_RECEIVED = 'CLIENT_STATUS_DIAGNOSIS_RECEIVED';
export const CLIENT_STATUS_UNDER_ANALYSIS_BY_THE_EXPERT = 'CLIENT_STATUS_UNDER_ANALYSIS_BY_THE_EXPERT';
export const CLIENT_STATUS_SCHEDULED_APPOINTMENT = 'CLIENT_STATUS_SCHEDULED_APPOINTMENT';

export const listStatusClient = {
    [CLIENT_STATUS_SET_OK]: {
        iconColor: '#00d1a6',
        backgroundColor: '#d7fff9',
        icon: SET_OK_Icon,
        label: 'request.list.statusRequest.CLIENT_STATUS_SET_OK'
    },
    [CLIENT_STATUS_NOT_ELIGIBLE]: {
        iconColor: '#e33838',
        backgroundColor: '#fff3f3',
        icon: NOT_ELIGIBLE_Icon,
        label: 'request.list.statusRequest.CLIENT_STATUS_NOT_ELIGIBLE'
    },
    [CLIENT_STATUS_IN_PROGRESS]: {
        iconColor: '#eb35b4',
        backgroundColor: '#ffecf9',
        icon: IN_PROGRESS_Icon,
        label: 'request.list.statusRequest.CLIENT_STATUS_IN_PROGRESS'
    },
    [CLIENT_STATUS_MAKE_AN_APPOINTMENT]: {
        iconColor: '#008cca',
        backgroundColor: '#daf3ff',
        icon: MAKE_AN_APPOINTMENT_Icon,
        label: 'request.list.statusRequest.CLIENT_STATUS_MAKE_AN_APPOINTMENT'
    },
    [CLIENT_STATUS_CANCELED]: {
        iconColor: '#989898',
        backgroundColor: '#f1f1f1',
        icon: CANCELED_Icon,
        label: 'request.list.statusRequest.CLIENT_STATUS_CANCELED'
    },
    [CLIENT_STATUS_DIAGNOSIS_RECEIVED]: {
        iconColor: '#4e4eff',
        backgroundColor: '#e6f0ff',
        icon: DIAGNOSIS_RECEIVED_Icon,
        label: 'request.list.statusRequest.CLIENT_STATUS_DIAGNOSIS_RECEIVED'
    },
    [CLIENT_STATUS_UNDER_ANALYSIS_BY_THE_EXPERT]: {
        iconColor: '#996eff',
        backgroundColor: '#f4efff',
        icon: UNDER_ANALYSIS_BY_THE_EXPERT_Icon,
        label: 'request.list.statusRequest.CLIENT_STATUS_UNDER_ANALYSIS_BY_THE_EXPERT'
    },
    [CLIENT_STATUS_SCHEDULED_APPOINTMENT]: {
        iconColor: '#008cca',
        backgroundColor: '#daf3ff',
        icon: SCHEDULED_APPOINTMENT_Icon,
        label: 'request.list.statusRequest.CLIENT_STATUS_SCHEDULED_APPOINTMENT'
    },
};
export const REQUEST_LIST_SORTING_STATE_COLUMN_EXTENSIONS = [
    {columnName: 'action', sortingEnabled: false},
    {columnName: 'action', sortingEnabled: false},
    {columnName: 'note', sortingEnabled: false},
    {columnName: "status", sortingEnabled: false},
    {columnName: "categories", sortingEnabled: false},
    // {columnName: "createdDate", sortingEnabled: false},
    {columnName: "problem", sortingEnabled: false},
    {columnName: "client", sortingEnabled: false},
    {columnName: "video", sortingEnabled: false},
    {columnName: "otherDoc", sortingEnabled: false},
    {columnName: "reportPaid", sortingEnabled: false},
];


export const REQUEST_LIST_FILTER_COLUMNS_ENABLE = [
    {columnName: "action", filteringEnabled: false},
    {columnName: "note", filteringEnabled: false},
    {columnName: "status", filteringEnabled: false},
    // {columnName: "categories", filteringEnabled: false},
    // {columnName: "createdDate", filteringEnabled: false},
    // {columnName: "problem", filteringEnabled: false},
    {columnName: "registrationStart", filteringEnabled: false},
    {columnName: "video", filteringEnabled: false},
    {columnName: "otherDoc", filteringEnabled: false},
    {columnName: "reportPaid", filteringEnabled: false},
];

export const MENU_DOCUMENT_TYPE_LIST = [
    {
        code: CLIENT_DOC_TAB_IMAGE_VIDEO,
        name: ("request.requestDetail.documentType.IMAGE_VIDEO"),
        items: [REQUEST_DOC_TYPE_IMAGE, REQUEST_DOC_TYPE_VIDEO]
    },
    {
        code: CLIENT_DOC_TAB_REPORT_QUOTATION,
        name: ("request.requestDetail.documentType.REPORT_QUOTATION"),
        items: [REQUEST_DOC_TYPE_QUOTATION, REQUEST_DOC_TYPE_REPORT]
    },
    {
        code: CLIENT_DOC_TAB_BILL,
        name: ("request.requestDetail.documentType.BILL"),
        items: [REQUEST_DOC_TYPE_BILL]
    },
    {
        code: CLIENT_DOC_TAB_OTHER,
        name: ("request.requestDetail.documentType.OTHER"),
        items: [REQUEST_DOC_TYPE_OTHER]
    }
]
export const customRenderShowBtnAction = ({
                                              iconComponent,
                                              titleToolTip = "",
                                              title = "",
                                              navLink = "",
                                              onClick,
                                              icon,
                                              propsDiv = {},
                                              className = "",
                                              hiddenTitle,
                                              componentContent = undefined,
                                              componentBottomTitle,
                                              ariaOwns=""
                                          }) => {
    const renderContentDivLink = (<React.Fragment>
        <div className={"iconWrapper"}
             aria-owns={ariaOwns ? ariaOwns : ""}
             aria-haspopup={ariaOwns ? "false" : "true"}
        >
            {icon ? <CustomSvg
                svgProps={{
                    src: icon,
                    title: title
                }}
                
            /> : ""}
            {iconComponent ?? ""}
        </div>
        {!hiddenTitle && <div className={"titleIcon"}>{title}</div>}
        {componentBottomTitle ?? ""}
    </React.Fragment>)

    const renderContent = componentContent ? componentContent :
        navLink ? <NavLink to={navLink} className={`btnActionWrapper linkAction ${className ?? ""}`}>
                {renderContentDivLink}
            </NavLink> :
            <div onClick={onClick} className={`btnActionWrapper fiveAction ${className ?? ""}`}>
                {renderContentDivLink}
            </div>
    return iconComponent || componentContent || icon ?
        titleToolTip ?
            <Tooltip
                title={titleToolTip ?? ""}
                enterDelay={100}
                leaveDelay={200}
                tooltipstyle={{
                    backgroundColor: '#34558B',
                    fontSize: '0.8rem',
                    padding: '0.3rem',
                }}
                interactive
                arrow
            >
                {renderContent}
            </Tooltip> :
            renderContent
        : ""
}
