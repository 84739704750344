export const EMAIL_OR_USER_ID_NOT_EXIST = "EMAIL_OR_USER_ID_NOT_EXIST";
export const OVERLAP_DATE_TIME = "OVERLAP_DATE_TIME";
export const USER_NOT_EXIT = "USER_NOT_EXIT";
export const CREATE_RESTDAY_SUCCESS = "CREATE_RESTDAY_SUCCESS";
export const ITEM_NOT_EXIST = "ITEM_NOT_EXIST";
export const REPAIRER_BUSY = "REPAIRER_BUSY";
export const REPAIRER_NOT_EXIST = "REPAIRER_NOT_EXIST";
export const START_AFTER_END = "START_AFTER_END";

//CAPTCHA_ERROR
export const CAPTCHA_REQUEST = "g_recaptcha_response";
export const DOMAIN_REQUEST = "domain";
export const CAPTCHA_ERROR = "CAPTCHA_ERROR";
export const CAPTCHA_EXPIRED = "CAPTCHA_EXPIRED";
export const HOSTNAME_MISMATCH = "HOSTNAME_MISMATCH";
export const GOOGLE_RECAPTCHA_SECRET_MISMATCH = "GOOGLE_RECAPTCHA_SECRET_MISMATCH";
export const DOMAIN_NULL = "DOMAIN_NULL";
export const CAPTCHA_NULL = "CAPTCHA_NULL";
export const PASSWORD_MATCHES_OLD_PASSWORD = "PASSWORD_MATCHES_OLD_PASSWORD";
//CAPTCHA_ERROR
