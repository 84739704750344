import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {Grid, TextField, withWidth} from "@material-ui/core";
import personIcon from "../../../images/infomation/person.svg";
import timeIcon from "../../../images/infomation/time.svg";
import {NUNITO} from "../../../constants/fontCss";
import euroIcon from "../../../images/infomation/euro.svg";
import CustomSvg from "../../../theme/CustomElements/CustomSvg";
import Information, {OWNER, TENANT} from "./Information";
import Payment from "./Payment";
import {connect} from "react-redux";
import {
    errorsMessCreateRequest,
    errorsMessCreateRequestBillingAddress,
    errorsMessCreateRequestCompany
} from "../../../functions/checkErrorEmpty";
import {checkSubmitSendDataERROR} from "../../../functions/checkCondition";
import {
    API_EDIT_REQUEST, API_EDIT_REQUEST_BY_TOKEN,
    API_GET_REQUEST_BY_TOKEN,
    API_GET_SHOW_REQUEST
} from "../../../constants/apiSuffix";
import axios from "axios";
import LoadingAction from "../../../theme/LoadingAction";
import AlertCustom from "../../../theme/form/Alert/AlertCustom";
import * as links from "../../../constants/links";
import {
    COUNTRY_DEFAULT,
    CURRENCY_DEFAULT, DATA_USER,
    FALSE_NUMBER, GO_DOC, OBJECT_COMPANY, OBJECT_PERSONAL,
    PRICE_DEFAULT, SM,
    STRING,
    TRUE_NUMBER, XS
} from "../../../constants/constants";
import {Redirect, withRouter} from "react-router";
import * as application from "../../../_actions/application";
import {LOGIN} from "../../../constants/links";
import DateClient from "./DateCLient";
import moment from "moment";
import qs from "qs";
import {Autocomplete} from "@material-ui/lab";
import returnIcon from "../../../images/navbar/Groupe 8620.svg";
import {NavLink} from "react-router-dom";
import {
    checkRequestIsGoFree, checkRequestIsGoFreeAndRegistrationStartNull,
    compareDate, convertTimezoneHour
} from "../../../functions/functions";
import _ from "lodash"


const styles = theme => ({
    informationPage:{
        width: '100%',
        '& .selectTabLabel':{
            background: '#fff',
            borderRadius: 0,
            width: '100%'
        },
        '& .returnRequestWrapper':{
            background: '#fff',
            borderRadius: '15px 15px 0 0',
            '& .returnRequest':{
                display: 'flex',
                height: '100%'
            },
            '& .returnHistory': {
                borderRadius: 24,
                border: 'solid 1px #001529',
                textDecoration: 'none',
                padding: '0.5rem 1rem',
                display: 'flex',
                alignItems: 'center',
                color: '#001529',
                margin: '0.5rem',
                '& svg':{
                    '& path':{
                        stroke: '#001529',
                    }
                },
                '&:hover':{
                    background:'#ebebeb',
                    backgroundPosition: '100% 0',
                    transition: 'all .4s ease-in-out',
                },
                '& > span':{
                    fontSize: '1.1125rem',
                    paddingLeft: '0.5rem',
                    lineHeight: 0.9,
                    fontFamily: NUNITO
                }
            },
        }
    },
    tabStatusWrapper:{
        // padding: '1rem',
        '&.tabActive':{
            '& .euroIcon':{
                background: '#0000FF !important'
            },
            '& .content': {
                '& .contentRequest': {
                    '& .number': {
                        color: '#0000FF',
                    },
                    '& .statusType': {
                        color: '#0000FF',
                    }
                },
            },
            '& svg':{
                '& path': {
                    fill: '#0000FF'
                }
            },
            '& .tabStatus3':{
                '& svg':{
                    '& path': {
                        fill: '#fff'
                    }
                },
            }
        }
    },
    tabStatus: {
        height: '100%',
        // '&.tabStatus1':{
        //     borderRadius: '15px 0 0 0',
        //     [theme.breakpoints.down(XS)]:{
        //         borderRadius: '15px 15px 0 0'
        //     }
        // },
        '&.tabStatus3':{
        //     borderRadius: '0  15px 0 0',
            '& svg':{
                '& path': {
                    fill: '#FFF'
                }
            },
        },
        '&.tabValueSelect':{
            borderRadius: 0,
        },
        padding: '1rem',
        '&.tabStatus': {
            '&:hover': {
                cursor: 'pointer',
                background: "#ebebeb",
                '& .number': {
                    fontSize: '1.3rem !important',
                },
                '& .statusType': {
                    fontSize: '1.3rem !important',
                }
            },
        },
        '&.disabledTab': {
            '& .contentRequest': {
                color: '#bfbfbf',
            },
            '& svg':{
                '& path': {
                    fill: '#bfbfbf'
                }
            },
            '& .iconWrapper': {
                '& .euroIcon': {
                    background: '#bfbfbf',
                }
            }
        },
        display: 'flex',
        background: '#fff',
        alignItems: 'center',
        // boxShadow: '0px 4px 20px rgba(15, 15, 137, 0.1)',
        // borderRadius: 15,
        justifyContent:'space-between',
        '& .content': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // padding: '0.5rem 0.5rem 0.5rem 1.3rem',
            '& .iconWrapper': {
                width: 26,
                height: 26,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& .euroIcon':{
                    background: '#001529',
                    width: 26,
                    height: 26,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    '& svg':{
                       marginRight: 2
                    }
                }
            },
            '& .contentRequest':{
                paddingLeft: '1rem',
                display: 'flex',
                color: '#001529',
                '& .number':{
                    fontFamily: NUNITO,
                    fontSize: '1.25rem',
                    fontWeight: 700,
                    paddingRight: '0.5rem'
                },
                '& .statusType':{
                    fontFamily: NUNITO,
                    fontSize: '1.25rem',
                    fontWeight: 700,
                    textTransform: 'capitalize'
                },
                '& div':{
                    lineHeight: 1
                }
            }
        },
        '& .imgBackground':{
            borderRadius: '0 15px 15px 0',
        },
        '& svg':{
            '& path': {
                fill: '#001529'
            }
        },
    }
});

export const TAB_INFORMATION = "INFORMATION";
export const TAB_DATE = "DATE";
export const TAB_PAID = "PAID";

const tabOptions = [{
    icon: <CustomSvg
        svgProps={{
            src: personIcon,
        }}
        scale="1.3"
    />,
    label: "information.tab1",
    value: TAB_INFORMATION,
    numberLabel: '1.'
},
    {
        icon: <CustomSvg
            svgProps={{
                src: timeIcon,
            }}
            scale="1.3"
        />,
        label: "information.tab2",
        value: TAB_DATE,
        numberLabel: '2.'
    },
    {
        icon: <div className={"euroIcon"}><CustomSvg
            svgProps={{
                src: euroIcon,
            }}
            scale="1.1"
        /></div>,
        label: "information.tab3",
        value: TAB_PAID,
        numberLabel: '3.'
    }];
const formDataInfo = {
    problem: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    addressCity: "",
    addressPostalCode: "",
    addressCountry: "FR",
    addressDescription: "",
    constructionYear: "",
    sentByAgency: "",
    checkOwner: "",
    additionalInfo: "",
    acceptCertificate: false,
    userInfoId: null,
    registeredObject: OBJECT_PERSONAL,
    companyName: '',
    companyTaxCode: '',
    otherBillingAddress: false,
    billingAddressCity: '',
    billingAddressPostalCode: '',
    billingAddressCountry: 'FR',
    billingAddressDescription: '',
    floorNumber: '',
    isThereAnElevator: undefined,
    doorPassword: ''
}

class InformationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            isLoading: false,
            successOpen: false,
            errorOpen: false,
            tabInformation: TAB_INFORMATION,
            valueForm: {...formDataInfo},
            errors: {},
            redirectToUrl: "",
            pageEditNotLogin: true,
            paymentRequest: false,
            registrationStart: null,
            paymentPrice: null,
            paymentCurrency: null,
            hasPayment: false,
            messageSuccess: null,
            messageError: null,
            id: null,
            requestProblem: null,
            submitInfo: false,
            initialValueForm: {},
            changeTab1: false,
            dataTab2Initial: {},
            changeTab2: false,
            callSaveDate: false,
            tabNext: TAB_INFORMATION,
            disableTab3: false,
            checkRedirectToDetail: false,
            initialRequest: null
        };
        this.updateDataRequest = this.updateDataRequest.bind(this);
    }

    handleSubmitInformation = (tabInformation) => {
        const {location, t, match} = this.props;
        let {errors, valueForm, tabNext,paymentRequest,pageEditNotLogin} = this.state;
        const pathPage = location && location.pathname ? location.pathname : "";
        const isPageDetail = pathPage.replace(/[0-9]/g, '') === links.REQUEST_INFORMATION_DETAIL.replace(":id", '')
        for (let [key, value] of Object.entries(valueForm)) {
             if (errorsMessCreateRequest.hasOwnProperty(key) && (!["password","confirmPassword"].includes(key) || pageEditNotLogin) && (key !== "acceptCertificate" || (!paymentRequest && !isPageDetail))) {
                errors = checkSubmitSendDataERROR(value, errorsMessCreateRequest[key].type, errors, key, errorsMessCreateRequest[key].errorByField, t)
            }
        }
        if(valueForm.checkOwner === TENANT  && (!valueForm.leaseType || !valueForm.leaseType.value)){
            errors.leaseType =t("information.formTab1.errors.leaseTypeEmpty");
        }

        if(valueForm.sentByAgency === TRUE_NUMBER && !valueForm.realEstateCenterCode){
            errors.realEstateCenterCode = t("information.formTab1.errors.realEstateCenterCodeEmpty");
        }

        if(valueForm.registeredObject === OBJECT_COMPANY){
            for (let [key, value] of Object.entries(errorsMessCreateRequestCompany)) {
                errors = checkSubmitSendDataERROR(valueForm[key], value.type, errors, key, value.errorByField, t);
            }
        }

        if(valueForm.otherBillingAddress){
            for (let [key, value] of Object.entries(errorsMessCreateRequestBillingAddress)) {
                errors = checkSubmitSendDataERROR(valueForm[key], value.type, errors, key, value.errorByField, t);
            }
        }
        // console.log(errors);
        let apiUrlRequest = "";
        if(match.params &&  match.params.tokenEnabled && this.state.pageEditNotLogin){
            apiUrlRequest = API_EDIT_REQUEST_BY_TOKEN.replace("{id}",  match.params.tokenEnabled);
            if(this.state.pageEditNotLogin && !valueForm.hiddenPassword && !valueForm.userInfoId) {
                errors = checkSubmitSendDataERROR(valueForm.password, STRING, errors, "password", "information.formTab1.errors.passwordEmpty", t);
                if (valueForm.password !== valueForm.confirmPassword) {
                    errors.confirmPassword = t("information.formTab1.errors.confirmPasswordMatch");
                }
            }
        }
        else if(match.params &&  match.params.id && ! this.state.pageEditNotLogin){
            errors = checkSubmitSendDataERROR(valueForm.sentByAgency, STRING, errors, "sentByAgency", "information.formTab1.errors.sentByAgencyEmpty",t);
            apiUrlRequest = API_EDIT_REQUEST.replace("{id}",   match.params.id );
        }

        if(Object.keys(errors) < 1 && apiUrlRequest){
            this.setState({isLoading: true})
            let valueFormNew = {...valueForm};
            if(valueForm.otherBillingAddress){
                valueFormNew={
                    ...valueFormNew,
                    billingAddressCity: valueForm.addressCity,
                    billingAddressPostalCode: valueForm.addressPostalCode,
                    billingAddressCountry: valueForm.addressCountry,
                    billingAddressDescription: valueForm.addressDescription
                }
            }

            const formData = new FormData();
            for (let [key, value] of Object.entries(valueFormNew)) {
                switch (key){
                    case 'password':
                    case 'confirmPassword':
                        if(this.state.pageEditNotLogin && !valueForm.hiddenPassword && !valueForm.userInfoId){
                            formData.append("request[user]["+key+"]", value ?? "");
                        }
                        break;
                    case 'leaseType':
                        formData.append("request["+key+"]", value && value.value?value.value: "");
                        break;
                    case 'startingDateOfStay':
                        formData.append("request["+key+"]", value ?moment(value).format("YYYY-MM-DD"): "");
                        break;
                    case 'sentByAgency':
                    case 'builtForOver2Years':
                        formData.append("request["+key+"]", value);
                        break;
                    case 'otherBillingAddress':
                        formData.append("request["+key+"]", value ?TRUE_NUMBER:FALSE_NUMBER);
                        break;
                    default:
                        formData.append("request["+key+"]", value ?? "");
                        break;
                }

            }

            axios.post(apiUrlRequest, formData)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data && response.data.auth && response.data.auth.token){
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.auth.token;
                        }

                        if(tabInformation === TAB_DATE) tabNext = TAB_PAID;
                        let dataItem = response.data.item;
                        const statusCode = dataItem && dataItem.status && dataItem.status.statusType ? dataItem.status.statusType.code : null;

                        this.setState({
                            isLoading: false,
                            successOpen: true,
                            tabInformation: tabInformation,
                            submitInfo: true,
                            changeTab1: false,
                            tabNext: tabNext,
                            checkRedirectToDetail: statusCode === GO_DOC,
                            valueForm: {...valueFormNew},
                        })
                    } else {
                        throw new Error();
                    }
                })
                .catch(error => {
                    this.setState({
                        isLoading: false,
                        errorOpen: true,
                        messageError: t("form.error.occurred")
                    })

                });
        } else {
            this.setState({
                errors: errors,
                errorOpen: true,
                messageError: t("form.error.filling")
            })
        }
    }
    handleChange = (event, field )=>{
        let {valueForm, errors} = this.state;
        let value = event;
        let newState = {};
        switch (field) {
            case "acceptCertificate":
                value = event.target.checked;
                break;
            case "otherBillingAddress":
                value = !event.target.checked;
                if(value) {
                    delete (errors["billingAddressCountry"]);
                    delete (errors["billingAddressCity"]);
                    delete (errors["billingAddressPostalCode"]);
                    delete (errors["billingAddressDescription"]);
                }
                break;
            case "registeredObject":
                value = event.target.checked === false ? OBJECT_COMPANY : OBJECT_PERSONAL;
                if(value === OBJECT_PERSONAL) {
                    delete (errors["companyName"]);
                    delete (errors["companyTaxCode"]);
                    valueForm["companyName"] = "";
                    valueForm["companyTaxCode"] = "";
                }
                break;
            case "addressCountry":
            case "billingAddressCountry":
                value= event ?event.code:"";
                break;
            case "leaseType":
            case "startingDateOfStay":
                break;
            case "isThereAnElevator":
                value = event.target.value;
                break;
            case "sentByAgency":
                value = event.target.value;
                if(value !== TRUE_NUMBER){
                    valueForm["realEstateCenterCode"] = "";
                    if (errors.hasOwnProperty("realEstateCenterCode")) {
                        delete (errors["realEstateCenterCode"]);
                    }
                }
                break;
            case "checkOwner":
                value = event.target.value;
                if (event.target.value === OWNER){
                    valueForm.leaseType = null;
                    if (errors.hasOwnProperty("leaseType")) {
                        delete (errors["leaseType"]);
                    }
                }
                break;
            case "builtForOver2Years":
                value = parseInt(event.target.value);
                if (!isNaN(value)){
                    valueForm.builtForOver2Years = value;
                    if (errors.hasOwnProperty("builtForOver2Years")) {
                        delete (errors["builtForOver2Years"]);
                    }
                }
                break;
            default:
                value = event.target.value;
                break;
        }
        if (errors.hasOwnProperty(field)) {
            delete (errors[field]);
        }
        valueForm[field] = value;
        newState = {
            ...newState, valueForm: valueForm, errors:errors, changeTab1: true
        }
        this.setState(newState)

    }

    onChangeTab = (value) => {
        const {changeTab1, changeTab2,initialRequest } = this.state;
        const statusCode = initialRequest && initialRequest.status && initialRequest.status.statusType ? initialRequest.status.statusType.code : null;
        if (value === TAB_PAID) {
            // checkChangeTabToPayment
            if (this.checkChangeToToPayment() && this.checkChangeToDateTab()) {
                if(changeTab1) {
                    this.handleSubmitInformation(value)
                    // this.setState({tabNext: TAB_DATE})
                }
                else if(changeTab2) {
                    this.setState({
                        callSaveDate: true,
                        tabNext: TAB_PAID
                    })
                }
                else this.setState({
                        tabInformation: value
                    })
            }
        } else if(value === TAB_DATE){
            if(this.checkChangeToDateTab()){
                if(changeTab1) {
                    this.handleSubmitInformation(value)
                    this.setState({tabNext: TAB_PAID})
                }
                else this.setState({
                    tabInformation: value,
                    tabNext: TAB_PAID
                })
            }
        }
        else {
            if(value) {
                // if(changeTab2) {
                //     this.setState({
                //         callSaveDate: true,
                //         tabNext: value
                //     })
                // }
                // else
                    this.setState({
                    tabInformation: value,
                    tabNext: statusCode === GO_DOC ? TAB_INFORMATION : TAB_DATE
                })
            }
        }
    }
    callbackDate = (isLoading, informationTab, disableTab3) => {
        const { t } = this.props
        let newState = {}
        if(isLoading){
            newState.isLoading = isLoading
        }
        if(informationTab) newState.tabInformation = informationTab
        if(disableTab3 || disableTab3 === false) newState.disableTab3 = disableTab3
        if(Object.keys(newState).length){
            this.setState(newState)
        }
    }

    // checkChangeTabToPayment
    checkChangeToToPayment() {
        const {
            registrationStart,
            hasPayment,
            paymentRequest,
            changeTab2,
            tabInformation,
            disableTab3
        } = this.state;
        if (!hasPayment || disableTab3 || registrationStart === null || (registrationStart && !paymentRequest && compareDate(moment(registrationStart), moment(), 'minutes')) || (changeTab2 && tabInformation === TAB_INFORMATION)) {
            return false;
        } else {
            // check Registration Time
            return true;
        }
    }
    // checkChangeTabToDate
    checkChangeToDateTab() {
        const {
            initialValueForm,
            submitInfo,
            paymentRequest
        } = this.state;
        if(submitInfo || paymentRequest || (initialValueForm.checkOwner && initialValueForm.checkOwner.length > 0)){
            return true
        } else return false
    }


    componentDidMount() {
        const {dataUser, match, location}= this.props;
        const search = this.props.location.search;
        let urlParams = qs.parse(search, { ignoreQueryPrefix: true });
        const pathPage = location && location.pathname ? location.pathname : "";
        let urlGetShow="";
        let checkIsPageEditNotLogin = true;
        const requestId = match && match.params && match.params.id ? match.params.id : null;
        let checkIdExist = !(requestId == null || requestId == "undefined");
        if(pathPage === links.INFORMATION_PAGE.replace(":id", requestId) ||
            pathPage === links.REQUEST_INFORMATION_DETAIL.replace(":id", requestId)
        ){
            urlGetShow=API_GET_SHOW_REQUEST.replace(":id", match.params.id)
            checkIsPageEditNotLogin = false
        }else if(pathPage === links.EDIT_REQUEST_NOT_LOGIN.replace(":tokenEnabled", match && match.params ?match.params.tokenEnabled:"")){
            urlGetShow=API_GET_REQUEST_BY_TOKEN.replace("{id}", match.params.tokenEnabled);
            checkIsPageEditNotLogin = true
            checkIdExist = true;
        }
        if(urlGetShow && checkIdExist) {
            this.setState({isLoading: true})
            axios.get(urlGetShow)
                .then(response =>{
                    if(response.status === 200) {
                        const userInfo = response.data.item;
                        const initialRequest = response.data.initialRequest;
                        if (urlParams.tab === "payment") {
                            this.updateDataRequest(userInfo, initialRequest, TAB_PAID,checkIsPageEditNotLogin);
                        } else {
                            this.updateDataRequest(userInfo, initialRequest, false,checkIsPageEditNotLogin);
                        }
                    }else{
                        throw new Error();
                    }
            })
                .catch(error=>{
                    if( error.response && error.response.status === 404) {
                        window.location.href = links.PAGE404
                    }else if(error.response && error.response.status === 400 && error.response.data.response_code === "REQUEST_NOT_EXIST"){
                        this.setState({
                            isLoading: false,
                            redirectToUrl: LOGIN
                        }, function (){
                            this.props.setErrorAlert(true, this.props.t("information.formTab1.errors.requestNotExist"))
                        })
                    }else {
                        this.setState({
                            isLoading: false
                        })
                    }
                })
        }

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {changeTab2, tabInformation, callSaveDate} = this.state
        let newState = {}
        if(!_.isEqual(this.props.dataTab2, this.state.dataTab2Initial) && !changeTab2 && tabInformation === TAB_DATE){
            newState.changeTab2 = true
        }
        if(_.isEqual(this.props.dataTab2, this.state.dataTab2Initial) && changeTab2 && tabInformation === TAB_DATE){
            newState.changeTab2 = false
        }
        if(callSaveDate){
            newState.callSaveDate = false
        }
        if(Object.keys(newState).length){
            this.setState(newState)
        }
    }

    handleCloseNotice = () => {
        this.setState({
            successOpen: false,
            errorOpen: false,
            messageSuccess: null,
            messageError: null
        })
    }

    updateDataRequest(userInfo, initialRequest,tabRedirect, checkIsPageEditNotLogin) {
        let {valueForm} = this.state;
        const {dataUser} = this.props;
        if (dataUser && dataUser.data && userInfo && userInfo.person){
            if(dataUser.data.personId === userInfo.person.id) {
                valueForm = {
                    ...valueForm,
                    hiddenPassword: true
                }
            }
        }
        const checkModified = !!(userInfo && userInfo.checkModified);
        valueForm = {
            ...valueForm,
            requestId: userInfo.id,
            firstName: userInfo.firstName ?? (initialRequest && initialRequest.firstName  ? initialRequest.firstName : ""),
            lastName: userInfo.lastName ?? (initialRequest && initialRequest.lastName  ? initialRequest.lastName : ""),
            email: userInfo.email ?? (initialRequest && initialRequest.email  ? initialRequest.email : ""),
            phoneNumber: userInfo.phoneNumber ?? (initialRequest && initialRequest.phoneNumber  ? initialRequest.phoneNumber : ""),
            addressCity: userInfo.addressCity ?? (initialRequest && initialRequest.addressCity  ? initialRequest.addressCity : ""),
            addressPostalCode: userInfo.addressPostalCode ?? (initialRequest && initialRequest.addressPostalCode  ? initialRequest.addressPostalCode : ""),
            addressCountry: userInfo.addressCountry ?? (initialRequest && initialRequest.addressCountry  ? initialRequest.addressCountry : COUNTRY_DEFAULT),
            addressDescription: userInfo.addressDescription ?? (initialRequest && initialRequest.addressDescription  ? initialRequest.addressDescription : ""),
            constructionYear: userInfo.constructionYear ?? (initialRequest && initialRequest.constructionYear  ? initialRequest.constructionYear : ""),
            sentByAgency: userInfo.sentByAgency !== null && userInfo.sentByAgency!== undefined ? userInfo.sentByAgency ? TRUE_NUMBER : FALSE_NUMBER: initialRequest && initialRequest.sentByAgency !== null && initialRequest.sentByAgency!== undefined ? initialRequest.sentByAgency ? TRUE_NUMBER : FALSE_NUMBER : "",
            builtForOver2Years: userInfo.builtForOver2Years ?? (initialRequest && initialRequest.builtForOver2Years  ? initialRequest.builtForOver2Years : ""),
            checkOwner: userInfo.checkOwner ?? (initialRequest && initialRequest.checkOwner  ? initialRequest.checkOwner : ""),
            additionalInfo: userInfo.additionalInfo ?? (initialRequest && initialRequest.additionalInfo  ? initialRequest.additionalInfo : ""),
            startingDateOfStay: userInfo.startingDateOfStay && moment(userInfo.startingDateOfStay).isValid()? moment(userInfo.startingDateOfStay): null,
            leaseType: userInfo.leaseType ?{value: userInfo.leaseType.id, label:userInfo.leaseType.name}:initialRequest && initialRequest.lastName  ? initialRequest.lastName : null,
            userInfoId:  userInfo.userInfoId,
            realEstateCenterCode: userInfo.realEstateCenterCode??"",
            payment: userInfo.payment,
            registeredObject: userInfo.registeredObject ?? (initialRequest && initialRequest.registeredObject  ? initialRequest.registeredObject : ""),
            companyName: userInfo.companyName ?? (initialRequest && initialRequest.companyName  ? initialRequest.companyName : ""),
            floorNumber: userInfo.floorNumber ?? (initialRequest && initialRequest.floorNumber  ? initialRequest.floorNumber : ""),
            isThereAnElevator: userInfo.isThereAnElevator !== null && userInfo.isThereAnElevator!== undefined ? (userInfo.isThereAnElevator == TRUE_NUMBER || userInfo.isThereAnElevator===true ? TRUE_NUMBER : FALSE_NUMBER): initialRequest && initialRequest.isThereAnElevator !== null && initialRequest.isThereAnElevator!== undefined ? initialRequest.isThereAnElevator ? TRUE_NUMBER : FALSE_NUMBER : "",
            doorPassword: userInfo.doorPassword ?? (initialRequest && initialRequest.doorPassword  ? initialRequest.doorPassword : ""),
            otherBillingAddress: userInfo.otherBillingAddress ?? (initialRequest && initialRequest.otherBillingAddress  ? initialRequest.otherBillingAddress : ""),
            billingAddressCity: userInfo.billingAddressCity ?? (initialRequest && initialRequest.billingAddressCity  ? initialRequest.billingAddressCity : ""),
            billingAddressPostalCode: userInfo.billingAddressPostalCode ?? (initialRequest && initialRequest.billingAddressPostalCode  ? initialRequest.billingAddressPostalCode : ""),
            billingAddressCountry: userInfo.billingAddressCountry ?? (initialRequest && initialRequest.billingAddressCountry  ? initialRequest.billingAddressCountry : ""),
            billingAddressDescription: userInfo.billingAddressDescription ?? (initialRequest && initialRequest.billingAddressDescription  ? initialRequest.billingAddressDescription : "")
        }

        let dateRegis= {
            dateSelected: userInfo.registrationStart ? new Date(userInfo.registrationStart) : null,
            timeSelected: userInfo.registrationTimeStart ? convertTimezoneHour(userInfo.registrationTimeStart, userInfo.registrationStart) : null,
            callTimeFree: userInfo.registrationTimeStart ? [userInfo.registrationTimeStart] : null,
            callTime: userInfo.registrationTimeEnd && userInfo.registrationTimeStart ? userInfo.registrationTimeEnd - userInfo.registrationTimeStart + 1 : null,
            timeRange: userInfo.registrationTimeEnd && userInfo.registrationTimeStart ? moment().hour(convertTimezoneHour(userInfo.registrationTimeStart, userInfo.registrationStart)/60).minute(convertTimezoneHour(userInfo.registrationTimeStart, userInfo.registrationStart)%60).second(0).format('HH:mm') + "-" + moment().hour(convertTimezoneHour(userInfo.registrationTimeEnd + 1, userInfo.registrationStart)/60).minute(convertTimezoneHour(userInfo.registrationTimeEnd + 1, userInfo.registrationStart)%60).second(0).format('HH:mm') : null
        }
        this.setState({
            valueForm: valueForm,
            isLoading: false,
            pageEditNotLogin: checkIsPageEditNotLogin,
            paymentRequest: !!(userInfo.payment && userInfo.payment.paid && !checkRequestIsGoFreeAndRegistrationStartNull(userInfo)),
            registrationStart: userInfo.registrationStart,
            hasPayment: !!userInfo.payment,
            paymentPrice: userInfo.payment && userInfo.payment.price !== null ? userInfo.payment.price : PRICE_DEFAULT,
            paymentCurrency: userInfo.payment && userInfo.payment.currency !== null ? userInfo.payment.currency : CURRENCY_DEFAULT,
            id: userInfo.id ?? null,
            requestProblem: userInfo.problem,
            initialValueForm: valueForm,
            dataTab2Initial: dateRegis,
            initialRequest: userInfo
        },()=> {
            if (!checkModified) {

            }
            this.props.setDataFormTab2(dateRegis);
            if (tabRedirect) {
                this.setState({
                    tabInformation: tabRedirect
                })
            }
        })
    }

    render() {
        const {
            classes,t,location, width, match,
        } = this.props;
        const { tabInformation, valueForm,errors, isLoading, redirectToUrl, paymentRequest,paymentPrice, paymentCurrency, requestProblem, checkRedirectToDetail, initialRequest } = this.state;

        if(redirectToUrl){
            return <Redirect to={redirectToUrl}/>;
        }
        const pathPage = location && location.pathname ? location.pathname : "";
        const isPageDetail = pathPage.replace(/[0-9]/g, '') === links.REQUEST_INFORMATION_DETAIL.replace(":id", '')
        let tabOptionsNew =checkRequestIsGoFree(valueForm)? tabOptions.filter(item => item.value !== TAB_PAID) :  tabOptions;
        let valueSelectTab = ""
        if ([XS, SM].includes(width)) {
            tabOptionsNew = tabOptionsNew.filter(item => ![TAB_DATE, TAB_PAID].includes(item.value) || (item.value === TAB_PAID && this.checkChangeToToPayment() && this.checkChangeToDateTab()) || (item.value === TAB_DATE && this.checkChangeToDateTab()))
            valueSelectTab = tabInformation ? tabOptionsNew.filter(item => item.value === tabInformation).length ? tabOptions.filter(item => item.value === tabInformation)[0] : "" : ""
        }
        const statusCode = initialRequest && initialRequest.status && initialRequest.status.statusType ? initialRequest.status.statusType.code : null;
        if (statusCode === GO_DOC) {
            tabOptionsNew = tabOptions.filter(item => item.value === TAB_INFORMATION);
        }
        let goFree = checkRequestIsGoFree(valueForm);
        if (checkRedirectToDetail) {
            return <Redirect to={links.REQUEST_INFORMATION_DETAIL.replace(":id", match && match.params ? match.params.id:"")}/>;
        }
        return (
            <div className={classes.informationPage}>
                {isLoading && <LoadingAction />}
                <AlertCustom
                    // successOpen={this.state.successOpen}
                    errorOpen={this.state.errorOpen}
                    handleCloseNotice={this.handleCloseNotice}
                    messageSuccess={this.state.messageSuccess}
                    messageError={this.state.messageError}
                />
                <Grid container>
                    <Grid container item xs={12}>
                        <Grid item xs={12} className={"returnRequestWrapper"}>
                        <div className={"returnRequest"}>
                            <NavLink to={links.REQUEST} className={"returnHistory"}>
                                <CustomSvg
                                    svgProps={{
                                        src: returnIcon,
                                    }}
                                    lable={t("information.header.returnHistory")}
                                />
                                {/*<img alt={t("information.header.returnHistory")} src={returnIcon}/>*/}
                                <span>{t("information.header.returnHistory")}</span>
                            </NavLink>

                        </div>
                        </Grid>
                        {width === XS ?
                            <Grid container item xs={12}>
                                <Autocomplete
                                    className="selectTabLabel"
                                    options={tabOptionsNew}
                                    classes={{
                                        option: classes.option,
                                    }}
                                    value={valueSelectTab}
                                    autoHighlight
                                    disableClearable
                                    getOptionLabel={(option) => option.numberLabel + " " + t(option.label)}
                                    renderOption={(option) => {
                                            return <React.Fragment>
                                                <div
                                                    className={`${classes.tabStatus} tabStatus${option.numberLabel.replace(".", "")} tabValueSelect`}>
                                                    <div className={"content"}>
                                                        <div className={"iconWrapper"}>
                                                            {option.icon}
                                                        </div>
                                                        <div className={"contentRequest"}>
                                                            <div className={"number"}>
                                                                {option.numberLabel}
                                                            </div>
                                                            <div className={"statusType"}>
                                                                {t(option.label)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                    }}
                                        onChange={(event, newValue) =>this.onChangeTab(newValue.value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password'
                                                }}
                                            />
                                        )}
                                        />
                                        </Grid>
                                        :
                                        <Grid container item sm={12} md={12} lg={12} className={"tabsInformation"}>
                                            {tabOptionsNew.map((item, key) => {
                                                let countTab = tabOptionsNew.length;
                                                let widthGrid = countTab <5 ?  Math.floor(12/countTab) : 4;
                                                return <Grid item sm={widthGrid} onClick={() => this.onChangeTab(item.value)}
                                                             className={`${classes.tabStatusWrapper} ${(tabInformation === item.value) ? "tabActive" : ""}`}>
                                                    <div
                                                        className={`${classes.tabStatus} ${!isPageDetail ? "tabStatus" : ""} tabStatus${key + 1} ${item.value === TAB_PAID? (this.checkChangeToToPayment() && this.checkChangeToDateTab() ? "": "disabledTab"):(item.value === TAB_DATE ?this.checkChangeToDateTab() ?"": "disabledTab":"")}`}>
                                                        <div className={"content"}>
                                                            <div className={"iconWrapper"}>
                                                                {item.icon}
                                                            </div>
                                                            <div className={"contentRequest"}>
                                                                <div className={"number"}>
                                                                    {item.numberLabel}
                                                                </div>
                                                                <div className={"statusType"}>
                                                                    {t(item.label)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            })}
                                        </Grid>}
                                <Grid container item lg={12} className={"contentTab"}>
                                    {tabInformation && tabInformation === TAB_INFORMATION &&
                                    <Information token={this.props.match.params.tokenEnabled} valueForm={valueForm}
                                                 paymentRequest={paymentRequest} handleChange={this.handleChange}
                                                 pageEditNotLogin={this.state.pageEditNotLogin} errors={errors}
                                                 handleSubmitInformation={this.handleSubmitInformation}
                                                 isLoading={isLoading}
                                                 initialRequest={initialRequest}
                                    />
                                    }
                                    {tabInformation && tabInformation === TAB_DATE && !isPageDetail &&
                                    <DateClient token={this.props.match.params.tokenEnabled}
                                                callbackDate={this.callbackDate}
                                                updateDataRequest={this.updateDataRequest}
                                                paymentRequest={paymentRequest}
                                                id={this.state.id}
                                                callSaveDate={this.state.callSaveDate}
                                                tabNext={this.state.tabNext}
                                    />
                                    }
                                    {tabInformation && tabInformation === TAB_PAID && !isPageDetail &&
                                    <Payment token={this.props.match.params.tokenEnabled} requestId={this.state.id}
                                             paymentRequest={paymentRequest} paymentPrice={paymentPrice}
                                             paymentCurrency={paymentCurrency} requestProblem={requestProblem}
                                             updateDataRequest={this.updateDataRequest}/>
                                    }
                                </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

InformationPage.defaultProps = {}

InformationPage.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        dataUser : state.authReducer.dataUser,
        dataTab2: state.applicationReducer.dataTab2
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setErrorAlert: (successOpenAlert, message) => dispatch(application.setErrorAlert(successOpenAlert, message)),
        setDataFormTab2: (dataTab2, field) => dispatch(application.setDataFormTab2(dataTab2, field))
    }
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter,
    withWidth()
)(InformationPage);
