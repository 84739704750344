import React, {useState} from "react";
import {connect, useSelector} from "react-redux";
import {compose} from "redux";
import {makeStyles} from "@material-ui/core/styles";
import {Popover} from "@material-ui/core";
import axios from "axios";
import {API_UPDATE_LAST_VIEW_CHAT} from "../../constants/apiSuffix";
import * as application from "../../_actions/application";
import {withTranslation} from "react-i18next";

const useStyles = makeStyles((theme)=>({
    chatPopoverContainer: {
        '& > div': {
            overflow: 'initial',
        }
    },
    chatPopoverWrapper: {
        backgroundColor: '#f1f1f2',
        padding: '5px 10px',
        borderRadius: 5,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 40,
        '&:after': {
            content: `''`,
            position: 'absolute',
            left: 'calc(50% - 10px)',
            top: '100%',
            width: 0,
            height: 0,
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderTop: '15px solid #f1f1f2',
            clear: 'both',
        },
        '& .btnSwitchUnRead': {
            cursor: 'pointer'
        },
    }
}));

export const NOT_READ_LAST_MESSAGE_CHAT = "NOT_READ_LAST_MESSAGE_CHAT"
const PopoverReadOrUnReadMessageChat = ({
                                            anchorEl,
                                            onClose,
                                            submitReadOrUnReadMessage,
                                            unReadMessageId,
                                            requestId,
                                            isStatusUnRead,
                                            ...props
                                        }) => {
    const classes = useStyles();
    const unReadMessage = () => {
        if (requestId) {
            const formData = new FormData();
            if(parseInt(unReadMessageId)) {
                formData.append('message', unReadMessageId)
            }
            if(unReadMessageId === NOT_READ_LAST_MESSAGE_CHAT){
                formData.append('notReadLastMess', "1")
            }
            axios.post(API_UPDATE_LAST_VIEW_CHAT.replace('{id}', requestId ), formData)
                .then(res => {
                    // console.log(res.data)
                    if (res.status === 200 && typeof submitReadOrUnReadMessage === 'function') {
                        submitReadOrUnReadMessage()
                    }
                })
                .catch(err => {
                    // console.log(err)
                })

        }
    }
    return anchorEl ? <Popover
        id="mouse-over-popover"
        open={true}
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        onClose={() => {
            onClose(null)
        }}
        disableRestoreFocus
        className={classes.chatPopoverContainer}
    >
        <div className={classes.chatPopoverWrapper}>

            <div className="btnSwitchUnRead" onClick={() => {
                unReadMessage()
            }}>
                {props.t(!isStatusUnRead ? 'chat.chatBox.switchUnRead': 'chat.chatBox.switchReadLastMess')}
            </div>
        </div>
    </Popover> : ""
}

const mapStateToProps = (state) => {
    return {
        countChatNotSeen:state.applicationReducer.countChatNotSeen,

    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setCountChatNotSeen: (value) => dispatch(application.setCountChatNotSeen(value) )
    }
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation()) (PopoverReadOrUnReadMessageChat);