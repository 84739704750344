import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import ButtonChat from "./ButtonChat";
import ButtonDocument from "./ButtonDocument";
import {USER_TYPE_CLIENT, XS} from "../../../../constants/constants";
import ButtonAddDocument from "../popover/ButtonAddDocument";
import moment from "moment";
import axios from "axios";
import {API_CREATE_REQUEST_UPLOAD_FILE_TOKEN, API_SEND_SMS_UPLOAD_FILE_REQUEST} from "../../../../constants/apiSuffix";
import {withRouter} from "react-router-dom";
import GeneralModal from "../../../../theme/Modal/GeneralModal";
import CloseIcon from "@material-ui/icons/Close";
import {REQUEST_UPLOAD_FILE_TOKEN} from "../../../../constants/links";
import LoadingAction from "../../../../theme/LoadingAction";
import DialogForm from "../../../../theme/CustomElements/DialogForm";

const styles = theme => ({
    titleWrapper: {
        textAlign: 'center',
        paddingTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    fullName: {
        color: '#001529',
        fontWeight: 700,
        fontSize: '1.5rem',
        textTransform: 'capitalize',

        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        whiteSpace: "pre-line",
        overflow: 'hidden',
        [theme.breakpoints.down(XS)]: {
            fontSize: '1rem',
            maxWidth: 120,
        }
    },
    owner: {
        background: '#BFBFBF',
        color: '#F9F9F9',
        width: '125px',
        display: 'inline-block',
        borderRadius: '15px',
        marginLeft: '10px',
        padding: '5px 0',
        [theme.breakpoints.down(XS)]: {
            fontSize: '0.8rem',
            width: 'unset',
            padding: '5px 10px'
        }
    },
    listAction: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 20,
        [theme.breakpoints.down(XS)]: {
            flexDirection: 'column',
            '& .titleIcon, .addCamera': {
                display: 'none!important',
            },
            '& .addDocWrapper1': {
                border: 'none!important',
                margin: 0,
            },
            '& button': {
                margin: 0,
            },
            '& .btnChatClient': {
                border: 'none!important',
                // '& .titleIcon': {
                //     display: 'none!important',
                // }
            },
            '& .iconWrapper': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '5px 0',
            }
        },
        '& .btnActionWrapper': {
            margin: '0 10px',
            cursor: 'pointer',
            '& .titleIcon': {
                // display: 'none!important',
            }
        },
        '& .btnChatClient': {
            borderLeft: '1px solid rgba(113, 109, 135, 0.25)',
            width: 120,
            color: '#716d87',
            fontSize: '0.7rem',
            padding: 5,
            '& .btnActionWrapper': {
                '&:hover': {
                    background: '#dbedff7d',
                    '& $actionChatWrapper': {
                        display: 'block'
                    },
                    '& div': {
                        color: '#1c1cff',
                    },
                },
            },

            '& .titleIcon': {
                display: 'block',
            }
        }
    },
    buttonTokenWrapper: {
        padding: '8px 10px',
        color: '#3939b7',
        border: '1px solid #3939b7',
        borderRadius: 10,
        margin: '5px 20px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0, 0.02)'
        }
    },
    dataUploadFileLinkWrapper: {
        color: "#3939b7",
        fontSize: 14,
        padding: '0 20px 30px 20px',
    },
    buttonTokenFooter : {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    filesModal: {
        backgroundColor: '#e6edfc',
    },
    copyAll: {
        margin: '15px 0 10px 0',
        backgroundColor: '#fff',

    },
    linkToken:{
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 25,
        '& .dataCopyLinkToken': {
            backgroundColor: '#fff',
            padding: '8px 16px',
            width: 'calc(100% - 70px)',
            marginLeft: 8,
            borderRadius: 7,
            cursor: 'pointer',
            whiteSpace: 'pre-wrap', overflowWrap: 'break-word',
            minHeight: 32,
            '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.05)'
            }
        }
    },
    popoverAlertAndButton: {
        position: 'relative',
        '& .arrowLeft': {
            position: 'absolute',
            zIndex: 1,
            top: -6,
            left: 'calc(50% - 5px)',
            width: 0,
            height: 0,
            borderLeft: '6px solid transparent',
            borderRight: '6px solid transparent',
            borderBottom: '7px solid #4ea5ff'
        },
        '& .popoverAlertText': {
            width: 'fit-content',
            padding: '4px 10px',
            borderRadius: 7,
            backgroundColor: '#4ea5ff',
            color: '#fff',
            fontSize: 12,
            visibility: 'visible',
            position: 'absolute',
            zIndex: 1,
            top: -21,
            left: '45%',
            minWidth: 85,
            textAlign: 'center'
        }
    }
})

const REMOVE_TOKEN = "REMOVE_TOKEN"
const SEND_TOKEN = "SEND_TOKEN"

class ClientTitleModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            openModal: false,
            token: "",
            dialogForm: false,
            copied: false
        }
        this.copyUploadTokenRef = React.createRef()
        document.addEventListener('copy', this.newCopyUploadUrl)
    }

    componentDidMount() {
        const {dataClient} = this.props

        this.setState({
            token: (dataClient?.uploadFileToken) ?? ""
        })
    }

    changeOpenModal = (event) => {
        this.setState({
            openModal: !!event
        })
    }

    changeRequestUploadFileToken = (removeToken) => {
        const {dataClient} = this.props

        const formData = new FormData();
        this.setState({
            isLoading: true
        }, function () {
            this.setDialogForm(null);
        })

        formData.append('uploadFileToken', removeToken ? 'remove' : 'new');
        axios.post(API_CREATE_REQUEST_UPLOAD_FILE_TOKEN.replace('{id}', dataClient.id), formData)
            .then(res => {
                if (res.status === 200 && res.data) {
                    this.props.setUploadFileToken(res.data.item)
                    this.setState({
                        isLoading: false,
                        token: res.data.item
                    })
                } else {
                    throw new Error();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
            })
    }

    sendSms = () =>{
        const {dataClient} = this.props
        this.setState({
            isLoading: true
        }, function () {
            this.setDialogForm(null);
        })

        axios.post(API_SEND_SMS_UPLOAD_FILE_REQUEST.replace('{id}', dataClient.id))
            .then(res => {
                if (res.status === 200 && res.data) {
                    this.setState({
                        isLoading: false,
                    })
                } else {
                    throw new Error();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
            })
    }

    usePathname = () => {
        return `${window.location.protocol}//${window.location.host}`
    }

    newCopyUploadUrl = (e) => {
        if(this.state.openModal) {
            const {token} = this.state;
            if (e) {
                if (e.target && e.target.className === 'dataCopyLinkToken') {
                    e.clipboardData.setData('text/plain', `${this.usePathname()}${REQUEST_UPLOAD_FILE_TOKEN.replace(":token", token)}`)
                } else {
                    const innerText =  e.target.innerText
                    e.clipboardData.setData('text/plain', innerText? innerText.replace(`${this.props.t("request.uploadFileToken.link")} :`, ""): "")
                }
                e.preventDefault();
            }
            this.setState({
                copied: true
            }, function () {
                setTimeout(() => {
                    this.setState({
                        copied: false
                    })
                }, 1000)
            })
        }
    }

    copyFieldUploadToken = async (e, text) => {
        if(this.state.openModal) {
            if (e && e.hasOwnProperty("preventDefault")) e.preventDefault();
            if (typeof text === "string") {
                await navigator.clipboard.writeText(text);
                this.newCopyUploadUrl();
            }
            else if (document.selection) {
                let range = document.body.createTextRange();
                range.moveToElementText(e.target || e);
                range.select().createTextRange();
                document.execCommand("copy");
            } else if (window.getSelection) {
                let range = document.createRange();
                range.selectNode(e.target || e);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
                document.execCommand("copy");
                window.getSelection().removeAllRanges();
            }
        }
    }

    setDialogForm = (value) => {
        this.setState({
            dialogForm: value
        })
    }

    render() {
        const {dataClient, classes, t, dataUser} = this.props
        const groupCode = dataUser?.data?.group;
        const {openModal, isLoading, token, dialogForm, copied} = this.state
        // const day = moment().subtract(3, 'days');
        return (
            <div className={classes.titleWrapper}>
                <span className={classes.fullName}>{dataClient && dataClient.fullName}</span>
                {dataClient.checkOwner && dataClient.checkOwner === "owner" &&
                <span className={classes.owner}>{t('information.formTab1.owner')}</span>}
                {dataClient && groupCode !== USER_TYPE_CLIENT ? <div className={classes.listAction}>
                    <ButtonChat
                        dataClient={dataClient}
                    />
                    <ButtonDocument
                        dataClient={dataClient}
                    />

                    {!dataClient?.person && <div className={classes.buttonTokenWrapper} onClick={() => this.changeOpenModal(true)}>
                        {t("request.uploadFileToken.requestToken")}
                    </div>}
                </div> : <div className={classes.listAction}>
                    <div className="btnChatClient">
                        <ButtonChat
                            dataClient={dataClient}
                            isClient={true}
                        />
                    </div>
                    <ButtonAddDocument
                        requestId={dataClient.id}
                    />
                </div>}
                {!!openModal && <GeneralModal
                    className={classes.filesModal}
                    open={openModal}
                    handleClose={() => this.changeOpenModal(false)}
                    content={<div className={classes.dataUploadFileLinkWrapper}>
                        {isLoading ? <LoadingAction isLoadingBlock={true}/> :
                            <div>
                                {token ? <div className={classes.linkToken}>
                                    {t("request.uploadFileToken.link")} :
                                    <div className={"dataCopyLinkToken"} ref={this.copyUploadTokenRef}
                                         onClick={() => this.copyFieldUploadToken(this.copyUploadTokenRef.current, `${this.usePathname()}${REQUEST_UPLOAD_FILE_TOKEN.replace(":token", token)}`)}>
                                        {this.usePathname()}{REQUEST_UPLOAD_FILE_TOKEN.replace(":token", token)}
                                    </div>
                                </div> : <div  className={classes.linkToken}>
                                    {t("request.uploadFileToken.link")} : <div className={"dataCopyLinkToken"} />
                                </div>}
                                <div className={classes.popoverAlertAndButton}>
                                    {copied && <div className={"popoverAlertText"}>
                                        <div className="arrowLeft"/>
                                        {t("request.uploadFileToken.linkCopied")}
                                    </div>}
                                </div>
                                {/*{token && <Button className={classes.copyAll}*/}
                                {/*                  aria-describedby="popover-copied-link-upload-file-token"*/}
                                {/*                  variant="contained"*/}
                                {/*                  onClick={() => this.copyFieldUploadToken(this.copyUploadTokenRef.current)}>*/}
                                {/*    {t("request.uploadFileToken.copyLink")}*/}

                                {/*</Button>}*/}
                                <div className={classes.buttonTokenFooter}>
                                    {token && <div className={classes.buttonTokenWrapper}
                                                   onClick={() => this.setDialogForm(REMOVE_TOKEN)}>
                                        {t("request.uploadFileToken.removeToken")}
                                    </div>}
                                    <div className={classes.buttonTokenWrapper}
                                         onClick={() => this.changeRequestUploadFileToken(false)}>
                                        {t("request.uploadFileToken.createNewToken")}
                                    </div>
                                    {token && dataClient.createdAt && moment().diff(moment(dataClient.createdAt), 'days') > 3 &&
                                    <div className={classes.buttonTokenWrapper}
                                         onClick={() => this.setDialogForm(SEND_TOKEN)}>
                                        {t("request.uploadFileToken.sendToken")}
                                    </div>}
                                </div>
                            </div>
                        }
                    </div>}
                    saveButton={false}
                    cancelButton={false}
                    hideFooter={true}
                    iconCloseModal={
                        <div className="closeIcon">
                            <CloseIcon/>
                        </div>
                    }
                />}

                {!!dialogForm && <DialogForm
                    styleNumber={2}
                    dialogProps={{
                        open: !!dialogForm
                    }}
                    disagreeButtonProps={{
                        handleDisagree: () => {
                            this.setDialogForm(null);
                        }
                    }}
                    agreeButtonProps={{
                        handleAgree: () => {
                            if(dialogForm === REMOVE_TOKEN){
                                this.changeRequestUploadFileToken(true)
                            }else {
                                this.sendSms()
                            }
                        }
                    }}
                    // messageProps={{
                    //     content: <span className={classes.messageCloseForm}>
                    //         {t('request.closeNewFormConfirm')}
                    //     </span>
                    // }}
                />}

            </div>
        );
    }
}

export default compose(withTranslation(), withStyles(styles), withRouter)(ClientTitleModal);
