import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import {NavLink, withRouter} from "react-router-dom";
import {
    FORM_CREATE,
    FORM_EDIT,
    FORM_LIST,
} from "../../../../constants/constants";
import axios from "axios";
import {
    API_DELETE_GENERAL_DOCUMENT,
    API_EDIT_GENERAL_DOCUMENT,
    API_LIST_GENERAL_DOCUMENT,
} from "../../../../constants/apiSuffix";
import {Popover, Switch} from "@material-ui/core";
import LoadingAction from "../../../../theme/LoadingAction";
import List from "../../../../theme/List";
import GeneralDocumentForm from "./GeneralDocumentForm";
import ViewVersionDocument from "./ViewVersionDocument";
import DialogForm from "../../../../theme/CustomElements/DialogForm";
import AlertCustom from "../../../../theme/form/Alert/AlertCustom";

const styles = (theme) => ({
    header:{
        display: 'flex',
        alignItems: "center",
        justifyContent: "flex-end",
        padding: '1rem',
        '& a':{
            '&:hover': {
                background: "#0000c7",
            },
            textTransform: "uppercase",
            fontSize: '0.75rem',
            color: "white",
            background: "#0000FF",
            padding: '0.5rem 1rem',
            borderRadius: 5,
            fontWeight: 700,
            textDecoration: 'none'
        }
    },
    generalDocumentPageWrapper: {

    },
    btnActionWrapper:{
        display: 'flex'
    },
    btnEdit:{
        padding: '0.5rem 1rem',
        fontWeight: 600,
        '&.btnDelete':{
            background: "#ff0000",
            marginLeft: 10,
            cursor: 'pointer',
            '&:hover':{
                background: "#b80c0c",
            },
        },
        '&:hover':{
            background: "#0000c7",
        },
        textTransform: "uppercase",
        textDecoration: 'none',
        color: "white",
        background: "#0000FF",
    },
    popoverVersionDoc: {
        padding: 20,
    },
    viewVersions: {
        padding: 10,
        cursor: 'pointer',
        display: 'initial',
        '&:hover':{
            background: '#f2f0f0',
            fontWeight: 'bold',
        }
    },
    generalDocPage: {
        '& .generalDocList': {
            '& td': {
                padding: '0.5rem 1rem!important'
            }
        }
    }
})

const dataInitial = {

}
class GeneralDocumentPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formType: FORM_LIST,
            forceUpdate: false,
            isLoading: false,
            popoverVersionDocument: null,
            dataPopover: null,
            dialogOpen: null,
            successOpen: false,
            errorOpen: false
        };

    }

    componentDidMount() {
    }

    changeEnabled = (row) => {
        this.setState({
            isLoading: true,
        })
        const formData = new FormData();
        formData.append('changeEnabled', true);
        formData.append('enabled', !row.enabled ? 1 : 0);
        axios.post(API_EDIT_GENERAL_DOCUMENT.replace('{id}', row.id), formData)
            .then(res => {
                this.setState({
                    isLoading: false,
                    forceUpdate: true
                })
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                })
            })
    }

    componentDidUpdate() {
        if(this.state.forceUpdate){
            this.setState({
                forceUpdate: false
            })
        }
    }

    deleteItem = (id)=>{
        this.setState({
            dialogOpen: null,
            isLoading: true
        })
        axios.delete(API_DELETE_GENERAL_DOCUMENT.replace("{id}", id))
            .then(res=>{
                this.setState({
                    forceUpdate: true,
                    isLoading: false,
                    successOpen: true
                })
            })
            .catch(error=>{
                this.setState({
                    isLoading: false,
                    errorOpen: false
                })
            })
    }
    render() {
        const {
            isLoading,
            formType,
            forceUpdate,
            popoverVersionDocument,
            dataPopover
        } = this.state;

        const {location, t} = this.props;
        const { classes, ...other } = this.props;

        let arrayColumn = [
            {
                name: "name",
                "title": this.props.t('reactAdmin.generalDoc.name'),
            },
            {
                name: "description",
                "title": this.props.t('reactAdmin.generalDoc.description'),
            },
            {
                name: "enabled",
                "title": this.props.t('reactAdmin.generalDoc.enabled'),
                getCellValue: row => {
                    return (
                        <Switch
                            checked={!!row.enabled}
                            onChange={(e) => {
                                this.changeEnabled(row)
                            }}
                        />
                    )
                }
            },
            {
                "name": "versionDocuments",
                "title": this.props.t('reactAdmin.generalDoc.versionDocuments'),
                getCellValue: row => {
                    return (
                        <div
                            onClick={(event) => {
                                this.setState({
                                    popoverVersionDocument: event.currentTarget,
                                    dataPopover: row
                                })
                            }}
                            className={classes.viewVersions}
                        >
                            {Array.isArray(row.versionDocuments) ? row.versionDocuments.length : 0}
                            {" " + this.props.t("reactAdmin.generalDoc." + (row.versionDocuments.length == 1 ? "version" : "versions"))}
                        </div>
                    )
                }
            },
            {
                "name": "type",
                "title": this.props.t('reactAdmin.generalDoc.type'),
                getCellValue: row => {
                    return (
                        <div>
                            {row.type?.name??''}
                        </div>
                    )
                }
            },
            {
                name: "action",
                "title": this.props.t('reactAdmin.generalDoc.action'),
                getCellValue: row => {
                   return (
                       <div className={classes.btnActionWrapper}>
                           <NavLink to={location.pathname+"/"+row.id} className={classes.btnEdit}>
                               {t("reactAdmin.categories.btnEdit")}
                           </NavLink>
                           <div
                               onClick={()=>this.setState({
                                   dialogOpen: row
                               })}
                               className={classes.btnEdit+ " btnDelete"}>
                               {t("reactAdmin.parameter.list.btnDelete")}
                           </div>
                       </div>
                   )
                }
            }
        ];


        let filterColumnsEnabled = [{columnName: "action", filteringEnabled: false}];
        let sortingStateColumnExtensions = [{columnName: 'action', sortingEnabled: false}];
        let apiParams = {
            // code: formValue.code
        };
        let componentState = Object.assign({
            iconList: '',
            iconNew: '',
            classList: 'generalDocList',
            columns: arrayColumn,
            // apiParams:apiParams,
            filterColumnsEnabled:filterColumnsEnabled,
            sortingStateColumnExtensions:sortingStateColumnExtensions,
            defaultColumnWidths:[
                // {columnName: 'action', width: 40}
            ],
            titleList: '',
            /*api link*/
            apiNameList: API_LIST_GENERAL_DOCUMENT,
            // hiddenColumns: hiddenColumns
            apiParams: apiParams ,
            /*route link*/
        },this.props.componentState?this.props.componentState:{});
        return (
            <div>
                {isLoading && <LoadingAction/>}
                {formType === FORM_EDIT || formType === FORM_CREATE ?
                    <GeneralDocumentForm />
                    :
                    <div className={classes.generalDocPage}>
                        <div className={classes.header}><NavLink to={location.pathname + "/create"}
                                                                 className={"btnCreate"}>{this.props.t("reactAdmin.label.create")}</NavLink></div>
                        <List
                            {...other}
                            componentState={componentState}
                            forceUpdate={forceUpdate}
                            // className={classes.requestListWrapper}
                            // callbackWidth={this.callbackWidth}
                        />
                    </div>
                }
                {popoverVersionDocument && dataPopover && <Popover
                    open={true}
                    anchorEl={popoverVersionDocument}
                    onClose={() => {
                        this.setState({
                            popoverVersionDocument: null,
                            dataPopover: null
                        })
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                   <div
                       className={classes.popoverVersionDoc}
                   >
                       <ViewVersionDocument
                           documents={Array.isArray(dataPopover?.versionDocuments) ? dataPopover.versionDocuments : []}
                           changeVersionDocumentDone={() => {
                               this.setState({
                                   forceUpdate: true,
                                   popoverVersionDocument: null,
                                   dataPopover: null
                               })
                           }}
                       />
                   </div>
                </Popover>}
                {!!this.state.dialogOpen && <DialogForm
                    styleNumber={2}
                    dialogProps={{
                        open: !!this.state.dialogOpen
                    }}
                    disagreeButtonProps={{
                        handleDisagree: () => {
                            this.setState({
                                dialogOpen: null
                            })
                        }
                    }}
                    agreeButtonProps={{
                        handleAgree: () => {
                            this.deleteItem(this.state.dialogOpen.id);
                        }
                    }}
                />}
                <AlertCustom
                    successOpen={this.state.successOpen}
                    handleCloseNotice={()=>this.setState({
                        successOpen: false,
                        errorOpen: false
                    })}
                    errorOpen={this.state.errorOpen}
                />
            </div>
        )
    }
}

GeneralDocumentPage.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

export default   compose(
    // connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter
) (GeneralDocumentPage);
