import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

const styles = theme => ({
    customButton: {
        background: '#0000ff',
        color: '#fff',
        textTransform: 'capitalize',
        position: 'relative',
        overflow: 'hidden',
        zIndex: 1,
        border: '1px solid transparent',
        '&::before': {
            content: `''`,
            background: '#0000c7',
            position: 'absolute',
            zIndex: -1,
            width: '100%',
            height: '100%',
            transition: '0.1s',
            visibility: 'hidden',
            opacity: 0
        },
        '&:hover': {
            '&::before': {
                visibility: 'visible',
                opacity: 1
            }
        }
    }
});

const LARGE_BUTTON = 'large';

class CustomButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            smallButtonProps: {
                size: 'small',
                variant: 'contained'
            },
            largeButtonProps: {
                size: 'large',
                variant: 'contained'
            }
        }
    }

    render() {
        const {classes, titleComponent} = this.props;
        const buttonProps = Object.assign(this.props.size && this.props.size === LARGE_BUTTON ? this.state.largeButtonProps : this.state.smallButtonProps, this.props.buttonProps ? this.props.buttonProps : {});
        return (
            <Button
                className={`${classes.customButton} ${this.props.className ? this.props.className : ''}`}
                onClick={this.props.onClick}
                disabled={this.props.disabled ? this.props.disabled : false}
                {...buttonProps}
            >
                {this.props.title ? this.props.title :(titleComponent ? titleComponent :  this.props.t('button.defaultTitle'))}
            </Button>
        )
    }
}

CustomButton.defaultProps = {
    onClick: (e) => {}
}

CustomButton.propTypes = {
    classes: PropTypes.object.isRequired,
    buttonProps: PropTypes.object,
    title: PropTypes.node,
    className: PropTypes.string,
    size: PropTypes.string,
    onClick: PropTypes.func
}

export default compose(
    withStyles(styles),
    withTranslation()
)(CustomButton);
