import { useEffect, useState } from 'react'
import axios from 'axios'
import {API_LIST_CHAT} from "../../constants/apiSuffix";

export default function useChat(pageNumber, requestId, callMessage, chatCurrent) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [chatMessages, setChatMessages] = useState([])
    const [hasMore, setHasMore] = useState(false)

    useEffect(() => {
        setChatMessages([])
    }, [])

    useEffect(() => {
        if (chatCurrent != null) {
            setChatMessages(prevBooks => {
                const dataChatMessages = [...new Set([chatCurrent, ...prevBooks])];
                const dataChatsId = [];
                const dataChatMessagesNew = [];
                dataChatMessages.map(item => {
                    if (!dataChatsId.includes(item.id)) {
                        dataChatMessagesNew.push(item);
                        dataChatsId.push(item.id);
                    }
                })
                dataChatMessagesNew.sort((chatA, chatB) => {
                    return new Date(chatB.createdDate).getTime() - new Date(chatA.createdDate).getTime();
                })
                return dataChatMessagesNew
            })
        }
    }, [chatCurrent])

    useEffect(() => {
        let cancel
        setLoading(true)
        setError(false)
        let paramStr = `?request=${requestId}&page=${pageNumber}&sorts[createdDate]=desc`;
        let url = API_LIST_CHAT;
        axios({
            method: 'GET',
            url: url+paramStr,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then(res => {
            setChatMessages(prevBooks => {
                const dataChatMessages = [...new Set([...prevBooks, ...res.data.items])];
                const dataChatsId = [];
                const dataChatMessagesNew = [];
                dataChatMessages.map(item => {
                    if (!dataChatsId.includes(item.id)) {
                        dataChatMessagesNew.push(item);
                        dataChatsId.push(item.id);
                    }
                })
                dataChatMessagesNew.sort((chatA, chatB) => {
                    return new Date(chatB.createdDate).getTime() - new Date(chatA.createdDate).getTime();
                })
                return dataChatMessagesNew
            })

            setHasMore(res.data.currentPage < res.data.nbPages)
            setLoading(false)
        }).catch(e => {
            if (axios.isCancel(e)) return
            setError(true)
            setLoading(false)
        })
        return () => cancel()
    }, [pageNumber])


    return { loading, error, chatMessages, hasMore }
}
