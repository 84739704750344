import React from "react";
import {TopToolbar,sanitizeListRestProps,CreateButton,
    // ExportButton
} from "react-admin";

export const ListActions = ({
                                bulkActions,
                                basePath,
                                // currentSort,
                                // displayedFilters,
                                // exporter,
                                // filters,
                                filterValues,
                                onUnselectItems,
                                resource,
                                selectedIds,
                                // showFilter,
                                // total,
                                t,
                                // classes,
                                props
                            }) => {
    const {
        className,
        hasList, hasEdit, hasShow,
        ...rest
    } = props;
    return (
            <TopToolbar  className={`${className} toolBarAction`} {...sanitizeListRestProps(rest)}>
                {bulkActions && React.cloneElement(bulkActions, {
                    basePath,
                    filterValues,
                    resource,
                    selectedIds,
                    onUnselectItems,
                })}
                {/*{filters && React.cloneElement(filters, {*/}
                {/*    resource,*/}
                {/*    showFilter,*/}
                {/*    displayedFilters,*/}
                {/*    filterValues,*/}
                {/*    contexts: 'button',*/}
                {/*}) }*/}
                <CreateButton basePath={basePath} label={t("reactAdmin.label.create")} className={"btnCreate"}/>
                {/*<ExportButton*/}
                {/*    disabled={total === 0}*/}
                {/*    resource={resource}*/}
                {/*    sort={currentSort}*/}
                {/*    filter={filterValues}*/}
                {/*    exporter={exporter}*/}
                {/*    label={t("reactAdmin.label.export")}*/}
                {/*    className={"btnExport"}*/}
                {/*/>*/}
                {/*<RefreshButton />*/}
            </TopToolbar>
    );
};