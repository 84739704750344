import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import { withRouter} from "react-router-dom";
import UploadDoc from "../../../../images/request/document/upload_doc.svg";
import axios from "axios";
import {
    API_CHANGE_VERSION_DOCUMENT,
} from "../../../../constants/apiSuffix";
// import userIcon from "../../../../images/profile/user.svg";
import Dropzone from "react-dropzone";
import 'react-dropzone-uploader/dist/styles.css'
import CustomInlineSvg from "../../../../theme/CustomElements/CustomInlineSvg";
import closeButton from "../../../../images/theme/close.svg";
import {TableBody, Table, TableCell, TableRow} from "@material-ui/core";
import viewIcon from "../../../../images/request/detail/eye.svg"
import moment from "moment";
import deleteIcon from "../../../../images/report/bin.svg";
import GeneralModal from "../../../../theme/Modal/GeneralModal";

const styles = (theme) => ({
    generalDocumentFormWrapper: {
        '& a.returnList':{
            '&:hover': {
                background: "#0000c7",
            },
            textTransform: "uppercase",
            fontSize: '0.75rem',
            color: "white",
            background: "#0000FF",
            padding: '0.5rem 1rem',
            borderRadius: 5,
            fontWeight: 700,
            textDecoration: 'none'
        }
    },
    filePreview: {
        position: 'relative',
        paddingTop: 20,
        '& object': {
            height: 240,
        },
        '& .closeButton':{
            position: 'absolute',
            top: 10,
            left: 10,
            backgroundColor: '#cbeeff',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 20,
            height: 20,
            cursor: 'pointer',
            '& svg':{
                '& path': {
                    fill: '#e33838',
                    stroke: '#e33838'
                }
            }
        },
    },
    inputUploadFile: {
        "& .uploadIcon": {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#bed5f9',
            borderRadius: 5,
            padding: '10px 0',
            '& img': {
                height: 80
            }
        }
    },
    btnResponse:{
        background: '#0000ff !important',
        margin: '1rem 0'
    },
    deleteSvg:{
        padding: '0.25rem 0.5rem',
        marginRight: 5,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        '&:hover':{
            background: '#f2f0f0',
        }
    },
    changeVersionDocument: {
        cursor: 'pointer',
        borderRadius: 5,
        '& img': {
            height: 48,

        }
    },
    listVersionDocument: {
        '& .title': {
            fontWeight: 'bold',
            fontSize: '1rem',
        },
        '& .versionDocItem': {
            // display: 'flex',
            // justifyContent: 'space-between',
            '& .name': {

            },
            '& .createdAt': {

            },
            '& .changeVersionDocument': {
                backgroundColor: '#0000ff !important',
                color: '#fff',
                borderRadius: 5,
                textAlign: 'center',
                maxWidth: 180,
                fontWeight: 600,
                padding: 5,
                cursor: 'pointer',
            }
        }
    },
    viewIcon: {
        padding: '0 10px',
        '& img': {
            width: 30,
        }
    },
})

const dataInitial = {

}
class ViewVersionDocument extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileVersion: null,
            openModal: false,
            dataModal: null
        };

    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    handleCloseModal = () => {
        this.setState({
            openModal: false,
            dataModal: null
        })
    }

    changeVersionDocument = () => {
        const {
            dataModal,
            fileVersion
        } = this.state;
        // const {match, location} = this.props;

        if (dataModal?.versionDocument?.id) {
            this.setState({
                isLoading: true
            })
            const formData = new FormData();
            formData.append('generalVersionDocument[documentFile]', fileVersion);
            axios.post(API_CHANGE_VERSION_DOCUMENT.replace('{id}',dataModal.versionDocument.id), formData)
                .then(res => {
                    this.setState({
                        fileVersion: null,
                        isLoading: false,
                        openModal: false,
                        dataModal: null
                    }, () => {
                        if (typeof this.props.changeVersionDocumentDone === "function" ) {
                            this.props.changeVersionDocumentDone()
                        }
                    })
                })
                .catch(err => {
                    this.setState({
                        isLoading: false
                    })
                })
        }
    }

    render() {
        const {
            openModal,
            dataModal,
            fileVersion
        } = this.state;
        const {
            classes,
            documents,
            isEdit
        } = this.props;
        const fileVersionPreview = fileVersion ? URL.createObjectURL(fileVersion) : null;
        return (
            <div>
                <div className={classes.listVersionDocument}>
                    <div className="title">{this.props.t('reactAdmin.generalDoc.listVersionDoc')}</div>
                    <Table>
                        <TableBody>
                    {
                        documents.map((item, index) => {
                            return(
                                <TableRow className="versionDocItem" key={index}>
                                    <TableCell className="name">
                                        {item.docName}
                                    </TableCell>
                                    <TableCell className="createdAt">
                                        {moment(item.createdAt).format('LLLL')}
                                    </TableCell>
                                    <TableCell>
                                        <a href={item.pathUrl} download target="_blank" className={classes.viewIcon}>
                                            <img src={viewIcon} alt="" />
                                        </a>
                                    </TableCell>
                                    <TableCell>
                                        <div
                                            className="changeVersionDocument" onClick={() => {
                                            this.setState({
                                                openModal: true,
                                                dataModal: {
                                                    versionDocument: item
                                                }
                                            })
                                        }}>
                                            {this.props.t('reactAdmin.generalDoc.changeDoc')}
                                        </div>
                                    </TableCell>
                                    {isEdit && <TableCell>
                                        <div className={classes.deleteSvg} onClick={() => {
                                            this.props.removeDocument(item)
                                        }}>
                                            <CustomInlineSvg svgProps={{
                                                src: deleteIcon
                                            }}/>
                                        </div>
                                    </TableCell>}
                                </TableRow>
                            )
                        })
                    }
                        </TableBody>
                    </Table>

                </div>
                {(openModal && dataModal?.versionDocument) &&
                    <GeneralModal
                        open={openModal}
                        handleClose={this.handleCloseModal}
                        title={<>
                            <div>{this.props.t('reactAdmin.generalDoc.changeDocForVersion') + " - " + moment(dataModal.versionDocument.createdAt).format('LLLL') }</div>
                            <a href={dataModal.versionDocument.pathUrl} download target="_blank" className={classes.viewIcon}>
                                <img src={viewIcon} alt="" />
                            </a>
                        </>}
                        // buttonModal={<SubmitUploadButton onClick={this.submitRequest}/>}
                        content={
                            <div>
                                <Dropzone
                                    ref={(fileUpload) => {
                                        this.fileUpload = fileUpload;
                                    }}
                                    // accept={}
                                    minSize={0}
                                    maxFiles={0}
                                    multiple={true}
                                    onDrop={acceptedFiles => {
                                        this.handleChange("fileVersion", acceptedFiles[0])
                                    }}>
                                    {({getRootProps, getInputProps}) => {
                                        return (<section>
                                            <div {...getRootProps()} className={classes.inputUploadFile}>
                                                <input {...getInputProps()} />
                                                <div className="uploadIcon">
                                                    <img src={UploadDoc} alt=""/>
                                                </div>
                                            </div>
                                        </section>)
                                    }}
                                </Dropzone>
                                {fileVersionPreview && <div className={classes.filePreview}>
                                    <object className={classes.zoomInModal} width="100%" height="100%"
                                            data={fileVersionPreview}>
                                        <embed src={fileVersionPreview}  width="100%" height="100%"/>
                                    </object>
                                    <div className={"closeButton"}
                                         onClick={()=>{
                                             this.handleChange("fileVersion", null)
                                         }}
                                    >
                                        <CustomInlineSvg
                                            svgProps={{
                                                src: closeButton,
                                            }}
                                            scale="0.6"
                                        />
                                    </div>
                                </div>}
                            </div>
                        }
                        handleSave={() => {
                            this.changeVersionDocument();
                        }}
                        // saveButton={false}
                        // cancelButton={false}
                        enableOnClose={false}
                    />
                }
            </div>
        )
    }
}

ViewVersionDocument.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

export default   compose(
    // connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter
) (ViewVersionDocument);
