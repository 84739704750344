import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {withStyles} from "@material-ui/core/styles";
import timeIcon from "../../../images/infomation/timeIcon.svg"
import noteIcon from "../../../images/staff/note.svg"
import {convertToDateH_m_D_M_Y} from "../../../functions/datetime";
import goIcon from "../../../images/request/status/go.svg";
import CustomInlineSvg from "../../../theme/CustomElements/CustomInlineSvg";

const styles = theme => ({
    label:{
        color: '#001529',
        fontWeight: 700,
        fontSize: '1.25rem'
    },
    modalLeft:{
        paddingRight: 30,
        borderRight: '1px solid #E0E0E0'
    },
    noteWrapper:{
        background: '#F4F7FF',
        borderRadius: 15,
        padding: '0.625rem',
        minHeight: 100
    },
    title:{
        color: '#001529',
        marginLeft: 10,
        fontWeight: 700,
        fontSize: '1rem'
    },
    titleWrapper:{
        display: 'flex',
        alignItems: 'center'
    },
    elementWrapper:{
        margin:'10px 0'
    },
    modalRight:{
        padding:'0 20px 0 30px'
    },
    iconRequest:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px 0',
        '& svg':{
            width: 50,
            height: 50,
            marginRight: 10
        },
        '& span':{
            fontSize: '1.5rem',
            fontWeight: 700,
            color: '#0DBE2A'
        }
    },
    noteDetail:{
        minHeight: 80,
        overflow: 'auto',
        maxHeight: 80
    }
})
class StaffModal extends Component {
    // constructor(props) {
    //     super(props);
    // }
    render() {
        const {data, t, classes} = this.props
        return (
            <Grid container xs={12}>
                <Grid item xs={6} className={classes.modalLeft}>
                    <Grid xs={12} className={classes.label}>
                        {t("restDayList.modal.staffRequest")}
                    </Grid>
                    <Grid xs={12} container className={classes.elementWrapper}>
                        <Grid xs={6} className={classes.titleWrapper}>
                            <img alt={'start time'} src={timeIcon}/>
                            <span className={classes.title}>{t("restDayList.modal.startRestAt")}:</span>
                        </Grid>
                        <Grid xs={6}>
                            {data && convertToDateH_m_D_M_Y(data.startRestAt)}
                        </Grid>
                    </Grid>
                    <Grid xs={12} container className={classes.elementWrapper}>
                        <Grid xs={6} className={classes.titleWrapper}>
                            <img alt={'start time'} src={timeIcon}/>
                            <span className={classes.title}>{t("restDayList.modal.endRestAt")}:</span>
                        </Grid>
                        <Grid xs={6}>
                            {data && convertToDateH_m_D_M_Y(data.endRestAt)}
                        </Grid>
                    </Grid>
                    <Grid xs={12} className={classes.noteWrapper}>
                        <Grid xs={12} className={classes.titleWrapper}>
                            <img alt={"note"} src={noteIcon}/>
                            <span className={classes.title}>{t("restDayList.modal.reason")}:</span>
                        </Grid>
                        <Grid xs={12} className={classes.noteDetail}>{data.reasonRequest}</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} className={classes.modalRight}>
                    <Grid xs={12} className={classes.label}>
                        {t("restDayList.modal.adminResponse")}
                    </Grid>
                    <Grid xs={12} className={classes.iconRequest}>
                        <CustomInlineSvg svgProps={{src: goIcon}}/>
                        <span>{t("request.status.REPAIRED")}</span>
                    </Grid>
                    <Grid xs={12} className={classes.noteWrapper}>
                        <Grid xs={12} className={classes.titleWrapper}>
                            <img alt={"note"} src={noteIcon}/>
                            <span className={classes.title}>{t("restDayList.modal.adminNote")}:</span>
                        </Grid>
                        <Grid xs={12} className={classes.noteDetail}>{data.description}</Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default compose(withStyles(styles),withTranslation())(StaffModal);