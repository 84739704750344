import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {connect} from "react-redux";
import * as links from "./constants/links";
import * as authActions from "./_actions/auth";
import axios from "axios";
import {DATA_USER} from "./constants/constants";

const PrivateRoute = ({component: Component, ...rest}) => {
    let checkLogin = false;
    if (localStorage.getItem(DATA_USER) !== null) {
        const dataUserLocal = JSON.parse(localStorage.getItem(DATA_USER))??{};
        if (
            dataUserLocal &&
            dataUserLocal.token
        ) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + dataUserLocal.token;
            checkLogin = true;
        }
    }
    return (
        <Route {...rest} match render={props => {
            if (checkLogin === true){
                return <Component {...props} history={rest.history} />;
            } else {
                return <Redirect to={links.LOGIN}/>;
            }
        }} />
    );
};
const mapStateToProps = (state, props) => {
    return {
        dataUser: state.authReducer.dataUser
    }
};
const mapDispatchToProps = (dispatch)=>{
    return {
        setDataUser: (dataUser) => dispatch(authActions.setDataUser(dataUser))
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);

