import React from 'react';
import {TextField, withStyles} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';
const styles = {
    autoComplete: {
        // width: '90%',
        '& label': {
            color: '#0000ff',
            fontSize: 16,
            fontWeight: 700,
            "&:not(.Mui-focused):not(.MuiFormLabel-filled)": {
                top: "-5px",
                "& + .MuiAutocomplete-inputRoot fieldset": {
                    border: "0 !important"
                }
            }
        },
        '& .MuiAutocomplete-root .MuiFormControl-root': {
            margin: 0,
        },
        '& .MuiAutocomplete-inputRoot': {
            padding: 0,
        },
        '& input': {
            color: '#0000ff',
            fontSize: 16,
            padding: "0 !important",
            fontFamily: `"Nunito", sans-serif`,
            "&::placeholder":{
                opacity:0.8,
                fontWeight: 100,
            }
        },
        border: "0 !important",
        "& fieldset": {
            border: "0 !important"
        },
    },
};

class AppAutocomplete extends React.Component{
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const {classes,handleSearchChange, handleFieldChange,...otherProps} = this.props;
        let stateSelect = {...otherProps}
        delete (stateSelect["t"])
        delete (stateSelect["inputPlaceHolder"])
        delete (stateSelect["tReady"])

        return (
            <div className={`${classes.autoComplete} ${this.props.className ? this.props.className: ""}`}>
                <Autocomplete
                    freeSolo
                    options={this.props.options}
                    onChange={(event, value) => handleFieldChange(this.props.field, value)}
                    defaultValue={this.props.value}
                    renderInput={params => (
                        <div>
                            <TextField
                                {...params}
                                placeholder={this.props.placeholder}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                value={this.props.value}
                                onChange={handleSearchChange}
                                InputProps={(this.props.error) ? {style: {border: '1px solid #f80606'}}:{style: {padding: 0}}}
                            />
                            {this.props.error? this.props.error: ""}
                        </div>
                    )}
                    {...stateSelect}
                />
            </div>
        );
    }
}

AppAutocomplete.propsType = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withTranslation()(AppAutocomplete));
