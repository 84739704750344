import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import logoClient from '../images/navbar/logo2.png'
import {connect} from "react-redux";
import {compose} from "redux";
import {NavLink, withRouter} from "react-router-dom";
import * as links from "../constants/links"
import userIcon from "../images/navbar/userAvatar.svg"
import * as authActions from "../_actions/auth";
import {LG, MD, /*SM,*/ USER_TYPE_ADMIN, USER_TYPE_REPAIRER, USER_TYPE_SUPER_ADMIN, XS} from "../constants/constants";
import settingIcon from "../images/navbar/setting.svg"
import ArrowIcon from "../images/navbar/arrow.svg"
import personIcon from "../images/navbar/person.svg"
import dasboadIcon from "../images/navbar/dasboard.svg"
import profileIcon from "../images/navbar/profileIcon.svg"
import editIcon from "../images/navbar/edit.svg"
import CustomInlineSvg from "../theme/CustomElements/CustomInlineSvg";
import profileBg from "../images/navbar/profileBG.svg"
import logOutIcon from "../images/navbar/logOut.svg"
import {NUNITO} from "../constants/fontCss";
import Grid from "@material-ui/core/Grid";



const styles = theme => ({
    navbar: {
        height: 80,
        display: 'flex',
        alignItems: 'center',
        // padding:'0 80px',
        padding: '0 4rem',
        background: '#fff',
        justifyContent: 'space-between',
        [theme.breakpoints.down(XS)]: {
            height: 60
        },
        [theme.breakpoints.between(MD, LG)]: {
            padding: '0 4rem'
        },
        [theme.breakpoints.between(XS, MD)]: {
            padding: '0 2rem'
        },
        '& a': {
            '& img': {
                [theme.breakpoints.down(XS)]: {
                    width: 80
                }
            }
        }
    },
    infoUser: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
        marginLeft: 20,
        [theme.breakpoints.down(XS)]: {
            marginLeft: 5,
        },
        '& .userName':{
            display: 'flex',
            flexDirection: 'column',
            lineHeight: '19px',
            textAlign: 'right',
            marginRight: '6px',
            marginTop: '2px',
        },
        '& span': {
            color: '#001529',
            fontSize: '1rem',
            marginRight: 5,
            [theme.breakpoints.down(XS)]: {
                fontSize: '0.8rem',
                lineHeight: '1rem',
            }
        },
        '&:hover': {
            '& $menu': {
                opacity: 1,
                visibility: 'visible'
            },
            '& $avatar': {
                border: '2px solid #a2a2a2'
            }
        },

        '&:before':{
            content:"''",
            width:'20px',
            height:'11px',
            display: "block",
            marginRight:'10px',
            backgroundImage: `url(${ArrowIcon})`,
            backgroundRepeat: 'no-repeat',
        }
    },
    avatar: {
        position: 'relative',
        display: 'flex',
        width: 40,
        height: 40,
        overflow: 'hidden',
        borderRadius: '50%',
        border: '2px solid #eee',
        transition: '0.2s',
        margin: 'auto',
        '& img':{
            height: '100%',
            width: '100%',
            objectFit: 'cover'
        },
        [theme.breakpoints.down(XS)]:{
            width: 30,
            height: 30,
        },
    },
    menu: {
        position: 'absolute',
        top: '110%',
        zIndex: 2000,
        // width: '100%',
        right: 0,
        // textAlign: 'right',
        border: '1px solid #eee',
        borderRadius: 15,
        boxShadow: '0 3px 10px rgba(0,0,0,.15)',
        background: '#fff',
        opacity: 0,
        visibility: 'hidden',
        transition: '0.2s',
        width: 350,
        '& ul': {
            listStyleType: 'none',
            margin: 0,
            padding: '0.25rem',
            '& a':{
                textDecoration: 'none'
            },
            '& li': {
                fontSize: '1rem',
                padding: '0.5rem 1rem',
                cursor: 'pointer',
                transition: '0.2s',
                color: '#001529',
                '&:hover': {
                    background: '#f5f5f5'
                }
            }
        },
        [theme.breakpoints.down(XS)]:{
            width: 285
        }
    },
    navbarRight:{
        display: 'flex',
        '& .activeIcon':{
            '& svg':{
                '& path':{
                    fill: '#0000FF'
                }
            }
        }
    },
    menuIcon:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        width:  40,
        borderRadius: '50%',
        margin: '0 0.5rem',
        position: 'relative',
        cursor: 'pointer',
        [theme.breakpoints.down(XS)]:{
            height: 30,
            width: 30,
            '& svg':{
                width: 15,
                height: 15
            }
        },
        '&.menuIcon1':{
            '&:hover': {
                '& $menuByIcon': {
                    opacity: 1,
                    visibility: 'visible'
                },
                '& svg':{
                    '& path':{
                        fill: '#0000FF'
                    }
                }
            }
        },
        '&.menuIcon2':{
            '&:hover': {
                '& $menuByIcon': {
                    opacity: 1,
                    visibility: 'visible'
                },
                '& svg':{
                    '& path':{
                        fill: '#0000FF'
                    }
                }
            }
        },
        '&.menuIcon3':{
            '&:hover': {
                '& $menuByIcon': {
                    opacity: 1,
                    visibility: 'visible',
                },
                '& svg':{
                    '& path':{
                        fill: '#0000FF'
                    }
                }
            }
        },
        '& svg':{
            '& path':{
                fill: '#6d7bae'
            }
        }
    },
    menuByIcon:{
        position: 'absolute',
        zIndex: 99,
        width: 'max-content',
        right: 0,
        textAlign: 'left',
        border: '1px solid #eee',
        borderRadius: 15,
        boxShadow: '0px 4px 20px rgba(0, 21, 41, 0.1)',
        background: '#fff',
        opacity: 0,
        visibility: 'hidden',
        transition: '0.2s',
        minWidth: 150,
        // maxWidth: 180,
        left: '50%',
        transform: 'translateX(-50%)',
        top: '110%',
        '& ul': {
            listStyleType: 'none',
            margin: 0,
            padding: '0.25rem',
            '& a':{
                textDecoration: 'none',
                display: 'block',
            },
            '& a:not(:first-child)':{
                borderTop: '1px solid #E0E0E0',
                paddingTop: 3,
                marginTop: 3,
            },
            '& li': {
                fontSize: '1rem',
                fontWeight: 600,
                padding: '0.5rem',
                cursor: 'pointer',
                transition: '0.2s',
                color: '#001529',
                fontFamily: NUNITO,
                borderRadius: 15,
                '&:hover': {
                    background: '#f5f5f5'
                },
                [theme.breakpoints.down(XS)]:{
                    fontSize: '0.8rem',
                },
            }
        }
    },
    profile:{
        cursor: 'initial',
        textAlign: 'center',
        position: 'relative',
        '&:before':{
            content: '""',
            width: '100%',
            height: '100%',
            background: `url(${profileBg}) no-repeat`,
            backgroundSize: 'cover',
            mixBlendMode: 'luminosity',
            position: 'absolute',
            left: 0,
            borderRadius: '15px 15px 0 0',
        },
        '& .infoWrapper':{
            paddingBottom: 15,
            position: 'relative',
            background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 44.27%)',
            borderBottom: '1px solid #DDE4F8'
        },
        '& .fullName':{
            fontWeight: 600,
            color: '#001529',
            fontSize: '1rem',
            margin: '5px 0px 3px 0px',
            [theme.breakpoints.down(XS)]:{
                fontSize: '0.8rem',
            },
        },
        '& .email':{
            fontSize: '0.875rem',
            fontWeight: 600,
            color: '#929AAE',
            [theme.breakpoints.down(XS)]:{
                fontSize: '0.7rem',
            },
        }
    },
    avatarInfo:{
        textAlign: 'center',
        paddingTop: 20,
        '& img':{
            position: 'relative',
            borderRadius: '50%',
            border: '3px solid #fff',
            width: 50,
            height: 50,
            objectFit: 'cover',
            [theme.breakpoints.down(XS)]:{
                width: 36,
                height: 36,
            },
        }
    },
    profileAction:{
        display: 'flex',
        margin: 10,
        justifyContent: 'space-between',
        '& img': {
            width: 24,
            height: 20,
        },
        '& span': {
            fontSize: '1rem',
            [theme.breakpoints.down(XS)]:{
                fontSize: '0.8rem',
            },
        },
        [theme.breakpoints.down(XS)]:{
            '& img': {
                width: 18,
                height: 18,
            },
        },
        '& .editBtn':{
            height: 50,
            [theme.breakpoints.down(XS)]:{
                height: 40,
            },
            textDecoration: 'none',
            background: '#0000FF',
            display: 'flex!important',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 10,
            padding: '10px 15px 10px 8px',
            '& span':{
                color: '#fff',
                marginLeft: 10,
                marginRight: 0,
            },
            '&:hover':{
                background: '#0303bd'
            }
        },
        '& .logOut':{
            height: 50,
            [theme.breakpoints.down(XS)]:{
                height: 40,
            },
            display: 'flex',
            alignItems: 'center',
            color: '#3B4C5C',
            fontWeight: 600,
            border: '1px solid #E0E0E0',
            borderRadius: 10,
            marginRight: 0,
            padding: '0 10px',
            lineHeight:'1.2rem',

            '& img':{
                marginRight: 8,
                marginLeft:5,
            },
            '&:hover':{
                background: 'whitesmoke'
            }
        },

    }
});

class Navbar extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const {dataUser, logout, location} = this.props;
        const {
            classes,
        } = this.props;
        // console.log(dataUser)
        return (
            <div className={classes.navbar}>
                <NavLink to={links.HOME}><img src={logoClient} alt={'logo'}/></NavLink>
                <div className={classes.navbarRight}>
                    {dataUser && dataUser.data && [USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN, USER_TYPE_REPAIRER].includes(dataUser.data.group) &&
                        <React.Fragment>
                            {dataUser.data.group !== USER_TYPE_REPAIRER &&
                                <div className={`${classes.menuIcon} menuIcon1 ${(location && location.pathname && [/*links.CATEGORY,*/ links.MANAGEMENT_STAFF, links.BACKOFFICE_PAGE].includes(location.pathname)) ? "activeIcon" : ""}`}>
                                    <CustomInlineSvg  svgProps={{
                                        src: settingIcon
                                    }}/>
                                    <div className={classes.menuByIcon}>
                                        <ul>
                                            {/*<NavLink to={links.CATEGORY}><li>{this.props.t('navbar.menuIcon1.categoryList')}</li></NavLink>*/}
                                            {/*<NavLink to={links.PROFILE}><li>{this.props.t('navbar.menuIcon1.staff')}</li></NavLink>*/}
                                            <NavLink to={links.MANAGEMENT_STAFF}><li>{this.props.t('navbar.menuIcon1.staff')}</li></NavLink>
                                            {dataUser.data.group === USER_TYPE_SUPER_ADMIN && <NavLink to={links.BACKOFFICE_PAGE}><li>{this.props.t('navbar.menuIcon1.manager')}</li></NavLink>}

                                        </ul>
                                    </div>
                                </div>
                            }
                            <div className={`${classes.menuIcon} menuIcon2 ${(location && location.pathname && [links.CALENDAR, links.RESTDAY].includes(location.pathname)) ? "activeIcon" : ""}`}>
                                <CustomInlineSvg  svgProps={{
                                    src: personIcon
                                }}/>
                                <div className={classes.menuByIcon}>
                                    <ul>
                                        {dataUser.data.group !== USER_TYPE_REPAIRER && <NavLink to={links.CALENDAR}><li>{this.props.t('navbar.menuIcon2.calendar')}</li></NavLink>}
                                        <NavLink to={links.RESTDAY}><li>{this.props.t('navbar.menuIcon2.restdayList')}</li></NavLink>
                                        {/*<NavLink to={links.MANAGEMENT_STAFF}><li>{this.props.t('navbar.menuIcon2.staffManagement')}</li></NavLink>*/}
                                    </ul>
                                </div>
                            </div>
                            <div className={`${classes.menuIcon} menuIcon3 ${(location && location.pathname && [links.ADMIN_REQUEST].includes(location.pathname)) ? "activeIcon" : ""}`}>
                                <CustomInlineSvg  svgProps={{
                                    src: dasboadIcon
                                }}/>
                                <div className={classes.menuByIcon}>
                                    <ul>
                                        {/*<NavLink to={links.MANAGEMENT_STAFF}><li>{this.props.t('navbar.menuIcon3.staffManagement')}</li></NavLink>*/}
                                        <NavLink to={links.ADMIN_REQUEST}><li>{this.props.t('navbar.menuIcon3.customerRequest')}</li></NavLink>
                                    </ul>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    {dataUser?.data && <div className={classes.infoUser}>
                        <div className={"userName"}>
                            <span>{dataUser ? dataUser.data.firstName : ''}</span>
                            <span>{dataUser ? dataUser.data.lastName : ''}</span>
                        </div>
                        <div className={classes.avatar}>
                            {/*<img src={userIcon} alt=""/>*/}
                            <img src={(dataUser && dataUser.data && dataUser.data.avatar) ?? userIcon} alt=""/>
                        </div>
                        <div className={classes.menu}>
                            <div className={classes.profile}>
                                <div className={classes.avatarInfo}>
                                    <img src={(dataUser && dataUser.data && dataUser.data.avatar) ?? profileIcon} alt=""/>
                                </div>
                                <div className={'infoWrapper'}>
                                    <div className={'fullName'}>
                                        {dataUser && dataUser.data && dataUser.data.fullName}
                                    </div>
                                    <div className={'email'}>
                                        {dataUser && dataUser.data && dataUser.data.email}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.profileAction}>
                                <Grid container>
                                    <Grid item xs={5} style={{
                                        padding: '0 5px'
                                    }}>
                                        <NavLink className={'editBtn'} to={links.PROFILE}>
                                            <img src={editIcon} alt={"edit profile"}/>
                                            <span>{this.props.t('navbar.editProfile')}</span>
                                        </NavLink>
                                    </Grid>
                                    <Grid item xs={7} style={{
                                        padding: '0 5px'
                                    }}>
                                        <span className={'logOut'} onClick={logout}>
                                            <img alt={'log out'} src={logOutIcon}/>
                                            {this.props.t('button.logout')}
                                        </span>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        )
    }
}

Navbar.defaultProps = {

}

Navbar.propTypes = {
    classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
    return {
        dataUser : state.authReducer.dataUser
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        logout: ()=>{
            dispatch(authActions.logout())
        }
    }
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    withRouter
)(Navbar);
