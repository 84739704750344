import * as types from "../_constants/application";
import { ACTION_ADD, ACTION_DELETE, /*DATA_USER*/ } from "../constants/constants";
// import { act } from "@testing-library/react";
// import { DETAIL_REPORT } from "../_constants/application";
let notification = true;
if (localStorage.getItem("notification") !== null) {
    try {
        notification = localStorage.getItem('notification') === "false" ? false : true;
    } catch (e) {
        localStorage.removeItem("notification");
    }
}
const initialState = {
    isLoading: false,
    problemRequest: {},
    countRequestByStatus: null,
    server500: false,
    errorOpenAlert: false,
    errorMessageAlert: "",
    showAllNote: false,
    showNoteCell: {},
    documentRequestType: {},
    documentRequestTypeRegis: {},
    currency: null,
    dataTab2: {},
    openModalAddRequestInList: false,
    openNoticeError: false,
    openNoticeSuccess: false,
    messageNoticeError: "",
    messageNoticeSuccess: "",
    blickoContact: null,
    dataCreateReport: {},
    dataDetailReport: {},
    reportRequestNotPaid: 0,
    notification: notification,
    countChatNotSeen: [],
    forceUpdateListChangeApiParams: null,
    requestDocumentManagerNotSeen: [],
    requestUpcomingAppointment: 0,
    countPaymentRequestReport: [],
    queryFurniture: {}
};

const applicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_FORCE_UPDATE_LIST_REDUX:
            // console.log(action.isLoading)
            return {
                ...state,
                forceUpdateListChangeApiParams: action.forceUpdateListChangeApiParams
            }
        case types.SET_IS_LOADING:
            // console.log(action.isLoading)
            return {
                ...state,
                isLoading: action.isLoading
            }
        case types.GET_COUNT_PAYMENT_REQUEST_REPORT_SEEN:
        case types.SET_COUNT_PAYMENT_REQUEST_REPORT_SEEN:
            return {
                ...state,
                countPaymentRequestReport: action.countPaymentRequestReport
            }
        case types.SET_COUNT_REPORT_REQUEST_NOT_PAID:
        case types.GET_COUNT_REPORT_REQUEST_NOT_PAID:
            return {
                ...state,
                reportRequestNotPaid: action.reportRequestNotPaid
            }
        case types.SET_COUNT_CHAT_NOT_SEEN:
        case types.GET_COUNT_CHAT_NOT_SEEN:
            return {
                ...state,
                countChatNotSeen: action.countChatNotSeen
            }
        case types.SET_COUNT_UPCOMMING_APPOINTMENT:
            return {
                ...state,
                requestUpcomingAppointment: state.requestUpcomingAppointment + action.changeValue
            }
        case types.GET_COUNT_UPCOMMING_APPOINTMENT:
            return {
                ...state,
                requestUpcomingAppointment: action.requestUpcomingAppointment
            }
        case types.SET_COUNT_REQUEST_DOCUMENT_NOT_SEEN:
        case types.GET_COUNT_REQUEST_DOCUMENT_NOT_SEEN:
            return {
                ...state,
                requestDocumentManagerNotSeen: action.requestDocumentManagerNotSeen
            }
        case types.ADD_VALUE_TO_COUNT_REQUEST_DOCUMENT_NOT_SEEN:
            let requestDocumentManagerNotSeenNew = [...state.requestDocumentManagerNotSeen]
            if (!requestDocumentManagerNotSeenNew.includes(action.valueAdd)) {
                requestDocumentManagerNotSeenNew.push(action.valueAdd)
            }
            return {
                ...state,
                requestDocumentManagerNotSeen: requestDocumentManagerNotSeenNew
            }
        case types.REMOVE_VALUE_TO_COUNT_REQUEST_DOCUMENT_NOT_SEEN:
            return {
                ...state,
                requestDocumentManagerNotSeen: [...state.requestDocumentManagerNotSeen].filter(item => item !== action.valueRemove)
            }
        case types.SET_PROBLEM_DESCRIBE:
            return {
                ...state,
                problemRequest: action.problemRequest
            }
        case types.SET_PROBLEM_DESCRIBE_REGIS:
            return {
                ...state,
                problemRequest: action.problemRequest
            }
        case types.GET_COUNT_REQUEST_BY_STATUS:
            return {
                ...state,
                countRequestByStatus: action.countRequestByStatus
            }
        case types.SET_SERVER_500:
            return {
                ...state,
                server500: action.server500
            }
        case types.ERROR_ALERT:
            return {
                ...state,
                errorOpenAlert: action.errorOpen,
                messageErrorAlert: action.errorMessage,
            }
        case types.SET_SHOW_ALL_NOTE:
            return {
                ...state,
                showAllNote: action.showAllNote,
                showNoteCell: {}
            };
        case types.SET_SHOW_NOTE_CELL:
            let showNoteCellNew = { ...state.showNoteCell };
            // const showNoteCellFilter = showNoteCellNew.filter(item => item === action.rowId.toString());
            if (action.actionType === ACTION_ADD) {
                showNoteCellNew = { ...showNoteCellNew, [action.rowId]: action.rowId }
            } else if (action.actionType === ACTION_DELETE) {
                // showNoteCellNew = showNoteCellNew.filter(item => item !== action.rowId.toString());
                delete (showNoteCellNew[action.rowId]);
            }
            return {
                ...state,
                showNoteCell: showNoteCellNew,
            };
        case types.GET_DOCUMENT_REQUEST_VALID:
            return {
                ...state,
                documentRequestType: action.documentRequestType,
            }
        case types.GET_DOCUMENT_REQUEST_REGISTRATION:
            return {
                ...state,
                documentRequestTypeRegis: action.documentRequestTypeRegis,
            }
        case types.GET_PARAMETER_CURRENCY:
            return {
                ...state,
                currency: action.currency,
            }
        case types.SHOW_PARAMETER_CONTACT:
            return {
                ...state,
                blickoContact: action.blickoContact,
            }
        case types.SET_OPEN_MODAL_NEW_REQUEST_IN_LIST:
            return {
                ...state,
                openModalAddRequestInList: action.openModalAddRequestInList,
            }
        case types.SET_DATE_SELECTED:
            if (action.field) {
                return {
                    ...state,
                    dataTab2: {
                        ...state.dataTab2,
                        [action.field]: action.dataTab2
                    }
                }
            }
            else return {
                ...state,
                dataTab2: action.dataTab2
            }
        case types.SET_CLOSE_NOTICE:
            return {
                ...state,
                openNoticeError: false,
                openNoticeSuccess: false,
                messageNoticeError: "",
                messageNoticeSuccess: ""
            }
        case types.SET_OPEN_NOTICE_ERROR:
            return {
                ...state,
                openNoticeError: true,
                messageNoticeError: action.messageNotice
            }
        case types.SET_OPEN_NOTICE_SUCCESS:
            return {
                ...state,
                openNoticeSuccess: true,
                messageNoticeSuccess: action.messageNotice
            }
        case types.CREATE_REPORT:
            return {
                ...state,
                dataCreateReport: action.dataCreateReport
            };
        case types.DETAIL_REPORT:
            return {
                ...state,
                dataDetailReport: action.dataDetailReport
            };
        case types.TOGGLE_NOTIFICATION:
            localStorage.setItem('notification', action.notification);
            return {
                ...state,
                notification: action.notification
            };
        case types.SET_QUERY_FURNITURE:
            return {
                ...state,
                queryFurniture: action.queryFurniture
            };
        default:
            return state;
    }
}

export default applicationReducer;
