import { useEffect, useMemo, useRef, useState } from "react";
import {
  API_ACTION_REPAIR_REQUEST,
  API_GET_REQUEST_BY_REPAIR_TOKEN,
} from "../../../constants/apiSuffix";
import { useParams } from "react-router-dom";
import axios from "axios";
import * as links from "./../../../constants/links";
import RequestInfo from "./RequestInfo";
import { withTranslation } from "react-i18next";
import {
  Button,
  IconButton,
  LinearProgress,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { compose } from "redux";
import RepairIcon from "./../../../images/request/successBG.png";
import BannerIcon from "./../../../images/banner/banner.png";
import SignaturePagApp from "./SignaturePadApp";
import RepairingIcon from "./../../../images/repairing.gif";
import CompleteRepairIcon from "./../../../images/complete_repair.gif";

import {
  COMPLETE_REPAIR,
  MD,
  REPAIRING,
  SM,
  XS,
} from "../../../constants/constants";
import ShareIcon from "@material-ui/icons/Share";
import UploadFile from "../../../theme/Modal/UploadFile";
import LoadingAction from "../../../theme/LoadingAction";
import InfoIcon from "@material-ui/icons/Info";
import CreateIcon from "@material-ui/icons/Create";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RequestDesc from "./RequestDesc";
import { NUNITO } from "../../../constants/fontCss";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AlertCustom from "../../../theme/form/Alert/AlertCustom";
const styles = (theme) => ({
  container: {
    height: "calc(100vh - 60px)",
    backgroundColor: "#f2f7fc",
    display: "flex",
    alignItems: "center",
    overflowY: "auto",
    flexDirection: "column",
  },
  header: {
    width: "100%",
    minHeight: 100,
    marginBottom: 10,
    background: `url(${BannerIcon})`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .header_title": {
      height: "100%",
      width: 600,
      maxWidth: "calc(100% - 40px)",
      display: "flex",
      alignItems: "center",
      fontSize: "1.2rem",
      color: "#fff",
      "& img": {
        height: "100%",
        marginRight: 10,
      },
      "& span": {
        fontWeight: "bold",
        marginLeft: 5,
        fontSize: "1.5rem",
      },
    },
    "& .shareBtn": {
      color: "#fff",
    },
  },
  content: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    [theme.breakpoints.down(SM)]: {
      flexDirection: "column",
    },
  },
  request_info: {
    display: "flex",
    flexDirection: "column",
    width: 600,
    maxWidth: "calc(100% - 40px)",
    background: "#fff",
    boxShadow: "0 3px 6px 0 rgba(13, 66, 159, 0.16)",
    borderRadius: 12,
    padding: 10,
    marginBottom: 10,
    color: "#605e72",
    "& .subject": {
      fontWeight: "bold",
    },
  },
  body: {
    display: "flex",
    width: 600,
    maxWidth: "calc(100% - 40px)",
    background: "#fff",
    boxShadow: "0 3px 6px 0 rgba(13, 66, 159, 0.16)",
    borderRadius: 12,
    padding: 10,
    marginBottom: 10,
    [theme.breakpoints.down(XS)]: {
      flexDirection: "column",
    },
    "& .body_tabs": {
      // paddingRight: "10px",
      borderRight: "3px solid #afdbfd",
      width: 180,
      [theme.breakpoints.down(XS)]: {
        display: "flex",
        borderRight: "none",
        borderBottom: "3px solid #afdbfd",
        overflowX: "auto",
        width: "100%",
        "& .body_tab_item": {
          fontSize: 12,
          flexDirection: "column",
          "& span": {
            margin: 0,
            marginTop: 10,
          },
        },
      },
      "& .body_tab_item": {
        display: "flex",
        alignItems: "center",
        padding: 10,
        cursor: "pointer",
        color: "#0071c7",
        fontFamily: '"Nunito", sans-serif',
        flex: 1,
        "&:hover": {
          backgroundColor: "#dbf1fe",
        },
        "&.body_tab_item_active": {
          fontWeight: "bold",
          backgroundColor: "#afdbfd",
        },
        "& span": {
          marginLeft: 10,
          textTransform: "uppercase",
        },
      },
    },
    "& .body_content": {
      flex: 1,
      padding: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& img.signatureImg": {
        border: "1px solid #afdbfd",
        minHeight: 240,
      },
      "& .uploadBlock_title": {
        fontSize: "14px",
      },
    },
  },
  descriptionText: {
    fontWeight: "bold",
    fontSize: "1rem",
    textAlign: "center",
    marginBottom: 10,
    color: "#605e72",
  },
  action: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 10,
  },
  actionBtn: {
    backgroundImage:
      "linear-gradient(95deg,#00c1d1, #0070c7, #00c1d1) !important",
    boxShadow: "0px 4px 20px rgba(15, 15, 137, 0.1) !important",
    borderRadius: 30,
    backgroundSize: "200%",
    transition: "all .4s ease-in-out",
    textTransform: "none",
    padding: "10px 20px",
    "&:before": {
      background: "none!important",
    },
    "& .MuiButton-label": {
      fontFamily: NUNITO,
      lineHeight: "1.3rem",
      fontWeight: "700",
      color: "#fff",
      fontSize: "1rem",
    },
    "&:hover": {
      backgroundPosition: "100% 0",
      transition: "all .4s ease-in-out",
    },
  },
  status: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    [theme.breakpoints.down(XS)]: {
      padding: 5,
      "& img": {
        maxWidth: 50,
      },
      "& span": {
        fontSize: 12,
      },
    },
    "& img": {
      width: "100%",
      marginBottom: 5,
    },
    "& span": {
      color: "#0071c7",
      fontWeight: "bold",
    },
  },
  block_item: {
    width: 600,
    maxWidth: "calc(100% - 40px)",
    background: "#fff",
    boxShadow: "0 3px 6px 0 rgba(13, 66, 159, 0.16)",
    borderRadius: 12,
    padding: 10,
    marginBottom: 10,
  },
  block_item_header: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    cursor: "pointer",
    fontFamily: '"Nunito", sans-serif',
    flex: 1,
    "& .title": {
      color: "#0071c7",
      flex: 1,
      display: "flex",
      alignItems: "center",
      "& span": {
        marginLeft: 10,
        textTransform: "uppercase",
        fontWeight: "bold",
      },
    },
    "& .icon": {
      color: "#605e72",
    },
  },
  block_item_content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& .completeIcon": {
      color: "#0071c7",
      height: 80,
      width: 80,
    },
    "& img.signatureImg": {
      border: "1px solid #afdbfd",
      // minHeight: 180,
      maxWidth: "100%",
    },
    "& .uploadBlock_title": {
      fontSize: "14px",
    },
  },
});

const TAB_INFO = "info";
const TAB_SIGNATURE = "signature";
const TAB_DOCUMENT = "document";
const RequestRepairPage = (props) => {
  const { classes, t } = props;
  const [data, setData] = useState(null);
  const { token } = useParams();
  const [repairDocs, setRepairDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tabActive, setTabActice] = useState(TAB_INFO);
  const [tabExpand, setTabExpand] = useState([
    TAB_INFO,
    TAB_SIGNATURE,
    TAB_DOCUMENT,
  ]);
  const [alert, setAlert] = useState({});
  const bottomRef = useRef();
  const tabContentWrapperRef = useRef({});
  const tabContentRef = useRef({});

  useEffect(() => {
    getRequest();
  }, []);

  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  const getRequest = () => {
    setData(null);
    const urlGetShow = `${API_GET_REQUEST_BY_REPAIR_TOKEN}?token=${token}`;
    axios
      .get(urlGetShow)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setData(response.data.item);
          setTimeout(() => {
            scrollToBottom();
          }, 400);
        } else {
          throw new Error();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error?.response?.status === 404) {
          window.location.href = links.PAGE404;
        }
        // setAlert({ error: true });
      });
  };

  const onShareRepair = () => {
    const currentUrl = window.location.href;
    navigator.clipboard
      .writeText(currentUrl)
      .then(function () {
        // console.log("URL copied to clipboard");
      })
      .catch(function (error) {
        // console.error("Error copying URL to clipboard: ", error);
      });
  };

  const onStartRepair = () => {
    onActionRepair({
      startRepair: 1,
    });
    setTabActice(TAB_SIGNATURE);
  };

  const onUploadRepairDoc = () => {
    const dataDocs = {};
    let index = 0;
    repairDocs.forEach((doc) => {
      if (doc instanceof File) {
        dataDocs[`repairDocuments[${index}]`] = doc;
        index += 1;
      }
    });
    onActionRepair(dataDocs);
  };

  const onActionRepair = (data) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("token", token);
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    axios
      .post(API_ACTION_REPAIR_REQUEST, formData)
      .then((res) => {
        getRequest();
        setRepairDocs([]);
        setAlert({ success: true });
      })
      .catch((res) => {
        setAlert({ error: true });
        setIsLoading(false);
      });
  };

  const statusCode = data?.lastStatus?.statusType?.code;
  const tabs = useMemo(() => {
    const allTabs = [
      {
        code: TAB_INFO,
        icon: InfoIcon,
        name: t("requestRepair.tab.info"),
      },
      {
        code: TAB_SIGNATURE,
        icon: CreateIcon,
        name: t("requestRepair.tab.signature"),
      },
      {
        code: TAB_DOCUMENT,
        icon: PermMediaIcon,
        name: t("requestRepair.tab.document"),
      },
    ];
    return allTabs.filter((tab) => {
      if (tab.code === TAB_INFO) return true;
      if (tab.code === TAB_SIGNATURE)
        return [REPAIRING, COMPLETE_REPAIR].includes(statusCode);
      if (tab.code === TAB_DOCUMENT) {
        return statusCode === COMPLETE_REPAIR;
      }
    });
  }, [data]);

  const hasDocUpload = useMemo(() => {
    return !!repairDocs.find((doc) => doc instanceof File);
  }, [repairDocs]);

  const showTabContent = (tabCurrent) => {
    const findTabActice = tabs.find(
      (tab) => tab.code === tabCurrent ?? tabActive
    );
    if (!findTabActice) return <></>;
    switch (tabCurrent ?? tabActive) {
      case TAB_INFO:
        return (
          <>
            <RequestInfo data={data} />
            {![REPAIRING, COMPLETE_REPAIR].includes(statusCode) && (
              <div className={classes.action}>
                <Button
                  className={classes.actionBtn}
                  onClick={() => onStartRepair()}
                >
                  {t("requestRepair.start_repair")}
                </Button>
              </div>
            )}
          </>
        );
      case TAB_SIGNATURE:
        if (data?.signAt && data?.signatureRepairer) {
          return (
            <>
              <div className={classes.descriptionText}>
                {t("requestRepair.sign_your_name")}
              </div>
              <img
                src={data.signatureRepairer?.pathUrl}
                className="signatureImg"
              />
              {statusCode === REPAIRING ? (
                <div className={classes.action}>
                  <Button
                    className={classes.actionBtn}
                    onClick={() => {
                      onActionRepair({
                        completeRepair: 1,
                      });
                      setTabActice(TAB_DOCUMENT);
                    }}
                  >
                    {t("requestRepair.complete_repair")}
                  </Button>
                </div>
              ) : (
                <CheckCircleIcon className="completeIcon" />
              )}
            </>
          );
        }
        return (
          <>
            <div className={classes.descriptionText}>
              {t("requestRepair.sign_your_name")}
            </div>
            <SignaturePagApp
              onSavePng={(data) => {
                onActionRepair({
                  signature: data,
                });
              }}
            />
          </>
        );
      case TAB_DOCUMENT:
        return (
          <>
            <div className={classes.descriptionText}>
              {t("requestRepair.upload_text")}
            </div>
            <UploadFile
              isRequestNew={true}
              isUploadRepairDoc={true}
              // onlyShowDocumentOld={
              //   !!(editRequestById && parseInt(editRequestById))
              // }
              // errors={errors}
              initialData={{
                filesInitial: data.repairDocuments,
              }}
              valueForm={{
                documents: repairDocs,
              }}
              handleChange={(value) => {
                console.log("value", value);
                setRepairDocs(value);
              }}
            />
            {hasDocUpload && (
              <div className={classes.action}>
                <Button
                  className={classes.actionBtn}
                  onClick={() => {
                    onUploadRepairDoc();
                  }}
                >
                  {t("requestRepair.upload")}
                </Button>
              </div>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    // tabs.forEach((tab) => {
    //   const tabContentWrapperEl = tabContentWrapperRef.current?.[tab.code];
    //   // if (tabContentWrapperEl && tabContentWrapperEl.clientHeight) {
    //   //   tabContentWrapperEl.style.height = 0;
    //   //   // tabContentWrapperEl.style.minHeight = 0;
    //   // }
    //   if (tabExpand.includes(tab.code) && tabContentWrapperEl) {
    //     const tabContentEl = tabContentRef.current?.[tab.code];
    //     if (tabContentEl) {
    //       // tabContentWrapperEl.style.height = "auto";
    //       setTimeout(() => {
    //         tabContentWrapperEl.style.height = `${tabContentEl.clientHeight}px`;
    //       }, 100);
    //     }
    //   } else if (tabContentWrapperEl) {
    //     tabContentWrapperEl.style.height = 0;
    //     tabContentWrapperEl.style.minHeight = 0;
    //   }
    // });
  }, [tabExpand, tabs]);
  const showStatus = () => {
    switch (statusCode) {
      case REPAIRING:
        return (
          <div className={classes.status}>
            <img src={RepairingIcon} />
            <span>Repairing...</span>
          </div>
        );
      case COMPLETE_REPAIR:
        return (
          <div className={classes.status}>
            <img src={CompleteRepairIcon} />
            <span>Complete</span>
          </div>
        );
    }
  };

  return (
    <div className={classes.container}>
      {isLoading && <LoadingAction />}
      <div className={classes.header}>
        {/* <div className="header_title">
          <img src={RepairIcon} />
          <div>
            {t("banner.hello")}
            <span>{t("rolesUser.repairer")}!</span>
          </div>
        </div> */}
        {/* <Tooltip title={t("requestDocument.btn.share")}>
          <IconButton
            className="shareBtn"
            onClick={() => {
              onShareRepair();
            }}
          >
            <ShareIcon />
          </IconButton>
        </Tooltip> */}
      </div>
      {data ? (
        <>
          <RequestDesc data={data} />

          <>
            {tabs.map((tab) => {
              return (
                <div className={classes.block_item}>
                  <div
                    className={classes.block_item_header}
                    onClick={() => {
                      setTabExpand((prev) =>
                        prev.includes(tab.code)
                          ? prev.filter((item) => item !== tab.code)
                          : [...prev, tab.code]
                      );
                    }}
                  >
                    <div className="title">
                      {<tab.icon />}
                      <span>{tab.name}</span>
                    </div>
                    <div className="icon">
                      {tabExpand.includes(tab.code) ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </div>
                  </div>

                  <div
                    ref={(el) => {
                      tabContentWrapperRef.current[tab.code] = el;
                    }}
                    className={classes.block_item_content}
                    // style={{
                    //   "-moz-transition": "height .25s",
                    //   "-ms-transition": "height .52s",
                    //   "-o-transition": "height .25s",
                    //   "-webkit-transition": "height .25s",
                    //   transition: "height .25s",
                    //   height: 0,
                    //   overflow: "hidden",
                    // }}
                  >
                    {tabExpand.includes(tab.code) && (
                      <div
                        style={{
                          width: "100%",
                        }}
                        ref={(el) => {
                          tabContentRef.current[tab.code] = el;
                        }}
                      >
                        {showTabContent(tab.code)}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </>
          <div ref={bottomRef}></div>
          {/* <div className={classes.body}>
            <div className="body_tabs">
              {tabs.map((tab) => {
                return (
                  <div
                    className={`body_tab_item ${
                      tab.code === tabActive ? "body_tab_item_active" : ""
                    }`}
                    onClick={() => {
                      setTabActice(tab.code);
                    }}
                  >
                    {<tab.icon />}
                    <span>{tab.name}</span>
                  </div>
                );
              })}
              {showStatus()}
            </div>
            <div className="body_content">{showTabContent()}</div>
          </div> */}
        </>
      ) : (
        <div
          style={{
            width: "100%",
          }}
        >
          <LinearProgress />
        </div>
      )}
      <AlertCustom
        successOpen={alert?.success}
        errorOpen={alert?.error}
        handleCloseNotice={() => {
          setAlert({});
        }}
      />
    </div>
  );
};

export default compose(
  withStyles(styles),
  withTranslation()
)(RequestRepairPage);
