import React, {Component} from 'react';
import {Button, CircularProgress, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import docPdfIcon from "../../../images/report/Composant 28 – 61.svg"
import {NUNITO, OPENSANS} from "../../../constants/fontCss";
import axios from "axios";
import {
    API_GET_REQUEST_REPORT_LIST,
    API_REQUEST_REPORT_CREATE,
    API_REQUEST_REPORT_DELETE,
    LIST_REPORT
} from "../../../constants/apiSuffix";
import DialogForm from "../../../theme/CustomElements/DialogForm";
import AlertCustom from "../../../theme/form/Alert/AlertCustom";
import tickIcon from "../../../images/request/status/checktick.svg";
import CustomInlineSvg from "../../../theme/CustomElements/CustomInlineSvg";
import {IS_PAID, IS_SENT_TO_CLIENT, SM, XS} from "../../../constants/constants";
import convertIcon from "../../../images/report/output-onlinepngtools.png";
import seeIcon from "../../../images/request/see-icon.svg";
import parse from "html-react-parser";
import {getDocumentRequestValid, setProblemDescribe} from "../../../_actions/application";
import * as application from "../../../_actions/application";
import * as applicationAction from "../../../_actions/application";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {NavLink} from "react-router-dom";
import * as links from "../../../constants/links"
import SET_OK_Icon from "../../../images/request/SET_OK.svg";
const styles = theme => ({
    tableReportByRequestWrapper:{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //background: '#f2f9ff',
        //marginBottom: '40px',
        background: 'linear-gradient(0deg, rgba(225,241,255,1) 0%, rgba(238,247,255,1) 68%, rgba(255,255,255,1) 100%)',
        borderBottomLeftRadius:8,
        borderBottomRightRadius:8,
        '& .tableReportByRequestContentWrapper':{
            maxWidth: 900,
            '& tr:not(:first-child) td':{
                borderTop: '1px dashed #6a8ec8 !important',
            },
        },
        '&.reportPaid': {
            background: '#D7FFF4',

            '& td' :{
                background: '#D7FFF4',
            },
        },
        padding: '2rem',
        [theme.breakpoints.between(XS, SM)]: {
            padding: 0,
        },
    },
    tableReportByRequest: {
        width: '100%',
        '& tr td': {
            padding: '1.4rem 1.5rem !important',
            border: 'none !important',
            background: '#f2f9ff',
            [theme.breakpoints.between(XS, SM)]: {
                padding: '0.5rem !important',
            },
        }
    },
    infoWrapper: {
        display: 'flex',
        fontFamily: NUNITO,
        fontSize: '1rem',
        color: '#4e4eff',
        [theme.breakpoints.between(XS, SM)]: {
            fontSize: '0.875rem',
            maxWidth: 180
        },
        '& img':{
            padding: '5px 1rem',
            transition: '0.1s ease',
            [theme.breakpoints.between(XS, SM)]: {
              padding: '0',
                paddingRight: 5,
            },

        },
        '& .aBtn':{

            '&:after':{
                content:'""',
                display:'block',
                width: '42px',
                height: '23px',
                backgroundImage: `url(${seeIcon})`,
                margin: 'auto',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
                top: '-4px',
                right: '-3px',
                backgroundPosition: 'center',
                borderRadius: '3px',
                padding: '5px',
                //border: '1px dotted blue',
                backgroundColor: '#fff',
            },

            '&:hover':{

                   '&:after':{
                    backgroundColor: '#d4e7ff',
                    border: '1px dotted transparent',
                    },

                    '& img':{
                        padding: '0px 1rem 10px 1rem',
                        transition: '0.1s ease',

                    },
            },

        },
        '& .leftInfo': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& .aBtn': {
                textDecoration: 'none',
                color: '#fff',
            },
            '& .btnPayment': {
                backgroundImage: 'linear-gradient(95deg,#00c1d1, #0070c7, #00c1d1) !important',
                boxShadow: '0px 4px 20px rgba(15, 15, 137, 0.1) !important',
                borderRadius: 20,
                maxWidth: '365px',
                backgroundSize: '200%',
                transition: 'all .4s ease-in-out',
                textTransform: 'none',
                color: '#fff',
                '&:before':{
                    background: 'none!important'
                },
                '&:hover':{
                    backgroundPosition: '100% 0',
                    transition: 'all .4s ease-in-out',
                },
            }
        },
        '& .nameInfoWrapper':{
            '& .nameInfo':{
                opacity: 0.69,
                lineHeight: 1.1
            },
            '& .amount':{
                fontSize: 21,
                fontWeight: 600,
                paddingTop: 7,
                [theme.breakpoints.between(XS, SM)]: {
                    fontSize: 15,
                },
            }
        }
    },
    btnSelected:{
        '&.chooseReport':{
            borderRadius: 23,
            //background: '#1064ea',
            background: '#3b3bff',
            padding: '1rem 1.5rem',
            fontSize: 18,
            color: '#ffffff',
            fontWeight: 600,
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            [theme.breakpoints.between(XS, SM)]: {
                fontSize: 14,
                padding: '1rem',
            },
            '&.chooseReportDifferent':{
                padding: '0.5rem 1.5rem',
                [theme.breakpoints.between(XS, SM)]: {
                    padding: '0.5rem 1rem',
                },
            },
            '& .chooseReportContent':{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& img':{
                    paddingRight: 5,
                    height: 30
                }
            },
            '&:hover':{
                //background: '#0f54c6',
                background: '#3a3abf',
            }
        },
        display: 'flex',
        textAlign: 'center',
        background: 'transparent',
        fontSize: '1rem',
        lineHeight: 1,
        color: '#605e72',
        fontWeight: 500,
        [theme.breakpoints.between(XS, SM)]: {
            fontSize: '0.875rem',
        },
        '& svg':{
            height: 17
        },
        '& .deleteSelected':{
            fontSize: '0.875rem',
            fontWeight: 500,
            color: '#565656',
            lineHeight: 1.2,
            textDecorationLine: 'underline',
            cursor: 'pointer',
            '&:hover':{
                color: '#2f35eb'
            }
        },
        fontFamily: NUNITO,
    },
    contentWrapper:{
        fontFamily: OPENSANS,
        fontSize: '1rem',
        color: '#2f35eb',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        [theme.breakpoints.between(XS, SM)]: {
            fontSize: '0.875rem',
        },
    },
    btnSelectedWrapper: {
        width: 300,
    },
    dialogFormSpecial: {
        '& .MuiDialog-paper':{
            background: '#d4f0ff !important'
        },

    }
})

const SELECTED_REPORT_FOR_REQUEST = "SELECTED_REPORT_FOR_REQUEST";
const REMOVE_SELECTED_REPORT_FOR_REQUEST = "REMOVE_SELECTED_REPORT_FOR_REQUEST";

class DetailRowRequestList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportsList: [],
            reportsPaidList: [],
            isLoading: false,
            dialogForm: null,
            successOpen: false,
            errorOpen: false,
            reportIdsSelected: [],
            requestReportsList : []
        }
    }

    componentDidMount() {
        const {dataRow} = this.props;
        this.setState({isLoading: true})
        axios.all([
            axios.get(LIST_REPORT, {
                params: {
                    request: this.props.dataRow.id,
                    maxPerPageAll: 1,
                    reportStatus: [IS_SENT_TO_CLIENT, IS_PAID]
                }
            }),
            axios.get(API_GET_REQUEST_REPORT_LIST, {
                params: {
                    maxPerPageAll: 1,
                    request: this.props.dataRow.id,
                }
            })
        ])
            .then(res => {
                let stateNew = {isLoading: false}
                if (res[0].status === 200) {
                    const dataItemsReport =(res[0]?.data?.items) ?? [];
                    let reportsNotPaidList = []
                    let reportsPaidList = []
                    dataItemsReport.forEach((item, index) => {
                        if(item.paid){
                            reportsPaidList.push(item)
                        }else{
                            reportsNotPaidList.push(item)
                        }
                    })
                    stateNew = {
                        ...stateNew,
                        reportsList: reportsNotPaidList,
                        reportsPaidList: reportsPaidList
                    }
                }
                if (res[1].status === 200) {
                    const datatItems = (res[1]?.data?.items) ?? []
                    stateNew = {
                        ...stateNew,
                        requestReportsList: datatItems,
                        reportIdsSelected: Array.isArray(datatItems) ? datatItems.map(item => {
                            return (item?.report?.id) ?? ""
                        }) : []
                    }
                }
                this.setState(stateNew)
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
            })
    }

    getRequestReportList = () => {
        axios.get(API_GET_REQUEST_REPORT_LIST, {
            params: {
                maxPerPageAll: 1,
                request: this.props.dataRow.id,
            }
        })
            .then(res => {
                let stateNew = {isLoading: false}
                if (res.status === 200) {
                    const datatItems = (res?.data?.items) ?? []
                    stateNew = {
                        ...stateNew,
                        requestReportsList: datatItems,
                        reportIdsSelected: Array.isArray(datatItems) ? datatItems.map(item => {
                            return (item?.report?.id) ?? ""
                        }) : []
                    }
                    this.setState(stateNew)
                }else {
                    throw Error()
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
            })
    }

    handleAgreeDialog = () => {
        const {dialogForm} =this.state;
        switch (dialogForm.actionDialogForm) {
            case SELECTED_REPORT_FOR_REQUEST:
                this.selectedReportForRequest();
                break;
            case REMOVE_SELECTED_REPORT_FOR_REQUEST:
                this.removeSelectedReportForRequest()
                break;
            default:
                break;
        }
    }

    removeSelectedReportForRequest = () =>{
        const {dataRow} = this.props
        const {requestReportsList, dialogForm} = this.state
        const requestReportId = requestReportsList.find(item=> dialogForm && item.report.id === dialogForm.id )

        this.setState({
            isLoading: true,
            dialogForm: null
        })
        axios.post(API_REQUEST_REPORT_DELETE.replace("{id}", requestReportId.id ??null))
            .then(response => {
                if(response.status ===200) {
                    this.setState({
                        successOpen: true,
                        // reportIdsSelected: this.state.reportIdsSelected.filter(item=>this.state.dialogForm && item !== this.state.dialogForm.id)
                    },function (){
                        this.getRequestReportList()
                        this.props.setReportRequestNotPaid(parseInt(this.props.reportRequestNotPaid) >0 ?parseInt(this.props.reportRequestNotPaid) - 1:0);
                        // this.props.dataRow.reportIdsSelected = [];
                    })
                }else {
                    throw new Error();
                }
            })
            .catch(error => {
                this.setState({
                    isLoading: false,
                    errorOpen: true,
                    dialogForm: null
                })
            })
    }

    selectedReportForRequest = () =>{
        const {dialogForm} =this.state;
        let formData = new FormData();
        formData.append('request',this.props.dataRow.id)
        formData.append('report', dialogForm.id);
        this.setState({
            isLoading: true,
        })
        axios.post(API_REQUEST_REPORT_CREATE, formData)
            .then(response => {
                if(response.status ===200) {
                    this.setState({
                        dialogForm: null,
                        successOpen: true,
                    },function (){
                        this.getRequestReportList()
                        this.props.setReportRequestNotPaid(parseInt(this.props.reportRequestNotPaid) ? (parseInt(this.props.reportRequestNotPaid) + 1) : 1);
                        // this.props.dataRow.reportIdsSelected =  response.data.item && response.data.item.report ?response.data.item.report.id: null;
                    })
                }else {
                    throw new Error();
                }
            })
            .catch(error => {
                this.setState({
                    isLoading: false,
                    errorOpen: true,
                    dialogForm: null
                })
            })
    }

    handleCloseNotice = () => {
        this.setState({
            successOpen: false,
            errorOpen: false
        })
    }

    openDialogForm = (item, action)=>{
        this.setState({
            dialogForm: {...item, actionDialogForm: action}
        })
    }

    renderRowTableReport = (itemReport, lengthReportsList) =>{
        const {classes, t, currency,dataRow} = this.props;
        const {reportIdsSelected, requestReportsList} = this.state;
        const hasReportChecked = !!(Array.isArray(reportIdsSelected) && reportIdsSelected.length)
        const  dataIntervention= itemReport.otherInfos && itemReport.otherInfos.initialData? JSON.parse(itemReport.otherInfos.initialData):{};
        const otherInfosReport = itemReport.otherInfos ?? {}
        let viewPrice = otherInfosReport && !Number.isNaN(Number(otherInfosReport.totalFinally)) ? new Intl.NumberFormat('fr-FR').format(Number(otherInfosReport.totalFinally).toFixed(2)) : '';
        // if (lengthReportsList === 1) {
        //     window.open(itemReport.reportPdf.pathUrl, '_blank');
        // }
        const requestReportItem = requestReportsList.find(item=>item.report && item.report.id === itemReport.id)
        return ( <tr>
            <td>
                <div className={classes.infoWrapper}>
                    <div className="leftInfo">
                        {/*{itemReport?.id}*/}
                        {itemReport.reportPdf && itemReport.reportPdf.pathUrl?
                            <a href={itemReport.reportPdf.pathUrl} target="_blank" rel="noreferrer" download onClick={(event) => { console.trace("click link report",event) }}>
                                <img alt="" src={docPdfIcon}/>
                            </a>:
                            <img alt="" src={docPdfIcon}/>
                        }
                        {!requestReportItem?.paid && itemReport?.token && <NavLink className="aBtn" to={links.PAYMENT_REPORT_REQUEST.replace(':token', itemReport.token)}>
                            {/* <Button className="btnPayment">
                                {t('payment.payment_by_tier_btn')}
                            </Button> */}
                        </NavLink>}
                    </div>
                    <div className={"nameInfoWrapper"}>
                        <div className={"nameInfo"}>
                            {dataIntervention.problemObserved}
                            <div>
                                {dataIntervention.solution ? "("+dataIntervention.solution+")":""}
                            </div>
                        </div>
                        <div className={"amount"}>
                            { viewPrice} {currency?currency.unit:""}
                        </div>
                    </div>

                </div>
            </td>
            <td>
                <div className={classes.contentWrapper}>
                    { otherInfosReport.commentInfo ??"" }
                </div>
            </td>

            <td  className={classes.btnSelectedWrapper}>
                {!!(requestReportItem?.paid) ? <img alt={""} src={SET_OK_Icon}/> :
                    hasReportChecked && reportIdsSelected.includes(itemReport.id) ?
                        <div className={`${classes.btnSelected}`}>
                            <CustomInlineSvg svgProps={{
                                src: tickIcon
                            }}/>
                            <div className={"reportSelected"}>{t("client.requestDetail.reportSelected")}
                                <div onClick={() => this.openDialogForm(itemReport, REMOVE_SELECTED_REPORT_FOR_REQUEST)}
                                     className={"deleteSelected"}>{t("client.requestDetail.deleteSelected")}</div>
                            </div>
                        </div> :
                        <div
                            className={`${classes.btnSelected} chooseReport ${hasReportChecked ? "chooseReportDifferent" : ""}`}
                            onClick={() => this.openDialogForm(itemReport, SELECTED_REPORT_FOR_REQUEST)}>
                            {hasReportChecked ? <div className={"chooseReportContent"}>
                                    <img alt={""} src={convertIcon}/> {t("client.requestDetail.chooseReportDifferent")}
                                </div> :
                                t("client.requestDetail.chooseReport")}
                        </div>
                }
            </td>
        </tr>)
    }

    renderContentDetail = ()=>{
        const {classes, t, currency,dataRow} = this.props
        const {isLoading, reportsList, reportsPaidList, reportIdsSelected,dialogForm} = this.state;
        const hasReportChecked = !!(Array.isArray(reportIdsSelected) && reportIdsSelected.length)
        const  reportSelected =  Array.isArray(reportsList) && reportsList.length  && hasReportChecked? reportsList.find(item=> reportIdsSelected.includes(item.id)):{}

        return(
            <React.Fragment>
            <div
                className={`AccordionDetail ${classes.tableReportByRequestWrapper} ${dataRow.requestReportInfo && dataRow.requestReportInfo.paid ? "reportPaid" : ""}`}>
                {isLoading ? <div className={"isLoading"}><CircularProgress/></div> :
                    <div className={"tableReportByRequestContentWrapper"}>
                        <table className={classes.tableReportByRequest}>
                            <tbody>
                            {
                                Array.isArray(reportsPaidList) && reportsPaidList.length ?
                                    reportsPaidList.map(itemReport => {
                                        return this.renderRowTableReport(itemReport, reportsPaidList.length);
                                    })
                                    : ""
                            }
                            {Array.isArray(reportsList) && reportsList.length ?
                                reportsList.map(itemReport => {
                                    return this.renderRowTableReport(itemReport, reportsList.length);
                                })
                                : ""}

                            </tbody>
                        </table>
                    </div>}
            </div>
        {!!(this.state.dialogForm) && <DialogForm
            styleNumber={2}
            className={hasReportChecked && dialogForm.actionDialogForm === SELECTED_REPORT_FOR_REQUEST ? classes.dialogFormSpecial : ""}
            dialogProps={{
                open: !!(this.state.dialogForm)
            }}
            disagreeButtonProps={{
                handleDisagree: () => this.setState({dialogForm: null})
            }}
            agreeButtonProps={{
                handleAgree: () => this.handleAgreeDialog()
            }}
            componentRender={hasReportChecked && dialogForm.actionDialogForm === SELECTED_REPORT_FOR_REQUEST ?
                <div>
                    {t("client.requestDetail.contentDialogChooseChangeReport",
                        {
                            amountA: (reportSelected?.otherInfos?.totalFinally ?? 0) + (currency ? parse(currency.symbolFormat.replace("{#}", "")) : ""),
                            amountB: (dialogForm?.otherInfos?.totalFinally ?? 0) + (currency ? parse(currency.symbolFormat.replace("{#}", "")) : "")
                        }
                    )
                    }
                </div> : null}
        />}
        <AlertCustom
            successOpen={this.state.successOpen}
            errorOpen={this.state.errorOpen}
            handleCloseNotice={() => this.handleCloseNotice()}
        />
        </React.Fragment>
        )
    }

    render() {
        const { colspan, listShowTypeGrid} = this.props
        if(listShowTypeGrid){
            return this.renderContentDetail()
        }else {
            return (
                <tr>
                    <td colSpan={colspan}>
                        {this.renderContentDetail()}
                    </td>
                </tr>
            );
        }
    }
}


const mapStateToProps = (state) => {
    return {
        reportRequestNotPaid:state.applicationReducer.reportRequestNotPaid,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setReportRequestNotPaid: (countReportRequestNotPaid) => dispatch(application.setReportRequestNotPaid(countReportRequestNotPaid) )
    }
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation(), withStyles(styles)) (DetailRowRequestList);

// export default compose(withTranslation(), withStyles(styles)) (DetailRowRequestList);
