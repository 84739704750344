import React, {Component} from 'react';
import {Grid, withStyles} from "@material-ui/core";
import GeneralModal from "../../../../theme/Modal/GeneralModal";
import {connect} from "react-redux";
import {getDocumentRequestValid, setProblemDescribe} from "../../../../_actions/application";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import * as application from "../../../../_actions/application";
import LoadingAction from "../../../../theme/LoadingAction";
import axios from "axios"
import {
    API_ADD_ASSIGN_REPAIRER,
    API_GET_USER_LIST
} from "../../../../constants/apiSuffix";
import {USER_TYPE_ADMIN, USER_TYPE_REPAIRER} from "../../../../constants/constants";
import CustomButton from "../../../../theme/CustomElements/CustomButton";
import RowFormColumn from "../../../../theme/form/RowFormColumn";
import AppKeyboardDateTimePicker from "../../../../theme/form/DatePicker/AppKeyboardDateTimePicker";
import qs from "qs";
import {getParamInUrl} from "../../../../functions/functions";
import AppAsyncPaginate from "../../../../theme/form/Select/AppAsyncPaginate";
import returnIcon from "../../../../images/request/return.svg";
import AppPersonAvatar from "../../../../theme/Avatar/AppPersonAvatar";
import moment from "moment";
import tickCircleGreen from "../../../../images/managerCalendar/tickCircle.svg";
import PropTypes from "prop-types";
import repaierGrid from "../../../../images/request/requestAdmin/person.svg"
import adressIcon from "../../../../images/request/requestAdmin/adress.svg"
import dateSelectIcon from "../../../../images/request/requestAdmin/dateSelect.svg"
import {REPAIRER_BUSY, REPAIRER_NOT_EXIST} from "../../../../constants/responseCode";
import AlertCustom from "../../../../theme/form/Alert/AlertCustom";
import countries from "i18n-iso-countries";
import Information from "../../information/Information";
import PhoneIcon from "@material-ui/icons/Phone";

const Option = (props) => {
    const {
        children,
        className,
        // cx,
        // getStyles,
        // isDisabled,
        // isFocused,
        isSelected,
        innerRef,
        innerProps,
    } = props;
    const dataRepairer =props.data;
    return (
        <div
            ref={innerRef}
            {...innerProps}
            className={`${(className ? className : "")} selectItemRepairerWrapper`}
        >
            <div className={"labelItem"}>
                <AppPersonAvatar
                    name={
                        <React.Fragment>
                            <div>{dataRepairer && dataRepairer.person && dataRepairer.person.fullName ? dataRepairer.person.fullName : dataRepairer.email}</div>
                        </React.Fragment>
                    }
                    alt={dataRepairer && dataRepairer.person && dataRepairer.person.fullName ? dataRepairer.person.fullName : dataRepairer.email}
                    variant="rounded"
                    src={dataRepairer && dataRepairer.person && dataRepairer.person.avatar && dataRepairer.person.avatar.pathUrl ? dataRepairer.person.avatar.pathUrl : ""}
                    avatarImgClassName={"avatarRepairer"}
                />
                    {dataRepairer && dataRepairer.person && dataRepairer.person.fullName} {dataRepairer.userOverlapTime? `( ${headTrans.userOverlapTime} )` :""}
            </div>
            {isSelected ? <div className={"selectedValue"}><img src={tickCircleGreen} alt={"tick"}/> selected</div> :
                <div/>}
        </div>
    );
};

const styles = theme => ({
    modalAddRepairedWrapper: {
        maxHeight: '90%',
        mimHeight: '50%',
        width: '535px',
        '& .rightContentWrapper':{
            background: '#F8F8FF !important'
        },
        '& .leftContentWrapper':{
            background: '#F8F8FF !important'
        },
        '& .headerModalWrapper': {
            '& .titleModal':
                {
                    fontWeight: 700,
                    fontSize: '1.2rem'
                }
        },
        '& .informationCustomer':{
            background: '#F8F8FF',
            borderRadius: 15,
            padding: '0.5rem',
            '& td': {
                '&.label': {
                    fontWeight: 700,
                    display: 'flex',
                    alignItems: 'center',
                    '& img':{
                        width: 20,
                        height: 20,
                        marginRight: 5
                    }
                },
                color: '#001529'
            },
            '& .nameTd':{
                width: 130,
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            },

        }
    },
    selectRepairerWrapper: {
        '&.errorAssign':{
            '& .inputRowFormColumn': {
                border: '1px solid #f63c3c',
            }
        },
        '& .inputRowFormColumn':{
            height: 350,
            padding: '0.5rem',
            border: '1px solid #E0E0E0',
            borderRadius: 15,
            '& .selectPaginate__control':{
                borderRadius: 20,
                height: 40,
                background: '#F8F8FF',
            },
            '& .selectPaginate__menu':{
                boxShadow:'none !important',
                height: 290,
                margin: 0,
                marginTop: 3,
                '& .selectPaginate__menu-list':{
                    maxHeight: '100%'
                }
            },
        },
        '& .selectItemRepairerWrapper': {
            border: '1px solid #E0E0E0 !important',
            borderRadius: 6,
            margin: '0.5rem 0',
            padding: '0.5rem',
            boxShadow: ' 0px 4px 20px rgba(0, 0, 254, 0.05)',
            fontSize: '1rem',
            color: '#001529',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 50,
            // '& .selectPaginate__menu-list':{
            //     border: 0
            // },
            '& .selectedValue':{
                color: '#16CB23',
                display: 'flex',
                alignItems: 'center',
                '& img':{
                    paddingRight: '0.3rem'
                }
            },
            '& .avatarRepairer':{
                height: '36px !important',
                width: '36px !important',
                marginRight: '0.5rem',
                objectFit: 'cover'
            }
        }
    },
    modalRestday: {
        "& .labelRowFormColumn": {
            margin: '15px 0px 10px 0px',
            fontSize: '0.875rem',
            fontWeight: 700,
            color: '#001529'
        },
        '& .column1': {
            paddingRight: '1rem',
        },
        '& .column2': {
            paddingLeft: '1rem'
        }
    },
    btnAddRestday: {
        background: '#0000FF !important',
        borderRadius: 18
    },

})
const headTrans = "managerCalendarPage.managerCalendarRight.modalAddRepairer."

class ModalAddRepairer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            getDataProblem: false,
            errors: {},
            successOpen: false,
            errorOpen: false,
            formValue: {
                startRestAt: null,
                endRestAt: null,
                repairer: null,
                client: null
            },
            showDetailClient: null
        }
    }

    handleChange = (value, field) => {
        let {formValue, errors} = this.state;
        formValue[field] = value;
        if (errors.hasOwnProperty(field)) {
            delete (errors[field]);
        }
        this.setState({
            formValue: formValue
        })
    }

    submitRequest = (event) => {
        event.preventDefault()
        const formData = new FormData();
        let {errors, formValue} = this.state
        let seft = this
        if(!formValue.repairer  || !formValue.repairer.value){
            errors["repairer"] = this.props.t(headTrans+"errors.managerEmpty");
        }
        if (Object.keys(errors).length < 1) {
            this.setState({
                isLoading: true
            })
            formData.append('repairer', formValue.repairer ? formValue.repairer.value : "")
            const urlApi = API_ADD_ASSIGN_REPAIRER.replace("{id}", this.props.modalValue.id)
            axios.post(urlApi, formData)
                .then(response => {
                    // let newItem = response.data.item
                    // this.props.setIsLoading(false)
                    this.setState({
                        isLoading: false,
                        successOpen: true
                    }, () => {
                        this.props.handleCloseModal("openModal", {forceUpdate: true})
                        if(this.props.setForceUpdate){
                            this.props.setForceUpdate(true)
                        }
                    })
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.data && error.response.data.errors && error.response.data.errors.form) {
                            this.setState({
                                isLoading: false,
                                error: error.response.data.errors.form,
                                errorOpen: true
                            })
                        } else if (error.response.data && [REPAIRER_BUSY, REPAIRER_NOT_EXIST].includes(error.response.data.response_code)) {
                            let res_code =error.response.data.response_code;
                            errors.repairer = res_code === REPAIRER_BUSY ?this.props.t(headTrans+"errors.repairerBusy"):
                                res_code === REPAIRER_NOT_EXIST?this.props.t(headTrans+"errors.userNotExist"):""
                            this.setState({
                                isLoading: false,
                                errors: errors,
                                errorOpen: true
                            })
                        }else{
                            this.setState({
                                isLoading: false,
                                errorOpen: true
                            })
                        }
                    }

                })
                .finally(() => {
                    seft.props.setIsLoading(false);
                })
        }else{
            this.setState({
                errors: errors,
                errorOpen: true
            })
        }
    }
    handleCloseNotice = () => {
        this.setState({
            successOpen: false,
            errorOpen: false
        })
    }


    async loadRepairerOptions(search, loadedOptions, {page}, requestId) {
        let params = {search: search, page: page};
        params = {...params, userType: [USER_TYPE_REPAIRER, USER_TYPE_ADMIN], checkEnableUser: 1};
        if(requestId){
            params={...params, requestId: requestId}
        }
        const url = API_GET_USER_LIST + "?" + qs.stringify(params)
        const response = await axios.get(url);
        const data = response.data;
        let dataItems = [];
        let hasMore = false;
        if (data.hasOwnProperty('items') && data.items) {
            dataItems = data.items;
            hasMore = data.currentPage < data.nbPages;
        } else if (data.hasOwnProperty('hydra:member')) {
            dataItems = data["hydra:member"];
            const hydraView = data["hydra:view"];
            const paramsCurrent = getParamInUrl(hydraView["@id"]);
            const paramsPageLast = getParamInUrl(hydraView["hydra:last"]);
            const currentPageList = paramsCurrent.hasOwnProperty("page") ? paramsCurrent.page : 1;
            const nbPagesList = paramsPageLast.hasOwnProperty("page") ? paramsPageLast.page : 1;
            hasMore = currentPageList < nbPagesList;

        }
        const options = dataItems.map((item) => {
            return {
                label: item.person && item.person.fullName ? item.fullName : item.email,
                value: item.id, ...item
            };
        });

        return {
            options: options,
            hasMore: hasMore,
            additional: {
                page: page + 1,
            },
        };
    }

    handleShowDetail = (modalValue) =>{
        this.setState({
            showDetailClient: modalValue
        })
    }

    render() {
        let { errors, formValue, showDetailClient} = this.state
        let {isLoading, t, classes, openModal, modalValue, addForRequest, renderShowDetailClient} = this.props
        return (
            <React.Fragment>
                {isLoading && <LoadingAction/>}
                <GeneralModal
                    className={`${classes.modalAddRepairedWrapper} ${renderShowDetailClient?"addRepairedWrapper":"" }`}
                    open={openModal}
                    handleClose={()=>this.props.handleCloseModal("openModal")}
                    // enableOnClose={true}
                    title={addForRequest ? (modalValue && modalValue.categories && modalValue.categories[0] && modalValue.categories[0].name) : t(headTrans + "titleAdd")}
                    buttonModal={showDetailClient ? "":<CustomButton title={t(headTrans + "btnSave")}
                                                                     className={classes.btnAddRestday}
                                                                     buttonProps={{
                                                                         startIcon: <React.Fragment><img
                                                                             src={returnIcon} className={"iconAdd"} alt=""/></React.Fragment>
                                                                     }} onClick={this.submitRequest}/>}
                    content={
                        <Grid container className={classes.modalRestday}>
                            {/*<Grid container item md={12} className={"informationCustomer"}>*/}
                            {/*    <table>*/}
                            {/*        <tbody>*/}
                            {/*        <tr>*/}
                            {/*            <td className={"label"}><img src={repaierGrid} alt={'person icon'}/> {t(headTrans + "customer")}:</td>*/}
                            {/*            <td className={'nameTd'}>{modalValue.fullName ?? ""}</td>*/}
                            {/*            <td className={"label"}><img src={dateSelectIcon} alt={'person icon'}/> {t(headTrans + "registrationStart")}:</td>*/}
                            {/*            <td>{modalValue && modalValue.registrationStart && moment(modalValue.registrationStart).format('kk:mm DD/MM/YYYY')}</td>*/}
                            {/*        </tr>*/}
                            {/*        <tr>*/}
                            {/*            <td className={"label"}><img src={adressIcon} alt={'adress icon'}/> {t(headTrans + "address")}:</td>*/}
                            {/*            <td colspan="3">{modalValue.addressPostalCode ?modalValue.addressPostalCode + ", ": ""}*/}
                            {/*                {modalValue.addressDescription?modalValue.addressDescription + ", ":""}*/}
                            {/*                {modalValue.addressCity?modalValue.addressCity + ", ":""}*/}
                            {/*                {modalValue.addressCountry? countries.getName(modalValue.addressCountry, this.props.t('translations.lang')) :""}</td>*/}
                            {/*        </tr>*/}
                            {/*        </tbody>*/}
                            {/*    </table>*/}
                            {/*</Grid>*/}
                            {/*{!addForRequest &&*/}
                            {/*    <React.Fragment>*/}
                            {/*        <Grid container item md={6}>*/}
                            {/*            <RowFormColumn*/}
                            {/*                label={t(headTrans + "registrationStart")}*/}
                            {/*                className={"column1"}*/}
                            {/*                input={*/}
                            {/*                    <AppKeyboardDateTimePicker*/}
                            {/*                        value={modalValue.registrationStart?moment(modalValue.registrationStart): null}*/}
                            {/*                        // onChange={(value) => this.handleChange(value, "startRestAt")}*/}
                            {/*                        // label={this.props.t("certificateMase.list.startDate")}*/}
                            {/*                        emptyLabel={''}*/}
                            {/*                        name={"registrationStart"}*/}
                            {/*                        error={(errors.startRestAt)}*/}
                            {/*                    />*/}
                            {/*                }*/}
                            {/*                textValidate={errors.startRestAt}*/}
                            {/*            />*/}
                            {renderShowDetailClient && <div onClick={()=>this.handleShowDetail(showDetailClient? null:modalValue)} className={`detailShow ${!showDetailClient? 'notReturn':""}`}> {"<<"} {t(headTrans + (showDetailClient?"return":"detailClient"))}</div>}
                            {showDetailClient && renderShowDetailClient?
                                <Information isModalShowClientDetail={true} valueForm={modalValue}/>
                                :
                                <Grid container item md={12} className={`modalContentAddRepairedWrapper`}>
                                    <Grid container item md={12} className={`informationCustomer ${renderShowDetailClient? "showCalendar":""}`}>
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td className={"label"}><img src={repaierGrid}
                                                                             alt={'person icon'}/> {t(headTrans + "customer")}:
                                                </td>
                                                <td className={'nameTd'}>{modalValue.fullName ?? ""}</td>
                                                <td className={"label"}><img src={dateSelectIcon}
                                                                             alt={'person icon'}/> {t(headTrans + "registrationStart")}:
                                                </td>
                                                <td>{modalValue && modalValue.registrationStart && moment(modalValue.registrationStart).format('HH:mm DD/MM/YYYY')}</td>
                                            </tr>
                                            <tr>
                                                <td className={"label"}><PhoneIcon/> {t(headTrans + "phoneNumber")}:
                                                </td>
                                                <td className={""}>{modalValue.phoneNumber}</td>
                                                <td className={"label"}><img src={adressIcon}
                                                                             alt={'adress icon'}/> {t(headTrans + "address")}:
                                                </td>
                                                <td>{modalValue.addressPostalCode ? modalValue.addressPostalCode + ", " : ""}
                                                    {modalValue.addressDescription ? modalValue.addressDescription + ", " : ""}
                                                    {modalValue.addressCity ? modalValue.addressCity + ", " : ""}
                                                    {modalValue.addressCountry ? countries.getName(modalValue.addressCountry, this.props.t('translations.lang')) : ""}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                    {!addForRequest &&
                                    <React.Fragment>
                                        <Grid container item md={6}>
                                            <RowFormColumn
                                                label={t(headTrans + "registrationStart")}
                                                className={"column1"}
                                                input={
                                                    <AppKeyboardDateTimePicker
                                                        value={modalValue.registrationStart ? moment(modalValue.registrationStart) : null}
                                                        // onChange={(value) => this.handleChange(value, "startRestAt")}
                                                        // label={this.props.t("certificateMase.list.startDate")}
                                                        emptyLabel={''}
                                                        name={"registrationStart"}
                                                        error={(errors.startRestAt)}
                                                    />
                                                }
                                                textValidate={errors.startRestAt}
                                            />
                                        </Grid>
                                        <Grid container item md={6}>
                                            <RowFormColumn
                                                label={t(headTrans + "registrationEnd")}
                                                className={"column2"}
                                                input={
                                                    <AppKeyboardDateTimePicker
                                                        value={modalValue.registrationEnd ? moment(modalValue.registrationEnd) : null}
                                                        // onChange={(value) => this.handleChange(value, "registrationEnd")}
                                                        // label={this.props.t("certificateMase.list.startDate")}
                                                        emptyLabel={''}
                                                        name={"registrationEnd"}
                                                        error={(errors.endRestAt)}
                                                    />
                                                }
                                                textValidate={errors.endRestAt}

                                            />
                                        </Grid>
                                    </React.Fragment>
                                    }

                                    <Grid container item md={12}>
                                        <RowFormColumn
                                            label={t(headTrans + "assignTo")}
                                            className={classes.selectRepairerWrapper + (errors.repairer ? " errorAssign" : "")}
                                            input={
                                                <AppAsyncPaginate
                                                    classNamePrefix={"selectPaginate"}
                                                    value={formValue.repairer ?
                                                        formValue.repairer :
                                                        (modalValue.manager ?
                                                            {
                                                                value: modalValue.manager.id,
                                                                label: (modalValue.manager.person ? modalValue.manager.person.fullName : modalValue.manager.email)
                                                            }
                                                            : null)}
                                                    loadOptions={(search, loadedOptions, {page}) => this.loadRepairerOptions(search, loadedOptions, {page}, modalValue.id)}
                                                    onChange={(value) => {if(!value.userOverlapTime){this.handleChange(value, "repairer")}}}
                                                    debounceTimeout={500}
                                                    menuIsOpen={true}
                                                    components={{Option}}
                                                    additional={{
                                                        page: 1
                                                    }}
                                                    // closeMenuOnSelect={false}
                                                />
                                            }
                                            textValidate={errors.repairer}

                                        />
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    }
                    saveButton={false}
                    cancelButton={false}
                    enableOnClose={false}
                />
                <AlertCustom
                    successOpen={this.state.successOpen}
                    errorOpen={this.state.errorOpen}
                    handleCloseNotice={()=>this.handleCloseNotice()}
                />
            </React.Fragment>

        );
    }
}

ModalAddRepairer.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        problemRequest: state.applicationReducer.problemRequest,
        isLoading: state.applicationReducer.isLoading,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setProblemDescribe: (problemRequest) => dispatch((setProblemDescribe(problemRequest))),
        setIsLoading: (isLoading) => dispatch(application.setIsLoading(isLoading)),
        getDocumentRequestValid: () => dispatch((getDocumentRequestValid())),
    }
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation(), withStyles(styles))(ModalAddRepairer);
