import {
    ARRAY, EMAIL, NUMBER, PHONE_NUMBER,
    // OBJECT, 
    STRING
} from "../constants/constants";

export const errorsMessCreateRequest = {
    addressFirstName:  {
        type: STRING,
        errorByField: ("information.formTab1.errors.firstNameEmpty")
    },
    addressLastName: {
        type: STRING,
        errorByField: ("information.formTab1.errors.lastNameEmpty")
    },
    // addressCompany:  {
    //     type: STRING,
    //     errorByField: ("errors.notNull")
    // },
    firstName:  {
        type: STRING,
        errorByField: ("information.formTab1.errors.firstNameEmpty")
    },
    lastName: {
        type: STRING,
        errorByField: ("information.formTab1.errors.lastNameEmpty")
    },
    email:  {
        type: EMAIL,
        errorByField: ("information.formTab1.errors.emailInvalid")
    },
    phoneNumber: {
        type: PHONE_NUMBER,
        errorByField: ("information.formTab1.errors.phoneNumberInvalid")
    },
    phoneNumberAddress: {
        type: PHONE_NUMBER,
        errorByField: ("form.error.invalid_field")
    },
    addressCity: {
        type: STRING,
        errorByField: ("information.formTab1.errors.addressCityEmpty")
    },
    addressPostalCode:  {
        type: STRING,
        errorByField: ("information.formTab1.errors.addressPostalCodeEmpty")
    },
    addressCountry:  {
        type: STRING,
        errorByField: ("information.formTab1.errors.addressCountryEmpty")
    },
    addressDescription:  {
        type: STRING,
        errorByField: ("information.formTab1.errors.addressDescriptionEmpty")
    },
    // constructionYear:  {
    //     type: NUMBER,
    //     errorByField: ("information.formTab1.errors.constructionYearInvalid")
    // },
    checkOwner:  {
        type: STRING,
        errorByField: ("information.formTab1.errors.checkOwnerEmpty")
    },
    acceptCertificate: {
        type: STRING,
        errorByField: ("information.formTab1.errors.acceptCertificateEmpty")
    },
    floorNumber:{
        type: STRING,
        errorByField: ("errors.notNull")
    },
    builtForOver2Years:{
        type: NUMBER,
        errorByField: ("errors.notNull")
    },
    // doorPassword:{
    //     type: STRING,
    //     errorByField: ("errors.notNull")
    // },
    isThereAnElevator: {
        type: STRING,
        errorByField: ("errors.notNull")
    }
}

export const errorMessPanel1 = {
    firstName:  {
        type: STRING,
        errorByField: ("information.formTab1.errors.firstNameEmpty")
    },
    lastName: {
        type: STRING,
        errorByField: ("information.formTab1.errors.lastNameEmpty")
    },
    email:  {
        type: EMAIL,
        errorByField: ("information.formTab1.errors.emailInvalid")
    },
    phoneNumber: {
        type: PHONE_NUMBER,
        errorByField: ("information.formTab1.errors.phoneNumberInvalid")
    },
}

export const errorMessPanel2 = {
    addressFirstName:  {
        type: STRING,
        errorByField: ("information.formTab1.errors.firstNameEmpty")
    },
    addressLastName: {
        type: STRING,
        errorByField: ("information.formTab1.errors.lastNameEmpty")
    },
    phoneNumberAddress:  {
        type: STRING,
        errorByField: ("errors.notNull")
    },
    addressCity: {
        type: STRING,
        errorByField: ("information.formTab1.errors.addressCityEmpty")
    },
    addressPostalCode:  {
        type: STRING,
        errorByField: ("information.formTab1.errors.addressPostalCodeEmpty")
    },
    addressCountry:  {
        type: STRING,
        errorByField: ("information.formTab1.errors.addressCountryEmpty")
    },
    addressDescription:  {
        type: STRING,
        errorByField: ("information.formTab1.errors.addressDescriptionEmpty")
    },
    floorNumber:{
        type: STRING,
        errorByField: ("errors.notNull")
    },
    // builtForOver2Years:{
    //     type: STRING,
    //     errorByField: ("errors.notNull")
    // },
    // doorPassword:{
    //     type: STRING,
    //     errorByField: ("errors.notNull")
    // },
    isThereAnElevator: {
        type: STRING,
        errorByField: ("errors.notNull")
    }
}

export const errorMessPanel4 = {
    checkOwner:  {
        type: STRING,
        errorByField: ("information.formTab1.errors.checkOwnerEmpty")
    },
    builtForOver2Years:{
        type: STRING,
        errorByField: ("information.formTab1.errors.checkOwnerEmpty")
    },
}
export const errorsMessCreateRequestCompany = {
    companyName:{
        type: STRING,
        errorByField: ("errors.notNull")
    },
    // companyTaxCode:{
    //     type: STRING,
    //     errorByField: ("errors.notNull")
    // }
}


export const errorsMessCreateRequestBillingAddress = {
    invoiceFirstName:  {
        type: STRING,
        errorByField: ("errors.notNull")
    },
    invoiceLastName: {
        type: STRING,
        errorByField:("errors.notNull")
    },
    billingAddressCity: {
        type: STRING,
        errorByField: ("errors.notNull")
    },
    billingAddressPostalCode:  {
        type: STRING,
        errorByField:("errors.notNull")
    },
    billingAddressCountry:  {
        type: STRING,
        errorByField: ("errors.notNull")
    },
    billingAddressDescription:  {
        type: STRING,
        errorByField: ("errors.notNull")
    },
}

export const errorsMessPerson = {
    firstName:  {
        type: STRING,
        errorByField: ("reactAdmin.user.form.errors.firstNameEmpty")
    },
    lastName: {
        type: STRING,
        errorByField: ("profile.form.errors.lastNameEmpty")
    },
    email:  {
        type: STRING,
        errorByField: ("profile.form.errors.emailInvalid")
    },
    // phoneNumber: {
    //     type: NUMBER,
    //     errorByField: ("profile.form.errors.phoneNumberInvalid")
    // },
    // addressPostalCode:  {
    //     type: STRING,
    //     errorByField: ("profile.form.errors.addressPostalCodeEmpty")
    // },
    // addressCountry:  {
    //     type: STRING,
    //     errorByField: ("profile.form.errors.addressCountryEmpty")
    // },
    // addressCity:  {
    //     type: STRING,
    //     errorByField: ("profile.form.errors.addressCityEmpty")
    // },
    // addressDescription:  {
    //     type: STRING,
    //     errorByField: ("profile.form.errors.addressDescriptionEmpty")
    // },
}

export const errorsMessMailType = {
    subject:  {
        type: STRING,
        errorByField: ("reactAdmin.mailType.errors.subjectEmpty")
    },
    body: {
        type: STRING,
        errorByField: ("reactAdmin.mailType.errors.bodyEmpty")
    },
    // statusType: {
    //     type: OBJECT,
    //     errorByField: ("reactAdmin.mailType.errors.statusTypeEmpty")
    // },
}

export const errorsChangeStatusRepaired = {
    price:  {
        type: STRING,
        errorByField: ("request.popoverChangeStatus.errors.priceEmpty")
    },
}

export const errorsMessAddRestDay = {
    subject:  {
        type: STRING,
        errorByField: ("managerCalendarPage.managerCalendarRight.modalRestday.errors.subjectEmpty")
    },
    user:  {
        type: ARRAY,
        errorByField: ("managerCalendarPage.managerCalendarRight.modalRestday.errors.userEmpty")
    },
    startRestAt: {
        type: STRING,
        errorByField: ("managerCalendarPage.managerCalendarRight.modalRestday.errors.startDateAtEmpty")
    },
    endRestAt: {
        type: STRING,
        errorByField: ("managerCalendarPage.managerCalendarRight.modalRestday.errors.endDateAtEmpty")
    },
    // statusType: {
    //     type: OBJECT,
    //     errorByField: ("reactAdmin.mailType.errors.statusTypeEmpty")
    // },
}
