import {MD, SM, XS} from "../../../../constants/constants";
import {NUNITO} from "../../../../constants/fontCss";

export const stylesManagerCalenderPageRight = theme => ({
    managerCalendarPage: {
        width: '100%',
        padding: '1rem',
        [theme.breakpoints.between(XS, SM)]: {
            padding: 0,
            paddingTop: '1.5rem',
        },
        '& .managerCalendarHeader': {
            display: 'flex',
            justifyContent: 'space-between',
            '& .headerOfCalenderWrapper':{
                display: 'flex',
                alignItems: 'flex-end',
            },
            '&.managerCalendarHeaderTimeline': {
                paddingLeft: 100,
                [theme.breakpoints.between(XS, SM)]: {
                    paddingLeft: 0,
                },
            },
            '& .monthSelectTimeline': {
                width: '100%',
                fontSize: '1.5rem',
                color: '#1D243E',
                fontWeight: 700,
                paddingBottom: '1rem'
            },
            '& .monthSelect': {
                color: '#001529',
                fontSize: '1.5rem',
                fontWeight: 700,
                background: '#fff',
                borderRadius: ' 15px 15px 0px 0px',
                padding: '0.5rem 1rem'
            },
            '& .managerCalendarHeaderLeft': {
                color: '#1D243E',
                fontWeight: 700,
                fontSize: '1rem',
                '& .groupPersonSelect__control': {
                    border: 'none',
                    borderRadius: 15,
                    background: '#fff',
                    cursor: 'pointer',
                    '& .groupPersonSelect__placeholder':{
                        color: '#1D243E'
                    },
                    '&:hover':{
                        background:'#A1A1A2',
                    },
                    '& svg':{
                        display: 'none',
                    }
                },
                '& .groupPersonSelect__value-container': {
                    background: 'transparent'
                },
                '& .headerTypeStatusWrapper': {
                    paddingRight: '1rem',
                    marginTop: '0.5rem',
                    // [theme.breakpoints.between(SM, MD)]: {
                    //     marginBottom: '1rem',
                    // },
                },
                '& .headerTypeStatus': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    background: '#FFFFFF',
                    boxShadow: '0px 4px 20px rgba(0, 0, 254, 0.05)',
                    borderRadius: 15,
                    padding: '0.5rem 1rem',
                    cursor: 'pointer',
                    '&:hover':{
                      background:'#A1A1A2',
                    },
                    '&.tabActiveTask':{
                        background: ' #585FE6',
                        '& .taskIconWrapper': {
                            background: '#fff',
                            '& svg':{
                                '& path':{
                                    fill: '#585FE6'
                                }
                            }
                        },
                    },
                    '&.tabActiveSun':{
                        background: ' #C4C4C4',
                        '& .sunIconWrapper': {
                            background: '#fff',
                            '& svg': {
                                fill: '#C4C4C4',
                                '& path':{
                                    fill: '#C4C4C4',
                                }
                            }
                        },
                    }
                }
            },
            '& .managerCalendarHeaderRight': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: '0.5rem',
                '& .tabShowContent': {
                    height: 40,
                    width: 60,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0px 4px 20px rgba(0, 0, 254, 0.05)',
                    background: '#fff',
                    cursor: 'pointer',
                    marginTop: '0.5rem',
                    '&.tabShowContentLeft': {
                        borderRadius: '15px 0px 0px 15px',
                    },
                    '&.tabShowContentRight': {
                        borderRadius: ' 0px 15px 15px 0px',
                    },
                    '&.tabActive': {
                        background: '#DDDDFF'
                    }
                },
                '& .btnAddHoliday': {
                    marginLeft: '0.5rem',
                    marginTop: '0.5rem',
                    background: '#00D5FF !important',
                    [theme.breakpoints.down(XS)]:{
                        marginTop: '0.5rem'
                    }
                },
            }
        }
    },
    calenderDetailWrapper: {
        width: '100%',
        '&.timelineCalenderWrapper': {
            '& .dx-scheduler': {
                padding: '0 0.5rem 0 0 ',
                boxShadow: 'none'
            },
            '& .dx-scheduler-work-space': {
                background: '#f8f8ff !important',
            },
    //         '& .dx-scheduler-header-scrollable': {
    //             height: 40,
    //             '& .dx-scheduler-header-panel': {
    //                 marginTop: '0 !important',
    //                 marginBottom: '0 !important',
    //             }
    //         },
    //         '& .dx-scheduler-timeline .dx-scheduler-date-table tbody': {
    //             height: 'auto !important',
    //             minHeight: '70px !important'
    //         },
            '& .dx-scheduler-header-panel-cell': {
                // margin: '0 !important',
                // padding: '0 !important',
                background: '#fff',
                fontHeight: 1,
                fontWeight: 700
            },
    //         '& .dx-scheduler-group-row':{
    //             // paddingBottom: '12px !important',
    //             // marginBottom: 12
    //         },
    //         '& .dx-scheduler-group-flex-container':{
    //             paddingBottom: 42
    //         },
            '& .dx-scheduler-group-header': {
                background: '#f8f8ff !important',
                borderTop: 'none !important',
                borderBottom: '10px solid #f8f8ff !important',
    //             // height: 70,
                display: 'flex',
                alignItems: 'center',
    //             // '&>div': {
    //             //     fontWeight: '700',
    //             //     fontSize: '1rem',
    //             //     color: '#929AAE',
    //             //     lineHeight: 1,
    //             //     whiteSpace: 'nowrap',
    //             //     overflow: 'hidden',
    //             //     textOverflow: 'ellipsis',
    //             //     display: '-webkit-box',
    //             //     '-webkit-line-clamp': 2,
    //             //     '-webkit-box-orient': 'vertical',
    //             // }
            },
    //         '& .dx-scheduler-group-table': {
    //             background: '#f8f8ff !important',
    //             paddingBottom: 8,
    //             minHeight: '70px !important'
    //         },
            '& .dx-scheduler-sidebar-scrollable': {
    //             paddingBottom: '0 !important',
                '&:before': {
    //                 background: '#f8f8ff !important',
                    borderBottom: '0 !important',
                }
            },
    //
            '& .dx-scheduler-appointment': {
                background: '#fff',
                height: '60px !important',
                marginTop: -20,
                border: '1px solid #E0E0E0',
                boxShadow: '0px 4px 10px rgba(0, 21, 41, 0.1)',
                borderRadius: 8,
    //             '& .dx-scheduler-appointment-content': {
    //                 padding: 0
    //             }
            },
            '& .dx-scheduler-date-table-cell': {
                borderBottom: '10px solid #fff',
                background: '#F3F3F3',
                // borderRight: '1px solid #E0E0E0',
                borderTop: 'none',
                // padding: '0 !important',
                // height: 70
            }
        },

        '& .dx-scheduler-appointment': {
            background: 'transparent',
            border: 'none',
            "-webkit-box-shadow": "none",
            boxShadow: "none !important",
            '& .dx-scheduler-appointment-content': {
                padding: 0
            }
        },

        '& .dx-scheduler-date-table-cell': {
            textAlign: 'left',
            border: 'none',
            borderRight: '1px solid #F2F2F2',
            borderBottom: ' 1px solid #F2F2F2',

            '& div': {
                color: '#1D243E',
                fontSize: '1.25rem',
                fontWeight: 700,
                fontFamily: NUNITO,
            },
            '& .companyHolidayWrapper':{
                background: '#fdfbea'
            }
        },
        '& .dx-resizable-handle-left, .dx-resizable-handle-right, .dx-scheduler-appointment-collector': {
            display: 'none'
        },
        '& .dx-scheduler-work-space': {
            marginTop: 0,
            paddingTop: 0,
            border: 'none',

        },
        '& .dx-scheduler-header': {
            display: 'none'
        },
        '& .dx-scheduler': {
            background: '#fff',
            width: '100%',
            boxShadow: '0px 4px 20px rgba(0, 0, 254, 0.05)',
            borderRadius: '0 15px 15px 15px',
            padding: '0.5rem',
            paddingTop: 0,
        },
    },
    contentItemCalender: {
        display: 'flex',
        alignItems: 'center',
        padding: '0.15rem 0.5rem',
        fontWeight: 700,
        '&.typeTask': {
            color: '#585FE6',
            width: '100%',
            '&.typeTaskTimeline':{
                height: '100%',
            }
        },
        '&.typeHoliday':{
            color: '#C4C4C4',
            width: '100%',
        },
        '& .statusName': {}

    },
    iconStatusWrapper: {
        '&.taskIconWrapper': {
            background: ' #585FE6',
        },
        '&.docIconWrapper': {
            background: ' #F8A521',
        },
        '&.sunIconWrapper': {
            background: ' #C4C4C4',
        },
        '&.iconStatusWrapper1': {
            borderRadius: 8,
            height: '1.5rem',
            width: '1.5rem',
            '& img': {
                height: 10,
            }
        },
        '&.iconStatusWrapper2': {
            borderRadius: 8,
            height: '2rem',
            width: '2rem',
            marginRight: '0.5rem',
            '& img': {
                height: 13,
            }
        },
        borderRadius: 4,
        height: '1rem',
        width: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    numberByStatus: {
        padding: '0 0.3rem'
    },
    contentItemCalenderWrapper: {
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        padding: '0 0.5rem',
        position: 'unset',
        flexDirection: 'column',
        '& .titleItem': {
            fontWeight: 700,
            color: '#1D243E',
        },
        '& .startEndTime': {
            display: 'flex',
            fontSize: '0.875rem',
            color: '#1D243E',
            '& img': {
                paddingRight: '0.3rem'
            }
        },
        '&.companyHolidayWrapper': {
            background: '#fdfbea',
            '& .companyHoliday': {
                color: '#EBD52F',
                textAlign: 'center',
                lineHeight: 1,
                '& svg': {
                    '& path': {
                        fill: '#EBD52F'
                    }
                }
            }
        }
    },
    btnResponse: {
        background: '#0000FF !important',
        position: 'absolute',
        marginLeft: '100%'
    }
});
